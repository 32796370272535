<template>
    <tr class="text-gray-650 bg-white | transition hover:bg-gray-150">
        <td>
            <activix-checkbox
                :id="productKey"
                class="align-middle mb-0.5"
                :disabled="disabled"
                :value="leadProduct.sold"
                @input="value => update('sold', value)"
            />
        </td>

        <td>
            <span class="hidden md:inline">
                {{ $t(`clientCard.products.${product.name}`) }}
            </span>

            <dl class="m-0 md:hidden">
                <dd>
                    <strong class="font-semibold">{{ $t(`clientCard.products.${product.name}`) }}</strong>
                </dd>
                <dd>
                    <invisible-input
                        :disabled="disabled"
                        :value="leadProduct.notes"
                        @input="value => update('notes', value)"
                    />
                </dd>
            </dl>
        </td>

        <td class="hidden md:table-cell">
            <invisible-input
                :disabled="disabled"
                :value="leadProduct.notes"
                @input="value => update('notes', value)"
            />
        </td>

        <td>
            <invisible-input
                :disabled="disabled"
                :value="leadProduct.price"
                mask="money"
                @input="value => update('price', value)"
            />

            <invisible-time-picker
                class="md:hidden"
                :value="leadProduct.time"
                :disabled="disabled"
                @input="updateTimeValue"
            />
        </td>

        <td class="hidden md:table-cell">
            <invisible-time-picker
                :value="leadProduct.time"
                :disabled="disabled"
                @input="updateTimeValue"
            />
        </td>
    </tr>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import { minutesToTime, timeToMinutes } from '@/utils/time';

    import InvisibleInput from '@/components/container/input/InvisibleInput';
    import InvisibleTimePicker from '@/components/container/input/InvisibleTimePicker.vue';

    import Lead from '@/entities/Lead';

    export default {
        components: { InvisibleInput, InvisibleTimePicker },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
            product: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            disabled() {
                return this.guest;
            },

            leadProduct() {
                const leadProduct = this.lead.products.find(value => {
                    return value.id === this.product.id;
                });

                if (leadProduct) {
                    return this.normalizeValues(leadProduct.pivot);
                }

                return this.normalizeValues({
                    price: this.product.default_price || '',
                    minutes: this.product.default_minutes || 0,
                    notes: '',
                    sold: false,
                });
            },

            productKey() {
                return `service-product-${this.product.id}`;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadProductAction']),

            normalizeValues(values) {
                return {
                    price: values?.price || null,
                    notes: values?.notes || null,
                    sold: values?.sold || false,
                    time: minutesToTime(values?.minutes || 0),
                };
            },

            async update(field, value) {
                if (
                    this.disabled ||
                    this.$wait.is(`updating.product.${this.product.id}`)
                ) {
                    return;
                }

                this.$wait.start(`updating.product.${this.product.id}`);

                let sold = this.leadProduct.sold;

                if (field === 'sold') {
                    sold = value;
                } else if (field === 'minutes') {
                    sold = !!value || !!this.leadProduct.price;
                } else if (field === 'price') {
                    sold = !!value || !!this.leadProduct.time?.HH || !!this.leadProduct.time?.mm;
                }

                await this.updateLeadProductAction({
                    id: this.product.id,
                    lead_id: this.lead.id,
                    name: this.product.name,
                    order: this.product.order,
                    type: this.product.type,
                    pivot: {
                        ...this.leadProduct,
                        sold,
                        [field]: value,
                    },
                });

                this.$wait.end(`updating.product.${this.product.id}`);
            },

            updateTimeValue(time) {
                if (time.HH == this.leadProduct.time.HH && time.mm == this.leadProduct.time.mm) {
                    return;
                }

                this.update('minutes', timeToMinutes(time));
            },
        },
    };
</script>
