<template>
    <flexible-input>
        <input-label slot="label" class="flex items-center">
            <activix-tooltip :content="$t('clientCard.vehicles.accidentedTooltip')">
                <span>  {{ $t('clientCard.vehicles.accidented_damage_cost') }} </span>
            </activix-tooltip>
        </input-label>

        <invisible-input
            class="flex-1 cursor-pointer"
            :readonly="true"
            :value="toMoney(vehicle.accidented_damage_cost, 2)"
            @click="onAccidentedDamageCostClick"
        />

        <accidented-vehicle
            :opened.sync="modals.accidentedVehicle.opened"
            :vehicle="vehicle"
        />
    </flexible-input>
</template>

<script>
    import AccidentedVehicle from '@/components/modals/AccidentedVehicle.vue';
    import FlexibleInput from '@/components/container/input/FlexibleInput.vue';
    import InputLabel from '@/components/presentational/input/InputLabel.vue';
    import InvisibleInput from '@/components/container/input/InvisibleInput.vue';

    import LeadVehicle from '@/entities/LeadVehicle.js';

    export default {
        components: {
            AccidentedVehicle,
            FlexibleInput,
            InputLabel,
            InvisibleInput,
        },

        props: {
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        data() {
            return {
                modals: {
                    accidentedVehicle: {
                        opened: false,
                    },
                },
            };
        },

        methods: {
            onAccidentedDamageCostClick() {
                this.modals.accidentedVehicle.opened = true;
            },
        },
    };
</script>
