<template>
    <div class="relative flex justify-between items-stretch p-6 gap-3 | group transition duration-300 hover:bg-gray-100 | md:gap-6">
        <communication-list-item-icon
            :communication="communication"
            :lead="lead"
            :planned="planned"
            :show-anchor-line="showAnchorLine"
        />

        <communication-list-item-content
            :account="account"
            :communication="communication"
            :lead="lead"
            @edit-communication="$emit('edit-communication', $event)"
            @open-context-modal="$emit('open-context-modal', $event)"
        />
    </div>
</template>

<script>
    import CommunicationListItemContent from '@/components/container/lead/communications/CommunicationListItemContent';
    import CommunicationListItemIcon from '@/components/container/lead/communications/CommunicationListItemIcon';

    import Account from '@/entities/Account.js';
    import Communication from '@/entities/Communication.js';
    import Lead from '@/entities/Lead.js';

    export default {
        components: {
            CommunicationListItemContent,
            CommunicationListItemIcon,
        },

        props: {
            account: {
                required: true,
                type: Account,
            },
            communication: {
                required: true,
                type: Communication,
            },
            lead: {
                required: true,
                type: Lead,
            },
            showAnchorLine: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            planned() {
                return !!this.communication.automation;
            },
        },
    };
</script>
