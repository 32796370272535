<template>
    <span class="flex items-center">
        <input-text
            name="value"
            type="currency"
            :vehicle="true"
            :record-id="vehicle.id"
            :value="vehicle.value"
            :disabled="isValueDisabled"
            v-if="!applyCBBValue"
        />
        <activix-tooltip :content="tooltip" v-else>
            <div class="cursor-pointer inline-block" :class="{ 'text-red-500': minMaxValues }" @click="triggerAllowManualValue">
                {{ minMaxValues ? minMaxValues : toMoney(vehicleValue, 2) }}
            </div>
        </activix-tooltip>
        <activix-tooltip :content="$t('clientCard.selectCorrectVersion')" v-if="showMatchModal">
            <activix-button class="h-7 w-8 p-0 ml-3" type="white" @click="triggerMatchModal">
                <icon class="text-grey-600 text-lg" name="regular/car-actions-search-1" />
            </activix-button>
        </activix-tooltip>
        <activix-confirm-modal
            :content="$t('clientCard.changeValueCBB')"
            :opened.sync="valueModal.changeOpened"
            :title="$t('clientCard.changeValueCBBTitle')"
            @approve="allowManualValue"
            @closed="closeModal"
        />
        <activix-confirm-modal
            :content="$t('clientCard.resetCBBValue')"
            :opened.sync="valueModal.resetCBBOpened"
            :title="$t('clientCard.resetCBBValueTitle')"
            @approve="resetCBBValue"
            @closed="closeModal"
        />
        <c-b-b-match-modal
            :opened.sync="valueModal.matchModalOpened"
            :vehicle="vehicle"
            @approve="setCBBValue"
            @closed="closeModal"
        />
    </span>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import InputText from './inputs/InputText.vue';
    import CBBMatchModal from '../modals/CBBMatchModal.vue';
    import EquityType from '../../entities/EquityType.js';
    import VehicleVinMatchType from '../../entities/VehicleVinMatchType.js';
    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'Value',

        components: {
            CBBMatchModal,
            InputText,
        },

        props: {
            vehicle: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                canChangeValue: false,
                valueModal: {
                    matchModalOpened: false,
                    changeOpened: false,
                    resetCBBOpened: false,
                },
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            isValueDisabled() {
                return (
                    this.guest ||
                    (this.contextLead.freeze_financial_data && this.contextLead.account.commercial) ||
                    (this.contextLead.account.trade_report && !this.authUser.trade_report && !this.authUser.isAdmin())
                );
            },

            tooltip() {
                let condition =
                    this.vehicle.condition?.toLowerCase() || this.contextAccount.renewal_alert_options.equity_base;

                if (!condition) {
                    return '';
                }

                if (condition == 'extra_clean') {
                    condition = 'xclean';
                }

                if (!EquityType.keys.includes(condition)) {
                    return '';
                }

                const conditionString = this.$t(`accounts.edit.renewalOptions.equity.${condition}`);

                return !empty(this.vehicle.vehicle_value) && this.applyCBBValue
                    ? this.$t('clientCard.valueByCBB', [conditionString])
                    : '';
            },

            vehicleValue() {
                return this.vehicle.calculateVehicleValue();
            },

            vehicleVin() {
                return this.vehicle.vehicle_value ? this.vehicle.vehicle_value.vehicle_vin : null;
            },

            minMaxValues() {
                if (this.vehicle.vehicle_value.min_value &&
                    this.vehicle.vehicle_value.max_value &&
                    this.vehicleVin?.match == VehicleVinMatchType.PARTIAL
                ) {
                    return `${this.toMoney(this.vehicle.vehicle_value.min_value)} - ${this.toMoney(this.vehicle.vehicle_value.max_value)}`;
                }

                return null;
            },

            showMatchModal() {
                return [VehicleVinMatchType.PARTIAL, VehicleVinMatchType.MANUAL].includes(this.vehicleVin?.match);
            },

            applyCBBValue() {
                return !empty(this.vehicle.vehicle_value) && this.vehicle.apply_cbb_value;
            },
        },

        watch: {
            'vehicle.value'(newValue) {
                if (empty(newValue)) {
                    this.triggerResetCBBValue();
                }
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadVehicleAction', 'updateVinVehicleValue']),

            triggerResetCBBValue() {
                if (!empty(this.vehicle.vehicle_value) && !this.applyCBBValue) {
                    this.valueModal.resetCBBOpened = true;
                }
            },

            triggerMatchModal() {
                if (this.vehicle.apply_cbb_value) {
                    this.valueModal.matchModalOpened = true;
                }
            },

            triggerAllowManualValue() {
                if (this.minMaxValues) {
                    this.triggerMatchModal();
                    return;
                }

                if (!empty(this.vehicle.vehicle_value) && this.applyCBBValue) {
                    this.valueModal.changeOpened = true;
                }
            },

            closeModal() {
                this.valueModal.changeOpened = false;
                this.valueModal.matchModalOpened = false;
                this.valueModal.resetCBBOpened = false;
            },

            resetCBBValue() {
                this.updateLeadVehicleAction({ apply_cbb_value: true }, this.vehicle.id);
            },

            setCBBValue(selected) {
                this.updateVinVehicleValue({ uvc: selected, vehicleId: this.vehicle.id });
            },

            allowManualValue() {
                this.updateLeadVehicleAction({ apply_cbb_value: false }, this.vehicle.id);
            },
        },
    };
</script>
