<template>
    <activix-tooltip :content="tooltip">
        <follow-up-field
            follow-up="discounted"
            :date="lead.discounted_date"
            :disabled="disabled"
            :lead-id="lead.id"
            :remove-confirmation="false"
            @input="updateLead({ discounted_date: $event })"
        />
    </activix-tooltip>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import FollowUpField from '@/components/container/lead/input/FollowUpField.vue';

    import Lead from '@/entities/Lead.js';

    export default {
        components: {
            FollowUpField,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            disabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.lead.delivered_date && !this.lead.isRefinanced) {
                    return true;
                }

                if (!this.$can('leads.discounted_date:update')) {
                    return true;
                }

                return this.guest;
            },

            tooltip() {
                if (this.lead.hasInvalidStatus()) {
                    return this.$t('clientCard.leadCannotHaveStatus');
                }

                if (!this.lead.delivered_date && !this.lead.isRefinanced) {
                    return this.$t('clientCard.mustBeDelivered');
                }

                if (!this.lead.account.client_card_fields.process.discounted) {
                    return this.$t('clientCard.discountedMustBeActive');
                }

                return '';
            },
        },

        methods: {
            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
