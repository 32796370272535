<template>
    <div
        class="
            relative flex items-center justify-center h-12 w-12 rounded-full text-2xl
            border border-2 border-opacity-0 text-gray-600 bg-gray-200
        "
    >
        <icon :name="$icons.phone" />
        <communication-direction-icon :communication="communication" />
    </div>
</template>

<script>
    import CommunicationDirectionIcon from '@/components/container/lead/communications/CommunicationDirectionIcon.vue';

    import Communication from '@/entities/Communication.js';
    import Lead from '@/entities/Lead.js';
    import Service from '@/entities/Service.js';

    export default {
        components: {
            CommunicationDirectionIcon,
        },

        props: {
            communication: {
                required: true,
                type: Communication,
            },
            lead: {
                required: true,
                type: Lead,
            },
        },

        computed: {
            audioClasses() {
                if (this.lead.ivr && this.communication.service_id === Service.TELBOOST) {
                    return 'ivr';
                }

                return this.serviceName;
            },

            serviceName() {
                return Service.getKey(this.communication.service_id);
            },
        },
    };
</script>
