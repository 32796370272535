<template>
    <section class="flex-1 flex flex-col overflow-visible space-y-6 rounded-b-sm bg-white">
        <table-box>
            <t-header
                class="uppercase text-gray-600"
                :style="headerStyle"
                slot="header"
            >
                <th class="w-1/6 font-semibold" />
                <th class="w-1/2 font-semibold">
                    {{ $t('clientCard.boxes.commercialProducts.product') }}
                </th>
                <th class="w-1/3 font-semibold">
                    {{ $t('clientCard.boxes.commercialProducts.profit') }}
                </th>
            </t-header>

            <t-content slot="content">
                <commercial-products-table-row
                    :lead="lead"
                    :product="product"
                    :key="product.id"
                    v-for="product in filteredProducts"
                />
            </t-content>

            <t-footer slot="footer">
                <th class="font-semibold uppercase text-gray-600">
                    {{ $t('clientCard.total') }}
                </th>
                <th />
                <th class="font-semibold uppercase text-gray-600">
                    {{ totalPrice }}
                </th>
            </t-footer>
        </table-box>
    </section>
</template>

<script>
    import { mapState } from 'pinia';
    import { useLayoutStore } from '@/store/modules/layout/store';

    import { toCurrency } from '@/utils/numbers.js';

    import CommercialProductsTableRow from '@/components/container/lead/CommercialProductsTableRow';
    import TContent from '@/components/presentational/table/TContent';
    import TFooter from '@/components/presentational/table/TFooter';
    import THeader from '@/components/presentational/table/THeader';
    import TableBox from '@/components/presentational/table/TableBox';

    import Lead from '@/entities/Lead';

    export default {
        components: {
            CommercialProductsTableRow,
            TContent,
            TFooter,
            THeader,
            TableBox,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useLayoutStore, ['bodySpacingTop', 'headerHeight']),

            filteredProducts() {
                return this.lead.account.products
                    .filter(product => {
                        return product.type === 'commercial' && product.visible;
                    })
                    .sort((a, b) => {
                        return a.order > b.order;
                    });
            },

            headerStyle() {
                const top = this.headerHeight + this.bodySpacingTop;

                return {
                    top: `${top}px`,
                };
            },

            totalPrice() {
                let total = 0;

                for (const product of this.lead.products) {
                    if (product.type !== 'commercial' || !product.pivot?.price || !product.pivot?.sold) {
                        continue;
                    }

                    total += parseFloat(product.pivot.price);
                }

                return toCurrency(total);
            },
        },
    };
</script>
