<template>
    <div class="grid grid-cols-2 gap-3 -mx-6 p-6 border-t border-b border-gray-200 bg-gray-100">
        <div class="text-center" :class="{ 'col-span-2': !isContractualModality }">
            <label class="mb-0 text-gray-700 tracking-wider uppercase text-sm">
                {{ $t('clientCard.vehicles.payment') }}
            </label>

            <samp class="block font-sans tabular-nums" v-text="paymentDetails" />
            <samp class="block font-sans tabular-nums" v-text="contractDetails" v-if="isContractualModality" />
        </div>

        <div class="text-center" v-if="isContractualModality">
            <label class="mb-0 text-gray-700 tracking-wider uppercase text-sm">
                {{ $t('clientCard.boxes.vehicles.moreInfo') }}
            </label>

            <samp class="block font-sans tabular-nums" v-text="initialCash" />
            <activix-tooltip :content="endContractDate" v-if="vehicle.end_contract_date">
                <samp class="block font-sans tabular-nums" v-text="monthsRemaining" />
            </activix-tooltip>
        </div>
    </div>
</template>

<script>
    import { toCurrency } from '@/utils/numbers.js';

    import ActivixDate from '@/value-objects/ActivixDate.js';
    import LeadVehicle from '@/entities/LeadVehicle.js';
    import LeadVehicleFrequency from '@/entities/LeadVehicleFrequency.js';
    import LeadVehicleModality from '@/entities/LeadVehicleModality.js';

    export default {
        components: { },

        props: {
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        computed: {
            contractDetails() {
                if (!this.vehicle.term || this.vehicle.rate === null) {
                    if (this.missingPaymentData) {
                        return '';
                    }

                    return this.$t('clientCard.boxes.vehicles.contractWidget.missingContractInfo');
                }

                const modality = LeadVehicleModality.getTranslation(this.vehicle.modality);
                const term = `${this.vehicle.term} ${this.$tc('delayUnits.months', this.vehicle.term)}`;
                const rate = `${this.vehicle.rate} %`;

                return `${modality} ${term} @ ${rate}`;
            },

            endContractDate() {
                return new ActivixDate(this.vehicle.end_contract_date).toHumanShort(false);
            },

            initialCash() {
                if (!this.vehicle.initial_cash) {
                    return this.$t('clientCard.boxes.vehicles.contractWidget.noCashdown');
                }

                const initialCash = `${toCurrency(this.vehicle.initial_cash)}`;

                return `${initialCash} ${this.$t('clientCard.vehicles.initialCash').toLowerCase()}`;
            },

            isCashDeal() {
                return this.vehicle.modality === LeadVehicleModality.CASH;
            },

            isContractualModality() {
                return [LeadVehicleModality.FINANCING, LeadVehicleModality.LEASING].includes(this.vehicle.modality);
            },

            missingPaymentData() {
                return !this.vehicle.payment || !this.vehicle.frequency;
            },

            monthsRemaining() {
                const monthsRemaining = LeadVehicle.calculateMonthsRemaining(this.vehicle.end_contract_date);

                return `${monthsRemaining} ${this.$tc('clientCard.vehicles.monthsRemaining', monthsRemaining).toLowerCase()}`;
            },

            paymentDetails() {
                if (this.isCashDeal) {
                    if (!this.vehicle.totalPrice) {
                        return this.$t('clientCard.boxes.vehicles.contractWidget.missingPaymentInfo');
                    }

                    const amount = toCurrency(this.vehicle.totalPrice);

                    return this.$t('clientCard.boxes.vehicles.contractWidget.cashdeal', { amount });
                }

                if (this.missingPaymentData) {
                    return this.$t('clientCard.boxes.vehicles.contractWidget.missingPaymentInfo');
                }

                const payment = toCurrency(this.vehicle.payment);
                const frequency = LeadVehicleFrequency.getTranslation(this.vehicle.frequency);

                return `${payment} / ${frequency}`;
            },
        },
    };
</script>
