<template>
    <activix-tooltip :content="tooltip">
        <activix-checkbox
            :disabled="isDisabled"
            :value="value"
            size="lg"
            @input="onChange"
        />
    </activix-tooltip>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import Lead from '@/entities/Lead.js';

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            disabledTooltip: {
                type: String,
                default: '',
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            isDisabled() {
                if (this.disabled) {
                    return true;
                }

                if (this.authUser.hasAdvisorSkills()) {
                    return true;
                }

                if (!this.soldVehicle) {
                    return true;
                }

                if (!this.lead.isSoldOrRefinanced()) {
                    return true;
                }

                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                return this.guest;
            },

            soldVehicle() {
                return this.lead.getSoldVehicle();
            },

            tooltip() {
                if (this.disabled) {
                    return this.disabledTooltip;
                }

                if (this.authUser.hasAdvisorSkills()) {
                    return this.$t('clientCard.unauthorizedActionOnField');
                }

                if (!this.lead.isSoldOrRefinanced()) {
                    return this.$t('clientCard.leadRequirementForAction', [this.$t('clientCard.sold')]);
                }

                if (this.lead.hasInvalidStatus()) {
                    return this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.verified'),
                    ]);
                }

                return this.$t('accounts.edit.verifiedSaleTooltip');
            },

            value() {
                return !!this.soldVehicle?.verified_by_id;
            },
        },

        methods: {
            onChange(value) {
                const verifiedById = value ? this.authUser.id : null;

                this.updateVehicle({ verified_by_id: verifiedById });
            },

            updateVehicle(data, options = {}) {
                this.$emit('update-vehicle', {
                    data,
                    id: this.soldVehicle.id,
                    options,
                });
            },
        },
    };
</script>
