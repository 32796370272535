<template>
    <lead-input
        :disabled="disabled"
        :center-icon="centerIcon"
        :editing="editing"
        @accept="triggerAccept"
        @cancel="cancel"
        @edit="editField"
        @blur="onBlur"
    >
        <date-time-picker
            class="w-full | md:w-auto"
            :input-class="['input-sm', { 'p-0 border-none bg-transparent': lgLayout }]"
            :disabled="disabled"
            :end-date="endDate"
            :initial-focus="true"
            :required="required"
            :start-date="startDate"
            :start-view="startView"
            :date-only="dateOnly"
            :show-icon="false"
            v-model="date"
        />

        <template #value>
            <template v-if="dateFormatted">
                {{ dateFormatted }}
            </template>
            <template v-else>
                ...
            </template>
        </template>
    </lead-input>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    // Components
    import DateTimePicker from '../../inputs/DateTimePicker.vue';
    import LeadInput from '../LeadInput.vue';
    import { useGlobalStore } from '../../../store/store.js';
    import { useContextStore } from '../../../store/modules/context/store.js';

    export default {
        components: {
            DateTimePicker,
            LeadInput,
        },

        props: {
            dateOnly: {
                type: Boolean,
                default: false,
            },
            name: String,
            value: {
                type: String,
            },
            label: String,
            placeholder: String,
            disabled: Boolean,
            required: Boolean,
            startView: {
                type: Number,
                default: 2,
            },
            recordId: {
                type: Number,
                required: true,
            },
            vehicle: {
                type: Boolean,
                default: false,
            },
            enableEmpty: {
                type: Boolean,
                default: false,
            },
            centerIcon: {
                type: Boolean,
                default: true,
            },
            customField: {
                type: Object,
                default: null,
            },
        },

        data() {
            return {
                editing: false,
                date: '',
                originalDate: '',
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),

            validDate() {
                return !!this.date || this.enableEmpty;
            },

            startDate() {
                if (this.name == 'delivery_date' && this.contextLead.sale_date) {
                    return this.contextLead.sale_date;
                }

                return null;
            },

            endDate() {
                if (['presented_date', 'sale_date', 'delivered_date', 'last_presented_date'].includes(this.name)) {
                    return now().toString();
                }

                return null;
            },

            dateFunction() {
                return this.dateOnly ? this.localeDate : this.locale_dt;
            },

            dateFormatted() {
                return this.dateFunction(this.date).humanShort();
            },
        },

        watch: {
            date: {
                immediate: true,
                handler() {
                    this.originalDate = this.date;
                },
            },

            value(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                this.setDate(newValue);
            },

            editing(newValue) {
                if (!newValue) {
                    $(this.$refs.input).datetimepicker('hide');
                }
            },

            'contextLead.id'() {
                this.setDate();
            },

            recordId() {
                this.setDate();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'updateLeadVehicleAction']),

            editField() {
                if (this.disabled || (this.name == 'delivery_date' && this.contextLead.delivered)) {
                    return;
                }

                this.editing = true;
            },

            triggerAccept() {
                if (!this.editing) {
                    return;
                }

                if (!this.validDate) {
                    this.cancel();
                    return;
                }

                const date = this.dateFunction(this.date);

                if (date.year() === 0) {
                    this.date = date.addYears().toString();
                }

                this.accept();
            },

            async accept() {
                this.editing = false;

                // Only update if value changed and recordId is set
                if (!this.recordId || this.value == this.date || (!this.date && !this.value)) {
                    return;
                }

                let options = {};
                const payload = {
                    [this.name]: this.date,
                };

                if (this.customField) {
                    options = {
                        customField: { ...this.customField, value: this.date },
                    };
                }

                if (this.vehicle) {
                    await this.updateLeadVehicleAction(payload, this.recordId, this.leadId, options);
                } else {
                    await this.updateLeadAction(this.recordId, payload, options);
                }
            },

            cancel() {
                this.date = this.originalDate;
                this.editing = false;
            },

            onBlur() {
                // Delay is necessary to prevent event overlapping
                setTimeout(() => {
                    this.triggerAccept();
                }, 50);
            },

            setDate() {
                this.date = this.dateFunction(this.value).toString();
            },
        },

        created() {
            this.setDate();
        },
    };
</script>
