<template>
    <div>
        <div class="flex flex-wrap -mx-3">
            <lead-input-wrapper class="w-full xs:w-1/2">
                <lead-input-group v-if="fieldIsVisible('category')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.category') }}
                    </lead-input-label>
                    <lead-input-value>
                        <select-picker
                            name="category"
                            type="vehicle"
                            :disabled="guest || disabledBySaleVerified"
                            :options="LeadVehicleCategory.selectOptions('value', 'text')"
                            :record-id="vehicle.id"
                            :value="vehicle.category"
                        />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('category_rv')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.categoryRV') }}
                    </lead-input-label>
                    <lead-input-value>
                        <select-picker
                            name="category_rv"
                            type="vehicle"
                            :disabled="guest || disabledBySaleVerified"
                            :options="LeadVehicleRvCategory.selectOptions('value', 'text')"
                            :record-id="vehicle.id"
                            :value="vehicle.category_rv"
                        />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('category') && vehicle.category == 'mechanical'">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.mechanical') }}
                    </lead-input-label>
                    <lead-input-value>
                        <select-picker
                            name="mechanical"
                            type="vehicle"
                            :disabled="guest || disabledBySaleVerified"
                            :options="vehicleMechanicalCategories"
                            :record-id="vehicle.id"
                            :value="vehicle.mechanical"
                        />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('vin')">
                    <lead-input-label>
                        <div class="flex items-center">
                            {{ $t('clientCard.vehicles.vin') }}
                            <activix-tooltip :content="tooltipNiv" v-if="vehicleExportSupplier">
                                <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                            </activix-tooltip>
                        </div>
                    </lead-input-label>
                    <lead-input-value>
                        <div class="flex items-center">
                            <activix-tooltip :content="vehicleValueTooltip">
                                <input-text
                                    name="vin"
                                    :vehicle="true"
                                    :record-id="vehicle.id"
                                    :disabled="guest || disabledBySaleVerified || isExported || hasVehicleValue"
                                    :value="vehicle.vin"
                                />
                            </activix-tooltip>
                            <div
                                class="ml-2 flex-shrink-0 text-sm"
                                v-if="
                                    (contextLead.account.vin_decoder || contextLead.account.vin_manual_decoder) &&
                                        !hasVehicleValue
                                "
                            >
                                <activix-tooltip
                                    :content="$t('clientCard.vehicles.decodeVin')"
                                    v-if="vinMayBeDecoded && !$wait.is('decoding.vin')"
                                >
                                    <button class="link-blue" @click="decodeVin">
                                        <icon name="regular/search" />
                                    </button>
                                </activix-tooltip>

                                <icon
                                    :name="$icons.loading"
                                    class="spin-inverse text-sm text-grey-500"
                                    v-if="$wait.is('decoding.vin')"
                                />
                            </div>
                        </div>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group>
                    <lead-input-label>
                        <div class="flex items-center">
                            {{ $t('clientCard.vehicles.make') }}
                            <activix-tooltip :content="tooltipMatch" v-if="vehicleExportSupplier">
                                <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                            </activix-tooltip>
                        </div>
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="vehicleValueTooltip">
                            <input-text
                                name="make"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :disabled="guest || disabledBySaleVerified || isExported || hasVehicleValue"
                                :value="vehicle.make"
                                v-if="contextLead.account.vehicle_text"
                            />
                            <select-picker
                                name="make"
                                type="vehicle"
                                :searchable="true"
                                :disabled="guest || disabledBySaleVerified || isExported || hasVehicleValue"
                                :options="filteredMakes"
                                :record-id="vehicle.id"
                                :value="vehicle.make"
                                @update="onUpdateMake"
                                v-else
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group>
                    <lead-input-label>
                        <div class="flex items-center">
                            {{ $t('clientCard.vehicles.model') }}
                            <activix-tooltip :content="tooltipMatch" v-if="vehicleExportSupplier">
                                <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                            </activix-tooltip>
                            <activix-tooltip
                                :content="$t('export.honda.needModelTrimAlert')"
                                v-if="type == 'wanted' && needModelWithTrimAlert"
                            >
                                <icon class="text-red-500 text-xs ml-1" name="regular/information-circle" />
                            </activix-tooltip>
                        </div>
                    </lead-input-label>
                    <lead-input-value>
                        <div class="flex items-center">
                            <activix-tooltip :content="vehicleValueTooltip">
                                <input-text
                                    name="model"
                                    :vehicle="true"
                                    :record-id="vehicle.id"
                                    :disabled="guest || disabledBySaleVerified || isExported || hasVehicleValue"
                                    :value="vehicle.model"
                                    v-if="contextLead.account.vehicle_text || contextLead.account.vehicle_model_text"
                                />
                                <select-picker
                                    name="model"
                                    type="vehicle"
                                    :searchable="true"
                                    :disabled="guest || disabledBySaleVerified || isExported || hasVehicleValue"
                                    :options="filteredModels"
                                    :record-id="vehicle.id"
                                    :value="vehicle.model"
                                    v-else
                                />
                            </activix-tooltip>

                            <activix-tooltip :content="$t('clientCard.requestSource')" v-if="!!vehicle.url">
                                <a class="flex items-center text-lg ml-2" target="_blank" :href="vehicleLink">
                                    <icon name="regular/view-1" />
                                </a>
                            </activix-tooltip>
                        </div>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group>
                    <lead-input-label>
                        <div class="flex items-center">
                            {{ $t('clientCard.vehicles.year') }}
                            <activix-tooltip :content="tooltipMatch" v-if="vehicleExportSupplier">
                                <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                            </activix-tooltip>
                        </div>
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="vehicleValueTooltip">
                            <select-picker
                                name="year"
                                type="vehicle"
                                :disabled="guest || disabledBySaleVerified || isExported || hasVehicleValue"
                                :options="years"
                                :record-id="vehicle.id"
                                :value="vehicle.year"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group>
                    <lead-input-label>
                        <div class="flex items-baseline">
                            {{ $t('clientCard.vehicles.version') }}
                            <activix-tooltip
                                :content="$t('export.honda.needTrimAlert')"
                                v-if="type == 'wanted' && needTrimAlert"
                            >
                                <icon class="text-red-500 text-xs ml-1" name="regular/information-circle" />
                            </activix-tooltip>
                        </div>
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="vehicleValueTooltip">
                            <select-picker
                                name="trim"
                                type="vehicle"
                                label="display_name_fr"
                                :disabled="guest || disabledBySaleVerified || isExported || hasVehicleValue"
                                :options="trims"
                                :record-id="vehicle.id"
                                :value="vehicle.trim"
                                v-if="!empty(trims)"
                            />
                            <input-text
                                name="trim"
                                :vehicle="true"
                                :disabled="guest || disabledBySaleVerified || hasVehicleValue"
                                :record-id="vehicle.id"
                                :value="vehicle.trim"
                                :allow-zero-value="true"
                                v-else
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="showCertified">
                    <lead-input-label>
                        {{ $t('clientCard.certified') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="certifiedTooltip">
                            <div class="input-wrapper">
                                <checkbox
                                    name="certified"
                                    :vehicle="true"
                                    :record-id="vehicle.id"
                                    :value="vehicle.certified"
                                    :disabled="guest || disabledBySaleVerified"
                                />
                            </div>
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('transmission')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.transmission') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <select-picker
                                name="transmission"
                                type="vehicle"
                                :disabled="guest || disabledBySaleVerified"
                                :options="LeadVehicleTransmission.selectOptions('value', 'text')"
                                :record-id="vehicle.id"
                                :value="vehicle.transmission"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && isServiceLead">
                    <lead-input-label>
                        {{ $t('accounts.edit.licensePlate') }}
                    </lead-input-label>
                    <lead-input-value>
                        <input-text
                            name="license_plate"
                            :vehicle="true"
                            :disabled="guest"
                            :record-id="vehicle.id"
                            :value="vehicle.license_plate"
                            :max-length="9"
                        />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && isServiceLead">
                    <lead-input-label>
                        {{ $t('accounts.edit.recall') }}
                    </lead-input-label>
                    <lead-input-value>
                        <input-text
                            name="recall"
                            :vehicle="true"
                            :disabled="guest"
                            :record-id="vehicle.id"
                            :value="vehicle.recall"
                        />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('budget')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.budget_min') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="budget_min"
                                type="currency"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.budget_min"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('budget')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.budget_max') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="budget_max"
                                type="currency"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.budget_max"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('year')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.year_min') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <select-picker
                                name="year_min"
                                type="vehicle"
                                :disabled="guest || disabledBySaleVerified"
                                :options="years"
                                :record-id="vehicle.id"
                                :value="vehicle.year_min"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('year')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.year_max') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <select-picker
                                name="year_max"
                                type="vehicle"
                                :disabled="guest || disabledBySaleVerified"
                                :options="years"
                                :record-id="vehicle.id"
                                :value="vehicle.year_max"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('comment')">
                    <lead-input-label>
                        {{ $t('accounts.edit.comment') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <text-area
                                name="comment"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :disabled="guest || disabledBySaleVerified"
                                :value="vehicle.comment"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="showTireType">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.tire_type') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <select-picker
                                name="tire_type"
                                type="vehicle"
                                :disabled="guest || disabledBySaleVerified"
                                :multiple="true"
                                :hide-empty-option="true"
                                :options="LeadVehicleTireType.selectOptions('value', 'text')"
                                :record-id="vehicle.id"
                                :value="formatedTireType"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>
            </lead-input-wrapper>
            <lead-input-wrapper class="w-full xs:w-1/2">
                <lead-input-group v-if="fieldIsVisible('stock')">
                    <lead-input-label>
                        <div class="flex items-center">
                            {{ $t('clientCard.vehicles.stock') }}
                            <activix-tooltip
                                :content="$t('clientCard.vehicles.securityDeposit')"
                                v-if="contextLead.deposit"
                            >
                                <icon name="bold/currency-dollar" class="text-blue-500 ml-1 text-sm" />
                            </activix-tooltip>
                        </div>
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <div class="flex flex-col | md:flex-row">
                                <select-picker
                                    name="stock_state"
                                    type="vehicle"
                                    :disabled="guest || disabledBySaleVerified"
                                    :options="LeadVehicleStockState.selectOptions('value', 'text')"
                                    :record-id="vehicle.id"
                                    :value="vehicle.stock_state"
                                    :center-icon="false"
                                />
                                <input-text
                                    class="md:ml-2"
                                    name="stock"
                                    prefix="#"
                                    :vehicle="true"
                                    :record-id="vehicle.id"
                                    :disabled="guest || disabledBySaleVerified || empty(vehicle.stock_state)"
                                    :value="vehicle.stock"
                                    :center-icon="false"
                                />
                            </div>
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('engine')">
                    <lead-input-label>
                        <div class="flex items-baseline">
                            {{ $t('clientCard.vehicles.engine') }}
                        </div>
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="engine"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.engine"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('fuel')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.fuel') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <select-picker
                                name="fuel"
                                type="vehicle"
                                :disabled="guest || disabledBySaleVerified"
                                :options="LeadVehicleFuel.selectOptions('value', 'text')"
                                :record-id="vehicle.id"
                                :searchable="true"
                                :value="vehicle.fuel"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('color_exterior')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.exteriorColor') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="color_exterior"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.color_exterior"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('color_interior')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.interiorColor') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="color_interior"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.color_interior"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('bodyType')">
                    <lead-input-label>
                        <div class="flex items-baseline">
                            {{ $t('clientCard.vehicles.bodyType') }}
                        </div>
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="body_type"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.body_type"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('drivingWheels')">
                    <lead-input-label>
                        <div class="flex items-baseline">
                            {{ $t('clientCard.vehicles.drivingWheels') }}
                        </div>
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="driving_wheels"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.driving_wheels"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('weight')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.weight') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <select-picker
                                name="weight"
                                type="vehicle"
                                :disabled="guest || disabledBySaleVerified"
                                :record-id="vehicle.id"
                                :options="vehicleWeightOptions"
                                :value="vehicle.weight"
                                v-if="isLegacyVehicleWeight"
                            />

                            <input-text
                                type="numeric"
                                name="weight"
                                :vehicle="true"
                                :disabled="guest || disabledBySaleVerified"
                                :record-id="vehicle.id"
                                :value="vehicle.weight"
                                v-else
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('mileage')">
                    <lead-input-label>
                        <div class="flex items-center">
                            {{ $t('clientCard.vehicles.mileage') }}
                        </div>
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="mileage"
                                type="kilometers"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.mileage"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('length')">
                    <lead-input-label> {{ $t('clientCard.vehicles.length') }} min. </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="length_min"
                                type="meters"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.length_min"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('length')">
                    <lead-input-label> {{ $t('clientCard.vehicles.length') }} max. </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="length_max"
                                type="meters"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.length_max"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('sleeping')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.sleeping') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="sleeping"
                                type="numeric"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.sleeping"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange'">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.options') }}
                    </lead-input-label>
                    <lead-input-value>
                        <text-area
                            name="option"
                            :vehicle="true"
                            :record-id="vehicle.id"
                            :disabled="guest"
                            :value="vehicle.option"
                        />
                    </lead-input-value>
                </lead-input-group>
            </lead-input-wrapper>
        </div>

        <div class="flex flex-wrap -mx-3 md:mt-12" v-if="advanced1Visible">
            <lead-input-wrapper class="w-full xs:w-1/2">
                <lead-input-group v-if="fieldIsVisible('offer_number')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.offer_number') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="offer"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.offer"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'wanted' && fieldIsVisible('suffix')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.suffix') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="suffix"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.suffix"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'wanted' && fieldIsVisible('order_number')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.order_number') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="order_number"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.order_number"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'wanted' && fieldIsVisible('tire')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.tires') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <tire
                                :vehicle="vehicle"
                                :guest="guest"
                                :lead="contextLead"
                                :in-client-card="true"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && fieldIsVisible('condition')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.condition') }}
                    </lead-input-label>
                    <lead-input-value>
                        <select-picker
                            name="condition"
                            type="vehicle"
                            :disabled="guest"
                            :options="LeadVehicleCondition.selectOptions('value', 'text')"
                            :record-id="vehicle.id"
                            :value="vehicle.condition"
                        />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && fieldIsVisible('link')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.link') }}
                    </lead-input-label>
                    <lead-input-value>
                        <input-text
                            name="link"
                            type="currency"
                            :vehicle="true"
                            :record-id="vehicle.id"
                            :value="vehicle.link"
                            :disabled="guest"
                        />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group
                    v-if="type == 'exchange' && fieldIsVisible('accidented_damage_cost') && tradeReportEnabled"
                >
                    <lead-input-label>
                        <activix-tooltip :content="$t('clientCard.vehicles.accidentedTooltip')">
                            <span>{{ $t('clientCard.vehicles.accidented_damage_cost') }}</span>
                        </activix-tooltip>
                    </lead-input-label>
                    <lead-input-value>
                        <span class="cursor-pointer" @click="onAccidentdDamageCostClick">
                            {{ vehicle.accidented_damage_cost ? toMoney(vehicle.accidented_damage_cost, 2) : '...' }}
                        </span>
                    </lead-input-value>
                </lead-input-group>
            </lead-input-wrapper>
            <lead-input-wrapper class="w-full xs:w-1/2">
                <lead-input-group v-if="type == 'wanted' && fieldIsVisible('price')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.price') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="price"
                                type="currency"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.price"
                                :disabled="guest || disabledBySaleVerified || (contextLead.freeze_financial_data && contextLead.account.commercial)"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'wanted' && fieldIsVisible('accessories')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.accessories') }}
                    </lead-input-label>
                    <activix-tooltip :content="disabledTooltip">
                        <lead-input-value>
                            <input-text
                                name="accessories"
                                type="currency"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.accessories"
                                :disabled="guest || disabledBySaleVerified || (contextLead.freeze_financial_data && contextLead.account.commercial)"
                            />
                        </lead-input-value>
                    </activix-tooltip>
                </lead-input-group>

                <lead-input-group v-if="type == 'wanted' && fieldIsVisible('preparation')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.preparation') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="preparation"
                                type="currency"
                                :vehicle="true"
                                :disabled="guest || disabledBySaleVerified || (contextLead.freeze_financial_data && contextLead.account.commercial)"
                                :record-id="vehicle.id"
                                :value="vehicle.preparation"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'wanted' && fieldIsVisible('documentation')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.documentation') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="documentation"
                                type="currency"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :disabled="guest || disabledBySaleVerified || (contextLead.freeze_financial_data && contextLead.account.commercial)"
                                :value="vehicle.documentation"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'wanted'">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.options') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <text-area
                                name="option"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :disabled="guest || disabledBySaleVerified"
                                :value="vehicle.option"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'wanted' && fieldIsVisible('total')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.total') }}
                    </lead-input-label>

                    <lead-input-value class="text-blue-500 font-bold">
                        {{ toMoney(total, 2) }}
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && fieldIsVisible('requested')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.requested') }}
                    </lead-input-label>
                    <lead-input-value>
                        <input-text
                            name="requested"
                            type="currency"
                            :vehicle="true"
                            :record-id="vehicle.id"
                            :value="vehicle.requested"
                            :disabled="guest || (contextLead.freeze_financial_data && contextLead.account.commercial)"
                        />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && fieldIsVisible('value')">
                    <lead-input-label>
                        <activix-tooltip :content="valueTooltip">
                            <span>{{ $t('clientCard.vehicles.value') }}</span>
                        </activix-tooltip>
                    </lead-input-label>
                    <lead-input-value>
                        <value :vehicle="vehicle" />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && tradeReportEnabled">
                    <lead-input-label>
                        <activix-tooltip :content="$t('clientCard.vehicles.actualValueTooltip')">
                            <span>{{ $t('clientCard.vehicles.actual_value') }}</span>
                        </activix-tooltip>
                    </lead-input-label>
                    <lead-input-value>
                        <input-text
                            name="actual_value"
                            type="currency"
                            :vehicle="true"
                            :record-id="vehicle.id"
                            :value="vehicle.actual_value"
                            :disabled="guest"
                        />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && tradeReportEnabled">
                    <lead-input-label>
                        <activix-tooltip :content="tradeTypeTooltip">
                            <span>{{ $t('clientCard.vehicles.trade_type') }}</span>
                        </activix-tooltip>
                    </lead-input-label>
                    <lead-input-value>
                        <select-picker
                            name="trade_type"
                            type="vehicle"
                            :disabled="guest || empty(vehicle.value)"
                            :options="LeadVehicleTradeType.selectOptions('value', 'text')"
                            :record-id="vehicle.id"
                            :value="vehicle.trade_type"
                        />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && calculatorValueVisible">
                    <lead-input-label>
                        <div class="flex items-center">
                            {{ $t('clientCard.vehicles.estimated') }}
                            <activix-tooltip
                                :content="
                                    vehicle.calculator_value ?
                                        $t(`clientCard.vehicles.received`, [
                                            vehicle.calculator_appraiser,
                                            locale_dt(supplierContent.imported_on).humanShort(),
                                        ]) : $t('clientCard.vehicles.noEstimate')"
                            >
                                <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                            </activix-tooltip>
                        </div>
                    </lead-input-label>
                    <lead-input-value>
                        <input-text
                            name="estimated"
                            type="currency"
                            :vehicle="false"
                            :record-id="vehicle.id"
                            :value="vehicle.calculator_value"
                            :disabled="true"
                        />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && tradeReportEnabled">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.trade_notes') }}
                    </lead-input-label>
                    <lead-input-value>
                        <text-area
                            name="trade_notes"
                            :vehicle="true"
                            :record-id="vehicle.id"
                            :disabled="guest"
                            :value="vehicle.trade_notes"
                        />
                    </lead-input-value>
                </lead-input-group>
            </lead-input-wrapper>
        </div>

        <div class="flex flex-wrap -mx-3 md:mt-12" v-if="advanced2Visible">
            <lead-input-wrapper class="w-full xs:w-1/2">
                <lead-input-group v-if="contextLead.account.auto_renewal || fieldIsVisible('modality')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.modality') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <select-picker
                                name="modality"
                                type="vehicle"
                                :disabled="guest || disabledBySaleVerified"
                                :options="LeadVehicleModality.selectOptions('value', 'text')"
                                :record-id="vehicle.id"
                                :value="vehicle.modality"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('frequency')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.frequency') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <select-picker
                                name="frequency"
                                type="vehicle"
                                :disabled="guest || disabledBySaleVerified"
                                :options="LeadVehicleFrequency.selectOptions('value', 'text')"
                                :record-id="vehicle.id"
                                :value="vehicle.frequency"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('rate')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.rate') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="rate"
                                type="percent"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.rate"
                                :disabled="guest || disabledBySaleVerified"
                                :allow-zero-value="true"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && fieldIsVisible('price')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.price') }}
                    </lead-input-label>
                    <lead-input-value>
                        <input-text
                            name="price"
                            type="currency"
                            :vehicle="true"
                            :record-id="vehicle.id"
                            :value="vehicle.price"
                            :disabled="guest || (contextLead.freeze_financial_data && contextLead.account.commercial)"
                        />
                    </lead-input-value>
                </lead-input-group>
            </lead-input-wrapper>
            <lead-input-wrapper class="w-full xs:w-1/2">
                <lead-input-group v-if="fieldIsVisible('initial_cash')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.initialCash') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="initial_cash"
                                type="currency"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.initial_cash"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('term')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.term') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <select-picker
                                name="term"
                                type="vehicle"
                                :disabled="guest || disabledBySaleVerified"
                                :options="LeadVehicleTerm.selectOptions('value', 'text')"
                                :record-id="vehicle.id"
                                :value="vehicle.term"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('payment')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.payment') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="payment"
                                type="currency"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.payment"
                                :disabled="guest || disabledBySaleVerified || (contextLead.freeze_financial_data && contextLead.account.commercial)"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('payment_with_tax')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.paymentWithTax') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="payment_with_tax"
                                type="currency"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.payment_with_tax"
                                :disabled="guest || disabledBySaleVerified || (contextLead.freeze_financial_data && contextLead.account.commercial)"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('profit') && (shouldShowProfit || authUser.isAdmin())">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.profit') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="profit"
                                type="currency"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.profit"
                                :disabled="guest || disabledBySaleVerified || (contextLead.freeze_financial_data && contextLead.account.commercial)"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>
            </lead-input-wrapper>
        </div>

        <div class="flex flex-wrap -mx-3 md:mt-12" v-if="advanced3Visible">
            <lead-input-wrapper class="w-full xs:w-1/2">
                <lead-input-group v-if="contextLead.account.auto_renewal || fieldIsVisible('end_contract_date')">
                    <lead-input-label>
                        {{ $tc('clientCard.vehicles.monthsRemaining', monthsRemaining) }}
                    </lead-input-label>
                    <lead-input-value>
                        {{ monthsRemaining }}
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="contextLead.account.auto_renewal || fieldIsVisible('end_contract_date')">
                    <lead-input-label>
                        {{ $tc('clientCard.vehicles.paymentsRemaining', paymentsRemaining) }}
                    </lead-input-label>
                    <lead-input-value>
                        {{ paymentsRemaining }}
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('allowed_mileage')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.allowed_mileage') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="allowed_mileage"
                                type="kilometers"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.allowed_mileage"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && fieldIsVisible('institution')">
                    <lead-input-label>
                        {{ $t('clientCard.institution') }}
                    </lead-input-label>
                    <lead-input-value>
                        <select-picker
                            name="bank_id"
                            type="vehicle"
                            :disabled="guest"
                            :options="vehicleBankOptions"
                            :record-id="vehicle.id"
                            :value="vehicleBank"
                        />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && fieldIsVisible('intention')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.intention') }}
                    </lead-input-label>
                    <lead-input-value>
                        <select-picker
                            name="intention"
                            type="vehicle"
                            :disabled="guest"
                            :options="LeadVehicleIntention.selectOptions('value', 'text')"
                            :record-id="vehicle.id"
                            :value="vehicle.intention"
                        />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && fieldIsVisible('sold_by')">
                    <lead-input-label>
                        {{ $t('accounts.edit.soldBy') }}
                    </lead-input-label>
                    <lead-input-value>
                        <input-text
                            name="sold_by"
                            :vehicle="true"
                            :disabled="guest"
                            :record-id="vehicle.id"
                            :value="vehicle.sold_by"
                        />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && fieldIsVisible('sold_date')">
                    <lead-input-label>
                        {{ $t('accounts.edit.soldDate') }}
                    </lead-input-label>
                    <lead-input-value>
                        <input-text
                            name="sold_date"
                            :vehicle="true"
                            :disabled="guest"
                            :record-id="vehicle.id"
                            :value="vehicle.sold_date"
                        />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && isServiceLead">
                    <lead-input-label>
                        {{ $t('accounts.edit.purchaseDate') }}
                    </lead-input-label>
                    <lead-input-value>
                        <date-time-picker
                            name="purchase_date"
                            :auto-save="true"
                            :disabled="guest"
                            :enable-empty="true"
                            :record-id="vehicle.id"
                            :start-view="4"
                            :value="vehicle.purchase_date"
                            :vehicle="true"
                        />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('in_service_date')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.in_service_date') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <date-time-picker
                                name="in_service_date"
                                :auto-save="true"
                                :date-only="true"
                                :disabled="guest || disabledBySaleVerified"
                                :enable-empty="true"
                                :record-id="vehicle.id"
                                :start-view="4"
                                :vehicle="true"
                                :value="vehicle.in_service_date"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>
            </lead-input-wrapper>
            <lead-input-wrapper class="w-full xs:w-1/2">
                <lead-input-group v-if="contextLead.account.auto_renewal || fieldIsVisible('end_contract_date')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.contractEnd') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <date-time-picker
                                name="end_contract_date"
                                :auto-save="false"
                                :date-only="true"
                                :disabled="guest || disabledBySaleVerified"
                                :enable-empty="true"
                                :record-id="vehicle.id"
                                :start-view="4"
                                :value="vehicle.end_contract_date"
                                :vehicle="true"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('security_deposit')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.securityDeposit') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="security_deposit"
                                type="currency"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.security_deposit"
                                :disabled="guest || disabledBySaleVerified || (contextLead.freeze_financial_data && contextLead.account.commercial)"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('residual')">
                    <lead-input-label>
                        {{ $t('clientCard.vehicles.residual') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="residual"
                                type="currency"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.residual"
                                :disabled="guest || disabledBySaleVerified || (contextLead.freeze_financial_data && contextLead.account.commercial)"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="type == 'exchange' && fieldIsVisible('balance')">
                    <lead-input-label>
                        <activix-tooltip :content="$t('clientCard.vehicles.balanceTooltip')">
                            <span>{{ $t('clientCard.vehicles.balance') }}</span>
                        </activix-tooltip>
                    </lead-input-label>
                    <lead-input-value>
                        <balance :vehicle="vehicle" />
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="showEquity">
                    <lead-input-label>
                        <activix-tooltip :content="$t('clientCard.vehicles.equityTooltip')">
                            <span>{{ $t('clientCard.vehicles.equity') }}</span>
                        </activix-tooltip>
                    </lead-input-label>
                    <lead-input-value :class="{ 'text-green-500': equity > 0, 'text-red-500': equity < 0 }">
                        {{ toMoney(equity, 2) || 'N/A' }}
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="showWarranty">
                    <lead-input-label>
                        {{ $t('accounts.edit.warranty') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="warranty"
                                :vehicle="true"
                                :disabled="guest || disabledBySaleVerified"
                                :record-id="vehicle.id"
                                :value="vehicle.warranty"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="showWarranty">
                    <lead-input-label>
                        {{ $t('accounts.edit.extendedWarranty') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="extended_warranty"
                                :vehicle="true"
                                :disabled="guest || disabledBySaleVerified"
                                :record-id="vehicle.id"
                                :value="vehicle.extended_warranty"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('warranty_type')">
                    <lead-input-label>
                        <span>{{ $t('clientCard.vehicles.warranty_type') }}</span>
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="warrantyTypeDisableTooltip" v-if="!makeWarrantyOptions.length">
                            <select-picker
                                name="make_warranty_id"
                                type="vehicle"
                                :options="makeWarrantyOptions"
                                :disabled="guest || disabledBySaleVerified || !makeWarrantyOptions.length"
                                :record-id="vehicle.id"
                                :value="vehicle.make_warranty_id"
                            />
                        </activix-tooltip>
                        <activix-tooltip :content="warrantyTypeDisableTooltip" v-else>
                            <select-picker
                                name="make_warranty_id"
                                type="vehicle"
                                :options="makeWarrantyOptions"
                                :disabled="guest || disabledBySaleVerified || !makeWarrantyOptions.length"
                                :record-id="vehicle.id"
                                :value="vehicle.make_warranty_id"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('end_warranty_date')">
                    <lead-input-label>
                        {{ $t('accounts.edit.endWarrantyDate') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <date-time-picker
                                name="end_warranty_date"
                                :auto-save="true"
                                :date-only="true"
                                :disabled="guest || disabledBySaleVerified"
                                :enable-empty="true"
                                :record-id="vehicle.id"
                                :start-view="4"
                                :value="vehicle.end_warranty_date"
                                :vehicle="true"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('end_warranty_mileage')">
                    <lead-input-label>
                        {{ $t('accounts.edit.endWarrantyMileage') }}
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="end_warranty_mileage"
                                type="kilometers"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.end_warranty_mileage"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('end_extended_warranty_date')">
                    <lead-input-label>
                        <activix-tooltip :content="$t('accounts.edit.endExtendedWarrantyDateTooltip')">
                            <span>{{ $t('accounts.edit.endExtendedWarrantyDate') }}</span>
                        </activix-tooltip>
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <date-time-picker
                                name="end_extended_warranty_date"
                                :auto-save="true"
                                :date-only="true"
                                :disabled="guest || disabledBySaleVerified"
                                :enable-empty="true"
                                :record-id="vehicle.id"
                                :start-view="4"
                                :value="vehicle.end_extended_warranty_date"
                                :vehicle="true"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="fieldIsVisible('end_extended_warranty_mileage')">
                    <lead-input-label>
                        <activix-tooltip :content="$t('accounts.edit.endExtendedWarrantyMileageTooltip')">
                            <span>{{ $t('accounts.edit.endExtendedWarrantyMileage') }}</span>
                        </activix-tooltip>
                    </lead-input-label>
                    <lead-input-value>
                        <activix-tooltip :content="disabledTooltip">
                            <input-text
                                name="end_extended_warranty_mileage"
                                type="kilometers"
                                :vehicle="true"
                                :record-id="vehicle.id"
                                :value="vehicle.end_extended_warranty_mileage"
                                :disabled="guest || disabledBySaleVerified"
                            />
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>
            </lead-input-wrapper>
        </div>

        <div class="flex flex-wrap -mx-3 md:mt-12" v-if="haveCustomField">
            <lead-input-wrapper
                class="w-full xs:w-1/2"
                :section="customFieldType"
                position="left"
                :vehicle="vehicle"
            />

            <lead-input-wrapper
                class="w-full xs:w-1/2"
                :section="customFieldType"
                position="right"
                :vehicle="vehicle"
            />
            <lead-input-wrapper
                class="w-full"
                :section="customFieldType"
                display-type="box"
                :vehicle="vehicle"
            />
        </div>

        <vin-missing-data
            :opened.sync="vinDataModalOpened"
            :vehicle="vehicle"
            :trims="availableTrims"
            :transmissions="availableTransmissions"
            :exterior-colors="availableExteriorColors"
            :interior-colors="availableInteriorColors"
        />
        <accidented-vehicle :opened.sync="accidentedVehicleModalOpened" :vehicle="vehicle" />

        <activix-confirm-modal
            :opened.sync="warrantyChangeModelOpened"
            :title="$t('general.areYouSure')"
            :content="$t('modal.warrantyChangeAlert')"
            name="warrantyChange"
            @approve="updateWarrantyEndDate"
        />
    </div>
</template>

<script>
    /* eslint-disable vue/no-mutating-props */
    import { range } from 'lodash-es';
    import { mapActions, mapState, mapWritableState } from 'pinia';
    import { addSpaceBeetweenThreeNumber } from '../../utils/numbers.js';
    import { sort } from '../../utils/index.js';
    import ActivixDate from '../../value-objects/ActivixDate.js';

    // Components
    import AccidentedVehicle from '../modals/AccidentedVehicle.vue';
    import Balance from './Balance.vue';
    import Checkbox from './inputs/Checkbox.vue';
    import DateTimePicker from './inputs/DateTimePicker.vue';
    import InputText from './inputs/InputText.vue';
    import LeadInputGroup from './LeadInputGroup.vue';
    import LeadInputLabel from './LeadInputLabel.vue';
    import LeadInputValue from './LeadInputValue.vue';
    import LeadInputWrapper from './LeadInputWrapper.vue';
    import SelectPicker from './inputs/SelectPicker.vue';
    import TextArea from './inputs/TextArea.vue';
    import Tire from '../dashboards/columns/FollowUp/Tire.vue';
    import Value from './Value.vue';
    import VinMissingData from '../modals/VinMissingData.vue';

    // Entities
    import ProgressState from '../../entities/ProgressState.js';
    import Supplier from '../../entities/Supplier.js';
    import Division from '../../entities/Division.js';
    import LeadType from '../../entities/LeadType.js';
    import LeadVehicle from '../../entities/LeadVehicle.js';
    import LeadVehicleCategory from '../../entities/LeadVehicleCategory.js';
    import LeadVehicleCondition from '../../entities/LeadVehicleCondition.js';
    import LeadVehicleFrequency from '../../entities/LeadVehicleFrequency.js';
    import LeadVehicleFuel from '../../entities/LeadVehicleFuel.js';
    import LeadVehicleIntention from '../../entities/LeadVehicleIntention.js';
    import LeadVehicleModality from '../../entities/LeadVehicleModality.js';
    import LeadVehicleRvCategory from '../../entities/LeadVehicleRvCategory.js';
    import LeadVehicleStockState from '../../entities/LeadVehicleStockState.js';
    import LeadVehicleTerm from '../../entities/LeadVehicleTerm.js';
    import LeadVehicleTireType from '../../entities/LeadVehicleTireType.js';
    import LeadVehicleTradeType from '../../entities/LeadVehicleTradeType.js';
    import LeadVehicleTransmission from '../../entities/LeadVehicleTransmission.js';
    import LegacyLeadVehicleWeight from '../../entities/LegacyLeadVehicleWeight.js';
    import Moment from '../../value-objects/Moment.js';
    import ClientCardSelection from '../../entities/ClientCardSection.js';
    import LeadVehicleType from '../../entities/LeadVehicleType.js';

    // Pinia
    import { useClientCardStore } from '../../store/modules/clientCard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            AccidentedVehicle,
            Balance,
            Checkbox,
            DateTimePicker,
            InputText,
            LeadInputGroup,
            LeadInputLabel,
            LeadInputValue,
            LeadInputWrapper,
            SelectPicker,
            TextArea,
            Tire,
            Value,
            VinMissingData,
        },

        props: {
            vehicle: {
                type: Object,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
            hasExport: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                accidentedVehicleModalOpened: false,
                vinDataModalOpened: false,
                warrantyChangeModelOpened: false,
                availableTrims: [],
                availableTransmissions: [],
                availableExteriorColors: [],
                availableInteriorColors: [],
                vehicleModels: [],
                vehicleYears: [],
                vehicleTrims: [],
                noTrimOnModel: ['Element', 'Prelude', 'S2000'],
                makeWarranties: [],

                LeadVehicleCategory,
                Division,
                LeadVehicleCondition,
                LeadVehicleFrequency,
                LeadVehicleFuel,
                LeadVehicleIntention,
                LeadVehicleModality,
                LeadVehicleRvCategory,
                LeadVehicleStockState,
                LeadVehicleTerm,
                LeadVehicleTireType,
                LeadVehicleTradeType,
                LeadVehicleTransmission,
                LegacyLeadVehicleWeight,
                Supplier,
                terms: [
                    { value: '12' },
                    { value: '24' },
                    { value: '32' },
                    { value: '33' },
                    { value: '34' },
                    { value: '36' },
                    { value: '38' },
                    { value: '39' },
                    { value: '40' },
                    { value: '42' },
                    { value: '45' },
                    { value: '48' },
                    { value: '54' },
                    { value: '60' },
                    { value: '64' },
                    { value: '66' },
                    { value: '72' },
                    { value: '78' },
                    { value: '84' },
                    { value: '90' },
                    { value: '96' },
                    { value: '108' },
                    { value: '120' },
                ],
            };
        },

        computed: {
            ...mapState(useClientCardStore, ['leadBanks']),
            ...mapWritableState(useClientCardStore, ['vehicles', 'vehicleMakes']),
            ...mapState(useGlobalStore, ['authUser', 'guest', 'configs']),
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),

            customFieldType() {
                return this.type === LeadVehicleType.WANTED ? ClientCardSelection.VEHICLE_WANTED : ClientCardSelection.VEHICLE_EXCHANGE;
            },

            haveCustomField() {
                return this.contextAccount.getAllCustomFields().some(field => {
                    return field.visible && field.section === this.customFieldType;
                });
            },

            makeWarrantyOptions() {
                return this.makeWarranties.map(makeWarranty => {
                    return {
                        value: makeWarranty.id,
                        text: `${makeWarranty.mileage_max === 0 ? this.$t('delays.infinite') : addSpaceBeetweenThreeNumber(makeWarranty.mileage_max.toString())} KM, ${makeWarranty.coverage_months} ${this.$tc('delays.month', makeWarranty.coverage_months)}, ${this.$t(`clientCard.vehicles.${makeWarranty.type}Warranty`)}`,
                    };
                });
            },

            vehicleWeightOptions() {
                return Object.values(LegacyLeadVehicleWeight.entries).map(entry => ({ value: entry, text: entry }));
            },

            isLegacyVehicleWeight() {
                return Object.values(LegacyLeadVehicleWeight.entries).includes(this.vehicle.weight);
            },

            showCertified() {
                return this.fieldIsVisible('certified');
            },

            shouldShowProfit() {
                if (this.contextAccount.sale_table_options && !this.contextAccount.sale_table_options.hide_profit) {
                    return true;
                }

                if (this.authUser.isDirector() || this.authUser.isCommercialDirector()) {
                    return true;
                }

                if (this.authUser.isAdvisor() && this.authUser.id == this.contextLead.user_id) {
                    return true;
                }

                if (
                    this.authUser.id == this.contextLead.bdc_user_id &&
                    (this.authUser.isBdc() || this.authUser.isBdcDirector())
                ) {
                    return true;
                }

                return false;
            },

            vehicleMechanicalCategories() {
                const categories = [
                    { value: 'mower', text: this.$t('clientCard.vehicles.mower') },
                    { value: 'blower', text: this.$t('clientCard.vehicles.blower') },
                    { value: 'water_pump', text: this.$t('clientCard.vehicles.water_pump') },
                    { value: 'outside', text: this.$t('clientCard.vehicles.outside') },
                    { value: 'generator', text: this.$t('clientCard.vehicles.generator') },
                    { value: 'border_size', text: this.$t('clientCard.vehicles.border_size') },
                    { value: 'motoculteur', text: this.$t('clientCard.vehicles.motoculteur') },
                ];

                return sort(categories, 'text');
            },

            vehicleBankOptions() {
                const banks = [];

                for (const leadbank of this.leadBanks) {
                    banks.push(leadbank);
                }

                if (!empty(this.vehicle.imported_bank)) {
                    banks.push({
                        value: 'text-form',
                        text: `${this.vehicle.imported_bank}`,
                    });
                }

                return [{ value: '', text: '...' }].concat(banks);
            },

            vehicleBank() {
                if (this.vehicle.imported_bank && !this.vehicle.bank_id) {
                    return 'text-form';
                }

                return this.vehicle.bank_id;
            },

            formatedTireType() {
                if (this.vehicle.tire_type) {
                    return this.vehicle.tire_type.map(type => {
                        return { value: type, text: this.$t(`clientCard.vehicles.tireType.${type}`) };
                    });
                }

                return null;
            },

            monthsRemaining() {
                return LeadVehicle.calculateMonthsRemaining(this.vehicle.end_contract_date);
            },

            paymentsRemaining() {
                return this.vehicle.paymentRemaining();
            },

            equity() {
                return this.vehicle.calculateEquity();
            },

            total() {
                const price = !empty(this.vehicle.price) ? parseFloat(this.vehicle.price) : null;
                const preparation = !empty(this.vehicle.preparation) ? parseFloat(this.vehicle.preparation) : null;
                const accessories = !empty(this.vehicle.accessories) ? parseFloat(this.vehicle.accessories) : null;
                const documentation = !empty(this.vehicle.documentation)
                    ? parseFloat(this.vehicle.documentation)
                    : null;

                return price + preparation + accessories + documentation;
            },

            advanced1Visible() {
                return (
                    this.type == 'exchange' ||
                    (this.type == 'wanted' &&
                        (this.contextLead.account.client_card_fields.wanted_vehicle.price ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.accessories ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.preparation ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.documentation ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.total ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.offer_number ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.suffix ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.order_number ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.tire))
                );
            },

            advanced2Visible() {
                return (
                    (this.type == 'wanted' &&
                        (this.contextLead.account.client_card_fields.wanted_vehicle.modality ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.frequency ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.rate ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.initial_cash ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.term ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.payment ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.profit)) ||
                    (this.type == 'exchange' &&
                        (this.contextLead.account.client_card_fields.exchange_vehicle.modality ||
                            this.contextLead.account.client_card_fields.exchange_vehicle.frequency ||
                            this.contextLead.account.client_card_fields.exchange_vehicle.rate ||
                            this.contextLead.account.client_card_fields.exchange_vehicle.price ||
                            this.contextLead.account.client_card_fields.exchange_vehicle.initial_cash ||
                            this.contextLead.account.client_card_fields.exchange_vehicle.term ||
                            this.contextLead.account.client_card_fields.exchange_vehicle.payment ||
                            this.contextLead.account.client_card_fields.exchange_vehicle.warranty))
                );
            },

            advanced3Visible() {
                return (
                    (this.type == 'exchange' &&
                        (this.contextLead.account.client_card_fields.exchange_vehicle.end_contract_date ||
                            this.contextLead.account.client_card_fields.exchange_vehicle.allowed_mileage ||
                            this.contextLead.account.client_card_fields.exchange_vehicle.institution ||
                            this.contextLead.account.client_card_fields.exchange_vehicle.intention ||
                            this.contextLead.account.client_card_fields.exchange_vehicle.sold_by ||
                            this.contextLead.account.client_card_fields.exchange_vehicle.sold_date ||
                            this.contextLead.account.client_card_fields.exchange_vehicle.security_deposit ||
                            this.contextLead.account.client_card_fields.exchange_vehicle.residual ||
                            this.contextLead.account.client_card_fields.exchange_vehicle.balance ||
                            this.contextLead.account.client_card_fields.exchange_vehicle.warranty)) ||
                    (this.type == 'wanted' &&
                        (this.contextLead.account.client_card_fields.wanted_vehicle.end_contract_date ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.allowed_mileage ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.security_deposit ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.residual ||
                            this.contextLead.account.client_card_fields.wanted_vehicle.end_warranty_date ||
                            (this.contextLead.account.client_card_fields.wanted_vehicle.warranty &&
                                this.contextLead.lead_type_id == 5))) ||
                    this.isServiceLead
                );
            },

            isValueDisabled() {
                return (
                    this.guest ||
                    (this.contextLead.freeze_financial_data && this.contextLead.account.commercial) ||
                    (this.contextLead.account.trade_report && !this.authUser.trade_report && !this.authUser.isAdmin())
                );
            },

            valueTooltip() {
                return this.tradeReportEnabled ? this.$t('clientCard.vehicles.valueTooltip') : '';
            },

            tradeTypeTooltip() {
                return empty(this.vehicle.value)
                    ? this.$t('clientCard.vehicles.tradeTypeDisabledTooltip')
                    : this.$t('clientCard.vehicles.tradeTypeTooltip');
            },

            tradeReportEnabled() {
                return this.contextLead.account.trade_report && (this.authUser.trade_report || this.authUser.isAdmin());
            },

            isServiceLead() {
                return this.contextLead.division_id == 3;
            },

            hasSupplier() {
                if (!this.vehicle.hasOwnProperty('suppliers')) return false;

                if (this.vehicle.suppliers.length < 1) {
                    return false;
                }

                return true;
            },

            hasVehicleValue() {
                return !empty(this.vehicle.vehicle_value);
            },

            vehicleValueTooltip() {
                if (this.hasVehicleValue) {
                    return this.$t('clientCard.vehicleEvaluate');
                }

                if (this.disabledBySaleVerified) {
                    return this.$t('clientCard.verifiedSaleUneditable');
                }
                return '';
            },

            isExported() {
                if (!this.hasSupplier) return false;

                const supplier = this.vehicle.suppliers.find(suppliable => {
                    return [Supplier.VAUTO, Supplier.AUTOPROPULSION, Supplier.QUICKTRADE].includes(
                        suppliable.id,
                    );
                });

                if (supplier) {
                    if (supplier.pivot) {
                        return supplier.exported;
                    }
                    if (supplier.state) {
                        return supplier.state.exported;
                    }
                }

                return false;
            },

            isImported() {
                if (!this.hasSupplier) {
                    return false;
                }

                const supplier = this.vehicle.suppliers.find(suppliable => {
                    return [Supplier.VAUTO, Supplier.AUTOPROPULSION, Supplier.QUICKTRADE].includes(
                        suppliable.id,
                    );
                });

                if (supplier) {
                    if (supplier.pivot) {
                        return supplier.pivot.imported;
                    }
                    if (supplier.state) {
                        return supplier.state.imported;
                    }
                }

                return false;
            },

            disabledBySaleVerified() {
                return this.contextLead.disabledBySaleVerified(this.authUser, this.vehicle);
            },

            supplierContent() {
                if (!this.vehicle.hasOwnProperty('suppliers')) return null;

                if (!this.vehicle.suppliers.length) return null;

                const supplier = this.vehicle.suppliers.find(suppliable => {
                    return [Supplier.VAUTO, Supplier.AUTOPROPULSION, Supplier.QUICKTRADE].includes(
                        suppliable.id,
                    );
                });

                if (!supplier) {
                    return null;
                }

                return supplier.pivot || supplier.state;
            },

            vehicleExportSupplier() {
                if (!this.vehicle.hasOwnProperty('suppliers')) {
                    return null;
                }

                const suppliers = this.vehicle.suppliers.filter(suppliable => {
                    return [Supplier.VAUTO, Supplier.AUTOPROPULSION, Supplier.QUICKTRADE].includes(
                        suppliable.id,
                    );
                });

                if (!suppliers.length) {
                    return null;
                }

                return suppliers[0];
            },

            needModelWithTrimAlert() {
                if (this.vehicle.make != 'Honda') {
                    return false;
                }

                const suppliable = this.contextLead.suppliers.some(suppliable => {
                    return suppliable.id == Supplier.HONDA_CANADA && suppliable.related_id;
                });

                if (suppliable) {
                    return false;
                }

                const supplier = this.contextAccount.suppliers.some(supplier => {
                    return supplier.id == Supplier.HONDA_CANADA && supplier.outbound;
                });

                if (!supplier || this.noTrimOnModel.includes(this.vehicle.model)) {
                    return false;
                }

                return this.vehicleTrims.length == 0;
            },

            needTrimAlert() {
                if (this.vehicle.make != 'Honda' || this.needModelWithTrimAlert) {
                    return false;
                }

                const suppliable = this.contextLead.suppliers.some(suppliable => {
                    return suppliable.id == Supplier.HONDA_CANADA && suppliable.related_id;
                });

                if (suppliable) {
                    return false;
                }

                const supplier = this.contextAccount.suppliers.some(supplier => {
                    return supplier.id == Supplier.HONDA_CANADA && supplier.outbound;
                });

                if (!supplier || this.noTrimOnModel.includes(this.vehicle.model)) {
                    return false;
                }

                return !this.vehicle.trim;
            },

            supplierImportBody() {
                if (typeof this.supplierContent.import_response === 'object') {
                    return this.supplierContent.import_response;
                }

                return JSON.parse(this.supplierContent.import_response);
            },

            filteredMakes() {
                if (this.contextLead.account.vehicle_text) {
                    return [];
                }

                if (this.contextLead.account.power_sport) {
                    return this.vehicles.map(vehicle => {
                        return {
                            value: vehicle.make,
                            text: vehicle.make,
                        };
                    });
                }

                return this.vehicleMakes.map(make => {
                    return {
                        value: make.name,
                        text: make.name,
                    };
                });
            },

            filteredModels() {
                if (this.contextLead.account.vehicle_text || this.contextLead.account.vehicle_model_text) {
                    return [];
                }

                if (this.contextLead.account.power_sport) {
                    const make = this.vehicles.find(v => v.make == this.vehicle.make) || { models: [] };

                    const models = make.models.map(model => {
                        return {
                            value: model.model,
                            text: model.model,
                        };
                    });

                    return sort(models, 'text');
                }

                const displayLocal = this.configs.locale == 'fr' ? 'display_name_fr' : 'display_name_en';
                const models = this.vehicleModels.map(model => {
                    const modelName = model[displayLocal] || model.name;

                    return {
                        value: modelName,
                        text: modelName,
                    };
                });

                return sort(models, 'text');
            },

            trims() {
                const trims = this.vehicleTrims.map(trim => {
                    return {
                        value: trim.name,
                        text: trim.name,
                    };
                });

                return sort(trims, 'text');
            },

            years() {
                const fromYear = 1899; // fromYear is exclusive
                const toYear = Moment.now().year() + 3;
                const years = range(toYear, fromYear, -1);

                return years.map(year => {
                    return {
                        value: year,
                        text: year,
                        bold: this.vehicleYears.some(y => y.name == year),
                    };
                });
            },

            vinMayBeDecoded() {
                return this.vehicle.vin && !this.vehicle.vin_decoded;
            },

            showTireType() {
                return (
                    this.type == 'exchange' &&
                    this.contextLead.account.trade_report &&
                    this.contextLead.account.client_card_fields.exchange_vehicle.tire_type
                );
            },

            showEquity() {
                return (
                    this.type == 'exchange' &&
                    (this.fieldIsVisible('balance') ||
                        this.fieldIsVisible('residual') ||
                        this.fieldIsVisible('end_contract_date'))
                );
            },

            showWarranty() {
                return (
                    this.fieldIsVisible('warranty') &&
                    (this.type == 'exchange' || this.contextLead.lead_type_id == LeadType.RENEWAL)
                );
            },

            vehicleLink() {
                if (this.vehicle.url && this.vehicle.url.indexOf('http') > -1) {
                    return this.vehicle.url;
                }

                return `//${this.vehicle.url}`;
            },

            tooltipNiv() {
                if (!this.vehicleExportSupplier) {
                    return '';
                }

                return `${this.$t(`${this.vehicleExportSupplier.display_name}.tooltip.niv`)}`;
            },

            tooltipMatch() {
                if (this.disabledBySaleVerified) {
                    return this.$t('clientCard.verifiedSaleUneditable');
                }

                if (!this.vehicleExportSupplier) {
                    return '';
                }

                return `${this.$t(`${this.vehicleExportSupplier.display_name}.tooltip.match`)}`;
            },

            calculatorValueVisible() {
                return this.authUser.isAdmin() ||
                    this.authUser.isDirector() ||
                    this.authUser.isBdcDirector() ||
                    this.authUser.isCommercialDirector() ||
                    this.authUser.calculator_value_visible;
            },

            certifiedTooltip() {
                if (this.disabledBySaleVerified) {
                    return this.$t('clientCard.verifiedSaleUneditable');
                }

                return this.$t('clientCard.certifiedTooltip');
            },

            disabledTooltip() {
                if (this.disabledBySaleVerified) {
                    return this.$t('clientCard.verifiedSaleUneditable');
                }
                return '';
            },

            warrantyTypeDisableTooltip() {
                if (this.disabledBySaleVerified) {
                    return this.$t('clientCard.verifiedSaleUneditable');
                }
                return this.$t('accounts.edit.warrantyTypeDisableToolTip');
            },
        },

        watch: {
            'vehicle.modality'(newValue, oldValue) {
                if (newValue == oldValue || (empty(newValue) && empty(oldValue))) return;

                if (newValue == 'cash' && this.type == 'wanted') {
                    const leadData = {};

                    // TODO Confirm if the approbation autoset should only be done when not renewal (see above if)
                    if (this.contextLead.progress_state != ProgressState.CASH_DEAL) {
                        leadData.progress_state = ProgressState.CASH_DEAL;
                        leadData.lead_bank_id = null;
                    }

                    if (!empty(leadData)) {
                        this.updateLeadAction(this.contextLead.id, leadData);
                    }
                }
            },

            'vehicle.make'(newValue, oldValue) {
                if (newValue == oldValue || this.contextLead.account.power_sport) {
                    return;
                }

                this.triggerFetchVehicleModels();
                this.triggerFetchVehicleMakeWarranty();
            },

            'vehicle.model'(newValue, oldValue) {
                if (newValue == oldValue || this.contextLead.account.power_sport) {
                    return;
                }

                this.triggerFetchVehicleYears();
                this.triggerFetchVehicleTrims();
            },

            'vehicle.year'(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                this.triggerFetchVehicleMakeWarranty();
            },

            'vehicle.term'(newValue, oldValue) {
                if (this.vehicle.end_contract_date || !newValue) {
                    return;
                }

                if (newValue != oldValue && this.contextLead.delivered_date) {
                    const deliveredDate = as_locale(this.contextLead.delivered_date, 'delivered_date');
                    this.updateLeadVehicleAction(
                        { end_contract_date: deliveredDate.addMonths(newValue).toString() },
                        this.vehicle.id,
                    );
                }
            },

            'vehicle.vin'(newValue, oldValue) {
                if (!this.vehicle.vin_decoded || newValue == oldValue) {
                    return;
                }

                const payload = {
                    vin_decoded: false,
                };

                this.updateLeadVehicleAction(payload, this.vehicle.id);
            },

            'vehicle.end_contract_date'(newValue) {
                if (this.vehicle.term) {
                    return;
                }

                const endContractDate = as_locale(newValue, 'new_date');
                const deliveredDate = as_locale(this.contextLead.delivered_date, 'delivered_date');
                const diffInMonths = endContractDate.diffInMonths(deliveredDate);
                let i = 0;
                let termFind = false;

                if (diffInMonths > 0) {
                    for (i = 0; i < this.terms.length; i++) {
                        if (this.terms[i].value == diffInMonths) {
                            termFind = true;
                            break;
                        }
                    }

                    if (termFind) {
                        this.updateLeadVehicleAction({ term: diffInMonths }, this.vehicle.id);
                    }
                }
            },
            'vehicle.in_service_date'(newValue, oldValue) {
                if (newValue && oldValue && new ActivixDate(newValue).isSame(new ActivixDate(oldValue))) {
                    return;
                }

                if (newValue && this.vehicle.end_warranty_date && this.vehicle.make_warranty_id) {
                    this.warrantyChangeModelOpened = true;
                }
            },
            'vehicle.make_warranty_id'(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                if (newValue && this.vehicle.end_warranty_date && this.vehicle.in_service_date) {
                    this.warrantyChangeModelOpened = true;
                }
            },
        },

        methods: {
            ...mapActions(useClientCardStore, ['setVehicles', 'setVehicleMakes']),

            ...mapActions(useGlobalStore, [
                'updateLeadAction',
                'updateLeadVehicleAction',
                'fetchVehicleMakeWarranty',
                'fetchVehicleModels',
                'fetchVehicleYears',
                'fetchVehicleTrims',
                'decodeVehicleVin',
            ]),

            async triggerFetchVehicleMakeWarranty() {
                this.makeWarranties = [];

                if (this.vehicle.make && this.vehicle.year && !isNaN(this.vehicle.year)) {
                    this.makeWarranties = await this.fetchVehicleMakeWarranty(this.vehicle.make, this.vehicle.year);

                    if (this.vehicle.make_warranty_id) {
                        const makeWarranty = this.makeWarranties.find(makeWarranty => makeWarranty.id == this.vehicle.make_warranty_id);

                        if (!makeWarranty) {
                            this.updateLeadVehicleAction({ make_warranty_id: null }, this.vehicle.id);
                            this.vehicle.make_warranty_id = null;
                        }
                    }
                }
            },

            async triggerFetchVehicleModels() {
                const selectedMake = this.vehicleMakes.find(m => m.name == this.vehicle.make);

                if (!selectedMake) {
                    this.vehicleModels = [];
                    return;
                }

                this.vehicleModels = await this.fetchVehicleModels(selectedMake.id);
            },

            async triggerFetchVehicleYears() {
                const selectedModel = this.vehicleModels.find(m => m.name == this.vehicle.model);

                if (!selectedModel) {
                    this.vehicleYears = [];
                    return;
                }

                this.vehicleYears = await this.fetchVehicleYears(selectedModel.id);
            },

            async triggerFetchVehicleTrims() {
                const selectedModel = this.vehicleModels.find(m => {
                    return (
                        m.name == this.vehicle.model ||
                        m.display_name_fr == this.vehicle.model ||
                        m.display_name_en == this.vehicle.model
                    );
                });

                if (!selectedModel) {
                    this.vehicleTrims = [];
                    return;
                }

                this.vehicleTrims = await this.fetchVehicleTrims(selectedModel.id);
            },

            async decodeVin() {
                if (this.vehicle.vin_decoded) {
                    return;
                }

                this.$wait.start('decoding.vin');

                try {
                    const data = await this.decodeVehicleVin({
                        vin: this.vehicle.vin,
                        vehicleId: this.vehicle.id,
                    });

                    this.availableTrims = !empty(data.missingDataChoices.trims) ? data.missingDataChoices.trims : [];
                    this.availableExteriorColors = !empty(data.missingDataChoices.exteriorColors)
                        ? data.missingDataChoices.exteriorColors
                        : [];
                    this.availableInteriorColors = !empty(data.missingDataChoices.interiorColors)
                        ? data.missingDataChoices.interiorColors
                        : [];
                    this.availableTransmissions = LeadVehicleTransmission.selectOptions('value', 'text');

                    this.$wait.end('decoding.vin');

                    // Show modal only if some data is missing and choices are available
                    if (
                        !empty(this.availableTrims) ||
                        !empty(this.availableExteriorColors) ||
                        !empty(this.availableInteriorColors) ||
                        !empty(this.availableTransmissions)
                    ) {
                        this.vinDataModalOpened = true;
                    }
                } catch (error) {
                    this.$wait.end('decoding.vin');

                    this.$notify.warning(this.$t('clientCard.vehicles.vinDecodingFailed'));
                }
            },

            onAccidentdDamageCostClick() {
                this.accidentedVehicleModalOpened = true;
            },

            onUpdateMake() {
                this.vehicle.model = '';
            },

            fieldIsVisible(field) {
                return (
                    (this.type == 'wanted' && this.contextLead.account.client_card_fields.wanted_vehicle[field]) ||
                    (this.type == 'exchange' && this.contextLead.account.client_card_fields.exchange_vehicle[field])
                );
            },

            updateWarrantyEndDate() {
                const makeWarranty = this.makeWarranties.find(makeWarranty => makeWarranty.id == this.vehicle.make_warranty_id);
                let endWarrantyDate = null;
                let endWarrantyMileage = null;
                let endExtendedWarrantyDate = null;
                let endExtendedWarrantyMileage = null;

                if (makeWarranty.type == 'base') {
                    endWarrantyDate = new ActivixDate(this.vehicle.in_service_date).addMonths(makeWarranty.coverage_months).toString();
                    endWarrantyMileage = makeWarranty.mileage_max;

                    this.updateLeadVehicleAction({ end_warranty_date: endWarrantyDate, end_warranty_mileage: endWarrantyMileage, end_extended_warranty_date: endExtendedWarrantyDate, end_extended_warranty_mileage: endExtendedWarrantyMileage }, this.vehicle.id);
                }
                if (makeWarranty.type == 'extended') {
                    const makeWarrantyBase = this.makeWarranties.find(makeWarranty => makeWarranty.make == this.vehicle.make && makeWarranty.type === 'base');

                    endExtendedWarrantyDate = new ActivixDate(this.vehicle.in_service_date).addMonths(makeWarranty.coverage_months).toString();
                    endExtendedWarrantyMileage = makeWarranty.mileage_max;

                    endWarrantyDate = new ActivixDate(this.vehicle.in_service_date).addMonths(makeWarrantyBase.coverage_months).toString();
                    endWarrantyMileage = makeWarrantyBase.mileage_max;

                    this.updateLeadVehicleAction({ end_warranty_date: endWarrantyDate, end_warranty_mileage: endWarrantyMileage, end_extended_warranty_date: endExtendedWarrantyDate, end_extended_warranty_mileage: endExtendedWarrantyMileage }, this.vehicle.id);
                }
            },
        },

        async mounted() {
            this.setVehicles();
            await this.setVehicleMakes();
            await this.triggerFetchVehicleMakeWarranty();
            await this.triggerFetchVehicleModels();
            await this.triggerFetchVehicleYears();
            await this.triggerFetchVehicleTrims();
        },
    };
</script>
