<template>
    <div class="flex items-center justify-center">
        <div class="flex flex-row items-center">
            <div class="relative">
                <div
                    class="rounded-full shadow-md cursor-pointer bg-grey-500 flex items-center justify-center"
                    :class="mdLayout ? 'w-24 h-24' : 'w-32 h-32'"
                    @click="openPersonalInfoModal"
                >
                    <icon
                        class="text-white"
                        :class="{ 'flip-x': contextLead.isService() }"
                        :scale="mdLayout ? '3' : '4'"
                        :name="getLeadTypeIcon(contextLead)"
                    />
                </div>

                <lead-status
                    data-intercom-target="lead.status"
                    class="absolute mb-1 mr-1 bottom-0 right-0"
                    icon-class="text-2xl bg-white rounded-full border border-grey-200 shadow"
                    :lead="contextLead"
                />
            </div>

            <div class="flex flex-col ml-3 min-w-0 | md:ml-6">
                <div class="flex items-center justify-center | md:justify-start">
                    <h1
                        class="m-0 text-2xl font-semibold link-grey truncate | lg:max-w-xs"
                        data-intercom-target="lead.info"
                        @click="openPersonalInfoModal"
                    >
                        {{ civility }} {{ contextLead.fullName || $t('clientCard.unknown') }}
                    </h1>

                    <div class="flex items-center ml-3 space-x-2" v-if="!mdLayout">
                        <activix-tooltip :content="$t('clientCard.association') + ' / ' + $t('clientCard.fusion')">
                            <activix-base-button
                                :disabled="guest"
                                data-intercom-target="lead.association"
                                @click="manualCheck"
                            >
                                <icon name="regular/cog" class="text-lg" />
                            </activix-base-button>
                        </activix-tooltip>

                        <lead-export-dropdown class="space-x-2" data-intercom-target="lead.export" />
                    </div>
                </div>

                <result-time class="mt-3 justify-center | md:justify-start" :lead="contextLead" :side-by-side="true">
                    <template #right v-if="mdLayout">
                        <activix-base-button
                            class="mx-3"
                            data-intercom-target="lead.association"
                            :disabled="guest"
                            @click="manualCheck"
                        >
                            <icon name="regular/cog" class="text-xl" />
                        </activix-base-button>

                        <lead-export-dropdown class="space-x-3" data-intercom-target="lead.export" />
                    </template>
                </result-time>

                <lead-actions class="mt-4 justify-center | md:justify-start" />
            </div>

            <personal-info name="lead:personalInfo" />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    // Components
    import LeadActions from './LeadActions.vue';
    import LeadExportDropdown from './LeadExportDropdown.vue';
    import LeadStatus from '../dashboards/columns/LeadStatus.vue';
    import PersonalInfo from '../modals/PersonalInfo.vue';
    import ResultTime from './ResultTime.vue';

    // Entities
    import Locale from '../../entities/Locale.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useLeadStore } from '../../store/modules/lead/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            LeadActions,
            LeadExportDropdown,
            LeadStatus,
            PersonalInfo,
            ResultTime,
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest', 'bdcUsers', 'commercialUsers', 'serviceAgentUsers', 'serviceAdvisorUsers']),
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useLeadStore, ['mergeManualCheckDuplicate']),

            formatedAdvisorUsers() {
                return this.advisorUsers.map(user => {
                    return {
                        value: user.id,
                        text: this.getFullName(user),
                    };
                });
            },

            formatedBdcUsers() {
                return this.bdcUsers.map(user => {
                    return {
                        value: user.id,
                        text: this.getFullName(user),
                    };
                });
            },

            formatedCommercialUsers() {
                return this.commercialUsers.map(user => {
                    return {
                        value: user.id,
                        text: this.getFullName(user),
                    };
                });
            },

            formatedServiceAdvisors() {
                return this.serviceAdvisorUsers.map(user => {
                    return {
                        value: user.id,
                        text: this.getFullName(user),
                    };
                });
            },

            formatedServiceAgents() {
                return this.serviceAgentUsers.map(user => {
                    return {
                        value: user.id,
                        text: this.getFullName(user),
                    };
                });
            },

            outgoingCommunication() {
                const communications = this.contextLead.communications.filter(communication => {
                    return communication.communication_type_id == 1;
                });

                return !empty(communications);
            },

            civility() {
                if (this.contextLead.business) {
                    return '';
                }

                switch (this.contextLead.civility) {
                    case 'mr':
                        return this.$t('database.mr');

                    case 'ms':
                        return this.$t('database.ms');

                    case 'mrs':
                        return this.$t('database.mrs');

                    case 'miss':
                        return this.$t('database.miss');

                    case 'dr':
                        return this.$t('database.dr');

                    case 'me':
                        return this.$t('database.me');

                    default:
                        return this.contextLead.civility;
                }
            },

            locales() {
                const locales = Locale.values.map(locale => {
                    return {
                        value: locale,
                        text: this.$t(`database.${locale}`),
                    };
                });

                return locales;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            openPersonalInfoModal() {
                this.$modal.show('lead:personalInfo');
            },

            manualCheck() {
                if (!this.guest) {
                    useLeadStore().mergeManualCheckDuplicate = !this.mergeManualCheckDuplicate;
                }
            },
        },
    };
</script>
