<template>
    <box>
        <box-header slot="header">
            {{ $t('clientCard.boxes.serviceProducts.title') }}
        </box-header>

        <template slot="content">
            <service-products-widget :lead="contextLead" />

            <input-list class="p-6" :fields="fields" />

            <hr class="w-full h-px m-0 border-none bg-gray-200" />

            <box-content class="px-0">
                <service-products :lead="contextLead" />
            </box-content>
        </template>
    </box>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';
    import { useContextStore } from '@/store/modules/context/store';

    import Box from '@/components/presentational/boxes/Box';
    import BoxContent from '@/components/presentational/boxes/BoxContent';
    import BoxHeader from '@/components/presentational/boxes/BoxHeader';
    import InputList from '@/components/container/input/InputList';
    import InvisibleDateTimePicker from '@/components/container/input/InvisibleDateTimePicker';
    import InvisibleInput from '@/components/container/input/InvisibleInput';
    import ServiceProducts from '@/components/container/lead/ServiceProducts';
    import ServiceProductsWidget from '@/components/container/lead/widget/ServiceProductsWidget';

    export default {
        components: {
            Box,
            BoxContent,
            BoxHeader,
            InputList,
            ServiceProducts,
            ServiceProductsWidget,
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),
            ...mapState(useContextStore, ['contextLead']),

            fields() {
                return [
                    {
                        column: 1,
                        label: this.$t('clientCard.repairOrder'),
                        component: InvisibleInput,
                        props: {
                            disabled: this.guest,
                            value: this.contextLead.repair_order,
                        },
                        events: { input: value => this.updateLead({ repair_order: value }) },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.repairDate'),
                        component: InvisibleDateTimePicker,
                        props: {
                            dateOnly: true,
                            disabled: this.guest,
                            value: this.contextLead.repair_date,
                        },
                        events: { input: value => this.updateLead({ repair_date: value }) },
                    },
                    {
                        column: 1,
                        label: this.$t('clientCard.workOrder'),
                        labelTooltip: this.$t('clientCard.workOrderTooltip'),
                        component: InvisibleInput,
                        props: {
                            disabled: this.guest,
                            value: this.contextLead.work_order,
                        },
                        events: { input: value => this.updateLead({ work_order: value }) },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.openWorkOrderDate'),
                        labelTooltip: this.$t('clientCard.openWorkOrderDateTooltip'),
                        component: InvisibleDateTimePicker,
                        props: {
                            disabled: this.guest,
                            value: this.contextLead.open_work_order_date,
                        },
                        events: { input: value => this.updateLead({ open_work_order_date: value }) },
                    },
                    {
                        column: 1,
                        label: this.$t('clientCard.workOrderPartialClosureDate'),
                        component: InvisibleDateTimePicker,
                        props: {
                            disabled: this.guest,
                            value: this.contextLead.work_order_partial_closure_date,
                        },
                        events: { input: value => this.updateLead({ work_order_partial_closure_date: value }) },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.workOrderClosureDate'),
                        component: InvisibleDateTimePicker,
                        props: {
                            disabled: this.guest,
                            value: this.contextLead.work_order_closure_date,
                        },
                        events: { input: value => this.updateLead({ work_order_closure_date: value }) },
                    },
                ];
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            updateLead(data) {
                this.updateLeadAction(this.contextLead.id, data);
            },
        },
    };
</script>
