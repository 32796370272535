<template>
    <input-list :fields="fields" />
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';
    import { useClientCardStore } from '@/store/modules/clientCard/store';

    import ActivixCheckbox from '@/components/elements/ActivixCheckbox';
    import CsiInput from '@/components/container/input/lead/CsiInput';
    import DeliverableInput from '@/components/container/input/lead/DeliverableInput';
    import DeliveredInput from '@/components/container/input/lead/DeliveredInput';
    import DeliveryInput from '@/components/container/input/lead/DeliveryInput';
    import DiscountedInput from '@/components/container/input/lead/DiscountedInput';
    import InputList from '@/components/container/input/InputList';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect';
    import PaperworkInput from '@/components/container/input/lead/PaperworkInput';
    import PreparedInput from '@/components/container/input/lead/PreparedInput';
    import ProcessConsent from '@/components/dashboards/columns/FollowUp/Consent';
    import RecordedInput from '@/components/container/input/lead/RecordedInput';

    import Lead from '@/entities/Lead.js';
    import ProgressState from '@/entities/ProgressState';

    export default {
        components: {
            InputList,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['guest', 'authUser']),
            ...mapState(useClientCardStore, ['leadBanks']),

            fields() {
                return [
                    {
                        column: 1,
                        visible: this.lead.account.hasProcessField('progress_state'),
                        label: this.$t('clientCard.state'),
                        tooltip: this.approbationTooltip,
                        component: InvisibleMultiselect,
                        props: {
                            disabled: this.approbationDisabled,
                            nullable: true,
                            options: this.leadProcesses,
                            value: this.lead.progress_state,
                        },
                        events: { input: value => this.onLeadInput({ progress_state: value }) },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasProcessField('lead_bank'),
                        label: this.$t('clientCard.institution'),
                        tooltip: this.institutionTooltip,
                        component: InvisibleMultiselect,
                        props: {
                            disabled: this.institutionDisabled,
                            nullable: true,
                            options: this.leadBankOptions,
                            value: this.leadBank,
                        },
                        events: { input: value => this.onLeadInput({ lead_bank_id: value }) },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasProcessField('approved'),
                        label: this.$t('clientCard.approved'),
                        component: ActivixCheckbox,
                        props: {
                            disabled: this.approvedDisabled,
                            size: 'lg',
                            value: this.lead.approved,
                        },
                        events: { input: value => this.onLeadInput({ approved: value }) },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasProcessField('delivery'),
                        label: this.$t('clientCard.deliveryDate'),
                        component: DeliveryInput,
                        props: { lead: this.lead },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasProcessField('deliverable'),
                        label: this.$t('clientCard.deliverableDate'),
                        component: DeliverableInput,
                        props: { lead: this.lead },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasProcessField('paperwork'),
                        label: this.$t('clientCard.paperwork'),
                        component: PaperworkInput,
                        props: { lead: this.lead },
                        events: { 'update-lead': this.updateLead },
                    },

                    {
                        column: 2,
                        visible: this.lead.account.hasProcessField('prepared'),
                        label: this.$t('clientCard.prepared'),
                        component: PreparedInput,
                        props: {
                            class: 'flex items-center',
                            lead: this.lead,
                        },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 2,
                        visible: this.lead.account.hasProcessField('gas'),
                        label: this.$t('clientCard.gas'),
                        component: ActivixCheckbox,
                        props: {
                            disabled: this.gasDisabled,
                            size: 'lg',
                            value: this.lead.gas,
                        },
                        events: { input: value => this.onLeadInput({ gas: value }) },
                    },
                    {
                        column: 2,
                        visible: this.lead.account.hasProcessField('inspected'),
                        label: this.$t('clientCard.inspected'),
                        component: ActivixCheckbox,
                        props: {
                            disabled: this.inspectedDisabled,
                            size: 'lg',
                            value: this.lead.inspected,
                        },
                        events: { input: value => this.onLeadInput({ inspected: value }) },
                    },
                    {
                        column: 2,
                        visible: this.$feature.isEnabled('casl_compliance'),
                        label: this.$t('consent.consent'),
                        component: ProcessConsent,
                        props: {
                            checkboxSize: 'lg',
                            lead: this.lead,
                        },
                        events: {
                            click: () => {
                                this.$modal.show('consentConfig', {
                                    leadId: this.lead.id,
                                });
                            },
                        },
                    },
                    {
                        column: 2,
                        visible: this.lead.account.hasProcessField('recorded'),
                        label: this.$t('clientCard.boxes.financialAndDelivery.reportedOn'),
                        component: RecordedInput,
                        props: { lead: this.lead },
                        events: { 'update-vehicle': this.updateVehicle },
                    },
                    {
                        column: 2,
                        visible: this.lead.account.hasProcessField('delivered'),
                        component: DeliveredInput,
                        rawComponent: true,
                        props: { lead: this.lead },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 2,
                        visible: this.lead.account.hasProcessField('discounted'),
                        label: this.$tc('clientCard.discounted'),
                        component: DiscountedInput,
                        props: { lead: this.lead },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 2,
                        visible: this.lead.account.hasProcessField('csi'),
                        label: this.$t('clientCard.csi'),
                        component: CsiInput,
                        props: { lead: this.lead },
                        events: { 'update-lead': this.updateLead },
                    },
                ];
            },

            approbationDisabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                return (
                    this.guest ||
                    this.authUser.isAdvisor() ||
                    this.authUser.isBdc() ||
                    (this.lead.account.id === 35 && this.authUser.isBdcDirector())
                );
            },

            approbationTooltip() {
                if (this.lead.hasInvalidStatus()) {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.approbation'),
                    ])}`;
                }

                return '';
            },

            approvedDisabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.$can('leads.approved:update')) {
                    return true;
                }

                return this.guest;
            },

            gasDisabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.lead.sale_date && !this.lead.sale_by_phone) {
                    return true;
                }

                if (this.guest && !this.lead.hasDeliveryMan(this.authUser.id)) {
                    return this.guest;
                }

                if (!this.$can('leads.gas:update')) {
                    return true;
                }

                return this.guest && !this.lead.hasDeliveryMan(this.authUser.id);
            },

            inspectedDisabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.lead.sale_date && !this.lead.sale_by_phone) {
                    return true;
                }

                if (!this.$can('leads.inspected:update')) {
                    return true;
                }

                return this.guest;
            },

            institutionDisabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                return this.guest || this.authUser.isAdvisor() || this.authUser.isBdc();
            },

            institutionTooltip() {
                if (this.lead.hasInvalidStatus()) {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.institution'),
                    ])}`;
                }

                return '';
            },

            leadBank() {
                if (this.lead.imported_bank && !this.lead.lead_bank_id) {
                    return 'text-form';
                }

                return this.lead.lead_bank_id;
            },

            leadBankOptions() {
                const banks = [];

                for (const leadBank of this.leadBanks) {
                    banks.push(leadBank);
                }

                if (!empty(this.lead.imported_bank)) {
                    banks.push({
                        value: 'text-form',
                        text: `${this.lead.imported_bank}`,
                    });
                }

                return banks;
            },

            leadProcesses() {
                return ProgressState.values
                    .map(state => {
                        return {
                            value: state,
                            text: ProgressState.getTranslation(state),
                            disabled: state === ProgressState.REFUSED && (this.lead.delivered || this.lead.approved),
                        };
                    })
                    .sort((a, b) => a.text.localeCompare(b.text));
            },

        },
        methods: {
            onLeadInput(data) {
                this.updateLead({ data });
            },

            updateLead({ data, options = {} }) {
                this.$emit('update-lead', { data, options });
            },

            updateVehicle({ data, id = null, options = {} }) {
                this.$emit('update-vehicle', { data, id, options });
            },
        },
    };
</script>
