<template>
    <div>
        <div class="box | mb-6">
            <div class="box-header | flex items-center">
                <h4 class="box-title">
                    {{ $t('clientCard.process') }}
                </h4>

                <div class="progress | flex-1 h-1 bg-grey-300 mb-0 ml-6 mt-1 mr-4">
                    <div class="progress-bar progress-bar-primary" ref="progress" />
                </div>

                <icon
                    name="bold/check-1"
                    class="text-grey-300 text-sm"
                    :class="{ 'text-blue-500': progressPercent == 100 }"
                />
            </div>
            <div class="box-body | pt-0" :class="{ 'pb-2': mdLayout }">
                <div class="flex flex-wrap -mx-3">
                    <lead-input-wrapper class="w-full xs:w-1/2" section="processService" position="left">
                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('result.appointment') }}
                            </lead-input-label>
                            <lead-input-value class="truncate">
                                <appointment :in-client-card="true" :lead="contextLead" />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('result.presented') }}
                            </lead-input-label>
                            <lead-input-value>
                                <presented :in-client-card="true" :lead="contextLead" />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('clientCard.promisedDate') }}
                            </lead-input-label>
                            <lead-input-value>
                                <date-time-picker
                                    name="promised_date"
                                    :disabled="guest"
                                    :enable-empty="true"
                                    :record-id="contextLead.id"
                                    :value="contextLead.promised_date"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('clientCard.endServiceDate') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip
                                    :content="!contextLead.presented ? $t('clientCard.needPresentedChecked') : ''"
                                >
                                    <date-time-picker
                                        name="end_service_date"
                                        :disabled="guest || !contextLead.presented"
                                        :enable-empty="true"
                                        :record-id="contextLead.id"
                                        :value="contextLead.end_service_date"
                                    />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('clientCard.plannedPickUpDate') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip
                                    :content="!contextLead.presented ? $t('clientCard.needPresentedChecked') : ''"
                                >
                                    <date-time-picker
                                        name="planned_pick_up_date"
                                        :disabled="guest || !contextLead.presented"
                                        :enable-empty="true"
                                        :record-id="contextLead.id"
                                        :value="contextLead.planned_pick_up_date"
                                    />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>
                    </lead-input-wrapper>
                    <lead-input-wrapper class="w-full xs:w-1/2" section="processService" position="right">
                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('clientCard.services.reachedClient') }}
                            </lead-input-label>
                            <lead-input-value>
                                <reached-client :in-client-card="true" :lead="contextLead" />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('clientCard.services.inspection') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip
                                    :content="!contextLead.presented ? $t('clientCard.needPresentedChecked') : ''"
                                >
                                    <road-test :in-client-card="true" :lead="contextLead" />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('clientCard.services.cleaning') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip
                                    :content="!contextLead.presented ? $t('clientCard.needPresentedChecked') : ''"
                                >
                                    <cleaning :in-client-card="true" :lead="contextLead" />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('clientCard.invoiced') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip
                                    :content="!contextLead.presented ? $t('clientCard.needPresentedChecked') : ''"
                                >
                                    <checkbox
                                        name="invoiced"
                                        :record-id="contextLead.id"
                                        :value="contextLead.invoiced"
                                        :disabled="guest || !contextLead.presented"
                                    />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.csi">
                            <lead-input-label>
                                {{ $t('clientCard.csi') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip
                                    :content="!contextLead.invoiced ? $t('clientCard.needInvoicedChecked') : ''"
                                >
                                    <csi :in-client-card="true" :lead="contextLead" />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>
                    </lead-input-wrapper>
                    <lead-input-wrapper class="md:w-full" section="processService" display-type="box" />
                </div>

                <service-multiselect-text
                    class="mt-4"
                    name="intention"
                    multiselect-custom-labels="service.categories"
                    :multiselect-multiple="true"
                    :multiselect-options="intentions"
                    :multiselect-placeholder="$t('clientCard.services.intention')"
                    :multiselect-value="contextLead.service_process.intention.value"
                    :textinput-value="contextLead.service_process.intention.comment"
                />
                <service-multiselect-text
                    class="mt-4"
                    name="walk_around"
                    multiselect-custom-labels="clientCard.services.walkArounds"
                    :multiselect-multiple="true"
                    :multiselect-options="walkArounds"
                    :multiselect-placeholder="$t('clientCard.services.walkAround')"
                    :multiselect-value="contextLead.service_process.walk_around.value"
                    :textinput-value="contextLead.service_process.walk_around.comment"
                />
                <service-multiselect-text
                    class="mt-4"
                    name="transport"
                    multiselect-custom-labels="clientCard.services.transports"
                    :multiselect-options="transports"
                    :multiselect-placeholder="$t('clientCard.services.transport')"
                    :multiselect-value="contextLead.service_process.transport.value"
                    :textinput-value="contextLead.service_process.transport.comment"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';

    // Components
    import { filter, uniq, map, union } from 'lodash-es';
    import Appointment from '../dashboards/columns/FollowUp/Appointment.vue';
    import Checkbox from './inputs/Checkbox.vue';
    import Cleaning from '../dashboards/columns/FollowUp/ServiceCleaningCar.vue';
    import Csi from '../dashboards/columns/FollowUp/Csi.vue';
    import DateTimePicker from './inputs/DateTimePicker.vue';
    import LeadInputGroup from './LeadInputGroup.vue';
    import LeadInputLabel from './LeadInputLabel.vue';
    import LeadInputValue from './LeadInputValue.vue';
    import LeadInputWrapper from './LeadInputWrapper.vue';
    import Presented from '../dashboards/columns/FollowUp/Presented.vue';
    import ReachedClient from '../dashboards/columns/FollowUp/ReachedClient.vue';
    import RoadTest from '../dashboards/columns/FollowUp/RoadTest.vue';
    import ServiceMultiselectText from './inputs/ServiceMultiselectText.vue';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            Appointment,
            Checkbox,
            Cleaning,
            Csi,
            DateTimePicker,
            LeadInputGroup,
            LeadInputLabel,
            LeadInputValue,
            LeadInputWrapper,
            Presented,
            ReachedClient,
            RoadTest,
            ServiceMultiselectText,
        },

        data() {
            return {
                walkArounds: ['a1', 'scratch', 'brokenGlass', 'bump', 'cleaning', 'other'],
                transports: ['drop', 'wait', 'limousine', 'courtesy', 'renting', 'other'],
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useGlobalStore, ['guest']),

            progressPercent() {
                if (this.contextLead.invoiced) {
                    return 100;
                }

                const max = 4;
                let cnt = 0;

                cnt += this.contextLead.appointment_date ? 1 : 0;
                cnt += this.contextLead.presented_date ? 1 : 0;
                cnt += this.contextLead.planned_pick_up_date ? 1 : 0;
                // cnt += this.contextLead.reached_client ? 1 : 0;

                return (cnt / max) * 100;
            },

            intentions() {
                return union(uniq(map(
                    filter(this.contextLead.account.products, p => p.type === 'service'), p => p.category,
                )), ['problem']).sort();
            },
        },

        watch: {
            progressPercent(newValue) {
                this.updateProgress(newValue);
            },
        },

        methods: {
            updateProgress(value) {
                $(this.$refs.progress).animate({ width: `${value}%` }, 200);
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.updateProgress(this.progressPercent);
            });
        },
    };
</script>
