<template>
    <div class="space-y-6">
        <feature-preview-header
            :active-state="activeState"
            :feature-preview="featurePreview"
            @active-state-changed="$emit('active-state-changed', $event)"
            @open-feedback="modals.feedback.opened = true"
        />

        <feature-preview-body :feature-preview="featurePreview" />

        <feature-preview-footer
            :feature-preview="featurePreview"
            @open-feedback="modals.feedback.opened = true"
        />

        <feedback
            :additional-data="additionalData"
            :opened.sync="modals.feedback.opened"
            portal="modal-2"
            sentiment="neutral"
        />
    </div>
</template>

<script>
    import FeaturePreviewBody from '@/components/container/featurePreview/FeaturePreviewBody';
    import FeaturePreviewFooter from '@/components/container/featurePreview/FeaturePreviewFooter';
    import FeaturePreviewHeader from '@/components/container/featurePreview/FeaturePreviewHeader';
    import Feedback from '@/components/modals/Feedback';

    import FeaturePreview from '@/entities/FeaturePreview.js';

    export default {
        components: {
            FeaturePreviewBody,
            FeaturePreviewFooter,
            FeaturePreviewHeader,
            Feedback,
        },

        props: {
            activeState: {
                type: Boolean,
                required: true,
            },
            featurePreview: {
                type: FeaturePreview,
                required: true,
            },
        },

        data() {
            return {
                modals: {
                    feedback: {
                        opened: false,
                    },
                },
            };
        },

        computed: {
            additionalData() {
                return { featurePreview: this.featurePreview.key };
            },
        },
    };
</script>
