<template>
    <div>
        <activix-tooltip :content="tooltip">
            <checkbox-date-picker
                :checkbox-color="checkboxColor"
                :checkbox-disabled="isWalkIn"
                :date="date"
                :disabled="isDisabled"
                @check="onCheckboxClick"
                @date-click="onDateClick"
            />
        </activix-tooltip>

        <remove-follow-up
            :follow-up="followUp"
            :opened.sync="removeFollowUpModalOpened"
            @approve="onRemove"
            @closed="removeFollowUpModalOpened = false"
        />

        <activix-confirm-modal
            :content="$t('clientCard.addVisitWithoutAppointmentWarning')"
            :opened.sync="visitWarningOpened"
            @approve="openFollowUpModal"
        />
    </div>
</template>

<script>
    import CheckboxDatePicker from '@/components/container/input/CheckboxDatePicker.vue';
    import RemoveFollowUp from '@/components/modals/RemoveFollowUp.vue';

    import ActivixDate from '@/value-objects/ActivixDate.js';
    import Lead from '@/entities/Lead';
    import LeadType from '@/entities/LeadType.js';

    export default {
        components: { CheckboxDatePicker, RemoveFollowUp },

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            disabledTooltip: {
                type: String,
                default: '',
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        data() {
            return {
                removeFollowUpModalOpened: false,
                visitWarningOpened: false,
            };
        },

        computed: {
            date() {
                if (this.lead.presented_date) {
                    return this.lead.presented_date;
                }

                return this.lead.home_presented_date;
            },

            checkboxColor() {
                return this.isHomePresented ? 'purple' : 'blue';
            },

            dateField() {
                if (this.isHomePresented) {
                    return 'home_presented_date';
                }

                return 'presented_date';
            },

            followUp() {
                return this.isHomePresented ? 'home_presented' : 'presented';
            },

            isDisabled() {
                if (this.disabled) {
                    return true;
                }

                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (this.lead.be_back_date) {
                    return true;
                }

                if (this.lead.sale_date && !this.lead.sale_by_phone) {
                    return true;
                }

                return !this.$can('leads.presented_date:update');
            },

            isHomePresented() {
                return !!this.lead.home_presented_date;
            },

            isWalkIn() {
                return this.lead.isOfType(LeadType.WALK_IN) && !this.lead.isService();
            },

            showFollowUpModalFromUrl() {
                return (
                    !this.isDisabled &&
                    !this.lead.presented_date &&
                    !this.lead.delivered_date &&
                    !this.lead.sale_date &&
                    !empty(this.$route.query.date)
                );
            },

            tooltip() {
                if (this.disabled) {
                    return this.disabledTooltip;
                }

                if (this.lead.hasInvalidStatus()) {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.presented'),
                    ])}`;
                }

                return '';
            },
        },

        watch: {
            '$route.query': {
                immediate: true,
                handler() {
                    if (this.showFollowUpModalFromUrl) {
                        this.$modal.show('followUp', {
                            leadId: this.lead.id,
                            current: 'presented',
                            last: null,
                        });
                    }
                },
            },
        },

        methods: {
            onActivation() {
                const apptConfirmationDate = new ActivixDate(this.lead.appt_confirmation_date);

                if (
                    this.lead.isService() &&
                    !this.lead.presented_date &&
                    apptConfirmationDate.isToday()
                ) {
                    this.updateLead({
                        presented_date: apptConfirmationDate.toString(),
                    });
                } else {
                    this.triggerOpenFollowUpModal();
                }
            },

            onCheckboxClick(isChecked) {
                if (isChecked) {
                    this.onActivation();
                } else {
                    this.removeFollowUpModalOpened = true;
                }
            },

            onDateClick() {
                this.onActivation();
            },

            onRemove() {
                this.updateLead({
                    [this.dateField]: null,
                });
            },

            openFollowUpModal() {
                this.$modal.show('followUp', {
                    leadId: this.lead.id,
                    hideTrash: this.isWalkIn,
                    current: this.followUp,
                    last: this.lead.isService() ? 'appt_confirmation' : 'appointment',
                });
            },

            triggerOpenFollowUpModal() {
                if (
                    !this.isWalkIn &&
                    !this.lead.appointment_date &&
                    !this.lead.presented_date &&
                    !this.lead.home_presented_date &&
                    !this.lead.sale_by_phone
                ) {
                    this.visitWarningOpened = true;
                } else {
                    this.openFollowUpModal();
                }
            },

            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
