<template>
    <div class="row | mb-4 flex flex-wrap items-center">
        <div class="font-bold" :class="titleClasses">
            {{ $t(`clientCard.products.${product.name}`) }}
        </div>

        <div class="inline-flex justify-center" :class="checkboxClasses">
            <checkbox tabindex="-1" :disabled="disabled" v-model="sold" />
        </div>

        <div :class="defaultClasses" v-if="withNotes">
            <input
                type="text"
                class="form-control | text-center"
                maxlength="256"
                :disabled="disabled"
                :placeholder="$t('clientCard.services.note')"
                v-model="notes"
            />
        </div>

        <div :class="moneyClasses" v-if="withMoney">
            <activix-masked-input
                class="price | text-center"
                :integer-limit="8"
                :mask-type="moneyMaskType"
                :disabled="disabled"
                :placeholder="type === 'service' ? $t('clientCard.services.price') : $t('clientCard.services.fiProfit')"
                ref="priceInput"
                v-model="price"
            />
        </div>

        <div :class="defaultClasses" v-if="withMinutes">
            <activix-time-picker :value="time" :disabled="disabled" @input="updateTimeValue" />
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash-es';
    import { mapState, mapActions } from 'pinia';
    import { minutesToTime, timeToMinutes } from '../../utils/time.js';

    // Components
    import Checkbox from './inputs/Checkbox.vue';
    import { useGlobalStore } from '../../store/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            Checkbox,
        },

        props: {
            product: {
                type: Object,
                required: true,
            },
            withMoney: {
                type: Boolean,
                default: true,
            },
            withMinutes: {
                type: Boolean,
                default: false,
            },
            withNotes: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                default: 'commercial',
            },
        },

        data() {
            return {
                minutes: undefined,
                notes: undefined,
                sold: undefined,
                price: undefined,
                currentProduct: {},
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            disabled() {
                if (
                    this.type == 'commercial' &&
                    (this.authUser.role_id == 5 ||
                        this.authUser.role_id == 7 ||
                        this.contextLead.freeze_financial_data ||
                        this.authUser.hide_fi_profits)
                ) {
                    return true;
                }

                return this.guest;
            },

            time() {
                return minutesToTime(this.minutes);
            },

            moneyMaskType() {
                return this.authUser.hide_fi_profits ? 'text' : 'money';
            },

            titleClasses() {
                if (this.withMoney && this.withMinutes && this.withNotes) {
                    return 'col-xs-10 col-sm-3';
                }

                return 'col-xs-12 col-sm-6';
            },

            checkboxClasses() {
                if (this.withMoney && this.withMinutes && this.withNotes) {
                    return 'col-xs-2 col-sm-1';
                }

                return 'col-xs-2 col-sm-1';
            },

            moneyClasses() {
                if (this.withMoney && this.withMinutes && this.withNotes) {
                    return 'col-xs-12 col-sm-2';
                }

                return 'col-xs-10 col-sm-5';
            },

            defaultClasses() {
                if (this.withMoney && this.withMinutes && this.withNotes) {
                    return 'col-xs-12 col-sm-3';
                }

                return 'col-xs-10 col-sm-5';
            },
        },

        watch: {
            notes(newValue, oldValue) {
                if (typeof oldValue === 'undefined' || (empty(newValue) && empty(oldValue)) || newValue == oldValue) {
                    return;
                }

                this.updateProduct();
            },

            price(newValue, oldValue) {
                if (
                    typeof oldValue === 'undefined' ||
                    (empty(newValue) && empty(oldValue)) ||
                    newValue == oldValue ||
                    newValue == 'XX' ||
                    oldValue == 'XX'
                ) {
                    return;
                }

                if (this.price > 0 && !this.sold) {
                    this.sold = true;
                } else if (!this.price && this.sold) {
                    this.sold = false;
                }

                this.updateProduct();
            },

            minutes(newValue, oldValue) {
                if (typeof oldValue === 'undefined' || (empty(newValue) && empty(oldValue)) || newValue == oldValue) {
                    return;
                }

                if (!newValue) {
                    this.sold = false;
                }

                this.updateProduct();
            },

            sold(newValue, oldValue) {
                if (typeof oldValue === 'undefined' || newValue == oldValue) {
                    return;
                }

                this.updateProduct();
            },

            'contextLead.products'() {
                if (!this.$wait.is(`updating.product.${this.product.id}`)) {
                    this.init();
                }
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadProductAction']),

            updateTimeValue(time) {
                this.minutes = timeToMinutes(time);

                if (this.minutes > 0 && !this.sold) {
                    this.sold = true;
                }
            },

            updateProduct() {
                if (
                    this.disabled ||
                    this.$wait.is(`updating.product.${this.product.id}`) ||
                    typeof this.price === 'undefined'
                ) {
                    return;
                }

                this.triggerUpdateProduct();
            },

            async triggerUpdateProduct() {
                this.$wait.start(`updating.product.${this.product.id}`);

                // Only update if one of the value has changed
                await this.updateLeadProductAction({
                    id: this.product.id,
                    lead_id: this.contextLead.id,
                    name: this.product.name,
                    order: this.product.order,
                    type: this.product.type,
                    pivot: {
                        price: this.price,
                        minutes: this.minutes,
                        sold: this.sold,
                        notes: this.notes,
                        visible: this.product.visible,
                    },
                });

                this.$wait.end(`updating.product.${this.product.id}`);
            },

            init() {
                const leadProduct = this.contextLead.products.find(value => {
                    return value.id == this.product.id;
                });

                if (leadProduct) {
                    this.initValues(leadProduct.pivot);
                } else {
                    this.initValues({
                        price: this.product.default_price,
                        minutes: this.product.default_minutes,
                        notes: '',
                        sold: false,
                    });
                }
            },

            initValues(values) {
                // Update price
                if (this.type == 'commercial' && this.authUser.hide_fi_profits) {
                    this.price = 'XX';
                } else if (typeof values.price !== 'undefined' && this.price != values.price) {
                    this.price = values.price;
                } else if (values.price === null || typeof values.price === 'undefined') {
                    this.price = null;
                }

                // Update minutes
                this.minutes = typeof values.minutes !== 'undefined' ? values.minutes : '';

                // Update notes
                this.notes = typeof values.notes !== 'undefined' ? values.notes : '';

                // Update sold
                this.sold = typeof values.sold !== 'undefined' ? values.sold : false;
            },
        },

        created() {
            this.updateProduct = debounce(this.updateProduct, 400);
        },

        mounted() {
            this.init();
        },
    };
</script>
