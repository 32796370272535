import Enum from './Enum.js';

export default class ClientCardSection extends Enum {
    static get entries() {
        return {
            general: 'general',
            vehicles: 'vehicles',
            performances: 'performances',
            notes: 'notes',
            calendar: 'calendar',
        };
    }

    static get GENERAL() {
        return this.entries.general;
    }

    static get VEHICLES() {
        return this.entries.vehicles;
    }

    static get PERFORMANCES() {
        return this.entries.performances;
    }

    static get NOTES() {
        return this.entries.notes;
    }

    static get CALENDAR() {
        return this.entries.calendar;
    }
}
