<template>
    <div class="merge-management">
        <div class="box | mb-6" :class="{ loading: fetching || !currentMergeAction }">
            <div class="box-header | flex flex-col items-center justify-between | md:flex-row">
                <h4 class="box-title">
                    {{ $t('clientCard.correspondingLeads') }}
                </h4>

                <div class="btn-group mt-2 | md:mt-0">
                    <activix-button
                        size="small"
                        :class="{ active: currentMergeAction == 'associate' }"
                        @click="setLeadMergeAction('associate')"
                    >
                        {{ $t('clientCard.association') }}
                    </activix-button>

                    <activix-button
                        size="small"
                        :disabled="!authUser.custom_permissions.merge_lead"
                        :class="{ active: currentMergeAction == 'merge' }"
                        @click="
                            authUser.custom_permissions.merge_lead
                                ? setLeadMergeAction( 'merge')
                                : setLeadMergeAction( '')
                        "
                    >
                        {{ $t('clientCard.fusion') }}
                    </activix-button>
                </div>
            </div>
            <div class="box-body">
                <activix-spinner :size="40" v-if="fetching" />
                <duplicate-table
                    parent="merge"
                    :leads="leads"
                    :fetching="fetching"
                    :bulk-select-leads.sync="bulkSelectLeads"
                    v-show="!fetching"
                />

                <template v-if="!fetching">
                    <div class="text-center" v-if="mergeManualCheckDuplicate">
                        <activix-button :disabled="executing" @click="cancelManual">
                            {{ $t('general.cancel') }}
                        </activix-button>
                        <activix-button
                            class="ml-2"
                            type="primary"
                            :disabled="disabledAction"
                            @click="executeAction"
                            v-if="!empty(currentMergeAction)"
                        >
                            {{ currentMergeAction ? $t(`clientCard.${currentMergeAction}Lead`) : '' }}
                        </activix-button>
                    </div>
                    <div class="text-center" v-else>
                        <activix-button type="primary" :loading="!!currentMergeAction" @click="keepThisLead">
                            {{ $t('clientCard.keepThisLead') }}
                        </activix-button>
                    </div>
                </template>
            </div>
        </div>

        <action-direction
            :opened.sync="actionDirectionModalOpened"
            portal="modal-2"
            @canceled="executing = false"
            @merged="onMerged"
            @merging="executing = true"
        />
        <select-action
            :opened.sync="selectActionModalOpened"
            portal="modal-2"
            @canceled="cancelManual"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store.js';
    import { arraysEqual } from '../../utils/index.js';

    // Components
    import DuplicateTable from './DuplicateTable.vue';
    import ActionDirection from '../modals/ActionDirection.vue';
    import SelectAction from '../modals/SelectAction.vue';

    import Lead from '../../entities/Lead.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useLeadStore } from '../../store/modules/lead/store.js';
    import { useClientCardStore } from '../../store/modules/clientCard/store.js';

    export default {
        components: {
            DuplicateTable,
            ActionDirection,
            SelectAction,
        },

        data() {
            return {
                leads: [],
                bulkSelectLeads: [],
                fetching: false,
                executing: false,
                actionDirectionModalOpened: false,
                selectActionModalOpened: false,
            };
        },

        computed: {
            ...mapState(useClientCardStore, ['authorizedAction']),
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useLeadStore, ['mergeManualCheckDuplicate', 'currentMergeAction']),
            ...mapState(useGlobalStore, ['mergedLead', 'configs', 'authUser']),

            disabledAction() {
                if (
                    (this.bulkSelectLeads.length < 2 && this.currentMergeAction == 'merge') ||
                    (this.currentMergeAction == 'merge' &&
                        this.authUser.hasAdvisorSkills() &&
                        !this.authUser.custom_permissions.merge_lead) ||
                    (this.bulkSelectLeads.length < 1 && this.currentMergeAction == 'associate') ||
                    this.executing
                ) {
                    return true;
                }

                if (!this.contextLead.id) {
                    return true;
                }

                if (this.currentMergeAction == 'merge') {
                    return false;
                }

                let associatedLeads = [];

                if (!empty(this.contextLead.customer)) {
                    for (let i = 0; i < this.contextLead.customer.leads.length; i++) {
                        associatedLeads.push(this.contextLead.customer.leads[i].id);
                    }
                } else {
                    associatedLeads = [this.contextLead.id];
                }

                return arraysEqual(associatedLeads, this.bulkSelectLeads);
            },
        },

        watch: {
            'contextLead.id'() {
                this.searchName();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'appendNewError', 'setMergedLead']),

            setLeadMergeAction(mergeAction) {
                useLeadStore().currentMergeAction = mergeAction;
            },

            executeAction() {
                if (this.currentMergeAction == 'merge') {
                    // Set the otherLead variable
                    for (let i = 0; i < this.bulkSelectLeads.length; i++) {
                        if (this.bulkSelectLeads[i] == this.contextLead.id) {
                            continue;
                        }

                        for (let j = 0; j < this.leads.length; j++) {
                            if (this.leads[j].id == this.bulkSelectLeads[i]) {
                                this.setMergedLead(this.leads[j]);
                            }
                        }
                    }

                    this.actionDirectionModalOpened = true;
                } else if (this.currentMergeAction == 'associate') {
                    this.associateLeads();
                }
            },

            cancelManual() {
                if (!this.contextLead.import_validated) {
                    this.updateLeadAction(this.contextLead.id, { import_validated: true });
                }

                this.setLeadMergeAction('');
                useLeadStore().mergeManualCheckDuplicate = false;
            },

            async mergeLeads(from, to, action) {
                this.executing = true;

                await this.$api.leads
                    .merge(
                        { id: from.id },
                        {
                            lang: this.$i18n.locale,
                            oldLeadId: to.id,
                            rule: action,
                        },
                    )
                    .then(response => {
                        if (response.status == 200 && response.data.success) {
                            if (to.id == this.contextLead.id) {
                                window.location.reload();
                            } else {
                                this.$router.replace({
                                    name: 'leads.update',
                                    params: { id: to.id },
                                });
                            }
                        } else {
                            this.appendNewError({
                                code: '0009',
                                display: true,
                                response,
                                request: {
                                    lang: this.$i18n.locale,
                                    oldLeadId: to.id,
                                    rule: action,
                                },
                            });
                            this.executing = false;
                            this.setLeadMergeAction('');
                        }
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 403) {
                            return;
                        }

                        this.appendNewError({
                            code: '0010',
                            display: true,
                            error,
                            request: {
                                lang: this.$i18n.locale,
                                oldLeadId: to.id,
                                rule: action,
                            },
                        });
                    });
            },

            associateLeads() {
                this.executing = true;

                this.$api.leads
                    .associate(
                        { id: this.contextLead.id },
                        {
                            lang: this.$i18n.locale,
                            associatedIds: this.bulkSelectLeads,
                            dissociation: true,
                        },
                    )
                    .then(response => {
                        if (response.status == 200) {
                            window.location.reload();
                        } else {
                            this.appendNewError({
                                code: '0011',
                                display: true,
                                response,
                                request: {
                                    lang: this.$i18n.locale,
                                    associatedIds: this.bulkSelectLeads,
                                    dissociation: true,
                                },
                            });
                            this.executing = false;
                        }
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 403) {
                            return;
                        }

                        this.appendNewError({
                            code: '0012',
                            display: true,
                            error,
                            request: {
                                lang: this.$i18n.locale,
                                associatedIds: this.bulkSelectLeads,
                                dissociation: true,
                            },
                        });
                    });
            },

            searchName() {
                if (!this.contextLead.id) {
                    return;
                }

                this.leads = [];
                this.fetching = true;

                const payload = {
                    name: this.getFullName(this.contextLead),
                    accountId: this.contextLead.account_id,
                    currentLeadId: this.contextLead.id,
                    type: 'name',
                };

                this.$axios
                    .get('v1/search', { params: payload })
                    .then(response => {
                        this.addLeads(response.data.data);

                        this.$nextTick(() => {
                            this.searchEmail();
                        });
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 403) {
                            return;
                        }

                        this.appendNewError({
                            code: '0104',
                            display: false,
                            error,
                            payload,
                        });

                        throw error;
                    });
            },

            searchEmail() {
                if (!this.contextLead.id) {
                    return;
                }

                if (empty(this.contextLead.lead_emails)) {
                    this.searchPhone();
                    return;
                }

                for (let i = 0; i < this.contextLead.lead_emails.length; i++) {
                    if (empty(this.contextLead.lead_emails[i].email)) {
                        if (i == this.contextLead.lead_emails.length - 1) {
                            this.$nextTick(() => {
                                this.searchPhone();
                            });
                        }

                        continue;
                    }

                    const payload = {
                        email: this.contextLead.lead_emails[i].email,
                        accountId: this.contextLead.account_id,
                        currentLeadId: this.contextLead.id,
                        type: 'email',
                    };

                    this.$axios
                        .get('v1/search', { params: payload })
                        .then(response => {
                            this.addLeads(response.data.data);

                            if (i == this.contextLead.lead_emails.length - 1) {
                                this.$nextTick(() => {
                                    this.searchPhone();
                                });
                            }
                        })
                        .catch(error => {
                            if (error.response && error.response.status === 403) {
                                return;
                            }

                            this.appendNewError({
                                code: '0013',
                                display: false,
                                error,
                                payload,
                            });

                            throw error;
                        });
                }
            },

            searchPhone() {
                if (!this.contextLead.id) {
                    return;
                }

                if (empty(this.contextLead.lead_phones)) {
                    this.showDuplicate();
                    return;
                }

                for (let i = 0; i < this.contextLead.lead_phones.length; i++) {
                    if (empty(this.contextLead.lead_phones[i].phone)) {
                        if (i == this.contextLead.lead_phones.length - 1) {
                            this.$nextTick(() => {
                                this.showDuplicate();
                            });
                        }

                        continue;
                    }

                    const payload = {
                        phone: this.contextLead.lead_phones[i].phone,
                        accountId: this.contextLead.account_id,
                        currentLeadId: this.contextLead.id,
                        type: 'phone',
                    };

                    this.$axios
                        .get('v1/search', { params: payload })
                        .then(response => {
                            this.addLeads(response.data.data);

                            if (i == this.contextLead.lead_phones.length - 1) {
                                this.$nextTick(() => {
                                    this.showDuplicate();
                                });
                            }
                        })
                        .catch(error => {
                            if (error.response && error.response.status === 403) {
                                return;
                            }

                            this.appendNewError({
                                code: '0014',
                                display: false,
                                error,
                                payload,
                            });

                            throw error;
                        });
                }
            },

            addLeads(leads) {
                leads.forEach(lead => {
                    if (this.leads.some(l => l.id == lead.id)) {
                        return;
                    }

                    this.leads.push(new Lead(lead));
                });
            },

            showDuplicate() {
                this.loadTable();

                if (!this.authUser.custom_permissions.merge_lead && empty(this.currentMergeAction)) {
                    this.setLeadMergeAction('associate');
                } else if (this.authUser.custom_permissions.merge_lead && empty(this.currentMergeAction)) {
                    this.openSelectActionModal();
                }
            },

            keepThisLead() {
                this.updateLeadAction(this.contextLead.id, { import_validated: true });

                this.fetching = false;
            },

            loadTable() {
                this.$nextTick(() => {
                    this.fetching = false;

                    $(window).resize();
                });
            },

            openSelectActionModal() {
                this.selectActionModalOpened = true;
            },

            onMerged() {
                this.$emit('merged');

                this.executing = false;
            },
        },

        mounted() {
            this.searchName();
        },
    };
</script>
