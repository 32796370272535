<template>
    <div class="inline-flex items-center" v-if="outboundSuppliers.length > 0">
        <activix-tooltip :content="tooltip">
            <icon
                name="regular/cloud-upload"
                class="link-grey | text-lg"
                :class="{ 'cursor-not-allowed': disabled }"
                :disabled="exporting"
                @click="send"
            />
        </activix-tooltip>
        <activix-tooltip :content="statusTooltip" v-if="statusIsReceived">
            <icon name="bold/check-1" class="ml-2 text-xs text-blue-500" />
        </activix-tooltip>
        <activix-tooltip :content="statusTooltip" v-if="statusIsWaiting">
            <icon name="bold/navigation-menu-horizontal" class="ml-2 text-sm text-grey-600" />
        </activix-tooltip>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    // Entities
    import Supplier from '../../entities/Supplier.js';
    import SuppliableStatus from '../../entities/SuppliableStatus.js';
    import VAutoResponse from '../../entities/VAutoResponse.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        props: {
            vehicle: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                exporting: false,
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),
            ...mapState(useGlobalStore, ['guest']),

            disabled() {
                return (
                    !this.hasMandatoryExportFields || !this.hasValidExportFields || this.exporting || this.isExported
                );
            },

            outboundSuppliers() {
                const exportableSuppliers = [Supplier.VAUTO, Supplier.AUTOPROPULSION, Supplier.QUICKTRADE];

                return this.contextAccount.suppliers.filter(supplier => {
                    return exportableSuppliers.includes(supplier.id) && supplier.outbound;
                });
            },

            isExported() {
                return this.supplierData.exported;
            },

            isImported() {
                return this.supplierData.imported;
            },

            tooltip() {
                if (!this.hasMandatoryExportFields) {
                    if (this.supplierIsAutopropulsion) {
                        return this.$t('suppliers.tooltip.mandatoryWithOdometer');
                    }

                    if (this.supplierIsQuickTrade) {
                        return this.$t('suppliers.tooltip.mandatoryOnlyVin');
                    }

                    return this.$t('suppliers.tooltip.mandatory');
                }

                if (!this.hasValidValueProfit) {
                    return this.$t('suppliers.tooltip.valuesCannotBeNegatives');
                }

                if (!this.hasValidVin) {
                    return this.$t('suppliers.tooltip.validVin');
                }

                if (this.isExported || this.isImported || this.exporting) {
                    return this.$t('suppliers.tooltip.doublesubmit');
                }

                if (this.outboundSuppliers) {
                    const supplier = this.outboundSuppliers[0];

                    return `${this.$t('clientCard.exportTo')} ${this.supplierName(supplier)}`;
                }

                return '';
            },

            hasMandatoryExportFields() {
                if (this.supplierIsQuickTrade && this.vehicle.vin) {
                    return true;
                }

                if (!this.vehicle.vin || !this.vehicle.make || !this.vehicle.model || !this.vehicle.year) {
                    return false;
                }

                if (this.supplierIsAutopropulsion && !this.vehicle.mileage) {
                    return false;
                }

                return true;
            },

            hasValidExportFields() {
                if (!this.hasValidValueProfit || !this.hasValidVin) {
                    return false;
                }

                return true;
            },

            hasValidValueProfit() {
                if (this.supplierIsVAuto && (this.vehicle.value < 0 || this.vehicle.profit < 0)) {
                    return false;
                }

                return true;
            },

            hasValidVin() {
                return this.vehicle.vin.length === 17;
            },

            supplier() {
                return this.vehicle.suppliers.find(supplier => {
                    return (
                        supplier.id == Supplier.VAUTO ||
                        supplier.id == Supplier.AUTOPROPULSION ||
                        supplier.id == Supplier.QUICKTRADE
                    );
                });
            },

            supplierIsAutopropulsion() {
                return this.outboundSuppliers.find(supplier => supplier.id == Supplier.AUTOPROPULSION);
            },

            supplierIsVAuto() {
                return this.outboundSuppliers.find(supplier => supplier.id == Supplier.VAUTO);
            },

            supplierIsQuickTrade() {
                return this.outboundSuppliers.find(supplier => supplier.id == Supplier.QUICKTRADE);
            },

            supplierStatus() {
                if (!this.supplier) {
                    return SuppliableStatus.UNSUBMITTED;
                }

                if (this.supplier.status) {
                    return this.supplier.status;
                }
                if (this.supplier.state) {
                    return this.supplier.state.status || SuppliableStatus.UNSUBMITTED;
                }

                return SuppliableStatus.UNSUBMITTED;
            },

            statusIsReceived() {
                return this.supplierStatus == SuppliableStatus.RECEIVED;
            },

            statusIsWaiting() {
                return this.supplierStatus == SuppliableStatus.WAITING;
            },

            statusTooltip() {
                if (this.statusIsReceived) {
                    return this.$t('suppliers.tooltip.received', [
                        this.vehicle.calculator_appraiser,
                        locale_dt(this.supplierResponse.imported_on).humanShort(),
                    ]);
                }

                if (this.statusIsWaiting) {
                    return this.$t('suppliers.label.waiting', [
                        locale_dt(this.supplierResponse.exported_on).humanShort(),
                    ]);
                }

                return '';
            },

            supplierResponse() {
                const response = {
                    imported_on: now(),
                };

                if (!this.supplier) {
                    return response;
                }

                return this.supplierData;
            },

            supplierData() {
                if (!this.supplier) {
                    return {};
                }

                return this.supplier.pivot || this.supplier.state || {};
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadVehicle', 'appendNewError']),

            supplierName(supplier) {
                return this.$t(`suppliers.export.${supplier.display_name}`);
            },

            async send() {
                if (this.disabled || this.exporting) {
                    return;
                }

                const supplier = this.outboundSuppliers[0];

                this.exporting = true;

                switch (supplier.id) {
                    case Supplier.VAUTO:
                        await this.sendToVauto();
                        break;

                    case Supplier.AUTOPROPULSION:
                        await this.sendToAutopropulsion();
                        break;

                    case Supplier.QUICKTRADE:
                        await this.sendToQuickTrade();
                        break;
                }

                this.exporting = false;
            },

            async sendToVauto() {
                try {
                    const response = await this.$axios.post(`v1/lead-vehicles/${this.vehicle.id}/vauto`, {
                        accountId: this.contextAccount.id,
                    });

                    switch (response.data.data.salesPersonResult) {
                        case VAutoResponse.SUCCESS_ADVISOR:
                            this.$notify.success(
                                this.$t('vauto.success.assignedAdvisor', [response.data.data.userName]),
                            );
                            break;

                        case VAutoResponse.SUCCESS_FALLBACK:
                            this.$notify.success(this.$t('vauto.success.assignedDirector'));
                            break;

                        case VAutoResponse.WARNING_NO_USERNAME:
                            this.$notify.warning(this.$t('vauto.warning.assignedFallbackUsernameNotSet'));
                            break;

                        case VAutoResponse.WARNING_USERNAME_ERROR:
                            this.$notify.warning(this.$t('vauto.warning.assignedFallbackVerifyUsername'));
                            break;

                        case VAutoResponse.WARNING_FALLBACK_NO_USERNAME:
                            this.$notify.warning(this.$t('vauto.warning.assignedDirectorUsernameNotSet'));
                            break;

                        case VAutoResponse.WARNING_FALLBACK_USERNAME_ERROR:
                            this.$notify.warning(this.$t('vauto.warning.assignedDirectorVerifyUsername'));
                            break;

                        case VAutoResponse.WARNING_INVALID_FALLBACK:
                            this.$notify.warning(this.$t('vauto.warning.assignedDirectorLastFallback'));
                            break;
                    }

                    this.updateLeadVehicle(response.data.data.result.vehicle);
                } catch (error) {
                    if (error.response.data.errors) {
                        switch (error.response.data.errors.salesPersonResult) {
                            case VAutoResponse.ERROR_USER_NOT_ACTIVE:
                                this.$notify.error(this.$t('vauto.error.userNotActive', [error.response.data.errors.userName]));
                                break;
                        }
                    } else {
                        this.appendNewError({
                            code: '0700',
                            display: true,
                            error,
                            payload: {
                                leadId: this.contextLead.id,
                            },
                        });
                    }
                }
            },

            async sendToAutopropulsion() {
                try {
                    const response = await this.$axios.post(`v1/lead-vehicles/${this.vehicle.id}/autopropulsion`);

                    if (response.status == 200) {
                        this.$notify.success(this.$t('autopropulsion.success'));

                        this.updateLeadVehicle({
                            ...this.vehicle,
                            suppliers: response.data.data.suppliers,
                        });
                    }
                } catch (e) {
                    switch (e.response.status) {
                        case 403:
                            this.$notify.error(this.$t('autopropulsion.warning.error403'));
                            break;

                        case 404:
                            this.$notify.error(this.$t('autopropulsion.warning.error404'));
                            break;

                        case 422:
                            if (e.response.message?.includes('All vehicles of this lead have already received')) {
                                this.$notify.error(this.$t('autopropulsion.warning.vehicleAlreadyReceived'));

                                break;
                            }

                            this.$notify.error(this.$t('autopropulsion.warning.error500'));
                            break;

                        default:
                            this.$notify.error(this.$t('autopropulsion.warning.error500'));
                            break;
                    }

                    this.appendNewError({
                        code: '0700',
                        display: false,
                        error: e,
                        payload: {
                            leadId: this.contextLead.id,
                        },
                    });
                }
            },

            async sendToQuickTrade() {
                try {
                    const response = await this.$axios.post(`v1/lead-vehicles/${this.vehicle.id}/quicktrade`);

                    if (response.status == 200) {
                        this.$notify.success(this.$t('quicktrade.success'));

                        this.updateLeadVehicle({
                            ...this.vehicle,
                            suppliers: response.data.data.suppliers,
                        });
                    }
                } catch (e) {
                    switch (e.response.status) {
                        case 403:
                            this.$notify.error(this.$t('quicktrade.warning.error403'));
                            break;

                        case 404:
                            this.$notify.error(this.$t('quicktrade.warning.error404'));
                            break;

                        case 422:
                            this.$notify.error(this.$t('quicktrade.warning.error422'));
                            break;

                        default:
                            this.$notify.error(this.$t('quicktrade.warning.error500'));
                            break;
                    }

                    this.appendNewError({
                        code: '0700',
                        display: false,
                        error: e,
                        payload: {
                            leadId: this.contextLead.id,
                        },
                    });
                }
            },
        },
    };
</script>
