<template>
    <flexible-input>
        <input-label slot="label" class="flex items-center" :blocked-reason="internalBlockedReason">
            {{ $t('clientCard.vehicles.model') }}
            <activix-tooltip :content="tooltipMatch" v-if="vehicle.exportSupplier">
                <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
            </activix-tooltip>
            <activix-tooltip :content="$t('export.honda.needModelTrimAlert')" v-if="needModelWithTrimAlert">
                <icon class="text-red-500 text-xs ml-1" name="regular/information-circle" />
            </activix-tooltip>
        </input-label>

        <activix-tooltip :content="vehicleValueTooltip">
            <invisible-input
                class="flex-1"
                :disabled="isDisabled"
                :value="vehicle.model"
                @input="$emit('input', $event)"
                v-if="account.vehicle_text || account.vehicle_model_text"
            />
            <invisible-multiselect
                :append-missing-value="true"
                :disabled="isDisabled"
                :nullable="true"
                :options="options"
                :searchable="true"
                :value="currentModelValue"
                @input="onChange"
                v-else
            />
        </activix-tooltip>

        <activix-tooltip :content="$t('clientCard.requestSource')" v-if="!!vehicleUrl">
            <a class="flex items-center text-lg ml-2" target="_blank" :href="vehicleLink">
                <icon name="regular/view-1" />
            </a>
        </activix-tooltip>
    </flexible-input>
</template>

<script>
    import { mapActions, mapState, mapWritableState } from 'pinia';
    import { useGlobalStore } from '@/store/store';
    import { useClientCardStore } from '@/store/modules/clientCard/store.js';

    import FlexibleInput from '@/components/container/input/FlexibleInput.vue';
    import InputLabel from '@/components/presentational/input/InputLabel.vue';
    import InvisibleInput from '@/components/container/input/InvisibleInput';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect.vue';

    import Account from '@/entities/Account';
    import LeadVehicle from '@/entities/LeadVehicle.js';
    import LeadVehicleType from '@/entities/LeadVehicleType.js';
    import Supplier from '@/entities/Supplier.js';

    export default {
        components: {
            FlexibleInput,
            InputLabel,
            InvisibleInput,
            InvisibleMultiselect,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            stockManagementEnabled: {
                type: Boolean,
                required: true,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        data: () => ({
            noTrimOnModel: ['Element', 'Prelude', 'S2000'],
        }),

        computed: {
            ...mapState(useGlobalStore, ['configs']),
            ...mapState(useClientCardStore, ['vehicleMakes']),
            ...mapWritableState(useClientCardStore, ['vehicleModels']),

            displayLocale() {
                return this.configs.locale === 'fr' ? 'display_name_fr' : 'display_name_en';
            },

            hasVehicleValue() {
                return !empty(this.vehicle.vehicle_value);
            },

            internalBlockedReason() {
                if (this.blockedReason) {
                    return this.blockedReason;
                }

                if (this.vehicle.isExported) {
                    return this.$t('clientCard.blocked.vehicleExported');
                }

                if (this.hasVehicleValue) {
                    return this.$t('clientCard.blocked.vehicleAppraisalCompleted');
                }

                if (this.isLinkedToInventory) {
                    return this.$t('clientCard.blocked.vehicleStock');
                }

                return '';
            },

            isDisabled() {
                return this.disabled || this.vehicle.isExported || this.hasVehicleValue || this.isLinkedToInventory;
            },

            isLinkedToInventory() {
                return !empty(this.vehicle.inventory_unit_id) && this.stockManagementEnabled;
            },

            needModelWithTrimAlert() {
                if (this.vehicle.type !== LeadVehicleType.WANTED) {
                    return false;
                }

                if (this.vehicle.make !== 'Honda') {
                    return false;
                }

                const suppliable = this.vehicle.lead.suppliers.some(suppliable => {
                    return suppliable.id === Supplier.HONDA_CANADA &&
                        suppliable.related_id;
                });

                if (suppliable) {
                    return false;
                }

                const supplier = this.account.suppliers.some(supplier => {
                    return supplier.id === Supplier.HONDA_CANADA &&
                        supplier.outbound;
                });

                if (!supplier || this.noTrimOnModel.includes(this.vehicle.model)) {
                    return false;
                }

                return this.options.length === 0;
            },

            options() {
                return (this.vehicleMakeModels || [])
                    .map(model => ({
                        value: this.getModelValue(model),
                        text: this.getModelName(model),
                    }))
                    .sort((a, b) => a.text.localeCompare(b.text));
            },

            tooltipMatch() {
                const exportSupplier = this.vehicle.exportSupplier;

                if (!exportSupplier) {
                    return '';
                }

                return `${this.$t(`${exportSupplier.display_name}.tooltip.match`)}`;
            },

            vehicleLink() {
                if (this.vehicleUrl.startsWith('http')) {
                    return this.vehicleUrl;
                }

                return `//${this.vehicleUrl}`;
            },

            vehicleMake() {
                return this.vehicleMakes.find(make => make.name === this.vehicle.make) || {};
            },

            vehicleMakeId() {
                return this.vehicleMake.id;
            },

            vehicleMakeModels() {
                return this.vehicleModels[this.vehicleMakeId];
            },

            currentModelValue() {
                const currentModel = this.vehicleMakeModels?.find(model => this.getModelName(model) === this.vehicle.model);

                if (!currentModel) {
                    return null;
                }

                return this.getModelValue(currentModel);
            },

            vehicleUrl() {
                return this.vehicle.url?.trim() || '';
            },

            vehicleValueTooltip() {
                return this.hasVehicleValue ? this.$t('clientCard.vehicleEvaluate') : '';
            },
        },

        watch: {
            vehicleMakeId: {
                immediate: true,
                handler() {
                    if (this.account.power_sport) {
                        return;
                    }

                    this.triggerFetchVehicleModels();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, [
                'fetchVehicleModels',
            ]),

            getModelName(model) {
                return model[this.displayLocale] || model.name;
            },

            getModelValue(model) {
                return `${this.getModelName(model)}[${model.id}]`;
            },

            onChange(modelValue) {
                this.$emit('input', modelValue.replace(/\[([^[]*)$/, ''));
            },

            async triggerFetchVehicleModels() {
                if (!this.vehicleMakeId || this.vehicleMakeModels) {
                    return;
                }

                const models = await this.fetchVehicleModels(this.vehicleMakeId);
                this.$set(this.vehicleModels, this.vehicleMakeId, models);
            },
        },
    };
</script>
