<template>
    <div class="flex flex-grow items-center gap-3">
        <activix-tooltip :content="tooltip">
            <invisible-input
                :button-icon="canCallLead ? $icons.phone : ''"
                :disabled="saving"
                :error="showInvalidState"
                :mask="phoneMask"
                :value="phoneValue"
                ref="input"
                @focus="onFocus"
                @input="onPhoneChange"
                @button-click="$emit('phone-icon-clicked', phone)"
            >
                <template #after>
                    <div
                        class="absolute inset-0 flex items-center justify-center z-10"
                        :class="{ 'rounded-md bg-gray-900/50': revealing }"
                        @click="revealPhone"
                        v-if="masked"
                    >
                        <div class="rounded-full bg-gray-800 px-2 text-lg text-white font-bold" v-if="revealing">
                            {{ revealTimer }}
                        </div>
                    </div>
                </template>
            </invisible-input>
        </activix-tooltip>

        <invisible-input
            class="max-w-[40%]"
            before="#"
            mask="0000000000"
            :animated="false"
            :value="phone.extension"
            @input="onExtensionChange"
            @focus="onFocus"
            v-if="showExtension"
        />

        <activix-confirm-modal
            :approve-text="$t('general.yes')"
            :content="$t('clientCard.blockedPhoneModification')"
            :deny-text="$t('general.no')"
            :opened="modals.blockedPhone.opened"
            :title="$t('general.areYouSure')"
            type="warning"
            @approve="blockedPhoneUpdateConfirmed"
            @close="modals.blockedPhone.opened = false"
        />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import InvisibleInput from '@/components/container/input/InvisibleInput';

    import LeadPhone from '@/entities/LeadPhone';
    import LeadPhoneType from '@/entities/LeadPhoneType';
    import Lead from '@/entities/Lead.js';

    export default {
        components: { InvisibleInput },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
            phone: {
                type: LeadPhone,
                required: true,
                validator: phone => {
                    return LeadPhoneType.exists(phone.type);
                },
            },
        },

        data: () => {
            return {
                modals: {
                    blockedPhone: {
                        confirmed: false,
                        opened: false,
                    },
                },
                revealed: false,
                revealing: false,
                revealTimer: 3,
                saving: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            blocked() {
                return this.phone.blocked;
            },

            canCallLead() {
                if (this.lgLayout) {
                    return false;
                }

                return (
                    !this.guest &&
                    !!this.phone.id &&
                    this.phoneIsValid &&
                    !this.saving &&
                    this.lead.account.outgoingPhoneProviders.length > 0
                );
            },

            extensionValue() {
                if (!this.showExtension) {
                    return '';
                }

                return this.formatExtension(this.phone.extension);
            },

            hasPhone() {
                return !!this.phone.phone;
            },

            isWorkType() {
                return this.phone.type === LeadPhoneType.WORK;
            },

            masked() {
                if (!this.authUser.block_lead_info) {
                    return false;
                }

                if (!this.phoneIsValid) {
                    return false;
                }

                return !this.revealed;
            },

            phoneIsValid() {
                return this.hasPhone && !!this.phone.valid;
            },

            phoneMask() {
                return this.masked ? null : 'phone';
            },

            phoneValue() {
                const value = this.phone.numberWithoutCountry() || this.phone.phone || null;

                if (this.masked) {
                    return this.maskPhone(value);
                }

                return value;
            },

            showExtension() {
                return this.isWorkType && this.phoneIsValid;
            },

            showInvalidState() {
                return this.hasPhone && !this.phoneIsValid && !this.saving;
            },

            tooltip() {
                if (this.saving) {
                    return this.$t('clientCard.boxes.general.validatingPhoneNumber');
                }

                if (this.showInvalidState) {
                    return this.$t('clientCard.boxes.general.invalidPhoneNumber');
                }

                return '';
            },
        },

        methods: {
            blockedPhoneUpdateConfirmed() {
                this.modals.blockedPhone.confirmed = true;

                this.$refs.input.focus();
            },

            onFocus() {
                if (this.blocked && !this.modals.blockedPhone.confirmed) {
                    this.$refs.input.blur();

                    this.modals.blockedPhone.opened = true;
                }
            },

            onExtensionChange(value) {
                this.$emit('update-phone', {
                    phoneData: {
                        extension: value,
                    },
                });
            },

            onPhoneChange(value) {
                this.saving = true;
                this.revealed = true;

                this.$emit('update-phone', {
                    phoneData: {
                        phone: value,
                    },
                    callback: async () => {
                        this.saving = false;
                    },
                });
            },

            revealPhone() {
                this.revealing = true;

                const interval = setInterval(() => {
                    if (--this.revealTimer === 0) {
                        this.revealed = true;

                        clearInterval(interval);
                    }
                }, 1000);
            },
        },
    };
</script>
