<template>
    <div class="grid grid-cols-1 gap-6 items-stretch | xl:grid-cols-6">
        <template v-if="contextLead.isService()">
            <lead-general-box class="col-span-full | xl:col-span-3" />
            <lead-notes-box class="col-span-full | xl:col-span-3" />
            <revival-box class="col-span-full | xl:col-span-2" />
            <process-box class="col-span-full | xl:col-span-4" />
            <lead-vehicle-box class="col-span-full | xl:col-span-3" type="exchange" />
            <service-products-box class="col-span-full | xl:col-span-3" />
        </template>

        <template v-else>
            <template v-if="hasCommercial">
                <lead-general-box class="col-span-full | xl:col-span-3" />
                <lead-notes-box class="col-span-full | xl:col-span-3" />
                <sales-process-box class="col-span-full | xl:col-span-3" />
                <financial-and-delivery-box class="col-span-full | xl:col-span-3" />
            </template>

            <template v-else>
                <lead-general-box class="col-span-full | xl:col-span-3" />

                <div class="flex flex-col gap-6 col-span-full | xl:col-span-3">
                    <sales-process-box />
                    <financial-and-delivery-box />
                </div>
            </template>

            <template v-if="!contextAccount.isCustom()">
                <lead-vehicle-box type="wanted" class="col-span-full | xl:col-span-3" />
                <lead-vehicle-box type="exchange" class="col-span-full | xl:col-span-3" />
            </template>
        </template>

        <template v-if="contextAccount.isCustom()">
            <lead-custom-box
                class="box-wrapper | col-span-full | xl:col-span-3 flex flex-1"
                :title="$t('clientCard.funnel')"
                section="funnel"
                v-if="hasFunnelFields"
            />
            <lead-custom-box
                class="box-wrapper | col-span-full | xl:col-span-3 flex flex-1"
                :title="$t('clientCard.dollarAndLost')"
                section="dollarAndLost"
                v-if="hasDollarLostFields"
            />
        </template>

        <calendar-box class="col-span-full | xl:col-span-3" />

        <commercial-products-box class="col-span-full | xl:col-span-3" v-if="hasCommercial" />

        <lead-notes-box class="col-span-full | xl:col-span-3 min-h-main-view" v-if="!hasCommercial && !contextLead.isService()" />

        <communications-box class="col-span-full | xl:col-span-3 xl:min-h-main-view" />

        <activity-log-box class="col-span-full | xl:col-span-3" />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store.js';

    import ActivityLogBox from '@/components/container/lead/boxes/ActivityLogBox';
    import CalendarBox from '@/components/container/lead/boxes/CalendarBox';
    import CommercialProductsBox from '@/components/container/lead/boxes/CommercialProductsBox';
    import CommunicationsBox from '@/components/container/lead/boxes/CommunicationsBox.vue';
    import FinancialAndDeliveryBox from '@/components/container/lead/boxes/FinancialAndDeliveryBox';
    import LeadCustomBox from '@/components/lead/LeadCustomBox.vue';
    import LeadGeneralBox from '@/components/container/lead/boxes/LeadGeneralBox';
    import LeadNotesBox from '@/components/container/lead/boxes/LeadNotesBox';
    import LeadVehicleBox from '@/components/container/lead/boxes/LeadVehicleBox';
    import ProcessBox from '@/components/container/lead/boxes/ServiceProcessBox';
    import RevivalBox from '@/components/container/lead/boxes/RevivalBox';
    import SalesProcessBox from '@/components/container/lead/boxes/SalesProcessBox.vue';
    import ServiceProductsBox from '@/components/container/lead/boxes/ServiceProductsBox';

    import ClientCardSection from '@/entities/ClientCardSection.js';

    export default {
        components: {
            ActivityLogBox,
            CalendarBox,
            CommercialProductsBox,
            CommunicationsBox,
            FinancialAndDeliveryBox,
            LeadCustomBox,
            LeadGeneralBox,
            LeadNotesBox,
            LeadVehicleBox,
            ProcessBox,
            RevivalBox,
            SalesProcessBox,
            ServiceProductsBox,
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextLead: 'contextLead',
            }),

            hasCommercial() {
                return this.contextAccount.commercial;
            },

            hasEquityAlert() {
                return this.contextAccount.equity_alert;
            },

            hasFunnelFields() {
                return this.contextAccount.custom_fields.some(field => field.section === ClientCardSection.FUNNEL);
            },

            hasDollarLostFields() {
                return this.contextAccount.custom_fields.some(
                    field => field.section === ClientCardSection.DOLLAR_AND_LOST,
                );
            },
        },
    };
</script>

<style scoped>
    .box-wrapper >>> .box {
        margin: 0;
    }
</style>
