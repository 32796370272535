<template>
    <div>
        <activix-modal :name="name" ref="modal" @closed="onClosed">
            <template slot="header">
                <h4 class="modal-title">
                    {{ $t('clientCard.personalInfo') }}
                </h4>
            </template>

            <template slot="body">
                <lead-input-wrapper>
                    <!-- Contact -->
                    <div class="table-row">
                        <div class="table-cell">
                            <h3 class="font-bold mt-0 mb-2">
                                {{ $t('clientCard.contact') }}
                            </h3>
                        </div>
                    </div>

                    <lead-input-group>
                        <lead-input-label>
                            {{ $t('clientCard.name') }}
                        </lead-input-label>
                        <lead-input-value>
                            <div class="flex flex-col | md:flex-row">
                                <select-picker
                                    name="civility"
                                    :disabled="guest"
                                    :options="civilities"
                                    :record-id="contextLead.id"
                                    :value="contextLead.civility"
                                    :center-icon="false"
                                />
                                <input-name
                                    name="secondary_name"
                                    class="md:ml-2"
                                    :disabled="guest"
                                    :record-id="contextLead.id"
                                    :value="contextLead"
                                    :center-icon="false"
                                />
                            </div>
                        </lead-input-value>
                    </lead-input-group>
                    <lead-input-group>
                        <lead-input-label>
                            {{ $t('clientCard.secondContact') }}
                        </lead-input-label>
                        <lead-input-value>
                            <div class="flex flex-col | md:flex-row">
                                <select-picker
                                    name="second_contact_civility"
                                    :disabled="guest"
                                    :options="civilities"
                                    :record-id="contextLead.id"
                                    :value="contextLead.second_contact_civility"
                                    :center-icon="false"
                                />

                                <input-text
                                    class="md:ml-2"
                                    name="second_contact"
                                    empty-value="..."
                                    :record-id="contextLead.id"
                                    :value="contextLead.second_contact"
                                    :disabled="guest"
                                    :center-icon="false"
                                />
                            </div>
                        </lead-input-value>
                    </lead-input-group>
                    <lead-input-group>
                        <lead-input-label>
                            {{ $t('clientCard.businessName') }}
                        </lead-input-label>
                        <lead-input-value>
                            <div class="flex flex-col | md:flex-row">
                                <checkbox
                                    name="business"
                                    :value="contextLead.business"
                                    :record-id="contextLead.id"
                                />
                                <input-text
                                    class="md:ml-2"
                                    name="business_name"
                                    empty-value="..."
                                    :record-id="contextLead.id"
                                    :value="contextLead.business_name"
                                    :disabled="guest"
                                    :center-icon="false"
                                />
                            </div>
                        </lead-input-value>
                    </lead-input-group>

                    <lead-input-group>
                        <lead-input-label>
                            <div class="flex items-center">
                                {{ $t('clientCard.phone') }}
                                <icon class="ml-2" name="regular/house-chimney-2" />
                            </div>
                        </lead-input-label>
                        <lead-input-value>
                            <div class="flex justify-between items-center">
                                <input-phone
                                    name="home_phone"
                                    type="home"
                                    :record-id="homePhone.id"
                                    :value="homePhone.phone"
                                    :valid="homePhone.valid"
                                    :blocked="homePhone.blocked"
                                    :disabled="guest"
                                    :masked="authUser.block_lead_info"
                                />

                                <activix-tooltip :content="phoneTooltip" v-if="canCallLead(homePhone)">
                                    <a
                                        :disabled="contextLead.unsubscribe_call || isUnsubscribeAll"
                                        :class="{ disabled: contextLead.unsubscribe_call || isUnsubscribeAll }"
                                        @click="openCallLeadModal(homePhone)"
                                    >
                                        {{ $t('clientCard.call') }}
                                    </a>
                                </activix-tooltip>
                            </div>
                        </lead-input-value>
                    </lead-input-group>
                    <lead-input-group>
                        <lead-input-label>
                            <div class="flex items-center">
                                {{ $t('clientCard.phone') }}
                                <icon class="ml-2" name="regular/briefcase" />
                            </div>
                        </lead-input-label>
                        <lead-input-value>
                            <div class="flex justify-between items-center">
                                <div class="flex flex-col w-full | md:flex-row">
                                    <input-phone
                                        name="work_phone"
                                        type="work"
                                        :center-icon="false"
                                        :record-id="workPhone.id"
                                        :value="workPhone.phone"
                                        :valid="workPhone.valid"
                                        :blocked="workPhone.blocked"
                                        :disabled="guest"
                                        :masked="authUser.block_lead_info"
                                    />

                                    <div class="flex items-center" v-if="workPhone.valid && workPhone.id">
                                        <span class="font-bold | md:ml-4">#</span>
                                        <input-phone
                                            class="ml-1"
                                            name="work_extension"
                                            type="work"
                                            width="xs"
                                            :center-icon="false"
                                            :extension="true"
                                            :disabled="guest"
                                            :record-id="workPhone.id"
                                            :value="workPhone.extension"
                                        />
                                    </div>
                                </div>

                                <activix-tooltip :content="phoneTooltip" v-if="canCallLead(workPhone)">
                                    <a
                                        :disabled="contextLead.unsubscribe_call || isUnsubscribeAll"
                                        :class="{ disabled: contextLead.unsubscribe_call || isUnsubscribeAll }"
                                        @click="openCallLeadModal(workPhone)"
                                    >
                                        {{ $t('clientCard.call') }}
                                    </a>
                                </activix-tooltip>
                            </div>
                        </lead-input-value>
                    </lead-input-group>
                    <lead-input-group>
                        <lead-input-label>
                            <div class="flex items-center">
                                {{ $t('clientCard.phone') }}
                                <icon class="ml-2" name="regular/mobile-phone" />
                            </div>
                        </lead-input-label>
                        <lead-input-value>
                            <div class="flex justify-between items-center">
                                <input-phone
                                    name="cell_phone"
                                    type="cell"
                                    :disabled="guest"
                                    :record-id="cellPhone.id"
                                    :value="cellPhone.phone"
                                    :valid="cellPhone.valid"
                                    :blocked="cellPhone.blocked"
                                    :masked="authUser.block_lead_info"
                                />

                                <activix-tooltip :content="phoneTooltip" v-if="canCallLead(cellPhone)">
                                    <a
                                        :disabled="contextLead.unsubscribe_call || isUnsubscribeAll"
                                        :class="{ disabled: contextLead.unsubscribe_call || isUnsubscribeAll }"
                                        @click="openCallLeadModal(cellPhone)"
                                    >
                                        {{ $t('clientCard.call') }}
                                    </a>
                                </activix-tooltip>
                            </div>
                        </lead-input-value>
                    </lead-input-group>
                    <lead-input-group>
                        <lead-input-label>
                            <div class="flex items-center">
                                {{ $t('clientCard.email') }}
                                <icon class="ml-2" name="regular/house-chimney-2" />
                            </div>
                        </lead-input-label>
                        <lead-input-value>
                            <div class="flex justify-between items-center">
                                <input-email
                                    name="home_email"
                                    type="home"
                                    :disabled="guest"
                                    :record-id="homeEmail.id"
                                    :value="homeEmail.email"
                                    :valid="homeEmail.valid"
                                    :masked="authUser.block_lead_info"
                                />

                                <activix-tooltip :content="emailTooltip" v-if="canEmailLead(homeEmail)">
                                    <a
                                        :disabled="contextLead.unsubscribe_email || isUnsubscribeAll"
                                        :class="{ disabled: contextLead.unsubscribe_email || isUnsubscribeAll }"
                                        @click="openEmailLeadModal"
                                    >
                                        {{ $t('clientCard.email') }}
                                    </a>
                                </activix-tooltip>
                            </div>
                        </lead-input-value>
                    </lead-input-group>
                    <lead-input-group>
                        <lead-input-label>
                            <div class="flex items-center">
                                {{ $t('clientCard.email') }}
                                <icon class="ml-2" name="regular/briefcase" />
                            </div>
                        </lead-input-label>
                        <lead-input-value>
                            <div class="flex justify-between items-center">
                                <input-email
                                    name="work_email"
                                    type="work"
                                    :disabled="guest"
                                    :record-id="workEmail.id"
                                    :value="workEmail.email"
                                    :valid="workEmail.valid"
                                    :masked="authUser.block_lead_info"
                                />

                                <activix-tooltip :content="emailTooltip" v-if="canEmailLead(workEmail)">
                                    <a
                                        :disabled="contextLead.unsubscribe_email || isUnsubscribeAll"
                                        :class="{ disabled: contextLead.unsubscribe_email || isUnsubscribeAll }"
                                        @click="openEmailLeadModal"
                                    >
                                        {{ $t('clientCard.email') }}
                                    </a>
                                </activix-tooltip>
                            </div>
                        </lead-input-value>
                    </lead-input-group>

                    <!-- Info -->
                    <div class="table-row">
                        <div class="table-cell">
                            <h3 class="font-bold mt-8 mb-2">
                                {{ $t('clientCard.info') }}
                            </h3>
                        </div>
                    </div>
                    <lead-input-group>
                        <lead-input-label>
                            {{ $t('clientCard.language') }}
                        </lead-input-label>
                        <lead-input-value>
                            <select-picker
                                name="locale"
                                :disabled="guest"
                                :options="locales"
                                :record-id="contextLead.id"
                                :value="contextLead.locale"
                            />
                        </lead-input-value>
                    </lead-input-group>
                    <lead-input-group>
                        <lead-input-label>
                            {{ $t('clientCard.sex') }}
                        </lead-input-label>
                        <lead-input-value>
                            <select-picker
                                name="sex"
                                :disabled="guest"
                                :options="genders"
                                :record-id="contextLead.id"
                                :value="contextLead.sex"
                            />
                        </lead-input-value>
                    </lead-input-group>
                    <lead-input-group>
                        <lead-input-label>
                            {{ $t('clientCard.birthDate') }}
                        </lead-input-label>
                        <lead-input-value>
                            <date-time-picker
                                name="birth_date"
                                :date-only="true"
                                :disabled="guest"
                                :record-id="contextLead.id"
                                :enable-empty="true"
                                :start-view="4"
                                :value="contextLead.birth_date"
                                @input="updateBornDate"
                            />
                        </lead-input-value>
                    </lead-input-group>

                    <!-- Address -->
                    <div class="table-row">
                        <div class="table-cell">
                            <h3 class="font-bold mt-8 mb-2">
                                {{ $t('clientCard.address') }}
                            </h3>
                        </div>
                    </div>
                    <lead-input-group>
                        <lead-input-label>
                            {{ $t('clientCard.address') }}
                        </lead-input-label>
                        <lead-input-value>
                            <input-text
                                name="address1"
                                :disabled="guest"
                                :record-id="contextLead.id"
                                :value="contextLead.address1"
                            />
                        </lead-input-value>
                    </lead-input-group>
                    <lead-input-group>
                        <lead-input-label>
                            {{ $t('clientCard.city') }}
                        </lead-input-label>
                        <lead-input-value>
                            <input-text
                                name="city"
                                :disabled="guest"
                                :record-id="contextLead.id"
                                :value="contextLead.city"
                            />
                        </lead-input-value>
                    </lead-input-group>
                    <lead-input-group>
                        <lead-input-label>
                            {{ $t('clientCard.province.' + contextLead.country) }}
                        </lead-input-label>
                        <lead-input-value>
                            <select-picker
                                name="province"
                                :disabled="guest"
                                :options="provinces"
                                :record-id="contextLead.id"
                                :value="contextLead.province"
                                ref="province"
                            />
                        </lead-input-value>
                    </lead-input-group>
                    <lead-input-group>
                        <lead-input-label>
                            {{ $t('clientCard.country') }}
                        </lead-input-label>
                        <lead-input-value>
                            <select-picker
                                name="country"
                                :disabled="guest"
                                :options="countries"
                                :record-id="contextLead.id"
                                :value="contextLead.country"
                                :allow-empty="false"
                                @update="onCountryUpdate()"
                            />
                        </lead-input-value>
                    </lead-input-group>
                    <lead-input-group>
                        <lead-input-label>
                            {{ $t('clientCard.postalCode.' + contextLead.country) }}
                        </lead-input-label>
                        <lead-input-value>
                            <input-text
                                name="postal_code"
                                type="postalCode"
                                :country="contextLead.country"
                                :disabled="guest"
                                :record-id="contextLead.id"
                                :value="contextLead.postal_code"
                            />
                        </lead-input-value>
                    </lead-input-group>
                </lead-input-wrapper>
            </template>
        </activix-modal>

        <call-lead name="personalInfo:callLead" portal="modal-2" />
        <email-lead name="personalInfo:emailLead" portal="modal-2" />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    // Components
    import CallLead from './CallLead.vue';
    import DateTimePicker from '../lead/inputs/DateTimePicker.vue';
    import EmailLead from './EmailLead.vue';
    import InputEmail from '../lead/inputs/InputEmail.vue';
    import InputName from '../lead/inputs/InputName.vue';
    import InputPhone from '../lead/inputs/InputPhone.vue';
    import InputText from '../lead/inputs/InputText.vue';
    import LeadInputGroup from '../lead/LeadInputGroup.vue';
    import LeadInputLabel from '../lead/LeadInputLabel.vue';
    import LeadInputValue from '../lead/LeadInputValue.vue';
    import LeadInputWrapper from '../lead/LeadInputWrapper.vue';
    import SelectPicker from '../lead/inputs/SelectPicker.vue';
    import Checkbox from '../lead/inputs/Checkbox.vue';

    // Entities
    import Locale from '../../entities/Locale.js';
    import Civility from '../../entities/Civility.js';
    import EmailValidationRisk from '../../entities/EmailValidationRisk.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            Checkbox,
            CallLead,
            DateTimePicker,
            EmailLead,
            InputEmail,
            InputName,
            InputPhone,
            InputText,
            LeadInputGroup,
            LeadInputLabel,
            LeadInputValue,
            LeadInputWrapper,
            SelectPicker,
        },

        props: {
            name: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                genders: [
                    {
                        value: 'M',
                        text: this.$t('database.male'),
                    },
                    {
                        value: 'W',
                        text: this.$t('database.female'),
                    },
                ],
                shouldOpenCallModal: false,
                shouldOpenEmailModal: false,
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),
            ...mapState(useGlobalStore, ['authUser', 'guest', 'configs']),

            civilities() {
                return Civility.uniqueOptions('value', 'text', false, this.contextLead.civility);
            },

            homePhone() {
                const phone = this.contextLead.lead_phones.find(value => {
                    return value.type == 'home';
                });

                if (!empty(phone)) {
                    return phone;
                }

                return {
                    id: null,
                    phone: '',
                    extension: '',
                    valid: true,
                };
            },

            workPhone() {
                const phone = this.contextLead.lead_phones.find(value => {
                    return value.type == 'work';
                });

                if (!empty(phone)) {
                    return phone;
                }

                return {
                    id: null,
                    phone: '',
                    extension: '',
                    valid: true,
                };
            },

            cellPhone() {
                const phone = this.contextLead.lead_phones.find(value => {
                    return value.type == 'cell';
                });

                if (!empty(phone)) {
                    return phone;
                }

                return {
                    id: null,
                    phone: '',
                    extension: '',
                    valid: true,
                };
            },

            homeEmail() {
                const email = this.contextLead.lead_emails.find(value => {
                    return value.type == 'home';
                });

                if (!empty(email)) {
                    return email;
                }

                return {
                    id: null,
                    email: '',
                    valid: true,
                };
            },

            workEmail() {
                const email = this.contextLead.lead_emails.find(value => {
                    return value.type == 'work';
                });

                if (!empty(email)) {
                    return email;
                }

                return {
                    id: null,
                    email: '',
                    valid: true,
                };
            },

            phoneTooltip() {
                if (this.isUnsubscribeAll) {
                    if (as_locale(this.contextLead.unsubscribe_all_date, 'unsubscribe_all_date').year() == 2222) {
                        return this.$t('clientCard.clientIsAllUnsubscribedForever');
                    }

                    return this.$t('clientCard.clientIsAllUnsubscribed', [
                        as_locale(this.contextLead.unsubscribe_all_date, 'unsubscribe_all_date').humanShort(true),
                    ]);
                }

                if (this.contextLead.unsubscribe_call) {
                    return this.$t('clientCard.clientIsCallUnsubscribed');
                }

                return '';
            },

            emailTooltip() {
                if (this.isUnsubscribeAll) {
                    if (as_locale(this.contextLead.unsubscribe_all_date, 'unsubscribe_all_date').year() == 2222) {
                        return this.$t('clientCard.clientIsAllUnsubscribedForever');
                    }

                    return this.$t('clientCard.clientIsAllUnsubscribed', [
                        as_locale(this.contextLead.unsubscribe_all_date, 'unsubscribe_all_date').humanShort(true),
                    ]);
                }

                if (this.contextLead.unsubscribe_email) {
                    return this.$t('clientCard.clientIsEmailUnsubscribed');
                }

                return '';
            },

            countries() {
                const countries = this.configs.countries.map(country => {
                    return {
                        value: country,
                        text: this.$t(`clientCard.countries.${country}`),
                    };
                });

                return countries;
            },

            provinces() {
                return this.configs.provinces[this.contextLead.country].map(province => {
                    return {
                        value: province,
                        text: this.$t(`clientCard.provinces.${province}`),
                    };
                }).sort((a, b) => a.text.localeCompare(b.text));
            },

            locales() {
                const locales = Locale.values.map(locale => {
                    return {
                        value: locale,
                        text: this.$t(`database.${locale}`),
                    };
                });

                return locales;
            },

            isUnsubscribeAll() {
                return !!this.contextLead.unsubscribe_all_date;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'setSpecificPhoneToCall']),

            onCountryUpdate() {
                this.updateLeadAction(this.contextLead.id, { province: '' });
                this.updateLeadAction(this.contextLead.id, { postal_code: '' });
                this.$refs.province.triggerEditing();
            },

            selectPhone(phone) {
                this.setSpecificPhoneToCall(phone);
                this.close();
            },

            updateBornDate(birthDate) {
                this.updateLeadAction(this.contextLead.id, { birth_date: birthDate });
            },

            canCallLead(phone) {
                if (this.lgLayout) {
                    return false;
                }

                return (
                    phone.valid && phone.id && !this.guest && this.contextLead.account.outgoingPhoneProviders.length > 0
                );
            },

            openCallLeadModal(phone) {
                if (this.contextLead.unsubscribe_call || this.isUnsubscribeAll) {
                    return;
                }

                this.selectPhone(phone);
                this.shouldOpenCallModal = true;
            },

            canEmailLead(email) {
                if (this.lgLayout || !this.emailIsValid(email)) {
                    return false;
                }

                return email.valid && email.id && !this.guest;
            },

            emailIsValid(email) {
                if (!email.validated_email || email.validated_email.risk === EmailValidationRisk.HIGH || email.validated_email.is_disposable) {
                    return false;
                }

                return true;
            },

            openEmailLeadModal() {
                if (this.contextLead.unsubscribe_email || this.isUnsubscribeAll) {
                    return;
                }

                this.close();
                this.shouldOpenEmailModal = true;
            },

            close() {
                this.$refs.modal.hide();
            },

            onClosed() {
                if (this.shouldOpenCallModal) {
                    this.$modal.show('personalInfo:callLead', this.contextLead.id);
                }

                if (this.shouldOpenEmailModal) {
                    this.$modal.show('personalInfo:emailLead', this.contextLead.id);
                }

                this.shouldOpenCallModal = false;
                this.shouldOpenEmailModal = false;
            },
        },
    };
</script>
