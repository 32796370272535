<template>
    <div class="relative">
        <div class="absolute w-10 h-20 -ml-10 bg-gradient-to-r from-transparent to-gray-300" />
        <activix-tooltip :content="tooltip" placement="bottom" v-if="isActive">
            <div>
                <button
                    class="flex items-center justify-center h-20 w-20 border-b border-l border-gray-350"
                    :class="isEnabled ? 'bg-gray-150 hover:bg-gray-300' : 'bg-gray-300'"
                    :disabled="!isEnabled"
                    @click="onClick"
                >
                    <div class="flex" v-if="groupFavicon && faviconIsLoaded">
                        <img
                            alt="Group Logo"
                            class="max-h-9 max-w-9 | transition-all duration-300"
                            :class="{ 'grayscale': !isEnabled && !loading }"
                            :src="groupFavicon"
                            @error="faviconIsLoaded = false"
                        />
                    </div>
                    <icon
                        class="h-7 w-7"
                        :class="isEnabled ? 'text-gray-600' : 'text-gray-450'"
                        name="regular/hierarchy-4"
                        v-else
                    />
                </button>
            </div>
        </activix-tooltip>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useInterAccountLeadsStore } from '../../store/modules/interAccountLeads/store.js';
    import { useLayoutStore } from '../../store/modules/layout/store.js';

    export default {
        data() {
            return {
                faviconIsLoaded: true,
                deleteModal: {
                    opened: false,
                    lead: null,
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'parentAuthUser']),
            ...mapState(useLayoutStore, ['activeSidebar']),
            ...mapState(useInterAccountLeadsStore, {
                interAccountLeads: 'leads',
                siblingAccounts: 'siblingAccounts',
                loading: 'loading',
            }),
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),

            isEnabled() {
                return !!this.interAccountLeads.length || this.activeSidebar === 'interAccountLeads';
            },

            isActive() {
                if (this.authUser.isAdmin()) {
                    return true;
                }

                return this.isEnabled && !!this.siblingAccounts.length;
            },

            tooltip() {
                if (!this.isEnabled) {
                    return this.$t('addLead.noInterAccountLeads');
                }

                return '';
            },

            groupFavicon() {
                return this.contextAccount.parents.find(parent => !!parent.favicon_url)?.favicon_url;
            },
        },

        watch: {
            'contextLead.id'() {
                if (this.activeSidebar !== 'interAccountLeads') {
                    this.searchLeads(true);
                }
            },
        },

        methods: {
            ...mapActions(useInterAccountLeadsStore, ['fetchInterAccountLeads']),

            async searchLeads(clear = false) {
                if (!this.contextLead.id) {
                    return;
                }

                if (!this.parentAuthUser.children.length >= 2 && !this.authUser.isAdmin()) {
                    return;
                }

                await this.fetchInterAccountLeads({ lead_id: this.contextLead.id, clear });
            },

            onClick() {
                this.$eventBus.$emit('show-right-sidebar', 'interAccountLeads');
            },
        },

        mounted() {
            if (!this.interAccountLeads.length) {
                this.searchLeads();
            }
        },
    };
</script>
