<template>
    <div>
        <checkbox-date-picker
            :checkbox-color="checkboxColor"
            :checkbox-disabled="checkboxDisabled"
            :date="date"
            :date-disabled="dateDisabled"
            :date-only="dateOnly"
            :disabled="disabled"
            @check="onCheckboxClick"
            @date-click="onDateClick"
        >
            <span slot="label-suffix">
                <slot name="checkbox-suffix" />
            </span>
        </checkbox-date-picker>

        <remove-follow-up
            :follow-up="followUp"
            :opened.sync="modal.removeFollowUp.opened"
            @approve="onRemove"
            @closed="modal.removeFollowUp.opened = false"
            v-if="removeConfirmation"
        />
    </div>
</template>

<script>
    import CheckboxDatePicker from '@/components/container/input/CheckboxDatePicker';
    import RemoveFollowUp from '@/components/modals/RemoveFollowUp.vue';

    export default {
        components: { CheckboxDatePicker, RemoveFollowUp },

        props: {
            checkboxColor: {
                type: String,
            },
            checkboxDisabled: {
                type: Boolean,
                default: false,
            },
            date: {
                required: true,
                validator: prop => typeof prop === 'string' || prop === null,
            },
            dateOnly: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            dateDisabled: {
                type: Boolean,
                default: false,
            },
            followUp: {
                type: String,
                required: true,
            },
            followUpParams: {
                type: Object,
                default: () => {},
            },
            leadId: {
                type: Number,
                required: true,
            },
            removeConfirmation: {
                type: Boolean,
                default: true,
            },
            showFollowUpModalFromUrl: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            modal: {
                removeFollowUp: {
                    opened: false,
                },
            },
        }),

        watch: {
            '$route.query': {
                immediate: true,
                handler() {
                    if (this.showFollowUpModalFromUrl) {
                        this.openFollowUpModal();
                    }
                },
            },
        },

        methods: {
            onCheckboxClick(isChecked) {
                if (isChecked) {
                    this.openFollowUpModal();
                } else if (this.removeConfirmation) {
                    this.modal.removeFollowUp.opened = true;
                } else {
                    this.onRemove();
                }
            },

            onDateClick() {
                this.openFollowUpModal();
            },

            onRemove() {
                this.$emit('input', null);
            },

            openFollowUpModal() {
                this.$modal.show('followUp', {
                    leadId: this.leadId,
                    current: this.followUp,
                    ...this.followUpParams,
                });
            },
        },
    };
</script>
