<template>
    <div>
        <div class="box | mb-6">
            <div class="box-header">
                <h4 class="box-title">
                    {{ $t('clientCard.performance') }}
                </h4>
            </div>
            <div class="box-body | pt-0" :class="{ 'pb-2': mdLayout }">
                <div class="flex flex-wrap -mx-3">
                    <lead-input-wrapper class="w-full xs:w-1/2" section="performance" position="left">
                        <lead-input-group
                            v-if="showPhoneAppointment && contextLead.account.hasPerformanceField('phone_appointment')"
                        >
                            <lead-input-label>
                                {{ $t('result.appointment') }}
                                <icon class="ml-2" :name="$icons.phoneAppointment" />
                            </lead-input-label>
                            <lead-input-value>
                                <phone-appointment :in-client-card="true" :lead="contextLead" />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group
                            v-if="showAppointment && contextLead.account.hasPerformanceField('appointment')"
                        >
                            <lead-input-label>
                                {{ $t('result.appointment') }}
                            </lead-input-label>
                            <lead-input-value>
                                <appointment :in-client-card="true" :lead="contextLead" />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasPerformanceField('presented')">
                            <lead-input-label>
                                {{ $t('result.presented') }}
                                <icon
                                    class="ml-2 mb-1"
                                    name="regular/house-chimney-2"
                                    v-if="!!contextLead.home_presented_date"
                                />
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip :content="presentedTooltip">
                                    <presented :in-client-card="true" :lead="contextLead" />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasPerformanceField('road_test')">
                            <lead-input-label>
                                {{ $t('result.roadTest') }}
                                <icon
                                    class="ml-2 mb-1"
                                    name="regular/house-chimney-2"
                                    v-if="!!contextLead.home_road_test_date"
                                />
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip :content="roadTestTooltip">
                                    <road-test :in-client-card="true" :lead="contextLead" />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasPerformanceField('long_term')">
                            <lead-input-label>
                                {{ $t('result.longTerm') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip :content="verifiedSaleDisabledTooltip">
                                    <long-term :in-client-card="true" :lead="contextLead" />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasPerformanceField('be_back')">
                            <lead-input-label>
                                {{ $t('result.beBack') }}
                            </lead-input-label>
                            <lead-input-value>
                                <be-back :in-client-card="true" :lead="contextLead" />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasPerformanceField('take_over')">
                            <lead-input-label>
                                {{ $t('result.takeOver') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip :content="takeOverTooltip">
                                    <take-over :in-client-card="true" :lead="contextLead" />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>
                        <lead-input-group v-if="contextLead.account.hasPerformanceField('deposit')">
                            <lead-input-label>
                                {{ $t('clientCard.deposit') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip :content="verifiedSaleDisabledTooltip">
                                    <deposit :in-client-card="true" :lead="contextLead" />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>
                        <lead-input-group v-if="contextLead.account.hasPerformanceField('write_up')">
                            <lead-input-label>
                                {{ $t('clientCard.writeUp') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip :content="verifiedSaleDisabledTooltip">
                                    <write-up :in-client-card="true" :lead="contextLead" />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group
                            v-if="
                                contextLead.account.hasPerformanceField('refinanced') && contextLead.lead_type_id == 5
                            "
                        >
                            <lead-input-label>
                                <activix-tooltip :content="$t('accounts.edit.refinancedTooltip')">
                                    {{ $t('accounts.edit.refinanced') }}
                                </activix-tooltip>
                            </lead-input-label>
                            <lead-input-value>
                                <refinanced
                                    :in-client-card="true"
                                    :lead="contextLead"
                                    :parent-tooltip="refinancedTooltip"
                                />
                            </lead-input-value>
                        </lead-input-group>
                    </lead-input-wrapper>

                    <lead-input-wrapper class="w-full xs:w-1/2" section="performance" position="right">
                        <lead-input-group v-if="contextLead.account.hasPerformanceField('sale')">
                            <lead-input-label>
                                {{ contextLead.lead_type_id == 5 ? $t('result.renewed') : soldLabel }}
                                <icon class="ml-2" :name="$icons.phoneAppointment" v-if="contextLead.sale_by_phone" />
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip :content="saleCheckTooltip">
                                    <sale :in-client-card="true" :lead="contextLead" />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>
                        <lead-input-group v-if="contextLead.account.hasPerformanceField('twenty_four_hour')">
                            <lead-input-label>
                                {{ $t('result.twentyFourHr') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip :content="verifiedSaleDisabledTooltip">
                                    <twenty-four-hour :in-client-card="true" :lead="contextLead" />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>
                        <lead-input-group v-if="contextLead.account.hasPerformanceField('dealer_tour')">
                            <lead-input-label>
                                {{ $t('clientCard.dealerTour') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip :content="verifiedSaleDisabledTooltip">
                                    <dealer-tour :in-client-card="true" :lead="contextLead" />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>
                        <lead-input-group v-if="contextLead.account.hasPerformanceField('walk_around')">
                            <lead-input-label>
                                {{ $t('clientCard.walkAround') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip :content="verifiedSaleDisabledTooltip">
                                    <walk-around
                                        :lead="contextLead"
                                        :in-client-card="true"
                                    />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasPerformanceField('qualification')">
                            <lead-input-label>
                                {{ $t('clientCard.qualification') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip :content="qualificationTooltip">
                                    <input-text
                                        name="qualification"
                                        placeholder="h:mm"
                                        :record-id="contextLead.id"
                                        :value="contextLead.qualification"
                                        :disabled="qualificationDisabled"
                                    />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>
                    </lead-input-wrapper>
                    <lead-input-wrapper class="md:w-full" section="performance" display-type="box" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';

    // Components
    import WriteUp from '@/components/dashboards/columns/FollowUp/WriteUp.vue';
    import Appointment from '../dashboards/columns/FollowUp/Appointment.vue';
    import BeBack from '../dashboards/columns/FollowUp/BeBack.vue';
    import DealerTour from '../dashboards/columns/FollowUp/DealerTour.vue';
    import Deposit from '../dashboards/columns/FollowUp/Deposit.vue';
    import InputText from './inputs/InputText.vue';
    import LeadInputGroup from './LeadInputGroup.vue';
    import LeadInputLabel from './LeadInputLabel.vue';
    import LeadInputValue from './LeadInputValue.vue';
    import LeadInputWrapper from './LeadInputWrapper.vue';
    import PhoneAppointment from '../dashboards/columns/FollowUp/PhoneAppointment.vue';
    import Presented from '../dashboards/columns/FollowUp/Presented.vue';
    import Refinanced from '../dashboards/columns/FollowUp/Refinanced.vue';
    import RoadTest from '../dashboards/columns/FollowUp/RoadTest.vue';
    import LongTerm from '../dashboards/columns/FollowUp/LongTerm.vue';
    import Sale from '../dashboards/columns/FollowUp/Sale.vue';
    import TakeOver from '../dashboards/columns/FollowUp/TakeOver.vue';
    import TwentyFourHour from '../dashboards/columns/FollowUp/TwentyFourHour.vue';
    import WalkAround from '../dashboards/columns/FollowUp/WalkAround.vue';

    // Entities
    import LeadStatus from '../../entities/LeadStatus.js';
    import LeadType from '../../entities/LeadType.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import Supplier from '../../entities/Supplier.js';

    export default {
        components: {
            LongTerm,
            WriteUp,
            Appointment,
            BeBack,
            DealerTour,
            Deposit,
            InputText,
            LeadInputGroup,
            LeadInputLabel,
            LeadInputValue,
            LeadInputWrapper,
            PhoneAppointment,
            Presented,
            Refinanced,
            RoadTest,
            Sale,
            TakeOver,
            TwentyFourHour,
            WalkAround,
        },

        computed: {
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            soldLabel() {
                return this.contextAccount.hasSupplier(Supplier.POLESTAR) ? this.$tc('clientCard.order') : this.$t('result.sale');
            },

            // Only for renewal
            currentLeasedVehicle() {
                return this.contextLead.exchange_vehicles.find(vehicle => {
                    return vehicle.modality == 'leasing';
                });
            },

            showPhoneAppointment() {
                return this.contextLead.lead_type_id != LeadType.WALK_IN && !!this.contextLead.appt_call_date;
            },

            showAppointment() {
                if (this.contextLead.lead_type_id == LeadType.WALK_IN) {
                    return false;
                }

                if (this.contextLead.appt_call_date && !this.contextLead.appointment_date) {
                    return false;
                }

                return true;
            },

            soldVehicle() {
                return this.contextLead.getSoldOrFirstWantedVehicle();
            },

            roadTestTooltip() {
                if (this.leadHasInvalidStatus) {
                    return `${this.$t(`clientCard.statusTooltip.${this.contextLead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.roadTest'),
                    ])}`;
                }

                if (this.contextLead.disabledBySaleVerified(this.authUser)) {
                    return `<em>${this.$t('clientCard.verifiedSaleUneditable')}</em>`;
                }

                return '';
            },

            qualificationDisabled() {
                if (this.leadHasInvalidStatus) {
                    return true;
                }

                if (!this.$can('leads.qualification:update')) {
                    return true;
                }

                if (this.contextLead.disabledBySaleVerified(this.authUser)) {
                    return true;
                }

                return this.guest;
            },

            qualificationTooltip() {
                if (this.leadHasInvalidStatus) {
                    return `${this.$t(`clientCard.statusTooltip.${this.contextLead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.qualification'),
                    ])}`;
                }

                if (this.contextLead.disabledBySaleVerified(this.authUser)) {
                    return `<em>${this.$t('clientCard.verifiedSaleUneditable')}</em>`;
                }

                return '';
            },

            takeOverTooltip() {
                if (this.leadHasInvalidStatus) {
                    return `${this.$t(`clientCard.statusTooltip.${this.contextLead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.takeOver'),
                    ])}`;
                }

                let tooltip = '';

                if (!this.contextLead.presented && !this.contextLead.home_presented_date) {
                    tooltip = this.$t('clientCard.needPresentedChecked');
                }

                return tooltip;
            },

            presentedTooltip() {
                if (this.leadHasInvalidStatus) {
                    return '';
                }

                if (
                    this.contextLead.sale &&
                    !this.contextLead.sale_by_phone &&
                    this.contextLead.lead_type_id != LeadType.WALK_IN
                ) {
                    if (this.contextLead.lead_type_id == LeadType.RENEWAL) {
                        return this.$t('clientCard.needRenewalUnchecked');
                    }

                    return this.$t('clientCard.needSaleUnchecked');
                }

                return '';
            },

            saleCheckTooltip() {
                if (this.contextLead.sale_date) {
                    if (this.contextLead.delivered) {
                        return this.$t('clientCard.needDeliveredUnchecked');
                    }

                    if (this.contextLead.sale_by_phone) {
                        return this.$tc('result.saleByPhone', 1);
                    }
                } else if (this.contextLead.refinanced) {
                    return this.$t('clientCard.needRefinancedUnchecked');
                }

                if (this.disabledByStockRule) {
                    return this.$t('clientCard.needVehicleInfoAndStockForSale');
                }

                if (this.disabledByVehicleInfoRule) {
                    return this.$t('clientCard.needVehicleInfoForSale');
                }

                if (this.contextLead.disabledBySaleVerified(this.authUser)) {
                    return `<em>${this.$t('clientCard.verifiedSaleUneditable')}</em>`;
                }

                return '';
            },

            verifiedSaleDisabledTooltip() {
                if (this.contextLead.disabledBySaleVerified(this.authUser)) {
                    return `<em>${this.$t('clientCard.verifiedSaleUneditable')}</em>`;
                }
                return '';
            },

            refinancedTooltip() {
                if (this.contextLead.disabledBySaleVerified(this.authUser)) {
                    return `<em>${this.$t('clientCard.verifiedSaleUneditable')}</em>`;
                }

                if (this.contextLead.sale_by_phone) {
                    return this.$tc('result.saleByPhone', 1);
                }

                if (this.contextLead.delivered) {
                    return this.$t('clientCard.needDeliveredUnchecked');
                }

                if (this.contextLead.sale && !this.contextLead.refinanced_date) {
                    return this.$t('clientCard.needRenewalUnchecked');
                }

                return '';
            },

            leadHasInvalidStatus() {
                if ([LeadStatus.INVALID, LeadStatus.LOST, LeadStatus.DUPLICATE].includes(this.contextLead.status)) {
                    return true;
                }

                return false;
            },

            disabledByVehicleInfoRule() {
                return (
                    !this.contextLead.sale_date &&
                    this.contextLead.account.stock_required_for_sale &&
                    this.contextLead.wanted_vehicles.every(vehicle => {
                        return !vehicle.make || !vehicle.model;
                    })
                );
            },

            disabledByStockRule() {
                return (
                    !this.contextLead.sale_date &&
                    this.contextLead.account.client_card_fields.wanted_vehicle.stock &&
                    this.contextLead.account.stock_required_for_sale &&
                    this.contextLead.wanted_vehicles.every(vehicle => {
                        return !vehicle.make || !vehicle.model || this.emptyStock(vehicle);
                    })
                );
            },
        },

        methods: {
            emptyStock(vehicle) {
                return !vehicle.stock_state || (vehicle.stock_state == 'stock' && !vehicle.stock);
            },
        },
    };
</script>
