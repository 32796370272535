<template>
    <section class="p-12 border-b border-gray-200 bg-gray-100">
        <figure class="relative flex justify-center items-center">
            <progress
                class="w-full h-3px"
                max="100"
                :value="percentValue"
            />

            <div class="absolute flex justify-between items-center w-full">
                <activix-tooltip
                    :content="step.tooltip"
                    :key="stepIndex"
                    v-for="(step, stepIndex) in steps"
                >
                    <div
                        class="absolute flex-shrink-0 transform rounded-full"
                        :class="{
                            'flex items-center justify-center w-9 h-9 shadow bg-white -translate-x-5 | -translate-x-6 md:w-12 md:h-12': step.icon,
                            'w-3 h-3 -translate-x-1.5': !step.icon,
                            'text-gray-600': step.icon && stepIndex > lastValidStepIndex,
                            'text-blue-500': step.icon && stepIndex <= lastValidStepIndex,
                            'bg-gray-500' : !step.icon && stepIndex > lastValidStepIndex,
                            'bg-blue-500' : !step.icon && stepIndex <= lastValidStepIndex,
                        }"
                        :style="{ marginLeft: `${stepsInterval * stepIndex}%` }"
                    >
                        <icon class="text-lg | md:text-xl" :name="step.icon" v-if="step.icon" />
                    </div>
                </activix-tooltip>
            </div>
        </figure>
    </section>
</template>

<script>
    export default {
        props: {
            percent: {
                type: Number,
            },
            steps: {
                type: Array,
                default: () => [],
                validator: value => value.every(item => {
                    const hasIsActiveProp = ['function', 'boolean'].includes(typeof item.isActive);
                    const subStepsIsValid = !item.subSteps || (
                        Array.isArray(item.subSteps) &&
                        item.subSteps.every(subItem => ['function', 'boolean'].includes(typeof subItem))
                    );

                    return hasIsActiveProp && subStepsIsValid;
                }),
            },
        },

        computed: {
            percentValue() {
                if (this.percent) {
                    return this.percent;
                }

                if (this.lastValidStepIndex === 0) {
                    return 0;
                }

                const percent = this.lastValidStepIndex * this.stepsInterval;
                const subSteps = [...this.steps[this.lastValidStepIndex].subSteps || []].reverse();
                let lastValidSubStepIndex = subSteps.findIndex(subStep => this.stepIsActive(subStep));

                if (lastValidSubStepIndex === -1) {
                    return percent;
                }

                const subStepsCount = subSteps.length;
                lastValidSubStepIndex = subStepsCount - lastValidSubStepIndex;
                const subStepPercent = this.stepsInterval / (subStepsCount + 1) * lastValidSubStepIndex;

                return percent + subStepPercent;
            },

            lastValidStepIndex() {
                const reversedIndex = this.reversedSteps.findIndex(step => this.stepIsActive(step.isActive));

                return reversedIndex >= 0 ? this.stepsCount - reversedIndex : 0;
            },

            reversedSteps() {
                return [...this.steps].reverse();
            },

            stepsCount() {
                return this.steps.length - 1;
            },

            stepsInterval() {
                return 100 / this.stepsCount;
            },
        },

        methods: {
            stepIsActive(isActive) {
                if (typeof isActive === 'function') {
                    return isActive();
                }

                return isActive;
            },
        },

        created() {
            if (typeof this.percent === 'undefined' && !this.steps.length) {
                console.error('ProgressBar needs either a percent or steps prop.');
            }
        },
    };
</script>

<style scoped>
    progress,
    progress::-webkit-progress-bar {
        @apply rounded-full bg-gray-500;
    }

    /* Do not merge with "progress::-moz-progress-bar". It does not work. */
    progress::-webkit-progress-value {
        @apply rounded-full bg-blue-500;
    }

    progress::-moz-progress-bar {
        @apply rounded-full bg-blue-500;
    }
</style>
