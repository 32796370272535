<template>
    <progress-bar :steps="progressBarSteps" />
</template>

<script>
    import ProgressBar from '@/components/container/lead/ProgressBar.vue';
    import Lead from '@/entities/Lead.js';

    export default {
        components: { ProgressBar },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            progressBarSteps() {
                return [
                    {
                        tooltip: this.$t('clientCard.boxes.salesProcess.progress.leadAcquired'),
                        isActive: true,
                    },
                    {
                        tooltip: this.$t('clientCard.boxes.salesProcess.progress.appointmentScheduled'),
                        isActive: () => !!this.lead.appointment_date || !!this.lead.appt_call_date,
                        icon: 'regular/calendar-3',
                    },
                    {
                        tooltip: this.$t('clientCard.boxes.salesProcess.progress.clientVisited'),
                        isActive: () => !!this.lead.presented_date || !!this.lead.home_presented_date,
                        icon: 'regular/walking',
                    },
                    {
                        tooltip: this.$t('clientCard.boxes.salesProcess.progress.testDriveDone'),
                        isActive: () => !!this.lead.road_test_date,
                    },
                    {
                        tooltip: this.$t('clientCard.boxes.salesProcess.progress.vehicleSold'),
                        isActive: () => !!this.lead.sale_date,
                        icon: 'regular/check-circle-1',
                    },
                ];
            },
        },
    };
</script>
