<template>
    <lead-link
        :disabled="disabled"
        :center-icon="centerIcon"
        :editing="editing"
        :valid="valid"
        class="flex-grow"
        @accept="triggerAccept"
        @cancel="cancel"
        @edit="editField"
        @blur="onBlur"
        @copy="copyUrl"
    >
        <activix-masked-input
            class="w-full | md:w-auto"
            :input-class="['input-sm', { 'p-0 border-none bg-transparent': lgLayout }]"
            :mask-type="maskType"
            :country="country"
            :placeholder="placeholder"
            :integer-limit="maxLengthNormalized"
            :disabled="disabled"
            :custom-mask="customMask"
            :allow-zero-value="allowZeroValue"
            ref="input"
            v-model="currentValue"
        />

        <template #value>
            <template v-if="!empty(unmaskedValue)">
                <activix-tooltip :content="!valid ? $t('general.invalidLink') : ''">
                    <activix-link
                        :target="'_blank'"
                        :href="customUrl"
                        :color="!valid ? 'grey' : 'blue'"
                        :disabled="!valid"
                    >
                        {{ unmaskedValue }}
                    </activix-link>
                </activix-tooltip>
            </template>
            <template v-else>
                {{ emptyValue }}
            </template>
        </template>
    </lead-link>
</template>

<script>
    import { mapActions } from 'pinia';
    import { toUrl } from '@/utils/index.js';
    import { parseCustomField } from '../../../value-objects/CustomField.js';

    // Components
    import LeadLink from '../LeadLink.vue';
    import ActivixLink from '../../elements/ActivixLink.vue';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        components: {
            LeadLink,
            ActivixLink,
        },

        props: {
            name: String,
            prefix: String,
            value: [String, Number, Array],
            placeholder: String,
            type: String,
            disabled: Boolean,
            recordId: Number,
            maxLength: Number,
            country: {
                type: String,
                default: 'CA',
            },
            vehicle: {
                type: Boolean,
                default: false,
            },
            customField: {
                type: Object,
                default: null,
            },
            emptyValue: {
                type: String,
                default: '...',
            },
            centerIcon: {
                type: Boolean,
                default: true,
            },
            allowZeroValue: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                currentValue: null,
                editing: false,
            };
        },

        computed: {
            maxLengthNormalized() {
                return this.maxLength || 255;
            },

            valid() {
                return !!(this.unmaskedValue || '').match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            },

            maskType() {
                return 'custom';
            },

            customMask() {
                return Array(this.maxLengthNormalized).fill(/./);
            },

            unmaskedValue() {
                return this.currentValue || null;
            },

            customUrl() {
                return toUrl(this.unmaskedValue);
            },
        },

        watch: {
            value() {
                if ((!this.allowZeroValue && this.value === 0 && empty(this.value)) || this.value === false) {
                    this.currentValue = null;
                    return;
                }

                this.currentValue = this.decodeString(this.value).trim();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'updateLeadVehicleAction']),

            async editField() {
                if (this.disabled) {
                    return;
                }

                this.editing = true;

                await this.$nextTick();

                this.$refs.input.focus();
            },

            triggerAccept() {
                if (!this.editing) {
                    return;
                }

                this.accept(this.unmaskedValue);
            },

            async accept(value) {
                this.editing = false;

                // Only update if value changed and recordId is set
                if (
                    value == this.value ||
                    (empty(value) && empty(this.value) && !this.allowZeroValue && value == 0) ||
                    empty(this.recordId)
                ) {
                    return;
                }

                if (!this.allowZeroValue && value == 0) {
                    value = null;
                }

                let options = {};
                const payload = {
                    [this.name]: value,
                };

                if (this.customField) {
                    options = {
                        customField: { ...this.customField, value: parseCustomField.toSave(value, this.customField.type) },
                    };
                }

                await this.updateLeadAction(this.recordId, payload, options);
            },

            cancel() {
                this.editing = false;

                this.currentValue = this.decodeString(this.value).trim();
            },

            init() {
                if (empty(this.value) && !this.allowZeroValue && this.value !== 0) {
                    return;
                }

                this.currentValue = this.decodeString(this.value).trim();
            },

            onBlur() {
                // Delay is necessary to prevent event overlapping
                setTimeout(() => {
                    this.triggerAccept();
                }, 50);
            },

            copyUrl() {
                if (this.customUrl) {
                    navigator.clipboard.writeText(this.customUrl);
                    this.$notify.info(this.$t('toastr.urlCopied'));
                }
            },
        },

        created() {
            this.init();
        },
    };
</script>
