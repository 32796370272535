<template>
    <activix-modal size="sm" :closable="false" :opened="opened">
        <template slot="header">
            <h4 class="modal-title">
                {{ modalTitle }}
            </h4>
        </template>

        <template slot="body">
            <div class="text-center my-1" :key="vehicle.id" v-for="vehicle in contextLead.wanted_vehicles">
                <activix-radio
                    name="vehicleExport"
                    :value="vehicle.id"
                    :disabled="vehicle.exported_to_serti"
                    v-model="exportedVehicle"
                >
                    <template
                        v-if="
                            !empty(vehicle.make) ||
                                !empty(vehicle.model) ||
                                !empty(vehicle.version) ||
                                !empty(vehicle.year)
                        "
                    >
                        {{ vehicle.make }}
                        {{ vehicle.model }}
                        {{ vehicle.version }}
                        {{ vehicle.year }}
                    </template>

                    <template v-else>
                        {{ $t('clientCard.unknown') }}
                    </template>
                </activix-radio>
            </div>
        </template>

        <template slot="footer">
            <activix-button @click="uncheck">
                {{ $t('general.cancel') }}
            </activix-button>
            <activix-button
                type="primary"
                :disabled="!exportedVehicle"
                :loading="loading"
                @click="submit"
            >
                {{ $t('general.save') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import { mapState } from 'pinia';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        props: {
            opened: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                exportedVehicle: null,
                loading: false,
                modalTitle: this.$t('clientCard.chooseVehicleToExport'),
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
        },

        methods: {
            uncheck() {
                this.$eventBus.$emit('cancel-follow-up');

                this.close();
            },

            submit() {
                this.loading = true;

                // Select vehicle to export
                this.$eventBus.$emit('send-to-supplier', this.exportedVehicle);
                this.close();

                this.$nextTick(() => {
                    this.loading = false;
                });
            },

            close() {
                this.$emit('update:opened', false);
            },
        },
    };
</script>
