import Enum from './Enum.js';

export default class VAutoResponse extends Enum {
    static get entries() {
        return {
            successAdvisor: 'success_advisor',
            successFallback: 'success_fallback',
            warningNoUsername: 'warning_no_username',
            warningUsernameError: 'warning_username_error',
            warningFallbackNoUsername: 'warning_fallback_no_username',
            warningFallbackUsernameError: 'warning_fallback_username_error',
            warningInvalidFallback: 'warning_invalid_fallback',
            errorUserNotActive: 'error_user_not_active',
        };
    }

    static get SUCCESS_ADVISOR() {
        return this.entries.successAdvisor;
    }

    static get SUCCESS_FALLBACK() {
        return this.entries.successFallback;
    }

    static get WARNING_NO_USERNAME() {
        return this.entries.warningNoUsername;
    }

    static get WARNING_USERNAME_ERROR() {
        return this.entries.warningUsernameError;
    }

    static get WARNING_FALLBACK_NO_USERNAME() {
        return this.entries.warningFallbackNoUsername;
    }

    static get WARNING_FALLBACK_USERNAME_ERROR() {
        return this.entries.warningFallbackUsernameError;
    }

    static get WARNING_INVALID_FALLBACK() {
        return this.entries.warningInvalidFallback;
    }

    static get ERROR_USER_NOT_ACTIVE() {
        return this.entries.errorUserNotActive;
    }
}
