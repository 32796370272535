<template>
    <flexible-input>
        <input-label slot="label" class="flex items-center" :blocked-reason="internalBlockedReason">
            {{ $t('clientCard.vehicles.version') }}
            <activix-tooltip :content="$t('export.honda.needTrimAlert')" v-if="needTrimAlert">
                <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
            </activix-tooltip>
        </input-label>

        <activix-tooltip :content="vehicleValueTooltip">
            <invisible-multiselect
                :append-missing-value="true"
                :disabled="disabled || vehicle.isExported || hasVehicleValue || isLinkedToInventory && !empty(inventoryUnit.trim)"
                :nullable="true"
                :options="options"
                :value="vehicle.trim"
                @input="$emit('input', $event)"
                v-if="hasValidOptions"
            />
            <invisible-input
                class="flex-1"
                :disabled="disabled || hasVehicleValue || isLinkedToInventory && !empty(inventoryUnit.trim)"
                :value="vehicle.trim"
                @input="$emit('input', $event)"
                v-else
            />
        </activix-tooltip>
    </flexible-input>
</template>

<script>
    import { mapActions, mapState, mapWritableState } from 'pinia';
    import { useGlobalStore } from '@/store/store';
    import { useClientCardStore } from '@/store/modules/clientCard/store.js';

    import FlexibleInput from '@/components/container/input/FlexibleInput.vue';
    import InputLabel from '@/components/presentational/input/InputLabel.vue';
    import InvisibleInput from '@/components/container/input/InvisibleInput';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect.vue';

    import Account from '@/entities/Account';
    import LeadVehicle from '@/entities/LeadVehicle.js';
    import LeadVehicleType from '@/entities/LeadVehicleType.js';
    import Supplier from '@/entities/Supplier.js';

    export default {
        components: {
            FlexibleInput,
            InputLabel,
            InvisibleInput,
            InvisibleMultiselect,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            inventoryUnit: {
                type: Object,
                required: false,
            },
            stockManagementEnabled: {
                type: Boolean,
                required: true,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        data: () => ({
            noTrimOnModel: ['Element', 'Prelude', 'S2000'],
        }),

        computed: {
            ...mapState(useClientCardStore, ['vehicleMakes', 'vehicleModels']),
            ...mapWritableState(useClientCardStore, ['vehicleTrims']),

            hasValidOptions() {
                return this.options.filter(option => option.value).length > 0;
            },

            hasVehicleValue() {
                return !empty(this.vehicle.vehicle_value);
            },

            internalBlockedReason() {
                if (this.blockedReason) {
                    return this.blockedReason;
                }

                if (this.vehicle.isExported) {
                    return this.$t('clientCard.blocked.vehicleExported');
                }

                if (this.hasVehicleValue) {
                    return this.$t('clientCard.blocked.vehicleAppraisalCompleted');
                }

                if (this.isLinkedToInventory && !empty(this.inventoryUnit.trim)) {
                    return this.$t('clientCard.blocked.vehicleStock');
                }

                return '';
            },

            isLinkedToInventory() {
                return !empty(this.inventoryUnit) && this.stockManagementEnabled;
            },

            needModelWithTrimAlert() {
                if (this.vehicle.make !== 'Honda') {
                    return false;
                }

                const suppliable = this.vehicle.lead.suppliers.some(suppliable => {
                    return suppliable.id === Supplier.HONDA_CANADA &&
                        suppliable.related_id;
                });

                if (suppliable) {
                    return false;
                }

                const supplier = this.account.suppliers.some(supplier => {
                    return supplier.id === Supplier.HONDA_CANADA &&
                        supplier.outbound;
                });

                if (!supplier || this.noTrimOnModel.includes(this.vehicle.model)) {
                    return false;
                }

                return this.options.length === 0;
            },

            needTrimAlert() {
                if (this.vehicle.type !== LeadVehicleType.WANTED) {
                    return false;
                }

                if (this.vehicle.make !== 'Honda' || this.needModelWithTrimAlert) {
                    return false;
                }

                const suppliable = this.vehicle.lead.suppliers.some(suppliable => {
                    return suppliable.id === Supplier.HONDA_CANADA &&
                        suppliable.related_id;
                });

                if (suppliable) {
                    return false;
                }

                const supplier = this.account.suppliers.some(supplier => {
                    return supplier.id === Supplier.HONDA_CANADA &&
                        supplier.outbound;
                });

                if (!supplier || this.noTrimOnModel.includes(this.vehicle.model)) {
                    return false;
                }

                return !this.vehicle.trim;
            },

            options() {
                const modelId = this.vehicleModel.id;

                return (this.vehicleTrims[modelId] || [])
                    .map(trim => {
                        return {
                            value: trim.name,
                            text: trim.name,
                        };
                    })
                    .sort((a, b) => a.text.localeCompare(b.text));
            },

            vehicleMake() {
                return this.vehicleMakes.find(make => make.name === this.vehicle.make) || {};
            },

            vehicleModel() {
                const makeId = this.vehicleMake.id;
                return this.vehicleModels[makeId]?.find(model => model.name === this.vehicle.model) || {};
            },

            vehicleModelId() {
                return this.vehicleModel.id;
            },

            vehicleModelTrims() {
                return this.vehicleTrims[this.vehicleModelId];
            },

            vehicleValueTooltip() {
                return this.hasVehicleValue ? this.$t('clientCard.vehicleEvaluate') : '';
            },
        },

        watch: {
            vehicleModelId: {
                immediate: true,
                handler() {
                    if (this.account.power_sport) {
                        return;
                    }

                    this.triggerFetchVehicleTrims();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, [
                'fetchVehicleTrims',
            ]),

            async triggerFetchVehicleTrims() {
                if (!this.vehicleModelId || this.vehicleModelTrims) {
                    return;
                }

                const trims = await this.fetchVehicleTrims(this.vehicleModelId);
                this.$set(this.vehicleTrims, this.vehicleModelId, trims);
            },
        },
    };
</script>
