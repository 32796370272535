<template>
    <lead-input-group :inline="fieldIsBox">
        <lead-input-label :inline="fieldIsBox">
            <activix-tooltip :content="field.parentName">
                <span>{{ field.name }}</span>
            </activix-tooltip>
        </lead-input-label>
        <lead-input-value :inline="fieldIsBox" :collapsing="!fieldIsBox">
            <div class="input-wrapper">
                <activix-tooltip :content="tooltip">
                    <component
                        :is="component"
                        class="max-w-full"
                        :name="field.field"
                        :disabled="isDisabled"
                        :options="fieldSelectPickerOptions"
                        :record-id="recordId"
                        :lead-id="contextLead.id"
                        :value="castedValue"
                        :multiple="true"
                        :taggable="true"
                        :custom-field="field"
                        :allow-empty="true"
                        :enable-empty="true"
                        :start-view="2"
                        :type="type"
                        :searchable="true"
                        :vehicle="isVehicle"
                    />
                </activix-tooltip>
            </div>
        </lead-input-value>
    </lead-input-group>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';
    import InputText from './inputs/InputText.vue';
    import InputLink from './inputs/InputLink.vue';
    import SelectPicker from './inputs/SelectPicker.vue';
    import LeadInputGroup from './LeadInputGroup.vue';
    import LeadInputLabel from './LeadInputLabel.vue';
    import LeadInputValue from './LeadInputValue.vue';
    import DateTimePicker from './inputs/DateTimePicker.vue';
    import Checkbox from './inputs/Checkbox.vue';
    import TextArea from './inputs/TextArea.vue';
    import InlineTextArea from './inputs/InlineTextArea.vue';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            InputText,
            InputLink,
            SelectPicker,
            LeadInputGroup,
            LeadInputLabel,
            LeadInputValue,
            DateTimePicker,
            Checkbox,
            TextArea,
            InlineTextArea,
        },

        props: {
            field: {
                type: Object,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            vehicle: {
                type: Object,
                default: () => {},
            },
        },
        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),
            ...mapState(useContextStore, ['contextLead']),

            recordId() {
                return this.vehicle ? this.vehicle.id : this.contextLead.id;
            },

            isVehicle() {
                return !!this.vehicle;
            },

            castedValue() {
                switch (this.field.type) {
                    case 'array':
                        return this.value.map(item => {
                            return {
                                value: item,
                                text: item,
                            };
                        });

                    case 'datetime':
                        return this.value.toString();

                    case 'currency':
                        return this.value[0] ? this.value[0] / 100 : '';

                    case 'boolean':
                        return this.value[0];

                    default:
                        return this.getTranslation(this.value, this.field.field);
                }
            },

            disabledBySaleVerified() {
                if (this.vehicle) {
                    return this.contextLead.disabledBySaleVerified(this.authUser, this.vehicle);
                }

                return (this.field.section == 'performance' && this.contextLead.disabledBySaleVerified(this.authUser));
            },

            isDisabled() {
                if (this.disabled) {
                    return true;
                }

                if (this.disabledBySaleVerified) {
                    return true;
                }

                return [
                    'custom_activix-users_post',
                    'custom_activix-users_role',
                    'custom_activix-users_concession',
                ].includes(this.field.field);
            },

            fieldSelectPickerOptions() {
                return this.field.select_picker_options
                    .map(item => {
                        return {
                            value: item,
                            text: item,
                        };
                    })
                    .sort((a, b) => a.text.localeCompare(b.text));
            },

            fieldIsBox() {
                return this.field.display == 'box' && this.field.type == 'textarea';
            },

            value() {
                const customField = (this.vehicle || this.contextLead).custom_fields.find(row => row.id == this.field.id);

                return customField ? customField.pivot.value : [];
            },

            type() {
                if (this.field.type == 'currency') {
                    return 'currency';
                }

                if (this.field.type == 'array' && this.vehicle) {
                    return 'vehicle';
                }

                if (this.field.type == 'url') {
                    return 'url';
                }

                return '';
            },

            component() {
                switch (this.field.type) {
                    case 'array':
                        return 'select-picker';
                    case 'datetime':
                        return 'date-time-picker';
                    case 'boolean':
                        return 'checkbox';
                    case 'textarea':
                        return this.field.display ? 'inline-text-area' : 'text-area';
                    case 'url':
                        return 'input-link';
                    case 'string':
                    case 'currency':
                    default:
                        return 'input-text';
                }
            },

            tooltip() {
                if (this.disabled) {
                    if (this.guest) {
                        return this.$t('clientCard.guestMode');
                    }
                }

                if (this.disabledBySaleVerified) {
                    return `<em>${this.$t('clientCard.verifiedSaleUneditable')}</em>`;
                }

                return '';
            },
        },

        methods: {
            getTranslation(value, field) {
                switch (field) {
                    case 'custom_activix-users_post':
                        return this.$t(`database.post_${value}`);

                    case 'custom_activix-users_role':
                        return this.$t(`database.role_${value}`);

                    default:
                        return value;
                }
            },
        },
    };
</script>
