<template>
    <div
        class="grid grid-cols-1 gap-3 | md:gap-6 xl:gap-3 2xl:gap-6"
        :class="columnLayout"
    >
        <user-assignation-item
            :blocked-reason="advisorBlockedReason"
            :label="$t('clientCard.advisor')"
            :readonly="!advisorEnabled || !!lead.second_user_id"
            :reassignment-allowed="displayAdvisorReassignmentButton"
            :reassignment-value="lead.second_user_id"
            :reassignment-value-tooltip="advisorTransferTooltip"
            :users="assignmentUsers"
            :value="lead.user_id"
            ref="advisorAssignation"
            @input="updateLead({ user_id: $event })"
            @open-reassignment-user-request="openUserReassignmentRequestModal('advisor')"
        />
        <user-assignation-item
            :blocked-reason="bdcAgentBlockedReason"
            :label="$t('clientCard.bdcAgent')"
            :readonly="!bdcAgentEnabled || !!lead.second_bdc_user_id"
            :reassignment-allowed="displayBdcReassignmentButton"
            :reassignment-value="lead.second_bdc_user_id"
            :reassignment-value-tooltip="bdcTransferTooltip"
            :users="assignmentUsers"
            :value="lead.bdc_user_id"
            @input="updateLead({ bdc_user_id: $event })"
            @open-reassignment-user-request="openUserReassignmentRequestModal('bdc')"
            v-if="showBdcAgent"
        />
        <user-assignation-item
            :blocked-reason="commercialBlockedReason"
            :label="$t('clientCard.commercialShort')"
            :readonly="!commercialEnabled"
            :users="assignmentUsers"
            :value="lead.commercial_id"
            @input="updateLead({ commercial_id: $event })"
            v-if="!lead.isService()"
        />
        <user-assignation-item
            :blocked-reason="serviceAgentBlockedReason"
            :class="{ 'text-grey-500': isSetBySerti('service_agent_id') }"
            :label="$t('clientCard.serviceAgent')"
            :readonly="!serviceAgentEnabled"
            :users="assignmentUsers"
            :value="lead.service_agent_id"
            @input="updateLead({ service_agent_id: $event })"
            v-if="lead.isService()"
        />
        <user-assignation-item
            :blocked-reason="serviceAdvisorBlockedReason"
            :class="{ 'text-grey-500': isSetBySerti('service_advisor_id') }"
            :label="$t('clientCard.serviceAdvisor')"
            :readonly="!serviceAdvisorEnabled"
            :users="assignmentUsers"
            :value="lead.service_advisor_id"
            @input="updateLead({ service_advisor_id: $event })"
            v-if="lead.isService()"
        />

        <assignation-user-request
            :opened="modals.reassignmentRequest.opened"
            :request-type="modals.reassignmentRequest.type"
            @save="saveUserReassignmentRequest"
            @closed="modals.reassignmentRequest.opened = false"
        />

        <assignation-user-transfer
            :opened.sync="modals.reassignmentTransfer.opened"
            :transfer-type="modals.reassignmentTransfer.type"
            @accept="acceptUserReassignment"
            @decline="declineUserReassignment"
            @cancel="cancelUserReassignment"
            @closed="modals.reassignmentTransfer.opened = false"
        />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    import AssignationUserRequest from '@/components/modals/AssignationUserRequest.vue';
    import AssignationUserTransfer from '@/components/modals/AssignationUserTransfer.vue';
    import UserAssignationItem from '@/components/container/lead/input/UserAssignationItem.vue';

    import CreatedMethods from '@/entities/CreatedMethod.js';
    import Lead from '@/entities/Lead.js';

    export default {
        components: {
            AssignationUserRequest,
            AssignationUserTransfer,
            UserAssignationItem,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        data: () => ({
            modals: {
                reassignmentRequest: {
                    opened: false,
                    type: '',
                },
                reassignmentTransfer: {
                    opened: false,
                    type: '',
                },
            },
        }),

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest', 'assignmentUsers']),

            advisorBlockedReason() {
                if (this.advisorEnabled && !this.lead.second_user_id) {
                    return '';
                }

                if (this.guest) {
                    return this.$t('clientCard.guestMode');
                }

                if (this.lead.second_user_id) {
                    return this.$t('clientCard.blocked.userAssignation.reassignationInProgress');
                }

                if (this.lead.user_id === this.authUser.id) {
                    if (!this.$feature.isEnabled('prevent-self-assignment')) {
                        return '';
                    }

                    return this.$t('clientCard.blocked.userAssignation.selfAssignementForbidden');
                }

                if (this.lead.user_id) {
                    return this.$t('clientCard.blocked.userAssignation.cannotModifyAlreadyAssignedUser');
                }

                if (this.authUser.hasAdvisorSkills() && !this.lead.account.assigned_lead) {
                    return this.$t('clientCard.blocked.userAssignation.selfAssignementForbidden');
                }

                return '';
            },

            advisorEnabled() {
                if (this.guest) {
                    return false;
                }

                if (this.lead.user_id === this.authUser.id) {
                    return !this.$feature.isEnabled('prevent-self-assignment');
                }

                if (
                    this.authUser.isAdmin() ||
                    this.authUser.isDirector() ||
                    this.authUser.isBdcDirector() ||
                    this.authUser.isSuperBdc() ||
                    this.authUser.isBdc()
                ) {
                    return true;
                }

                if (this.lead.user_id) {
                    return false;
                }

                return !this.authUser.hasAdvisorSkills() || this.lead.account.assigned_lead;
            },

            advisorHasDoubleAssignation() {
                return !!this.lead.user_id && !!this.lead.second_user_id;
            },

            advisorTransferTooltip() {
                const secondUserName = this.lead.second_user.fullName;

                if (!secondUserName) {
                    return '';
                }

                const userName = this.lead.user.fullName;

                return `
                    ${this.$t('clientCard.assignation.transferAssignedTooltip', [userName])}
                    <br>
                    ${this.$t('clientCard.assignation.transferWaitingTooltip', [secondUserName])}
                `;
            },

            bdcAgentBlockedReason() {
                if (this.bdcAgentEnabled && !this.lead.second_bdc_user_id) {
                    return '';
                }

                if (this.guest) {
                    return this.$t('clientCard.guestMode');
                }

                if (this.lead.second_bdc_user_id) {
                    return this.$t('clientCard.blocked.userAssignation.reassignationInProgress');
                }

                if (this.lead.bdc_user_id === this.authUser.id) {
                    if (!this.$feature.isEnabled('prevent-self-assignment')) {
                        return '';
                    }

                    return this.$t('clientCard.blocked.userAssignation.selfAssignementForbidden');
                }

                if (this.lead.bdc_user_id) {
                    return this.$t('clientCard.blocked.userAssignation.cannotModifyAlreadyAssignedUser');
                }

                if (this.authUser.hasAdvisorSkills() && !this.lead.account.assigned_lead) {
                    return this.$t('clientCard.blocked.userAssignation.selfAssignementForbidden');
                }

                return '';
            },

            bdcAgentEnabled() {
                if (this.guest) {
                    return false;
                }

                if (this.lead.bdc_user_id === this.authUser.id) {
                    return !this.$feature.isEnabled('prevent-self-assignment');
                }

                if (
                    this.authUser.isAdmin() ||
                    this.authUser.isDirector() ||
                    this.authUser.isBdcDirector() ||
                    this.authUser.isSuperBdc()
                ) {
                    return true;
                }

                if (this.lead.bdc_user_id) {
                    return false;
                }

                if (this.authUser.isAdvisor() && this.lead.account.unrestricted_assignment) {
                    return true;
                }

                return !this.authUser.hasAdvisorSkills() || this.lead.account.assigned_lead;
            },

            bdcHasDoubleAssignation() {
                return !!this.lead.bdc_user_id && !!this.lead.second_bdc_user_id;
            },

            bdcTransferTooltip() {
                const secondBdcName = this.lead.second_bdc_user.fullName;

                if (!secondBdcName) {
                    return '';
                }

                const bdcName = this.lead.bdc_user.fullName;

                return `
                    ${this.$t('clientCard.assignation.transferAssignedTooltip', [bdcName])}
                    <br>
                    ${this.$t('clientCard.assignation.transferWaitingTooltip', [secondBdcName])}
                `;
            },

            columnLayout() {
                if (this.lead.isService()) {
                    return this.showBdcAgent ? 'md:grid-cols-2' : 'md:grid-cols-3';
                }

                return this.showBdcAgent ? 'md:grid-cols-3' : 'md:grid-cols-2';
            },

            commercialBlockedReason() {
                if (this.guest) {
                    return this.$t('clientCard.guestMode');
                }

                return '';
            },

            commercialEnabled() {
                return !this.guest;
            },

            displayAdvisorReassignment() {
                return (
                    this.authUser.isAdvisor() &&
                    !!this.lead.user_id &&
                    (this.$feature.isEnabled('prevent-self-assignment') || this.authUser.id !== this.lead.user_id)
                );
            },

            displayAdvisorReassignmentButton() {
                return !this.guest &&
                    (!!this.lead.second_user_id || this.displayAdvisorReassignment);
            },

            displayBdcReassignment() {
                return (
                    this.authUser.isBdc() &&
                    !!this.lead.bdc_user_id &&
                    (this.$feature.isEnabled('prevent-self-assignment') || this.authUser.id !== this.lead.bdc_user_id)
                );
            },

            displayBdcReassignmentButton() {
                return !this.guest &&
                    (
                        !!this.lead.second_bdc_user_id ||
                        (this.displayBdcReassignment && !this.authUser.isSuperBdc())
                    );
            },

            reassignmentModalTypeIsAdvisor() {
                return (
                    this.advisorHasDoubleAssignation &&
                    (this.authUser.id === this.lead.user_id || this.shouldShowReassignmentModalToDirector)
                );
            },

            reassignmentModalTypeIsBdc() {
                return (
                    this.bdcHasDoubleAssignation &&
                    (this.authUser.id === this.lead.bdc_user_id || this.shouldShowReassignmentModalToDirector)
                );
            },

            serviceAdvisorBlockedReason() {
                if (this.serviceAdvisorEnabled) {
                    return '';
                }

                if (this.guest) {
                    return this.$t('clientCard.guestMode');
                }

                if (this.lead.service_advisor_id === this.authUser.id) {
                    return '';
                }

                if (
                    this.authUser.isAdmin() ||
                    this.authUser.isDirector() ||
                    this.authUser.isBdcDirector() ||
                    this.authUser.isSuperBdc()
                ) {
                    return '';
                }

                if (this.lead.service_advisor_id) {
                    return this.$t('clientCard.blocked.userAssignation.cannotModifyAlreadyAssignedUser');
                }

                return '';
            },

            serviceAdvisorEnabled() {
                if (this.guest) {
                    return false;
                }

                if (this.lead.service_advisor_id === this.authUser.id) {
                    return true;
                }

                if (
                    this.authUser.isAdmin() ||
                    this.authUser.isDirector() ||
                    this.authUser.isBdcDirector() ||
                    this.authUser.isSuperBdc()
                ) {
                    return true;
                }

                return !this.lead.service_advisor_id;
            },

            serviceAgentBlockedReason() {
                if (this.serviceAgentEnabled) {
                    return '';
                }

                if (this.guest) {
                    return this.$t('clientCard.guestMode');
                }

                if (this.lead.service_agent_id === this.authUser.id) {
                    return '';
                }

                if (
                    this.authUser.isAdmin() ||
                    this.authUser.isDirector() ||
                    this.authUser.isBdcDirector() ||
                    this.authUser.isSuperBdc()
                ) {
                    return '';
                }

                if (this.lead.service_agent_id) {
                    return this.$t('clientCard.blocked.userAssignation.cannotModifyAlreadyAssignedUser');
                }

                return '';
            },

            serviceAgentEnabled() {
                if (this.guest) {
                    return false;
                }

                if (this.lead.service_agent_id === this.authUser.id) {
                    return true;
                }

                if (
                    this.authUser.isAdmin() ||
                    this.authUser.isDirector() ||
                    this.authUser.isBdcDirector() ||
                    this.authUser.isSuperBdc()
                ) {
                    return true;
                }

                return !this.lead.service_agent_id;
            },

            shouldShowReassignmentModalToDirector() {
                return (
                    (this.authUser.isDirector() || this.authUser.isBdcDirector()) &&
                    (!!this.lead.second_user_id || !!this.lead.second_bdc_user_id) &&
                    (
                        this.authUser.divisions.some(division => division.id === this.lead.division_id) ||
                        !this.lead.division_id
                    )
                );
            },

            showBdcAgent() {
                return this.lead.account.bdc_advisor;
            },
        },

        methods: {
            async acceptUserReassignment() {
                if (this.$wait.is('accepting.assignation')) {
                    return;
                }

                this.$wait.start('accepting.assignation');
                this.modals.reassignmentTransfer.opened = false;

                switch (this.modals.reassignmentTransfer.type) {
                    case 'advisor':
                        await this.updateLead({
                            user_id: this.lead.second_user_id,
                            second_user_id: null,
                        });
                        break;

                    case 'bdc':
                        await this.updateLead({
                            bdc_user_id: this.lead.second_bdc_user_id,
                            second_bdc_user_id: null,
                        });
                        break;
                }

                this.$nextTick(() => {
                    this.$wait.end('accepting.assignation');
                    this.modals.reassignmentTransfer.type = '';
                });
            },

            cancelUserReassignment() {
                this.modals.reassignmentTransfer.opened = false;
                this.modals.reassignmentTransfer.type = '';
            },

            async declineUserReassignment() {
                this.modals.reassignmentTransfer.opened = false;

                switch (this.modals.reassignmentTransfer.type) {
                    case 'advisor':
                        await this.updateLead({ second_user_id: null });
                        break;

                    case 'bdc':
                        this.updateLead({ second_bdc_user_id: null });
                        break;
                }

                this.$nextTick(() => {
                    this.modals.reassignmentTransfer.type = '';
                });
            },

            isSetBySerti(field) {
                if (!this.lead.isService() || this.lead.created_method !== CreatedMethods.SERTI) {
                    return false;
                }

                return !empty(this.lead.serti_imported_fields) &&
                    Array.isArray(this.lead.serti_imported_fields) &&
                    this.lead.serti_imported_fields.includes(field);
            },

            openSelectAdvisor() {
                this.$refs.advisorAssignation.open();
            },

            openUserReassignmentRequestModal(type) {
                if (
                    (!this.lead.second_user_id && this.displayAdvisorReassignment && type === 'advisor') ||
                    (!this.lead.second_bdc_user_id && this.displayBdcReassignment && type === 'bdc')
                ) {
                    this.modals.reassignmentRequest.type = type;
                    this.modals.reassignmentRequest.opened = true;
                }

                if (this.lead.second_user_id || this.lead.second_bdc_user_id) {
                    this.shouldShowModalToDirectorsOrFirstUserAssign(type);
                }
            },

            async saveUserReassignmentRequest(content, userId) {
                const requestedUserId = userId || this.authUser.id;

                const comment = {
                    content,
                    user_id: this.authUser.id,
                    request_user_id: requestedUserId,
                    lead_id: this.lead.id,
                    alert_users: [],
                    alert_sms: false,
                    alert_email: false,
                    alert: false,
                };

                const commentAdded = await this.$api.comments.store({ comment });

                if (this.authUser.isAdvisor()) {
                    try {
                        this.updateLead({ second_user_id: requestedUserId });
                        this.$nextTick(() => {
                            this.$eventBus.$emit('update-comment');
                        });
                    } catch (error) {
                        await this.$api.comments.destroy(commentAdded.id);
                    }
                }

                if (this.authUser.isBdc()) {
                    try {
                        this.updateLead({ second_bdc_user_id: requestedUserId });
                        this.$nextTick(() => {
                            this.$eventBus.$emit('update-comment');
                        });
                    } catch (error) {
                        this.$api.comments.destroy(commentAdded.id);
                    }
                }
            },

            shouldShowModalToDirectorsOrFirstUserAssign(type) {
                if (
                    this.shouldShowReassignmentModalToDirector ||
                    this.authUser.isAdmin() ||
                    (type === 'advisor' &&
                        this.lead.user_id === this.authUser.id &&
                        this.advisorHasDoubleAssignation) ||
                    (type === 'bdc' &&
                        this.lead.bdc_user_id === this.authUser.id &&
                        this.bdcHasDoubleAssignation)
                ) {
                    this.$nextTick(() => {
                        this.modals.reassignmentTransfer.type = type;
                        this.modals.reassignmentTransfer.opened = true;
                    });
                }
            },

            showModalToDirectorsOrFirstUserAssign() {
                let type = '';

                if (this.reassignmentModalTypeIsAdvisor) {
                    type = 'advisor';
                }

                if (this.reassignmentModalTypeIsBdc) {
                    type = 'bdc';
                }

                if (!type) {
                    return;
                }

                this.shouldShowModalToDirectorsOrFirstUserAssign(type);
            },

            updateLead(data) {
                this.$emit('update-lead', { data });
            },
        },

        async mounted() {
            await this.$nextTick();

            this.showModalToDirectorsOrFirstUserAssign();
        },

        created() {
            this.$eventBus.$on('select-advisor', this.openSelectAdvisor);
        },

        beforeDestroy() {
            this.$eventBus.$off('select-advisor', this.openSelectAdvisor);
        },
    };
</script>
