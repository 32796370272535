<template>
    <div class="space-y-6">
        <div class="grid grid-cols-2 max-h-96 gap-1.5 rounded-md overflow-hidden">
            <figure class="col-span-2">
                <img
                    class="object-contain w-full h-full"
                    :alt="imgData[1].placeholder"
                    :src="imgData[1].src"
                />
            </figure>
        </div>

        <ul class="pl-3 list-inside list-disc">
            <li v-text="$t('featurePreview.newLeadPage.feature1')" />
            <li v-text="$t('featurePreview.newLeadPage.feature2')" />
        </ul>
    </div>
</template>

<script>
    /* eslint-disable global-require */
    import FeaturePreview from '@/entities/FeaturePreview.js';

    export default {
        props: {
            featurePreview: {
                type: FeaturePreview,
                required: true,
            },
        },

        data: vm => ({
            imgData: {
                1: {
                    src: require('@/assets/img/feature-preview/new-lead-page/new-lead-page-1.png'),
                    placeholder: vm.$t('featurePreview.new-lead-page.img1Placeholder'),
                },
            },
        }),
    };
</script>
