<template>
    <div>
        <template v-if="contextLead.isService()">
            <general-info class="flex w-full px-3 | xl:w-1/2" />
            <comment-box class="flex w-full px-3 | xl:w-1/2" />
            <revival-service class="flex w-full px-3 | xl:w-1/2" />
            <process-service class="flex w-full px-3 | xl:w-1/2" />
            <vehicle-carousel class="flex w-full px-3 | xl:w-1/2" type="exchange" />
            <service-product class="flex w-full px-3 | xl:w-1/2" />
        </template>
        <template v-else>
            <template v-if="hasCommercial">
                <general-info class="flex w-full px-3 | xl:w-1/2" />
                <comment-box class="flex w-full px-3 | xl:w-1/2" />
                <performance class="flex w-full px-3 | xl:w-1/2" />
                <process class="flex w-full px-3 | xl:w-1/2" />
            </template>

            <template v-if="!hasCommercial">
                <general-info class="flex w-full px-3 | xl:w-1/2" />
                <div class="flex flex-col w-full px-3 | xl:w-1/2">
                    <performance />
                    <process />
                </div>
            </template>

            <template v-if="!contextAccount.isCustom()">
                <vehicle-carousel class="flex w-full px-3 | xl:w-1/2" type="wanted" />
                <vehicle-carousel class="flex w-full px-3 | xl:w-1/2" type="exchange" />
            </template>
        </template>

        <template v-if="contextAccount.isCustom()">
            <lead-custom-box
                class="flex w-full px-3 | xl:w-1/2"
                :title="$t('clientCard.funnel')"
                section="funnel"
                v-if="hasFunnelFields"
            />
            <lead-custom-box
                class="flex w-full px-3 | xl:w-1/2"
                :title="$t('clientCard.dollarAndLost')"
                section="dollarAndLost"
                v-if="hasDollarLostFields"
            />
        </template>
        <div class="flex flex-col w-full px-3" :class="{'xl:w-1/2': hasCommercial || !contextLead.isService()}">
            <revival-service v-if="!contextLead.isService() && hasEquityAlert" />
            <div class="box | flex flex-1 mb-6">
                <calendar
                    class="box-body | min-h-screen-75 lg:min-h-0 px-3 py-6"
                    :current-lead="contextLead"
                />
            </div>
        </div>
        <commercial-director class="flex w-full px-3 | xl:w-1/2" v-if="hasCommercial" />
        <comment-box class="flex w-full px-3 | xl:w-1/2" v-if="!hasCommercial && !contextLead.isService()" />

        <communications-box class="flex w-full px-3" />
        <history class="flex w-full px-3" />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import RevivalService from './RevivalService.vue';
    import ProcessService from './ProcessService.vue';
    import Performance from './Performance.vue';
    import Process from './Process.vue';
    import GeneralInfo from './GeneralInfo.vue';
    import VehicleCarousel from './VehicleCarousel.vue';
    import CommentBox from '../comment/CommentBox.vue';
    import Calendar from './Calendar.vue';
    import CommunicationsBox from './CommunicationsBox.vue';
    import ServiceProduct from './ServiceProduct.vue';
    import CommercialDirector from './CommercialDirector.vue';
    import History from './History.vue';
    import LeadCustomBox from './LeadCustomBox.vue';
    import ClientCardSection from '../../entities/ClientCardSection.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            LeadCustomBox,
            History,
            CommercialDirector,
            ServiceProduct,
            CommunicationsBox,
            Calendar,
            CommentBox,
            VehicleCarousel,
            GeneralInfo,
            Process,
            Performance,
            ProcessService,
            RevivalService,
        },

        computed: {
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),

            hasCommercial() {
                return this.contextLead.account.commercial;
            },

            hasEquityAlert() {
                return this.contextLead.account.equity_alert;
            },

            hasFunnelFields() {
                return this.contextAccount.custom_fields.some(field => field.section == ClientCardSection.FUNNEL);
            },

            hasDollarLostFields() {
                return this.contextAccount.custom_fields.some(
                    field => field.section == ClientCardSection.DOLLAR_AND_LOST,
                );
            },
        },
    };
</script>
