<template>
    <div class="flex items-center" v-if="outboundSuppliers.length">
        <el-dropdown trigger="click" placement="bottom" v-if="multipleSuppliers">
            <activix-base-button :disabled="guest" :class="triggerClassName">
                <icon name="regular/share-1" class="text-lg" />
            </activix-base-button>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    :disabled="guest || isExporting(supplier) || (isExported(supplier) && !haveLink(supplier))"
                    :key="supplier.account_supplier_id"
                    @click.native="send(supplier)"
                    v-for="supplier in outboundSuppliers"
                >
                    <icon :name="getIcon(supplier)" class="mr-1.5" />

                    {{ getText(supplier) }}

                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <activix-tooltip :content="getText()" v-else>
            <activix-base-button
                class="link-grey"
                :class="triggerClassName"
                :disabled="guest || isExporting() || (isExported() && !haveLink())"
                @click="send()"
            >
                <icon :name="getIcon()" class="text-xl | lg:text-lg" />
            </activix-base-button>
        </activix-tooltip>

        <select-vehicle-dms-export :opened.sync="selectVehicleDmsExportModalOpened" />

        <activix-confirm-modal
            type="warning"
            :title="$t('clientCard.sertiPushNoPhoneTitle')"
            :content="$t('clientCard.sertiPushNoPhoneText')"
            :approve-text="$t('export.addPhone')"
            :deny-text="$t('general.cancel')"
            :opened.sync="phoneAddConfirmModalOpened"
            @approve="onPhoneAddChosen"
        />

        <activix-confirm-modal
            type="warning"
            :title="$t('export.lucyPushNoPhoneTitle')"
            :content="$t('export.lucyPushNoPhoneText')"
            :approve-text="$t('export.add')"
            :deny-text="$t('general.cancel')"
            :opened.sync="phoneOrEmailAddConfirmModalOpened"
            @approve="onPhoneAddChosen"
        />

        <activix-confirm-modal
            type="warning"
            :title="$t('export.lucyPushNoNameTitle')"
            :content="$t('export.lucyPushNoNameText')"
            :approve-text="$t('export.addName')"
            :deny-text="$t('general.cancel')"
            :opened.sync="nameAddConfirmModalOpened"
            @approve="onPhoneAddChosen"
        />

        <activix-confirm-modal
            type="warning"
            :title="$t('clientCard.pbsVehicleNoVINTitle')"
            :content="$t('clientCard.pbsVehicleNoVINText')"
            :approve-text="$t('general.continue')"
            :deny-text="$t('general.cancel')"
            :opened.sync="vehicleNoVIN.modalOpened"
            @approve="onApproveVehicleNoVIN"
        />

        <activix-confirm-modal
            type="warning"
            :title="$t('clientCard.vehicleNoStockNumberOrVinTitle')"
            :content="$t('clientCard.vehicleNoStockNumberOrVinText')"
            :approve-text="$t('general.continue')"
            :deny-text="$t('general.cancel')"
            :opened.sync="vehicleNoVINOrStockNumber.modalOpened"
            @approve="onApproveVehicleNoVINOrStockNumber"
        />

        <activix-confirm-modal
            type="warning"
            :title="$t('clientCard.noAdvisorTitle')"
            :content="$t('clientCard.noAdvisorForExport')"
            :approve-text="$t('addLead.select')"
            :deny-text="$t('general.cancel')"
            :opened.sync="noAdvisorModalOpened"
            @approve="onApproveSelectAdvisor"
        />
    </div>
</template>

<script>
    // Utils
    import { camelCase, get } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';
    import { deskitSsoUrl } from '../../utils/index.js';

    // Entities
    import Supplier from '../../entities/Supplier.js';

    // Components
    import SelectVehicleDmsExport from '../modals/SelectVehicleDmsExport.vue';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            SelectVehicleDmsExport,
        },

        props: {
            triggerClassName: {
                type: [String, Array, Object],
                default: null,
            },
        },

        data() {
            return {
                noAdvisorModalOpened: false,
                selectVehicleDmsExportModalOpened: false,
                phoneAddConfirmModalOpened: false,
                phoneOrEmailAddConfirmModalOpened: false,
                nameAddConfirmModalOpened: false,
                vehicleNoVIN: {
                    modalOpened: false,
                    supplier: false,
                    vehicleId: null,
                },
                vehicleNoVINOrStockNumber: {
                    modalOpened: false,
                    supplier: false,
                    vehicleId: null,
                },
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useGlobalStore, ['guest']),

            multipleSuppliers() {
                return this.outboundSuppliers.length > 1;
            },

            outboundSuppliers() {
                const exportableSuppliers = [
                    Supplier.SERTI_SALE,
                    Supplier.DESKIT,
                    Supplier.ONE_EIGHTY,
                    Supplier.PBS,
                    Supplier.AUTOVANCE,
                    Supplier.LUCY,
                    Supplier.MERLIN,
                    Supplier.FNIPRESTIGE,
                ];

                return this.contextLead.account.suppliers.filter(supplier => {
                    if (supplier.id === Supplier.ONE_EIGHTY && !supplier.related_id) {
                        return false;
                    }

                    return exportableSuppliers.includes(supplier.id) && supplier.outbound;
                });
            },

            autoVanceUrl() {
                const supplier = this.contextLead.suppliers.find(supplier => supplier.id == Supplier.AUTOVANCE);

                if (!supplier) {
                    return '';
                }

                return `https://desk.autovance.com/deal/${supplier.related_id}`;
            },

            deskitUrl() {
                return deskitSsoUrl(this.contextLead.id);
            },

            fniPrestigeUrl() {
                return 'https://fniprestige.app';
            },

            exported() {
                if (this.contextLead.exported && this.contextLead.exported.length > 0) {
                    return (this.contextLead.exported).reduce((a, v) => ({ ...a, [this.getSupplierAccountId(v)]: v }), {});
                }

                return this.contextLead.exported_suppliers;
            },

            deskitSupplier() {
                return this.outboundSuppliers.find(supplier => this.isDeskit(supplier));
            },

            autoVanceSupplier() {
                return this.outboundSuppliers.find(supplier => this.isAutoVance(supplier));
            },

            hasDeskitSupplier() {
                return !!this.deskitSupplier;
            },

            hasAutoVanceSupplier() {
                return !!this.autoVanceSupplier;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['addLeadVehicleAction', 'updateLeadVehicleAction', 'appendNewError']),
            ...mapActions(useContextStore, ['setContextLeadIdAction']),

            isSerti(supplier) {
                return supplier.id == Supplier.SERTI_SALE || supplier.id == Supplier.MERLIN;
            },

            isMerlin(supplier) {
                return supplier.id == Supplier.MERLIN;
            },

            isDeskit(supplier) {
                return supplier.id == Supplier.DESKIT;
            },

            isPBS(supplier) {
                return supplier.id == Supplier.PBS;
            },

            isAutoVance(supplier) {
                return supplier.id == Supplier.AUTOVANCE;
            },

            isLucy(supplier) {
                return supplier.id == Supplier.LUCY;
            },

            isFniPrestige(supplier) {
                return supplier.id == Supplier.FNIPRESTIGE;
            },

            isExporting(supplier) {
                if (!supplier) {
                    supplier = this.outboundSuppliers[0];
                }

                return !!this.$wait.is(`exporting${supplier.id}`);
            },

            isExported(supplier) {
                if (!supplier) {
                    supplier = this.outboundSuppliers[0];
                }

                if (this.isSerti(supplier) && this.contextLead.wanted_vehicles.some(v => !v.exported_to_serti)) {
                    return false;
                }

                return this.exported && (
                    this.exported.hasOwnProperty(supplier.account_supplier_id) ||
                    (
                        this.isMerlin(supplier) &&
                        Object.values(this.exported).includes(Supplier.SERTI_SALE)
                    )
                );
            },

            // TODO remove after migration of all lead exported field
            getSupplierAccountId(supplierId) {
                return (this.contextLead.account.suppliers).find(supplier => supplier.id === supplierId)?.account_supplier_id;
            },

            haveLink(supplier) {
                if (!supplier) {
                    supplier = this.outboundSuppliers[0];
                }

                return this.isFniPrestige(supplier) || this.isAutoVance(supplier) || this.isDeskit(supplier);
            },

            getIcon(supplier) {
                if (!supplier) {
                    supplier = this.outboundSuppliers[0];
                }

                if (this.isFniPrestige(supplier) || (this.haveLink(supplier) && this.isExported(supplier))) {
                    return 'regular/accounting-calculator';
                }

                if (this.multipleSuppliers) {
                    return 'regular/upload-thick-bottom';
                }

                return 'regular/cloud-upload';
            },

            getLabel(supplier) {
                if (!supplier) {
                    supplier = this.outboundSuppliers[0];
                }

                if (this.isFniPrestige(supplier) || (this.isExported(supplier) && this.haveLink(supplier))) {
                    return this.$t('clientCard.goTo');
                }

                return this.$t('clientCard.exportTo');
            },

            getText(supplier) {
                if (!supplier) {
                    supplier = this.outboundSuppliers[0];
                }

                return `${this.getLabel(supplier)} ${this.supplierName(supplier)}`;
            },

            vehicleAlreadyExported(supplier, vehicleId = null) {
                if (!this.isSerti(supplier)) {
                    return this.isExported(supplier);
                }

                const vehicle = this.contextLead.wanted_vehicles.find(vehicle => vehicle.id == vehicleId);

                return get(vehicle, 'exported_to_serti', false);
            },

            openModalChoosePhone() {
                this.phoneAddConfirmModalOpened = true;
            },
            openModalChoosePhoneOrEmail() {
                this.phoneOrEmailAddConfirmModalOpened = true;
            },
            openModalSetName() {
                this.nameAddConfirmModalOpened = true;
            },

            openModalVehicleNoVIN(supplier, vehicleId) {
                this.vehicleNoVIN.supplier = supplier;
                this.vehicleNoVIN.vehicleId = vehicleId;
                this.vehicleNoVIN.modalOpened = true;
            },

            openModalVehicleNoVINOrStockNumber(supplier, vehicleId) {
                this.vehicleNoVINOrStockNumber.supplier = supplier;
                this.vehicleNoVINOrStockNumber.vehicleId = vehicleId;
                this.vehicleNoVINOrStockNumber.modalOpened = true;
            },

            openModalNoAdvisor() {
                this.noAdvisorModalOpened = true;
            },

            openSelectVehicleDmsExportModal() {
                this.selectVehicleDmsExportModalOpened = true;
            },

            async addVehicle(type) {
                await this.addLeadVehicleAction({ type }, this.contextLead.id);
            },

            async send(supplier, vehicleId = null) {
                if (!supplier) {
                    supplier = this.outboundSuppliers[0];
                }

                if (this.isExporting(supplier)) {
                    return;
                }

                if (this.guest) {
                    return;
                }

                if (this.isFniPrestige(supplier)) {
                    this.$behavior.track('Export', {
                        action: 'open_link',
                        location: 'lead',
                        supplier: supplier.name,
                    });

                    window.open(this.fniPrestigeUrl);
                    return;
                }

                if (this.isExported(supplier)) {
                    this.$behavior.track('Export', {
                        action: 'open_link',
                        location: 'lead',
                        supplier: supplier.name,
                    });

                    if (this.isAutoVance(supplier)) {
                        window.open(this.autoVanceUrl);
                        return;
                    }

                    if (this.isDeskit(supplier)) {
                        window.open(this.deskitUrl);
                        return;
                    }

                    return;
                }

                this.$behavior.track('Export', { action: 'initiated', location: 'lead', supplier: supplier.name });

                // Prevent Serti submit if there is no phone
                if (this.isSerti(supplier) && empty(this.contextLead.lead_phones)) {
                    this.$behavior.track('Export', {
                        action: 'modal',
                        confirm: 'Choose Phone',
                        location: 'lead',
                        supplier: supplier.name,
                    });
                    this.openModalChoosePhone();
                    return;
                }

                // Prevent Serti and Deskit submit if there is no wanted vehicles
                if ((this.isSerti(supplier) || this.isDeskit(supplier)) && empty(this.contextLead.wanted_vehicles)) {
                    await this.addVehicle('wanted');
                    this.$behavior.track('Export', {
                        action: 'modal',
                        confirm: 'Add Vehicle',
                        location: 'lead',
                        supplier: supplier.name,
                    });
                    return;
                }

                if (!vehicleId && this.contextLead.wanted_vehicles.length === 1) {
                    vehicleId = this.contextLead.wanted_vehicles[0].id;
                }

                if (this.isDeskit(supplier) && !this.contextLead.user_id) {
                    this.$behavior.track('Export', {
                        action: 'modal',
                        confirm: 'No Advisor',
                        location: 'lead',
                        supplier: supplier.name,
                    });
                    this.openModalNoAdvisor();
                    return;
                }

                if (this.isDeskit(supplier) && this.contextLead.wanted_vehicles.some(vehicle => !vehicle.vin || !vehicle.stock)) {
                    this.$behavior.track('Export', {
                        action: 'modal',
                        confirm: 'No VIN No Stock',
                        location: 'lead',
                        supplier: supplier.name,
                    });
                    this.openModalVehicleNoVINOrStockNumber(supplier, vehicleId);
                    return;
                }

                if (this.isLucy(supplier) && (!this.contextLead.fullName || (empty(this.contextLead.lead_emails) && empty(this.contextLead.lead_phones)))) {
                    if (!this.contextLead.fullName) {
                        this.$behavior.track('Export', {
                            action: 'modal',
                            confirm: 'Missing name',
                            location: 'lead',
                            supplier: supplier.name,
                        });
                        this.openModalSetName();
                    } else {
                        this.$behavior.track('Export', {
                            action: 'modal',
                            confirm: 'Missing email or phone',
                            location: 'lead',
                            supplier: supplier.name,
                        });
                        this.openModalChoosePhoneOrEmail();
                    }
                    return;
                }

                if (this.isPBS(supplier) && this.contextLead.vehicles.some(vehicle => !vehicle.vin)) {
                    this.$behavior.track('Export', {
                        action: 'modal',
                        confirm: 'No VIN',
                        location: 'lead',
                        supplier: supplier.name,
                    });
                    this.openModalVehicleNoVIN(supplier, vehicleId);
                    return;
                }

                if (this.isSerti(supplier) && this.contextLead.wanted_vehicles.length > 1 && !vehicleId) {
                    this.setContextLeadIdAction(this.contextLead.id);

                    // Open Modal
                    this.$behavior.track('Export', {
                        action: 'modal',
                        confirm: 'Select Vehicle',
                        location: 'lead',
                        supplier: supplier.name,
                    });
                    this.openSelectVehicleDmsExportModal();
                    return;
                }

                if (this.vehicleAlreadyExported(supplier, vehicleId)) {
                    return;
                }

                this.exportLead(supplier, vehicleId);
            },

            async exportLead(supplier, vehicleId = null) {
                this.$wait.start(`exporting${supplier.id}`);

                try {
                    await this.$axios.post(`v1/leads/${this.contextLead.id}/export`, {
                        supplierId: supplier.id,
                        accountSupplierId: supplier.account_supplier_id,
                        vehicleId,
                    });

                    if (this.isSerti(supplier)) {
                        this.updateLeadVehicleAction({ exported_to_serti: true }, vehicleId);
                    }

                    if (!this.isLucy(supplier)) {
                        this.exported[supplier.account_supplier_id] = supplier.id;
                    }

                    this.$behavior.track('Export', { action: 'completed', location: 'lead', supplier: supplier.name });

                    this.$notify.success(this.$t('clientCard.succefullyExported'));
                    this.$wait.end(`exporting${supplier.id}`);
                } catch (error) {
                    this.$wait.end(`exporting${supplier.id}`);
                    if (!error.response) {
                        this.appendNewError({
                            code: '0008',
                            display: false,
                            error,
                            payload: {
                                supplierId: supplier.id,
                                vehicleId,
                                accountSupplierId: supplier.account_supplier_id,
                            },
                        });
                        return;
                    }

                    if (error.response.data.message === 'Problem with DMS.' || error.response.data.message === 'Error with DMS export.') {
                        this.$notify.warning({
                            duration: -1,
                            title: this.$t('toastr.warning'),
                            text: this.$t('clientCard.dmsError', [supplier.name]),
                        });
                    } else if (error.response.data.message === 'Duplicate lead.') {
                        this.$notify.warning({
                            duration: -1,
                            title: this.$t('toastr.warning'),
                            text: this.$t('clientCard.duplicateLead'),
                        });
                    } else {
                        this.appendNewError({
                            code: '0008',
                            display: true,
                            error,
                            payload: {
                                supplierId: supplier.id,
                                vehicleId,
                                accountSupplierId: supplier.account_supplier_id,
                            },
                        });
                    }
                }
            },

            onApproveVehicleNoVIN() {
                this.exportLead(this.vehicleNoVIN.supplier, this.vehicleNoVIN.vehicleId);
            },

            onApproveVehicleNoVINOrStockNumber() {
                this.exportLead(this.vehicleNoVINOrStockNumber.supplier, this.vehicleNoVINOrStockNumber.vehicleId);
            },

            onApproveSelectAdvisor() {
                this.$eventBus.$emit('select-advisor');
            },

            onPhoneAddChosen() {
                this.$modal.show('lead:personalInfo');
            },

            onSendToSupplier(vehicleId) {
                const serti = this.outboundSuppliers.find(s => this.isSerti(s));

                if (serti) {
                    this.send(serti, vehicleId);
                }
            },

            supplierName(supplier) {
                const displayName = camelCase(supplier.display_name);

                return `${this.$t(`suppliers.export.${displayName}`)}${supplier.integration_name ? ` (${supplier.integration_name})` : ''}`;
            },
        },

        created() {
            this.$eventBus.$on('send-to-supplier', this.onSendToSupplier);
        },

        beforeDestroy() {
            this.$eventBus.$off('send-to-supplier', this.onSendToSupplier);
        },
    };
</script>
