<template>
    <div class="flex items-center space-x-3">
        <activix-tooltip :content="responseTimeTooltip">
            <div
                class="
                    flex items-center gap-1.5 rounded-full text-sm bg-gray-400
                    px-3 py-0.5
                    2xl:py-1
                "
            >
                <icon name="regular/matches-fire" />
                <time-duration
                    class="cursor-default"
                    :fallback="lead.merged_leads.length ? 'N/A' : '-'"
                    :seconds="lead.response_time"
                />
            </div>
        </activix-tooltip>

        <activix-tooltip :content="firstUpdateTimeTooltip">
            <div
                class="
                    flex items-center gap-1.5 rounded-full text-sm bg-gray-400
                    px-3 py-0.5
                    2xl:py-1
                "
            >
                <icon name="regular/content-paper-edit" />
                <time-duration
                    class="cursor-default"
                    fallback="-"
                    :seconds="lead.first_update_time"
                />
            </div>
        </activix-tooltip>
    </div>
</template>

<script>
    import TimeDuration from '@/components/container/date/TimeDuration.vue';

    import Lead from '@/entities/Lead';

    export default {
        components: { TimeDuration },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            firstUpdateTimeTooltip() {
                return this.$t('clientCard.firstUpdateTimeTooltip');
            },

            responseTimeNotApplicableMerged() {
                return !this.lead.response_time && this.lead.merged_leads.length > 0;
            },

            responseTimeTooltip() {
                if (this.responseTimeNotApplicableMerged) {
                    return this.$t('clientCard.responseTimeNotApplicableMergedTooltip');
                }

                return this.$t('clientCard.durationTooltip');
            },
        },
    };
</script>
