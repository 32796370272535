<template>
    <div class="flex flex-col gap-6 -mx-6 -mb-6 p-6 border-t border-gray-200 bg-gray-100">
        <div class="col-span-full flex items-center">
            <icon class="mr-1.5" :name="$icons.service" />
            <activix-label class="m-0" :label="$t('clientCard.boxes.vehicles.serviceFollowUp')" />
        </div>

        <revival
            :blocked-reason="blockedReason"
            :disabled="disabled"
            :lead="lead"
            :with-modal="!lead.isService()"
            @update-lead="$emit('update-lead', $event)"
        />
    </div>
</template>

<script>
    import ActivixLabel from '@/components/elements/ActivixLabel';
    import Revival from '@/components/container/lead/Revival';

    import Lead from '@/entities/Lead';

    export default {
        components: { ActivixLabel, Revival },

        props: {
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            lead: {
                type: Lead,
                required: true,
            },
        },
    };
</script>
