<template>
    <div>
        <div class="box | relative mb-6">
            <div class="box-header | flex flex-col" :class="{ 'h-auto': lgLayout }">
                <div class="flex items-center justify-between">
                    <div class="flex items-center" v-if="lgLayout">
                        <icon
                            name="regular/navigation-left-circle-1"
                            class="text-2xl link-grey-light"
                            :class="{ disabled: index <= 0 }"
                            @click="previousVehicle()"
                            v-if="vehicles.length > 1"
                        />
                        <h4 class="box-title | ml-4" v-if="vehicles.length > 1">
                            <span> ({{ index + 1 }} / {{ vehicles.length }}) </span>
                        </h4>
                        <icon
                            name="regular/navigation-right-circle_1"
                            class="text-2xl link-grey-light ml-4"
                            :class="{ disabled: index >= vehicles.length - 1 }"
                            @click="nextVehicle()"
                            v-if="vehicles.length > 1"
                        />
                        <activix-tooltip
                            :content="!loading && !guest ? $t('clientCard.addVehicle') : ''"
                            v-if="
                                !loading &&
                                    !guest &&
                                    (!contextLead.isService() || contextLead.exchange_vehicles.length < 1)
                            "
                        >
                            <icon
                                name="bold/add"
                                class="text-xl link-grey-light"
                                :class="{ 'ml-4': vehicles.length > 1 }"
                                @click="onAddVehicle"
                            />
                        </activix-tooltip>
                        <icon
                            :name="$icons.loading"
                            class="text-grey-500 spin-inverse text-xl"
                            :class="{ 'ml-4': vehicles.length > 1 }"
                            v-if="loading"
                        />
                    </div>
                    <div class="flex items-center" v-else>
                        <h4 class="box-title">
                            {{ $t(`clientCard.${type}Vehicle`) }}
                            <span class="text-lg" v-if="vehicles.length > 1">
                                ({{ index + 1 }} / {{ vehicles.length }})
                            </span>
                        </h4>
                        <div class="flex items-center ml-2">
                            <icon :name="$icons.loading" class="text-grey-500 spin-inverse" v-if="loading" />
                            <activix-tooltip
                                :content="!loading && !guest ? $t('clientCard.addVehicle') : ''"
                                v-if="
                                    !loading &&
                                        !guest &&
                                        (!contextLead.isService() || contextLead.exchange_vehicles.length < 1)
                                "
                            >
                                <icon name="bold/add" class="link-grey-light" @click="onAddVehicle" />
                            </activix-tooltip>

                            <template v-if="vehicles.length > 1">
                                <icon
                                    name="regular/navigation-left-circle-1"
                                    class="text-lg link-grey-light ml-2"
                                    :class="{ disabled: index <= 0 }"
                                    @click="previousVehicle()"
                                />
                                <icon
                                    name="regular/navigation-right-circle_1"
                                    class="text-lg link-grey-light ml-1"
                                    :class="{ disabled: index >= vehicles.length - 1 }"
                                    @click="nextVehicle()"
                                />
                            </template>
                        </div>
                        <div v-if="contextAccount.isPolestar() && type === 'wanted'">
                            <span
                                class="label | border border-green-500 text-green-500 ml-3 leading-none"
                                v-if="type === 'wanted' && !vehicle.isConsideredEmpty()"
                            >
                                <template v-if="vehicleIsSold">{{ soldLabel }}</template>
                                <template v-else-if="vehicleIsRenewed">{{ $t('clientCard.renewal') }}</template>
                                <template v-else-if="vehicleIsRefinanced">{{ $t('clientCard.refinanced') }}</template>
                                <template v-else-if="vehicleIsDelivered">{{ $t('clientCard.delivered') }}</template>
                                <template v-else-if="isPolestarVehicleDeposited">{{ $t('clientCard.deposit') }}</template>
                            </span>
                            <span
                                class="label | border border-blue-500 text-blue-500 ml-3 leading-none"
                                v-text="polestarTestDriveLabel"
                                v-if="isPolestarTestDrive"
                            />
                        </div>
                    </div>
                    <div class="flex items-center space-x-4" :class="{ 'ml-auto': lgLayout }">
                        <activix-tooltip
                            :content="$t('clientCard.vehicleExportedToSerti')"
                            v-if="vehicle.exported_to_serti"
                        >
                            <img class="h-5" src="/img/supplier/serti_fade.png" />
                        </activix-tooltip>
                        <span
                            class="label | border border-green-500 text-green-500 ml-2 leading-none"
                            v-if="type === 'wanted' && !vehicle.isConsideredEmpty() && !contextAccount.isPolestar()"
                        >
                            <template v-if="vehicleIsSold">{{ soldLabel }}</template>
                            <template v-else-if="vehicleIsRenewed">{{ $t('clientCard.renewal') }}</template>
                            <template v-else-if="vehicleIsRefinanced">{{ $t('clientCard.refinanced') }}</template>
                            <template v-else-if="vehicleIsDelivered">{{ $t('clientCard.delivered') }}</template>
                        </span>
                        <verified
                            class="ml-2"
                            size="md"
                            :in-client-card="true"
                            :lead="contextLead"
                            :vehicle-id="vehicle.id"
                            v-if="vehicleMayBeVerified"
                        />

                        <vehicle-export :vehicle="vehicle" v-if="type == 'exchange' && vehicles.length" />

                        <div class="space-x-2" v-if="contextAccount.isPolestar() && type === 'wanted'">
                            <activix-tooltip :content="polestarOrdersTooltip">
                                <button :class="{ 'text-gray-500 cursor-not-allowed': polestarOrders.length === 0, 'text-blue-500 font-bold': polestarFilter === LeadVehicleFilter.ORDER }" @click="setPolestarOrdersFilter">
                                    {{ $tc('clientCard.order', 2) }}
                                </button>
                            </activix-tooltip>
                            <activix-tooltip :content="polestarTestDrivesTooltip">
                                <button :class="{ 'text-gray-500 cursor-not-allowed': polestarTestDrives.length === 0, 'text-blue-500 font-bold': polestarFilter === LeadVehicleFilter.TEST_DRIVE }" @click="setPolestarTestDrivesFilter">
                                    {{ $tc('clientCard.roadTest', 2) }}
                                </button>
                            </activix-tooltip>
                            <button :class="{ 'text-blue-500 font-bold': polestarFilter === LeadVehicleFilter.ALL }" @click="polestarFilter = LeadVehicleFilter.ALL">
                                {{ $t('clientCard.all') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="box-body | pt-0">
                <vehicle
                    :vehicle="vehicle"
                    :type="type"
                    :has-export="
                        type == 'exchange' &&
                            (accountHasExport(Supplier.VAUTO) ||
                                accountHasExport(Supplier.AUTOPROPULSION) ||
                                accountHasExport(Supplier.QUICKTRADE))
                    "
                    :key="vehicle.id"
                    v-if="vehicles.length > 0"
                />

                <div class="w-full text-center" v-if="vehicles.length == 0">
                    <template v-if="contextAccount.isPolestar() && type === 'wanted' && polestarFilter !== LeadVehicleFilter.ALL">
                        {{ $t('clientCard.noVehicleFilter') }}
                    </template>
                    <template v-else>
                        {{ $t('clientCard.noVehicle') }}
                    </template>
                </div>

                <activix-tooltip :content="trashTooltip" v-if="vehicles.length > 1 && !guest">
                    <button class="absolute right-0 bottom-0 p-2 mb-6 mr-6 | lg:p-0 lg:mb-8 lg:mr-8">
                        <icon
                            class="link-grey-light text-xl lg:text-base"
                            :name="$icons.trash"
                            :class="[!canDeleteVehicle ? 'disabled' : 'hover:text-red-500']"
                            @click="onDeleteVehicle"
                        />
                    </button>
                </activix-tooltip>
            </div>
        </div>

        <activix-confirm-modal
            type="warning"
            :title="$t('clientCard.cannotAddVehicleRenewal')"
            :content="$t('clientCard.addNewLead')"
            :approve-text="$t('clientCard.newLead')"
            :deny-text="$t('general.cancel')"
            :opened.sync="addVehicleRenewalModalOpened"
            @approve="redirectToNewLead"
        />

        <activix-confirm-modal
            type="warning"
            :title="$t('clientCard.deleteVehicleConfirm')"
            :content="$t('clientCard.deleteVehicleSubConfirm')"
            :opened.sync="deleteVehicleModalOpened"
            @approve="triggerDeleteVehicle"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { orderBy, filter, slice, isUndefined } from 'lodash-es';

    // Components
    import Vehicle from './Vehicle.vue';
    import Verified from '../dashboards/columns/Verified.vue';
    import VehicleExport from './VehicleExport.vue';

    // Entities
    import LeadType from '../../entities/LeadType.js';
    import LeadVehicle from '../../entities/LeadVehicle.js';
    import ProgressState from '../../entities/ProgressState.js';
    import Supplier from '../../entities/Supplier.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import ActivixDate from '../../value-objects/ActivixDate.js';
    import LeadVehicleFilter from '../../entities/LeadVehicleFilter.js';

    export default {
        components: {
            Vehicle,
            Verified,
            VehicleExport,
        },

        props: {
            type: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                loading: false,
                index: 0,
                selectServiceVehicleModalOpened: false,
                addVehicleRenewalModalOpened: false,
                deleteVehicleModalOpened: false,
                Supplier,
                polestarFilter: LeadVehicleFilter.ALL,
                LeadVehicleFilter,
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),
            ...mapState(useGlobalStore, ['guest']),

            soldLabel() {
                return this.contextAccount.hasSupplier(Supplier.POLESTAR) ? this.$tc('clientCard.order') : this.$t('clientCard.sold');
            },

            vehicles() {
                if (empty(this.contextLead[`${this.type}_vehicles`])) {
                    return [];
                }

                if (this.contextLead.isService()) {
                    let vehicles = orderBy(this.contextLead.exchange_vehicles, 'id', 'asc');

                    const serviceVehicles = filter(vehicles, vehicle => {
                        return vehicle.service;
                    });

                    if (!empty(serviceVehicles)) {
                        if (serviceVehicles.length > 1) {
                            vehicles = slice(serviceVehicles, 1, serviceVehicles.length);
                        } else {
                            vehicles = serviceVehicles;
                        }
                    }

                    return isUndefined(vehicles) ? [] : vehicles.map(vehicle => new LeadVehicle(vehicle));
                }

                // If lead is renewal, only show oldest vehicle
                if (
                    this.type == 'exchange' &&
                    this.contextLead.isOfType(LeadType.RENEWAL) &&
                    this.contextLead.exchange_vehicles.length > 1
                ) {
                    const vehicles = orderBy(this.contextLead.exchange_vehicles, 'id', 'asc');

                    return [new LeadVehicle(vehicles[0])];
                }

                const leadVehicles = this.contextLead[`${this.type}_vehicles`].map(vehicle => new LeadVehicle(vehicle));

                if (this.contextAccount.hasSupplier(Supplier.POLESTAR)) {
                    return leadVehicles.filter(vehicle => {
                        switch (this.polestarFilter) {
                            case LeadVehicleFilter.ALL:
                                return true;
                            case LeadVehicleFilter.ORDER:
                                return vehicle?.suppliers.some(supplier => supplier?.state?.additional_information?.type === LeadVehicleFilter.ORDER);
                            case LeadVehicleFilter.TEST_DRIVE:
                                return vehicle?.suppliers.some(supplier => supplier?.state?.additional_information?.type === LeadVehicleFilter.TEST_DRIVE);
                        }

                        return true;
                    }).sort(((a, b) => {
                        const vehicleA = new ActivixDate(a.created_at);
                        const vehicleB = new ActivixDate(b.created_at);

                        return vehicleB.diffInSeconds(vehicleA);
                    }));
                }

                return leadVehicles;
            },

            vehiclesOptions() {
                if (!this.lgLayout) {
                    return [];
                }

                return this.vehicles.map((vehicle, index) => {
                    return {
                        id: index,
                        label: vehicle.toString() || 'N/A',
                    };
                });
            },

            selectedVehicleOption() {
                return this.vehiclesOptions.find(option => {
                    return option.id == this.index;
                });
            },

            vehicle() {
                return this.vehicles[this.index] || new LeadVehicle({ id: 0 });
            },

            supplierWithRelatedId() {
                return this.vehicle.suppliers.find(supplier => supplier.related_id !== null);
            },

            canDeleteVehicle() {
                return !this.vehicle.sold && !this.supplierWithRelatedId;
            },

            vehicleIsSold() {
                return (
                    this.contextLead.isNotOfType(5) &&
                    this.vehicle.sold &&
                    (this.contextLead.sale || this.contextLead.sale_by_phone) &&
                    !this.contextLead.delivered
                );
            },

            vehicleIsRenewed() {
                return (
                    this.contextLead.isOfType(5) &&
                    this.vehicle.sold &&
                    (this.contextLead.sale || this.contextLead.sale_by_phone) &&
                    !this.contextLead.delivered
                );
            },

            vehicleIsRefinanced() {
                return (
                    this.contextLead.isOfType(5) &&
                    this.vehicle.sold &&
                    !this.contextLead.sale &&
                    this.contextLead.refinanced &&
                    !this.contextLead.delivered
                );
            },

            vehicleIsDelivered() {
                return this.contextLead.delivered && this.vehicle.sold;
            },

            vehicleMayBeVerified() {
                return (
                    this.contextLead.account.verified_sale &&
                    this.vehicle.sold &&
                    (this.contextLead.sale || this.contextLead.sale_by_phone || this.contextLead.refinanced)
                );
            },

            trashTooltip() {
                if (this.vehicle.sold) {
                    return this.$t('clientCard.soldVehicleDisabled');
                }

                if (this.supplierWithRelatedId) {
                    return this.$t('clientCard.exportedVehicleDisable', [this.supplierWithRelatedId.name]);
                }

                return this.$t('clientCard.deleteVehicle');
            },

            isPolestarVehicleDeposited() {
                return this.vehicle?.custom_fields.some(customField => customField?.fixed_field === 'custom_field_order_deposit' && customField?.pivot?.value[0] === true);
            },

            isPolestarTestDrive() {
                return this.vehicle?.suppliers.some(supplier => supplier?.state?.additional_information?.type === LeadVehicleFilter.TEST_DRIVE);
            },

            polestarTestDriveLabel() {
                const testDriveSubTypeCustomField = this.vehicle?.custom_fields.find(customField => customField?.fixed_field === 'custom_field_sub_type');

                if (!testDriveSubTypeCustomField || !testDriveSubTypeCustomField?.pivot?.value || testDriveSubTypeCustomField.pivot.value[0] === null) {
                    return this.$tc('clientCard.roadTest');
                }

                const value = (testDriveSubTypeCustomField.pivot.value[0]).toLowerCase();

                switch (value) {
                    case 'drive out': return this.$t('clientCard.roadTestAYL');
                    case 'event': return this.$t('clientCard.roadTestTDM');
                }

                return this.$tc('clientCard.roadTest');
            },

            polestarOrdersTooltip() {
                if (this.polestarOrders.length) {
                    return `${this.polestarOrders.length} ${this.$tc('clientCard.order', this.polestarOrders.length)}`;
                }

                return this.$t('clientCard.orderEmpty');
            },

            polestarTestDrivesTooltip() {
                if (this.polestarTestDrives.length) {
                    return `${this.polestarTestDrives.length} ${this.$tc('clientCard.roadTest', this.polestarTestDrives.length)}`;
                }

                return this.$t('clientCard.roadTestEmpty');
            },

            polestarOrders() {
                const leadVehicles = this.contextLead[`${this.type}_vehicles`].map(vehicle => new LeadVehicle(vehicle));

                return leadVehicles.filter(vehicle => {
                    return vehicle?.suppliers.some(supplier => supplier?.state?.additional_information?.type === LeadVehicleFilter.ORDER);
                });
            },

            polestarTestDrives() {
                const leadVehicles = this.contextLead[`${this.type}_vehicles`].map(vehicle => new LeadVehicle(vehicle));

                return leadVehicles.filter(vehicle => {
                    return vehicle?.suppliers.some(supplier => supplier?.state?.additional_information?.type === LeadVehicleFilter.TEST_DRIVE);
                });
            },
        },

        watch: {
            vehicle() {
                this.index = this.normalizeIndex();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['addLeadVehicleAction', 'deleteLeadVehicleAction']),

            accountHasExport(supplierId) {
                return this.contextAccount.suppliers.some(supplier => {
                    return supplier.id == supplierId && supplier.outbound;
                });
            },

            previousVehicle() {
                if (this.index <= 0) {
                    return;
                }

                this.index--;
            },

            nextVehicle() {
                if (this.index >= this.vehicles.length - 1) {
                    return;
                }

                this.index++;
            },

            onAddVehicle() {
                if (
                    this.contextLead.isOfType(LeadType.RENEWAL) &&
                    this.type == 'exchange' &&
                    this.vehicles.length == 1
                ) {
                    this.addVehicleRenewalModalOpened = true;
                    return;
                }

                this.addNewVehicle();
            },

            addNewVehicle() {
                this.loading = true;

                const vehicleData = {
                    type: this.type,
                };

                if (this.type == 'wanted' && empty(this.contextLead.wanted_vehicles)) {
                    if (this.contextLead.progress_state == ProgressState.CASH_DEAL) {
                        vehicleData.modality = 'cash';
                    }

                    if (this.contextLead.sale_date) {
                        vehicleData.sold = true;
                    }
                }

                this.addLeadVehicleAction(vehicleData, this.contextLead.id)
                    .then(() => {
                        this.addSuccess();
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },

            redirectToNewLead() {
                this.$eventBus.$emit('open-lead-create', {
                    accountId: this.contextLead.account_id,
                    relatedLead: this.contextLead.id,
                    type: 'renewal',
                });
            },

            onDeleteVehicle() {
                if (!this.canDeleteVehicle) {
                    return;
                }

                this.deleteVehicleModalOpened = true;
            },

            triggerDeleteVehicle() {
                this.deleteLeadVehicleAction(this.vehicle.id, this.contextLead.id).then(() => {
                    this.removeSuccess();
                });
            },

            addSuccess() {
                this.loading = false;
                this.polestarFilter = LeadVehicleFilter.ALL;

                // Select new vehicle
                if (this.contextAccount.isPolestar()) {
                    this.index = 0;
                } else if (this.vehicles.length > 0) {
                    this.index = this.vehicles.length - 1;
                }
            },

            removeSuccess() {
                this.loading = false;

                // Select new vehicle
                if (this.index > 0) {
                    this.index--;
                }
            },

            normalizeIndex() {
                return this.index < this.vehicles.length ? this.index : 0;
            },

            setPolestarTestDrivesFilter() {
                if (this.polestarTestDrives.length === 0) {
                    return;
                }

                this.polestarFilter = LeadVehicleFilter.TEST_DRIVE;
            },

            setPolestarOrdersFilter() {
                if (this.polestarOrders.length === 0) {
                    return;
                }

                this.polestarFilter = LeadVehicleFilter.ORDER;
            },
        },
    };
</script>
