import Enum from './Enum.js';

export default class Segment extends Enum {
    static get entries() {
        return {
            conquest: 'conquest',
            csi: 'csi',
            endLcap: 'endLcap',
            endLnette: 'endLnette',
            endWarranty: 'endWarranty',
            loyalty: 'loyalty',
            noShow: 'noShow',
            notSold: 'notSold',
            other: 'other',
            promo: 'promo',
            reminder: 'reminder',
            service: 'service',
        };
    }

    static get CONQUEST() {
        return this.entries.conquest;
    }

    static get CSI() {
        return this.entries.csi;
    }

    static get END_LCAP() {
        return this.entries.endLcap;
    }

    static get END_LNETTE() {
        return this.entries.endLnette;
    }

    static get END_WARRANTY() {
        return this.entries.endWarranty;
    }

    static get LOYALTY() {
        return this.entries.loyalty;
    }

    static get NO_SHOW() {
        return this.entries.noShow;
    }

    static get NOT_SOLD() {
        return this.entries.notSold;
    }

    static get OTHER() {
        return this.entries.other;
    }

    static get PROMO() {
        return this.entries.promo;
    }

    static get REMINDER() {
        return this.entries.reminder;
    }

    static get SERVICE() {
        return this.entries.service;
    }

    static get translationGroup() {
        return 'segments';
    }
}
