<template>
    <div
        class="relative flex items-center justify-center h-12 w-12 rounded-full text-2xl"
        :class="{
            'border-2 border-dashed text-gray-500 bg-white' : planned,
            'border border-2 border-opacity-0 text-gray-600 bg-gray-200': !planned,
        }"
    >
        <icon :name="$icons.sms" />
        <communication-direction-icon :communication="communication" />
    </div>
</template>

<script>
    import CommunicationDirectionIcon from '@/components/container/lead/communications/CommunicationDirectionIcon.vue';

    import Communication from '@/entities/Communication.js';

    export default {
        components: { CommunicationDirectionIcon },

        props: {
            communication: {
                required: true,
                type: Communication,
            },
            planned: {
                default: false,
                type: Boolean,
            },
        },
    };
</script>
