<template>
    <activix-confirm-modal
        type="warning"
        :title="$t('clientCard.cannotAddVehicleRenewal')"
        :content="$t('clientCard.addNewLead')"
        :approve-text="$t('clientCard.newLead')"
        :deny-text="$t('general.cancel')"
        :opened="opened"
        @approve="redirectToNewLead"
        @close="$emit('close')"
    />
</template>

<script>
    import Lead from '@/entities/Lead.js';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
            opened: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            redirectToNewLead() {
                this.$eventBus.$emit('open-lead-create', {
                    accountId: this.lead.account_id,
                    relatedLead: this.lead.id,
                    type: 'renewal',
                });
            },
        },
    };
</script>
