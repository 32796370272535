<template>
    <activix-modal :opened="opened" @close="close" @closed="onClosed">
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('clientCard.vehicles.vinMissingDataModal.title') }}
            </h4>
        </template>

        <template slot="body">
            <div class="row mb-4" v-if="!!trims.length">
                <label class="col-xs-3">
                    {{ $t('clientCard.vehicles.version') }}
                </label>

                <div class="col-xs-9">
                    <activix-multiselect
                        name="trim"
                        identifier="value"
                        label="text"
                        :selected="selectedTrim"
                        :options="trims"
                        :searchable="false"
                        v-model="vehicleTempData.trim"
                    />
                </div>
            </div>
            <div class="row mb-4" v-if="!!transmissions.length">
                <label class="col-xs-3">
                    {{ $t('clientCard.vehicles.transmission') }}
                </label>

                <div class="col-xs-9">
                    <activix-multiselect
                        name="trim"
                        identifier="value"
                        label="text"
                        :selected="selectedTransmission"
                        :options="transmissions"
                        :searchable="false"
                        v-model="vehicleTempData.transmission"
                    />
                </div>
            </div>
            <div class="row mb-4" v-if="!!formatedExteriorColors.length">
                <label class="col-xs-3">
                    {{ $t('clientCard.vehicles.exteriorColor') }}
                </label>

                <div class="col-xs-9">
                    <activix-multiselect
                        name="trim"
                        identifier="value"
                        label="text"
                        :selected="selectedExteriorColor"
                        :options="formatedExteriorColors"
                        :searchable="false"
                        v-model="vehicleTempData.color_exterior"
                    />
                </div>
            </div>
            <div class="row mb-4" v-if="!!formatedInteriorColors.length">
                <label class="col-xs-3">
                    {{ $t('clientCard.vehicles.interiorColor') }}
                </label>

                <div class="col-xs-9">
                    <activix-multiselect
                        name="trim"
                        identifier="value"
                        label="text"
                        :selected="selectedInteriorColor"
                        :options="formatedInteriorColors"
                        :searchable="false"
                        v-model="vehicleTempData.color_interior"
                    />
                </div>
            </div>
        </template>

        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.cancel') }}
            </activix-button>

            <activix-button type="primary" :loading="$wait.is('saving.missingData')" @click="save">
                {{ $t('general.save') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import { mapActions } from 'pinia';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        props: {
            opened: {
                type: Boolean,
                required: true,
                default: false,
            },
            vehicle: {
                type: Object,
                required: true,
            },
            trims: {
                type: Array,
                required: true,
            },
            transmissions: {
                type: Array,
                required: true,
            },
            exteriorColors: {
                type: Array,
                required: true,
            },
            interiorColors: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                vehicleTempData: {
                    trim: '',
                    transmission: '',
                    color_exterior: '',
                    color_interior: '',
                },
            };
        },

        computed: {
            selectedTransmission() {
                return this.transmissions.find(trans => {
                    return trans.value == this.vehicleTempData.transmission
                        ? this.vehicleTempData.transmission
                        : this.vehicle.transmission;
                });
            },

            selectedTrim() {
                return this.trims.find(trim => {
                    return trim.value == this.vehicleTempData.trim ? this.vehicleTempData.trim : this.vehicle.trim;
                });
            },

            selectedExteriorColor() {
                return (
                    this.formatedExteriorColors.find(color => {
                        return (
                            color ==
                            (this.vehicleTempData.color_exterior
                                ? this.vehicleTempData.color_exterior
                                : this.vehicle.color_exterior)
                        );
                    }) || null
                );
            },

            selectedInteriorColor() {
                return (
                    this.formatedInteriorColors.find(color => {
                        return (
                            color ==
                            (this.vehicleTempData.color_interior
                                ? this.vehicleTempData.color_interior
                                : this.vehicle.color_interior)
                        );
                    }) || null
                );
            },

            formatedExteriorColors() {
                return this.exteriorColors ? this.exteriorColors.map(color => color.colorName) : [];
            },

            formatedInteriorColors() {
                return this.interiorColors ? this.interiorColors.map(color => color.colorName) : [];
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadVehicleAction']),

            save() {
                this.$wait.start('saving.missingData');
                const payload = {};

                if (this.vehicleTempData.trim) {
                    payload.trim = this.vehicleTempData.trim;
                }

                if (this.vehicleTempData.transmission) {
                    payload.transmission = this.vehicleTempData.transmission.value
                        ? this.vehicleTempData.transmission.value
                        : '';
                }

                if (this.vehicleTempData.color_exterior) {
                    payload.color_exterior = this.vehicleTempData.color_exterior;
                }

                if (this.vehicleTempData.color_interior) {
                    payload.color_interior = this.vehicleTempData.color_interior;
                }

                this.updateLeadVehicleAction(payload, this.vehicle.id);

                this.close();
                this.reset();
                this.$wait.end('saving.missingData');
            },

            close() {
                this.$emit('update:opened', false);
            },

            reset() {
                this.vehicleTempData.trim = '';
                this.vehicleTempData.transmission = '';
                this.vehicleTempData.color_exterior = '';
                this.vehicleTempData.color_interior = '';
            },

            onClosed() {
                this.reset();
            },
        },
    };
</script>
