<template>
    <box-header-icon
        icon-name="regular/filter-1"
        :indicator="hasFiltersApplied"
        :tooltip="$t('dashboards.filter')"
    >
        <template #popoverContent="{ closePopover }">
            <filters
                :filters="filters"
                @apply="applyFilters($event, closePopover)"
                @filter-selected="onFilterSelected"
                @reset="resetFilters(closePopover)"
            />
        </template>
    </box-header-icon>
</template>

<script>
    import BoxHeaderIcon from '@/components/presentational/boxes/BoxHeaderIcon';
    import Filters from '@/components/container/Filters';

    import CommunicationKind from '@/entities/CommunicationKind';
    import CommunicationMethod from '@/entities/CommunicationMethod';
    import CommunicationType from '@/entities/CommunicationType';

    const initialFilters = {
        direction: null,
        method: [],
        emailAttachment: null,
        type: [],
    };

    export default {
        components: { BoxHeaderIcon, Filters },

        props: {
            communications: {
                required: true,
                type: Array,
            },
        },

        data: () => ({
            filterValues: initialFilters,
            showEmailAttachmentFilter: false,
        }),

        computed: {
            filteredCommunications() {
                return this.communications.filter(this.communicationMatchesFilters);
            },

            filters() {
                return {
                    direction: {
                        label: this.$t('clientCard.boxes.communications.filters.direction'),
                        options: CommunicationType.selectOptions('value', 'text'),
                        type: 'select',
                        value: this.filterValues.direction,
                    },
                    method: {
                        label: this.$t('clientCard.boxes.communications.filters.method'),
                        options: CommunicationMethod.selectOptions('value', 'text'),
                        multiple: true,
                        type: 'select',
                        value: this.filterValues.method,
                    },
                    emailAttachment: {
                        label: this.$t('clientCard.boxes.communications.filters.emailAttachment'),
                        type: 'bool',
                        value: this.filterValues.emailAttachment,
                        invalid: !this.showEmailAttachmentFilter,
                    },
                    type: {
                        label: this.$t('clientCard.boxes.communications.filters.type'),
                        options: CommunicationKind.selectOptions('value', 'text'),
                        multiple: true,
                        type: 'select',
                        value: this.filterValues.type,
                    },
                };
            },

            hasFiltersApplied() {
                return Object.entries(this.filterValues).some(([name, value]) => {
                    const initialFilter = initialFilters[name];

                    if (initialFilter instanceof Array) {
                        const selectedValues = value || [];

                        return selectedValues.length !== initialFilter.length ||
                            selectedValues.some(selectedValue => !initialFilter.includes(selectedValue));
                    }

                    return value !== initialFilter;
                });
            },
        },

        watch: {
            filteredCommunications: {
                immediate: true,
                handler() {
                    this.$emit('communications-filtered', this.filteredCommunications);
                },
            },
        },

        methods: {
            applyFilters(selectedFilters, closeFilterMenu) {
                closeFilterMenu();

                this.updateFilters({
                    ...this.filterValues,
                    ...selectedFilters,
                });
            },

            communicationMatchesAttachment(communication) {
                if (
                    this.filterValues.emailAttachment === null ||
                    communication.communication_method_id !== CommunicationMethod.EMAIL
                ) {
                    return true;
                }

                const hasAttachments = communication.attachments.length;

                return this.filterValues.emailAttachment ? hasAttachments : !hasAttachments;
            },

            communicationMatchesDirection(communication) {
                if (!this.filterValues.direction) {
                    return true;
                }

                return this.filterValues.direction === communication.communication_type_id;
            },

            communicationMatchesFilters(communication) {
                return this.communicationMatchesDirection(communication) &&
                    this.communicationMatchesMethod(communication) &&
                    this.communicationMatchesAttachment(communication) &&
                    this.communicationMatchesType(communication);
            },

            communicationMatchesMethod(communication) {
                if (!this.filterValues.method.length) {
                    return true;
                }

                return this.filterValues.method.includes(communication.communication_method_id);
            },

            communicationMatchesType(communication) {
                if (!this.filterValues.type.length) {
                    return true;
                }

                return this.filterValues.type.some(typeFilter => {
                    if (typeFilter === CommunicationKind.AUTO) {
                        return communication.kind === CommunicationKind.AUTO || communication.automation;
                    }

                    return this.filterValues.type === communication.kind;
                });
            },

            onFilterSelected({ filterName, filterValue }) {
                if (filterName === 'method') {
                    this.showEmailAttachmentFilter = filterValue.includes(CommunicationMethod.EMAIL);
                }
            },

            resetFilters(closeFilterMenu) {
                closeFilterMenu();

                this.updateFilters(initialFilters);
            },

            updateFilters(filters) {
                this.$set(this, 'filterValues', filters);
            },
        },
    };
</script>
