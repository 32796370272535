<template>
    <box>
        <box-header slot="header">
            {{ $t('clientCard.boxes.serviceProcess.title') }}
        </box-header>

        <template slot="content">
            <service-process-progress-bar :lead="contextLead" />

            <div class="flex flex-grow flex-col">
                <box-content>
                    <service-process
                        :lead="contextLead"
                        @update-lead="updateLead"
                    />
                </box-content>

                <template v-if="hasCustomFields">
                    <hr class="w-full h-px m-0 border-none bg-gray-200" />

                    <box-content class="flex-grow-0">
                        <service-process-custom-fields
                            :lead="contextLead"
                            @update-lead="updateLead"
                        />
                    </box-content>
                </template>
            </div>

            <service-process-additional-data
                class="p-3 gap-3 border-t border-gray-200 bg-gray-100 | md:p-6 md:gap-6 xl:p-3 xl:gap-3 2xl:p-6 2xl:gap-6"
                :lead="contextLead"
                @update-lead="updateLead"
            />
        </template>
    </box>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store';
    import { useGlobalStore } from '@/store/store.js';

    import Box from '@/components/presentational/boxes/Box';
    import BoxContent from '@/components/presentational/boxes/BoxContent.vue';
    import BoxHeader from '@/components/presentational/boxes/BoxHeader';
    import ServiceProcess from '@/components/container/lead/ServiceProcess';
    import ServiceProcessAdditionalData from '@/components/container/lead/ServiceProcessAdditionalData.vue';
    import ServiceProcessCustomFields from '@/components/container/lead/ServiceProcessCustomFields.vue';
    import ServiceProcessProgressBar from '@/components/container/lead/input/ServiceProcessProgressBar';

    import ClientCardSection from '@/entities/ClientCardSection';

    export default {
        components: {
            Box,
            BoxContent,
            BoxHeader,
            ServiceProcess,
            ServiceProcessAdditionalData,
            ServiceProcessCustomFields,
            ServiceProcessProgressBar,
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),

            hasCustomFields() {
                return this.contextLead.account.hasSectionCustomFields(ClientCardSection.PROCESS_SERVICE);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            updateLead({ data, options = {} }) {
                this.updateLeadAction(this.contextLead.id, data, options);
            },
        },
    };
</script>
