<template>
    <invisible-multiselect
        :disabled="disabled"
        :nullable="true"
        :options="options"
        :value="value"
        @input="updateLead({ lead_form_id: $event })"
    />
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect';

    import Lead from '@/entities/Lead';
    import LeadForm from '@/entities/LeadForm.js';

    export default {
        components: {
            InvisibleMultiselect,
        },

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['configs']),

            options() {
                const defaultForms = this.configs.leadForms
                    .map(form => ({
                        value: form.id,
                        text:
                            form[`display_name_${this.$i18n.locale}`] ||
                            LeadForm.getTranslation(form.name),
                    }));

                const accountForms = this.lead.account.lead_forms
                    .map(form => ({
                        value: form.id,
                        text: form.name,
                    }));

                const forms = [
                    ...defaultForms,
                    ...accountForms,
                ];

                if (this.lead.form) {
                    forms.unshift({
                        value: 'text-form',
                        text: this.lead.form,
                    });
                }

                return forms.sort((a, b) => a.text.localeCompare(b.text));
            },

            value() {
                if (this.lead.form && !this.lead.lead_form_id) {
                    return 'text-form';
                }

                return this.lead.lead_form_id;
            },
        },

        methods: {
            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
