<template>
    <div>
        <div class="self-stretch p-1.5 rounded-t-lg bg-white" v-if="showVideoWidget">
            <div
                class="aspect-w-16 aspect-h-9"
                v-if="isRecordingProcessing"
            >
                <div class="object-cover flex flex-col justify-center items-center border border-gray-300 rounded-md bg-gray-200">
                    <icon class="text-4xl animate-spin" name="regular/button-refresh-arrows" />
                    <p class="mt-3 mb-0" v-text="$t('clientCard.boxes.communications.conferences.videoProcessing')" />
                </div>
            </div>

            <div
                class="aspect-w-16 aspect-h-9"
                v-if="isRecordingAvailable"
            >
                <video
                    class="object-cover block border border-gray-300 rounded-md"
                    controls
                    autopictureinpicture="true"
                    preload="metadata"
                >
                    <source :src="communication.url" type="video/mp4" />
                    {{ $t('clientCard.boxes.communications.conferences.browserNotSupportingVideoPlaying') }}
                    <a :href="communication.url" target="_blank">
                        {{ $t('clientCard.download') }}
                    </a>
                </video>
            </div>
        </div>

        <div
            class="flex justify-between items-center p-6 gap-12 rounded-b-lg bg-white"
            :class="showVideoWidget ? 'border-t border-gray-200' : 'rounded-t-lg'"
        >
            <div class="flex items-center gap-5">
                <div class="flex items-center">
                    <span class="mr-1.5 w-1.5 h-1.5 rounded-full" :class="statusColor" />
                    <span v-text="statusText" />
                </div>
            </div>

            <div class="flex justify-end items-center gap-3">
                <activix-tooltip
                    :content="$t('clientCard.boxes.communications.conferences.recordedVideoTooltip')"
                    v-if="showRecLabel"
                >
                    <div class="px-1.5 py-0.5 border border-red-500 rounded-md text-xs font-semibold text-red-500 uppercase">
                        <span v-text="$t('clientCard.boxes.communications.conferences.recorded')" />
                    </div>
                </activix-tooltip>

                <activix-tooltip :content="isDisabled ? $t('clientCard.boxes.communications.conferences.notAuthorizedToJoin'): ''">
                    <activix-button
                        :disabled="isDisabled"
                        :href="roomUrl"
                        tag="a"
                        target="_blank"
                        type="primary"
                        @click="onJoinClicked"
                        v-text="$t('clientCard.boxes.communications.conferences.join')"
                        v-if="!isConferenceOver"
                    />
                </activix-tooltip>

                <p class="mb-0" v-if="subStatusText">
                    {{ subStatusText }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import { timeHms } from '@/utils/time.js';

    import Account from '@/entities/Account';
    import Communication from '@/entities/Communication';
    import CommunicationStatus from '@/entities/CommunicationStatus';
    import Role from '@/entities/Role';
    import VideoConferenceStatus from '@/entities/VideoConferenceStatus';
    import VideoRecordingStatus from '@/entities/VideoRecordingStatus';

    export default {
        props: {
            account: {
                required: true,
                type: Account,
            },
            communication: {
                required: true,
                type: Communication,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            canJoinRoom() {
                return (
                    !this.authUser.isAdmin() &&
                    !this.$auth.impersonating() &&
                    !!this.roomUrl &&
                    !this.communication.isVideoConferenceOver
                );
            },

            conferenceDuration() {
                if (!this.communication.duration_call) {
                    return null;
                }

                const duration = timeHms(this.communication.duration_call);

                return this.$t('clientCard.boxes.communications.conferences.duration', [duration]);
            },

            createdByFinance() {
                return this.account.users.some(user => {
                    return user.id === this.communication.user_id && user.role_id === Role.COMMERCIAL;
                });
            },

            isConferenceOver() {
                return this.communication.videoConferenceStatus === VideoConferenceStatus.OVER;
            },

            isDisabled() {
                if (!this.canJoinRoom && !this.communication.url) {
                    return true;
                }

                if (this.communication.created_by_user) {
                    return true;
                }

                if (this.authUser.isAdmin()) {
                    return false;
                }

                if (this.authUser.id === this.communication.user_id) {
                    return false;
                }

                if (this.communication.receptionist) {
                    return false;
                }

                if (this.createdByFinance && !this.authUser.isCommercialDirector() && !this.authUser.isDirector()) {
                    return true;
                }

                return false;
            },

            isInterrupted() {
                return this.isConferenceOver && this.communication.status === CommunicationStatus.INTERRUPTED;
            },

            isRecorded() {
                return this.roomData.allow_recording;
            },

            isRecordingAvailable() {
                return this.communication.videoRecordingStatus === VideoRecordingStatus.RECORDING_AVAILABLE;
            },

            isRecordingProcessing() {
                return this.communication.videoRecordingStatus === VideoRecordingStatus.PROCESSING;
            },

            isUnanswered() {
                return this.isConferenceOver && this.communication.status === CommunicationStatus.UNANSWERED;
            },

            roomData() {
                return this.communication.video_room || {};
            },

            roomUrl() {
                const videoRoomUserUrl = this.roomData?.user_url;

                if (!videoRoomUserUrl) {
                    return '';
                }

                return `${process.env.VUE_APP_VIDEO_SERVICE_URL}${videoRoomUserUrl}`;
            },

            showRecLabel() {
                return this.isRecorded && !this.isInterrupted && !this.isUnanswered;
            },

            showVideoWidget() {
                return this.isRecordingAvailable || this.isRecordingProcessing;
            },

            statusColor() {
                return {
                    live: 'bg-red-500',
                    over: 'bg-gray-500',
                    planned: 'bg-green-500',
                }[this.communication.videoConferenceStatus];
            },

            statusText() {
                const key = {
                    live: 'conferenceStatuses.live',
                    over: 'conferenceStatuses.over',
                    planned: 'conferenceStatuses.planned',
                }[this.communication.videoConferenceStatus];

                return this.$t(key);
            },

            subStatusText() {
                if (!this.isConferenceOver) {
                    return '';
                }

                if (this.conferenceDuration) {
                    return this.conferenceDuration;
                }

                if (this.isInterrupted) {
                    return this.$t('communicationStatuses.interrupted');
                }

                if (this.isUnanswered) {
                    return this.$t('communicationStatuses.unanswered');
                }

                return '';
            },
        },

        methods: {
            onJoinClicked() {
                this.$behavior.track('VideoConference', { action: 'join', location: 'communication' });
            },
        },
    };
</script>
