<template>
    <div
        class="wrapper relative flex items-stretch"
        :class="[positioningClassName, {
            '-ml-2.5': animated,
            'text-gray-500': disabled,
        }]"
    >
        <activix-time-picker
            :class="[positioningClassName, { '-ml-px': animated }]"
            :value="value"
            :disabled="disabled"
            ref="timepicker"
            @input="update"
        />
    </div>
</template>

<script>
    export default {
        props: {
            animated: {
                type: Boolean,
                default: true,
            },
            disabled: {
                default: false,
                type: Boolean,
            },
            placeholder: {
                default: '---',
                type: String,
            },
            value: {
                type: Object,
                default: () => undefined,
            },
        },

        computed: {
            positioningClassName() {
                return [
                    'transition-all duration-150',
                    { 'focus-within:ml-0': !this.disabled },
                ];
            },
        },

        methods: {
            update(value) {
                this.$emit('input', value);
            },
        },
    };
</script>

<style scoped>
    .wrapper >>> input {
        @apply bg-white bg-opacity-0 border border-gray-300 border-opacity-0 ring-1 ring-gray-200 ring-opacity-0;
    }

    .wrapper >>> input:is(:focus,:hover,:focus-within) {
        @apply ring-1;
    }
    .wrapper >>> input:is(:focus-within) {
        @apply ring-blue-500 ring-opacity-100 border-blue-500 border-opacity-100;
    }
</style>
