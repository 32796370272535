<template>
    <div
        class="relative flex-1 flex flex-col justify-between gap-3"
        :class="{
            'opacity-50': isInactiveAutomation
        }"
    >
        <div class="flex flex-col justify-between | md:flex-row md:gap-6">
            <div class="flex flex-col mb-1.5 gap-1.5 | md:flex-row md:items-center md:gap-3">
                <label class="mb-0 text-sm uppercase font-semibold tracking-wide text-gray-800" v-text="communicationTitle" />

                <div class="flex items-center px-3 py-0.5 gap-1.5 rounded-full text-sm text-gray-800 bg-gray-300" v-if="communication.automation">
                    <icon name="regular/video-game-magic-wand" />
                    <span v-text="$t('clientCard.boxes.communications.automated')" />
                </div>
            </div>

            <activix-tooltip :content="createdAtTooltip">
                <date-distance class="text-sm text-gray-700 first-letter:capitalize truncate | md:text-base" :date="communicationDate" />
            </activix-tooltip>
        </div>

        <div class="flex flex-col justify-between gap-3 | md:flex-row md:gap-6">
            <div class="flex flex-col flex-grow gap-1.5 | md:gap-3">
                <h5 class="space-y-3 my-0 leading-normal font-semibold text-gray-700" v-if="showSubject">
                    <template v-if="communication.isEmail">
                        <span :class="{ 'italic': !communication.email_subject }" v-text="communication.email_subject || `(${$t('mailClient.noSubject')})`" />
                    </template>

                    <template v-if="communication.isPhone">
                        <span v-text="communication.maskedPhone" v-if="authUser.block_lead_info" />
                        <span v-text="communication.formattedPhone" v-else />
                    </template>
                </h5>

                <div
                    class="last:mb-0"
                    :class="{ 'h-20': !isPhoneAudioDisabled }"
                    v-if="audioFile"
                >
                    <span
                        class="p-1.5 rounded-lg shadow"
                        v-text="$t('dashboards.noAudioAccess')"
                        v-if="isPhoneAudioDisabled"
                    />

                    <audio-player
                        class="border border-gray-200 rounded-md"
                        :file="audioFile"
                        placeholder
                        v-else
                    />
                </div>

                <video-conference-widget
                    class="rounded-lg shadow"
                    :class="recordingStatus ? 'max-w-full' : 'max-w-xs'"
                    :account="account"
                    :communication="communication"
                    v-if="communication.isVideo"
                />

                <div class="flex flex-col gap-3">
                    <template v-if="communication.description">
                        <div class="flex items-start gap-1.5" v-if="communication.isPhone || communication.isVideo ">
                            <icon class="flex-shrink-0 mt-1.5 text-md text-gray-650" name="regular/notes-paper-text" />
                            <p class="italic mb-0 line-clamp-3 text-gray-650" v-text="communication.description" />
                        </div>

                        <p class="mb-0" v-text="communication.description" v-else />
                    </template>

                    <p class="mb-0 line-clamp-3" v-text="communication.formattedExcerpt" v-if="communication.formattedExcerpt" />
                    <p class="mb-0 line-clamp-3" v-text="niotextTemplateContent" v-else-if="niotextTemplateContent" />

                    <div class="flex items-center justify-start gap-3">
                        <div
                            class="flex items-center px-3 py-0.5 gap-1.5 rounded-full text-sm text-gray-800 bg-gray-300"
                            v-if="showCommunicationId"
                        >
                            <span>ID: {{ communication.id }}</span>
                        </div>

                        <activix-email-attachments-button
                            :attachments="communication.attachments"
                            v-if="communication.attachments.length"
                        />

                        <activix-tooltip :content="emailReadOnTooltip" v-if="showEmailReadOn">
                            <div class="flex items-center px-3 py-0.5 gap-1.5 rounded-full text-sm text-gray-800 bg-gray-300">
                                <icon name="regular/email-action-read" />
                                <span> {{ communication.formattedEmailReadOn }} </span>
                            </div>
                        </activix-tooltip>

                        <activix-tooltip :content="$t('clientCard.boxes.communications.exchangeCount')" v-if="showExchangeCount">
                            <div class="flex items-center px-3 py-0.5 gap-1.5 rounded-full text-sm text-gray-800 bg-gray-300">
                                <icon name="regular/data-transfer-diagonal" />
                                <span> {{ exchangeCount }}</span>
                            </div>
                        </activix-tooltip>

                        <activix-tooltip
                            :content="totalClicksTooltip"
                            v-if="communication.clicked"
                        >
                            <div class="flex items-center px-3 py-0.5 gap-1.5 rounded-full text-sm text-gray-800 bg-gray-300">
                                <icon name="regular/cursor" />
                                <span v-text="totalClicksText" />
                            </div>
                        </activix-tooltip>

                        <activix-tooltip :content="$t('clientCard.timeElapsed')" v-if="durationReached">
                            <div class="flex items-center px-3 py-0.5 gap-1.5 rounded-full text-sm text-gray-800 bg-gray-300">
                                <icon name="regular/matches-fire" />
                                <span v-text="durationReached" />
                            </div>
                        </activix-tooltip>
                    </div>
                </div>
            </div>

            <div class="flex justify-between items-center | md:flex-col md:justify-center md:items-end">
                <div class="md:flex-grow">
                    <activix-tooltip :content="userName">
                        <figure class="p-1 rounded-full shadow bg-white">
                            <activix-avatar
                                class="rounded-full"
                                :class="isAuthUser ? 'bg-blue-100 text-blue-600' : 'bg-gray-200 text-gray-700'"
                                :size="27"
                                :username="userName"
                            />
                        </figure>
                    </activix-tooltip>
                </div>

                <div class="flex gap-3">
                    <activix-button
                        class="mt-1.5 rounded | transition justify-self-end self-end place-self-end group-hover:opacity-100 | md:opacity-0"
                        size="small"
                        type="white"
                        @click.stop="openContextModal"
                        v-text="$t('clientCard.boxes.communications.view')"
                        v-if="contextModalEnabled"
                    />

                    <activix-button
                        class="mt-1.5 rounded | transition justify-self-end self-end place-self-end group-hover:opacity-100 | md:opacity-0"
                        size="small"
                        type="white"
                        @click.stop="$emit('edit-communication', communication)"
                        v-text="$t('clientCard.boxes.communications.edit')"
                        v-if="isEditable"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import emojione from 'emojione';

    import ActivixEmailAttachmentsButton from '@/components/buttons/ActivixEmailAttachmentsButton';
    import AudioPlayer from '@/components/audio/AudioPlayer';
    import DateDistance from '@/components/container/date/DateDistance';
    import VideoConferenceWidget from '@/components/container/lead/communications/VideoConferenceWidget';

    import Account from '@/entities/Account';
    import ActivixDate from '@/value-objects/ActivixDate';
    import Communication from '@/entities/Communication';
    import CommunicationKind from '@/entities/CommunicationKind';
    import CommunicationMethod from '@/entities/CommunicationMethod';
    import Lead from '@/entities/Lead';
    import LeadType from '@/entities/LeadType';
    import Role from '@/entities/Role';
    import Service from '@/entities/Service';
    import User from '@/entities/User';

    export default {
        components: { AudioPlayer, ActivixEmailAttachmentsButton, DateDistance, VideoConferenceWidget },

        props: {
            account: {
                required: true,
                type: Account,
            },
            communication: {
                required: true,
                type: Communication,
            },
            lead: {
                required: true,
                type: Lead,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            audioFile() {
                if (!this.communication.isPhone || !this.communication.formattedUrl) {
                    return null;
                }

                return {
                    duration: this.communication.duration_call,
                    id: `communication-${this.communication.id}`,
                    url: this.communication.formattedUrl,
                };
            },

            communicationDate() {
                return this.communication.original_created_at || this.communication.created_at;
            },

            communicationDirectionText() {
                return this.communication.isOutgoing ? this.$t('general.outgoing') : this.$t('general.incoming');
            },

            communicationSubTypeText() {
                if (this.communication.kind === CommunicationKind.MASS_MAILING) {
                    return this.$t('options.massMailing');
                }

                if (this.communication.service_id === Service.WEBBOOST) {
                    return this.$t('general.webBoost');
                }

                if (this.communication.service_id === Service.TELBOOST) {
                    return this.lead.ivr
                        ? this.$t('phoneProvider.ivr')
                        : this.$t('general.phoneUp');
                }

                return '';
            },

            communicationTitle() {
                const title = this.$i18n.locale === 'fr'
                    ? `${this.communicationTypeText} ${this.communicationDirectionText}`
                    : `${this.communicationDirectionText} ${this.communicationTypeText}`;

                return [
                    this.communicationTitlePrefix,
                    title,
                    this.communicationSubTypeText,
                ].filter(part => part).join(' - ');
            },

            communicationTitlePrefix() {
                if (this.isPlanned) {
                    return this.$t('clientCard.boxes.communications.planned');
                }

                return '';
            },

            communicationTypeText() {
                if (this.communication.isEmail) {
                    return this.communication.media?.ziggeo_id
                        ? this.$t('users.edit.emailVideo')
                        : this.$t('users.edit.email');
                }

                if (this.communication.isSms) {
                    return this.communication.created_by_user
                        ? this.$t('clientCard.manualSMS')
                        : this.$t('users.edit.sms');
                }

                if (this.communication.isMessenger) {
                    return this.$t('users.edit.messenger');
                }

                if (this.communication.isVideo) {
                    return this.$t('clientCard.videoconference');
                }

                if (!this.communication.isPhone) {
                    return '';
                }

                if (this.communication.created_by_user) {
                    return this.$t('clientCard.manualCall');
                }

                return this.$tc('general.call', 1);
            },

            communicationUser() {
                const user = this.account.users.find(accountUser => {
                    return accountUser.id === this.communication.user_id;
                });

                return user ? new User(user) : null;
            },

            contextModalEnabled() {
                if (this.communication.isSms && !this.userHasAccessToNioText) {
                    return false;
                }

                return this.communication.isEmail || this.communication.isMessenger || this.communication.isSms;
            },

            createdAtTooltip() {
                return `${this.communication.formattedCreatedAt} <br /> ${this.crmCreatedAtTooltip}`;
            },

            createdByFinance() {
                return this.account.users.some(user => {
                    return user.id === this.communication.user_id && user.role_id === Role.COMMERCIAL;
                });
            },

            crmCreatedAtTooltip() {
                if (!this.communication.original_created_at) {
                    return '';
                }

                const originalCreatedAt = new ActivixDate(this.communication.original_created_at);
                const crmCreatedAt = new ActivixDate(this.communication.created_at);

                if (crmCreatedAt.isSameDay(originalCreatedAt)) {
                    return '';
                }

                return this.$t('clientCard.crmCreatedAt', { date: crmCreatedAt.toHumanShort(false) });
            },

            durationReached() {
                if (!this.showDurationReached || !this.communication.formattedDurationReached) {
                    return '';
                }

                return this.communication.formattedDurationReached;
            },

            emailReadOn() {
                return new ActivixDate(this.communication.email_read_at).toHumanShort();
            },

            emailReadOnTooltip() {
                return this.$t('clientCard.boxes.communications.emailReadOn', [this.emailReadOn]);
            },

            exchangeCount() {
                const counter = (this.communication.inbound_counter + this.communication.outbound_counter) || this.communication.exchange_counter;

                if (counter && [CommunicationMethod.SMS, CommunicationMethod.MESSENGER].includes(this.communication.communication_method_id)) {
                    return counter;
                }

                return null;
            },

            hasEmailReadAt() {
                return (
                    this.authUser.id === this.communication.user_id &&
                    this.communication.email_read_at &&
                    !this.communication.user_seen_email_read_at
                );
            },

            isAuthUser() {
                return this.authUser.isSameUser(this.communication.user_id);
            },

            isAutomation() {
                return !!this.communication.automation;
            },

            isEditable() {
                if (this.communication.isEmail && this.communication.created_by_user) {
                    return true;
                }

                if (this.communication.isSms && this.communication.created_by_user) {
                    return true;
                }

                return (this.communication.isMessenger || this.communication.isPhone || this.communication.isVideo);
            },

            isInactiveAutomation() {
                return this.isAutomation && !this.communication.automation_job.active;
            },

            isPlanned() {
                return this.isAutomation;
            },

            isPhoneAudioDisabled() {
                if (this.communication.created_by_user && !this.communication.created_by_api) {
                    return true;
                }

                if (this.account.disable_communication_audio) {
                    return true;
                }

                if (this.authUser.isAdmin()) {
                    return false;
                }

                if (this.authUser.id === this.communication.user_id) {
                    return false;
                }

                if (this.communication.receptionist) {
                    return false;
                }

                if (this.account.limited_audio_access && this.authUser.isAdvisor() && this.authUser.id !== this.lead.user_id) {
                    return true;
                }

                if (this.authUser.custom_permissions?.limit_audio_access) {
                    return true;
                }

                if (this.createdByFinance) {
                    return this.authUser.isCommercialDirector() || this.authUser.isDirector();
                }

                return true;
            },

            niotextTemplateContent() {
                if (!this.communication.niotext_template) {
                    return '';
                }

                return emojione.shortnameToImage(this.communication.niotext_template.content[this.communication.locale]);
            },

            recordingStatus() {
                return this.communication.videoRecordingStatus;
            },

            showCommunicationId() {
                return this.communication.isPhone && this.authUser.analyst_access;
            },

            showEmailReadOn() {
                return this.communication.isEmail && this.communication.isOutgoing && this.emailReadOn;
            },

            showExchangeCount() {
                return this.lead.account.niotext && this.exchangeCount;
            },

            showDurationReached() {
                return this.lead.isNotOfType(LeadType.EVENT);
            },

            showSubject() {
                return this.communication.isEmail || this.communication.isPhone;
            },

            totalClicksTooltip() {
                return this.$t('clientCard.boxes.communications.clickedTime', [this.communication.total_clicks]);
            },

            totalClicksText() {
                const totalClicks = this.communication.total_clicks;

                return `${totalClicks} ${this.$tc('clientCard.boxes.communications.time', totalClicks)}`;
            },

            userHasAccessToNioText() {
                return this.authUser.hasNioText() && this.authUser.account.hasNioText();
            },

            userName() {
                if (this.communicationUser) {
                    return this.communicationUser.fullName;
                }

                if (this.communication.receptionist) {
                    return this.$t('divisions.reception');
                }

                return '';
            },

            userTooltip() {
                return `<span class="font-bold">${this.$t('clientCard.user')}:</span> ${this.userName}`;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['clearSingleSeenEmailReadAlert']),

            clearEmailRead() {
                if (this.hasEmailReadAt) {
                    this.clearSingleSeenEmailReadAlert(this.communication);
                }
            },

            openContextModal() {
                if (this.communication.isEmail) {
                    this.clearEmailRead();
                }

                this.$emit('open-context-modal', this.communication);
            },
        },
    };
</script>
