<template>
    <div class="client-card | flex flex-col">
        <template v-if="isInitialized">
            <tabs />

            <div v-show="!isLoading">
                <div class="flex flex-wrap -mb-6 lg:-mx-3" :class="{ 'mt-6': lgLayout }">
                    <template v-if="lgLayout">
                        <global-info class="w-full px-4 mb-6" />
                    </template>
                    <div class="flex w-full" v-else>
                        <assign-info class="flex-1 px-3 mb-6" />
                        <global-info class="px-3 mb-6" />
                        <time-info class="flex-1 justify-end px-3 mb-6" />
                    </div>

                    <merge-management
                        class="flex w-full px-3"
                        v-if="mergeManualCheckDuplicate"
                    />

                    <lead-boxes-mobile v-if="lgLayout" />
                    <lead-boxes class="flex flex-wrap w-full" v-else />
                </div>
            </div>

            <portal to="anchor-bottom-right">
                <feature-preview-management-button />
            </portal>

            <follow-up name="followUp" />
            <consent-config name="consentConfig" />
            <duplicate-info name="duplicateInfo" :in-client-card="true" />
            <select-vehicle name="selectVehicle" :in-client-card="true" />
            <cancel-appointment name="cancelAppointment" />
            <screen-pop-no-associated-lead-event v-if="isLeadEvent && isCreatedAfterRelease" />
            <screen-pop-lead-communicating :communicating-lead="communicatingLead" />
        </template>

        <div class="flex-1 flex items-center justify-center" v-if="isLoading">
            <activix-spinner :message="$t('general.loading')" />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import LeadType from '../../entities/LeadType.js';
    import ActivixDate from '../../value-objects/ActivixDate.js';
    import CreatedMethod from '../../entities/CreatedMethod.js';

    // Mixins
    import TrackView from '../../mixins/TrackView.js';

    // Components
    import AssignInfo from '../../components/lead/AssignInfo.vue';
    import CancelAppointment from '../../components/modals/CancelAppointment.vue';
    import ConsentConfig from '../../components/modals/ConsentConfig.vue';
    import DuplicateInfo from '../../components/modals/DuplicateInfo.vue';
    import FeaturePreviewManagementButton from '../../components/container/featurePreview/FeaturePreviewManagementButton.vue';
    import FollowUp from '../../components/modals/FollowUp.vue';
    import GlobalInfo from '../../components/lead/GlobalInfo.vue';
    import LeadBoxes from '../../components/lead/LeadBoxes.vue';
    import LeadBoxesMobile from '../../components/lead/LeadBoxesMobile.vue';
    import MergeManagement from '../../components/lead/MergeManagement.vue';
    import ScreenPopLeadCommunicating from '../../components/modals/ScreenPopLeadCommunicating.vue';
    import ScreenPopNoAssociatedLeadEvent from '../../components/modals/ScreenPopNoAssociatedLeadEvent.vue';
    import SelectVehicle from '../../components/modals/SelectVehicle.vue';
    import Tabs from '../../components/lead/Tabs.vue';
    import TimeInfo from '../../components/lead/TimeInfo.vue';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useLeadStore } from '../../store/modules/lead/store.js';
    import { useInterAccountLeadsStore } from '../../store/modules/interAccountLeads/store.js';
    import { useClientCardStore } from '../../store/modules/clientCard/store.js';

    export default {
        name: 'Lead',

        components: {
            AssignInfo,
            CancelAppointment,
            ConsentConfig,
            DuplicateInfo,
            FeaturePreviewManagementButton,
            FollowUp,
            GlobalInfo,
            LeadBoxes,
            LeadBoxesMobile,
            MergeManagement,
            ScreenPopLeadCommunicating,
            ScreenPopNoAssociatedLeadEvent,
            SelectVehicle,
            Tabs,
            TimeInfo,
        },

        mixins: [TrackView],

        props: {
            relatedLead: {
                type: Object,
                default: () => ({}),
            },
            rightSidebarOpened: {
                type: Boolean,
                default: false,
            },
        },

        metaInfo() {
            return {
                title: this.contextLead.fullName,
            };
        },

        computed: {
            ...mapState(useClientCardStore, ['authorizedAction']),
            ...mapState(useLeadStore, ['mergeManualCheckDuplicate']),
            ...mapState(useGlobalStore, ['leads', 'authUser', 'guest', 'communicatingLead']),
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),

            isCreatedAfterRelease() {
                const releaseDate = new ActivixDate('2021-05-01 00:00');
                const leadCreationDate = new ActivixDate(this.contextLead.created_at);

                return leadCreationDate.isAfter(releaseDate);
            },

            isLeadEvent() {
                return this.contextLead.lead_type_id === LeadType.EVENT;
            },

            isInitialized() {
                return !!this.contextLead.id && !this.$wait.is('fetching.contextAccount');
            },

            isLoading() {
                return !this.isInitialized || this.$wait.is('initializing.lead');
            },

            isCreatedBySerti() {
                return this.contextLead.created_method == CreatedMethod.SERTI;
            },

            shouldShowMergeManagementOnLoad() {
                if (this.isCreatedBySerti) {
                    return false;
                }

                return !this.contextLead.import_validated;
            },
        },

        watch: {
            '$route.params.id': {
                immediate: true,
                handler(newId, previousId) {
                    if (!newId || newId == previousId) {
                        return;
                    }

                    if (newId != this.contextLead.id) {
                        this.$wait.start('initializing.lead');
                    }

                    this.setContextLeadIdAction(newId);
                },
            },

            'contextLead.id': {
                immediate: true,
                handler(newId, previousId) {
                    if (!newId || newId == previousId) {
                        return;
                    }

                    this.whenLeadIsInitialized();

                    this.$wait.end('initializing.lead');

                    this.checkCommunicating();
                },
            },

            guest: {
                immediate: true,
                handler() {
                    this.showGuestAlert();
                },
            },
        },

        methods: {
            ...mapActions(useContextStore, [
                'setContextAccountAction',
                'setContextLeadIdAction',
            ]),
            ...mapActions(useGlobalStore, ['updateLeadAction', 'setCommunicating']),

            whenLeadIsInitialized() {
                // Open duplicate validation if addLead panel not open
                if (!this.rightSidebarOpened) {
                    useLeadStore().mergeManualCheckDuplicate = this.shouldShowMergeManagementOnLoad;
                    useLeadStore().currentMergeAction = '';
                }

                if (
                    !this.contextAccount.id ||
                    this.contextAccount.id != this.contextLead.account_id
                ) {
                    this.setContextAccountAction(this.contextLead.account_id);
                }

                if (this.guest) {
                    this.showGuestAlert();
                }
            },

            showGuestAlert() {
                if (!this.isInitialized) {
                    return;
                }

                if (this.contextLead.lead_type_id == LeadType.EVENT && this.isCreatedAfterRelease) {
                    const nonEventLeads = this.contextLead.customer.leads.filter(lead => {
                        return lead.lead_type_id !== LeadType.EVENT;
                    });

                    if (!nonEventLeads.length) {
                        return;
                    }
                }

                if (this.guest) {
                    this.$notify.warning({
                        duration: -1,
                        ignoreDuplicates: true,
                        text: this.$t('clientCard.guestMode'),
                    });
                } else {
                    this.$notify.clean();
                }
            },

            async checkCommunicating() {
                if (!this.contextLead.id) {
                    return;
                }

                const response = await this.$api.leads.checkCommunicating(this.contextLead.id);

                if (response.isCommunicating) {
                    this.setCommunicating(response.lead);
                }
            },
        },

        beforeDestroy() {
            useInterAccountLeadsStore().leads = [];
        },
    };
</script>
