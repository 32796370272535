<template>
    <section class="flex flex-col gap-x-6 gap-y-12">
        <input-list :fields="identityFields" />

        <div class="grid grid-cols-2 gap-6">
            <div class="flex flex-col gap-6">
                <div class="flex items-center">
                    <icon class="mr-1.5" name="regular/house-chimney-2" />
                    <activix-label class="m-0" :label="$t('clientCard.contactType.home')" />
                </div>
                <input-list :fields="homeContactFields" :column-count="1" />
            </div>
            <div class="flex flex-col gap-6">
                <div class="flex items-center">
                    <icon class="mr-1.5" name="regular/baggage" />
                    <activix-label class="m-0" :label="$t('clientCard.contactType.work')" />
                </div>
                <input-list :fields="workContactFields" :column-count="1" />
            </div>
        </div>

        <div class="flex flex-col gap-6">
            <div class="flex items-center">
                <icon class="mr-1.5" name="regular/pin-1" />
                <activix-label class="m-0" :label="$t('clientCard.address')" />
            </div>
            <input-list :fields="addressFields" />
        </div>
    </section>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import ActivixLabel from '@/components/elements/ActivixLabel';
    import CountryInput from '@/components/container/input/lead/CountryInput';
    import EmailInput from '@/components/container/input/lead/EmailInput';
    import InputList from '@/components/container/input/InputList';
    import InvisibleDateTimePicker from '@/components/container/input/InvisibleDateTimePicker';
    import InvisibleInput from '@/components/container/input/InvisibleInput';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect';
    import PhoneInput from '@/components/container/input/lead/PhoneInput';
    import ProvinceInput from '@/components/container/input/lead/ProvinceInput';

    import ActivixDate from '@/value-objects/ActivixDate';
    import Civility from '@/entities/Civility';
    import Lead from '@/entities/Lead';
    import LeadEmail from '@/entities/LeadEmail';
    import LeadEmailType from '@/entities/LeadEmailType.js';
    import LeadPhone from '@/entities/LeadPhone';
    import LeadPhoneType from '@/entities/LeadPhoneType.js';
    import Locale from '@/entities/Locale';
    import Sex from '@/entities/Sex';

    export default {
        components: {
            ActivixLabel,
            InputList,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            cellPhone() {
                return new LeadPhone(
                    this.lead.cellPhone || { type: LeadPhoneType.CELL },
                );
            },

            homeEmail() {
                return new LeadEmail(
                    this.lead.homeEmail || { type: LeadEmailType.HOME },
                );
            },

            homePhone() {
                return new LeadPhone(
                    this.lead.homePhone || { type: LeadPhoneType.HOME },
                );
            },

            workEmail() {
                return new LeadEmail(
                    this.lead.workEmail || { type: LeadEmailType.WORK },
                );
            },

            workPhone() {
                return new LeadPhone(
                    this.lead.workPhone || { type: LeadPhoneType.WORK },
                );
            },

            guestModeReason() {
                return this.guest ? this.$t('clientCard.guestMode') : '';
            },

            addressFields() {
                return [
                    {
                        column: 1,
                        label: this.$t('clientCard.address'),
                        blockedReason: this.guestModeReason,
                        component: InvisibleInput,
                        props: {
                            readonly: this.guest,
                            value: this.lead.address1,
                        },
                        events: {
                            input: value => this.onLeadInput({ address1: value }),
                        },
                    },
                    {
                        column: 1,
                        label: this.$t(`clientCard.province.${this.lead.country}`),
                        blockedReason: this.guestModeReason,
                        component: ProvinceInput,
                        props: {
                            country: this.lead.country,
                            disabled: this.guest,
                            province: this.lead.province,
                        },
                        events: {
                            'update-lead': this.updateLead,
                        },
                    },
                    {
                        column: 1,
                        label: this.$t(`clientCard.postalCode.${this.lead.country}`),
                        blockedReason: this.guestModeReason,
                        component: InvisibleInput,
                        props: {
                            mask: this.lead.country === 'CA' ? 'postalCode' : 'zipCode',
                            readonly: this.guest,
                            value: this.lead.postal_code,
                        },
                        events: {
                            input: value => this.onLeadInput({ postal_code: value }),
                        },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.city'),
                        blockedReason: this.guestModeReason,
                        component: InvisibleInput,
                        props: {
                            readonly: this.guest,
                            value: this.lead.city,
                        },
                        events: {
                            input: value => this.onLeadInput({ city: value }),
                        },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.country'),
                        blockedReason: this.guestModeReason,
                        component: CountryInput,
                        props: {
                            lead: this.lead,
                            readonly: this.guest,
                        },
                        events: {
                            'update-lead': this.updateLead,
                        },
                    },
                ];
            },

            homeContactFields() {
                return [
                    {
                        label: this.$t('clientCard.phoneType.home'),
                        blockedReason: this.guestModeReason,
                        component: PhoneInput,
                        props: {
                            lead: this.lead,
                            phone: this.homePhone,
                            readonly: this.guest,
                        },
                        events: {
                            'update-phone': payload => this.updatePhone(this.homePhone, payload),
                            'phone-icon-clicked': phone => this.onPhoneIconClicked(phone),
                        },
                    },
                    {
                        label: this.$t('clientCard.phoneType.cell'),
                        blockedReason: this.guestModeReason,
                        component: PhoneInput,
                        props: {
                            lead: this.lead,
                            phone: this.cellPhone,
                            readonly: this.guest,
                        },
                        events: {
                            'update-phone': payload => this.updatePhone(this.cellPhone, payload),
                            'phone-icon-clicked': phone => this.onPhoneIconClicked(phone),
                        },
                    },
                    {
                        label: this.$t('clientCard.emailType.home'),
                        blockedReason: this.guestModeReason,
                        component: EmailInput,
                        props: {
                            email: this.homeEmail,
                            readonly: this.guest,
                        },
                        events: {
                            'update-email': payload => this.updateEmail(this.homeEmail, payload),
                            'email-icon-clicked': () => this.$modal.show('lead:emailLead', this.lead.id),
                        },
                    },
                ];
            },

            identityFields() {
                const civilityOptions = Civility.selectOptions('value', 'text');

                return [
                    {
                        column: 1,
                        label: this.$t('clientCard.civility'),
                        blockedReason: this.guestModeReason,
                        component: InvisibleMultiselect,
                        props: {
                            nullable: true,
                            options: civilityOptions,
                            readonly: this.guest,
                            value: this.lead.civility,
                        },
                        events: {
                            input: value => this.onLeadInput({ civility: value }),
                        },
                    },
                    {
                        column: 1,
                        label: this.$t('clientCard.firstName'),
                        blockedReason: this.guestModeReason,
                        component: InvisibleInput,
                        props: {
                            readonly: this.guest,
                            value: this.lead.first_name,
                        },
                        events: {
                            input: value => this.onLeadInput({ first_name: value }),
                        },
                    },
                    {
                        column: 1,
                        label: this.$t('clientCard.lastName'),
                        blockedReason: this.guestModeReason,
                        component: InvisibleInput,
                        props: {
                            readonly: this.guest,
                            value: this.lead.last_name,
                        },
                        events: {
                            input: value => this.onLeadInput({ last_name: value }),
                        },
                    },

                    {
                        column: 1,
                        label: `${this.$t('clientCard.civility')} (${this.$t('clientCard.secondContact')})`,
                        blockedReason: this.guestModeReason,
                        component: InvisibleMultiselect,
                        props: {
                            nullable: true,
                            options: civilityOptions,
                            readonly: this.guest,
                            value: this.lead.second_contact_civility,
                        },
                        events: {
                            input: value => this.onLeadInput({ second_contact_civility: value }),
                        },
                    },
                    {
                        column: 1,
                        label: this.$t('clientCard.secondContact'),
                        blockedReason: this.guestModeReason,
                        component: InvisibleInput,
                        props: {
                            readonly: this.guest,
                            value: this.lead.second_contact,
                        },
                        events: {
                            input: value => this.onLeadInput({ second_contact: value }),
                        },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.language'),
                        blockedReason: this.guestModeReason,
                        component: InvisibleMultiselect,
                        props: {
                            options: Locale.values.map(locale => {
                                return {
                                    value: locale,
                                    text: this.$t(`database.${locale}`),
                                };
                            }),
                            readonly: this.guest,
                            value: this.lead.locale,
                        },
                        events: {
                            input: value => this.onLeadInput({ locale: value }),
                        },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.sex'),
                        blockedReason: this.guestModeReason,
                        component: InvisibleMultiselect,
                        props: {
                            nullable: true,
                            options: Sex.selectOptions('value', 'text'),
                            readonly: this.guest,
                            value: this.lead.sex,
                        },
                        events: {
                            input: value => this.onLeadInput({ sex: value }),
                        },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.birthDate'),
                        blockedReason: this.guestModeReason,
                        component: InvisibleDateTimePicker,
                        props: {
                            'date-only': true,
                            'end-date': (new ActivixDate('now')).toDateTimeString(),
                            'start-view': 4,
                            readonly: this.guest,
                            value: this.lead.birth_date,
                        },
                        events: {
                            input: value => this.onLeadInput({ birth_date: value }),
                        },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.businessName'),
                        blockedReason: this.guestModeReason,
                        component: InvisibleInput,
                        props: {
                            readonly: this.guest,
                            value: this.lead.business_name,
                        },
                        events: {
                            input: value => this.onLeadInput({ business_name: value }),
                        },
                    },
                ];
            },

            workContactFields() {
                return [
                    {
                        label: this.$t('clientCard.phoneType.work'),
                        blockedReason: this.guestModeReason,
                        component: PhoneInput,
                        props: {
                            lead: this.lead,
                            phone: this.workPhone,
                            readonly: this.guest,
                        },
                        events: {
                            'update-phone': payload => this.updatePhone(this.workPhone, payload),
                            'phone-icon-clicked': phone => this.onPhoneIconClicked(phone),
                        },
                    },
                    {
                        label: this.$t('clientCard.emailType.work'),
                        blockedReason: this.guestModeReason,
                        component: EmailInput,
                        props: {
                            email: this.workEmail,
                            readonly: this.guest,
                        },
                        events: {
                            'update-email': payload => this.updateEmail(this.workEmail, payload),
                            'email-icon-clicked': () => this.$modal.show('lead:emailLead', this.lead.id),
                        },
                    },
                ];
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['setSpecificPhoneToCall']),

            onLeadInput(data) {
                this.updateLead({ data });
            },

            onPhoneIconClicked(phone) {
                this.setSpecificPhoneToCall(phone);
                this.$modal.show('lead:callLead', this.lead.id);
            },

            updateEmail(email, { emailData, callback }) {
                this.$emit('update-email', {
                    email: {
                        id: email.id,
                        type: email.type,
                        ...emailData,
                    },
                    callback,
                });
            },

            updateLead({ data, options = {} }) {
                this.$emit('update-lead', { data, options });
            },

            updatePhone(phone, { phoneData, callback }) {
                this.$emit('update-phone', {
                    phone: {
                        id: phone.id,
                        type: phone.type,
                        ...phoneData,
                    },
                    callback,
                });
            },
        },
    };
</script>
