<template>
    <activix-helper-notification
        :title="$t('modal.noAssociatedEventLead.errorTitle')"
        :description="$t('modal.noAssociatedEventLead.errorDesc')"
        ref="notification"
    >
        <template #avatar>
            <div class="h-12 w-12 flex bg-gray-200 rounded-full shadow items-center justify-center">
                <icon class="text-gray-600 text-2xl mb-1" :name="$icons.warning" />
            </div>
        </template>
        <template #buttons>
            <activix-button
                class="leading-5"
                type="primary"
                tag="router-link"
                @click.native="associate"
            >
                {{ $t('modal.noAssociatedEventLead.actionAssociate') }}
            </activix-button>
            <activix-button class="leading-5" @click="close">
                {{ $t('modal.noAssociatedEventLead.actionDismiss') }}
            </activix-button>
        </template>
    </activix-helper-notification>
</template>

<script>
    import { mapState } from 'pinia';
    import LeadType from '../../entities/LeadType.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        computed: {
            ...mapState(useContextStore, ['contextLead']),
        },
        watch: {
            'contextLead.id': {
                immediate: true,
                handler() {
                    this.checkEventLeadAsAssociated();
                },
            },

            'contextLead.lead_type_id'() {
                this.checkEventLeadAsAssociated();
            },

            'contextLead.customer.leads'() {
                this.checkEventLeadAsAssociated();
            },
        },

        methods: {
            open() {
                this.$nextTick(() => {
                    if (this.$refs.notification) {
                        this.$refs.notification.open();
                    }
                });
            },

            close() {
                if (this.$refs.notification) {
                    this.$refs.notification.close();
                }

                this.$nextTick(() => {
                    this.$reset();
                });
            },

            associate() {
                this.$eventBus.$emit('open-associated-lead-create');
                this.close();
            },

            checkEventLeadAsAssociated() {
                if (this.contextLead.lead_type_id !== LeadType.EVENT) {
                    return;
                }

                const nonEventLeads = this.contextLead.customer.leads.filter(lead => {
                    return lead.id === this.contextLead.event_related_id;
                });

                if (!nonEventLeads.length || !this.contextLead.event_related_id) {
                    this.open();
                }
            },
        },
    };
</script>
