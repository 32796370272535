<template>
    <div>
        <div class="box | mb-6">
            <div class="box-header">
                <h4 class="box-title">
                    {{ $t('clientCard.services.soldServices') }}
                </h4>
            </div>
            <div class="box-body | pt-0">
                <div class="flex flex-wrap -mx-3 mb-12">
                    <lead-input-wrapper class="w-full xs:w-1/2">
                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('clientCard.repairOrder') }}
                            </lead-input-label>
                            <lead-input-value>
                                <input-text
                                    name="repair_order"
                                    :record-id="contextLead.id"
                                    :value="contextLead.repair_order"
                                    :disabled="guest"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('clientCard.repairDate') }}
                            </lead-input-label>
                            <lead-input-value>
                                <date-time-picker
                                    name="repair_date"
                                    :date-only="true"
                                    :disabled="guest"
                                    :enable-empty="true"
                                    :record-id="contextLead.id"
                                    :value="contextLead.repair_date"
                                />
                            </lead-input-value>
                        </lead-input-group>
                    </lead-input-wrapper>
                    <lead-input-wrapper class="w-full xs:w-1/2">
                        <lead-input-group>
                            <lead-input-label>
                                <activix-tooltip :content="$t('clientCard.workOrderTooltip')">
                                    <span>{{ $t('clientCard.workOrder') }}</span>
                                </activix-tooltip>
                            </lead-input-label>
                            <lead-input-value>
                                <input-text
                                    name="work_order"
                                    :record-id="contextLead.id"
                                    :value="contextLead.work_order"
                                    :disabled="guest"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group>
                            <lead-input-label>
                                <activix-tooltip :content="$t('clientCard.openWorkOrderDateTooltip')">
                                    <span>{{ $t('clientCard.openWorkOrderDate') }}</span>
                                </activix-tooltip>
                            </lead-input-label>
                            <lead-input-value>
                                <date-time-picker
                                    name="open_work_order_date"
                                    :disabled="guest"
                                    :enable-empty="true"
                                    :record-id="contextLead.id"
                                    :value="contextLead.open_work_order_date"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('clientCard.workOrderPartialClosureDate') }}
                            </lead-input-label>
                            <lead-input-value>
                                <date-time-picker
                                    name="work_order_partial_closure_date"
                                    :disabled="guest"
                                    :enable-empty="true"
                                    :record-id="contextLead.id"
                                    :value="contextLead.work_order_partial_closure_date"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('clientCard.workOrderClosureDate') }}
                            </lead-input-label>
                            <lead-input-value>
                                <date-time-picker
                                    name="work_order_closure_date"
                                    :disabled="guest"
                                    :enable-empty="true"
                                    :record-id="contextLead.id"
                                    :value="contextLead.work_order_closure_date"
                                />
                            </lead-input-value>
                        </lead-input-group>

                    </lead-input-wrapper>
                </div>

                <product
                    type="service"
                    :product="product"
                    :with-notes="true"
                    :with-minutes="true"
                    :key="product.id"
                    v-for="product in filteredProducts"
                />

                <div class="row | font-bold text-center">
                    <div class="col-xs-3 | text-left">
                        {{ $t('clientCard.total') }}
                    </div>
                    <div class="col-xs-1">
                        =
                    </div>
                    <div class="col-xs-offset-3 col-xs-2">
                        {{ toMoney(totalPrice, 2, $i18n.locale) }}
                    </div>
                    <div class="col-xs-3">
                        {{ totalMinutes }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { sortBy } from 'lodash-es';

    // Components
    import DateTimePicker from './inputs/DateTimePicker.vue';
    import InputText from './inputs/InputText.vue';
    import LeadInputGroup from './LeadInputGroup.vue';
    import LeadInputLabel from './LeadInputLabel.vue';
    import LeadInputValue from './LeadInputValue.vue';
    import LeadInputWrapper from './LeadInputWrapper.vue';
    import Product from './Product.vue';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            DateTimePicker,
            InputText,
            LeadInputGroup,
            LeadInputLabel,
            LeadInputValue,
            LeadInputWrapper,
            Product,
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useGlobalStore, ['guest']),

            totalPrice() {
                let total = 0;

                for (const product of this.contextLead.products) {
                    if (product.type !== 'service' || empty(product.pivot.price) || !product.pivot.sold) {
                        continue;
                    }

                    total += parseFloat(product.pivot.price);
                }

                return total;
            },

            totalMinutes() {
                let total = 0;

                for (const product of this.contextLead.products) {
                    if (empty(product.pivot.minutes) || !product.pivot.sold) {
                        continue;
                    }

                    total += parseFloat(product.pivot.minutes);
                }

                return `${total} min.`;
            },

            filteredProducts() {
                const filteredProducts = this.contextLead.account.products.filter(product => {
                    return (
                        product.type == 'service' &&
                        (product.visible ||
                            this.contextLead.products.some(leadProduct => {
                                return leadProduct.id == product.id && leadProduct.pivot.sold;
                            }))
                    );
                });

                return sortBy(filteredProducts, product => product.order);
            },
        },
    };
</script>
