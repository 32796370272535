<template>
    <div>
        <lead-input
            :disabled="disabled || saving"
            :center-icon="centerIcon"
            :editing="editing"
            :valid="valid"
            @accept="triggerAccept"
            @cancel="cancel"
            @edit="editField"
            @blur="onBlur"
        >
            <activix-masked-input
                type="tel"
                class="w-full"
                :input-class="['input-sm', { 'p-0 border-none bg-transparent': lgLayout }]"
                :mask-type="maskType"
                :disabled="disabled || saving"
                ref="input"
                v-model="currentValue"
            />

            <template #value>
                <div class="flex items-center">
                    <template v-if="showMasked">
                        {{ maskPhone(formatedValue) }}
                    </template>
                    <span nolink v-else>
                        {{ !empty(currentValue) ? formatedValue : '...' }}
                    </span>

                    <div class="ml-2" @click="showPhone = true" v-if="showMasked && !editing">
                        <icon class="link-grey-light" name="regular/phone" v-if="!showPhone" />
                        <span class="text-grey-500" v-else>{{ countdown }}</span>
                    </div>
                </div>
            </template>
        </lead-input>

        <activix-confirm-modal
            :approve-text="$t('general.yes')"
            :content="$t('clientCard.blockedPhoneModification')"
            :deny-text="$t('general.no')"
            :opened.sync="blockedPhoneModalOpened"
            :title="$t('general.areYouSure')"
            type="warning"
            @approve="focusInput"
        />
    </div>
</template>

<script>
    // Utils
    import { mapActions, mapState } from 'pinia';
    import { showWarning } from '../../../utils/toastr.js';

    // Components
    import LeadInput from '../LeadInput.vue';
    import { useGlobalStore } from '../../../store/store.js';
    import { useContextStore } from '../../../store/modules/context/store.js';

    export default {
        components: {
            LeadInput,
        },

        props: {
            name: String,
            value: [String, Number],
            placeholder: String,
            blocked: Boolean,
            disabled: Boolean,
            masked: Boolean,
            recordId: Number,
            width: String,
            type: {
                type: String,
                required: true,
            },
            valid: {
                type: Boolean,
                default: true,
            },
            extension: {
                type: Boolean,
                default: false,
            },
            centerIcon: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                currentValue: '',
                editing: false,
                loading: false,
                saving: false,
                showPhone: false,
                countdown: 5,
                blockedPhoneModalOpened: false,
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),

            formatedValue() {
                return this.extension
                    ? this.formatExtension(this.currentValue)
                    : this.formatPhone(this.currentValue) || this.currentValue;
            },

            showMasked() {
                return this.masked && this.valid && this.countdown > 0 && !empty(this.currentValue);
            },

            maskType() {
                return this.extension ? 'phoneExtension' : 'phone';
            },
        },

        watch: {
            showPhone(newValue) {
                if (!newValue) {
                    this.countdown = 5;
                    return;
                }

                this.reduceCountDown();
            },

            value() {
                if (empty(this.value) || this.value === false) {
                    this.currentValue = null;
                    return;
                }

                this.currentValue = this.removeCountryCode(this.value);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['addLeadPhoneAction', 'updateLeadPhoneAction', 'deleteLeadPhoneAction']),

            removeCountryCode(phone) {
                if (phone && phone.indexOf('+1') == 0) {
                    return phone.substr(2);
                }

                return phone;
            },

            reduceCountDown() {
                this.countdown--;

                if (this.countdown > 0) {
                    setTimeout(() => {
                        this.reduceCountDown();
                    }, 1000);
                }
            },

            async focusInput() {
                this.editing = true;

                await this.$nextTick();

                $(this.$refs.input).focus();
            },

            editField() {
                if (this.disabled || this.showMasked) {
                    return;
                }

                if (this.blocked) {
                    this.blockedPhoneModalOpened = true;
                    return;
                }

                this.focusInput();
            },

            triggerAccept() {
                if (!this.editing || this.saving) {
                    return;
                }

                this.saving = true;

                this.accept(this.currentValue);
            },

            async accept(value) {
                // Only update if value changed
                if (value == this.removeCountryCode(this.value) || (empty(value) && empty(this.value))) {
                    this.editing = false;
                    this.saving = false;
                    return;
                }

                this.loading = true;

                const payload = {
                    type: this.type,
                    lead_id: this.contextLead.id,
                };

                if (this.extension) {
                    payload.extension = value;
                } else {
                    payload.phone = value;
                }

                if (empty(this.recordId)) {
                    await this.addLeadPhoneAction(payload, this.contextLead.id);
                } else if (empty(value) && !this.extension) {
                    try {
                        await this.deleteLeadPhoneAction(this.recordId);
                    } catch (e) {
                        if (e.response && e.response.status == 400) {
                            showWarning(this.$t('toastr.nioTextPhoneCantBeDeleted'));
                            this.currentValue = this.removeCountryCode(value);
                        }
                    }
                } else {
                    try {
                        await this.updateLeadPhoneAction(payload, this.recordId, this.contextLead.id);
                    } catch (e) {
                        if (e.response && e.response.status == 400) {
                            showWarning(this.$t('toastr.phoneMustBeMobile'));
                            this.currentValue = this.removeCountryCode(value);
                        }
                    }
                }

                this.editing = false;
                this.loading = false;
                this.saving = false;
            },

            cancel() {
                this.editing = false;
                this.saving = false;
                this.currentValue = this.removeCountryCode(this.value);
            },

            init() {
                if (empty(this.value) || !this.masked || !this.valid) {
                    this.showPhone = true;
                }

                if (!empty(this.value)) {
                    this.currentValue = this.removeCountryCode(this.value);
                }
            },

            onBlur() {
                // Delay is necessary to prevent event overlapping
                setTimeout(() => {
                    this.triggerAccept();
                }, 50);
            },
        },

        created() {
            this.init();
        },
    };
</script>
