<template>
    <div class="hidden | md:block" v-if="featurePreviews.length">
        <button
            class="relative flex flex-col justify-center items-center w-16 h-12 py-3 rounded-lg shadow-xl text-gray-700 bg-white bg-opacity-80 filter backdrop-blur-sm | hover:bg-gray-300"
            @click="modals.featurePreviewManagement.opened = true"
        >
            <icon name="regular/lab-flask-experiment" />
            <span class="absolute inset-x-0 top-0 flex justify-center mb-3" v-if="hasRecentFeature">
                <span class="w-6 h-1.5 rounded-b bg-blue-500 bg-opacity-80 backdrop-blur" />
            </span>
        </button>

        <feature-preview-management :feature-previews="featurePreviews" :opened.sync="modals.featurePreviewManagement.opened" />
    </div>
</template>

<script>
    import FeaturePreviewManagement from '@/components/modals/FeaturePreviewManagement.vue';

    import FeaturePreview from '@/entities/FeaturePreview.js';

    export default {
        components: {
            FeaturePreviewManagement,
        },

        data() {
            return {
                modals: {
                    featurePreviewManagement: {
                        opened: false,
                    },
                },
            };
        },

        computed: {
            featurePreviews() {
                return this.$auth.featurePreviews()
                    .map(featurePreview => new FeaturePreview(featurePreview));
            },

            hasRecentFeature() {
                return this.featurePreviews.some(featurePreview => featurePreview.isNew);
            },
        },
    };
</script>
