<template>
    <progress-bar :steps="progressBarSteps" />
</template>

<script>
    import ProgressBar from '@/components/container/lead/ProgressBar.vue';
    import Lead from '@/entities/Lead.js';

    export default {
        components: { ProgressBar },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            progressBarSteps() {
                return [
                    { isActive: true },
                    {
                        tooltip: this.$t('clientCard.boxes.salesProcess.progress.appointmentScheduled'),
                        isActive: () => !!this.lead.appointment_date,
                        icon: 'regular/send-email',
                    },
                    {
                        tooltip: this.$t('clientCard.boxes.salesProcess.progress.clientVisited'),
                        isActive: () => !!this.lead.presented_date,
                        icon: 'regular/accounting-document',
                    },
                    {
                        tooltip: this.$t('clientCard.plannedPickUpDate'),
                        isActive: () => !!this.lead.planned_pick_up_date,
                        icon: 'regular/login-keys',
                    },
                    {
                        tooltip: this.$t('clientCard.invoiced'),
                        isActive: () => this.lead.invoiced,
                        icon: 'regular/check-circle-1',
                    },
                ];
            },
        },
    };
</script>
