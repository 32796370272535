<template>
    <activix-tooltip :content="tooltip">
        <follow-up-field
            follow-up="refinanced"
            :follow-up-params="{ last: 'presented' }"
            :date="lead.refinanced_date"
            :disabled="isDisabled"
            :lead-id="lead.id"
            @input="updateLead({ refinanced_date: $event })"
        />
    </activix-tooltip>
</template>

<script>
    import { camelCase } from 'lodash-es';

    import FollowUpField from '@/components/container/lead/input/FollowUpField.vue';

    import Lead from '@/entities/Lead';
    import LeadVehicleModality from '@/entities/LeadVehicleModality.js';

    export default {
        components: { FollowUpField },

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            disabledTooltip: {
                type: String,
                default: '',
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            currentLeasedVehicle() {
                return this.lead.exchange_vehicles.find(v => v.modality === LeadVehicleModality.LEASING);
            },

            isDisabled() {
                if (this.disabled) {
                    return true;
                }

                if ((this.lead.sale_date && !this.lead.refinanced_date) || !this.currentLeasedVehicle) {
                    return true;
                }

                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                return !this.$can('leads.refinanced:update');
            },

            tooltip() {
                if (this.disabled) {
                    return this.disabledTooltip;
                }

                if (this.lead.hasInvalidStatus()) {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.refinanced'),
                    ])}`;
                }

                if (this.lead.sale_by_phone) {
                    return this.$tc('result.saleByPhone', 1);
                }

                if (this.lead.delivered_date) {
                    return this.$t('clientCard.needDeliveredUnchecked');
                }

                if (this.lead.sale_date && !this.lead.refinanced_date) {
                    return this.$t('clientCard.needRenewalUnchecked');
                }

                let tooltip = '';

                if (this.lead.refinanced_date && !empty(this.lead.refinanced_type)) {
                    tooltip += `<em>${this.$t(`renewal.${camelCase(this.lead.refinanced_type)}`)}`;
                }

                if ((!this.lead.sale_date || this.lead.refinanced_date) && !this.currentLeasedVehicle) {
                    tooltip += tooltip ? '<br>' : '';
                    tooltip += `${this.$t('clientCard.refinancedTooltip')}`;
                }

                return tooltip;
            },
        },

        methods: {
            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
