<template>
    <component
        :is="featureTemplate"
        :feature-preview="featurePreview"
    />
</template>

<script>
    import { startCase } from 'lodash-es';

    import FeaturePreview from '@/entities/FeaturePreview.js';

    const components = {};
    const requireComponent = require.context('./templates', true, /\.(js|vue)$/i);

    requireComponent.keys().forEach(fileName => {
        const componentConfig = requireComponent(fileName);
        const componentFileName = fileName.split('/').pop().replace(/\.\w+$/, '');
        const componentName = startCase(componentFileName).replace(/\s/g, '');

        components[componentName] = componentConfig.default || componentConfig;
    });

    export default {
        components: { ...components },

        props: {
            featurePreview: {
                type: FeaturePreview,
                required: true,
            },
        },

        computed: {
            featureTemplate() {
                return startCase(this.featurePreview.key).replace(/\s/g, '');
            },
        },
    };
</script>
