<template>
    <div>
        <div class="box | flex flex-col mb-6">
            <div class="box-header | flex items-center">
                <h4 class="box-title">
                    {{ $t('clientCard.comment') }}
                </h4>

                <div class="relative flex items-center ml-2">
                    <activix-tooltip :content="$t('dashboards.refresh')">
                        <icon
                            :name="$icons.loading"
                            class="link-grey-light"
                            :class="{ 'spin-inverse': $wait.is('fetching.comments') }"
                            @click="refreshComments"
                        />
                    </activix-tooltip>

                    <div
                        class="badge badge-warning | absolute top-0 right-0 -mr-px -mt-px"
                        v-if="refreshCommentsNeeded"
                    />
                </div>
            </div>

            <comment
                class="box-body | flex-1 pt-0"
                :classes="!hasCommercial && !contextLead.isService() ? 'lg:max-h-screen-75' : 'lg:max-h-screen-50'"
                :lead-id="contextLead.id"
                :show-loading="!!contextLead.latest_comment_id"
                :class="{ 'pb-2': mdLayout }"
                ref="comments"
            />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import Comment from './Comment.vue';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        name: 'CommentBox',

        components: {
            Comment,
        },

        data() {
            return {
                refreshCommentsNeeded: false,
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),

            hasCommercial() {
                return this.contextLead.account.commercial;
            },
        },

        watch: {
            '$route.name'(newValue) {
                if (newValue != 'leads.update') {
                    return;
                }

                this.$refs.comments.loadComments();
            },
        },

        methods: {
            onRefreshCommentsNeeded() {
                this.refreshCommentsNeeded = true;
            },

            refreshComments() {
                this.$refs.comments.fetchCommentPage(true);
                this.refreshCommentsNeeded = false;
            },
        },

        mounted() {
            this.$eventBus.$on('refresh-comments', this.onRefreshCommentsNeeded);
        },

        beforeDestroy() {
            this.$eventBus.$off('refresh-comments', this.onRefreshCommentsNeeded);
        },
    };
</script>
