<template>
    <div :class="{ 'px-3': withPadding }">
        <slot />
        <lead-custom-input
            :field="field"
            :disabled="guest"
            :vehicle="vehicle"
            :key="field.id"
            v-for="field in visibleFields"
        />
    </div>
</template>

<script>

    // Components
    import { mapState } from 'pinia';
    import LeadCustomInput from './LeadCustomInput.vue';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            LeadCustomInput,
        },

        props: {
            withPadding: {
                type: Boolean,
                default: true,
            },
            section: {
                type: String,
                required: false,
            },
            displayType: {
                type: String,
                default: 'line',
            },
            position: {
                type: String,
                validator: value => ['right', 'left'].includes(value),
            },
            vehicle: {
                type: Object,
                default: () => {},
            },
        },
        computed: {
            ...mapState(useGlobalStore, ['guest']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            fields() {
                return this.contextAccount.getAllCustomFields().filter(field => {
                    return field.visible && field.section === this.section && field.display === this.displayType;
                });
            },

            leftFields() {
                return this.fields.slice(0, Math.ceil(this.fields.length / 2));
            },

            rightFields() {
                return this.fields.slice(this.leftFields.length, this.fields.length);
            },

            visibleFields() {
                if (!this.position) {
                    return this.fields;
                }

                return this.position === 'left' ? this.leftFields : this.rightFields;
            },
        },
    };
</script>
