<template>
    <activix-tooltip :content="tooltip">
        <follow-up-field
            follow-up="be_back"
            :date="lead.be_back_date"
            :disabled="isDisabled"
            :lead-id="lead.id"
            :remove-confirmation="false"
            :show-follow-up-modal-from-url="showFollowUpModalFromUrl"
            @input="updateLead({ be_back_date: $event })"
        />
    </activix-tooltip>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import FollowUpField from '@/components/container/lead/input/FollowUpField.vue';

    import Lead from '@/entities/Lead.js';
    import LeadStatus from '@/entities/LeadStatus.js';

    export default {
        components: { FollowUpField },

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            disabledTooltip: {
                type: String,
                default: '',
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            isDisabled() {
                if (this.disabled) {
                    return true;
                }

                if (!this.lead.presented_date || this.lead.status === LeadStatus.LOST) {
                    return true;
                }

                if (this.authUser.id && !this.authUser.isAuthorizedToActionOnLead(this.lead)) {
                    return true;
                }

                return !this.$can('leads.be_back_date:update');
            },

            showFollowUpModalFromUrl() {
                return (
                    !this.isDisabled &&
                    this.lead.presented_date &&
                    !this.lead.delivered_date &&
                    !this.lead.sale_date &&
                    !this.lead.be_back_date &&
                    !empty(this.$route.query.date)
                );
            },

            tooltip() {
                if (this.disabled) {
                    return this.disabledTooltip;
                }

                if (this.lead.hasInvalidStatus()) {
                    return `<em>${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.beBack'),
                    ])}</em>`;
                }

                if (!this.lead.presented_date) {
                    return this.$t('clientCard.needPresentedDealerChecked');
                }

                return '';
            },
        },

        methods: {
            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
