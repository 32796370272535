<template>
    <flexible-input :blocked-reason="internalBlockedReason" :label="$t('clientCard.division')">
        <invisible-multiselect
            :disabled="disabled || isRestricted"
            :nullable="true"
            :options="options"
            :value="value"
            @input="updateLead({ division_id: $event })"
        />
    </flexible-input>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import FlexibleInput from '@/components/container/input/FlexibleInput';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect';

    import Division from '@/entities/Division';
    import Lead from '@/entities/Lead';
    import LeadType from '@/entities/LeadType';

    export default {
        components: {
            FlexibleInput,
            InvisibleMultiselect,
        },

        props: {
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs']),

            internalBlockedReason() {
                if (this.blockedReason) {
                    return this.blockedReason;
                }

                if (this.lead.isOfType(LeadType.PRE_BOOKING, LeadType.DMS)) {
                    return this.$t('clientCard.cantChangeDivisionLeadType', [
                        LeadType.getTranslation(this.lead.lead_type_id),
                        this.$t('divisions.service'),
                    ]);
                }

                if (this.hasSoldServiceProducts) {
                    return this.$t('clientCard.cantChangeDivisionSoldProducts', [this.$t('divisions.service')]);
                }

                return '';
            },

            options() {
                const divisions = [];

                for (const division of this.configs.divisions) {
                    if (division.id > Division.SERVICE) {
                        continue;
                    }

                    if (
                        division.id === Division.SERVICE &&
                        [
                            LeadType.LOYALTY,
                            LeadType.RENEWAL,
                            LeadType.WEB_ORDER,
                        ].includes(this.lead.lead_type_id)
                    ) {
                        continue;
                    }

                    // If service, only allow if user & account has access
                    if (division.id === Division.SERVICE && !this.lead.isService()) {
                        if (!this.lead.account.service || !this.authUser.hasDivisionAccess(Division.SERVICE)) {
                            continue;
                        }
                    }

                    divisions.push({
                        value: division.id,
                        text: this.$t(`divisions.${division.name}`),
                    });
                }

                // Sort
                divisions.sort((a, b) => a.text.localeCompare(b.text));

                return divisions;
            },

            hasSoldServiceProducts() {
                return this.lead.products.some(product => product.pivot.sold && product.type === 'service');
            },

            isRestricted() {
                return this.lead.isOfType(LeadType.PRE_BOOKING) ||
                    this.lead.isOfType(LeadType.DMS) ||
                    this.hasSoldServiceProducts;
            },

            value() {
                return this.lead.division_id;
            },
        },

        methods: {
            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
