import Enum from './Enum.js';

export default class LeadForm extends Enum {
    static get entries() {
        return {
            appointment_request: 'appointment_request',
            auto_show: 'auto_show',
            call_me: 'call_me',
            carproof: 'carproof',
            coupon: 'coupon',
            credit: 'credit',
            exchange_evaluation: 'exchange_evaluation',
            express_request: 'express_request',
            information_request: 'information_request',
            mobile: 'mobile',
            price_new: 'price_new',
            price_use: 'price_use',
            test_drive: 'test_drive',
        };
    }

    static get APPOINTMENT_REQUEST() {
        return this.entries.appointment_request;
    }

    static get AUTO_SHOW() {
        return this.entries.auto_show;
    }

    static get CALL_ME() {
        return this.entries.call_me;
    }

    static get CARPROOF() {
        return this.entries.carproof;
    }

    static get COUPON() {
        return this.entries.coupon;
    }

    static get CREDIT() {
        return this.entries.credit;
    }

    static get EXCHANGE_EVALUATION() {
        return this.entries.exchange_evaluation;
    }

    static get EXPRESS_REQUEST() {
        return this.entries.express_request;
    }

    static get INFORMATION_REQUEST() {
        return this.entries.information_request;
    }

    static get MOBILE() {
        return this.entries.mobile;
    }

    static get PRICE_NEW() {
        return this.entries.price_new;
    }

    static get PRICE_USE() {
        return this.entries.price_use;
    }

    static get TEST_DRIVE() {
        return this.entries.test_drive;
    }

    static get translationGroup() {
        return 'leadForms';
    }
}
