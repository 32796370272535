<template>
    <activix-tooltip :content="tooltip">
        <follow-up-field
            follow-up="available"
            :follow-up-params="{ last: 'sale' }"
            :date="lead.available_date"
            :disabled="isDisabled"
            :lead-id="lead.id"
            @input="updateLead({ available_date: $event })"
        />
    </activix-tooltip>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import FollowUpField from '@/components/container/lead/input/FollowUpField.vue';

    import Lead from '@/entities/Lead.js';
    import LeadType from '@/entities/LeadType.js';

    export default {
        components: {
            FollowUpField,
        },

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            disabledTooltip: {
                type: String,
                default: '',
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            isDisabled() {
                if (this.disabled) {
                    return true;
                }

                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.lead.sale_date && !this.lead.sale_by_phone) {
                    return true;
                }

                if (!this.$can('leads.available_date:update')) {
                    return true;
                }

                return this.guest && !this.lead.hasDeliveryMan(this.authUser.id);
            },

            tooltip() {
                if (this.disabled) {
                    return this.disabledTooltip;
                }

                if (!this.lead.sale_date && !this.lead.sale_by_phone) {
                    if (this.lead.isOfType(LeadType.RENEWAL)) {
                        return this.$t('clientCard.needRenewalChecked');
                    }

                    return this.$t('clientCard.needSaleChecked');
                }

                if (this.lead.hasInvalidStatus()) {
                    return `<em>${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.available'),
                    ])}</em>`;
                }

                return '';
            },
        },

        methods: {
            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
