<template>
    <label class="m-0 px-3 py-1.5 rounded-full text-xs bg-gray-300" v-text="title" />
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                required: true,
            },
        },
    };
</script>
