var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex divide-x divide-gray-200",class:{ 'flex-col': _vm.asDropdownItems }},[_c('button-icon',{staticClass:"py-3 !justify-start",class:{
            'px-4': _vm.asDropdownItems,
            'px-6': !_vm.asDropdownItems,
            'hover:!bg-gray-100': _vm.asDropdownItems && !_vm.phoneIsDisabled,
        },attrs:{"disabled":_vm.phoneIsDisabled,"icon-name":_vm.$icons.phone,"indicator":_vm.preferenceIsPhone,"tooltip":_vm.phoneTooltip,"vertical-layout":_vm.asDropdownItems},on:{"click":_vm.onPhoneClick},scopedSlots:_vm._u([(_vm.asDropdownItems)?{key:"after-icon",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(_vm.$t('clientCard.phone'))}})]},proxy:true}:null],null,true)}),_c('button-icon',{staticClass:"py-3 !justify-start",class:{
            'px-4': _vm.asDropdownItems,
            'px-6': !_vm.asDropdownItems,
            'hover:!bg-gray-100': _vm.asDropdownItems && !_vm.emailIsDisabled,
        },attrs:{"disabled":_vm.emailIsDisabled,"icon-name":_vm.$icons.email,"indicator":_vm.preferenceIsEmail,"tooltip":_vm.emailTooltip,"vertical-layout":_vm.asDropdownItems},on:{"click":_vm.onEmailClick},scopedSlots:_vm._u([(_vm.asDropdownItems)?{key:"after-icon",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(_vm.$t('clientCard.email'))}})]},proxy:true}:null],null,true)}),_c('button-icon',{staticClass:"py-3 !justify-start",class:{
            'px-4': _vm.asDropdownItems,
            'px-6': !_vm.asDropdownItems,
            'hover:!bg-gray-100': _vm.asDropdownItems && !_vm.smsIsDisabled,
        },attrs:{"disabled":_vm.smsIsDisabled,"icon-name":_vm.$icons.sms,"indicator":_vm.preferenceIsSms,"tooltip":_vm.smsTooltip,"vertical-layout":_vm.asDropdownItems},on:{"click":_vm.onSmsClick},scopedSlots:_vm._u([(_vm.asDropdownItems)?{key:"after-icon",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(_vm.$t('clientCard.sms'))}})]},proxy:true}:null],null,true)}),_c('button-icon',{staticClass:"py-3 !justify-start",class:{
            'px-4': _vm.asDropdownItems,
            'px-6': !_vm.asDropdownItems,
            'hover:!bg-gray-100': _vm.asDropdownItems && !_vm.videoIsDisabled,
        },attrs:{"data-intercom-target":_vm.videoIntercomLabel,"disabled":_vm.videoIsDisabled,"icon-name":_vm.$icons.camera,"indicator":_vm.preferenceIsSms,"tooltip":_vm.videoTooltip},on:{"click":_vm.onVideoClick},scopedSlots:_vm._u([(_vm.asDropdownItems)?{key:"after-icon",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(_vm.$t('clientCard.videoconference'))}})]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }