<template>
    <input-list :fields="fields" />
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import { toCurrency } from '@/utils/numbers.js';

    import ActivixCheckbox from '@/components/elements/ActivixCheckbox';
    import CampaignInput from '@/components/container/input/lead/CampaignInput';
    import DivisionInput from '@/components/container/input/lead/DivisionInput';
    import FormInput from '@/components/container/input/lead/FormInput';
    import InputList from '@/components/container/input/InputList';
    import InvisibleDateTimePicker from '@/components/container/input/InvisibleDateTimePicker.vue';
    import InvisibleInput from '@/components/container/input/InvisibleInput';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect.vue';
    import InvisibleTextarea from '@/components/container/input/InvisibleTextarea';
    import NavigationHistoryInput from '@/components/container/input/lead/NavigationHistoryInput';
    import Rating from '@/components/container/input/Rating';
    import SourceInput from '@/components/container/input/lead/SourceInput';

    import CreatedMethods from '@/entities/CreatedMethod.js';
    import Lead from '@/entities/Lead.js';
    import LeadType from '@/entities/LeadType';
    import Segment from '@/entities/Segment.js';

    export default {
        components: {
            InputList,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            fields() {
                const guestMode = this.guest;
                const guestModeReason = guestMode ? this.$t('clientCard.guestMode') : '';
                const invalidStatus = this.lead.hasInvalidStatus();
                const invalidStatusReason = invalidStatus ? this.$t(`clientCard.blocked.leadStatus.${this.lead.status}`) : '';
                const readOnlyFieldReason = this.$t('clientCard.blocked.readOnlyField');

                return [
                    {
                        column: 1,
                        visible: this.lead.account.hasProcessField('division'),
                        component: DivisionInput,
                        rawComponent: true,
                        props: {
                            blockedReason: guestModeReason,
                            disabled: guestMode,
                            lead: this.lead,
                        },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasProcessField('source'),
                        label: this.$t('clientCard.provider'),
                        blockedReason: readOnlyFieldReason,
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: this.sourceProvider,
                        },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasProcessField('source'),
                        component: SourceInput,
                        rawComponent: true,
                        props: {
                            blockedReason: guestModeReason,
                            disabled: guestMode,
                            lead: this.lead,
                        },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 1,
                        visible: this.leadFormAvailable && this.hasGeneralInfoField('lead_form'),
                        label: this.$t('clientCard.leadForm'),
                        blockedReason: guestModeReason,
                        component: FormInput,
                        props: {
                            disabled: guestMode,
                            lead: this.lead,
                        },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasProcessField('budget'),
                        label: this.$t('accounts.edit.budgetTextField'),
                        blockedReason: guestModeReason,
                        component: InvisibleInput,
                        props: {
                            disabled: guestMode,
                            value: this.lead.budget,
                        },
                        events: { input: value => this.onLeadInput({ budget: value }) },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasProcessField('sector'),
                        label: this.$t('accounts.edit.sector'),
                        blockedReason: guestModeReason,
                        component: InvisibleInput,
                        props: {
                            disabled: guestMode,
                            value: this.lead.sector,
                        },
                        events: { input: value => this.onLeadInput({ sector: value }) },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasProcessField('storage') && this.lead.isService(),
                        label: this.$t('clientCard.storage'),
                        blockedReason: guestModeReason,
                        component: InvisibleInput,
                        props: {
                            disabled: guestMode,
                            value: this.lead.storage,
                        },
                        events: { input: value => this.onLeadInput({ storage: value }) },
                    },
                    {
                        column: 1,
                        visible: this.lead.isService() && this.lead.account.hasGeneralInfoField('prepaid'),
                        label: this.$t('clientCard.prepaid'),
                        blockedReason: guestModeReason || invalidStatusReason,
                        component: ActivixCheckbox,
                        props: {
                            disabled: guestMode || invalidStatus,
                            value: this.lead.prepaid,
                        },
                        events: { input: value => this.onLeadInput({ prepaid: value }) },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasGeneralInfoField('comment'),
                        label: this.$t('clientCard.generalComment'),
                        blockedReason: guestModeReason,
                        component: InvisibleTextarea,
                        fixedHeight: false,
                        props: {
                            disabled: guestMode,
                            value: this.lead.comment,
                        },
                        events: { input: value => this.onLeadInput({ comment: value }) },
                    },
                    {
                        column: 2,
                        visible: this.lead.account.hasProcessField('rating'),
                        label: this.$t('clientCard.rating'),
                        blockedReason: guestModeReason,
                        component: Rating,
                        props: {
                            rating: this.lead.rating,
                            ratingComment: this.lead.rating_comment,
                            readonly: guestMode,
                        },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 2,
                        visible: this.lead.isService(),
                        label: this.$t('clientCard.services.averageSpending'),
                        labelTooltip: this.$t('clientCard.services.averageSpendingTooltip'),
                        blockedReason: readOnlyFieldReason,
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: toCurrency(this.averageSpending),
                        },
                    },
                    {
                        column: 2,
                        visible: this.lead.account.hasProcessField('client_number') &&
                            this.lead.isNotOfType(LeadType.EVENT) &&
                            this.lead.account.client_number,
                        label: this.$t('clientCard.clientNumber'),
                        blockedReason: guestModeReason,
                        component: InvisibleInput,
                        props: {
                            disabled: guestMode,
                            value: this.lead.client_number,
                        },
                        events: { input: value => this.onLeadInput({ client_number: value }) },
                    },
                    {
                        column: 2,
                        visible: this.lead.account.hasProcessField('created_method'),
                        label: this.$t('dashboards.createdMethodString'),
                        blockedReason: readOnlyFieldReason,
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: CreatedMethods.getTranslation(this.lead.created_method),
                        },
                    },
                    {
                        column: 2,
                        visible: this.lead.isOfType(LeadType.PHONE) && this.lead.account.hasProcessField('call_date'),
                        label: this.$t('clientCard.callDate'),
                        blockedReason: guestModeReason,
                        component: InvisibleDateTimePicker,
                        props: {
                            disabled: guestMode,
                            value: this.lead.call_date,
                        },
                        events: { input: value => this.onLeadInput({ call_date: value }) },
                    },
                    {
                        column: 2,
                        visible: this.showIntentionField && this.hasGeneralInfoField('navigation_history'),
                        label: this.$t('clientCard.navigationHistory'),
                        component: NavigationHistoryInput,
                        props: {
                            value: this.lead.navigation_history,
                        },
                    },
                    {
                        column: 2,
                        visible: this.hasGeneralInfoField('campaign') && this.lead.isOfType(LeadType.EVENT),
                        label: this.$t('clientCard.event.event'),
                        blockedReason: guestModeReason,
                        component: CampaignInput,
                        props: {
                            disabled: guestMode,
                            lead: this.lead,
                        },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 2,
                        visible: this.hasGeneralInfoField('campaign') && !this.lead.isOfType(LeadType.EVENT),
                        label: this.$t('dashboards.importedCampaign'),
                        blockedReason: guestModeReason,
                        component: InvisibleInput,
                        props: {
                            disabled: guestMode,
                            value: this.lead.imported_campaign,
                        },
                        events: { input: value => this.onLeadInput({ imported_campaign: value }) },
                    },
                    {
                        column: 2,
                        visible: this.showSegmentField,
                        label: this.$t('clientCard.event.segment'),
                        blockedReason: guestModeReason,
                        component: InvisibleMultiselect,
                        props: {
                            disabled: guestMode,
                            nullable: true,
                            options: Segment.selectOptions('value', 'text'),
                            value: this.lead.segment,
                        },
                        events: { input: value => this.onLeadInput({ segment: value }) },
                    },
                    {
                        column: 2,
                        visible: this.showIntentionField && this.hasGeneralInfoField('referrer'),
                        label: this.$t('clientCard.referrer'),
                        blockedReason: guestModeReason,
                        component: InvisibleInput,
                        props: {
                            disabled: guestMode,
                            value: this.lead.referrer,
                        },
                        events: { input: value => this.onLeadInput({ referrer: value }) },
                    },
                    {
                        column: 2,
                        visible: this.showIntentionField && this.hasGeneralInfoField('search_term'),
                        label: this.$t('clientCard.searchTerm'),
                        blockedReason: guestModeReason,
                        component: InvisibleInput,
                        props: {
                            disabled: guestMode,
                            value: this.lead.search_term,
                        },
                        events: { input: value => this.onLeadInput({ search_term: value }) },
                    },
                    {
                        column: 2,
                        visible: this.showIntentionField && this.hasGeneralInfoField('keyword'),
                        label: this.$t('clientCard.keyword'),
                        blockedReason: guestModeReason,
                        component: InvisibleInput,
                        props: {
                            disabled: guestMode,
                            value: this.lead.keyword,
                        },
                        events: { input: value => this.onLeadInput({ keyword: value }) },
                    },
                    {
                        column: 2,
                        visible: this.lead.isService() && this.lead.account.hasGeneralInfoField('loyalty'),
                        label: this.$t('clientCard.loyalty'),
                        blockedReason: guestModeReason || invalidStatusReason,
                        component: ActivixCheckbox,
                        props: {
                            disabled: guestMode || invalidStatus,
                            value: this.lead.loyalty,
                        },
                        events: { input: value => this.onLeadInput({ loyalty: value }) },
                    },
                ];
            },

            averageSpending() {
                return this.lead.customer?.average_spending || this.lead.product_total || 0;
            },

            hasSoldServiceProducts() {
                return this.lead.products.some(product => product.pivot.sold && product.type === 'service');
            },

            leadFormAvailable() {
                if (
                    // Hide for phone Up leads except for Thibault Chevrolet because they whined
                    this.lead.isOfType(LeadType.PHONE) &&
                    [63, 181, 189, 191].includes(this.lead.account_id)
                ) {
                    return true;
                }

                return ![LeadType.EVENT, LeadType.PHONE].includes(this.lead.lead_type_id);
            },

            showIntentionField() {
                return [LeadType.EMAIL, LeadType.WEB_ORDER].includes(this.lead.lead_type_id);
            },

            showSegmentField() {
                return this.hasGeneralInfoField('segment') &&
                    (this.lead.isService() || this.lead.isOfType(LeadType.EVENT));
            },

            sourceProvider() {
                const provider = this.lead.account.sources
                    .map(source => {
                        return {
                            sourceId: source.id,
                            id: source.provider.id,
                            name: source.provider[`display_name_${this.$i18n.locale}`] || source.provider.name,
                        };
                    })
                    .find(source => source.sourceId === this.lead.source_id);

                if (provider) {
                    return provider.name;
                }

                if (!this.lead.source?.provider) {
                    return null;
                }

                return this.lead.source.provider[`display_name_${this.$i18n.locale}`] || this.lead.source.provider.name;
            },
        },

        methods: {
            hasGeneralInfoField(field) {
                return this.lead.account.hasGeneralInfoField(field);
            },

            onLeadInput(data) {
                this.updateLead({ data });
            },

            updateLead({ data, options = {} }) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
