<template>
    <flexible-input>
        <input-label slot="label" class="flex items-center" :blocked-reason="internalBlockedReason">
            {{ $t('clientCard.vehicles.transmission') }}
        </input-label>

        <invisible-multiselect
            :append-missing-value="true"
            :disabled="isDisabled"
            :nullable="true"
            :options="options"
            :searchable="true"
            :value="vehicle.transmission"
            @input="$emit('input', $event)"
        />
    </flexible-input>
</template>

<script>
    import FlexibleInput from '@/components/container/input/FlexibleInput.vue';
    import InputLabel from '@/components/presentational/input/InputLabel.vue';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect.vue';

    import LeadVehicle from '@/entities/LeadVehicle.js';
    import LeadVehicleTransmission from '@/entities/LeadVehicleTransmission.js';

    export default {
        components: {
            FlexibleInput,
            InputLabel,
            InvisibleMultiselect,
        },

        props: {
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            inventoryUnit: {
                type: Object,
                required: false,
            },
            stockManagementEnabled: {
                type: Boolean,
                required: true,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        computed: {
            internalBlockedReason() {
                if (this.blockedReason) {
                    return this.blockedReason;
                }

                if (this.vehicle.isExported) {
                    return this.$t('clientCard.blocked.vehicleExported');
                }

                if (this.isLinkedToInventory && !empty(this.inventoryUnit.transmission)) {
                    return this.$t('clientCard.blocked.vehicleStock');
                }

                return '';
            },

            isDisabled() {
                return this.disabled || this.vehicle.isExported || this.isLinkedToInventory && !empty(this.inventoryUnit.transmission);
            },

            isLinkedToInventory() {
                return !empty(this.inventoryUnit) && this.stockManagementEnabled;
            },

            options() {
                return LeadVehicleTransmission.selectOptions('value', 'text');
            },
        },
    };
</script>
