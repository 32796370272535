<template>
    <invisible-input
        :button-icon="canEmailLead ? $icons.email : ''"
        :disabled="saving"
        :readonly="readonly || masked"
        type="email"
        :value="value"
        @input="onChange"
        @button-click="$emit('email-icon-clicked')"
    >
        <template #before-input>
            <email-validation class="ml-2" :email="email" v-if="email.email" />
        </template>

        <template #after>
            <div
                class="absolute inset-0 flex items-center justify-center z-10"
                :class="{ 'rounded-md bg-gray-900/50': revealing }"
                @click="revealEmail"
                v-if="masked"
            >
                <div class="rounded-full bg-gray-800 px-2 text-lg text-white font-bold" v-if="revealing">
                    {{ revealTimer }}
                </div>
            </div>
        </template>
    </invisible-input>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import EmailValidation from '@/components/lead/EmailValidation';
    import InvisibleInput from '@/components/container/input/InvisibleInput';

    import LeadEmail from '@/entities/LeadEmail';
    import LeadEmailType from '@/entities/LeadEmailType';

    export default {
        components: { EmailValidation, InvisibleInput },

        props: {
            email: {
                type: LeadEmail,
                required: true,
                validator: email => {
                    return LeadEmailType.exists(email.type);
                },
            },
            readonly: {
                default: false,
                type: Boolean,
            },
        },

        data() {
            return {
                revealed: false,
                revealing: false,
                revealTimer: 3,
                saving: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            canEmailLead() {
                if (this.lgLayout || this.validationIsRisky) {
                    return false;
                }

                return !this.guest && this.email.id && this.email.valid;
            },

            masked() {
                if (!this.authUser.block_lead_info) {
                    return false;
                }

                if (!this.valid) {
                    return false;
                }

                return !this.revealed;
            },

            valid() {
                return this.email.valid;
            },

            validationIsRisky() {
                return this.email.validated_email.isEmpty() || this.email.validated_email.isNotValid();
            },

            value() {
                const email = this.email.email || '';

                if (this.masked) {
                    return this.maskEmail(email);
                }

                return email;
            },
        },

        methods: {
            onChange(value) {
                this.revealed = true;
                this.saving = true;

                this.$emit('update-email', {
                    emailData: {
                        email: value,
                    },
                    callback: () => {
                        this.saving = false;
                    },
                });
            },

            revealEmail() {
                this.revealing = true;

                const interval = setInterval(() => {
                    if (--this.revealTimer === 0) {
                        this.revealed = true;

                        clearInterval(interval);
                    }
                }, 1000);
            },
        },
    };
</script>
