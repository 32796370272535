<template>
    <div>
        <activix-tooltip :content="tooltip">
            <button @click="modals.navigationHistory.opened = true" v-if="value">
                <icon name="regular/list-bullets" class="link-blue | text-base" />
            </button>

            <invisible-input
                :disabled="true"
                v-else
            />
        </activix-tooltip>

        <navigation-history
            :navigation-history="value"
            :opened="modals.navigationHistory.opened"
            @close="modals.navigationHistory.opened = false"
        />
    </div>
</template>

<script>
    import InvisibleInput from '@/components/container/input/InvisibleInput.vue';
    import NavigationHistory from '@/components/modals/NavigationHistory';

    export default {
        components: { InvisibleInput, NavigationHistory },

        props: {
            value: {
                default: '',
                type: String,
            },
        },

        data: () => ({
            modals: {
                navigationHistory: {
                    opened: false,
                },
            },
        }),

        computed: {
            tooltip() {
                if (this.value) {
                    return this.$t('clientCard.navigationHistoryClickMsg');
                }

                return this.$t('clientCard.navigationHistoryEmpty');
            },
        },
    };
</script>
