<template>
    <follow-up-field
        follow-up="paperwork"
        :date="lead.paperwork_date"
        :disabled="disabled"
        :lead-id="lead.id"
        @input="updateLead({ paperwork_date: $event })"
    />
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import FollowUpField from '@/components/container/lead/input/FollowUpField.vue';

    import Lead from '@/entities/Lead.js';

    export default {
        components: {
            FollowUpField,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            disabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.$can('leads.paperwork_date:update')) {
                    return true;
                }

                return this.guest;
            },
        },

        methods: {
            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
