<template>
    <figure
        class="
            flex-shrink-0 p-1.5 rounded-r-lg rounded-tl-lg shadow-lg bg-white
            w-24 h-24
            md:w-32 md:h-32
            xl:w-24 xl:h-24
            2xl:w-32 2xl:h-32
        "
    >
        <div class="flex justify-center items-center w-full h-full p-0.5 border border-gray-300 rounded bg-gray-200">
            <icon
                class="text-gray-600 text-5xl 2xl:text-6xl"
                :class="{ 'flip-x': lead.isService() }"
                :name="iconName"
            />
        </div>
    </figure>
</template>

<script>
    import Lead from '@/entities/Lead';
    import LeadType from '@/entities/LeadType.js';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            iconName() {
                return LeadType.getIcon(this.lead.lead_type_id);
            },
        },
    };
</script>
