<template>
    <div class="flex divide-x divide-gray-200">
        <activix-tooltip :content="$t('modal.addTaskEventTitle')">
            <button
                class="relative flex flex-col justify-center items-center px-6 py-3 text-gray-700 | hover:bg-gray-300 last:rounded-tr-lg"
                @click="openCreateTaskEventModal"
            >
                <icon
                    class="text-xl"
                    name="regular/calendar-add"
                />
            </button>
        </activix-tooltip>
    </div>
</template>

<script>
    import Lead from '@/entities/Lead';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        methods: {
            openCreateTaskEventModal() {
                this.$eventBus.$emit('open-add-task-event', {
                    leadId: this.lead.id,
                });
            },
        },
    };
</script>
