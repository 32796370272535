<template>
    <vehicle-box
        :account="account"
        :can-add-vehicle="canAddVehicle"
        :highlighted-item-id="highlightedItemId"
        :pagination="pagination"
        :type="type"
        :vehicles="currentPageVehicles"
        @add-vehicle="onAddVehicle"
        @change-page="changePage"
        @delete-vehicle="deleteVehicle"
        @export-vehicle="exportVehicle"
        @update-lead="updateLead"
        @update-vehicle="updateVehicle"
        @update-vehicle-vin-value="updateVehicleVinValue"
    >
        <template #filters>
            <vehicle-filters
                :account="account"
                :vehicles="allVehiclesNormalized"
                ref="filters"
                @apply="onFiltersApply"
                @reset="onFiltersReset"
                @vehicles-filtered="onVehiclesFiltered"
            />
        </template>

        <template #item-content="{ vehicle }">
            <vehicle-list-item-content
                :account="account"
                :tabs-config="tabsConfig(vehicle)"
                :vehicle="vehicle"
                @update-vehicle="updateVehicle"
            />
        </template>

        <template #modals>
            <add-renewal-vehicle
                :lead="contextLead"
                :opened="modals.addRenewalVehicle.opened"
                @close="modals.addRenewalVehicle.opened = false"
            />

            <activix-confirm-modal
                :content="$t('clientCard.boxes.vehicles.filtersResetOnVehicleCreation')"
                :opened="modals.filtersResetOnVehicleCreation.opened"
                @approve="addVehicle"
                @close="modals.filtersResetOnVehicleCreation.opened = false"
            />
        </template>
    </vehicle-box>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useClientCardStore } from '@/store/modules/clientCard/store';
    import { useContextStore } from '@/store/modules/context/store';
    import { useGlobalStore } from '@/store/store';

    import { sort } from '@/utils';
    import Moment from '@/value-objects/Moment';
    import { range } from 'lodash-es';

    import AccidentedDamageCostInput from '@/components/container/input/vehicle/AccidentedDamageCostInput';
    import ActivixCheckbox from '@/components/elements/ActivixCheckbox.vue';
    import AddRenewalVehicle from '@/components/modals/AddRenewalVehicle';
    import BalanceInput from '@/components/container/input/vehicle/BalanceInput';
    import BudgetRangeInput from '@/components/container/input/vehicle/BudgetRangeInput';
    import ColorExteriorInput from '@/components/container/input/vehicle/ColorExteriorInput.vue';
    import ColorInteriorInput from '@/components/container/input/vehicle/ColorInteriorInput.vue';
    import EngineInput from '@/components/container/input/vehicle/EngineInput.vue';
    import FuelInput from '@/components/container/input/vehicle/FuelInput.vue';
    import InvisibleDateTimePicker from '@/components/container/input/InvisibleDateTimePicker';
    import InvisibleInput from '@/components/container/input/InvisibleInput.vue';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect.vue';
    import InvisibleTextarea from '@/components/container/input/InvisibleTextarea.vue';
    import LengthRangeInput from '@/components/container/input/vehicle/LengthRangeInput';
    import MakeInput from '@/components/container/input/vehicle/MakeInput.vue';
    import MakeWarrantyInput from '@/components/container/input/vehicle/MakeWarrantyInput';
    import MileageInput from '@/components/container/input/vehicle/MileageInput.vue';
    import ModelInput from '@/components/container/input/vehicle/ModelInput.vue';
    import StockNumberInput from '@/components/container/input/vehicle/StockNumberInput.vue';
    import StockStatusInput from '@/components/container/input/vehicle/StockStatusInput.vue';
    import TransmissionInput from '@/components/container/input/vehicle/TransmissionInput.vue';
    import TrimInput from '@/components/container/input/vehicle/TrimInput.vue';
    import ValueInput from '@/components/container/input/vehicle/ValueInput';
    import VehicleAppraisalWidget from '@/components/container/lead/vehicles/VehicleAppraisalWidget';
    import VehicleBox from '@/components/container/lead/boxes/VehicleBox';
    import VehicleContractWidget from '@/components/container/lead/vehicles/VehicleContractWidget';
    import VehicleFilters from '@/components/container/lead/vehicles/VehicleFilters';
    import VehicleFollowUpWidget from '@/components/container/lead/vehicles/VehicleFollowUpWidget';
    import VehicleListItemContent from '@/components/container/lead/vehicles/VehicleListItemContent';
    import VehiclePricingOverview from '@/components/container/lead/vehicles/VehiclePricingOverview';
    import VinInput from '@/components/container/input/vehicle/VinInput.vue';
    import WeightInput from '@/components/container/input/vehicle/WeightInput.vue';
    import YearInput from '@/components/container/input/vehicle/YearInput.vue';
    import YearRangeInput from '@/components/container/input/vehicle/YearRangeInput';

    import ClientCardSelection from '@/entities/ClientCardSection';
    import LeadType from '@/entities/LeadType';
    import LeadVehicle from '@/entities/LeadVehicle';
    import LeadVehicleCategory from '@/entities/LeadVehicleCategory';
    import LeadVehicleCondition from '@/entities/LeadVehicleCondition';
    import LeadVehicleFrequency from '@/entities/LeadVehicleFrequency';
    import LeadVehicleIntention from '@/entities/LeadVehicleIntention';
    import LeadVehicleModality from '@/entities/LeadVehicleModality';
    import LeadVehicleRvCategory from '@/entities/LeadVehicleRvCategory';
    import LeadVehicleTerm from '@/entities/LeadVehicleTerm';
    import LeadVehicleTradeType from '@/entities/LeadVehicleTradeType';
    import LeadVehicleType from '@/entities/LeadVehicleType';
    import ProgressState from '@/entities/ProgressState';
    import Supplier from '@/entities/Supplier';
    import VAutoResponse from '@/entities/VAutoResponse';

    export default {
        components: {
            AddRenewalVehicle,
            VehicleBox,
            VehicleFilters,
            VehicleListItemContent,
        },

        props: {
            type: {
                required: true,
                validator(value) {
                    return LeadVehicleType.keys.includes(value);
                },
            },
        },

        data: () => ({
            filteredVehicles: [],
            hasFiltersApplied: false,
            highlightedItemId: null,
            modals: {
                addRenewalVehicle: {
                    opened: false,
                },
                filtersResetOnVehicleCreation: {
                    opened: false,
                },
            },
            pagination: {
                currentPage: 1,
                orderDirection: 'asc',
                perPage: 5,
                totalItemsCount: null,
            },
        }),

        computed: {
            ...mapState(useClientCardStore, ['leadBanks']),
            ...mapState(useGlobalStore, ['authUser', 'guest']),
            ...mapState(useContextStore, ['contextLead']),

            account() {
                return this.contextLead.account;
            },

            allVehicles() {
                const vehicles = this.contextLead[this.vehicleTypeKey] || [];

                return vehicles
                    .sort(this.orderByCreation)
                    .map(vehicle => new LeadVehicle(vehicle));
            },

            allVehiclesNormalized() {
                if (this.isPolestarAccount) {
                    return this.allVehicles;
                }

                if (this.contextLead.isService()) {
                    const serviceVehicle = this.allVehicles.find(vehicle => vehicle.service) || this.allVehicles[0];

                    return serviceVehicle ? [serviceVehicle] : [];
                }

                if (this.isRenewalWithMoreThanOneExchangeVehicle) {
                    return [this.allVehicles[0]];
                }

                return this.allVehicles;
            },

            canAddVehicle() {
                return !this.guest &&
                    (
                        !this.contextLead.isService() ||
                        !this.contextLead.exchange_vehicles.length
                    );
            },

            currentPageVehicles() {
                return this.filteredVehicles.slice(this.paginationOffset, this.paginationOffset + this.pagination.perPage);
            },

            customFieldsSection() {
                return this.type === LeadVehicleType.WANTED ? ClientCardSelection.VEHICLE_WANTED : ClientCardSelection.VEHICLE_EXCHANGE;
            },

            hasCustomFields() {
                return this.account.getSectionCustomFields(this.customFieldsSection).length > 0;
            },

            hasRenewalVehiclesLimit() {
                return this.type === LeadVehicleType.EXCHANGE &&
                    this.contextLead.isOfType(LeadType.RENEWAL) &&
                    this.pagination.totalItemsCount === 1;
            },

            initialPageForCreation() {
                return this.pagination.orderDirection === 'desc' ? 1 : this.paginationTotalPagesCount;
            },

            isPolestarAccount() {
                return this.account.isPolestar();
            },

            isRenewalWithMoreThanOneExchangeVehicle() {
                return this.type === LeadVehicleType.EXCHANGE &&
                    this.contextLead.isOfType(LeadType.RENEWAL) &&
                    this.allVehicles.length > 1;
            },

            paginationOffset() {
                return (this.pagination.currentPage - 1) * this.pagination.perPage;
            },

            paginationTotalPagesCount() {
                return Math.ceil(this.pagination.totalItemsCount / this.pagination.perPage);
            },

            stockManagementEnabled() {
                if (!this.$feature.isEnabled('stock-management')) {
                    return false;
                }

                return this.account.hasInventorySupplier();
            },

            tradeReportEnabled() {
                return this.account.trade_report && (this.authUser.trade_report || this.authUser.isAdmin());
            },

            vehicleMechanicalCategories() {
                const categories = [
                    { value: 'mower', text: this.$t('clientCard.vehicles.mower') },
                    { value: 'blower', text: this.$t('clientCard.vehicles.blower') },
                    { value: 'water_pump', text: this.$t('clientCard.vehicles.water_pump') },
                    { value: 'outside', text: this.$t('clientCard.vehicles.outside') },
                    { value: 'generator', text: this.$t('clientCard.vehicles.generator') },
                    { value: 'border_size', text: this.$t('clientCard.vehicles.border_size') },
                    { value: 'motoculteur', text: this.$t('clientCard.vehicles.motoculteur') },
                ];

                return sort(categories, 'text');
            },

            vehicleTypeKey() {
                return `${this.type}_vehicles`;
            },

            yearsRange() {
                const fromYear = 1899; // fromYear is exclusive
                const toYear = Moment.now().year() + 3;
                return range(toYear, fromYear, -1)
                    .map(year => {
                        const yearString = year.toString();

                        return {
                            value: yearString,
                            text: yearString,
                        };
                    });
            },
        },

        watch: {
            filteredVehicles() {
                this.pagination.totalItemsCount = this.filteredVehicles.length;

                if (this.currentPageVehicles.length) {
                    return;
                }

                if (this.pagination.currentPage > this.paginationTotalPagesCount) {
                    this.changePage({ newPage: this.paginationTotalPagesCount });
                }
            },

            'pagination.orderDirection'() {
                this.$ls.set(`lead.vehicles.${this.type}.ordering`, this.pagination.orderDirection);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, [
                'addLeadVehicleAction',
                'appendNewError',
                'deleteLeadVehicleAction',
                'updateLeadAction',
                'updateLeadVehicle',
                'updateLeadVehicleAction',
                'updateVinVehicleValue',
            ]),

            addVehicle() {
                this.$wait.start(`adding.${this.vehicleTypeKey}`);

                this.$refs.filters.clearFilters();

                const data = {
                    type: this.type,
                };

                if (this.type === LeadVehicleType.WANTED && this.pagination.totalItemsCount === 0) {
                    if (this.contextLead.progress_state === ProgressState.CASH_DEAL) {
                        data.modality = 'cash';
                    }

                    if (this.contextLead.sale_date) {
                        data.sold = true;
                    }
                }

                this.addLeadVehicleAction(data, this.contextLead.id)
                    .then(async response => {
                        await this.changePage({ newPage: this.initialPageForCreation });

                        this.highlightedItemId = response.data.data.id;
                    })
                    .finally(() => {
                        this.$wait.end(`adding.${this.vehicleTypeKey}`);
                    });
            },

            async changePage({ newPage, newOrderDirection = null }) {
                this.highlightedItemId = null;

                this.$wait.start(`fetching.${this.vehicleTypeKey}`);

                await this.$forceNextTick();

                this.pagination.currentPage = newPage;

                if (newOrderDirection) {
                    this.pagination.orderDirection = newOrderDirection;
                }

                this.$wait.end(`fetching.${this.vehicleTypeKey}`);
            },

            deleteVehicle(vehicleId) {
                this.deleteLeadVehicleAction(vehicleId);
            },

            disabledBySaleVerified(vehicle) {
                return !!this.contextLead.disabledBySaleVerified(this.authUser, vehicle);
            },

            disabledTooltip(vehicle) {
                if (this.disabledBySaleVerified(vehicle)) {
                    return this.$t('clientCard.verifiedSaleUneditable');
                }

                return '';
            },

            async exportVehicle({ supplierId, vehicle }) {
                this.$wait.start(`vehicles[${vehicle.id}].exporting`);

                switch (supplierId) {
                    case Supplier.AUTOPROPULSION:
                        await this.sendToAutopropulsion(vehicle);
                        break;

                    case Supplier.QUICKTRADE:
                        await this.sendToQuickTrade(vehicle);
                        break;

                    case Supplier.VAUTO:
                        await this.sendToVauto(vehicle);
                        break;
                }

                this.$wait.end(`vehicles[${vehicle.id}].exporting`);
            },

            fieldIsVisible(field) {
                return this.account.hasVehicleField(field, this.type, false);
            },

            initOrderDirection() {
                const orderDirectionPreference = this.$ls.get(`vehicles.${this.type}.ordering`);

                if (orderDirectionPreference) {
                    this.pagination.orderDirection = orderDirectionPreference;
                } else {
                    this.pagination.orderDirection = this.isPolestarAccount ? 'desc' : 'asc';
                }
            },

            onAddVehicle() {
                if (this.hasRenewalVehiclesLimit) {
                    this.modals.addRenewalVehicle.opened = true;
                } else if (this.hasFiltersApplied) {
                    this.modals.filtersResetOnVehicleCreation.opened = true;
                } else {
                    this.addVehicle();
                }
            },

            onFiltersApply() {
                this.changePage({ newPage: 1 });
            },

            onFiltersReset() {
                this.changePage({ newPage: 1 });
            },

            onVehiclesFiltered({ filteredVehicles, hasFiltersApplied }) {
                const vehicles = [...filteredVehicles];
                const soldVehicleIndex = filteredVehicles.findIndex(vehicle => vehicle.sold);

                if (soldVehicleIndex !== -1) {
                    const [soldVehicle] = vehicles.splice(soldVehicleIndex, 1);

                    vehicles.splice(0, 0, soldVehicle);
                }

                this.filteredVehicles = vehicles;
                this.hasFiltersApplied = hasFiltersApplied;
            },

            orderByCreation(vehicleA, vehicleB) {
                if (this.pagination.orderDirection === 'desc') {
                    return vehicleB.created_at.localeCompare(vehicleA.created_at);
                }

                return vehicleA.created_at.localeCompare(vehicleB.created_at);
            },

            async sendToAutopropulsion(vehicle) {
                try {
                    const response = await this.$axios.post(`v1/lead-vehicles/${vehicle.id}/autopropulsion`);

                    if (response.status === 200) {
                        this.$notify.success(this.$t('autopropulsion.success'));

                        this.updateLeadVehicle({
                            ...vehicle,
                            suppliers: response.data.data.suppliers,
                        });
                    }
                } catch (e) {
                    switch (e.response?.status) {
                        case 403:
                            this.$notify.error(this.$t('autopropulsion.warning.error403'));
                            break;

                        case 404:
                            this.$notify.error(this.$t('autopropulsion.warning.error404'));
                            break;

                        case 422:
                            if (e.response.message?.includes('All vehicles of this lead have already received')) {
                                this.$notify.error(this.$t('autopropulsion.warning.vehicleAlreadyReceived'));

                                break;
                            }

                            this.$notify.error(this.$t('autopropulsion.warning.error500'));
                            break;

                        default:
                            this.$notify.error(this.$t('autopropulsion.warning.error500'));
                            break;
                    }

                    this.appendNewError({
                        code: '0700',
                        display: false,
                        error: e,
                        payload: {
                            leadId: this.contextLead.id,
                        },
                    });
                }
            },

            async sendToQuickTrade(vehicle) {
                try {
                    const response = await this.$axios.post(`v1/lead-vehicles/${vehicle.id}/quicktrade`);

                    if (response.status === 200) {
                        this.$notify.success(this.$t('quicktrade.success'));

                        this.updateLeadVehicle({
                            ...vehicle,
                            suppliers: response.data.data.suppliers,
                        });
                    }
                } catch (e) {
                    switch (e.response?.status) {
                        case 403:
                            this.$notify.error(this.$t('quicktrade.warning.error403'));
                            break;

                        case 404:
                            this.$notify.error(this.$t('quicktrade.warning.error404'));
                            break;

                        case 422:
                            this.$notify.error(this.$t('quicktrade.warning.error422'));
                            break;

                        default:
                            this.$notify.error(this.$t('quicktrade.warning.error500'));
                            break;
                    }

                    this.appendNewError({
                        code: '0700',
                        display: false,
                        error: e,
                        payload: {
                            leadId: this.contextLead.id,
                        },
                    });
                }
            },

            async sendToVauto(vehicle) {
                try {
                    const response = await this.$axios.post(`v1/lead-vehicles/${vehicle.id}/vauto`, {
                        accountId: this.account.id,
                    });

                    switch (response.data.data.salesPersonResult) {
                        case VAutoResponse.SUCCESS_ADVISOR:
                            this.$notify.success(
                                this.$t('vauto.success.assignedAdvisor', [response.data.data.userName]),
                            );
                            break;

                        case VAutoResponse.SUCCESS_FALLBACK:
                            this.$notify.success(this.$t('vauto.success.assignedDirector'));
                            break;

                        case VAutoResponse.WARNING_NO_USERNAME:
                            this.$notify.warning(this.$t('vauto.warning.assignedFallbackUsernameNotSet'));
                            break;

                        case VAutoResponse.WARNING_USERNAME_ERROR:
                            this.$notify.warning(this.$t('vauto.warning.assignedFallbackVerifyUsername'));
                            break;

                        case VAutoResponse.WARNING_FALLBACK_NO_USERNAME:
                            this.$notify.warning(this.$t('vauto.warning.assignedDirectorUsernameNotSet'));
                            break;

                        case VAutoResponse.WARNING_FALLBACK_USERNAME_ERROR:
                            this.$notify.warning(this.$t('vauto.warning.assignedDirectorVerifyUsername'));
                            break;

                        case VAutoResponse.WARNING_INVALID_FALLBACK:
                            this.$notify.warning(this.$t('vauto.warning.assignedDirectorLastFallback'));
                            break;
                    }

                    this.updateLeadVehicle(response.data.data.result.vehicle);
                } catch (error) {
                    if (error.response?.data?.errors) {
                        switch (error.response.data.errors.salesPersonResult) {
                            case VAutoResponse.ERROR_USER_NOT_ACTIVE:
                                this.$notify.error(this.$t('vauto.error.userNotActive', [error.response.data.errors.userName]));
                                break;
                        }
                    } else {
                        this.appendNewError({
                            code: '0700',
                            display: true,
                            error,
                            payload: {
                                leadId: this.contextLead.id,
                            },
                        });
                    }
                }
            },

            showWarranty(vehicle) {
                return (
                    this.fieldIsVisible('warranty') &&
                    (vehicle.isExchange || this.contextLead.isOfType(LeadType.RENEWAL))
                );
            },

            tradeTypeTooltip(vehicle) {
                return empty(vehicle.value)
                    ? this.$t('clientCard.vehicles.tradeTypeDisabledTooltip')
                    : this.$t('clientCard.vehicles.tradeTypeTooltip');
            },

            updateLead({ data, options = {} }) {
                this.updateLeadAction(this.contextLead.id, data, options);
            },

            updateVehicle({ data, id, options = {} }) {
                this.updateLeadVehicleAction(data, id, this.contextLead.id, options);
            },

            updateVehicleData(id, data) {
                this.updateVehicle({ data, id });
            },

            updateVehicleVinValue({ uvc, vehicleId }) {
                this.updateVinVehicleValue({ uvc, vehicleId });
            },

            vehicleBank(vehicle) {
                if (vehicle.imported_bank && !vehicle.bank_id) {
                    return 'text-form';
                }
                return vehicle.bank_id;
            },

            vehicleBankOptions(vehicle) {
                const banks = [];

                for (const leadbank of this.leadBanks) {
                    banks.push(leadbank);
                }

                if (!empty(vehicle.imported_bank)) {
                    banks.push({
                        value: 'text-form',
                        text: `${vehicle.imported_bank}`,
                    });
                }

                return [{ value: '', text: '...' }].concat(banks);
            },

            tabsConfig(vehicle) {
                const saleVerified = this.disabledBySaleVerified(vehicle);
                const saleVerifiedReason = saleVerified ? this.$t('clientCard.verifiedSaleUneditable') : '';
                const guestMode = this.guest;
                const guestModeReason = guestMode ? this.$t('clientCard.guestMode') : '';
                const financialDataLocked = this.contextLead.financialDataFreezed;
                const financialDataLockedReason = financialDataLocked ? this.$t('clientCard.financialDataLocked') : '';

                const tabsConfig = {
                    info: {
                        label: this.$t('clientCard.boxes.vehicles.tabs.info'),
                        fieldsConfig: [
                            {
                                columnCount: 2,
                                fields: [
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('category'),
                                        label: this.$t('clientCard.vehicles.category'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleMultiselect,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            nullable: true,
                                            options: LeadVehicleCategory.selectOptions('value', 'text'),
                                            value: vehicle.category,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { category: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('category_rv'),
                                        label: this.$t('clientCard.vehicles.categoryRV'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleMultiselect,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            nullable: true,
                                            options: LeadVehicleRvCategory.selectOptions('value', 'text'),
                                            value: vehicle.category_rv,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { category_rv: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('category') && vehicle.category === LeadVehicleCategory.MECHANICAL,
                                        label: this.$t('clientCard.vehicles.mechanical'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleMultiselect,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            nullable: true,
                                            options: this.vehicleMechanicalCategories,
                                            value: vehicle.mechanical,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { mechanical: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        component: MakeInput,
                                        rawComponent: true,
                                        props: {
                                            account: this.account,
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            stockManagementEnabled: this.stockManagementEnabled,
                                            vehicle,
                                        },
                                        events: {
                                            'update-vehicle': data => this.updateVehicleData(vehicle.id, data),
                                        },
                                    },
                                    {
                                        column: 1,
                                        component: ModelInput,
                                        rawComponent: true,
                                        props: {
                                            account: this.account,
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            stockManagementEnabled: this.stockManagementEnabled,
                                            vehicle,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { model: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        component: YearInput,
                                        rawComponent: true,
                                        props: {
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            stockManagementEnabled: this.stockManagementEnabled,
                                            vehicle,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { year: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        component: TrimInput,
                                        rawComponent: true,
                                        props: {
                                            account: this.account,
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            inventoryUnit: vehicle.inventory_unit,
                                            stockManagementEnabled: this.stockManagementEnabled,
                                            vehicle,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { trim: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('transmission'),
                                        component: TransmissionInput,
                                        rawComponent: true,
                                        props: {
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            inventoryUnit: vehicle.inventory_unit,
                                            stockManagementEnabled: this.stockManagementEnabled,
                                            vehicle,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { transmission: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: vehicle.isExchange && vehicle.lead.isService(),
                                        label: this.$t('accounts.edit.licensePlate'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            maxlength: 9,
                                            value: vehicle.license_plate,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { license_plate: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: vehicle.isExchange && vehicle.lead.isService(),
                                        label: this.$t('accounts.edit.recall'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            value: vehicle.recall,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { recall: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('engine'),
                                        component: EngineInput,
                                        rawComponent: true,
                                        props: {
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            inventoryUnit: vehicle.inventory_unit,
                                            stockManagementEnabled: this.stockManagementEnabled,
                                            vehicle,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { engine: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('bodyType'),
                                        label: this.$t('clientCard.vehicles.bodyType'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            value: vehicle.body_type,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { body_type: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('certified'),
                                        label: this.$t('clientCard.certified'),
                                        tooltip: (guestMode || saleVerified) ? '' : this.$t('clientCard.certifiedTooltip'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: ActivixCheckbox,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            size: 'lg',
                                            value: vehicle.certified,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { certified: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('vin'),
                                        component: VinInput,
                                        rawComponent: true,
                                        props: {
                                            account: this.account,
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            stockManagementEnabled: this.stockManagementEnabled,
                                            vehicle,
                                        },
                                        events: {
                                            'update-vehicle': data => this.updateVehicleData(vehicle.id, data),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('stock'),
                                        component: StockStatusInput,
                                        rawComponent: true,
                                        props: {
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            inventoryUnit: vehicle.inventory_unit,
                                            stockManagementEnabled: this.stockManagementEnabled,
                                            vehicle,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { stock_state: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('stock'),
                                        component: StockNumberInput,
                                        rawComponent: true,
                                        props: {
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            stockManagementEnabled: this.stockManagementEnabled,
                                            vehicle,
                                        },
                                        events: {
                                            'update-vehicle': data => this.updateVehicleData(vehicle.id, data),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('color_exterior'),
                                        component: ColorExteriorInput,
                                        rawComponent: true,
                                        props: {
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            inventoryUnit: vehicle.inventory_unit,
                                            stockManagementEnabled: this.stockManagementEnabled,
                                            vehicle,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { color_exterior: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('color_interior'),
                                        component: ColorInteriorInput,
                                        rawComponent: true,
                                        props: {
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            inventoryUnit: vehicle.inventory_unit,
                                            stockManagementEnabled: this.stockManagementEnabled,
                                            vehicle,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { color_interior: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('mileage'),
                                        component: MileageInput,
                                        rawComponent: true,
                                        props: {
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            inventoryUnit: vehicle.inventory_unit,
                                            stockManagementEnabled: this.stockManagementEnabled,
                                            vehicle,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { mileage: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('fuel'),
                                        component: FuelInput,
                                        rawComponent: true,
                                        props: {
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            inventoryUnit: vehicle.inventory_unit,
                                            stockManagementEnabled: this.stockManagementEnabled,
                                            vehicle,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { fuel: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('drivingWheels'),
                                        label: this.$t('clientCard.vehicles.drivingWheels'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            value: vehicle.driving_wheels,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { driving_wheels: value }),
                                        },
                                    },
                                ],
                            },
                            {
                                columnCount: 2,
                                fields: [
                                    {
                                        visible: this.fieldIsVisible('year'),
                                        component: YearRangeInput,
                                        rawComponent: true,
                                        props: {
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            vehicle,
                                        },
                                        events: {
                                            'update-vehicle': data => this.updateVehicleData(vehicle.id, data),
                                        },
                                    },
                                    {
                                        visible: this.fieldIsVisible('budget'),
                                        component: BudgetRangeInput,
                                        rawComponent: true,
                                        props: {
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            vehicle,
                                        },
                                        events: {
                                            'update-vehicle': data => this.updateVehicleData(vehicle.id, data),
                                        },
                                    },
                                    {
                                        visible: this.fieldIsVisible('length'),
                                        component: LengthRangeInput,
                                        rawComponent: true,
                                        props: {
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            vehicle,
                                        },
                                        events: {
                                            'update-vehicle': data => this.updateVehicleData(vehicle.id, data),
                                        },
                                    },
                                    {
                                        visible: this.fieldIsVisible('sleeping'),
                                        label: this.$t('clientCard.vehicles.sleeping'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            mask: 'integer',
                                            value: vehicle.sleeping,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { sleeping: value }),
                                        },
                                    },
                                    {
                                        visible: this.fieldIsVisible('weight'),
                                        label: this.$t('clientCard.vehicles.weight'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: WeightInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            vehicle,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { weight: value }),
                                        },
                                    },
                                ],
                            },
                            {
                                columnCount: 2,
                                fields: [
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('comment'),
                                        label: this.$t('accounts.edit.comment'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleTextarea,
                                        fixedHeight: false,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            value: vehicle.comment,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { comment: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        label: this.$t('clientCard.vehicles.options'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleTextarea,
                                        fixedHeight: false,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            value: vehicle.option,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { option: value }),
                                        },
                                    },
                                ],
                            },
                        ],
                    },

                    price: {
                        label: this.$t('clientCard.boxes.vehicles.tabs.price'),
                        fieldsConfig: [
                            {
                                columnCount: 2,
                                fields: [
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('payment'),
                                        label: this.$t('clientCard.vehicles.payment'),
                                        blockedReason: guestModeReason || saleVerifiedReason || financialDataLockedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified || financialDataLocked,
                                            mask: 'money',
                                            value: vehicle.payment,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { payment: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('payment_with_tax'),
                                        label: this.$t('clientCard.vehicles.paymentWithTax'),
                                        blockedReason: guestModeReason || saleVerifiedReason || financialDataLockedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified || financialDataLocked,
                                            mask: 'money',
                                            value: vehicle.payment_with_tax,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { payment_with_tax: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('frequency'),
                                        label: this.$t('clientCard.vehicles.frequency'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleMultiselect,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            nullable: true,
                                            options: LeadVehicleFrequency.selectOptions('value', 'text'),
                                            value: vehicle.frequency,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { frequency: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('term'),
                                        label: this.$t('clientCard.vehicles.term'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleMultiselect,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            nullable: true,
                                            options: LeadVehicleTerm.selectOptions('value', 'text'),
                                            value: vehicle.term?.toString(),
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { term: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('rate'),
                                        label: this.$t('clientCard.vehicles.rate'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            mask: 'percent',
                                            value: vehicle.rate,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { rate: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('initial_cash'),
                                        label: this.$t('clientCard.vehicles.initialCash'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            mask: 'money',
                                            value: vehicle.initial_cash,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { initial_cash: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.account.auto_renewal || this.fieldIsVisible('modality'),
                                        label: this.$t('clientCard.vehicles.modality'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleMultiselect,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            nullable: true,
                                            options: LeadVehicleModality.selectOptions('value', 'text'),
                                            value: vehicle.modality,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { modality: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('residual'),
                                        label: this.$t('clientCard.vehicles.residual'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified || financialDataLocked,
                                            mask: 'money',
                                            value: vehicle.residual,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { residual: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: vehicle.isWanted && this.fieldIsVisible('tire'),
                                        label: this.$t('clientCard.vehicles.tires'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: ActivixCheckbox,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            size: 'lg',
                                            value: vehicle.tire,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { tire: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('offer_number'),
                                        label: this.$t('clientCard.vehicles.offer_number'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            value: vehicle.offer,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { offer: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: vehicle.isWanted && this.fieldIsVisible('suffix'),
                                        label: this.$t('clientCard.vehicles.suffix'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            value: vehicle.suffix,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { suffix: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: vehicle.isWanted && this.fieldIsVisible('order_number'),
                                        label: this.$t('clientCard.vehicles.order_number'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            value: vehicle.order_number,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { order_number: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: vehicle.isWanted,
                                        component: VehiclePricingOverview,
                                        rawComponent: true,
                                        props: {
                                            account: this.account,
                                            blockedReason: guestModeReason || saleVerifiedReason || financialDataLockedReason,
                                            disabled: guestMode || saleVerified || financialDataLocked,
                                            stockManagementEnabled: this.stockManagementEnabled,
                                            vehicle,
                                        },
                                        events: {
                                            'update-vehicle': data => this.updateVehicleData(vehicle.id, data),
                                        },
                                    },
                                ],
                            },
                        ],
                    },

                    contract: {
                        label: this.$t('clientCard.boxes.vehicles.tabs.contract'),
                        fieldsConfig: [
                            {
                                columnCount: 1,
                                fields: [
                                    {
                                        column: 1,
                                        visible: vehicle.isWanted && (this.account.auto_renewal || this.fieldIsVisible('end_contract_date')),
                                        component: VehicleContractWidget,
                                        rawComponent: true,
                                        props: {
                                            vehicle,
                                        },
                                        events: {
                                            'update-vehicle': data => this.updateVehicleData(vehicle.id, data),
                                        },
                                    },
                                ],
                            },
                            {
                                columnCount: 2,
                                fields: [
                                    {
                                        visible: vehicle.isExchange && this.fieldIsVisible('balance'),
                                        column: 1,
                                        component: BalanceInput,
                                        rawComponent: true,
                                        props: {
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            vehicle,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { balance: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('allowed_mileage'),
                                        label: this.$t('clientCard.vehicles.allowed_mileage'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            mask: 'km',
                                            value: vehicle.allowed_mileage,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { allowed_mileage: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: vehicle.isExchange && this.fieldIsVisible('institution'),
                                        label: this.$t('clientCard.institution'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleMultiselect,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            options: this.vehicleBankOptions(vehicle),
                                            value: this.vehicleBank(vehicle),
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { bank_id: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: vehicle.isExchange && this.fieldIsVisible('intention'),
                                        label: this.$t('clientCard.vehicles.intention'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleMultiselect,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            options: LeadVehicleIntention.selectOptions('value', 'text'),
                                            value: vehicle.intention,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { intention: value }),
                                        },
                                    },

                                    {
                                        column: 1,
                                        visible: vehicle.isExchange && this.fieldIsVisible('sold_by'),
                                        label: this.$t('accounts.edit.soldBy'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            value: vehicle.sold_by,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { sold_by: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: vehicle.isExchange && this.fieldIsVisible('sold_date'),
                                        label: this.$t('accounts.edit.soldDate'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            value: vehicle.sold_date,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { sold_date: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('security_deposit'),
                                        label: this.$t('clientCard.vehicles.securityDeposit'),
                                        blockedReason: guestModeReason || saleVerifiedReason || financialDataLockedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified || financialDataLocked,
                                            mask: 'money',
                                            value: vehicle.security_deposit,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { security_deposit: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('in_service_date'),
                                        label: this.$t('clientCard.vehicles.in_service_date'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleDateTimePicker,
                                        props: {
                                            dateOnly: true,
                                            disabled: guestMode || saleVerified,
                                            enableEmpty: true,
                                            startView: 4,
                                            value: vehicle.in_service_date,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { in_service_date: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.account.auto_renewal || this.fieldIsVisible('end_contract_date'),
                                        label: this.$t('clientCard.vehicles.contractEnd'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleDateTimePicker,
                                        props: {
                                            dateOnly: true,
                                            disabled: guestMode || saleVerified,
                                            enableEmpty: true,
                                            startView: 4,
                                            value: vehicle.end_contract_date,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { end_contract_date: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.showWarranty(vehicle),
                                        label: this.$t('accounts.edit.warranty'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            value: vehicle.warranty,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { warranty: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.showWarranty(vehicle),
                                        label: this.$t('accounts.edit.extendedWarranty'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            value: vehicle.extended_warranty,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { extended_warranty: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        label: this.$t('clientCard.vehicles.warranty_type'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: MakeWarrantyInput,
                                        props: {
                                            account: this.account,
                                            disabled: guestMode || saleVerified,
                                            vehicle,
                                        },
                                        events: {
                                            'update-vehicle': data => this.updateVehicleData(vehicle.id, data),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('end_warranty_date'),
                                        label: this.$t('accounts.edit.endWarrantyDate'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleDateTimePicker,
                                        props: {
                                            dateOnly: true,
                                            disabled: guestMode || saleVerified,
                                            enableEmpty: true,
                                            startView: 4,
                                            value: vehicle.end_warranty_date,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { end_warranty_date: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('end_warranty_mileage'),
                                        label: this.$t('accounts.edit.endWarrantyMileage'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            mask: 'km',
                                            value: vehicle.end_warranty_mileage,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { end_warranty_mileage: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('end_extended_warranty_date'),
                                        label: this.$t('clientCard.boxes.vehicles.endExtendedWarrantyDate'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleDateTimePicker,
                                        props: {
                                            dateOnly: true,
                                            disabled: guestMode || saleVerified,
                                            enableEmpty: true,
                                            startView: 4,
                                            value: vehicle.end_extended_warranty_date,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { end_extended_warranty_date: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('end_extended_warranty_mileage'),
                                        label: this.$t('clientCard.boxes.vehicles.endExtendedWarrantyMileage'),
                                        blockedReason: guestModeReason || saleVerifiedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || saleVerified,
                                            mask: 'km',
                                            value: vehicle.end_extended_warranty_mileage,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { end_extended_warranty_mileage: value }),
                                        },
                                    },
                                ],
                            },
                            {
                                columnCount: 1,
                                fields: [
                                    {
                                        column: 1,
                                        visible: vehicle.isExchange && (this.contextLead.isService() || this.account.equity_alert),
                                        component: VehicleFollowUpWidget,
                                        rawComponent: true,
                                        props: {
                                            blockedReason: guestModeReason || saleVerifiedReason,
                                            disabled: guestMode || saleVerified,
                                            lead: this.contextLead,
                                        },
                                        events: {
                                            'update-lead': data => this.updateLead(data),
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                };

                if (vehicle.isExchange) {
                    tabsConfig.appraisal = {
                        label: this.$t('clientCard.boxes.vehicles.tabs.appraisal'),
                        fieldsConfig: [
                            {
                                columnCount: 1,
                                fields: [
                                    {
                                        column: 1,
                                        visible: this.contextLead.isService() || this.account.equity_alert,
                                        component: VehicleAppraisalWidget,
                                        rawComponent: true,
                                        props: {
                                            account: this.account,
                                            vehicle,
                                        },
                                        events: {
                                            'export-vehicle': data => this.exportVehicle(data),
                                        },
                                    },
                                ],
                            },
                            {
                                columnCount: 2,
                                fields: [
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('link'),
                                        label: this.$t('clientCard.vehicles.link'),
                                        blockedReason: guestModeReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode,
                                            mask: 'money',
                                            value: vehicle.link,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { link: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('requested'),
                                        label: this.$t('clientCard.vehicles.requested'),
                                        blockedReason: guestModeReason || financialDataLockedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || financialDataLocked,
                                            mask: 'money',
                                            value: vehicle.requested,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { requested: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('value'),
                                        component: ValueInput,
                                        rawComponent: true,
                                        props: {
                                            account: this.account,
                                            blockedReason: guestModeReason || financialDataLockedReason,
                                            disabled: guestMode || financialDataLocked,
                                            vehicle,
                                        },
                                        events: {
                                            'update-vehicle': this.updateVehicle,
                                            'update-vehicle-vin-value': data => this.$emit('update-vehicle-vin-value', data),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.tradeReportEnabled,
                                        label: this.$t('clientCard.vehicles.actual_value'),
                                        labelTooltip: this.$t('clientCard.vehicles.actualValueTooltip'),
                                        blockedReason: guestModeReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode,
                                            mask: 'money',
                                            value: vehicle.actual_value,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { actual_value: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.tradeReportEnabled,
                                        label: this.$t('clientCard.vehicles.trade_type'),
                                        labelTooltip: !empty(vehicle.value) ? this.$t('clientCard.vehicles.tradeTypeTooltip') : '',
                                        blockedReason: guestModeReason || (empty(vehicle.value) ? this.$t('clientCard.vehicles.tradeTypeDisabledTooltip') : ''),
                                        component: InvisibleMultiselect,
                                        props: {
                                            disabled: guestMode || empty(vehicle.value),
                                            options: LeadVehicleTradeType.selectOptions('value', 'text'),
                                            value: vehicle.trade_type,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { trade_type: value }),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.tradeReportEnabled,
                                        label: this.$t('clientCard.vehicles.trade_notes'),
                                        component: InvisibleTextarea,
                                        fixedHeight: false,
                                        props: {
                                            disabled: true,
                                            value: vehicle.trade_notes,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { trade_notes: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('price'),
                                        label: this.$t('clientCard.vehicles.price'),
                                        blockedReason: guestModeReason || financialDataLockedReason,
                                        component: InvisibleInput,
                                        props: {
                                            disabled: guestMode || financialDataLocked,
                                            mask: 'money',
                                            value: vehicle.price,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { price: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.contextLead.isService(),
                                        label: this.$t('accounts.edit.purchaseDate'),
                                        blockedReason: guestModeReason,
                                        component: InvisibleDateTimePicker,
                                        props: {
                                            disabled: guestMode,
                                            startView: 4,
                                            value: vehicle.purchase_date,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { purchase_date: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('condition'),
                                        label: this.$t('clientCard.vehicles.condition'),
                                        blockedReason: guestModeReason,
                                        component: InvisibleMultiselect,
                                        props: {
                                            disabled: guestMode,
                                            options: LeadVehicleCondition.selectOptions('value', 'text'),
                                            value: vehicle.condition,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { condition: value }),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.tradeReportEnabled && this.fieldIsVisible('accidented_damage_cost'),
                                        component: AccidentedDamageCostInput,
                                        rawComponent: true,
                                        props: {
                                            vehicle,
                                        },
                                        events: {
                                            input: value => this.updateVehicleData(vehicle.id, { accidented_damage_cost: value }),
                                        },
                                    },
                                ],
                            },
                        ],
                    };
                }

                if (this.hasCustomFields) {
                    tabsConfig.custom = {
                        label: this.$t('clientCard.boxes.vehicles.tabs.custom'),
                        blockedReason: guestModeReason || saleVerifiedReason,
                        disabled: guestMode || saleVerified,
                    };
                }

                return tabsConfig;
            },
        },

        mounted() {
            this.initOrderDirection();
        },
    };
</script>
