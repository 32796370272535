<template>
    <invisible-multiselect
        :disabled="disabled"
        :nullable="true"
        :options="provinces"
        :value="province"
        ref="input"
        @input="updateLead({ province: $event })"
    />
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect';

    export default {
        components: { InvisibleMultiselect },

        props: {
            country: {
                required: true,
                validator: value => typeof value === 'string' || value === null,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            province: {
                required: true,
                validator: value => typeof value === 'string' || value === null,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['configs']),

            provinces() {
                return this.configs.provinces[this.country].map(province => {
                    return {
                        value: province,
                        text: this.$t(`clientCard.provinces.${province}`),
                    };
                }).sort((a, b) => a.text.localeCompare(b.text));
            },
        },

        methods: {
            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
