<template>
    <input-list :column-count="3" :fields="fields" />
</template>

<script>
    import InputList from '@/components/container/input/InputList';
    import ServiceMultiselectText from '@/components/container/lead/ServiceMultiselectText.vue';

    import Lead from '@/entities/Lead.js';
    import { map, filter, uniq, union } from 'lodash-es';

    export default {
        components: { InputList },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            fields() {
                return [
                    {
                        column: 1,
                        component: ServiceMultiselectText,
                        fixedHeight: false,
                        props: {
                            label: this.$t('clientCard.services.intention'),
                            'multiselect-custom-labels': 'service.categories',
                            'multiselect-multiple': true,
                            'multiselect-options': this.intentions,
                            'multiselect-value': this.lead.service_process.intention.value,
                            'textinput-value': this.lead.service_process.intention.comment,
                        },
                        events: { 'fields-input': data => this.updateServiceProcess('intention', data) },
                    },
                    {
                        column: 2,
                        component: ServiceMultiselectText,
                        fixedHeight: false,
                        props: {
                            label: this.$t('clientCard.services.walkAround'),
                            'multiselect-custom-labels': 'clientCard.services.walkArounds',
                            'multiselect-multiple': true,
                            'multiselect-options': this.walkArounds,
                            'multiselect-value': this.lead.service_process.walk_around.value,
                            'textinput-value': this.lead.service_process.walk_around.comment,
                        },
                        events: { 'fields-input': data => this.updateServiceProcess('walk_around', data) },
                    },
                    {
                        column: 3,
                        component: ServiceMultiselectText,
                        fixedHeight: false,
                        props: {
                            label: this.$t('clientCard.services.transport'),
                            'multiselect-custom-labels': 'clientCard.services.transports',
                            'multiselect-options': this.transports,
                            'multiselect-value': this.lead.service_process.transport.value,
                            'textinput-value': this.lead.service_process.transport.comment,
                        },
                        events: { 'fields-input': data => this.updateServiceProcess('transport', data) },
                    },
                ];
            },

            intentions() {
                return union(uniq(map(
                    filter(this.lead.account.products, p => p.type === 'service'),
                    p => p.category,
                )), ['problem']).sort();
            },

            transports() {
                return ['drop', 'wait', 'limousine', 'courtesy', 'renting', 'other'];
            },

            walkArounds() {
                return ['a1', 'scratch', 'brokenGlass', 'bump', 'cleaning', 'other'];
            },
        },

        methods: {
            updateServiceProcess(field, data) {
                const payload = {
                    data: {
                        service_process: {
                            ...this.lead.service_process,
                            [field]: {
                                ...this.lead.service_process[field],
                                ...data,
                            },
                        },
                    },
                };

                this.$emit('update-lead', payload);
            },
        },
    };
</script>
