<template>
    <activix-modal
        size="sm"
        :opened="opened"
        :portal="portal"
        :closable="false"
        :cancelable="true"
        cancel-class-name="mt-2 z-10 | xl:mt-4"
        @canceled="onCancel"
        @closed="onClosed"
    >
        <template slot="body">
            <sweet-alert-icon class="mx-auto" type="warning" />
            <div
                class="text-2xl font-semibold text-center text-grey-650"
                v-text="`${$t('clientCard.association')} / ${$t('clientCard.fusion')}`"
            />
            <div class="text-lg text-center" v-text="$t('clientCard.selectAction')" />
        </template>

        <template slot="footer">
            <div class="flex">
                <activix-button class="flex-1 mr-2" @click="setLeadMergeAction('associate')">
                    {{ $t('clientCard.association') }}
                </activix-button>

                <activix-button
                    class="flex-1 ml-2"
                    :disabled="!authUser.custom_permissions.merge_lead"
                    @click="setLeadMergeAction('merge')"
                >
                    {{ $t('clientCard.fusion') }}
                </activix-button>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    import { mapState } from 'pinia';
    import SweetAlertIcon from '../elements/SweetAlert/Icon.vue';
    import { useLeadStore } from '../../store/modules/lead/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            SweetAlertIcon,
        },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            portal: {
                type: String,
                default: 'modal-1',
            },
        },

        data() {
            return {
                canceled: false,
            };
        },

        computed: {
            ...mapState(useLeadStore, ['currentMergeAction']),
            ...mapState(useGlobalStore, ['authUser']),
        },

        watch: {
            currentMergeAction(newValue) {
                useLeadStore().mergeManualCheckDuplicate = !!newValue;

                if (empty(newValue)) return;

                this.close();
            },
        },

        methods: {
            setLeadMergeAction(mergeAction) {
                useLeadStore().currentMergeAction = mergeAction;
            },
            onCancel() {
                this.canceled = true;
                this.close();
            },

            close() {
                this.$emit('update:opened', false);
            },

            onClosed() {
                if (this.canceled) {
                    this.$emit('canceled');
                }

                this.canceled = false;
            },
        },
    };
</script>
