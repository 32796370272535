<template>
    <div>
        <activix-tooltip :content="tooltip">
            <checkbox-date-picker
                :checkbox-color="checkboxColor"
                :date="lead.sale_date"
                :date-only="true"
                :disabled="isDisabled"
                @check="onCheckboxClick"
                @date-click="onDateClick"
            />
        </activix-tooltip>

        <remove-follow-up
            follow-up="sale"
            :opened.sync="removeFollowUpModalOpened"
            @approve="onRemove"
            @closed="removeFollowUpModalOpened = false"
        />

        <activix-confirm-modal
            :approve-text="$t('followUp.addSaleByPhone')"
            :content="$t('followUp.saleIsByPhone')"
            :deny-text="$t('general.cancel')"
            :opened.sync="confirmSaleByPhoneModalOpened"
            :title="$t('general.areYouSure')"
            @approve="openModalSelection"
        />

        <vue-fetti v-if="visibleConfetti" />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import VueFetti from 'vuefetti/src/components/VueFetti.vue';

    import CheckboxDatePicker from '@/components/container/input/CheckboxDatePicker.vue';
    import RemoveFollowUp from '@/components/modals/RemoveFollowUp.vue';

    import AccountType from '@/entities/AccountType';
    import ActivixDate from '@/value-objects/ActivixDate';
    import Lead from '@/entities/Lead';
    import LeadType from '@/entities/LeadType';
    import LeadVehicleModality from '@/entities/LeadVehicleModality.js';
    import ProgressState from '@/entities/ProgressState.js';

    export default {
        components: {
            CheckboxDatePicker,
            RemoveFollowUp,
            VueFetti,
        },

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            disabledTooltip: {
                type: String,
                default: '',
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        data() {
            return {
                confirmSaleByPhoneModalOpened: false,
                removeFollowUpModalOpened: false,
                visibleConfetti: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            checkboxColor() {
                return this.lead.sale_by_phone ? 'purple' : 'blue';
            },

            date() {
                return new ActivixDate(this.lead.sale_date);
            },

            disabledByVehicleInfoRule() {
                return (
                    !this.lead.sale_date &&
                    this.lead.account.stock_required_for_sale &&
                    this.lead.wanted_vehicles.every(vehicle => {
                        return !vehicle.make || !vehicle.model;
                    })
                );
            },

            disabledByStockRule() {
                return (
                    !this.lead.sale_date &&
                    this.lead.account.client_card_fields.wanted_vehicle.stock &&
                    this.lead.account.stock_required_for_sale &&
                    this.lead.wanted_vehicles.every(vehicle => {
                        return !vehicle.make || !vehicle.model || this.hasEmptyStock(vehicle);
                    })
                );
            },

            isDisabled() {
                if (this.disabled) {
                    return true;
                }

                if (this.presentedDate.isEmpty() && !this.lead.account.sale_by_phone) {
                    return true;
                }

                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (this.lead.refinanced_date) {
                    if (!this.lead.sale_date || this.lead.delivery_date || this.lead.delivered_date) {
                        return true;
                    }
                }

                if ((this.disabledByStockRule || this.disabledByVehicleInfoRule) && this.lead.account.type !== AccountType.CUSTOM) {
                    return true;
                }

                if (this.lead.delivered_date && this.lead.isNotOfType(LeadType.RENEWAL)) {
                    // if the sale is not checked for some reason allow it to be checked to fix the error
                    return !this.date.isEmpty();
                }

                if (!this.$can('leads.sale_date:update')) {
                    return true;
                }

                return this.guest;
            },

            presentedDate() {
                return new ActivixDate(this.lead.presented_date).or(this.lead.home_presented_date);
            },

            shouldDisplayConfirmSaleByPhone() {
                return this.presentedDate.isEmpty() && this.date.isEmpty();
            },

            shouldDisplaySelectVehicle() {
                return this.lead.wanted_vehicles.length > 1;
            },

            soldOrFirstWantedVehicle() {
                return this.lead.getSoldOrFirstWantedVehicle();
            },

            tooltip() {
                if (this.disabled) {
                    return this.disabledTooltip;
                }

                if (this.lead.sale_date) {
                    if (this.lead.delivered_date) {
                        return this.$t('clientCard.needDeliveredUnchecked');
                    }

                    if (this.lead.sale_by_phone) {
                        return this.$tc('result.saleByPhone', 1);
                    }
                } else if (this.lead.refinanced_date) {
                    return this.$t('clientCard.needRefinancedUnchecked');
                }

                if (this.disabledByStockRule) {
                    return this.$t('clientCard.needVehicleInfoAndStockForSale');
                }

                if (this.disabledByVehicleInfoRule) {
                    return this.$t('clientCard.needVehicleInfoForSale');
                }

                return '';
            },
        },

        watch: {
            'lead.sale_date': {
                immediate: true,
                handler() {
                    if (
                        this.lead.progress_state === ProgressState.CASH_DEAL &&
                        this.soldOrFirstWantedVehicle &&
                        this.soldOrFirstWantedVehicle.modality !== LeadVehicleModality.CASH
                    ) {
                        this.updateVehicle({
                            modality: LeadVehicleModality.CASH,
                        });
                    }
                },
            },
        },

        methods: {
            autoSetToday() {
                this.updateLead({
                    sale_date: this.presentedDate.toString(),
                });

                if (this.soldOrFirstWantedVehicle?.id && !this.soldOrFirstWantedVehicle?.sold) {
                    this.updateVehicle({
                        sold: true,
                    });
                }
            },

            hasEmptyStock(vehicle) {
                return !vehicle.stock_state || (vehicle.stock_state === 'stock' && !vehicle.stock);
            },

            async makeConfetti() {
                if (!this.$feature.isEnabled('special-sale')) {
                    return;
                }

                this.visibleConfetti = false;
                await this.$nextTick();
                this.visibleConfetti = true;
            },

            onActivation() {
                if (this.shouldDisplaySelectVehicle) {
                    if (this.shouldDisplayConfirmSaleByPhone) {
                        this.confirmSaleByPhoneModalOpened = true;
                    } else {
                        this.openSelectVehicleModal();
                    }
                } else if (this.date.isEmpty() && this.presentedDate.isToday()) {
                    this.autoSetToday();
                    this.makeConfetti();
                } else if (this.shouldDisplayConfirmSaleByPhone) {
                    this.confirmSaleByPhoneModalOpened = true;
                } else {
                    this.openFollowUpModal();
                }
            },

            onCheckboxClick(isChecked) {
                if (isChecked) {
                    this.onActivation();
                } else {
                    this.removeFollowUpModalOpened = true;
                }
            },

            onDateClick() {
                if (!this.date.isEmpty()) {
                    this.openFollowUpModal();
                } else {
                    this.onActivation();
                }
            },

            onRemove() {
                this.updateLead({
                    delivery_date: null,
                    sale_by_phone: false,
                    sale_date: null,
                });
            },

            openFollowUpModal() {
                this.$modal.show('followUp', {
                    leadId: this.lead.id,
                    current: 'sale',
                    last: 'presented',
                });
            },

            openModalSelection() {
                if (this.shouldDisplaySelectVehicle) {
                    this.openSelectVehicleModal();
                } else {
                    this.openFollowUpModal();
                }
            },

            openSelectVehicleModal() {
                this.$modal.show('selectVehicle', {
                    leadId: this.lead.id,
                    step: 'sale',
                });
            },

            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },

            updateVehicle(data, options = {}) {
                this.$emit('update-vehicle', { data, options });
            },
        },

        created() {
            this.$eventBus.$on('make-confetti-sale', this.makeConfetti);
        },

        beforeDestroy() {
            this.$eventBus.$off('make-confetti-sale', this.makeConfetti);
        },
    };
</script>
