<template>
    <activix-tooltip :content="tooltip">
        <follow-up-field
            follow-up="appointment"
            :checkbox-color="checkboxColor"
            :date="lead.appointment_date"
            :disabled="isDisabled"
            :follow-up-params="{ showPhoneIcon: !lead.appt_call_date && !lead.isService() }"
            :lead-id="lead.id"
            @input="updateLead({ appointment_date: $event })"
        />
    </activix-tooltip>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import FollowUpField from '@/components/container/lead/input/FollowUpField.vue';

    import Lead from '@/entities/Lead.js';
    import LeadType from '@/entities/LeadType';
    import TaskEventType from '@/entities/TaskEventType';

    export default {
        components: { FollowUpField },

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            disabledTooltip: {
                type: String,
                default: '',
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            checkboxColor() {
                if (this.lead.appointment_date && this.mainEvent?.no_show) {
                    return 'orange';
                }

                if (this.mainEvent?.canceled) {
                    return 'red';
                }

                if (this.mainEvent?.confirmed) {
                    return 'green';
                }

                return 'blue';
            },

            isDisabled() {
                if (this.disabled) {
                    return true;
                }

                if (this.lead.isOfType(LeadType.WALK_IN)) {
                    return true;
                }

                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (this.lead.sale_date && !this.lead.sale_by_phone) {
                    return true;
                }

                if (this.lead.presented_date || this.lead.home_presented_date) {
                    return true;
                }

                if (this.lead.isService() && this.lead.appt_confirmation_date) {
                    return true;
                }

                if (!this.$can('leads.appointment_date:update')) {
                    return true;
                }

                return this.guest;
            },

            tooltip() {
                if (this.disabled) {
                    return this.disabledTooltip;
                }

                let tooltip = '';
                let disabledTooltip = '';

                if (this.lead.isOfType(LeadType.WALK_IN)) {
                    disabledTooltip += this.$t('result.leadIsWalkIn');
                } else if (this.lead.hasInvalidStatus()) {
                    disabledTooltip += `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.appointment'),
                    ])}`;
                } else if (this.lead.sale_by_phone) {
                    disabledTooltip += this.$t('result.leadIsSoldbyPhone');
                } else if (this.lead.isService() && this.lead.appt_confirmation_date) {
                    disabledTooltip += this.$t('result.leadIsServiceWithConfirmation');
                } else if (this.guest) {
                    disabledTooltip += this.$t('clientCard.guestMode');
                }

                if (disabledTooltip) {
                    tooltip += tooltip ? '<br>' : '';
                    tooltip += `<em>${disabledTooltip}</em>`;
                    return tooltip;
                }

                if (this.lead.appointment_date && this.mainEvent && this.mainEvent.no_show) {
                    if (tooltip) {
                        tooltip += '<br>';
                    }

                    tooltip += this.$t('modal.noShowTooltip');
                }

                if (this.lead.presented_date || this.lead.home_presented_date) {
                    if (tooltip) {
                        tooltip += '<br>';
                    }

                    tooltip += this.$t('clientCard.needPresentedUnchecked');
                }

                return tooltip;
            },

            mainEvent() {
                if (!this.lead || !this.lead.task_events) {
                    return null;
                }

                return this.lead.task_events.find(taskEvent => {
                    return taskEvent.task_event_type_id === TaskEventType.APPOINTMENT && taskEvent.main_event;
                });
            },
        },

        methods: {
            openCancelAppointmentModal() {
                this.$modal.show('cancelAppointment', {
                    leadId: this.lead.id,
                    mainEventId: this.mainEvent?.id,
                });
            },

            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },

        created() {
            this.$eventBus.$on('open-cancel-appointment', this.openCancelAppointmentModal);
        },

        beforeDestroy() {
            this.$eventBus.$off('open-cancel-appointment', this.openCancelAppointmentModal);
        },
    };
</script>
