<template>
    <div class="flex">
        <div class="w-1/2 pr-3">
            <activix-multiselect
                identifier="value"
                :close-on-select="!multiselectMultiple"
                :disabled="guest"
                :multiple="multiselectMultiple"
                :options="options"
                :placeholder="multiselectPlaceholder"
                :searchable="false"
                :select-all="false"
                :selected="selectedOptions"
                @update="update"
            />
        </div>
        <div class="w-1/2 pl-3">
            <textarea
                class="form-control"
                rows="1"
                :value="comment"
                @input="updateComment"
            />
        </div>
    </div>
</template>

<script>
    import { cloneDeep, throttle } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';
    import { useGlobalStore } from '../../../store/store.js';
    import { useContextStore } from '../../../store/modules/context/store.js';

    export default {
        props: {
            name: String,
            multiselectValue: [String, Array],
            multiselectOptions: Array,
            multiselectMultiple: {
                type: Boolean,
                default: false,
            },
            multiselectPlaceholder: {
                type: String,
                default: '',
            },
            multiselectCustomLabels: String,
            textinputValue: String,
        },

        data() {
            return {
                value: '',
                comment: '',
                deferedUpdate: null,
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useGlobalStore, ['guest']),

            options() {
                return this.multiselectOptions.map(option => {
                    return {
                        value: option,
                        label: this.$t(`${this.multiselectCustomLabels}.${option}`),
                    };
                });
            },

            selectedOptions() {
                const selectedOptions = this.options.filter(option => {
                    return this.value && (option.value === this.value || this.value.includes(option.value));
                });

                return Array.isArray(this.value) ? selectedOptions : selectedOptions[0];
            },

            limit() {
                if (this.multiselectMultiple) {
                    return 2;
                }

                return 100;
            },
        },

        watch: {
            contextLead() {
                this.updateValues();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            async update(selections = false) {
                const payload = {
                    service_process: cloneDeep(this.contextLead.service_process),
                };

                if (selections !== false) {
                    const newValue = Array.isArray(selections) ? selections.map(s => s.value) : selections.value;

                    payload.service_process[this.name].value = newValue;
                }

                payload.service_process[this.name].comment = this.comment || null;

                await this.updateLeadAction(this.contextLead.id, payload);
            },

            updateValues() {
                this.comment = this.textinputValue;
                this.value = this.multiselectValue;
            },

            updateComment(e) {
                this.comment = e.target.value;
                this.deferedUpdate();
            },
        },

        created() {
            this.deferedUpdate = throttle(this.update, 2000);

            this.updateValues();
        },
    };
</script>
