<template>
    <t-footer class="font-semibold uppercase text-gray-600">
        <th />
        <th>{{ $t('clientCard.total') }}</th>
        <th class="hidden md:table-cell" />
        <th class="hidden md:table-cell">{{ totalPrice }}</th>
        <th class="md:hidden">{{ totalPrice }} <br /> {{ totalTime }}</th>
        <th class="hidden md:table-cell">{{ totalTime }}</th>
    </t-footer>
</template>

<script>
    import { toCurrency } from '@/utils/numbers.js';

    import TFooter from '@/components/presentational/table/TFooter';

    import Lead from '@/entities/Lead';

    export default {
        components: { TFooter },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            serviceProducts() {
                return this.lead.products.filter(product => product.type === 'service');
            },

            totalPrice() {
                let total = 0;

                for (const product of this.serviceProducts) {
                    if (!product.pivot?.price || !product.pivot?.sold) {
                        continue;
                    }

                    total += parseFloat(product.pivot.price);
                }

                return toCurrency(total);
            },

            totalSeconds() {
                let total = 0;

                for (const product of this.serviceProducts) {
                    if (!product.pivot?.minutes || !product.pivot?.sold) {
                        continue;
                    }

                    total += parseFloat(product.pivot.minutes);
                }

                return total * 60;
            },

            totalTime() {
                return this.timeHms(this.totalSeconds, true);
            },
        },
    };
</script>
