<template>
    <div>
        <section class="relative grid grid-cols-1 p-6 gap-12 border-b border-gray-200 bg-gray-100 | md:p-12 md:grid-cols-3">
            <div class="text-center">
                <label class="mb-0 text-gray-600 tracking-wider uppercase text-sm">
                    {{ $t('clientCard.total') }}
                </label>

                <samp class="block text-2xl text-gray-700 tabular-nums">
                    <template v-if="$i18n.locale !== 'fr'">$ </template>
                    {{ totalPriceRounded }}<span class="mx-1 text-base align-top">{{ totalPriceDecimal }}</span>
                    <template v-if="$i18n.locale === 'fr'"> $</template>
                </samp>
            </div>

            <div class="text-center">
                <label class="mb-0 text-gray-600 tracking-wider uppercase text-sm">
                    {{ $t('clientCard.boxes.serviceProducts.totalTime') }}
                </label>

                <samp class="block text-2xl text-gray-700 tabular-nums">
                    {{ totalTime }}
                </samp>
            </div>

            <div class="text-center">
                <label class="mb-0 text-gray-600 tracking-wider uppercase text-sm">
                    {{ $t('clientCard.boxes.serviceProducts.workOrder') }}
                </label>

                <samp class="block text-2xl text-gray-700 tabular-nums">
                    {{ lead.work_order || '---' }}
                </samp>
            </div>
        </section>
    </div>
</template>

<script>
    import Lead from '@/entities/Lead';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            serviceProducts() {
                return this.lead.products.filter(product => product.type === 'service');
            },

            totalPrice() {
                let total = 0;

                for (const product of this.lead.products) {
                    if (!product.pivot?.price || !product.pivot?.sold) {
                        continue;
                    }

                    total += parseFloat(product.pivot.price);
                }
                return total;
            },

            totalPriceDecimal() {
                return (this.totalPrice - Math.floor(this.totalPrice)).toFixed(2).split('.')[1];
            },

            totalPriceRounded() {
                return Math.floor(this.totalPrice);
            },

            totalSeconds() {
                let total = 0;

                for (const product of this.serviceProducts) {
                    if (!product.pivot?.minutes || !product.pivot?.sold) {
                        continue;
                    }

                    total += parseFloat(product.pivot.minutes);
                }

                return total * 60;
            },

            totalTime() {
                return this.timeHms(this.totalSeconds, true);
            },
        },
    };
</script>
