<!-- eslint-disable vue/valid-v-bind-sync -->
<template>
    <div>
        <div class="box | mb-6">
            <div class="box-header | flex items-center justify-between">
                <h4 class="box-title">
                    {{ $t('clientCard.commercial') }}
                </h4>
                <div class="flex items-center">
                    <activix-tooltip :content="meetingTooltip">
                        <icon
                            class="text-xl"
                            :class="[
                                contextLead.commercial_meeting ? 'link-blue' : 'link-grey-light',
                                { disabled: actionDisabled },
                            ]"
                            :name="contextLead.commercial_meeting ? $icons.meetingOn : $icons.meetingOff"
                            @click="toggleMeeting(!contextLead.commercial_meeting)"
                        />
                    </activix-tooltip>

                    <activix-tooltip :content="needCommercialTooltip">
                        <icon
                            class="text-xl ml-2"
                            :class="[
                                contextLead.commercial_no_need ? 'link-blue' : 'link-grey-light',
                                { disabled: actionDisabled },
                            ]"
                            :name="$icons.excluded"
                            @click="toggleNeedCommercial(!contextLead.commercial_no_need)"
                        />
                    </activix-tooltip>

                    <activix-tooltip :content="freezeFinancialDataTooltip" v-if="authUser.freeze_financial_data">
                        <icon
                            class="text-xl ml-2"
                            :class="[
                                contextLead.freeze_financial_data ? 'link-blue' : 'link-grey-light',
                                { disabled: actionDisabled },
                            ]"
                            :name="contextLead.freeze_financial_data ? 'regular/lock-1' : 'regular/lock-unlock'"
                            @click="toggleNeedFreezeFinancialData(!contextLead.freeze_financial_data)"
                        />
                    </activix-tooltip>
                </div>
            </div>
            <div class="box-body | pt-0">
                <product :product.sync="product" :key="product.id" v-for="product in commercialProducts" />

                <div class="row | font-bold text-center">
                    <div class="col-xs-2 col-sm-6 | text-left">
                        {{ $t('clientCard.total') }}
                    </div>
                    <div class="col-xs-1">
                        =
                    </div>
                    <div class="col-xs-8 col-sm-5">
                        {{ toMoney(totalPrice, 2, $i18n.locale, authUser.hide_fi_profits) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    // Components
    import Product from './Product.vue';
    // Pinia
    import { useGlobalStore } from '../../store/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: { Product },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            totalPrice() {
                let total = 0;

                for (const product of this.contextLead.products) {
                    if (product.type !== 'commercial' || empty(product.pivot.price) || !product.pivot.sold) {
                        continue;
                    }

                    total += parseFloat(product.pivot.price);
                }

                return total;
            },

            commercialProducts() {
                return this.contextLead.account.products
                    .filter(product => {
                        return product.type === 'commercial' && product.visible;
                    })
                    .sort((a, b) => {
                        return a.order > b.order;
                    });
            },

            meetingTooltip() {
                if (this.contextLead.commercial_meeting) {
                    return this.$t('clientCard.met');
                }

                return this.$t('clientCard.notMet');
            },

            needCommercialTooltip() {
                if (this.contextLead.commercial_no_need) {
                    return this.$t('clientCard.excluded');
                }

                return this.$t('clientCard.included');
            },

            freezeFinancialDataTooltip() {
                if (this.contextLead.freeze_financial_data) {
                    return this.$t('clientCard.unfreezedFinancialData');
                }

                return this.$t('clientCard.freezedFinancialData');
            },

            actionDisabled() {
                return this.guest || this.authUser.isAdvisor() || this.authUser.isBdc();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            toggleMeeting(met) {
                if (this.guest || this.authUser.isAdvisor() || this.authUser.isBdc()) {
                    return;
                }

                this.updateLeadAction(this.contextLead.id, { commercial_meeting: met });
            },

            toggleNeedCommercial(need) {
                if (this.guest || this.authUser.isAdvisor() || this.authUser.isBdc()) {
                    return;
                }

                this.updateLeadAction(this.contextLead.id, { commercial_no_need: need });
            },

            toggleNeedFreezeFinancialData(freeze) {
                if (this.guest) {
                    return;
                }

                this.updateLeadAction(this.contextLead.id, { freeze_financial_data: freeze });
            },
        },
    };
</script>
