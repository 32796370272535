<template>
    <input-list :fields="fields" :column-count="3" />
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import ActivixCheckbox from '@/components/elements/ActivixCheckbox';
    import AppointmentInput from '@/components/container/input/lead/AppointmentInput.vue';
    import CsiInput from '@/components/container/input/lead/CsiInput.vue';
    import InputList from '@/components/container/input/InputList';
    import InvisibleDateTimePicker from '@/components/container/input/InvisibleDateTimePicker.vue';
    import PresentedInput from '@/components/container/input/lead/PresentedInput.vue';
    import RoadTestInput from '@/components/container/input/lead/RoadTestInput.vue';

    import Lead from '@/entities/Lead.js';

    export default {
        components: { InputList },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            fields() {
                return [
                    {
                        column: 1,
                        label: this.$t('result.appointment'),
                        component: AppointmentInput,
                        props: { lead: this.lead },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 1,
                        label: this.$t('clientCard.services.inspection'),
                        tooltip: !this.lead.presented_date ? this.$t('clientCard.needPresentedChecked') : '',
                        component: RoadTestInput,
                        props: { lead: this.lead },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 1,
                        label: this.$t('clientCard.endServiceDate'),
                        tooltip: !this.lead.presented_date ? this.$t('clientCard.needPresentedChecked') : '',
                        component: InvisibleDateTimePicker,
                        props: {
                            disabled: this.guest || !this.lead.presented_date,
                            value: this.lead.end_service_date,
                        },
                        events: { input: value => this.onLeadInput({ end_service_date: value }) },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.csi,
                        label: this.$t('clientCard.csi'),
                        tooltip: !this.lead.invoiced ? this.$t('clientCard.needInvoicedChecked') : '',
                        component: CsiInput,
                        props: { lead: this.lead },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.services.reachedClient'),
                        component: ActivixCheckbox,
                        props: {
                            value: this.lead.reached_client,
                            disabled: this.lead.hasInvalidStatus() || !this.lead.presented_date || this.guest,
                            size: 'lg',
                        },
                        events: { input: value => this.onLeadInput({ reached_client: value }) },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.promisedDate'),
                        component: InvisibleDateTimePicker,
                        props: {
                            disabled: this.guest,
                            value: this.lead.promised_date,
                        },
                        events: { input: value => this.onLeadInput({ promised_date: value }) },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.invoiced'),
                        tooltip: !this.lead.presented_date ? this.$t('clientCard.needPresentedChecked') : '',
                        component: ActivixCheckbox,
                        props: {
                            value: this.lead.invoiced,
                            disabled: this.guest || !this.lead.presented_date,
                            size: 'lg',
                        },
                        events: { input: value => this.onLeadInput({ invoiced: value }) },
                    },
                    {
                        column: 3,
                        label: this.$t('result.presented'),
                        component: PresentedInput,
                        props: { lead: this.lead },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 3,
                        label: this.$t('clientCard.services.cleaning'),
                        tooltip: !this.lead.presented_date ? this.$t('clientCard.needPresentedChecked') : '',
                        component: ActivixCheckbox,
                        props: {
                            value: this.lead.service_cleaning_car,
                            disabled: this.lead.hasInvalidStatus() || !this.lead.presented_date || this.guest,
                            size: 'lg',
                        },
                        events: { input: value => this.onLeadInput({ service_cleaning_car: value }) },
                    },
                    {
                        column: 3,
                        label: this.$t('clientCard.plannedPickUpDate'),
                        tooltip: !this.lead.presented_date ? this.$t('clientCard.needPresentedChecked') : '',
                        component: InvisibleDateTimePicker,
                        props: {
                            disabled: this.guest || !this.lead.presented_date,
                            value: this.lead.planned_pick_up_date,
                        },
                        events: { input: value => this.onLeadInput({ planned_pick_up_date: value }) },
                    },
                ];
            },
        },

        methods: {
            onLeadInput(data) {
                this.updateLead({ data });
            },

            updateLead({ data, options = {} }) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
