<template>
    <div>
        <activix-calendar
            :config="calendarConfig"
            :event-render="eventRender"
            :events="getEvents"
            :default-date="nextTaskDate"
            :height="lgLayout ? 'parent' : 'self'"
            ref="calendar"
            @event-after-render="onEventAfterRender"
            @event-click="onEventClick"
            @event-destroy="onEventDestroy"
            @event-drop="onEventDrop"
        />

        <call-lead name="calendar:callLead" />
        <email-lead name="calendar:emailLead" />
        <automated-event-modal :show.sync="modals.showUpdateFutureEvent" :payload="pendingPayload" @save="save" />
    </div>
</template>

<script>
    import { clone } from 'lodash-es';

    import CalendarMixin from '@/mixins/Calendar.js';
    import { isMd } from '@/utils/layout.js';

    import AutomatedEventModal from '@/components/modals/AutomatedEventModal.vue';
    import CallLead from '@/components/modals/CallLead.vue';
    import EmailLead from '@/components/modals/EmailLead.vue';

    import Lead from '@/entities/Lead.js';

    export default {
        name: 'Calendar',

        components: {
            AutomatedEventModal,
            CallLead,
            EmailLead,
        },

        mixins: [CalendarMixin],

        props: {
            currentLead: {
                type: Lead,
                required: true,
            },
        },

        data() {
            return {
                actionIsLoading: false,
                calendarConfig: {
                    defaultView: isMd ? 'threeDay' : 'month',
                },
                events: [],
                nextTaskDate: null,
            };
        },

        watch: {
            'currentLead.task_events': {
                immediate: true,
                deep: true,
                handler() {
                    this.updateCalendar();
                },
            },
        },

        methods: {
            updateCalendar() {
                const nextTaskEvents = (clone(this.currentLead.task_events) || [])
                    .sort((a, b) => {
                        return as_locale(a.start_at, 'start_at').diff(as_locale(b.start_at, 'start_at'));
                    })
                    .filter(event => {
                        return (
                            !event.canceled &&
                            !event.no_show &&
                            !event.status &&
                            (as_locale(event.start_at, 'start_at').isFuture() ||
                                as_locale(event.start_at, 'start_at').isToday())
                        );
                    });

                this.nextTaskDate = nextTaskEvents[0]
                    ? as_locale(nextTaskEvents[0].start_at, 'start_at').toDateString()
                    : now().toDateString();

                this.postMessageToWorker({
                    action: 'format',
                    events: this.currentLead.task_events,
                    useDefaultColorScheme: this.$ls.get('useDefaultColorScheme', true),
                });
            },

            getEvents(start, end, timezone, callback) {
                callback(this.events);
            },

            findEvent(eventId) {
                return this.events.find(e => e.id === eventId);
            },

            onAfterFormat({ events }) {
                this.events = events;

                if (this.$refs.calendar) {
                    this.$refs.calendar.fireMethod('refetchEvents');
                }
            },

            openCreateTaskEventModal() {
                this.$eventBus.$emit('open-add-task-event', {
                    leadId: this.currentLead.id,
                });
            },
        },

        mounted() {
            this.updateCalendar();
        },
    };
</script>
