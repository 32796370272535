<template>
    <activix-modal
        size="sm"
        :opened="opened"
        @canceled="$emit('cancel')"
        @closed="$emit('closed')"
    >
        <div class="flex flex-col" slot="body">
            <sweet-alert-icon class="mx-auto" type="info" />
            <div class="text-2xl font-semibold text-center my-2" v-text="$t('modal.titleAssignAdvisor')" />

            <div class="flex items-center justify-center mt-2">
                <span class="line-through">{{ getFullName(firstUser) }}</span>
                <icon class="mx-2 text-sm" name="regular/arrow-right" />
                {{ getFullName(secondUser) }}
            </div>
            <div class="mt-4 text-center">
                {{ $t('modal.leadAssignRequest') }}
            </div>
            <div class="text-center italic">
                <span>&#171;</span>
                {{ requestReason }}
                <span>&#187;</span>
            </div>
        </div>

        <div slot="footer">
            <activix-button @click="$emit('decline')" v-if="canDeclineRequest">
                {{ $t('modal.decline') }}
            </activix-button>
            <activix-button type="primary" @click="accept">
                {{ $t('modal.agree') }}
            </activix-button>
        </div>
    </activix-modal>
</template>

<script>
    import { mapState } from 'pinia';
    import SweetAlertIcon from '../elements/SweetAlert/Icon.vue';

    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            SweetAlertIcon,
        },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            transferType: {
                type: String,
            },
        },

        data() {
            return {
                content: '',
                canceled: false,
                comments: [],
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useGlobalStore, ['authUser']),

            firstUser() {
                return this.transferType == 'bdc' ? this.contextLead.bdc_user : this.contextLead.user;
            },

            canDeclineRequest() {
                return this.authUser.isAdmin() || this.authUser.isDirector() || this.authUser.isBdcDirector();
            },

            secondUser() {
                return this.transferType == 'bdc' ? this.contextLead.second_bdc_user : this.contextLead.second_user;
            },

            requestReason() {
                if (!this.comments) {
                    return '';
                }

                const comment = this.comments.find(comment => comment.request_user_id == this.secondUser.id);

                return comment ? `${comment.content}` : '';
            },
        },

        watch: {
            opened() {
                if (this.opened) {
                    this.fetchComments();
                }
            },
        },

        methods: {
            async fetchComments() {
                const response = await this.$axios.get(`v1/comments/${this.contextLead.id}`);

                this.comments = response.data.comments;
            },

            accept() {
                this.$emit('accept');
            },
        },
    };
</script>
