<template>
    <activix-tooltip :content="balanceCheckTooltip">
        <input-text
            name="balance"
            type="currency"
            :vehicle="true"
            :record-id="vehicle.id"
            :value="vehicle.balance"
            :disabled="guest"
            v-if="empty(vehicle.payment) || empty(vehicle.frequency)"
        />
        <div class="cursor-not-allowed inline-block" v-else>
            {{ toMoney(vehicle.balance, 2) }}
        </div>
    </activix-tooltip>
</template>

<script>
    import { mapState } from 'pinia';
    import InputText from './inputs/InputText.vue';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'Balance',

        components: {
            InputText,
        },

        props: {
            vehicle: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            balanceCheckTooltip() {
                return this.vehicle.payment && this.vehicle.frequency
                    ? this.$t('clientCard.needPaymentAndFrequencyEmpty')
                    : '';
            },
        },
    };
</script>
