<template functional>
    <div
        class="w-full align-middle"
        :class="[data.staticClass, { 'max-w-px': props.collapsing, 'table-cell': !props.inline }]"
    >
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            collapsing: {
                type: Boolean,
                default: true,
            },
            inline: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
