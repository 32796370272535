<template>
    <box>
        <box-header slot="header">
            {{ title }}
        </box-header>

        <box-content slot="content">
            <revival
                :blocked-reason="guest ? $t('clientCard.guestMode') : ''"
                :disabled="guest"
                :lead="contextLead"
                :with-modal="contextLead.isService()"
                @update-lead="updateLead"
            />
        </box-content>
    </box>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store';
    import { useGlobalStore } from '@/store/store.js';

    import Box from '@/components/presentational/boxes/Box';
    import BoxContent from '@/components/presentational/boxes/BoxContent';
    import BoxHeader from '@/components/presentational/boxes/BoxHeader';
    import Revival from '@/components/container/lead/Revival';

    export default {
        components: {
            Box,
            BoxContent,
            BoxHeader,
            Revival,
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useGlobalStore, ['guest']),

            title() {
                return this.contextLead.isService()
                    ? this.$t('clientCard.boxes.revival.title')
                    : this.$t('clientCard.followUp');
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            updateLead({ data, options = {} }) {
                this.updateLeadAction(this.contextLead.id, data, options);
            },
        },
    };
</script>
