<template>
    <div>
        <div class="box | mb-6">
            <div class="box-header">
                <h4 class="box-title">
                    {{ title }}
                </h4>
            </div>
            <div class="box-body | pt-0" :class="{ 'pb-2': mdLayout }">
                <div class="flex flex-wrap -mx-3">
                    <lead-input-wrapper class="w-full xs:w-1/2" :section="section" position="left" />
                    <lead-input-wrapper class="w-full xs:w-1/2" :section="section" position="right" />
                    <lead-input-wrapper class="md:w-full" :section="section" display-type="box" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LeadInputWrapper from './LeadInputWrapper.vue';

    export default {
        components: {
            LeadInputWrapper,
        },

        props: {
            title: {
                type: String,
                required: true,
            },
            section: {
                type: String,
                required: true,
            },
        },
    };
</script>
