<template>
    <div class="flex items-end">
        <lead-input-wrapper :with-padding="false" v-if="contextLead.created_at">
            <lead-input-group>
                <lead-input-label>
                    {{ $t('clientCard.advisor') }}
                </lead-input-label>
                <lead-input-value>
                    <div class="flex items-center">
                        <select-picker
                            name="user_id"
                            :options="formatedAssignmentUsers"
                            :record-id="contextLead.id"
                            :value="contextLead.user_id"
                            :disabled="!advisorEnabled"
                            ref="selectAdvisor"
                            v-if="canAssignAnyAdvisor"
                        />
                        <activix-tooltip :content="assignationAdvisorTransferTooltip" v-else>
                            <div class="disabled opacity-50">
                                {{ getFullName(contextLead.second_user) }}
                            </div>
                        </activix-tooltip>
                        <activix-tooltip
                            :content="
                                !contextLead.second_user_id
                                    ? $t('clientCard.assignationRequest')
                                    : assignationAdvisorTransferTooltip
                            "
                            v-if="displayAdvisorReassignationButton"
                        >
                            <div>
                                <icon
                                    class="ml-2"
                                    :class="contextLead.second_user_id ? 'link-orange' : 'link-grey'"
                                    name="regular/human-resources-employee-previous"
                                    @click="openAssignationUserRequestModal('advisor')"
                                />
                            </div>
                        </activix-tooltip>
                    </div>
                </lead-input-value>
            </lead-input-group>

            <lead-input-group v-if="contextLead.account.bdc_advisor">
                <lead-input-label>
                    {{ $t('clientCard.bdcAgent') }}
                </lead-input-label>
                <lead-input-value>
                    <div class="flex items-center">
                        <select-picker
                            name="bdc_user_id"
                            :options="formatedAssignmentUsers"
                            :record-id="contextLead.id"
                            :value="contextLead.bdc_user_id"
                            :disabled="!bdcAgentEnabled"
                            v-if="canAssignAnyBdc"
                        />
                        <activix-tooltip :content="assignationBdcTransferTooltip" v-else>
                            <div class="disabled opacity-50">
                                {{ getFullName(contextLead.second_bdc_user) }}
                            </div>
                        </activix-tooltip>
                        <activix-tooltip
                            :content="
                                !contextLead.second_bdc_user_id
                                    ? $t('clientCard.assignationRequest')
                                    : assignationBdcTransferTooltip
                            "
                            v-if="displayBdcReassignationButton"
                        >
                            <div>
                                <icon
                                    class="ml-2"
                                    :class="contextLead.second_bdc_user_id ? 'link-orange' : 'link-grey'"
                                    name="regular/human-resources-employee-previous"
                                    @click="openAssignationUserRequestModal('bdc')"
                                />
                            </div>
                        </activix-tooltip>
                    </div>
                </lead-input-value>
            </lead-input-group>

            <lead-input-group v-if="contextLead.isService()">
                <lead-input-label>
                    {{ $t('clientCard.serviceAgent') }}
                </lead-input-label>
                <lead-input-value>
                    <div class="flex items-center">
                        <select-picker
                            name="service_agent_id"
                            :class="{ 'text-grey-500': isSetBySerti('service_agent_id') }"
                            :disabled="!serviceAgentEnabled"
                            :options="formatedAssignmentUsers"
                            :record-id="contextLead.id"
                            :value="contextLead.service_agent_id"
                        />
                    </div>
                </lead-input-value>
            </lead-input-group>

            <lead-input-group v-if="!contextLead.isService()">
                <lead-input-label>
                    {{ $t('clientCard.commercialShort') }}
                </lead-input-label>
                <lead-input-value>
                    <div class="flex items-center">
                        <select-picker
                            name="commercial_id"
                            :disabled="!commercialEnabled"
                            :options="formatedAssignmentUsers"
                            :record-id="contextLead.id"
                            :value="contextLead.commercial_id"
                        />
                    </div>
                </lead-input-value>
            </lead-input-group>

            <lead-input-group v-if="contextLead.isService()">
                <lead-input-label>
                    {{ $t('clientCard.serviceAdvisor') }}
                </lead-input-label>
                <lead-input-value>
                    <div class="flex items-center">
                        <select-picker
                            name="service_advisor_id"
                            :class="{ 'text-grey-500': isSetBySerti('service_advisor_id') }"
                            :disabled="!serviceAdvisorEnabled"
                            :options="formatedAssignmentUsers"
                            :record-id="contextLead.id"
                            :value="contextLead.service_advisor_id"
                        />
                    </div>
                </lead-input-value>
            </lead-input-group>
        </lead-input-wrapper>

        <assignation-user-request
            :opened="assignationUserRequestModalOpened"
            :request-type="assignationUserRequestType"
            @save="saveAssignSecondUserRequest"
            @closed="assignationUserRequestModalOpened = false"
        />

        <assignation-user-transfer
            :opened.sync="assignationUserTransferModalOpened"
            :transfer-type="assignationUserTransferType"
            @accept="acceptAssignationUserTransfer"
            @decline="declineAssignationUserTransfer"
            @cancel="cancelAssignationUserTransfer"
            @closed="assignationUserTransferModalOpened = false"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    import AssignationUserRequest from '../modals/AssignationUserRequest.vue';
    import AssignationUserTransfer from '../modals/AssignationUserTransfer.vue';

    // Components
    import LeadInputGroup from './LeadInputGroup.vue';
    import LeadInputLabel from './LeadInputLabel.vue';
    import LeadInputValue from './LeadInputValue.vue';
    import LeadInputWrapper from './LeadInputWrapper.vue';
    import SelectPicker from './inputs/SelectPicker.vue';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            LeadInputGroup,
            LeadInputLabel,
            LeadInputValue,
            LeadInputWrapper,
            SelectPicker,
            AssignationUserRequest,
            AssignationUserTransfer,
        },

        data() {
            return {
                assignationUserRequestModalOpened: false,
                assignationUserTransferModalOpened: false,
                assignationUserRequestType: '',
                assignationUserTransferType: '',
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),
            ...mapState(useGlobalStore, ['authUser', 'guest', 'advisorUsers', 'bdcUsers', 'serviceAgentUsers', 'serviceAdvisorUsers', 'assignmentUsers', 'commercialUsers']),

            advisorEnabled() {
                if (this.guest) {
                    return false;
                }

                if (
                    this.authUser.isAdmin() ||
                    this.authUser.isDirector() ||
                    this.authUser.isBdcDirector() ||
                    this.authUser.isSuperBdc()
                ) {
                    return true;
                }

                if (!this.contextLead.user_id) {
                    if (
                        !this.contextLead.account.assigned_lead &&
                        !this.authUser.isBdc() &&
                        this.authUser.hasAdvisorSkills()
                    ) {
                        return false;
                    }

                    return true;
                }

                return this.$feature.isEnabled('prevent-self-assignment') ? false : this.contextLead.user_id == this.authUser.id;
            },

            bdcAgentEnabled() {
                if (this.guest) {
                    return false;
                }

                if (
                    this.authUser.isAdmin() ||
                    this.authUser.isDirector() ||
                    this.authUser.isBdcDirector() ||
                    this.authUser.isSuperBdc()
                ) {
                    return true;
                }

                if (!this.contextLead.bdc_user_id) {
                    if (this.authUser.isAdvisor() && this.contextLead.account.unrestricted_assignment) {
                        return true;
                    }

                    if (this.authUser.hasAdvisorSkills() && !this.contextLead.account.assigned_lead) {
                        return false;
                    }

                    return true;
                }

                return this.$feature.isEnabled('prevent-self-assignment') ? false : this.contextLead.bdc_user_id == this.authUser.id;
            },

            commercialEnabled() {
                return !this.guest;
            },

            serviceAgentEnabled() {
                if (this.guest) {
                    return false;
                }

                if (
                    this.authUser.isAdmin() ||
                    this.authUser.isDirector() ||
                    this.authUser.isBdcDirector() ||
                    this.authUser.isSuperBdc()
                ) {
                    return true;
                }

                if (!this.contextLead.service_agent_id) {
                    return true;
                }

                return this.contextLead.service_agent_id == this.authUser.id;
            },

            serviceAdvisorEnabled() {
                if (this.guest) {
                    return false;
                }

                if (
                    this.authUser.isAdmin() ||
                    this.authUser.isDirector() ||
                    this.authUser.isBdcDirector() ||
                    this.authUser.isSuperBdc()
                ) {
                    return true;
                }

                if (!this.contextLead.service_advisor_id) {
                    return true;
                }

                return this.contextLead.service_advisor_id == this.authUser.id;
            },

            displayAdvisorReassignation() {
                return (
                    this.authUser.isAdvisor() &&
                    this.contextLead.user_id &&
                    (this.$feature.isEnabled('prevent-self-assignment') || this.authUser.id != this.contextLead.user_id)
                );
            },

            displayAdvisorReassignationButton() {
                return (
                    (!this.contextLead.second_user_id &&
                        this.contextLead.user_id &&
                        this.authUser.isAdvisor() &&
                        (this.$feature.isEnabled('prevent-self-assignment') || this.authUser.id != this.contextLead.user_id)) ||
                    this.contextLead.second_user_id
                );
            },

            displayBdcReassignationButton() {
                return (
                    (!this.contextLead.second_bdc_user_id &&
                        this.contextLead.bdc_user_id &&
                        this.authUser.isBdc() &&
                        !this.authUser.isSuperBdc() &&
                        (this.$feature.isEnabled('prevent-self-assignment') || this.authUser.id != this.contextLead.bdc_user_id)) ||
                    this.contextLead.second_bdc_user_id
                );
            },

            displayBdcReassignation() {
                return (
                    this.authUser.isBdc() &&
                    this.contextLead.bdc_user_id &&
                    (this.$feature.isEnabled('prevent-self-assignment') || this.authUser.id != this.contextLead.bdc_user_id)
                );
            },

            canAssignAnyAdvisor() {
                return !this.contextLead.second_user_id;
            },

            canAssignAnyBdc() {
                return !this.contextLead.second_bdc_user_id;
            },

            formatedAssignmentUsers() {
                return this.assignmentUsers.map(user => {
                    return {
                        value: user.id,
                        text: this.getFullName(user),
                    };
                });
            },

            formatedAdvisorUsers() {
                return this.advisorUsers.map(user => {
                    return {
                        value: user.id,
                        text: this.getFullName(user),
                    };
                });
            },

            formatedAllRolesUsers() {
                return this.allRolesUsers.map(user => {
                    return {
                        value: user.id,
                        text: this.getFullName(user),
                    };
                });
            },

            formatedAdvisorSecondUsers() {
                const users = this.advisorUsers.filter(user => {
                    return this.contextLead.second_user_id == user.id || this.authUser.id == user.id;
                });

                return users.map(user => {
                    return {
                        value: user.id,
                        text: this.getFullName(user),
                    };
                });
            },

            formatedBdcUsers() {
                return this.bdcUsers.map(user => {
                    return {
                        value: user.id,
                        text: this.getFullName(user),
                    };
                });
            },

            formatedBdcSecondUsers() {
                const users = this.bdcUsers.filter(user => {
                    return this.contextLead.second_bdc_user_id == user.id || this.authUser.id == user.id;
                });

                return users.map(user => {
                    return {
                        value: user.id,
                        text: this.getFullName(user),
                    };
                });
            },

            formatedCommercialUsers() {
                return this.commercialUsers.map(user => {
                    return {
                        value: user.id,
                        text: this.getFullName(user),
                    };
                });
            },

            formatedServiceAgents() {
                return this.serviceAgentUsers.map(user => {
                    return {
                        value: user.id,
                        text: this.getFullName(user),
                    };
                });
            },

            formatedServiceAdvisors() {
                return this.serviceAdvisorUsers.map(user => {
                    return {
                        value: user.id,
                        text: this.getFullName(user),
                    };
                });
            },

            assignationAdvisorTransferTooltip() {
                const userName = this.contextLead.user.fullName;
                const secondUserName = this.contextLead.second_user.fullName;

                return `
                    ${this.$t('clientCard.assignation.transferAssignedTooltip', [userName])}
                    <br>
                    ${this.$t('clientCard.assignation.transferWaitingTooltip', [secondUserName])}
                `;
            },

            assignationBdcTransferTooltip() {
                const bdcName = this.contextLead.bdc_user.fullName;
                const secondBdcName = this.contextLead.second_bdc_user.fullName;

                return `
                    ${this.$t('clientCard.assignation.transferAssignedTooltip', [bdcName])}
                    <br>
                    ${this.$t('clientCard.assignation.transferWaitingTooltip', [secondBdcName])}
                `;
            },

            advisorHasDoubleAssignation() {
                return !!this.contextLead.user_id && !!this.contextLead.second_user_id;
            },

            bdcHasDoubleAssignation() {
                return !!this.contextLead.bdc_user_id && !!this.contextLead.second_bdc_user_id;
            },

            shouldShowReassignationModalToDirector() {
                return (
                    (this.authUser.isDirector() || this.authUser.isBdcDirector()) &&
                    (this.contextLead.second_user_id || this.contextLead.second_bdc_user_id) &&
                    (this.authUser.divisions.some(division => division.id == this.contextLead.division_id) ||
                        !this.contextLead.division_id)
                );
            },

            assignationModalTypeIsAdvisor() {
                return (
                    this.advisorHasDoubleAssignation &&
                    (this.authUser.id == this.contextLead.user_id || this.shouldShowReassignationModalToDirector)
                );
            },

            assignationModalTypeIsBdc() {
                return (
                    this.bdcHasDoubleAssignation &&
                    (this.authUser.id == this.contextLead.bdc_user_id || this.shouldShowReassignationModalToDirector)
                );
            },

            advisorTooltip() {
                if (this.authUser.isAdvisor() && this.contextLead.user_id != this.authUser.id) {
                    if (!this.contextLead.user_id) {
                        return '';
                    }

                    if (this.contextLead.second_user_id) {
                        return this.$t('clientCard.assignedRequestAdvisor', {
                            user: this.getFullName(this.contextLead.second_user),
                        });
                    }

                    return this.$t('clientCard.assignationDontAllow');
                }

                if (this.contextLead.second_user_id) {
                    if (this.shouldShowReassignationModalToDirector || this.authUser.id == this.contextLead.user_id) {
                        return this.$t('clientCard.assignedRequestNeedToBeApprovedAdvisor', {
                            user: this.getFullName(this.contextLead.second_user),
                        });
                    }

                    return this.$t('clientCard.assignedRequestAdvisor', {
                        user: this.getFullName(this.contextLead.second_user),
                    });
                }

                return '';
            },
        },

        watch: {
            'contextLead.id': {
                immmediate: true,
                handler(newValue) {
                    if (newValue) {
                        this.showModalToDirectorsOrFirstUserAssign();
                    }
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            async saveAssignSecondUserRequest(content, userId) {
                const comment = {};
                comment.user_id = this.authUser.id;
                comment.request_user_id = userId || this.authUser.id;
                comment.content = content;
                comment.lead_id = this.contextLead.id;
                comment.alert_users = [];
                comment.alert_sms = false;
                comment.alert_email = false;
                comment.alert = false;
                const commentAdded = await this.$api.comments.store({ comment });

                if (this.authUser.isAdvisor()) {
                    try {
                        this.updateLeadAction(this.contextLead.id, { second_user_id: userId || this.authUser.id });
                        this.$nextTick(() => {
                            this.$eventBus.$emit('update-comment');
                        });
                    } catch (error) {
                        await this.$api.comments.destroy(commentAdded.id);
                    }
                }

                if (this.authUser.isBdc()) {
                    try {
                        this.updateLeadAction(this.contextLead.id, { second_bdc_user_id: userId || this.authUser.id });
                        this.$nextTick(() => {
                            this.$eventBus.$emit('update-comment');
                        });
                    } catch (error) {
                        this.$api.comments.destroy(commentAdded.id);
                    }
                }
            },

            async acceptAssignationUserTransfer() {
                if (this.$wait.is('accepting.assignation')) {
                    return;
                }

                this.$wait.start('accepting.assignation');
                this.assignationUserTransferModalOpened = false;

                switch (this.assignationUserTransferType) {
                    case 'bdc':
                        await this.updateLeadAction(this.contextLead.id, {
                            bdc_user_id: this.contextLead.second_bdc_user_id,
                            second_bdc_user_id: null,
                        });
                        break;

                    case 'advisor':
                        await this.updateLeadAction(this.contextLead.id, {
                            user_id: this.contextLead.second_user_id,
                            second_user_id: null,
                        });
                        break;
                }

                this.$nextTick(() => {
                    this.$wait.end('accepting.assignation');
                    this.assignationUserTransferType = '';
                });
            },

            async declineAssignationUserTransfer() {
                this.assignationUserTransferModalOpened = false;

                switch (this.assignationUserTransferType) {
                    case 'bdc':
                        this.updateLeadAction(this.contextLead.id, { second_bdc_user_id: null });
                        break;

                    case 'advisor':
                        await this.updateLeadAction(this.contextLead.id, { second_user_id: null });
                        break;
                }

                this.$nextTick(() => {
                    this.assignationUserTransferType = '';
                });
            },

            cancelAssignationUserTransfer() {
                this.assignationUserTransferModalOpened = false;
                this.assignationUserTransferType = '';
            },

            shouldShowModalToDirectorsOrFirstUserAssign(type) {
                if (
                    this.shouldShowReassignationModalToDirector ||
                    this.authUser.isAdmin() ||
                    (type == 'advisor' &&
                        this.contextLead.user_id == this.authUser.id &&
                        this.advisorHasDoubleAssignation) ||
                    (type == 'bdc' && this.contextLead.bdc_user_id == this.authUser.id && this.bdcHasDoubleAssignation)
                ) {
                    this.$nextTick(() => {
                        this.assignationUserTransferModalOpened = true;
                        this.assignationUserTransferType = type;
                    });
                }
            },

            openAssignationUserRequestModal(type) {
                if (
                    (!this.contextLead.second_user_id && this.displayAdvisorReassignation && type == 'advisor') ||
                    (!this.contextLead.second_bdc_user_id && this.displayBdcReassignation && type == 'bdc')
                ) {
                    this.assignationUserRequestType = type;
                    this.assignationUserRequestModalOpened = true;
                }

                if (this.contextLead.second_user_id || this.contextLead.second_bdc_user_id) {
                    this.shouldShowModalToDirectorsOrFirstUserAssign(type);
                }
            },

            isSetBySerti(field) {
                if (!this.contextLead.isService() || this.contextLead.created_method != 'serti') {
                    return false;
                }

                if (
                    !empty(this.contextLead.serti_imported_fields) &&
                    Array.isArray(this.contextLead.serti_imported_fields) &&
                    this.contextLead.serti_imported_fields.includes(field)
                ) {
                    return true;
                }

                return false;
            },

            updateModalFromQuery() {
                const assignementRequest = this.$route.query.assignementRequest;

                if (!assignementRequest) {
                    return;
                }

                if (assignementRequest == 'advisor' && this.assignationModalTypeIsAdvisor) {
                    this.openAssignationUserRequestModal('advisor');
                }

                if (assignementRequest == 'bdc' && this.assignationModalTypeIsBdc) {
                    this.openAssignationUserRequestModal('bdc');
                }

                this.$router.replace({ query: null });
            },

            showModalToDirectorsOrFirstUserAssign() {
                let type = '';

                if (this.assignationModalTypeIsAdvisor) {
                    type = 'advisor';
                }

                if (this.assignationModalTypeIsBdc) {
                    type = 'bdc';
                }

                if (!type) {
                    return;
                }

                this.shouldShowModalToDirectorsOrFirstUserAssign(type);
            },

            openSelectAdvisor() {
                if (this.$refs.selectAdvisor) {
                    this.$refs.selectAdvisor.triggerEditing();
                }
            },
        },

        async mounted() {
            await this.$nextTick();

            this.updateModalFromQuery();

            this.showModalToDirectorsOrFirstUserAssign();
        },

        created() {
            this.$eventBus.$on('select-advisor', this.openSelectAdvisor);
        },

        beforeDestroy() {
            this.$eventBus.$off('select-advisor', this.openSelectAdvisor);
        },
    };
</script>
