<template>
    <div class="flex items-center">
        <activix-checkbox
            :color="checkboxColor"
            :disabled="isCheckboxDisabled"
            :size="checkboxSize"
            :value="isChecked"
            @click.native.prevent="onClick"
        />

        <span
            class="editable | ml-4"
            :class="{
                'disabled': isDateDisabled,
                'text-gray-500': isDateDisabled || humanShortDate === placeholder
            }"
            @click="onDateClick"
        >
            {{ humanShortDate }}
        </span>

        <slot name="label-suffix" />
    </div>
</template>

<script>
    import ActivixDate from '@/value-objects/ActivixDate';

    export default {
        props: {
            checkboxColor: {
                type: String,
            },
            checkboxDisabled: {
                type: Boolean,
                default: false,
            },
            checkboxSize: {
                type: String,
                default: 'lg',
                validator: value => ['xs', 'sm', 'md', 'lg'].includes(value),
            },
            date: {
                required: true,
                validator: prop => ['string', 'undefined'].includes(typeof prop) || prop === null,
            },
            dateDisabled: {
                type: Boolean,
                default: false,
            },
            dateOnly: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: '---',
            },
        },

        computed: {
            isChecked() {
                return !!this.date;
            },

            isCheckboxDisabled() {
                return this.disabled || this.checkboxDisabled;
            },

            isDateDisabled() {
                return this.disabled || this.dateDisabled;
            },

            humanShortDate() {
                const humanShortDate = new ActivixDate(this.date).toHumanShort(!this.dateOnly);

                return humanShortDate || this.placeholder;
            },
        },

        methods: {
            onClick() {
                if (this.isCheckboxDisabled) {
                    return;
                }

                this.$emit('check', !this.isChecked);
            },

            onDateClick() {
                if (this.isDateDisabled) {
                    return;
                }

                this.$emit('date-click');
            },
        },
    };
</script>
