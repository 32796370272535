<template>
    <div class="flex items-center">
        <div class="flex-1 overflow-hidden">
            <div class="text-gray-600 tracking-wider mb-1" v-text="historyItem.date" v-if="historyItem.date" />
            <div class="flex items-center space-x-3">
                <img
                    alt="Favicon"
                    class="text-md text-gray-600 h-[16px] w-[16px]"
                    :src="favicon"
                    @load="faviconIsLoaded = true"
                    @error="faviconIsLoaded = false"
                    v-show="faviconIsLoaded"
                    v-if="favicon"
                />
                <icon
                    name="regular/earth-1"
                    class="flex-shrink-0 h-[16px] w-[16px] text-gray-600"
                    v-else-if="isUrl"
                />
                <icon
                    name="regular/list-bullets"
                    class="flex-shrink-0 h-[16px] w-[16px] text-gray-600"
                    v-else
                />

                <a
                    class="link-blue truncate"
                    :href="historyItem.text"
                    target="_blank"
                    v-text="historyItem.text"
                    v-if="isUrl"
                />
                <div
                    class="truncate"
                    v-text="historyItem.text"
                    v-else
                />
            </div>
        </div>
        <activix-tooltip :content="$t('clientCard.clickOpenNewTab')" v-if="isUrl">
            <activix-button
                tag="a"
                :href="historyItem.text"
                target="_blank"
                class="ml-3"
            >
                <icon name="regular/expand-6" />
            </activix-button>
        </activix-tooltip>
    </div>
</template>

<script>

    export default {
        props: {
            historyItem: {
                type: Object,
                default: () => {},
                required: true,
            },
        },

        data: () => ({
            faviconIsLoaded: false,
        }),

        computed: {
            isUrl() {
                return this.historyItem.text.startsWith('http');
            },

            favicon() {
                if (!this.isUrl) {
                    return '';
                }

                const baseUrl = new URL(this.historyItem.text);

                return `http://www.google.com/s2/favicons?domain=${baseUrl.origin}`;
            },
        },
    };
</script>
