/* eslint-disable camelcase */
import emojione from 'emojione';
import { formatPhone } from '@/utils/index.js';
import { timeHms } from '@/utils/time.js';
import { maskEmail, maskPhone } from '@/utils/mask.js';

import ActivixDate from '@/value-objects/ActivixDate.js';
import CommunicationType from '@/entities/CommunicationType.js';
import CommunicationStatus from '@/entities/CommunicationStatus';
import VideoRecordingStatus from '@/entities/VideoRecordingStatus';
import VideoConferenceStatus from '@/entities/VideoConferenceStatus';
import Model from './Model.js';
import User from './User.js';
import CommunicationMethod from './CommunicationMethod.js';

export default class Communication extends Model {
    static get defaults() {
        return {
            lead_id: null,
            user_id: null,
        };
    }

    set attachments(attachments) {
        this._attachments = attachments || [];
    }

    set guests(guests) {
        this._guests = guests || [];
    }

    set user(user) {
        this._user = new User(user);
    }

    get attachments() {
        return this._attachments || [];
    }

    get guests() {
        return this._guests || [];
    }

    get user() {
        return this._user || new User();
    }

    get isEmail() {
        return this.communication_method_id === CommunicationMethod.EMAIL;
    }

    get isMessenger() {
        return this.communication_method_id === CommunicationMethod.MESSENGER;
    }

    get isPhone() {
        return this.communication_method_id === CommunicationMethod.PHONE;
    }

    get isSms() {
        return this.communication_method_id === CommunicationMethod.SMS;
    }

    get isVideo() {
        return this.communication_method_id === CommunicationMethod.VIDEO;
    }

    get isIncoming() {
        return this.communication_type_id === CommunicationType.INCOMING;
    }

    get isOutgoing() {
        return this.communication_type_id === CommunicationType.OUTGOING;
    }

    get isVideoConferenceOver() {
        return ![CommunicationStatus.PENDING, CommunicationStatus.ATTEMPTED, CommunicationStatus.CALLING].includes(this.status);
    }

    get formattedCreatedAt() {
        if (this.original_created_at) {
            return new ActivixDate(this.original_created_at).toHumanShort();
        }

        return new ActivixDate(this.created_at).toHumanShort();
    }

    get formattedDuration() {
        if (!this.duration_call) {
            return '';
        }

        return timeHms(this.duration_call);
    }

    get formattedDurationReached() {
        if (!this.duration_reached || this.duration_reached === 'N/A') {
            return '';
        }

        return timeHms(this.duration_reached);
    }

    get formattedEmailReadOn() {
        if (!this.isEmail || !this.isOutgoing || !this.email_read_at) {
            return '';
        }

        const readOn = new ActivixDate(this.email_read_at);

        return readOn.formatDistanceToNow();
    }

    get formattedExcerpt() {
        if (this.isEmail && this.email_body) {
            const parser = new DOMParser().parseFromString(this.email_body, 'text/html');
            const content = parser.body.textContent || '';
            return content.replace(/\u00a0/g, ' ');
        }

        if (this.isSms && this.excerpt) {
            return emojione.shortnameToImage(this.excerpt);
        }

        return this.excerpt;
    }

    get formattedPhone() {
        return formatPhone(this.phone, true);
    }

    get formattedUrl() {
        if (!this.url) {
            return '';
        }

        if (!this.url.endsWith('.wav') && !this.url.endsWith('.mp3')) {
            return `${this.url}.wav`;
        }

        return this.url;
    }

    get maskedClientEmail() {
        if (!this.client_email) {
            return '';
        }

        return maskEmail(this.client_email);
    }

    get maskedPhone() {
        if (!this.phone) {
            return '';
        }

        return maskPhone(this.formattedPhone);
    }

    get videoConferenceStatus() {
        if (!this.isVideo) {
            return null;
        }

        if (this.isVideoConferenceOver) {
            return VideoConferenceStatus.OVER;
        }

        if (this.status === CommunicationStatus.CALLING) {
            return VideoConferenceStatus.LIVE;
        }

        return VideoConferenceStatus.PLANNED;
    }

    get videoRecordingStatus() {
        if (!this.isVideo) {
            return null;
        }

        if (!this.video_room.allow_recording) {
            return VideoRecordingStatus.NOT_RECORDED;
        }

        if (!this.isVideoConferenceOver) {
            return VideoRecordingStatus.PENDING;
        }

        if ([CommunicationStatus.INTERRUPTED, CommunicationStatus.UNANSWERED].includes(this.status)) {
            return VideoRecordingStatus.NOT_RECORDED;
        }

        if (this.url) {
            return VideoRecordingStatus.RECORDING_AVAILABLE;
        }

        return VideoRecordingStatus.PROCESSING;
    }
}
