<template>
    <activix-tabs-mobile class="flex flex-col w-full mt-2" toggle-class="border-t-2 border-b-2 border-grey-400">
        <activix-tab-mobile :name="ClientCardTabs.GENERAL" icon="regular/information-circle">
            <div class="box | mb-6">
                <div class="box-header">
                    <h4 class="box-title">
                        {{ $t('clientCard.assignationsAndDates') }}
                    </h4>
                </div>
                <div class="box-body | pt-0" :class="{ 'pb-2': mdLayout }">
                    <div class="flex flex-wrap -mx-3">
                        <assign-info class="w-full px-3 md:w-1/2" />

                        <time-info class="w-full px-3 md:w-1/2" />
                    </div>
                </div>
            </div>

            <general-info />

            <communications-box />

            <service-product v-if="contextLead.isService()" />
            <commercial-director v-else />

            <history />
        </activix-tab-mobile>
        <activix-tab-mobile :name="ClientCardTabs.VEHICLES" icon="regular/car-1">
            <activix-tabs-mobile class="bg-white pt-6" toggle-class="mx-6" inactive-background="bg-grey-300">
                <activix-tab-mobile :name="$t('searchLead.wanted')">
                    <vehicle-carousel type="wanted" />
                </activix-tab-mobile>

                <activix-tab-mobile :name="$t('searchLead.exchange')">
                    <vehicle-carousel type="exchange" />
                </activix-tab-mobile>
            </activix-tabs-mobile>
        </activix-tab-mobile>
        <activix-tab-mobile :name="ClientCardTabs.PERFORMANCES" icon="regular/check-2">
            <template v-if="contextLead.isService()">
                <revival-service />
                <process-service />
            </template>

            <template v-else>
                <performance />
                <process />
                <revival-service v-if="hasEquityAlert" />
            </template>
        </activix-tab-mobile>
        <activix-tab-mobile :name="ClientCardTabs.NOTES" icon="regular/notes-book-text">
            <comment-box />
        </activix-tab-mobile>
        <activix-tab-mobile :name="ClientCardTabs.CALENDAR" icon="regular/calendar-favorite">
            <div class="box | mb-6">
                <calendar
                    class="box-body | min-h-screen-75 lg:min-h-0 px-3 py-6"
                    :current-lead="contextLead"
                />
            </div>
        </activix-tab-mobile>
    </activix-tabs-mobile>
</template>

<script>
    import { mapState } from 'pinia';
    import ActivixTabMobile from '@/components/elements/ActivixTabMobile.vue';
    import AssignInfo from './AssignInfo.vue';
    import TimeInfo from './TimeInfo.vue';
    import RevivalService from './RevivalService.vue';
    import ProcessService from './ProcessService.vue';
    import Performance from './Performance.vue';
    import Process from './Process.vue';
    import GeneralInfo from './GeneralInfo.vue';
    import VehicleCarousel from './VehicleCarousel.vue';
    import CommentBox from '../comment/CommentBox.vue';
    import Calendar from './Calendar.vue';
    import CommunicationsBox from './CommunicationsBox.vue';
    import ServiceProduct from './ServiceProduct.vue';
    import CommercialDirector from './CommercialDirector.vue';
    import History from './History.vue';
    import ClientCardTabs from '../../entities/ClientCardTabs.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            ActivixTabMobile,
            AssignInfo,
            TimeInfo,
            History,
            CommercialDirector,
            ServiceProduct,
            CommunicationsBox,
            Calendar,
            CommentBox,
            VehicleCarousel,
            GeneralInfo,
            Process,
            Performance,
            ProcessService,
            RevivalService,
        },

        data() {
            return {
                ClientCardTabs,
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),

            hasEquityAlert() {
                return this.contextLead.account.equity_alert;
            },
        },
    };
</script>
