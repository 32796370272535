<template functional>
    <div
        class="min-w-36 pr-3 align-middle whitespace-nowrap text-md text-grey-700 font-bold | md:text-base lg:min-w-0"
        :class="[data.staticClass, { 'table-cell': !props.inline }]"
    >
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            inline: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
