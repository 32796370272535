<template>
    <span class="whitespace-nowrap">
        {{ output }}
    </span>
</template>

<script>
    export default {
        props: {
            fallback: {
                type: String,
                default: '',
            },
            maxDays: {
                type: Number,
                default: 90,
            },
            maxHours: {
                type: Number,
                default: 72,
            },
            seconds: {
                required: true,
                validator: prop => typeof prop === 'number' || prop === null,
            },
        },

        computed: {
            output() {
                if (!this.seconds && this.seconds !== 0) {
                    return this.fallback;
                }

                if (this.seconds < 60) {
                    return `${this.seconds} ${this.$t('delayUnits.abbreviations.seconds')}`;
                }

                const minutes = Math.round(this.seconds / 60);

                if (minutes > 0 && minutes < 60) {
                    return `${minutes} ${this.$t('delayUnits.abbreviations.minutes')}`;
                }

                const hours = Math.floor(this.seconds / 3600);

                if (hours > 0 && hours < this.maxHours) {
                    const remainingSeconds = this.seconds - (hours * 3600);
                    const remainingMinutes = Math.round(remainingSeconds / 60);
                    const minutesFormatted = String(remainingMinutes).padStart(2, '0');

                    return `${hours}${this.$t('delayUnits.abbreviations.hours')}${minutesFormatted}`;
                }

                const days = Math.floor(this.seconds / 86400);

                if (days >= this.maxDays) {
                    return `> ${this.maxDays} ${this.$t('delayUnits.abbreviations.days')}`;
                }

                return `${days} ${this.$t('delayUnits.abbreviations.days')}`;
            },
        },
    };
</script>
