import Enum from './Enum.js';

export default class LegacyLeadVehicleWeight extends Enum {
    static get entries() {
        return {
            under_3000: '0 - 3000',
            between_3000_5000: '3000 - 5000',
            over_5000: '5000 +',
        };
    }

    static get UNDER_3000() {
        return this.entries.under_3000;
    }

    static get BETWEEN_3000_5000() {
        return this.entries.between_3000_5000;
    }

    static get OVER_5000() {
        return this.entries.over_5000;
    }
}
