<template>
    <lead-input
        :disabled="disabled"
        :center-icon="centerIcon"
        :editing="editing"
        @accept="accept"
        @cancel="cancel"
        @edit="editField"
        @blur="onBlur"
    >
        <activix-input
            type="text"
            class="w-1/2"
            :input-class="['input-sm', { 'p-0 border-none bg-transparent': lgLayout }]"
            :placeholder="$t('clientCard.firstName')"
            :disabled="disabled"
            ref="firstName"
            v-model="firstName"
        />
        <activix-input
            type="text"
            class="w-1/2"
            :input-class="['input-sm', { 'p-0 border-none bg-transparent': lgLayout }]"
            :placeholder="$t('clientCard.lastName')"
            :disabled="disabled"
            ref="lastName"
            v-model="lastName"
        />

        <template #value>
            <span class="mr-2" v-text="prefix" v-if="prefix" />
            <span v-text="empty(fullName) ? '...' : fullName" />
        </template>
    </lead-input>
</template>

<script>
    // Components
    import { mapActions } from 'pinia';
    import LeadInput from '../LeadInput.vue';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        components: {
            LeadInput,
        },

        props: {
            name: String,
            prefix: String,
            value: Object,
            disabled: Boolean,
            recordId: Number,
            centerIcon: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                firstName: '',
                lastName: '',
                editing: false,
            };
        },

        computed: {
            fullName() {
                return `${this.firstName} ${this.lastName}`.trim();
            },
        },

        watch: {
            'value.first_name'() {
                this.setValue();
            },

            'value.last_name'() {
                this.setValue();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            async editField() {
                if (this.disabled) {
                    return;
                }

                this.editing = true;

                await this.$nextTick();

                $(this.$refs.firstName).focus();
            },

            async accept() {
                if (!this.editing) {
                    return;
                }

                this.editing = false;

                // Only update if value changed and recordId is set
                if (
                    empty(this.recordId) ||
                    (this.firstName == this.value.first_name && this.lastName == this.value.lastName)
                ) {
                    return;
                }

                await this.updateLeadAction(this.recordId, {
                    first_name: this.firstName,
                    last_name: this.lastName,
                });
            },

            cancel() {
                this.editing = false;
            },

            setValue() {
                if (empty(this.value)) {
                    this.firstName = '';
                    this.lastName = '';
                    return;
                }

                this.firstName = this.value.first_name || '';
                this.lastName = this.value.last_name || '';
            },

            onBlur() {
                // Delay is necessary to prevent event overlapping
                setTimeout(() => {
                    this.accept();
                }, 50);
            },
        },

        created() {
            this.setValue();
        },
    };
</script>
