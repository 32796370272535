<template>
    <div class="flex items-center">
        <lead-input
            :disabled="disabled"
            :center-icon="centerIcon"
            :editing="editing"
            :show-buttons="!lgLayout"
            @accept="triggerAccept"
            @cancel="cancel"
            @edit="editField"
            @blur="onBlur"
        >
            <activix-multiselect
                identifier="value"
                label="text"
                size="small"
                :allow-empty="allowEmpty"
                :automatic-dropdown="true"
                :automatic-focus="true"
                :automatic-render="true"
                :clearable="true"
                :external-trigger="true"
                :multiple="multiple"
                :name="name"
                :options="currentOptions"
                :searchable="isSearchable"
                :selected="currentValue"
                :taggable="taggable"
                :limit-selected-visible="1"
                @cancel="cancel"
                @update="onUpdate"
            />

            <template #value>
                {{ formatedCurrentValue }}
            </template>
            <template #right>
                <slot name="right" />
            </template>
        </lead-input>
    </div>
</template>

<script>
    // Utils
    import { isArray, cloneDeep, get } from 'lodash-es';
    import { mapActions } from 'pinia';
    // Components
    import LeadInput from '../LeadInput.vue';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        components: {
            LeadInput,
        },

        props: {
            allowEmpty: {
                type: Boolean,
                default: true,
            },
            disabled: Boolean,
            multiple: Boolean,
            name: String,
            options: [Object, Array],
            placeholder: String,
            recordId: {
                type: Number,
                required: true,
            },
            openTop: {
                type: Boolean,
                default: false,
            },
            hideEmptyOption: {
                type: Boolean,
                default: false,
            },
            searchable: {
                type: Boolean,
                default: false,
            },
            taggable: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                default: 'lead',
            },
            validate: {
                type: Function,
                default: () => {
                    return true;
                },
            },
            customField: {
                type: Object,
                default: null,
            },
            value: [String, Number, Array],
            centerIcon: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                currentValue: {},
                editing: false,
                triggerFocus: false,
                originalValue: {},
            };
        },

        computed: {
            formatedCurrentValue() {
                let formatedValue = '';

                if (isArray(this.currentValue)) {
                    for (let i = 0; i < this.currentValue.length; i++) {
                        formatedValue += this.currentValue[i].text;
                        formatedValue += i != this.currentValue.length - 1 && this.currentValue.length > 1 ? ', ' : '';
                    }

                    return formatedValue || '...';
                }

                return this.currentValue ? this.currentValue.text : '...';
            },

            currentOptions() {
                const options = cloneDeep(this.options) || [];

                if (
                    this.allowEmpty &&
                    !this.multiple &&
                    !options.some(option => !option.value) &&
                    !this.hideEmptyOption
                ) {
                    options.unshift({
                        value: '',
                        text: '...',
                    });
                }

                if (this.value && isArray(this.value)) {
                    this.value.forEach(value => {
                        if (!options.find(option => option.value == value.value)) {
                            const pos = options.some(option => !option.value) ? 1 : 0;

                            options.splice(pos, 0, { value: value.value, text: value.text });
                        }
                    });
                } else if (this.value && !options.find(option => option.value == this.value)) {
                    const pos = options.some(option => !option.value) ? 1 : 0;

                    options.splice(pos, 0, { value: this.value, text: this.value });
                }

                return options;
            },

            isSearchable() {
                return this.searchable || this.currentOptions.length > 10;
            },
        },

        watch: {
            value() {
                this.setCurrentValue();
            },

            currentOptions() {
                this.setCurrentValue();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'updateLeadVehicleAction']),

            onUpdate(value) {
                this.currentValue = value;
                this.$emit('update', value);

                if (!this.multiple) {
                    this.triggerAccept();
                }
            },

            cancel() {
                this.currentValue = this.originalValue;
                this.editing = false;
            },

            editField() {
                if (this.disabled) {
                    return;
                }

                this.originalValue = this.currentValue;
                this.editing = true;
            },

            setCurrentValue() {
                if (isArray(this.value)) {
                    const tmpCurrentOptions = [];
                    this.value.forEach(option => {
                        const tmpOption = this.currentOptions.find(o => o.value == option.value);
                        if (tmpOption) {
                            tmpCurrentOptions.push(tmpOption);
                        }
                    });
                    this.currentValue = tmpCurrentOptions;
                } else {
                    this.currentValue = this.currentOptions.find(o => o.value == this.value);
                }
            },

            triggerAccept() {
                if (!this.editing) {
                    return;
                }

                this.editing = false;

                if (!this.validateData()) {
                    return;
                }

                this.updateRecord();
            },

            async updateRecord() {
                const selectList = [];
                let value = null;

                if (isArray(this.currentValue)) {
                    this.currentValue.forEach(c => {
                        selectList.push(get(c, 'value'));
                    });
                    value = selectList;
                } else {
                    value = get(this.currentValue, 'value') || null;
                }

                let options = {};
                const payload = {
                    [this.name]: value,
                };

                if (this.customField) {
                    options = {
                        customField: { ...this.customField, value },
                    };
                }

                try {
                    if (this.type == 'vehicle') {
                        await this.updateLeadVehicleAction(payload, this.recordId, null, options);
                    } else {
                        await this.updateLeadAction(this.recordId, payload, options);
                    }
                } catch (e) {
                    console.error(e);
                    this.setCurrentValue();
                }
            },

            validateMultiple() {
                if (isArray(this.currentValue)) {
                    this.currentValue.forEach(c => {
                        if (c.value == this.value) {
                            return true;
                        }
                        return false;
                    });
                } else if (this.currentValue.value == this.value) {
                    return true;
                }

                return false;
            },

            validateData() {
                if (
                    !this.recordId ||
                    (!empty(this.currentValue) && this.validateMultiple()) ||
                    (empty(this.currentValue) && empty(this.value))
                ) {
                    return false;
                }

                if (!empty(this.currentValue) && !this.validate(this.currentValue.value)) {
                    this.setCurrentValue();
                    return false;
                }

                return true;
            },

            onBlur() {
                if (this.mdLayout) {
                    return;
                }

                this.triggerAccept();
            },

            triggerEditing() {
                this.editing = true;
            },
        },

        created() {
            this.setCurrentValue();
        },
    };
</script>
