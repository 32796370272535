<template>
    <div class="flex items-center" :class="{ loading: !contextLead.id }">
        <activix-tooltip :content="phoneTooltip || leadIsAlreadyCommunicatingTooltip">
            <activix-round-button
                class="h-11 w-11"
                :active="contextLead.communication_preference == 'phone'"
                :disabled="phoneIsDisabled || leadIsAlreadyCommunicating"
                @click="onPhoneClick"
            >
                <icon class="text-xl" :name="$icons.phone" />
            </activix-round-button>
        </activix-tooltip>

        <activix-tooltip :content="emailTooltip || leadIsAlreadyCommunicatingTooltip">
            <activix-round-button
                class="h-11 w-11 ml-3"
                :active="contextLead.communication_preference == 'email'"
                :disabled="emailIsDisabled || leadIsAlreadyCommunicating"
                @click="onEmailClick"
            >
                <icon class="text-xl" :name="$icons.email" />
            </activix-round-button>
        </activix-tooltip>

        <activix-tooltip :content="smsTooltip || leadIsAlreadyCommunicatingTooltip" v-if="hasNioText">
            <activix-round-button
                class="h-11 w-11 ml-3"
                :active="contextLead.communication_preference == 'sms'"
                :disabled="smsIsDisabled || leadIsAlreadyCommunicating"
                @click="onSmsClick"
            >
                <icon class="text-xl" :name="$icons.sms" />
            </activix-round-button>
        </activix-tooltip>

        <activix-tooltip :content="videoTooltip || leadIsAlreadyCommunicatingTooltip">
            <activix-round-button
                class="h-11 w-11 ml-3"
                :disabled="videoIsDisabled || leadIsAlreadyCommunicating"
                :data-intercom-target="videoIntercomLabel"
                @click="onVideoClick"
            >
                <icon class="text-xl" :name="$icons.camera" />
            </activix-round-button>
        </activix-tooltip>

        <call-lead name="lead:callLead" />
        <email-lead name="lead:emailLead" />
        <videoconference-invitation name="lead:videoconferenceInvitation" />
    </div>
</template>

<script>
    import ActivixDate from '@/value-objects/ActivixDate.js';
    import LeadCommunicating from '@/mixins/LeadCommunicating.js';

    import { mapState } from 'pinia';

    // Components
    import CallLead from '../modals/CallLead.vue';
    import EmailLead from '../modals/EmailLead.vue';
    import VideoconferenceInvitation from '../modals/VideoconferenceInvitation.vue';

    // Mixins
    import LeadPhoneValidation from '../../mixins/LeadPhoneValidation.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useClientCardStore } from '../../store/modules/clientCard/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            CallLead,
            EmailLead,
            VideoconferenceInvitation,
        },

        mixins: [LeadPhoneValidation, LeadCommunicating],

        computed: {
            ...mapState(useClientCardStore, ['authorizedAction']),
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),
            ...mapState(useGlobalStore, ['authUser', 'guest', 'communicatingLead']),

            customerConsentInactive() {
                return this.$feature.isEnabled('casl_compliance') && !this.contextLead.customer.consentAllowsContact;
            },

            hasNioText() {
                return this.contextAccount.hasNioText() && this.authUser.hasNioText();
            },

            hasVideoconference() {
                return this.contextAccount.video_conference;
            },

            hasValidEmail() {
                return this.contextLead.lead_emails.some(email => email.valid);
            },

            hasValidPhone() {
                return this.contextLead.phones.some(phone => phone.valid);
            },

            hasValidMobilePhone() {
                return !!this.contextLead.getMobilePhones().length;
            },

            tooltipUnsubscribeAll() {
                if (!this.contextLead.unsubscribe_all_date) {
                    return '';
                }

                const unsubscribeAllDate = new ActivixDate(this.contextLead.unsubscribe_all_date);

                return unsubscribeAllDate.year === 2222
                    ? this.$t('clientCard.clientIsAllUnsubscribedForever')
                    : this.$t('clientCard.clientIsAllUnsubscribed', [unsubscribeAllDate.toHumanShort(true)]);
            },

            actionIsDisabled() {
                return !!this.contextLead.unsubscribe_all_date || ((this.guest || !this.contextAccount.access_all_leads_communications) && !this.authorizedAction);
            },

            phoneIsDisabled() {
                return (
                    this.actionIsDisabled ||
                    !!this.contextLead.unsubscribe_call_date ||
                    !this.hasValidPhone ||
                    !this.contextAccount.outgoingPhoneProviders.length
                );
            },

            phoneTooltip() {
                if (this.tooltipUnsubscribeAll) {
                    return this.tooltipUnsubscribeAll;
                }

                if (this.contextLead.unsubscribe_call_date) {
                    return this.$t('clientCard.clientIsCallUnsubscribed');
                }

                if (!this.hasValidPhone) {
                    return this.$t('client.noPhone');
                }

                if (this.contextLead.communication_preference === 'phone') {
                    return this.$t('clientCard.communicationPreferenceTooltip');
                }

                if (this.actionIsDisabled) {
                    return this.$t('clientCard.leadAlreadyAssigned');
                }

                return '';
            },

            emailIsDisabled() {
                return this.actionIsDisabled ||
                    !!this.contextLead.unsubscribe_email_date ||
                    !this.hasValidEmail ||
                    this.customerConsentInactive;
            },

            emailTooltip() {
                if (this.tooltipUnsubscribeAll) {
                    return this.tooltipUnsubscribeAll;
                }

                if (this.contextLead.unsubscribe_email_date) {
                    return this.$t('clientCard.clientIsEmailUnsubscribed');
                }

                if (this.customerConsentInactive) {
                    return `${this.$t('consent.consent')} ${this.$t('consent.statuses.expired').toLowerCase()}`;
                }

                if (!this.hasValidEmail) {
                    return this.$t('client.noEmail');
                }

                if (this.contextLead.communication_preference === 'email') {
                    return this.$t('clientCard.communicationPreferenceTooltip');
                }

                if (this.actionIsDisabled) {
                    return this.$t('clientCard.leadAlreadyAssigned');
                }

                return '';
            },

            smsIsDisabled() {
                return (
                    this.customerConsentInactive ||
                    this.actionIsDisabled ||
                    !this.hasNioText ||
                    (!this.hasValidatedMobile(this.contextLead) && !this.hasNotValidatedPhone(this.contextLead)) ||
                    this.$wait.is('validating.leadPhones')
                );
            },

            smsTooltip() {
                if (this.tooltipUnsubscribeAll) {
                    return this.tooltipUnsubscribeAll;
                }

                if (this.customerConsentInactive) {
                    return `${this.$t('consent.consent')} ${this.$t('consent.statuses.expired').toLowerCase()}`;
                }

                if (this.$wait.is('validating.leadPhones')) {
                    return this.$t('clientCard.validating');
                }

                if (!this.hasValidatedMobile(this.contextLead) && !this.hasNotValidatedPhone(this.contextLead)) {
                    return this.$t('client.noMobilePhone');
                }

                if (this.contextLead.communication_preference === 'sms') {
                    return this.$t('clientCard.communicationPreferenceTooltip');
                }

                if (this.actionIsDisabled) {
                    return this.$t('clientCard.leadAlreadyAssigned');
                }

                return '';
            },

            videoIsDisabled() {
                return (
                    this.authUser.isAdmin() ||
                    this.$auth.impersonating() ||
                    !this.$auth.audienceIsInSync() ||
                    this.actionIsDisabled ||
                    !!this.contextLead.unsubscribe_call_date ||
                    !this.hasVideoconference ||
                    !this.hasValidEmail ||
                    !this.contextLead.fullName ||
                    this.customerConsentInactive
                );
            },

            videoTooltip() {
                if (this.authUser.isAdmin()) {
                    return this.$t('communications.videoconference.adminUnauthorized');
                }

                if (this.$auth.impersonating()) {
                    return this.$t('communications.videoconference.impersonateUnauthorized');
                }

                if (!this.hasVideoconference) {
                    return this.$t('communications.videoconference.needVideoconference');
                }

                if (!this.$auth.audienceIsInSync()) {
                    return this.$t('communications.videoconference.needToReconnect');
                }

                if (this.tooltipUnsubscribeAll) {
                    return this.tooltipUnsubscribeAll;
                }

                if (this.contextLead.unsubscribe_call_date) {
                    return this.$t('clientCard.clientIsCallUnsubscribed');
                }

                if (this.customerConsentInactive) {
                    return `${this.$t('consent.consent')} ${this.$t('consent.statuses.expired').toLowerCase()}`;
                }

                if (!this.hasValidEmail) {
                    return this.$t('communications.videoconference.invitation.noContactInfo');
                }

                if (!this.contextLead.fullName) {
                    return this.$t('communications.videoconference.needClientName');
                }

                if (this.actionIsDisabled) {
                    return this.$t('clientCard.leadAlreadyAssigned');
                }

                return '';
            },

            videoIntercomLabel() {
                if (this.videoIsDisabled) {
                    return 'videoconference.disable';
                }

                return 'videoconference.call';
            },

            leadIsAlreadyCommunicating() {
                return !!this.communicatingLead;
            },

            leadIsAlreadyCommunicatingTooltip() {
                if (!this.leadIsAlreadyCommunicating) {
                    return '';
                }

                return this.getLeadCommunicatingMessage(this.communicatingLead);
            },
        },

        methods: {
            onPhoneClick() {
                if (this.phoneIsDisabled) {
                    return;
                }

                this.$modal.show('lead:callLead', this.contextLead.id);
            },

            onEmailClick() {
                if (this.emailIsDisabled) {
                    return;
                }

                this.$modal.show('lead:emailLead', this.contextLead.id);
            },

            onSmsClick() {
                if (this.smsIsDisabled) {
                    return;
                }

                this.$eventBus.$emit('open-sms-modal', { leadId: this.contextLead.id });
            },

            onVideoClick() {
                if (this.videoIsDisabled) {
                    return;
                }

                this.$behavior.track('VideoConference', { action: 'call', location: 'lead' });

                this.$modal.show('lead:videoconferenceInvitation', this.contextLead.id);
            },
        },
    };
</script>
