<template>
    <li class="flex items-stretch gap-3 | group">
        <div class="relative">
            <figure class="relative flex-shrink-0 flex justify-center items-center w-12 h-12 p-3 rounded-full bg-gray-200 z-10">
                <icon :name="icon" />
            </figure>

            <div class="flex justify-center h-full w-full absolute inset-0 mt-6 pointer-events-none" v-if="showAnchorLine">
                <span class="w-2px bg-gray-300" />
            </div>
        </div>

        <div class="flex-1 flex flex-col justify-center min-w-0">
            <p class="mb-0">
                <strong class="font-semibold">
                    {{ userName }}
                </strong>
                <span>
                    {{ fieldAction }}
                </span>

                <template v-if="isAssociation">
                    <router-link
                        :to="{ name: 'leads.update', params: { id: item.relevant_data } }"
                        v-text="fieldName"
                    />
                </template>
                <strong class="text-base font-semibold font-sans" v-else>
                    {{ fieldName }}
                </strong>

                <span v-if="fieldModel">
                    {{ fieldModel }}
                </span>
            </p>

            <div class="flex-shrink-0 flex gap-1.5 | sm:hidden">
                <activix-tooltip :content="`${formattedDate} ${formattedTime}`">
                    <span class="mb-0 text-right text-gray-600 text-sm">
                        {{ formattedDateTime }}
                    </span>
                </activix-tooltip>
            </div>

            <div class="flex flex-wrap items-center gap-1.5 mt-3 break-words" v-if="oldValue || newValue">
                <samp
                    class="inline-block min-w-0 px-1.5 py-0.5 border border-gray-400 rounded-sm text-base text-gray-600 font-monospace bg-gray-150"
                    v-if="oldValue"
                >
                    <s>
                        {{ oldValue }}
                    </s>
                </samp>

                <samp
                    class="inline-block min-w-0 px-1.5 py-0.5 border border-blue-300 rounded-sm text-base text-blue-700 font-monospace bg-blue-100"
                    v-if="newValue"
                >
                    {{ newValue }}
                </samp>
            </div>
        </div>

        <div class="flex-shrink-0 flex-col items-end justify-start gap-1.5 | hidden sm:flex">
            <activix-tooltip :content="`${formattedDate} ${formattedTime}`">
                <span class="mb-0 text-right text-gray-600 text-sm">
                    {{ formattedDateTime }}
                </span>
            </activix-tooltip>
        </div>
    </li>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import { camelCase, upperFirst } from 'lodash-es';
    import { toCurrency } from '@/utils/numbers.js';

    import CaslConsentType from '@/entities/CaslConsentType';
    import CommentType from '@/entities/CommentType.js';
    import Division from '@/entities/Division';
    import Lead from '@/entities/Lead';
    import LeadType from '@/entities/LeadType.js';
    import Supplier from '@/entities/Supplier';
    import { useContextStore } from '@/store/modules/context/store.js';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
            item: {
                type: Object,
                required: true,
            },
            showAnchorLine: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs', 'usersAndSupplierUsers']),
            ...mapState(useContextStore, { contextAccount: 'account' }),

            camelCaseType() {
                return camelCase(this.item.type);
            },

            fieldGender() {
                if (['Communication', 'Reminder', 'TaskEvent'].includes(this.modelName)) {
                    return 'F';
                }

                return 'M';
            },

            modelCasts() {
                return this.configs.casts[this.modelName] || {};
            },

            modelName() {
                switch (this.item.type) {
                    case 'exchange_vehicle':
                    case 'wanted_vehicle':
                        return 'LeadVehicle';

                    case 'customables':
                        return 'Customable';

                    default:
                        return upperFirst(camelCase(this.item.type));
                }
            },

            newValue() {
                return this.formatValue('new');
            },

            oldValue() {
                return this.formatValue('old');
            },

            isAssociation() {
                return ['customer', 'client', 'eventLeadCreated', 'manualAssociation'].includes(this.item.field_name);
            },

            isBoolean() {
                return this.modelCasts[this.item.field_name] === 'boolean' ||
                    this.isCustomField && this.item.custom_field_type === 'boolean';
            },

            isCaslConsent() {
                return this.modelName === 'Customer' &&
                    this.item.field_name.startsWith('casl_consent');
            },

            isCents() {
                return this.isCustomField && this.item.custom_field_type === 'currency';
            },

            isCommentCase() {
                return this.modelName === 'Comment' &&
                    this.item.relevant_data?.related_id &&
                    this.item.relevant_data?.type === CommentType.CASE;
            },

            isCurrency() {
                return this.modelCasts[this.item.field_name] === 'float';
            },

            isCustomField() {
                return this.modelName === 'Customable';
            },

            isDate() {
                return this.modelCasts[this.item.field_name] === 'date';
            },

            isDatetime() {
                return this.modelCasts[this.item.field_name] === 'datetime' ||
                    this.isCustomField && this.item.custom_field_type === 'datetime';
            },

            isDmsExport() {
                return this.item.field_name === 'exported_suppliers';
            },

            isExported() {
                return this.item.field_name === 'exported';
            },

            isMerge() {
                return this.item.field_name === 'merged';
            },

            isPercent() {
                return this.modelName === 'LeadVehicle' && ['rate'].includes(this.item.field_name);
            },

            isReassignation() {
                return ['second_user', 'second_bdc_user'].includes(this.item.field_name);
            },

            isRenewalCycle() {
                return this.item.field_name === 'renewal_cycle';
            },

            isCreatedOrDeletedField() {
                return ['created_at', 'deleted_at'].includes(this.item.field_name);
            },

            fieldAction() {
                if (this.isDmsExport) {
                    return this.getDmsName;
                }

                if (this.isExported) {
                    return this.exportedAction;
                }

                if (this.isReassignation) {
                    return this.$t('history.assignationDemand');
                }

                if (this.isRenewalCycle) {
                    return this.$t('history.renewalCycle');
                }

                if (this.createdRelationAction) {
                    return this.createdRelationAction;
                }

                if (this.deletedRelationAction) {
                    return this.deletedRelationAction;
                }

                if (this.caslConsentAction) {
                    return this.caslConsentAction;
                }

                if (this.associationAction) {
                    return this.associationAction;
                }

                if (this.mergeAction) {
                    return this.mergeAction;
                }

                if (this.isBoolean) {
                    return this.$t('history.actions.updated');
                }

                if (empty(this.item.old_value)) {
                    return this.$t('history.actions.added');
                }

                if (empty(this.item.new_value)) {
                    return this.$t('history.actions.removed');
                }

                if (this.isCommentCase) {
                    return `${this.$t('history.actions.updated')} ${this.$t('history.the')}`;
                }

                return this.$t('history.actions.updated');
            },

            associationAction() {
                if (!this.isAssociation) {
                    return '';
                }

                if (this.item.field_name === 'eventLeadCreated') {
                    return this.$t('history.eventLeadCreated');
                }

                if (this.item.field_name === 'manualAssociation') {
                    return this.$t('history.manualAssociation');
                }

                return this.$t('history.associated');
            },

            caslConsentAction() {
                if (!this.isCaslConsent) {
                    return '';
                }

                if (this.item.field_name === 'casl_consent.confirmed_type') {
                    if (this.item.new_value === CaslConsentType.MANUAL) {
                        return this.$t('history.actions.forced');
                    }

                    if (!this.item.new_value) {
                        return this.$t('history.actions.withdraw');
                    }
                } else if (this.item.field_name === 'casl_consent.confirmation.sent_at') {
                    return this.$t('history.actions.sent');
                }

                return '';
            },

            createdRelationAction() {
                if (this.item.field_name !== 'created_at') {
                    return '';
                }

                if (this.isCustomField) {
                    return this.$t('history.addedCustomable');
                }

                return `${this.$t('history.actions.added')} ${this.actionSuffix}`;
            },

            deletedRelationAction() {
                if (this.item.field_name !== 'deleted_at') {
                    return '';
                }

                let action = this.$t('history.actions.removed');

                if (this.modelName === 'Lead' && this.item.old_value && !this.item.new_value) {
                    action = this.$t('history.actions.restored');
                }

                return `${action} ${this.actionSuffix}`;
            },

            exportedAction() {
                const newValue = JSON.parse(this.item.new_value || '[]');
                const oldValue = JSON.parse(this.item.old_value || '[]');

                const supplierId = newValue.filter(item => !oldValue.includes(item))[0];

                if (Supplier.isSupplierDeskingTool(supplierId)) {
                    return this.$t('history.exportedToDeskingTool');
                }

                return this.$t('history.exportedToDms');
            },

            mergeAction() {
                if (!this.isMerge) {
                    return '';
                }

                return this.$t('history.merged');
            },

            actionSuffix() {
                const suffixGender = this.fieldGender === 'F' ? 'Feminine' : '';

                if (this.modelName === 'Lead' && this.item.field_name === 'deleted_at') {
                    return this.$t(`history.prefix.this${suffixGender}`);
                }

                if (
                    this.$i18n.locale === 'en' &&
                    ['LeadEmail'].includes(this.modelName)
                ) {
                    return this.$t('history.prefix.an');
                }

                return this.$t(`history.prefix.a${suffixGender}`);
            },

            fieldName() {
                if (
                    this.isDmsExport ||
                    this.isExported ||
                    this.isRenewalCycle
                ) {
                    return '';
                }

                if (
                    this.isAssociation ||
                    this.isMerge
                ) {
                    return this.item.old_lead_name;
                }

                if (this.isReassignation) {
                    if (this.item.field_name === 'second_bdc_user') {
                        return this.$t('history.bdc');
                    }

                    return this.$t('history.advisor');
                }

                if (this.isCustomField) {
                    return this.item.relevant_data;
                }

                if (this.caslConsentField) {
                    return this.caslConsentField;
                }

                if (this.isCreatedOrDeletedField) {
                    return this.$t(`history.models.${this.camelCaseType}`);
                }

                if (this.isCommentCase) {
                    return `${this.$t('history.case')} #${this.item.relevant_data.related_id}`;
                }

                if (this.modelName === 'Communication') {
                    return this.$t(`history.communication.field.${this.item.field_name}`);
                }

                if (this.item.field_name === 'postal_code') {
                    return this.$t(`history.lead.postalCode.${this.lead.country}`);
                }

                const translationKey = this.modelName === 'LeadVehicle' ? 'vehicle' : this.camelCaseType;

                return this.$t(`history.${translationKey}.${camelCase(this.item.field_name)}`);
            },

            modelPrefix() {
                const prefixType = this.item.old_value ? 'of' : 'to';
                const prefixGender = this.fieldGender === 'F' ? 'Feminine' : '';
                const prefix = this.$t(`history.prefix.${prefixType}${prefixGender}`);

                return `${prefix} `;
            },

            fieldModel() {
                if (
                    this.isAssociation ||
                    this.isCreatedOrDeletedField
                ) {
                    return this.modelSuffix;
                }

                if (
                    this.isCaslConsent ||
                    this.isCommentCase ||
                    this.isCustomField ||
                    this.isDmsExport ||
                    this.isExported ||
                    this.isReassignation
                ) {
                    return '';
                }

                if (this.isMerge) {
                    return this.$t('history.toThisLead');
                }

                const model = this.$t(`history.models.${this.camelCaseType}`);

                return `${this.modelPrefix}${model}${this.modelSuffix}`;
            },

            modelSuffix() {
                if (this.emailType) {
                    return ` (${this.emailType})`;
                }

                if (this.phoneType) {
                    return ` (${this.phoneType})`;
                }

                if (this.isAssociation) {
                    const associatedLead = this.lead.customer?.leads?.find(lead => lead.id === this.item.relevant_data);

                    if (associatedLead) {
                        return ` (${LeadType.getTranslation(associatedLead.lead_type_id)})`;
                    }
                }

                return '';
            },

            caslConsentField() {
                if (!this.isCaslConsent) {
                    return '';
                }

                if (this.item.field_name === 'casl_consent.confirmed_type') {
                    if (this.item.new_value === CaslConsentType.MANUAL || this.item.old_value === CaslConsentType.MANUAL) {
                        return this.$t('history.customer.caslConsentTypeManual');
                    }
                }

                if (this.item.field_name === 'casl_consent.confirmation.sent_at') {
                    return this.$t('history.customer.caslConsentConfirmationConsentRequest');
                }

                return '';
            },

            emailType() {
                if (this.modelName !== 'LeadEmail') {
                    return '';
                }

                const leadEmail = this.lead.lead_emails.find((email) => {
                    return email.id === this.item.model_id;
                });

                return leadEmail ? this.$t(`history.leadEmail.type.${leadEmail.type}`) : '';
            },

            phoneType() {
                if (this.modelName !== 'LeadPhone') {
                    return '';
                }

                const leadPhone = this.lead.lead_phones.find((phone) => {
                    return phone.id === this.item.model_id;
                });

                return leadPhone ? this.$t(`history.leadPhone.type.${leadPhone.type}`) : '';
            },

            formattedDateTime() {
                const date = locale_dt(this.item.date);

                if (date.isSameDay(now())) {
                    return date.fromNow();
                }

                return date.humanShort();
            },

            formattedDate() {
                return locale_dt(this.item.date).humanShort(true);
            },

            formattedTime() {
                return locale_dt(this.item.date).toTimeShortString();
            },

            icon() {
                if (this.isAssociation) {
                    return 'regular/multiple-neutral-2';
                }

                switch (this.item.type) {
                    case 'wanted_vehicle':
                    case 'exchange_vehicle':
                        return 'regular/car-1';

                    case 'lead_phone':
                        return this.$icons.phone;

                    case 'task_event':
                        return 'regular/calendar-3';

                    case 'lead_email':
                        return this.$icons.email;

                    case 'comment':
                        return 'regular/pencil-1';

                    case 'reminder':
                        return 'regular/alarm-bell';

                    default:
                        return 'regular/single-neutral-actions';
                }
            },

            getDmsName() {
                if (!this.item.new_value) {
                    return this.$t('history.exportedToDms');
                }

                const oldValues = JSON.parse(this.item.old_value);
                const newValues = JSON.parse(this.item.new_value);
                const newSupplier = {};

                // get difference between two objects
                for (const [key, value] of Object.entries(newValues)) {
                    if (!oldValues.hasOwnProperty(key)) {
                        newSupplier[key] = value;
                    }
                }

                if (Object.keys(newSupplier).length === 0) {
                    return this.$t('history.exportedToDms');
                }

                const supplierId = newSupplier[Object.keys(newSupplier)[0]];

                const supplier = this.contextAccount.suppliers.find(supplier => supplier.id === supplierId);

                if (!supplier) {
                    return this.$t('history.exportedToDms');
                }

                const displayName = camelCase(supplier.display_name);

                return `${this.$t('history.exportedToSupplier')} ${this.$t(`suppliers.${displayName}`)}`;
            },

            users() {
                return this.usersAndSupplierUsers.concat(this.authUser.account.users);
            },

            userName() {
                if (!this.item.user_id) {
                    return 'Activix CRM';
                }

                if (this.item.user_name) {
                    return this.item.user_name;
                }

                const user = this.users.find(user => user.id == this.item.user_id);

                return user?.fullName || this.$t('general.unknown');
            },
        },

        methods: {
            formatValue(type) {
                if (
                    this.isAssociation ||
                    this.isCaslConsent ||
                    this.isCommentCase ||
                    (this.isCustomField && this.item.field_name === 'created_at') ||
                    this.isDmsExport ||
                    this.isExported ||
                    this.isMerge ||
                    this.isReassignation ||
                    this.isRenewalCycle
                ) {
                    return '';
                }

                if (this.isCreatedOrDeletedField) {
                    return this.formatRelationValue(type);
                }

                const value = this.item[`${type}_value`];
                const rawValue = this.item[`raw_${type}_value`];

                if (this.isBoolean) {
                    return value === '1' ? this.$t('general.yes') : this.$t('general.no');
                }

                if (empty(value)) {
                    return '';
                }

                if (this.isDate) {
                    return locale_date(value, '', 'UTC').humanShort();
                }

                if (this.isDatetime) {
                    return locale_dt(value, '', 'UTC').humanShort();
                }

                if (this.isPercent) {
                    return `${value} %`;
                }

                if (this.isCents) {
                    return toCurrency(value / 100);
                }

                if (this.isCurrency) {
                    return toCurrency(value);
                }

                switch (this.modelName) {
                    case 'Communication':
                        return this.$t(`history.communication.value.${value}`);

                    case 'Lead':
                        return this.formatLeadValue(value, rawValue);

                    case 'LeadEmail':
                        return this.formatEmailValue(value);

                    case 'LeadPhone':
                        return this.formatPhoneValue(value);

                    case 'LeadVehicle':
                        return this.formatVehicleValue(value);
                }

                return value;
            },

            formatLeadValue(value, rawValue) {
                switch (this.item.field_name) {
                    case 'locale':
                        if (['fr', 'en'].includes(value.toLowerCase())) {
                            return this.$t(`database.${value.toLowerCase()}`);
                        }

                        return value;

                    case 'source':
                        return this.lead.account.sourcesWithProvider.find(source => source.id === rawValue)?.name || value;

                    case 'civility':
                        switch (value) {
                            case 'mr':
                                return this.$t('database.mr');
                            case 'ms':
                                return this.$t('database.ms');
                            case 'miss':
                                return this.$t('database.miss');
                            default:
                                return value;
                        }

                    case 'sex':
                        return value === 'W' ? this.$t('database.female') : this.$t('database.male');

                    case 'province': {
                        const provinces = this.configs.provinces;

                        if (!empty(provinces[value.toUpperCase()])) {
                            return this.$t(`clientCard.provinces.${value.toUpperCase()}`);
                        }
                        return value;
                    }

                    case 'status':
                        return this.$t(`result.${value}`);

                    case 'division':
                        return this.$te(`divisions.${value}`)
                            ? this.$t(`divisions.${value}`)
                            : Division.getTranslation(value);

                    case 'lead_type':
                        return this.$t(`leadTypes.${camelCase(value)}`);

                    case 'lead_form': {
                        const keyPath = `leadForms.${value}`;

                        if (this.$te(keyPath)) {
                            return this.$t(keyPath);
                        }

                        break;
                    }

                    case 'result':
                        return this.$t(`clientCard.${value}`);

                    case 'progress_state':
                        return this.$t(`progress_state.${value}`);
                }

                return value;
            },

            formatVehicleValue(value) {
                switch (this.item.field_name) {
                    case 'stock_state':
                        return this.$t(`inventory.${value}`);

                    case 'verified_by':
                        return value === 'nothing' ? this.$t('general.no') : this.$t('general.yes');

                    case 'modality':
                    case 'frequency':
                    case 'condition':
                    case 'intention':
                    case 'category':
                    case 'category_rv':
                    case 'length':
                    case 'mechanical':
                    case 'fuel':
                        if (value === 'two_weeks') {
                            return this.$t('clientCard.vehicles.biWeekly');
                        }

                        return this.$t(`clientCard.vehicles.${value}`);

                    case 'term':
                        return `${value} ${this.$t('clientCard.vehicles.months')}`;

                    case 'sold_date':
                        return locale_dt(value, '', 'UTC').humanShort();
                }

                return value;
            },

            formatRelationValue(type) {
                if (this.item.field_name === 'created_at' && type === 'old') {
                    return '';
                }

                if (this.item.field_name === 'deleted_at' && type === 'new') {
                    return '';
                }

                const value = this.item.relevant_data;

                if (this.authUser.isAdmin()) {
                    return value;
                }

                if (this.modelName === 'LeadEmail') {
                    return this.formatEmailValue(value);
                }

                if (this.modelName === 'LeadPhone') {
                    return this.formatPhoneValue(value);
                }

                return value;
            },

            formatEmailValue(value) {
                if (!this.authUser.block_lead_info) {
                    return value;
                }

                return this.maskEmail(value);
            },

            formatPhoneValue(value) {
                if (!this.authUser.block_lead_info || this.item.field_name === 'extension') {
                    return value;
                }

                return this.maskPhone(value);
            },
        },
    };
</script>
