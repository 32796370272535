<template>
    <section
        :id="computedId"
        role="tabpanel"
        :aria-hidden="!active"
        v-show="active"
        v-if="rendered || preRender"
    >
        <slot />
    </section>
</template>

<script>
    export default {
        name: 'ActivixTabMobile',

        props: {
            id: {
                type: [String, Number],
                default: null,
            },
            name: {
                type: String,
                required: true,
            },
            icon: {
                type: String,
                default: '',
            },
            prefix: {
                type: String,
                default: '',
            },
            suffix: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            preRender: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            active: false,
            isVisible: true,
            rendered: false,
        }),

        computed: {
            textHeader() {
                return this.prefix + this.name + this.suffix;
            },

            computedId() {
                return this.id || this.name.toLowerCase().replace(/ /g, '-') || this.icon;
            },

            hash() {
                if (this.disabled) {
                    return '#';
                }

                return `#${this.computedId}`;
            },
        },

        watch: {
            active() {
                this.rendered = true;
            },
        },
    };
</script>
