<template>
    <tr class="text-gray-650 bg-white | transition hover:bg-gray-150">
        <td>
            <activix-checkbox
                :id="productKey"
                class="align-middle mb-0.5"
                :disabled="disabled"
                :value="leadProduct.sold"
                @input="update('sold', $event)"
            />
        </td>
        <td>
            <span>{{ $t(`clientCard.products.${product.name}`) }}</span>
        </td>
        <td>
            <invisible-input
                :disabled="disabled"
                :value="leadProduct.price"
                :mask="priceMask"
                @input="update('price', $event)"
            />
        </td>
    </tr>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import InvisibleInput from '@/components/container/input/InvisibleInput';

    import Lead from '@/entities/Lead';

    export default {
        components: { InvisibleInput },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
            product: {
                type: Object,
                default: () => {},
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            disabled() {
                if (
                    this.authUser.isAdvisor() ||
                    this.authUser.isBdc() ||
                    this.lead.freeze_financial_data ||
                    this.authUser.hide_fi_profits
                ) {
                    return true;
                }

                return this.guest;
            },

            leadProduct() {
                const leadProduct = this.lead.products.find(value => {
                    return value.id === this.product.id;
                });

                if (leadProduct) {
                    return this.normalizeValues(leadProduct.pivot);
                }

                return this.normalizeValues({
                    price: this.product.default_price || '',
                    sold: false,
                });
            },

            priceMask() {
                return this.authUser.hide_fi_profits ? '' : 'money';
            },

            productKey() {
                return `commercial-product-${this.product.id}`;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadProductAction']),

            normalizeValues(values) {
                let price = values?.price || null;

                if (this.authUser.hide_fi_profits) {
                    price = 'XX';
                }

                return {
                    price,
                    sold: values?.sold || false,
                };
            },

            async update(field, value) {
                if (
                    this.disabled ||
                    this.$wait.is(`updating.product.${this.product.id}`)
                ) {
                    return;
                }

                this.$wait.start(`updating.product.${this.product.id}`);

                let sold = this.leadProduct.sold;

                if (field === 'sold') {
                    sold = value;
                } else if (field === 'price') {
                    sold = !!value;
                }

                await this.updateLeadProductAction({
                    id: this.product.id,
                    lead_id: this.lead.id,
                    name: this.product.name,
                    order: this.product.order,
                    type: this.product.type,
                    pivot: {
                        ...this.leadProduct,
                        sold,
                        [field]: value,
                    },
                });

                this.$wait.end(`updating.product.${this.product.id}`);
            },
        },
    };
</script>
