<template>
    <flexible-input>
        <input-label slot="label" class="flex items-center" :blocked-reason="blockedReason">
            {{ $t('clientCard.vehicles.stock') }}
            <activix-tooltip :content="$t('clientCard.vehicles.securityDeposit')" v-if="vehicle.lead.deposit">
                <icon name="bold/currency-dollar" class="text-blue-500 ml-1 text-sm" />
            </activix-tooltip>
        </input-label>

        <invisible-input
            before="#"
            button-icon="regular/car-actions-search-1"
            class="flex-1"
            :button-tooltip="$t('clientCard.vehicles.openStock')"
            :disabled="disabled"
            :value="vehicle.stock"
            @button-click="openInventorySelectionModal"
            @input="updateStock($event)"
            v-if="stockManagementEnabled && vehicle.isWanted"
        />
        <invisible-input
            before="#"
            class="flex-1"
            :disabled="disabled"
            :value="vehicle.stock"
            @input="updateStock($event)"
            v-else
        />

        <modal-inventory-selection
            :opened.sync="modalInventorySelectionOpened"
            :account-id="vehicle.lead.account_id"
            :vehicle="vehicle"
            :lead="vehicle.lead"
            @select="updateVehicle"
        />
    </flexible-input>
</template>

<script>
    import FlexibleInput from '@/components/container/input/FlexibleInput.vue';
    import InputLabel from '@/components/presentational/input/InputLabel.vue';
    import InvisibleInput from '@/components/container/input/InvisibleInput';
    import ModalInventorySelection from '@/components/modals/ModalInventorySelection.vue';

    import LeadVehicle from '@/entities/LeadVehicle.js';

    export default {
        components: {
            FlexibleInput,
            InputLabel,
            InvisibleInput,
            ModalInventorySelection,
        },

        props: {
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            stockManagementEnabled: {
                type: Boolean,
                required: true,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        data() {
            return {
                modalInventorySelectionOpened: false,
            };
        },

        methods: {
            openInventorySelectionModal() {
                this.modalInventorySelectionOpened = true;
            },

            updateStock(stock) {
                this.$emit('update-vehicle', { stock });
            },

            updateVehicle(selectedVehicleId) {
                const params = {
                    inventory_unit_id: selectedVehicleId,
                };
                this.$emit('update-vehicle', params);
            },
        },
    };
</script>
