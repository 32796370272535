<template>
    <div class="relative flex-grow overflow-y-auto" v-if="validItems.length">
        <ul class="list-none p-6 m-0 space-y-6 xl:inset-0 xl:absolute">
            <activity-log-item
                class="last:pb-6"
                :lead="lead"
                :item="item"
                :show-anchor-line="index + 1 !== validItems.length"
                :key="index"
                v-for="(item, index) in validItems"
            />
        </ul>
    </div>

    <empty-state
        class="pb-6"
        :description="$t('clientCard.emptyState.activityLogDescription')"
        :title="$t('clientCard.emptyState.activityLogTitle')"
        svg="note-blank-2"
        v-else
    />
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    import ActivityLogItem from '@/components/container/lead/ActivityLogItem';
    import EmptyState from '@/components/EmptyState';

    import Lead from '@/entities/Lead.js';

    export default {
        components: { ActivityLogItem, EmptyState },

        props: {
            activities: {
                type: Array,
                required: true,
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            legacyLeadFieldsToIgnore() {
                return [
                    'appointment',
                    'appt_call',
                    'appt_confirmation',
                    'available',
                    'be_back',
                    'csi',
                    'delivered',
                    'delivery',
                    'home_presented',
                    'paperwork',
                    'presented',
                    'refinanced',
                    'road_test',
                    'sale',
                    'take_over',
                ];
            },

            showProfit() {
                if (this.authUser.id === this.lead.user_id || this.authUser.id === this.lead.bdc_user_id) {
                    return true;
                }

                if (this.lead.account.sale_table_options && !this.lead.account.sale_table_options.hide_profit) {
                    return true;
                }

                return this.authUser.isAdvisor() || this.authUser.isBdc() || this.authUser.isBdcDirector();
            },

            validItems() {
                return this.activities
                    .map(item => ({
                        ...item,
                        new_value: item.new_value === '...' ? null : item.new_value,
                        old_value: item.old_value === '...' ? null : item.old_value,
                    }))
                    .filter(item => {
                        if (
                            !this.lead.account.hasGeneralInfoField(item.field_name) ||
                            !this.lead.account.hasPerformanceField(item.field_name) ||
                            !this.lead.account.hasProcessField(item.field_name) ||
                            !this.lead.account.hasVehicleField(item.field_name, item.type)
                        ) {
                            return false;
                        }

                        if (
                            item.type === 'customer' &&
                            (
                                !this.$feature.isEnabled('casl_compliance') ||
                                !['casl_consent.confirmed_type', 'casl_consent.confirmation.sent_at'].includes(item.field_name)
                            )
                        ) {
                            return false;
                        }

                        if (item.field_name === 'created_at') {
                            if (!['customables', 'lead_email', 'lead_phone', 'task_event'].includes(item.type)) {
                                return false;
                            }

                            if (item.old_value) {
                                return false;
                            }

                            return !['lead_email', 'lead_phone'].includes(item.type) || item.relevant_data;
                        }

                        if (item.field_name === 'deleted_at') {
                            if (!['lead', 'lead_email', 'lead_phone', 'task_event'].includes(item.type)) {
                                return false;
                            }

                            if (item.type !== 'lead' && item.old_value) {
                                return false;
                            }

                            return !['lead_email', 'lead_phone'].includes(item.type) || item.relevant_data;
                        }

                        if (item.type === 'customables' && !item.relevant_data) {
                            return false;
                        }

                        if (item.type === 'task_event' && item.field_name === 'confirmation_timeframe') {
                            return false;
                        }

                        if (item.type === 'lead' && ['created_at', 'updated_at'].includes(item.field_name)) {
                            return false;
                        }

                        if (
                            ['second_user', 'second_bdc_user'].includes(item.field_name) &&
                            !item.new_value
                        ) {
                            return false;
                        }

                        if (item.type === 'lead_phone' && item.field_name === 'lead') {
                            return false;
                        }

                        if (
                            (item.type === 'exchange_vehicle' && item.field_name === 'type') ||
                            (item.type === 'wanted_vehicle' && item.field_name === 'type')
                        ) {
                            return false;
                        }

                        if (item.field_name === 'profit' && !this.showProfit) {
                            return false;
                        }

                        if (['client', 'customer'].includes(item.field_name) && !this.showAssociation(item)) {
                            return false;
                        }

                        if (item.type === 'lead' && this.legacyLeadFieldsToIgnore.includes(item.field_name)) {
                            return false;
                        }

                        if (item.new_value === item.old_value) {
                            return false;
                        }

                        return (!empty(item.old_value) && item.old_value !== '...') ||
                            (!empty(item.new_value) && item.new_value !== '...');
                    });
            },
        },

        methods: {
            showAssociation(item) {
                return ['customer', 'client', 'eventLeadCreated', 'manualAssociation'].includes(item.field_name);// && this.authUser.isAdmin();
            },
        },
    };
</script>
