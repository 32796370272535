<template>
    <div class="w-full">
        <activix-select
            :disabled="isReadonly"
            :options="resultStatusOptions"
            :same-width="false"
            :value="resultStatus"
            ref="select"
        >
            <template #trigger="{ disabled }">
                <activix-tooltip :content="tooltip">
                    <button
                        class="flex justify-center items-center gap-0.5 rounded-lg text-sm text-white px-2 py-1.5 | sm:gap-1.5"
                        :class="[statusClass, { 'cursor-default': disabled }]"
                    >
                        <icon :name="statusIcon" />
                        <span class="hidden | sm:inline" v-text="statusString" />
                        <icon
                            name="bold/arrow-down-1"
                            class="ml-1.5 | print:hidden"
                            scale="0.55"
                            v-if="!disabled"
                        />
                    </button>
                </activix-tooltip>
            </template>

            <template #default="{ options }">
                <div class="flex flex-col -my-1">
                    <button
                        class="group flex gap-1.5 items-center px-3 py-1.5 select-none transition-colors"
                        :class="{
                            'text-gray-400 italic': option.disabled,
                            'text-gray-600 hover:bg-gray-100 hover:text-gray-800': !option.disabled,
                        }"
                        :key="option.value"
                        @click="onSelect(option.value)"
                        v-for="option in options"
                    >
                        <icon :class="option.iconColorClassName" :name="option.iconName" />
                        <span v-text="option.label" />

                        <span class="w-6 flex-1 text-right">
                            <icon class="text-sm text-blue-500" name="bold/check-1" v-if="option.value === resultStatus" />
                        </span>
                    </button>
                </div>
            </template>
        </activix-select>

        <add-communication
            :opened="modals.addCommunication.opened"
            :lead="lead"
            @close="modals.addCommunication.opened = false"
        />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useClientCardStore } from '@/store/modules/clientCard/store.js';
    import { useGlobalStore } from '@/store/store.js';

    import { camelCase } from 'lodash-es';

    import { ActivixSelect } from '@activix/ui';
    import AddCommunication from '@/components/modals/AddCommunication.vue';

    import CommunicationMethod from '@/entities/CommunicationMethod.js';
    import CommunicationStatus from '@/entities/CommunicationStatus.js';
    import CommunicationType from '@/entities/CommunicationType.js';
    import LeadResult from '@/entities/LeadResult.js';
    import LeadStatus from '@/entities/LeadStatus.js';

    export default {
        components: {
            ActivixSelect, AddCommunication,
        },

        props: {
            lead: {
                type: Object,
                required: true,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            modals: {
                addCommunication: {
                    opened: false,
                },
            },
        }),

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            connectedCommunications() {
                return this.lead.communications.filter(communication => {
                    return ![
                        CommunicationStatus.INTERRUPTED,
                        CommunicationStatus.ERROR,
                        CommunicationStatus.INVALID,
                        CommunicationStatus.PENDING,
                    ].includes(communication.status);
                });
            },

            hasMessengerCommunications() {
                return this.lead.communications.some(communication => {
                    return communication.communication_method_id === CommunicationMethod.MESSENGER;
                });
            },

            hasOutgoingConnectedCommunications() {
                return this.connectedCommunications.some(communication => {
                    return communication.communication_type_id === CommunicationType.OUTGOING && communication.connected;
                });
            },

            isReadonly() {
                return this.readonly ||
                    this.guest ||
                    !this.authUser.hasAccessToEditAccount(this.lead.account_id) ||
                    this.lead.isResultSet() ||
                    !this.lead.account.manually_status;
            },

            pendingAvailable() {
                return !this.connectedCommunications.some(communication => {
                    return (
                        communication.communication_type_id === CommunicationType.INCOMING ||
                        !['unanswered', 'pending'].includes(communication.status)
                    );
                });
            },

            resultStatus() {
                return this.lead.getResult();
            },

            resultStatusOptions() {
                const options = [
                    {
                        iconColorClassName: 'text-blue-500',
                        iconName: 'bold/navigation-menu-horizontal-1',
                        label: this.$t('clientCard.attempted'),
                        value: LeadResult.ATTEMPTED,
                    },
                    {
                        iconColorClassName: 'text-green-500',
                        iconName: 'bold/check-circle-1',
                        label: this.$t('clientCard.reached'),
                        value: LeadResult.REACHED,
                    },
                ];

                if (this.pendingAvailable) {
                    options.unshift({
                        iconColorClassName: 'text-orange-500',
                        iconName: 'bold/alert-circle',
                        label: this.$t('clientCard.pending'),
                        value: LeadResult.PENDING,
                    });
                }

                return options;
            },

            statusClass() {
                switch (this.resultStatus) {
                    case LeadResult.REACHED:
                        return 'label-success';
                    case LeadResult.ATTEMPTED:
                        return 'label-primary';
                    default:
                        return 'label-warning';
                }
            },

            statusIcon() {
                switch (this.resultStatus) {
                    case LeadResult.REACHED:
                        return 'bold/check-circle-1';
                    case LeadResult.ATTEMPTED:
                        return 'bold/navigation-menu-horizontal-1';
                    default:
                        return 'bold/alert-circle';
                }
            },

            statusString() {
                switch (this.resultStatus) {
                    case LeadResult.REACHED:
                        return this.$t('result.reached');
                    case LeadResult.ATTEMPTED:
                        return this.$tc('result.attempted');
                    default:
                        return this.$t('result.pending');
                }
            },

            tooltip() {
                if (this.lead.hasInvalidStatus()) {
                    const statusTranslation = this.$t(`result.${camelCase(this.lead.status)}`);

                    return `<strong> ${statusTranslation}</strong><br>${
                        this.lead.status === LeadStatus.LOST ? this.lead.lost_reason : ''
                    }`;
                }

                return '';
            },
        },

        methods: {
            onSelect(newStatus) {
                this.$refs.select.close();

                const currentStatus = this.resultStatus;

                if (newStatus && currentStatus) {
                    this.updateLead({ result: newStatus });

                    if (
                        (currentStatus === LeadResult.ATTEMPTED && newStatus === LeadResult.REACHED) ||
                        (currentStatus === LeadResult.PENDING && [LeadResult.ATTEMPTED, LeadResult.REACHED].includes(newStatus))
                    ) {
                        useClientCardStore().currentCommunication = {};
                        this.modals.addCommunication.opened = true;
                    }
                }
            },

            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
