<template>
    <box :class="{ loading: loading }">
        <box-header slot="header">
            {{ $t('clientCard.boxes.activityLog.title') }}

            <box-header-icon
                slot="right"
                :disabled="loading || $wait.is('fetching.history')"
                :icon-class-name="{ 'spin-inverse': $wait.is('fetching.history') }"
                :icon-name="$icons.loading"
                :indicator="refreshNeeded"
                :tooltip="$t('dashboards.refresh')"
                @click="fetchHistory(true)"
            />
        </box-header>

        <template #content>
            <activity-log :activities="activities" :lead="contextLead" />
        </template>
    </box>
</template>

<script>
    import { mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store';

    import ActivityLog from '@/components/container/lead/ActivityLog';
    import Box from '@/components/presentational/boxes/Box';
    import BoxHeader from '@/components/presentational/boxes/BoxHeader';
    import BoxHeaderIcon from '@/components/presentational/boxes/BoxHeaderIcon.vue';

    export default {
        components: {
            ActivityLog,
            Box,
            BoxHeader,
            BoxHeaderIcon,
        },

        data() {
            return {
                activities: [],
                loading: true,
                refreshNeeded: false,
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),

            canBeRefreshed() {
                return this.contextLead.id && !this.$wait.is('fetching.history');
            },
        },

        watch: {
            contextLead: {
                deep: true,
                handler(newValue, oldValue) {
                    if (!this.canBeRefreshed) {
                        return;
                    }

                    if (newValue.id !== oldValue.id) {
                        this.fetchHistory(false);
                        return;
                    }

                    this.refreshNeeded = true;
                },
            },
        },

        methods: {
            fetchHistory(showLoading = true) {
                if (!this.canBeRefreshed) {
                    return;
                }

                this.$wait.start('fetching.history');
                this.refreshNeeded = false;
                this.loading = !!showLoading;

                this.$axios
                    .get(`v1/leads/${this.contextLead.id}/history`)
                    .then(response => {
                        this.activities = response.data.data;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$wait.end('fetching.history');
                    });
            },
        },

        mounted() {
            this.fetchHistory();
        },
    };
</script>
