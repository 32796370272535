<template>
    <div>
        <div class="flex justify-between items-center">
            <div class="flex items-center gap-2">
                <h4 class="m-0">
                    {{ title }}
                </h4>

                <feature-preview-new-tag v-if="featurePreview.isNew" />
            </div>

            <div class="flex items-center gap-2">
                <span class="text-sm text-gray-600">
                    {{ activationLabel }}
                    <date-distance class="text-sm text-gray-600" :date="activationDate" v-if="activeState" />
                </span>

                <activix-switcher
                    :value="activeState"
                    @input="onToggleActiveState($event)"
                />
            </div>
        </div>

        <div class="flex flex-wrap item-center gap-3 pt-4">
            <feature-preview-tag
                :title="tag"
                :key="`tag#${index}`"
                v-for="(tag, index) in tags"
            />
        </div>

        <activix-confirm-modal
            :content="$t('featurePreview.requestFeedback', [title])"
            :opened.sync="modals.requestFeedback.opened"
            @approve="$emit('open-feedback')"
            @deny="modals.requestFeedback.opened = false"
        />
    </div>
</template>

<script>
    import DateDistance from '@/components/container/date/DateDistance';
    import FeaturePreviewNewTag from '@/components/presentational/featurePreview/FeaturePreviewNewTag';
    import FeaturePreviewTag from '@/components/presentational/featurePreview/FeaturePreviewTag';

    import ActivixDate from '@/value-objects/ActivixDate';
    import FeaturePreview from '@/entities/FeaturePreview.js';

    export default {
        components: { DateDistance, FeaturePreviewNewTag, FeaturePreviewTag },

        props: {
            activeState: {
                type: Boolean,
                required: true,
            },
            featurePreview: {
                type: FeaturePreview,
                required: true,
            },
        },

        data() {
            return {
                modals: {
                    requestFeedback: {
                        opened: false,
                    },
                },
            };
        },

        computed: {
            activationDate() {
                return new ActivixDate(this.featurePreview.activated_at);
            },

            activationLabel() {
                const label = this.activeState ? this.$t('featurePreview.activated') : this.$t('featurePreview.deactivated');

                if (this.activeState === this.featurePreview.isActive) {
                    return label;
                }

                return `${this.$t('featurePreview.willBe')} ${label.toLowerCase()}`;
            },

            tags() {
                const locale = this.$i18n.locale;
                return this.featurePreview.tags[locale];
            },

            title() {
                const locale = this.$i18n.locale;
                return this.featurePreview.title[locale];
            },
        },

        methods: {
            onToggleActiveState(newActiveState) {
                this.$emit('active-state-changed', {
                    featurePreviewId: this.featurePreview.id,
                    activeState: newActiveState,
                });

                if (this.featurePreview.isActive && !newActiveState) {
                    this.modals.requestFeedback.opened = true;
                }
            },
        },
    };
</script>
