<template>
    <activix-tooltip :content="tooltip">
        <follow-up-field
            follow-up="csi"
            :follow-up-params="{ last: lead.isService() ? 'presented' : 'delivered' }"
            :date="date"
            :disabled="disabled"
            :lead-id="lead.id"
            :remove-confirmation="false"
            @input="updateLead({ csi_date: $event })"
        />
    </activix-tooltip>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import FollowUpField from '@/components/container/lead/input/FollowUpField.vue';

    import ActivixDate from '@/value-objects/ActivixDate.js';
    import Lead from '@/entities/Lead.js';

    export default {
        components: {
            FollowUpField,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            date() {
                return new ActivixDate(this.lead.csi_date).toDateString();
            },

            disabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.lead.isService() && !this.lead.delivered_date && !this.lead.csi_date) {
                    return true;
                }

                if (this.lead.isService() && !this.lead.invoiced && !this.lead.csi_date) {
                    return true;
                }

                if (!this.$can('leads.csi_date:update')) {
                    return true;
                }

                return this.guest;
            },

            tooltip() {
                if (this.lead.hasInvalidStatus()) {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.csi'),
                    ])}`;
                }

                return '';
            },
        },

        methods: {
            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
