<template>
    <div class="flex flex-grow gap-x-3">
        <activix-tooltip :content="tooltip">
            <activix-checkbox
                :disabled="disabled"
                :value="lead.prepared"
                class="mr-3 flex-col"
                size="lg"
                @input="updateLead({ prepared: $event })"
            />
        </activix-tooltip>

        <activix-tooltip
            :content="$t('clientCard.preparedWorkOrderTooltip')"
            v-if="lead.account.client_card_fields.process.prepared_work_order"
        >
            <invisible-input
                class="flex-grow"
                :disabled="guest"
                :value="lead.prepared_work_order"
                before="#"
                @input="updateLead({ prepared_work_order: $event })"
            />
        </activix-tooltip>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import InvisibleInput from '@/components/container/input/InvisibleInput';

    import Lead from '@/entities/Lead.js';
    import LeadType from '@/entities/LeadType';

    export default {
        components: {
            InvisibleInput,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        data() {
            return {
                createRenewalModalOpened: false,
                uncheckModalOpened: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            disabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.lead.sale_date && !this.lead.sale_by_phone) {
                    return true;
                }

                if (!this.$can('leads.prepared:update')) {
                    return true;
                }

                return this.guest;
            },

            tooltip() {
                if (!this.lead.sale_date && !this.lead.sale_by_phone) {
                    if (this.lead.isOfType(LeadType.RENEWAL)) {
                        return this.$t('clientCard.needRenewalChecked');
                    }

                    return this.$t('clientCard.needSaleChecked');
                }

                if (this.lead.hasInvalidStatus()) {
                    return `<em>${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.prepared'),
                    ])}</em>`;
                }

                return '';
            },
        },

        methods: {
            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
