<template>
    <section class="flex-1 flex flex-col overflow-visible space-y-6 rounded-b-sm bg-white">
        <table-box>
            <t-header
                class="uppercase text-gray-600"
                :style="headerStyle"
                slot="header"
            >
                <th class="w-1/6 font-semibold | md:w-1/12" />

                <th class="w-3/6 font-semibold | md:w-1/4">
                    {{ $t('clientCard.boxes.serviceProcess.product') }}
                </th>

                <th class="font-semibold | hidden md:table-cell md:w-1/3">
                    {{ $t('clientCard.boxes.serviceProcess.description') }}
                </th>

                <th class="w-2/6 font-semibold | md:w-1/6">
                    {{ $t('clientCard.boxes.serviceProcess.price') }}
                </th>

                <th class="w-1/6 font-semibold | hidden md:table-cell md:w-1/6">
                    {{ $t('clientCard.boxes.serviceProcess.time') }}
                </th>
            </t-header>

            <t-content slot="content">
                <service-products-table-row
                    :lead="lead"
                    :product="product"
                    :key="product.id"
                    v-for="product in filteredProducts"
                />
            </t-content>
            <service-products-footer slot="footer" :lead="lead" />
        </table-box>
    </section>
</template>

<script>
    import { mapState } from 'pinia';
    import { useLayoutStore } from '@/store/modules/layout/store';

    import { sortBy } from 'lodash-es';

    import ServiceProductsFooter from '@/components/container/lead/ServiceProductsFooter';
    import ServiceProductsTableRow from '@/components/container/lead/ServiceProductsTableRow';
    import TContent from '@/components/presentational/table/TContent';
    import THeader from '@/components/presentational/table/THeader';
    import TableBox from '@/components/presentational/table/TableBox';

    import Lead from '@/entities/Lead';

    export default {
        components: {
            ServiceProductsFooter,
            ServiceProductsTableRow,
            TContent,
            THeader,
            TableBox,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useLayoutStore, ['bodySpacingTop', 'headerHeight']),

            filteredProducts() {
                const filteredProducts = this.lead.account.products.filter(product => {
                    return (
                        product.type === 'service' &&
                        (
                            product.visible ||
                            this.lead.products.some(leadProduct => leadProduct.id === product.id && leadProduct.pivot.sold)
                        )
                    );
                });

                return sortBy(filteredProducts, product => product.order);
            },

            headerStyle() {
                const top = this.headerHeight + this.bodySpacingTop;

                return {
                    top: `${top}px`,
                };
            },
        },
    };
</script>
