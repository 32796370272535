<template>
    <div>
        <div class="box | mb-6">
            <div class="box-header">
                <h4 class="box-title">
                    {{ $t('clientCard.generalInfo') }}
                </h4>
            </div>
            <div class="box-body | pt-0" :class="{ 'pb-2': mdLayout }">
                <div class="flex flex-wrap -mx-3">
                    <lead-input-wrapper class="w-full xs:w-1/2" section="generalInfo" position="left">
                        <lead-input-group v-if="contextLead.account.hasGeneralInfoField('rating')">
                            <lead-input-label>
                                {{ $t('clientCard.rating') }}
                            </lead-input-label>
                            <lead-input-value>
                                <rating class="input-wrapper" :lead="contextLead" :readonly="guest" />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="isServiceDivision">
                            <lead-input-label>
                                <activix-tooltip :content="$t('clientCard.services.averageSpendingTooltip')">
                                    <span>{{ $t('clientCard.services.averageSpending') }}</span>
                                </activix-tooltip>
                            </lead-input-label>
                            <lead-input-value>
                                {{ toMoney(averageSpending) }}
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasGeneralInfoField('lead_type')">
                            <lead-input-label>
                                {{ $t('clientCard.clientType') }}
                            </lead-input-label>
                            <lead-input-value>
                                <div
                                    class="input-wrapper"
                                    :class="{ disabled: leadTypeDisabled }"
                                    @click="onEditLeadType"
                                    v-if="!leadTypeEditable"
                                >
                                    <activix-tooltip :content="!isLeadWebByScraper ? '' : $t('clientCard.integrationLeadBlockedTooltip')">
                                        <span class="link-grey-dark">{{ leadTypeName }}</span>
                                    </activix-tooltip>
                                </div>

                                <select-picker
                                    name="lead_type_id"
                                    :allow-empty="false"
                                    :disabled="guest"
                                    :options="leadTypes"
                                    :record-id="contextLead.id"
                                    :validate="validateLeadType"
                                    :value="contextLead.lead_type_id"
                                    v-if="leadTypeEditable"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <template v-if="contextLead.account.hasGeneralInfoField('campaign')">
                            <lead-input-group v-if="contextLead.lead_type_id == 7">
                                <lead-input-label>
                                    {{ $t('clientCard.event.event') }}
                                </lead-input-label>
                                <lead-input-value>
                                    <select-picker
                                        name="event"
                                        :allow-empty="false"
                                        :options="campaigns"
                                        :record-id="contextLead.id"
                                        :value="eventCampaignField.name"
                                        :disabled="guest"
                                    />
                                </lead-input-value>
                            </lead-input-group>
                            <lead-input-group v-else>
                                <lead-input-label>
                                    {{ $t('dashboards.importedCampaign') }}
                                </lead-input-label>
                                <lead-input-value>
                                    <input-text
                                        name="imported_campaign"
                                        :record-id="contextLead.id"
                                        :disabled="guest"
                                        :value="contextLead.imported_campaign"
                                    />
                                </lead-input-value>
                            </lead-input-group>
                        </template>

                        <lead-input-group
                            v-if="
                                (contextLead.lead_type_id == 7 || isServiceDivision) &&
                                    contextLead.account.hasGeneralInfoField('segment')
                            "
                        >
                            <lead-input-label>
                                {{ $t('clientCard.event.segment') }}
                            </lead-input-label>
                            <lead-input-value>
                                <select-picker
                                    name="segment"
                                    :disabled="guest"
                                    :options="segments"
                                    :record-id="contextLead.id"
                                    :value="contextLead.segment"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group
                            v-if="leadFormAvailable && contextLead.account.hasGeneralInfoField('lead_form')"
                        >
                            <lead-input-label>
                                {{ $t('clientCard.leadForm') }}
                            </lead-input-label>
                            <lead-input-value>
                                <select-picker
                                    name="lead_form_id"
                                    :disabled="guest"
                                    :options="leadForms"
                                    :record-id="contextLead.id"
                                    :value="leadForm"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasGeneralInfoField('communication_preference')">
                            <lead-input-label>
                                <activix-tooltip :content="$t('accounts.edit.communicationPreferenceTooltip')">
                                    <span>{{ $t('clientCard.communicationPreference') }}</span>
                                </activix-tooltip>
                            </lead-input-label>
                            <lead-input-value>
                                <select-picker
                                    name="communication_preference"
                                    :disabled="guest"
                                    :options="communicationPreferences"
                                    :record-id="contextLead.id"
                                    :value="contextLead.communication_preference"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasGeneralInfoField('created_method')">
                            <lead-input-label>
                                {{ $t('dashboards.createdMethodString') }}
                            </lead-input-label>
                            <lead-input-value>
                                <span
                                    v-text="$t(`createdMethods.${contextLead.created_method}`)"
                                    v-if="contextLead.created_method"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group
                            v-if="isServiceDivision && contextLead.account.hasGeneralInfoField('storage')"
                        >
                            <lead-input-label>
                                {{ $t('clientCard.storage') }}
                            </lead-input-label>
                            <lead-input-value>
                                <input-text
                                    name="storage"
                                    :record-id="contextLead.id"
                                    :value="contextLead.storage"
                                    :disabled="guest"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group
                            v-if="
                                contextLead.account.client_number &&
                                    contextLead.lead_type_id != 7 &&
                                    contextLead.account.hasGeneralInfoField('client_number')
                            "
                        >
                            <lead-input-label>
                                {{ $t('clientCard.clientNumber') }}
                            </lead-input-label>
                            <lead-input-value>
                                <input-text
                                    name="client_number"
                                    :record-id="contextLead.id"
                                    :value="contextLead.client_number"
                                    :disabled="guest"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group
                            v-if="showIntentionField && contextLead.account.hasGeneralInfoField('referrer')"
                        >
                            <lead-input-label>
                                {{ $t('clientCard.referrer') }}
                            </lead-input-label>
                            <lead-input-value>
                                <input-text
                                    name="referrer"
                                    :disabled="guest"
                                    :record-id="contextLead.id"
                                    :value="contextLead.referrer"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group
                            v-if="showIntentionField && contextLead.account.hasGeneralInfoField('navigation_history')"
                        >
                            <lead-input-label>
                                {{ $t('clientCard.navigationHistory') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip :content="$t('clientCard.navigationHistoryClickMsg')" v-if="contextLead.navigation_history">
                                    <button @click="openNavigationHistory">
                                        <icon name="regular/list-bullets" class="link-blue | text-base" />
                                    </button>
                                </activix-tooltip>
                                <activix-tooltip :content="$t('clientCard.navigationHistoryEmpty')" v-else>
                                    <span class="cursor-not-allowed">...</span>
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>
                    </lead-input-wrapper>
                    <lead-input-wrapper class="w-full xs:w-1/2" section="generalInfo" position="right">
                        <lead-input-group v-if="contextLead.account.hasGeneralInfoField('source')">
                            <lead-input-label>
                                {{ $t('clientCard.provider') }}
                            </lead-input-label>
                            <lead-input-value>
                                <input-text
                                    name="source_provider"
                                    :disabled="true"
                                    :record-id="contextLead.id"
                                    :value="sourceProvider"
                                />
                            </lead-input-value>
                        </lead-input-group>
                        <lead-input-group v-if="contextLead.account.hasGeneralInfoField('source')">
                            <lead-input-label>
                                {{ $t('clientCard.origin') }}
                            </lead-input-label>
                            <lead-input-value>
                                <select-picker
                                    name="source_id"
                                    :disabled="guest || contextLead.created_method == 'messenger'"
                                    :options="sources"
                                    :record-id="contextLead.id"
                                    :value="providerValue"
                                >
                                    <template #right>
                                        <a
                                            class="ml-2 text-lg"
                                            @click="openOriginalEmailModal"
                                            v-if="showOriginalEmailModalLink"
                                        >
                                            <activix-tooltip :content="$t('clientCard.originalEmail')">
                                                <icon name="regular/archive-folder" />
                                            </activix-tooltip>
                                        </a>
                                    </template>
                                </select-picker>
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasGeneralInfoField('division')">
                            <lead-input-label>
                                {{ $t('clientCard.division') }}
                            </lead-input-label>
                            <lead-input-value>
                                <select-picker
                                    name="division_id"
                                    :disabled="!divisionIsEditable"
                                    :options="divisions"
                                    :record-id="contextLead.id"
                                    :value="contextLead.division_id"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group
                            v-if="
                                contextLead.lead_type_id == 7 && contextLead.account.hasGeneralInfoField('called_count')
                            "
                        >
                            <lead-input-label>
                                {{ $t('clientCard.event.callCount') }}
                            </lead-input-label>
                            <lead-input-value>
                                <input-text
                                    name="called_count"
                                    :record-id="contextLead.id"
                                    :value="contextLead.called_count"
                                    :disabled="true"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group
                            v-if="contextLead.lead_type_id == 2 && contextLead.account.hasGeneralInfoField('call_date')"
                        >
                            <lead-input-label>
                                {{ $t('clientCard.callDate') }}
                            </lead-input-label>
                            <lead-input-value>
                                <date-time-picker
                                    name="call_date"
                                    :disabled="guest"
                                    :record-id="contextLead.id"
                                    :value="contextLead.call_date"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasGeneralInfoField('sector')">
                            <lead-input-label>
                                {{ $t('accounts.edit.sector') }}
                            </lead-input-label>
                            <lead-input-value>
                                <input-text
                                    name="sector"
                                    :record-id="contextLead.id"
                                    :disabled="guest"
                                    :value="contextLead.sector"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasGeneralInfoField('budget')">
                            <lead-input-label>
                                {{ $t('accounts.edit.budgetTextField') }}
                            </lead-input-label>
                            <lead-input-value>
                                <input-text
                                    name="budget"
                                    :disabled="guest"
                                    :record-id="contextLead.id"
                                    :value="contextLead.budget"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasGeneralInfoField('comment')">
                            <lead-input-label>
                                {{ $t('clientCard.generalComment') }}
                            </lead-input-label>
                            <lead-input-value>
                                <text-area
                                    name="comment"
                                    :record-id="contextLead.id"
                                    :disabled="guest"
                                    :value="contextLead.comment"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group
                            v-if="isServiceDivision && contextLead.account.hasGeneralInfoField('prepaid')"
                        >
                            <lead-input-label>
                                {{ $t('clientCard.prepaid') }}
                            </lead-input-label>
                            <lead-input-value>
                                <prepaid :in-client-card="true" :lead="contextLead" />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group
                            v-if="isServiceDivision && contextLead.account.hasGeneralInfoField('loyalty')"
                        >
                            <lead-input-label>
                                {{ $t('clientCard.loyalty') }}
                            </lead-input-label>
                            <lead-input-value>
                                <loyalty :in-client-card="true" :lead="contextLead" />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group
                            v-if="showIntentionField && contextLead.account.hasGeneralInfoField('search_term')"
                        >
                            <lead-input-label>
                                {{ $t('clientCard.searchTerm') }}
                            </lead-input-label>
                            <lead-input-value>
                                <input-text
                                    name="search_term"
                                    :disabled="guest"
                                    :record-id="contextLead.id"
                                    :value="contextLead.search_term"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group
                            v-if="showIntentionField && contextLead.account.hasGeneralInfoField('keyword')"
                        >
                            <lead-input-label>
                                {{ $t('clientCard.keyword') }}
                            </lead-input-label>
                            <lead-input-value>
                                <input-text
                                    name="keyword"
                                    :disabled="guest"
                                    :record-id="contextLead.id"
                                    :value="contextLead.keyword"
                                />
                            </lead-input-value>
                        </lead-input-group>
                    </lead-input-wrapper>
                    <lead-input-wrapper class="w-full" section="generalInfo" display-type="box" />
                </div>
            </div>
        </div>

        <original-email :opened.sync="originalEmailModalOpened" />
        <select-service-vehicle :opened.sync="selectServiceVehicleModalOpened" />
        <navigation-history :opened.sync="navigationHistoryModalOpened" :navigation-history="contextLead.navigation_history" />

        <activix-confirm-modal
            approve-text="Ok"
            type="info"
            :content="divisionIsEditableTooltip"
            :opened.sync="changeDivisionModalOpened"
            :show-deny="false"
        />

        <activix-confirm-modal
            :content="$t('clientCard.sureChangeLeadType')"
            :opened.sync="changeLeadTypeModalOpened"
            @approve="leadTypeEditable = true"
        />

        <activix-confirm-modal
            approve-text="Ok"
            type="info"
            :content="leadTypeValidationModal.content"
            :opened.sync="leadTypeValidationModal.opened"
            :show-deny="false"
        />
    </div>
</template>

<script>
    import { camelCase, find, get, orderBy, uniqBy } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';

    // Components
    import NavigationHistory from '../modals/NavigationHistory.vue';
    import DateTimePicker from './inputs/DateTimePicker.vue';
    import InputText from './inputs/InputText.vue';
    import LeadInputGroup from './LeadInputGroup.vue';
    import LeadInputLabel from './LeadInputLabel.vue';
    import LeadInputValue from './LeadInputValue.vue';
    import LeadInputWrapper from './LeadInputWrapper.vue';
    import Loyalty from '../dashboards/columns/FollowUp/Loyalty.vue';
    import OriginalEmail from '../modals/OriginalEmail.vue';
    import Prepaid from '../dashboards/columns/FollowUp/Prepaid.vue';
    import Rating from './Rating.vue';
    import SelectPicker from './inputs/SelectPicker.vue';
    import SelectServiceVehicle from '../modals/SelectServiceVehicle.vue';
    import TextArea from './inputs/TextArea.vue';

    // Entities
    import LeadType from '../../entities/LeadType.js';
    import CreatedMethods from '../../entities/CreatedMethod.js';
    import Division from '../../entities/Division.js';
    import ActivixDate from '../../value-objects/ActivixDate.js';
    import CampaignType from '../../entities/CampaignType.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useClientCardStore } from '../../store/modules/clientCard/store.js';

    export default {
        components: {
            DateTimePicker,
            InputText,
            LeadInputGroup,
            LeadInputLabel,
            LeadInputValue,
            LeadInputWrapper,
            Loyalty,
            OriginalEmail,
            Prepaid,
            Rating,
            SelectPicker,
            SelectServiceVehicle,
            TextArea,
            NavigationHistory,
        },

        data() {
            return {
                navigationHistoryModalOpened: false,
                leadTypeEditable: false,
                originalEmailModalOpened: false,
                selectServiceVehicleModalOpened: false,
                changeLeadTypeModalOpened: false,
                changeDivisionModalOpened: false,
                leadTypeValidationModal: {
                    opened: false,
                    content: '',
                },
            };
        },

        computed: {
            ...mapState(useClientCardStore, ['authorizedAction']),
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),
            ...mapState(useGlobalStore, ['authUser', 'guest', 'configs']),

            campaigns() {
                const campaigns = this.contextAccount.campaigns
                    .filter(campaign => {
                        return campaign.type === CampaignType.EVENT && campaign.name !== this.eventCampaignField.name;
                    })
                    .map(campaign => ({
                        value: campaign.id,
                        text: campaign.name,
                    }));

                return orderBy(campaigns, 'value', 'desc');
            },

            eventCampaignField() {
                return this.contextLead.campaigns.find(campaign => campaign.type === CampaignType.EVENT) || { name: '' };
            },

            showIntentionField() {
                return [LeadType.EMAIL, LeadType.WEB_ORDER].includes(this.contextLead.lead_type_id);
            },

            providerValue() {
                return (!empty(this.contextLead.media_source) || !empty(this.contextLead.import_source)) &&
                    empty(this.contextLead.source_id)
                    ? 'text-source'
                    : this.contextLead.source_id;
            },

            leadFormAvailable() {
                let available = true;

                // Hide for phone Up leads except for Thibault Chevrolet because they whined
                if (
                    this.contextLead.lead_type_id == LeadType.PHONE &&
                    ![63, 181, 189, 191].includes(this.contextLead.account_id)
                ) {
                    available = false;
                }

                if (this.contextLead.lead_type_id == LeadType.EVENT) {
                    available = false;
                }

                return available;
            },

            leadForm() {
                if (this.contextLead.form && !this.contextLead.lead_form_id) {
                    return 'text-form';
                }

                return this.contextLead.lead_form_id;
            },

            leadTypeDisabled() {
                return this.guest || this.isEvent || this.isLeadWebByScraper;
            },

            leadTypes() {
                const leadTypes = [];

                for (const leadType of this.configs.leadTypes) {
                    if (
                        (leadType.id == LeadType.DMS && !this.isServiceDivision) ||
                        (leadType.id == LeadType.EVENT && this.contextLead.lead_type_id != LeadType.EVENT) ||
                        (leadType.id == LeadType.PRE_BOOKING && !this.isServiceDivision) ||
                        (!this.authUser.isAdmin() &&
                            ((leadType.id == LeadType.WEB_ORDER && !this.authUser.module_access.web_order) ||
                                (leadType.id == LeadType.LOYALTY && !this.authUser.module_access.loyalty) ||
                                (leadType.id == LeadType.RENEWAL && !this.authUser.module_access.renewal))) ||
                        (leadType.id == LeadType.SMS &&
                            !(
                                this.contextAccount.hasNioText() &&
                                (this.authUser.hasNioText() || this.authUser.isAdmin())
                            ))
                    ) {
                        continue;
                    }

                    leadTypes.push({
                        value: leadType.id,
                        text: this.$t(`leadTypes.${camelCase(leadType.name)}`),
                    });
                }

                // Sort
                leadTypes.sort((a, b) => a.text.localeCompare(b.text));

                return leadTypes;
            },

            leadForms() {
                const leadForms = [];

                if (!empty(this.contextLead.form)) {
                    leadForms.push({
                        value: 'text-form',
                        text: this.contextLead.form,
                    });
                }

                // Default forms
                for (const leadForm of this.configs.leadForms) {
                    leadForms.push({
                        value: leadForm.id,
                        text:
                            leadForm[`display_name_${this.$i18n.locale}`] ||
                            this.$t(`leadForms.${leadForm.name}`),
                    });
                }

                // Account specific forms
                for (const leadForm of this.contextLead.account.lead_forms) {
                    leadForms.push({
                        value: leadForm.id,
                        text: leadForm.name,
                    });
                }

                // Sort
                leadForms.sort((a, b) => a.text.localeCompare(b.text));

                return leadForms;
            },

            communicationPreferences() {
                return [
                    { value: 'phone', text: this.$t('clientCard.phone') },
                    { value: 'email', text: this.$t('clientCard.email') },
                    { value: 'sms', text: this.$t('clientCard.sms') },
                ];
            },

            sources() {
                const sources = [];

                if (!empty(this.contextLead.import_source)) {
                    sources.push({
                        value: 'text-source',
                        text: this.contextLead.import_source,
                    });
                } else if (!empty(this.contextLead.media_source)) {
                    sources.push({
                        value: 'text-source',
                        text: this.contextLead.media_source,
                    });
                }

                for (const source of this.contextLead.account.sourcesWithProvider) {
                    if (empty(source.lead_type_id) || source.lead_type_id == this.contextLead.lead_type_id) {
                        if (this.contextLead.account.display_approved_sources && source.approved_source_id) {
                            sources.push({
                                value: source.approved_source.id,
                                text: source.approved_source.name,
                            });
                        } else if (source.display_on_listing) {
                            sources.push({
                                value: source.id,
                                text: source.name,
                            });
                        }
                    }
                }

                // Add current source to have the possibility to see it (Only if not already in array)
                if (
                    !empty(this.contextLead.source) &&
                    this.contextLead.source.id == this.contextLead.source_id &&
                    empty(find(sources, obj => obj.value == this.contextLead.source_id))
                ) {
                    if (this.contextLead.account.display_approved_sources && this.contextLead.source.approved_source) {
                        sources.push({
                            value: this.contextLead.source.id,
                            text: this.contextLead.source.approved_source.name,
                        });
                    } else {
                        sources.push({
                            value: this.contextLead.source.id,
                            text: this.contextLead.source.name,
                        });
                    }
                }

                // Sort
                sources.sort((a, b) => a.text.localeCompare(b.text));

                return uniqBy(sources, 'value');
            },

            sourceProvider() {
                const provider = this.contextLead.account.sources
                    .map(source => {
                        return {
                            sourceId: source.id,
                            id: source.provider.id,
                            name: source.provider[`display_name_${this.$i18n.locale}`] || source.provider.name,
                        };
                    })
                    .find(source => source.sourceId == this.contextLead.source_id);

                if (provider) {
                    return provider.name;
                }

                return (empty(this.contextLead.source))
                    ? null
                    : this.contextLead.source.provider[`display_name_${this.$i18n.locale}`] || this.contextLead.source.provider.name;
            },

            divisions() {
                const divisions = [];

                for (const division of this.configs.divisions) {
                    if (division.id > Division.SERVICE) continue;

                    if (
                        [LeadType.LOYALTY, LeadType.RENEWAL, LeadType.WEB_ORDER].includes(
                            this.contextLead.lead_type_id,
                        ) &&
                        division.id == Division.SERVICE
                    ) {
                        continue;
                    }

                    // If service, only allow if user & account has access
                    if (division.id == Division.SERVICE && !this.isServiceDivision) {
                        if (!this.contextLead.account.service || !this.authUser.hasDivisionAccess(Division.SERVICE)) {
                            continue;
                        }
                    }

                    divisions.push({
                        value: division.id,
                        text: this.$t(`divisions.${division.name}`),
                    });
                }

                // Sort
                divisions.sort((a, b) => a.text.localeCompare(b.text));

                return divisions;
            },

            divisionString() {
                if (!this.contextLead.division_id) {
                    return '-';
                }

                const divisionKey = Object.keys(Division.entries).find(
                    d => Division.entries[d] == this.contextLead.division_id,
                );

                return this.$t(`divisions.${divisionKey}`);
            },

            isEvent() {
                return this.contextLead.lead_type.name == 'event' && !this.authUser.isAdmin();
            },

            isLeadWebByScraper() {
                return this.contextLead.lead_type_id == LeadType.EMAIL && this.contextLead.created_method == CreatedMethods.SCRAPER && !this.authUser.isAdmin();
            },

            segments() {
                return [
                    { value: 'conquest', text: this.$t('result.conquest') },
                    { value: 'promo', text: this.$t('result.promo') },
                    { value: 'notSold', text: this.$t('result.notSold') },
                    { value: 'service', text: this.$t('result.service') },
                    { value: 'loyalty', text: this.$t('result.loyalty') },
                    { value: 'reminder', text: this.$t('result.reminder') },
                    { value: 'endWarranty', text: this.$t('result.endWarranty') },
                    { value: 'endLcap', text: this.$t('result.endLcap') },
                    { value: 'endLnette', text: this.$t('result.endLnette') },
                    { value: 'csi', text: this.$t('result.csi') },
                    { value: 'noShow', text: this.$t('result.noShow') },
                    { value: 'other', text: this.$t('result.other') },
                ];
            },

            isPrebookingType() {
                return this.leadIsPrebookingType(this.contextLead);
            },

            isDmsType() {
                return this.leadIsDmsType(this.contextLead);
            },

            isServiceDivision() {
                return this.leadIsServiceDivision(this.contextLead);
            },

            isServiceDMS() {
                return this.leadIsServiceDivision(this.contextLead) && this.contextLead.lead_type_id == LeadType.DMS;
            },

            hasSoldServiceProducts() {
                return this.leadHasSoldServiceProducts(this.contextLead);
            },

            divisionIsEditableTooltip() {
                switch (true) {
                    case this.divisionIsEditable:
                        return '';

                    case this.isPrebookingType:
                        return this.$t('clientCard.cantChangeDivisionLeadType', [
                            this.$t('leadTypes.preBooking'),
                            this.$t('divisions.service'),
                        ]);

                    case this.hasSoldServiceProducts:
                        return this.$t('clientCard.cantChangeDivisionSoldProducts', [this.$t('divisions.service')]);

                    default:
                        return '';
                }
            },

            averageSpending() {
                return (
                    (this.contextLead.customer && this.contextLead.customer.average_spending) ||
                    this.contextLead.product_total ||
                    0
                );
            },

            leadTypeName() {
                let leadTypeName = '-';

                if (this.contextLead.lead_type_id) {
                    leadTypeName = this.$t(`leadTypes.${camelCase(this.getLeadType(this.contextLead.lead_type_id))}`);
                }

                return leadTypeName;
            },

            divisionIsEditable() {
                return !this.guest && !this.isPrebookingType && !this.isDmsType && !this.hasSoldServiceProducts;
            },

            showOriginalEmailModalLink() {
                return (
                    (!!get(this.contextLead, 'request.old_url') &&
                        get(this.contextLead, 'request.parser.type') != 'xml') ||
                    !!this.contextLead.original_email ||
                    !!this.contextLead.scraper_description ||
                    !!this.contextLead.chat
                );
            },
        },

        watch: {
            contextLead: {
                handler(newLead) {
                    // Reset default values
                    this.leadTypeEditable = false;

                    if (!this.leadIsServiceDivision(newLead) || !newLead.exchange_vehicles.length) {
                        return;
                    }

                    if (newLead.exchange_vehicles.length == 1 && !newLead.exchange_vehicles[0].service) {
                        this.updateLeadVehicleAction({ service: true }, newLead.exchange_vehicles[0].id);
                        return;
                    }

                    this.shouldOpenServiceVehicleSelector(newLead);
                },
                deep: true,
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadVehicleAction']),

            getDate(date) {
                return new ActivixDate(date).toHumanShort(false);
            },

            openNavigationHistory() {
                this.navigationHistoryModalOpened = !this.navigationHistoryModalOpened;
            },

            validateLeadType(newType) {
                const currentType = this.contextLead.lead_type_id;
                const contextLeadVehicles = this.contextLead.exchange_vehicles;

                if (newType == currentType) {
                    return true;
                }

                if (
                    [LeadType.LOYALTY, LeadType.RENEWAL, LeadType.WEB_ORDER].includes(newType) &&
                    this.contextLead.division_id == Division.SERVICE
                ) {
                    return false;
                }

                // If the type renewal validate that it has a current vehicle with an end contract date or a cash modality
                if (newType == LeadType.RENEWAL) {
                    if (empty(contextLeadVehicles)) {
                        this.leadTypeValidationModal.content = this.$t('clientCard.needOneCurrentVehicle');
                        this.leadTypeValidationModal.opened = true;
                        return false;
                    }

                    if (contextLeadVehicles.length > 0) {
                        for (let i = contextLeadVehicles.length - 1; i >= 0; i--) {
                            if (
                                contextLeadVehicles[i].modality != 'cash' &&
                                !contextLeadVehicles[i].end_contract_date
                            ) {
                                this.leadTypeValidationModal.content = this.$t('clientCard.endContractRequired');
                                this.leadTypeValidationModal.opened = true;
                                return false;
                            }
                        }
                    }
                }

                return true;
            },

            onEditLeadType() {
                if (this.leadTypeDisabled) {
                    return;
                }
                this.changeLeadTypeModalOpened = true;
            },

            leadIsPrebookingType(lead) {
                return lead.lead_type_id == 8;
            },

            leadIsDmsType(lead) {
                return lead.lead_type_id == LeadType.DMS;
            },

            leadIsServiceDivision(lead) {
                return lead.division_id == 3;
            },

            leadHasSoldServiceProducts(lead) {
                return lead.products.some(product => product.pivot.sold && product.type == 'service');
            },

            openOriginalEmailModal() {
                this.originalEmailModalOpened = true;
            },

            openSelectServiceVehicleModal() {
                this.selectServiceVehicleModalOpened = true;
            },

            shouldOpenServiceVehicleSelector(lead) {
                if (!this.leadIsServiceDivision(lead)) {
                    return;
                }

                if (lead.exchange_vehicles.length > 1) {
                    const serviceVehicles = lead.exchange_vehicles.filter(vehicle => vehicle.service);

                    if (empty(serviceVehicles)) {
                        this.openSelectServiceVehicleModal();
                    }
                }
            },
        },

        mounted() {
            this.shouldOpenServiceVehicleSelector(this.contextLead);
        },
    };
</script>
