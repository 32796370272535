<template>
    <div
        class="relative flex items-center justify-center h-12 w-12 rounded-full text-2xl border border-2 border-opacity-0 text-gray-600 bg-gray-200"
    >
        <icon :name="$icons.camera" />
        <communication-direction-icon :communication="communication" />
    </div>
</template>

<script>
    import CommunicationDirectionIcon from '@/components/container/lead/communications/CommunicationDirectionIcon.vue';

    import Communication from '@/entities/Communication.js';

    export default {
        components: { CommunicationDirectionIcon },

        props: {
            communication: {
                required: true,
                type: Communication,
            },
        },
    };
</script>
