<template>
    <div>
        <checkbox-date-picker
            :disabled="disabled"
            :date="recordedDate"
            @check="onCheckboxClick"
            @date-click="onDateClick"
        />

        <remove-follow-up
            follow-up="recorded"
            :opened.sync="removeFollowUpModalOpened"
            @approve="updateVehicle({ recorded_date: null })"
            @closed="removeFollowUpModalOpened = false"
        />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import CheckboxDatePicker from '@/components/container/input/CheckboxDatePicker.vue';
    import RemoveFollowUp from '@/components/modals/RemoveFollowUp.vue';

    import Lead from '@/entities/Lead.js';

    export default {
        components: {
            CheckboxDatePicker,
            RemoveFollowUp,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        data() {
            return {
                removeFollowUpModalOpened: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            disabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.$can('leads.recorded:update')) {
                    return true;
                }

                return this.guest;
            },

            missingSoldVehicle() {
                return !this.lead.getSoldVehicle() && this.lead.wanted_vehicles.length > 1;
            },

            recordedDate() {
                return this.lead.getSoldOrFirstWantedVehicle()?.recorded_date;
            },
        },

        methods: {
            onCheckboxClick(isChecked) {
                if (this.disabled) {
                    return;
                }

                if (!isChecked) {
                    this.removeFollowUpModalOpened = true;
                } else if (this.missingSoldVehicle) {
                    this.openSelectVehicleModal();
                } else {
                    this.openFollowUpModal();
                }
            },

            onDateClick() {
                if (this.disabled) {
                    return;
                }

                this.openFollowUpModal();
            },

            openFollowUpModal() {
                this.$modal.show('followUp', {
                    leadId: this.lead.id,
                    current: 'recorded',
                });
            },

            openSelectVehicleModal() {
                this.$modal.show('selectVehicle', {
                    leadId: this.lead.id,
                    step: 'recorded',
                });
            },

            updateVehicle(data, options = {}) {
                this.$emit('update-vehicle', { data, options });
            },
        },
    };
</script>
