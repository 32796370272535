var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"p-12 border-b border-gray-200 bg-gray-100"},[_c('figure',{staticClass:"relative flex justify-center items-center"},[_c('progress',{staticClass:"w-full h-3px",attrs:{"max":"100"},domProps:{"value":_vm.percentValue}}),_c('div',{staticClass:"absolute flex justify-between items-center w-full"},_vm._l((_vm.steps),function(step,stepIndex){return _c('activix-tooltip',{key:stepIndex,attrs:{"content":step.tooltip}},[_c('div',{staticClass:"absolute flex-shrink-0 transform rounded-full",class:{
                        'flex items-center justify-center w-9 h-9 shadow bg-white -translate-x-5 | -translate-x-6 md:w-12 md:h-12': step.icon,
                        'w-3 h-3 -translate-x-1.5': !step.icon,
                        'text-gray-600': step.icon && stepIndex > _vm.lastValidStepIndex,
                        'text-blue-500': step.icon && stepIndex <= _vm.lastValidStepIndex,
                        'bg-gray-500' : !step.icon && stepIndex > _vm.lastValidStepIndex,
                        'bg-blue-500' : !step.icon && stepIndex <= _vm.lastValidStepIndex,
                    },style:({ marginLeft: ((_vm.stepsInterval * stepIndex) + "%") })},[(step.icon)?_c('icon',{staticClass:"text-lg | md:text-xl",attrs:{"name":step.icon}}):_vm._e()],1)])}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }