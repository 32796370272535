<template>
    <activix-modal
        size="lg"
        :opened="opened"
        :closable="false"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('modal.vehicleDamageCostTitle') }}
            </h4>
        </template>

        <template slot="body">
            <div class="form-horizontal">
                <accidented-vehicle-part
                    :name="part.name"
                    :cost.sync="part.cost"
                    :description.sync="part.description"
                    :key="part.part_id"
                    v-for="part in damageParts"
                />
                <div class="lg:flex lg:items-center mb-6 mx-2">
                    <div class="w-1/4 mx-2">
                        <label class="block text-grey-500 font-bold lg:text-left mb-1 lg:mb-0 ml-4">
                            {{ $t('clientCard.leadVehicleAccidented.total') }}
                        </label>
                    </div>
                    <div class="w-1/6 mx-2 text-center font-bold">
                        {{ toMoney(damageTotal, 2, $i18n.locale) }}
                    </div>
                </div>
            </div>
        </template>

        <template slot="footer">
            <div class="flex items-center justify-end">
                <div>
                    <activix-button @click="cancel">
                        {{ $t('general.cancel') }}
                    </activix-button>
                    <activix-tooltip :content="isReadOnly ? $t('modal.readOnlyNotAllowed') : ''">
                        <span class="ml-2">
                            <activix-button type="primary" :disabled="isReadOnly" @click="save">
                                {{ $t('modal.save') }}
                            </activix-button>
                        </span>
                    </activix-tooltip>
                </div>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    // Components
    import AccidentedVehiclePart from '../lead/AccidentedVehiclePart.vue';
    import { useGlobalStore } from '../../store/store.js';
    import { useClientCardStore } from '../../store/modules/clientCard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        name: 'AccidentedVehicle',

        components: { AccidentedVehiclePart },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            vehicle: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                damageParts: [],
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useClientCardStore, ['accidentedParts']),
            ...mapState(useGlobalStore, ['authUser']),

            damageTotal() {
                return this.damageParts.reduce((a, b) => +a + +b.cost, 0);
            },

            isReadOnly() {
                return (
                    !this.authUserIsOwner &&
                    !this.authUser.isAdmin() &&
                    !this.authUser.isDirector() &&
                    !this.authUser.isBdcDirector() &&
                    !this.authUser.isCommercialDirector()
                );
            },

            authUserIsOwner() {
                return [
                    this.contextLead.user_id,
                    this.contextLead.bdc_user_id,
                    this.contextLead.commercial_id,
                ].includes(this.authUser.id);
            },
        },

        watch: {
            'vehicle.id': {
                immmediate: true,
                handler(newValue) {
                    if (newValue) {
                        this.getAccidentedVehicle();
                    }
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadVehicleAccidentedParts', 'updateLeadVehicleAction']),

            getAccidentedVehicle() {
                const extendParts = [];
                this.accidentedParts.forEach(part => {
                    extendParts.push({
                        lead_vehicle_id: this.vehicleId,
                        part_id: part.id,
                        name: part.text,
                        cost: null,
                        description: null,
                    });
                });

                if (!empty(this.vehicle.accidented_parts)) {
                    this.vehicle.accidented_parts.forEach(vehiclePart => {
                        const replacedPart = extendParts.find(
                            templatePart => vehiclePart.part_id == templatePart.part_id,
                        );

                        if (replacedPart) {
                            replacedPart.cost = vehiclePart.cost;
                            replacedPart.description = vehiclePart.description;
                        }
                    });
                }

                extendParts.sort((a, b) => {
                    return a.part_id - b.part_id;
                });

                this.damageParts = extendParts;
            },

            close() {
                this.$emit('update:opened', false);
            },

            cancel() {
                this.getAccidentedVehicle();
                this.close();
            },

            onClosed() {
                this.close();
            },

            async save() {
                if (!this.isReadOnly) {
                    await this.updateDamagePartsOnVehicle();
                    await this.updateAccidentedDamageCost();
                    this.close();
                }
            },

            async updateDamagePartsOnVehicle() {
                this.damageParts.forEach(damage => {
                    if (damage.cost || damage.description) {
                        damage.lead_vehicle_id = this.vehicle.id;
                    }
                });

                const payload = {
                    accidented_parts: this.damageParts,
                };

                await this.updateLeadVehicleAccidentedParts(payload, this.vehicle.id, this.contextLead.id);
            },

            updateAccidentedDamageCost() {
                const payload = {
                    accidented_damage_cost: this.damageTotal,
                };

                this.updateLeadVehicleAction(payload, this.vehicle.id);
            },
        },

        mounted() {
            this.getAccidentedVehicle();
        },
    };
</script>
