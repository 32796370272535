import Model from './Model.js';
import ActivixDate from '../value-objects/ActivixDate.js';

export default class FeaturePreview extends Model {
    static get defaults() {
        return {
        };
    }

    get isNew() {
        const availableAt = new ActivixDate(this.available_at);

        if (availableAt.isEmpty()) {
            return false;
        }

        const now = new ActivixDate('now');

        return now.diffInDays(availableAt) < 30;
    }

    get isActive() {
        return !!this.activated_at;
    }
}
