<template>
    <div class="flex items-center space-x-2">
        <lead-input
            :disabled="disabled || saving"
            :center-icon="centerIcon"
            :editing="editing"
            :valid="valid"
            @accept="triggerAccept"
            @cancel="cancel"
            @edit="editField"
            @blur="onBlur"
        >
            <activix-masked-input
                mask-type="email"
                class="w-full"
                :input-class="['input-sm', { 'p-0 border-none bg-transparent': lgLayout }]"
                :disabled="disabled || saving"
                ref="input"
                v-model="currentValue"
            />

            <template #value>
                <div class="flex items-center">
                    <template v-if="showMasked">
                        {{ maskEmail(currentValue) }}
                    </template>
                    <template v-else>
                        {{ !empty(currentValue) ? currentValue : '...' }}
                    </template>

                    <div class="ml-2" @click="showEmail = true" v-if="showMasked">
                        <icon class="link-grey-light" :name="$icons.email" v-if="!showEmail" />
                        <span class="text-grey-500" v-else>{{ countdown }}</span>
                    </div>
                </div>
            </template>
        </lead-input>
        <transition appear name="fade">
            <email-validation
                :email="currentLeadEmail"
                v-if="currentLeadEmail.email"
            />
        </transition>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import LeadEmail from '@/entities/LeadEmail.js';
    import LeadInput from '../LeadInput.vue';
    import EmailValidation from '../EmailValidation.vue';
    import { useGlobalStore } from '../../../store/store.js';
    import { useContextStore } from '../../../store/modules/context/store.js';

    export default {
        components: {
            LeadInput,
            EmailValidation,
        },

        props: {
            name: String,
            value: [String, Number],
            placeholder: String,
            disabled: Boolean,
            masked: Boolean,
            recordId: Number,
            type: {
                type: String,
                required: true,
            },
            valid: {
                type: Boolean,
                default: true,
            },
            extension: {
                type: Boolean,
                default: false,
            },
            centerIcon: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                currentValue: '',
                editing: false,
                loading: false,
                showEmail: false,
                saving: false,
                countdown: 4,
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),

            showMasked() {
                return this.masked && this.valid && this.countdown > 0 && !empty(this.currentValue);
            },

            currentLeadEmail() {
                return new LeadEmail(
                    this.contextLead.emails.find(leadEmail => leadEmail.id == this.recordId),
                );
            },
        },

        watch: {
            showEmail(newValue) {
                if (!newValue) {
                    this.countdown = 4;
                    return;
                }

                this.reduceCountDown();
            },

            value() {
                if (empty(this.value) || this.value === false) {
                    this.currentValue = null;
                    return;
                }

                this.currentValue = this.value;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadEmailAction', 'addLeadEmailAction', 'deleteLeadEmailAction']),

            reduceCountDown() {
                this.countdown--;

                if (this.countdown > 0) {
                    setTimeout(() => {
                        this.reduceCountDown();
                    }, 1000);
                }
            },

            async editField() {
                if (this.disabled || this.showMasked) {
                    return;
                }

                this.editing = true;

                await this.$nextTick();

                $(this.$refs.input).focus();
            },

            triggerAccept() {
                if (!this.editing || this.saving) {
                    return;
                }

                this.saving = true;

                this.accept(this.currentValue);
            },

            async accept(value) {
                // Only update if value changed
                if (value == this.value || (empty(value) && empty(this.value))) {
                    this.editing = false;
                    this.saving = false;
                    return;
                }

                this.loading = true;

                const payload = {
                    email: value,
                    type: this.type,
                    lead_id: this.contextLead.id,
                };

                if (empty(this.recordId)) {
                    await this.addLeadEmailAction(payload, this.contextLead.id);
                } else if (empty(value)) {
                    await this.deleteLeadEmailAction(this.recordId);
                } else {
                    await this.updateLeadEmailAction(payload, this.recordId, this.contextLead.id);
                }

                this.editing = false;
                this.saving = false;
                this.loading = false;
            },

            cancel() {
                this.editing = false;
                this.saving = false;
                this.currentValue = this.value;
            },

            init() {
                if (empty(this.value) || !this.masked || !this.valid) {
                    this.showEmail = true;
                }

                if (!empty(this.value)) {
                    this.currentValue = this.decodeString(this.value);
                }
            },

            onBlur() {
                // Delay is necessary to prevent event overlapping
                setTimeout(() => {
                    this.triggerAccept();
                }, 50);
            },
        },

        created() {
            this.init();
        },
    };
</script>
