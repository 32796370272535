<template>
    <activix-button
        class="rounded"
        type="white"
        v-popover:leadManagement
        v-if="userHasAccessToActions"
    >
        <icon name="regular/cog" />

        <el-popover
            placement="bottom-end"
            trigger="click"
            ref="leadManagement"
            @click.stop
        >
            <div class="p-6 space-y-3 bg-white" v-if="hasOptions">
                <div class="flex justify-between items-center gap-6" v-if="showFreezeFinancialData">
                    <span>{{ $t('clientCard.freezedFinancialData') }}</span>
                    <activix-switcher :value="lead.freeze_financial_data" @input="updateLead({ freeze_financial_data: $event })" />
                </div>
            </div>

            <div class="flex p-6 rounded-b-lg border-t border-gray-200 bg-gray-50" v-if="showDeleteAction">
                <activix-button
                    class="flex-1"
                    type="danger"
                    @click="deleteLead"
                    v-text="$t('clientCard.deleteLead')"
                />
            </div>
        </el-popover>
    </activix-button>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    import Lead from '@/entities/Lead.js';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            freezeFinancialDataTooltip() {
                if (this.lead.freeze_financial_data) {
                    return this.$t('clientCard.unfreezedFinancialData');
                }

                return this.$t('clientCard.freezedFinancialData');
            },

            hasOptions() {
                return this.showFreezeFinancialData;
            },

            showDeleteAction() {
                return this.authUser.isAdmin() || this.authUser.custom_permissions.delete_lead;
            },

            showFreezeFinancialData() {
                return this.lead.account.commercial && this.authUser.freeze_financial_data;
            },

            userHasAccessToActions() {
                return this.showFreezeFinancialData || this.showDeleteAction;
            },
        },

        methods: {
            deleteLead() {
                this.$refs.leadManagement?.doClose();

                this.$emit('delete-lead');
            },

            updateLead(data) {
                this.$emit('update-lead', { data });
            },
        },
    };
</script>
