<template>
    <input-list :fields="fields" />
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import ActivixCheckbox from '@/components/elements/ActivixCheckbox';
    import AppointmentInput from '@/components/container/input/lead/AppointmentInput.vue';
    import AvailableInput from '@/components/container/input/lead/AvailableInput.vue';
    import BeBackInput from '@/components/container/input/lead/BeBackInput.vue';
    import InputList from '@/components/container/input/InputList.vue';
    import InvisibleInput from '@/components/container/input/InvisibleInput.vue';
    import PhoneAppointmentInput from '@/components/container/input/lead/PhoneAppointmentInput.vue';
    import PresentedInput from '@/components/container/input/lead/PresentedInput.vue';
    import RefinancedInput from '@/components/container/input/lead/RefinancedInput.vue';
    import RoadTestInput from '@/components/container/input/lead/RoadTestInput.vue';
    import SaleInput from '@/components/container/input/lead/SaleInput.vue';
    import TakeOverInput from '@/components/container/input/lead/TakeOverInput.vue';
    import VerifiedSaleInput from '@/components/container/input/lead/VerifiedSaleInput.vue';

    import Lead from '@/entities/Lead.js';
    import LeadType from '@/entities/LeadType.js';

    export default {
        components: {
            InputList,
        },

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            disabledTooltip: {
                type: String,
                default: '',
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            fields() {
                return [
                    {
                        column: 1,
                        visible: this.showPhoneAppointment,
                        label: this.$t('result.appointment'),
                        icon: this.$icons.phoneAppointment,
                        component: PhoneAppointmentInput,
                        props: {
                            disabled: this.disabled,
                            disabledTooltip: this.disabledTooltip,
                            lead: this.lead,
                        },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 1,
                        visible: this.showAppointment,
                        label: this.$t('result.appointment'),
                        component: AppointmentInput,
                        props: {
                            disabled: this.disabled,
                            disabledTooltip: this.disabledTooltip,
                            lead: this.lead,
                        },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasPerformanceField('presented'),
                        label: this.$t('result.presented'),
                        icon: this.lead.home_presented_date ? this.$icons.homeVisit : '',
                        component: PresentedInput,
                        props: {
                            disabled: this.disabled,
                            disabledTooltip: this.disabledTooltip,
                            lead: this.lead,
                        },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasPerformanceField('road_test'),
                        label: this.$t('result.roadTest'),
                        icon: this.lead.home_road_test_date ? this.$icons.homeRoadTest : '',
                        component: RoadTestInput,
                        props: {
                            disabled: this.disabled,
                            disabledTooltip: this.disabledTooltip,
                            lead: this.lead,
                        },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasPerformanceField('long_term'),
                        label: this.$t('result.longTerm'),
                        tooltip: this.disabledTooltip || this.longTermTooltip,
                        component: ActivixCheckbox,
                        props: {
                            disabled: this.disabled || this.longTermDisabled,
                            size: 'lg',
                            value: this.lead.long_term,
                        },
                        events: { input: value => this.onLeadInput({ long_term: value }) },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasPerformanceField('deposit'),
                        label: this.$t('clientCard.deposit'),
                        tooltip: this.disabledTooltip || this.depositTooltip,
                        component: ActivixCheckbox,
                        props: {
                            disabled: this.disabled || this.depositDisabled,
                            size: 'lg',
                            value: this.lead.deposit,
                        },
                        events: { input: value => this.onLeadInput({ deposit: value }) },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasPerformanceField('write_up'),
                        label: this.$t('clientCard.writeUp'),
                        tooltip: this.disabledTooltip || this.writeUpTooltip,
                        component: ActivixCheckbox,
                        props: {
                            disabled: this.disabled || this.writeUpDisabled,
                            size: 'lg',
                            value: this.lead.write_up,
                        },
                        events: { input: value => this.onLeadInput({ write_up: value }) },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasPerformanceField('sale'),
                        label: this.saleLabel,
                        icon: this.lead.sale_by_phone ? this.$icons.phoneAppointment : '',
                        component: SaleInput,
                        props: {
                            disabled: this.disabled,
                            disabledTooltip: this.disabledTooltip,
                            lead: this.lead,
                        },
                        events: {
                            'update-lead': this.updateLead,
                            'update-vehicle': this.updateVehicle,
                        },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasPerformanceField('refinanced') && this.lead.isOfType(LeadType.RENEWAL),
                        label: this.$t('accounts.edit.refinanced'),
                        component: RefinancedInput,
                        props: {
                            disabled: this.disabled,
                            disabledTooltip: this.disabledTooltip,
                            lead: this.lead,
                        },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.hasProcessField('available'),
                        label: this.$t('clientCard.boxes.salesProcess.deliveryPrepDate'),
                        component: AvailableInput,
                        props: {
                            disabled: this.disabled,
                            disabledTooltip: this.disabledTooltip,
                            lead: this.lead,
                        },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 1,
                        visible: this.lead.account.waiting_sale_option &&
                            this.lead.account.hasProcessField('waiting_sale'),
                        label: this.$t('clientCard.boxes.salesProcess.waitingSale'),
                        tooltip: this.disabledTooltip || this.waitingSaleTooltip,
                        component: ActivixCheckbox,
                        props: {
                            disabled: this.disabled || this.waitingSaleDisabled,
                            size: 'lg',
                            value: this.lead.waiting_sale,
                        },
                        events: { input: value => this.onLeadInput({ waiting_sale: value }) },
                    },
                    {
                        column: 2,
                        visible: this.lead.account.hasPerformanceField('qualification'),
                        label: this.$t('clientCard.qualification'),
                        tooltip: this.disabledTooltip || this.qualificationTooltip,
                        component: InvisibleInput,
                        props: {
                            readonly: this.disabled || this.qualificationDisabled,
                            value: this.lead.qualification,
                        },
                        events: { input: value => this.onLeadInput({ qualification: value }) },
                    },
                    {
                        column: 2,
                        visible: this.lead.account.hasPerformanceField('walk_around'),
                        label: this.$t('clientCard.walkAround'),
                        tooltip: this.disabledTooltip || this.walkAroundTooltip,
                        component: ActivixCheckbox,
                        props: {
                            disabled: this.disabled || this.walkAroundDisabled,
                            size: 'lg',
                            value: this.lead.walk_around,
                        },
                        events: { input: value => this.onLeadInput({ walk_around: value }) },
                    },
                    {
                        column: 2,
                        visible: this.lead.account.hasPerformanceField('dealer_tour'),
                        label: this.$t('clientCard.dealerTour'),
                        tooltip: this.disabledTooltip || this.dealerTourTooltip,
                        component: ActivixCheckbox,
                        props: {
                            disabled: this.disabled || this.dealerTourDisabled,
                            size: 'lg',
                            value: this.lead.dealer_tour,
                        },
                        events: { input: value => this.onLeadInput({ dealer_tour: value }) },
                    },
                    {
                        column: 2,
                        visible: this.lead.account.hasPerformanceField('take_over'),
                        label: this.$t('clientCard.boxes.salesProcess.takeOver'),
                        component: TakeOverInput,
                        props: {
                            disabled: this.disabled,
                            disabledTooltip: this.disabledTooltip,
                            lead: this.lead,
                        },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 2,
                        visible: this.lead.account.hasPerformanceField('be_back'),
                        label: this.$t('clientCard.boxes.salesProcess.beBack'),
                        component: BeBackInput,
                        props: {
                            disabled: this.disabled,
                            disabledTooltip: this.disabledTooltip,
                            lead: this.lead,
                        },
                        events: { 'update-lead': this.updateLead },
                    },
                    {
                        column: 2,
                        visible: this.lead.account.hasPerformanceField('twenty_four_hour'),
                        label: this.$t('result.twentyFourHr'),
                        tooltip: this.disabledTooltip || this.twentyFourHourTooltip,
                        component: ActivixCheckbox,
                        props: {
                            disabled: this.disabled || this.twentyFourHourDisabled,
                            size: 'lg',
                            value: this.lead.twenty_four_hour,
                        },
                        events: { input: value => this.onLeadInput({ twenty_four_hour: value }) },
                    },
                    {
                        column: 2,
                        visible: this.saleMayBeVerified,
                        label: this.$t('clientCard.vehicles.verifiedTooltip'),
                        component: VerifiedSaleInput,
                        props: {
                            disabled: this.disabled,
                            disabledTooltip: this.disabledTooltip,
                            lead: this.lead,
                        },
                        events: { 'update-vehicle': this.updateVehicle },
                    },
                ];
            },

            dealerTourDisabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.$can('leads.dealer_tour:update')) {
                    return true;
                }

                return this.guest;
            },

            dealerTourTooltip() {
                if (this.lead.hasInvalidStatus()) {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.dealerTour'),
                    ])}`;
                }

                return '';
            },

            depositDisabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.$can('leads.deposit:update')) {
                    return true;
                }

                return this.guest;
            },

            depositTooltip() {
                if (this.lead.hasInvalidStatus()) {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.deposit'),
                    ])}`;
                }

                return '';
            },

            longTermDisabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                return this.guest;
            },

            longTermTooltip() {
                if (this.lead.hasInvalidStatus()) {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.longTerm'),
                    ])}`;
                }

                return '';
            },

            qualificationDisabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.$can('leads.qualification:update')) {
                    return true;
                }

                return this.guest;
            },

            qualificationTooltip() {
                if (this.lead.hasInvalidStatus()) {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.qualification'),
                    ])}`;
                }

                return '';
            },

            saleLabel() {
                if (this.lead.isOfType(LeadType.RENEWAL)) {
                    return this.$t('result.renewed');
                }

                return this.$t('result.sale');
            },

            saleMayBeVerified() {
                return (
                    this.lead.account.verified_sale &&
                    this.lead.isSoldOrRefinanced() &&
                    !!this.lead.getSoldVehicle()
                );
            },

            showAppointment() {
                if (!this.lead.account.hasPerformanceField('appointment')) {
                    return false;
                }

                if (this.lead.isOfType(LeadType.WALK_IN)) {
                    return false;
                }

                return !this.lead.appt_call_date || !!this.lead.appointment_date;
            },

            showPhoneAppointment() {
                if (!this.lead.account.hasPerformanceField('phone_appointment')) {
                    return false;
                }

                return this.lead.isNotOfType(LeadType.WALK_IN) && !!this.lead.appt_call_date;
            },

            twentyFourHourDisabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.$can('leads.twenty_four_hour:update')) {
                    return true;
                }

                return this.guest;
            },

            twentyFourHourTooltip() {
                if (this.lead.hasInvalidStatus()) {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.twentyFourHour'),
                    ])}`;
                }

                return '';
            },

            waitingSaleDisabled() {
                if (this.lead.hasInvalidStatus() ||
                    !this.lead.sale_date ||
                    this.lead.delivery_date ||
                    this.lead.delivered_date) {
                    return true;
                }

                if (!this.$can('leads.waiting_sale:update')) {
                    return true;
                }

                return this.guest;
            },

            waitingSaleTooltip() {
                if (this.lead.hasInvalidStatus()) {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.waitingSale'),
                    ])}`;
                }

                if (!this.lead.sale_date) {
                    if (this.lead.isOfType(LeadType.RENEWAL)) {
                        return this.$t('clientCard.needRenewalChecked');
                    }

                    return this.$t('clientCard.needSaleChecked');
                }

                if (this.lead.delivered_date) {
                    return this.$t('clientCard.waitingSaleDisabled');
                }

                return this.$t('clientCard.waitingSaleTooltip');
            },

            walkAroundDisabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.$can('leads.walk_around:update')) {
                    return true;
                }

                return this.guest;
            },

            walkAroundTooltip() {
                if (this.lead.hasInvalidStatus()) {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.walkAround'),
                    ])}`;
                }

                return '';
            },

            writeUpDisabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                return this.guest;
            },

            writeUpTooltip() {
                if (this.lead.hasInvalidStatus()) {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.writeUp'),
                    ])}`;
                }

                return '';
            },
        },

        methods: {
            onLeadInput(data) {
                this.updateLead({ data });
            },

            updateLead({ data, options = {} }) {
                this.$emit('update-lead', { data, options });
            },

            updateVehicle({ data, id = null, options = {} }) {
                this.$emit('update-vehicle', { data, id, options });
            },
        },
    };
</script>
