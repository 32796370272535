<template>
    <box>
        <box-header slot="header">
            {{ $t('clientCard.boxes.salesProcess.title') }}
        </box-header>

        <template slot="content">
            <sales-process-progress-bar :lead="contextLead" />

            <div class="flex flex-grow flex-col">
                <box-content>
                    <sales-process
                        :disabled="disabledBySaleVerified"
                        :disabled-tooltip="disabledTooltip"
                        :lead="contextLead"
                        @update-lead="updateLead"
                        @update-vehicle="updateVehicle"
                    />
                </box-content>

                <template v-if="hasCustomFields">
                    <hr class="w-full h-px m-0 border-none bg-gray-200" />

                    <box-content class="flex-grow-0">
                        <sales-process-custom-fields
                            :lead="contextLead"
                            @update-lead="updateLead"
                        />
                    </box-content>
                </template>
            </div>
        </template>
    </box>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store';
    import { useGlobalStore } from '@/store/store.js';

    import Box from '@/components/presentational/boxes/Box';
    import BoxContent from '@/components/presentational/boxes/BoxContent.vue';
    import BoxHeader from '@/components/presentational/boxes/BoxHeader';
    import SalesProcess from '@/components/container/lead/SalesProcess';
    import SalesProcessCustomFields from '@/components/container/lead/SalesProcessCustomFields.vue';
    import SalesProcessProgressBar from '@/components/container/lead/input/SalesProcessProgressBar.vue';

    import ClientCardSection from '@/entities/ClientCardSection';

    export default {
        components: {
            Box,
            BoxContent,
            BoxHeader,
            SalesProcess,
            SalesProcessCustomFields,
            SalesProcessProgressBar,
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, ['contextLead']),

            disabledBySaleVerified() {
                return !!this.contextLead.disabledBySaleVerified(this.authUser);
            },

            disabledTooltip() {
                if (this.disabledBySaleVerified) {
                    return this.$t('clientCard.verifiedSaleUneditable');
                }

                return '';
            },

            hasCustomFields() {
                return this.contextLead.account.hasSectionCustomFields(ClientCardSection.PERFORMANCE);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'updateLeadVehicleAction']),

            updateLead({ data, options = {} }) {
                this.updateLeadAction(this.contextLead.id, data, options);
            },

            updateVehicle({ data, id = null, options = {} }) {
                const vehicleId = id || this.contextLead.getSoldOrFirstWantedVehicle().id;

                this.updateLeadVehicleAction(data, vehicleId, this.contextLead.id, options);
            },
        },
    };
</script>
