<template functional>
    <div
        class="relative pt-2"
        :class="[data.staticClass, { 'table-row h-12 pb-1 | lg:h-10 | md:pt-0 md:pb-0': !props.inline }]"
    >
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            inline: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
