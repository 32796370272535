<template>
    <div
        class="flex divide-x divide-gray-200"
        :class="{ 'flex-col': asDropdownItems }"
    >
        <button-icon
            class="py-3 !justify-start"
            :class="{
                'px-4': asDropdownItems,
                'px-6': !asDropdownItems,
                'hover:!bg-gray-100': asDropdownItems && !phoneIsDisabled,
            }"
            :disabled="phoneIsDisabled"
            :icon-name="$icons.phone"
            :indicator="preferenceIsPhone"
            :tooltip="phoneTooltip"
            :vertical-layout="asDropdownItems"
            @click="onPhoneClick"
        >
            <template #after-icon v-if="asDropdownItems">
                <span v-text="$t('clientCard.phone')" />
            </template>
        </button-icon>

        <button-icon
            class="py-3 !justify-start"
            :class="{
                'px-4': asDropdownItems,
                'px-6': !asDropdownItems,
                'hover:!bg-gray-100': asDropdownItems && !emailIsDisabled,
            }"
            :disabled="emailIsDisabled"
            :icon-name="$icons.email"
            :indicator="preferenceIsEmail"
            :tooltip="emailTooltip"
            :vertical-layout="asDropdownItems"
            @click="onEmailClick"
        >
            <template #after-icon v-if="asDropdownItems">
                <span v-text="$t('clientCard.email')" />
            </template>
        </button-icon>

        <button-icon
            class="py-3 !justify-start"
            :class="{
                'px-4': asDropdownItems,
                'px-6': !asDropdownItems,
                'hover:!bg-gray-100': asDropdownItems && !smsIsDisabled,
            }"
            :disabled="smsIsDisabled"
            :icon-name="$icons.sms"
            :indicator="preferenceIsSms"
            :tooltip="smsTooltip"
            :vertical-layout="asDropdownItems"
            @click="onSmsClick"
        >
            <template #after-icon v-if="asDropdownItems">
                <span v-text="$t('clientCard.sms')" />
            </template>
        </button-icon>

        <button-icon
            class="py-3 !justify-start"
            :class="{
                'px-4': asDropdownItems,
                'px-6': !asDropdownItems,
                'hover:!bg-gray-100': asDropdownItems && !videoIsDisabled,
            }"
            :data-intercom-target="videoIntercomLabel"
            :disabled="videoIsDisabled"
            :icon-name="$icons.camera"
            :indicator="preferenceIsSms"
            :tooltip="videoTooltip"
            @click="onVideoClick"
        >
            <template #after-icon v-if="asDropdownItems">
                <span v-text="$t('clientCard.videoconference')" />
            </template>
        </button-icon>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useClientCardStore } from '@/store/modules/clientCard/store.js';
    import { useGlobalStore } from '@/store/store.js';

    import LeadCommunicating from '@/mixins/LeadCommunicating.js';
    import LeadPhoneValidation from '@/mixins/LeadPhoneValidation.js';

    import ButtonIcon from '@/components/presentational/ButtonIcon';

    import ActivixDate from '@/value-objects/ActivixDate.js';
    import CommunicationPreference from '@/entities/CommunicationPreference.js';
    import Lead from '@/entities/Lead';

    export default {
        components: { ButtonIcon },

        mixins: [LeadCommunicating, LeadPhoneValidation],

        props: {
            asDropdownItems: {
                type: Boolean,
                default: false,
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useClientCardStore, ['authorizedAction']),
            ...mapState(useGlobalStore, ['authUser', 'communicatingLead', 'guest']),

            actionIsDisabled() {
                return !!this.lead.unsubscribe_all_date ||
                    (
                        (this.guest || !this.lead.account.access_all_leads_communications) &&
                        !this.authorizedAction
                    );
            },

            customerConsentInactive() {
                return this.$feature.isEnabled('casl_compliance') && !this.lead.customer.consentAllowsContact;
            },

            emailIsDisabled() {
                return this.leadIsAlreadyCommunicating ||
                    this.actionIsDisabled ||
                    !!this.lead.unsubscribe_email_date ||
                    !this.hasValidEmail ||
                    this.customerConsentInactive;
            },

            emailTooltip() {
                if (this.unsubscribeAllTooltip) {
                    return this.unsubscribeAllTooltip;
                }

                if (this.lead.unsubscribe_email_date) {
                    return this.$t('clientCard.clientIsEmailUnsubscribed');
                }

                if (this.customerConsentInactive) {
                    return `${this.$t('consent.consent')} ${this.$t('consent.statuses.expired').toLowerCase()}`;
                }

                if (!this.hasValidEmail) {
                    return this.$t('client.noEmail');
                }

                if (this.leadIsAlreadyCommunicatingTooltip) {
                    return this.leadIsAlreadyCommunicatingTooltip;
                }

                if (this.actionIsDisabled) {
                    return this.$t('clientCard.unauthorizedAction');
                }

                const tooltipParts = [];

                if (!this.asDropdownItems) {
                    tooltipParts.push(this.$t('clientCard.email'));
                }

                if (this.preferenceIsEmail) {
                    tooltipParts.push(this.$t('clientCard.communicationPreference'));
                }

                return tooltipParts.join(' - ');
            },

            hasValidEmail() {
                return this.lead.emails.some(email => email.valid);
            },

            hasNioText() {
                return this.lead.account.hasNioText() && this.authUser.hasNioText();
            },

            hasValidPhone() {
                return this.lead.phones.some(phone => phone.valid);
            },

            hasVideoconference() {
                return this.lead.account.video_conference;
            },

            leadIsAlreadyCommunicating() {
                return !!this.communicatingLead;
            },

            leadIsAlreadyCommunicatingTooltip() {
                if (!this.leadIsAlreadyCommunicating) {
                    return '';
                }

                return this.getLeadCommunicatingMessage(this.communicatingLead);
            },

            phoneIsDisabled() {
                return (
                    this.leadIsAlreadyCommunicating ||
                    this.actionIsDisabled ||
                    !!this.lead.unsubscribe_call_date ||
                    !this.hasValidPhone ||
                    !this.lead.account.outgoingPhoneProviders.length
                );
            },

            phoneTooltip() {
                if (this.unsubscribeAllTooltip) {
                    return this.unsubscribeAllTooltip;
                }

                if (this.lead.unsubscribe_call_date) {
                    return this.$t('clientCard.clientIsCallUnsubscribed');
                }

                if (!this.hasValidPhone) {
                    return this.$t('client.noPhone');
                }

                if (this.leadIsAlreadyCommunicatingTooltip) {
                    return this.leadIsAlreadyCommunicatingTooltip;
                }

                if (this.actionIsDisabled) {
                    return this.$t('clientCard.unauthorizedAction');
                }

                const tooltipParts = [];

                if (!this.asDropdownItems) {
                    tooltipParts.push(this.$t('clientCard.phone'));
                }

                if (this.preferenceIsPhone) {
                    tooltipParts.push(this.$t('clientCard.communicationPreference'));
                }

                return tooltipParts.join(' - ');
            },

            preferenceIsEmail() {
                return this.lead.communication_preference === CommunicationPreference.EMAIL;
            },

            preferenceIsPhone() {
                return this.lead.communication_preference === CommunicationPreference.PHONE;
            },

            preferenceIsSms() {
                return this.lead.communication_preference === CommunicationPreference.SMS;
            },

            smsIsDisabled() {
                return (
                    this.leadIsAlreadyCommunicating ||
                    this.customerConsentInactive ||
                    this.actionIsDisabled ||
                    !this.hasNioText ||
                    (!this.hasValidatedMobile(this.lead) && !this.hasNotValidatedPhone(this.lead)) ||
                    this.$wait.is('validating.leadPhones')
                );
            },

            smsTooltip() {
                if (!this.hasNioText) {
                    return this.$t('modal.niotextNeeded');
                }

                if (this.unsubscribeAllTooltip) {
                    return this.unsubscribeAllTooltip;
                }

                if (this.customerConsentInactive) {
                    return `${this.$t('consent.consent')} ${this.$t('consent.statuses.expired').toLowerCase()}`;
                }

                if (this.$wait.is('validating.leadPhones')) {
                    return this.$t('clientCard.validating');
                }

                if (!this.hasValidatedMobile(this.lead) && !this.hasNotValidatedPhone(this.lead)) {
                    return this.$t('client.noMobilePhone');
                }

                if (this.leadIsAlreadyCommunicatingTooltip) {
                    return this.leadIsAlreadyCommunicatingTooltip;
                }

                if (this.actionIsDisabled) {
                    return this.$t('clientCard.unauthorizedAction');
                }

                const tooltipParts = [];

                if (!this.asDropdownItems) {
                    tooltipParts.push(this.$t('clientCard.sms'));
                }

                if (this.preferenceIsSms) {
                    tooltipParts.push(this.$t('clientCard.communicationPreference'));
                }

                return tooltipParts.join(' - ');
            },

            unsubscribeAllTooltip() {
                if (!this.lead.unsubscribe_all_date) {
                    return '';
                }

                const unsubscribeAllDate = new ActivixDate(this.lead.unsubscribe_all_date);

                return unsubscribeAllDate.year === 2222
                    ? this.$t('clientCard.clientIsAllUnsubscribedForever')
                    : this.$t('clientCard.clientIsAllUnsubscribed', [unsubscribeAllDate.toHumanShort(true)]);
            },

            videoIsDisabled() {
                return (
                    this.leadIsAlreadyCommunicating ||
                    this.authUser.isAdmin() ||
                    this.$auth.impersonating() ||
                    !this.$auth.audienceIsInSync() ||
                    this.actionIsDisabled ||
                    !!this.lead.unsubscribe_call_date ||
                    !this.hasVideoconference ||
                    !this.hasValidEmail ||
                    !this.lead.fullName ||
                    this.customerConsentInactive
                );
            },

            videoIntercomLabel() {
                if (this.videoIsDisabled) {
                    return 'videoconference.disable';
                }

                return 'videoconference.call';
            },

            videoTooltip() {
                if (this.authUser.isAdmin()) {
                    return this.$t('communications.videoconference.adminUnauthorized');
                }

                if (this.$auth.impersonating()) {
                    return this.$t('communications.videoconference.impersonateUnauthorized');
                }

                if (!this.hasVideoconference) {
                    return this.$t('communications.videoconference.needVideoconference');
                }

                if (!this.$auth.audienceIsInSync()) {
                    return this.$t('communications.videoconference.needToReconnect');
                }

                if (this.unsubscribeAllTooltip) {
                    return this.unsubscribeAllTooltip;
                }

                if (this.lead.unsubscribe_call_date) {
                    return this.$t('clientCard.clientIsCallUnsubscribed');
                }

                if (this.customerConsentInactive) {
                    return `${this.$t('consent.consent')} ${this.$t('consent.statuses.expired').toLowerCase()}`;
                }

                if (!this.hasValidEmail) {
                    return this.$t('communications.videoconference.invitation.noContactInfo');
                }

                if (!this.lead.fullName) {
                    return this.$t('communications.videoconference.needClientName');
                }

                if (this.leadIsAlreadyCommunicatingTooltip) {
                    return this.leadIsAlreadyCommunicatingTooltip;
                }

                if (this.actionIsDisabled) {
                    return this.$t('clientCard.unauthorizedAction');
                }

                if (!this.asDropdownItems) {
                    return this.$t('clientCard.videoconference');
                }

                return '';
            },
        },

        methods: {
            onEmailClick() {
                if (this.emailIsDisabled) {
                    return;
                }

                this.$modal.show('lead:emailLead', this.lead.id);

                this.$emit('action-selected', 'email');
            },

            onPhoneClick() {
                if (this.phoneIsDisabled) {
                    return;
                }

                this.$modal.show('lead:callLead', this.lead.id);

                this.$emit('action-selected', 'phone');
            },

            onSmsClick() {
                if (this.smsIsDisabled) {
                    return;
                }

                this.$eventBus.$emit('open-sms-modal', { leadId: this.lead.id });

                this.$emit('action-selected', 'sms');
            },

            onVideoClick() {
                if (this.videoIsDisabled) {
                    return;
                }

                this.$behavior.track('VideoConference', { action: 'call', location: 'lead' });

                this.$modal.show('lead:videoconferenceInvitation', this.lead.id);

                this.$emit('action-selected', 'video');
            },
        },
    };
</script>
