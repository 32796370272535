<template>
    <custom-input-list
        section="processService"
        :column-count="3"
        :disabled="guest"
        :values="lead.custom_fields"
        @input="$emit('update-lead', $event)"
    />
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import CustomInputList from '@/components/presentational/input/CustomInputList.vue';

    import Lead from '@/entities/Lead.js';

    export default {
        components: {
            CustomInputList,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),
        },
    };
</script>
