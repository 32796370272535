<template>
    <activix-modal size="sm" :closable="false" :opened="opened">
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('clientCard.chooseServiceVehicle') }}
            </h4>
        </template>

        <template slot="body">
            <div class="text-center my-1" :key="vehicle.id" v-for="vehicle in contextLead.exchange_vehicles">
                <activix-radio name="vehicleService" :value="vehicle.id" v-model="serviceVehicle">
                    <template
                        v-if="
                            !empty(vehicle.make) ||
                                !empty(vehicle.model) ||
                                !empty(vehicle.version) ||
                                !empty(vehicle.year)
                        "
                    >
                        {{ vehicle.make }}
                        {{ vehicle.model }}
                        {{ vehicle.version }}
                        {{ vehicle.year }}
                    </template>

                    <template v-else>
                        {{ $t('clientCard.unknown') }}
                    </template>
                </activix-radio>
            </div>
        </template>

        <template slot="footer">
            <activix-button
                type="primary"
                :disabled="!serviceVehicle"
                :loading="loading"
                @click="submit"
            >
                {{ $t('general.save') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import { forEach } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import { useGlobalStore } from '../../store/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        props: {
            opened: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                serviceVehicle: null,
                loading: false,
            };
        },
        computed: {
            ...mapState(useContextStore, ['contextLead']),
        },

        watch: {
            contextLead() {
                this.serviceVehicle = null;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadVehicleAction']),

            async submit() {
                this.loading = true;

                this.updateLeadVehicleAction({ service: true }, this.serviceVehicle);

                forEach(this.contextLead.exchange_vehicles, vehicle => {
                    if (vehicle.service && vehicle.id != this.serviceVehicle) {
                        this.updateLeadVehicleAction({ service: false }, vehicle.id);
                    }
                });

                this.close();

                await this.$nextTick();

                this.loading = false;
            },

            close() {
                this.$emit('update:opened', false);
            },
        },
    };
</script>
