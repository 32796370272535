<template>
    <activix-tooltip :content="tooltip">
        <follow-up-field
            follow-up="deliverable"
            :date="lead.deliverable_date"
            :disabled="disabled"
            :follow-up-params="{ last: 'sale' }"
            :lead-id="lead.id"
            @input="updateLead({ deliverable_date: $event })"
        />
    </activix-tooltip>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import FollowUpField from '@/components/container/lead/input/FollowUpField';

    import AccountType from '@/entities/AccountType';
    import Lead from '@/entities/Lead';
    import LeadType from '@/entities/LeadType';

    export default {
        components: { FollowUpField },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            deliverableDate() {
                return this.lead.deliverable_date;
            },

            disabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.lead.sale_date && !this.lead.deliverable_date && (!this.lead.isOfType(LeadType.RENEWAL) || !this.lead.refinanced_date)) {
                    return true;
                }

                if (empty(this.soldVehicle) && !this.lead.deliverable_date && this.lead.account.type !== AccountType.CUSTOM && (!this.lead.isOfType(LeadType.RENEWAL) || !this.lead.refinanced_date)) {
                    return true;
                }

                if (this.lead.delivery_date || this.lead.delivered_date) {
                    return true;
                }

                if (this.lead.delivered_date && (!this.lead.isOfType(LeadType.RENEWAL) || !this.lead.refinanced_date)) {
                    return true;
                }

                if (!this.$can('leads.deliverable_date:update')) {
                    return true;
                }

                return this.guest && !this.lead.hasDeliveryMan(this.authUser.id);
            },

            soldVehicle() {
                return this.lead.getSoldVehicle();
            },

            tooltip() {
                if (this.guest) {
                    return this.$t('clientCard.guestMode');
                }

                if (!this.lead.sale_date && !this.lead.sale_by_phone && !this.lead.deliverable_date && (!this.lead.isOfType(LeadType.RENEWAL) || !this.lead.refinanced_date)) {
                    if (this.lead.isOfType(LeadType.RENEWAL)) {
                        return this.$t('clientCard.leadMustBeRenewed');
                    }

                    return this.$t('clientCard.leadMustBeSold');
                }

                if (!this.lead.hasInvalidStatus()) {
                    if (!this.lead.sale_date && !this.lead.deliverable_date && (!this.lead.isOfType(LeadType.RENEWAL) || !this.lead.refinanced_date)) {
                        if (this.lead.isOfType(LeadType.RENEWAL)) {
                            return this.$t('clientCard.needRenewalChecked');
                        }

                        return this.$t('clientCard.needSaleChecked');
                    }
                } else {
                    const statusKey = `clientCard.statusTooltip.${this.lead.status}`;

                    return `${this.$t(statusKey, [
                        this.$t('clientCard.statusTooltip.inputs.deliverable'),
                    ])}`;
                }

                if (empty(this.soldVehicle) && !this.lead.deliverable_date && (!this.lead.isOfType(LeadType.RENEWAL) || !this.lead.refinanced_date)) {
                    return this.$t('clientCard.leadMustHaveASoldWantedVehicle');
                }

                return '';
            },
        },

        methods: {
            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
