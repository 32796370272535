<template>
    <activix-tooltip :content="tooltip">
        <follow-up-field
            follow-up="take_over"
            :date="lead.take_over_date"
            :date-only="true"
            :disabled="isDisabled"
            :lead-id="lead.id"
            :remove-confirmation="false"
            @input="updateLead({ take_over_date: $event })"
        />
    </activix-tooltip>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import FollowUpField from '@/components/container/lead/input/FollowUpField.vue';

    import Lead from '@/entities/Lead.js';
    import LeadStatus from '@/entities/LeadStatus.js';

    export default {
        components: { FollowUpField },

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            disabledTooltip: {
                type: String,
                default: '',
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            isDisabled() {
                if (this.disabled) {
                    return true;
                }

                if ((!this.lead.presented_date && !this.lead.home_presented_date) || this.lead.status === LeadStatus.LOST) {
                    return true;
                }

                if (this.authUser.id) {
                    if (!this.authUser.isAuthorizedToActionOnLead(this.lead)) {
                        return true;
                    }

                    if (
                        !this.authUser.isDirector() &&
                        !this.authUser.isAdmin() &&
                        this.lead.account.take_over_director
                    ) {
                        return true;
                    }
                }

                if (!this.$can('leads.take_over_date:update')) {
                    return true;
                }

                return this.guest;
            },

            tooltip() {
                if (this.disabled) {
                    return this.disabledTooltip;
                }

                if (this.lead.hasInvalidStatus()) {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.takeOver'),
                    ])}`;
                }

                if (!this.lead.presented_date && !this.lead.home_presented_date) {
                    return this.$t('clientCard.needPresentedChecked');
                }

                let tooltip = '';

                if (
                    this.lead.presented_date &&
                    this.lead.account.take_over_director &&
                    !this.authUser.isDirector() &&
                    !this.authUser.isAdmin()
                ) {
                    tooltip += `<em>${this.$t('clientCard.needToBeDirector')}</em>`;
                }

                if (this.lead.take_over_director && this.lead.take_over_date) {
                    if (tooltip) {
                        tooltip += '<br>';
                    }

                    tooltip += `<strong>${this.$t('clientCard.takeOverBy')}</strong> : ${
                        this.lead.take_over_director.fullName
                    }`;
                }

                return tooltip;
            },
        },

        methods: {
            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
