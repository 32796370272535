<template>
    <div>
        <div class="w-full mt-2" v-if="mdLayout">
            <input
                class="w-full form-control"
                type="text"
                :placeholder="$t('search.search')"
                v-model="tableState.searchToken"
            />
        </div>

        <el-table
            class="w-full border"
            :border="true"
            :data="paginatedRows"
            :default-sort="{ prop: 'created_at', order: 'descending' }"
            :stripe="true"
            @sort-change="updateTableSorting"
        >
            <el-table-column
                :sortable="false"
                prop="raw.bulkSelect"
                header-align="center"
                align="center"
                class-name="text-center"
                width="40"
            >
                <template #header>
                    <activix-checkbox
                        :value="allSelected"
                        size="sm"
                        class-name="flex items-center justify-center"
                        @click.native.prevent="onSelectAll"
                    />
                </template>
                <template slot-scope="{ row }">
                    <activix-tooltip :content="disabledTooltip(row.lead)">
                        <activix-checkbox
                            :disabled="isDisabled(row.lead)"
                            :value="isChecked(row.lead)"
                            size="sm"
                            class-name="flex items-center justify-center"
                            @click="updateBulkSelect(row.lead)"
                        />
                    </activix-tooltip>
                </template>
            </el-table-column>
            <el-table-column
                header-align="center"
                label="Clients"
                prop="raw.name"
                :sortable="true"
            >
                <template slot-scope="{ row }">
                    <client-name :lead="row.lead" :light="true" />
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="raw.phones"
                :min-width="!xxlLayout ? '115' : '100'"
                :label="$t('clientCard.phone')"
                :sortable="true"
            >
                <template slot-scope="{ row }">
                    <div v-html="row.formatted.phones" />
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="raw.emails"
                :min-width="!xxlLayout ? '250' : '120'"
                :label="$t('clientCard.email')"
                :sortable="true"
            >
                <template slot-scope="{ row }">
                    <div v-html="row.formatted.emails" />
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="raw.vehicles"
                :label="$t('clientCard.vehicle')"
                :sortable="true"
            >
                <template slot-scope="{ row }">
                    <div v-html="row.formatted.vehicles" />
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="raw.vehiclesStockState"
                width="95"
                :label="$t('clientCard.stock')"
                :sortable="true"
            >
                <template slot-scope="{ row }">
                    <div v-html="row.formatted.vehiclesStockState" />
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="raw.users"
                :label="$t('clientCard.advisor')"
                :sortable="true"
            >
                <template slot-scope="{ row }">
                    <div v-html="row.formatted.users" />
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="Date"
                prop="raw.date"
                :sortable="true"
            >
                <template slot-scope="{ row }">
                    <div v-html="row.formatted.date" />
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="raw.performance"
                label="Performance"
                width="100"
                :sortable="true"
            >
                <template slot-scope="{ row }">
                    <div v-html="row.formatted.performance" />
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                width="60"
                :label="$t('clientCard.status')"
                :sortable="false"
            >
                <template slot-scope="{ row }">
                    <lead-status :lead="row.lead" :disabled="true" />
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="raw.type"
                width="120"
                :label="$t('clientCard.type')"
                :sortable="true"
            >
                <template slot-scope="{ row }">
                    <div v-html="row.formatted.type" />
                </template>
            </el-table-column>
        </el-table>
        <div class="row my-4">
            <div class="col-xs-6" v-if="!mdLayout">
                <input
                    class="form-control w-auto"
                    type="text"
                    :placeholder="$t('search.search')"
                    v-model="tableState.searchToken"
                />
            </div>
            <div class="col-xs-6 text-right">
                <el-pagination
                    :layout="duplicatePaginationLayout"
                    :background="true"
                    :current-page.sync="tableState.currentPage"
                    :page-size.sync="tableState.perPage"
                    :page-sizes="tableState.pageOptions"
                    :pager-count="5"
                    :total="filteredRows.length"
                    @size-change="updateTablePerPage"
                />
            </div>
        </div>
    </div>
</template>

<script>
    // Entities
    import LeadType from '@/entities/LeadType.js';

    /* eslint-disable vue/require-prop-types */
    import { orderBy } from 'lodash-es';
    import { mapState } from 'pinia';
    import { formater } from '../../globals/LeadFormatter.js';

    // Components
    import ClientName from '../dashboards/columns/ClientName.vue';
    import LeadStatus from '../dashboards/columns/LeadStatus.vue';

    // Mixins
    import DataTableMixin from '../../mixins/DataTable.js';
    import { useLeadStore } from '../../store/modules/lead/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            ClientName,
            LeadStatus,
        },

        mixins: [DataTableMixin],

        props: [
            'leads',
            'customMethod',
            'customMethodTwo',
            'action',
            'forceLoading',
            'fetching',
            'bulkSelectLeads',
            'parent',
        ],

        data() {
            return {
                changed: true,
            };
        },

        computed: {
            ...mapState(useLeadStore, ['currentMergeAction']),
            ...mapState(useContextStore, ['contextLead']),

            duplicatePaginationLayout() {
                return this.mdLayout ? 'total, jumper, prev, next' : 'total, sizes, prev, pager, next';
            },

            rows() {
                const getBulkSelectValue = lead => {
                    if (
                        this.bulkSelectLeads.indexOf(lead.id) != -1 &&
                        !empty(this.contextLead) &&
                        lead.id != this.contextLead.id
                    ) {
                        return 1;
                    }
                    if (
                        this.bulkSelectLeads.indexOf(lead.id) == -1 &&
                        !empty(this.contextLead) &&
                        lead.id != this.contextLead.id
                    ) {
                        return 2;
                    }

                    return 0;
                };

                return this.leads.map(lead => {
                    return {
                        lead,
                        formatted: {
                            date: formater.formatDate(lead) || '-',
                            emails: formater.formatEmail(lead, true) || '-',
                            performance: formater.formatPerformance(lead) || '-',
                            phones: formater.formatPhone(lead, true) || '-',
                            type: formater.formatType(lead) || '-',
                            users: formater.formatAgents(lead, true),
                            vehicles: formater.formatVehicleWanted(lead, true) || '-',
                            vehiclesStockState: formater.formatVehicleWantedStockState(lead, true) || '-',
                        },
                        raw: {
                            bulkSelect: getBulkSelectValue(lead),
                            date: formater.formatDate(lead, null, null, '', false),
                            emails: formater.formatEmail(lead, false),
                            name: formater.formatClientName(lead),
                            performance: formater.formatPerformance(lead),
                            phones: formater.formatPhone(lead, false),
                            type: formater.formatType(lead),
                            users: formater.formatAgents(lead, false),
                            vehicles: formater.formatVehicleWanted(lead, false),
                            vehiclesStockState: formater.formatVehicleWantedStockState(lead, false),
                        },
                    };
                });
            },

            filteredRows() {
                const searchedData = this.searchData(this.tableState.searchToken, this.rows);
                const sortedData = orderBy(
                    searchedData,
                    [this.tableState.sorting.column],
                    [this.tableState.sorting.order],
                );

                return sortedData;
            },

            paginatedRows() {
                return this.filteredRows.slice(
                    (this.tableState.currentPage - 1) * this.tableState.perPage,
                    this.tableState.currentPage * this.tableState.perPage,
                );
            },

            allSelected() {
                return this.rows.every(row => {
                    return this.bulkSelectLeads.includes(row.lead.id);
                });
            },
        },

        watch: {
            currentMergeAction() {
                this.fillBulkSelect();
            },
        },

        methods: {
            relatedEventLead(mainLead) {
                return this.leads.filter(lead => {
                    return lead.lead_type_id === LeadType.EVENT;
                }).some(eventLead => {
                    return eventLead.event_related_id === mainLead.id;
                });
            },

            isChecked(lead) {
                return lead.id == this.contextLead.id || this.bulkSelectLeads.includes(lead.id);
            },

            isAssociateWithContextLead(lead) {
                return this.contextLead.customer_id === lead.customer.id;
            },

            isDisabled(lead) {
                if (lead.id === this.contextLead.id) {
                    return true;
                }

                if (lead.lead_type_id === LeadType.EVENT && lead.event_related_id && this.isAssociateWithContextLead(lead)) {
                    return true;
                }

                if (this.relatedEventLead(lead) && this.isAssociateWithContextLead(lead)) {
                    return true;
                }

                return (
                    this.currentMergeAction == 'merge' &&
                    this.bulkSelectLeads.length > 1 &&
                    !this.bulkSelectLeads.includes(lead.id)
                );
            },

            disabledTooltip(lead) {
                if (lead.id === this.contextLead.id) {
                    return this.$t('clientCard.cantSelectActualLead');
                }

                if (lead.lead_type_id === LeadType.EVENT && lead.event_related_id && this.isAssociateWithContextLead(lead)) {
                    return this.$t('clientCard.cantAssociateEventLead');
                }

                if (this.relatedEventLead(lead) && this.isAssociateWithContextLead(lead)) {
                    return this.$t('clientCard.cantAssociateLeadWithEventRelated');
                }

                return '';
            },

            updateBulkSelect(lead) {
                const value = !this.isChecked(lead);
                let bulkSelectLeads = !empty(this.bulkSelectLeads) ? this.bulkSelectLeads : [];

                const relatedEventLead = this.leads.filter(l => {
                    return l.event_related_id === lead.id;
                })[0];

                const leadIds = [lead.id];

                if (relatedEventLead) {
                    leadIds.push(relatedEventLead.id);
                }

                if (lead.event_related_id) {
                    leadIds.push(lead.event_related_id);
                }

                if (value) {
                    bulkSelectLeads = [...bulkSelectLeads, ...leadIds];
                } else if (!empty(bulkSelectLeads)) {
                    bulkSelectLeads = bulkSelectLeads.filter((value) => {
                        return !leadIds.includes(value);
                    });
                }

                this.$emit('update:bulkSelectLeads', bulkSelectLeads);
            },

            fillBulkSelect() {
                if (!this.contextLead.id) {
                    return;
                }

                let bulkSelectLeads = [this.contextLead.id];

                if (this.currentMergeAction == 'associate' && !empty(this.contextLead.customer)) {
                    bulkSelectLeads = this.contextLead.customer.leads.map(lead => lead.id);
                }

                this.$emit('update:bulkSelectLeads', bulkSelectLeads);
            },

            onSelectAll() {
                const unselectableLeads = this.leads.filter(lead => {
                    return (lead.lead_type_id === LeadType.EVENT || this.relatedEventLead(lead)) && this.isAssociateWithContextLead(lead);
                }).map(lead => lead.id);

                if (this.allSelected) {
                    this.$emit('update:bulkSelectLeads', [this.contextLead.id, ...unselectableLeads]);
                } else {
                    this.$emit(
                        'update:bulkSelectLeads',
                        this.rows.map(row => row.lead.id),
                    );
                }
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.fillBulkSelect();
            });
        },
    };
</script>
