<template>
    <flexible-input>
        <input-label slot="label" class="flex items-center" :blocked-reason="internalBlockedReason">
            {{ $t('clientCard.vehicles.vin') }}
            <activix-tooltip :content="tooltipVin" v-if="vehicle.exportSupplier">
                <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
            </activix-tooltip>
        </input-label>

        <activix-tooltip :content="vehicleValueTooltip">
            <invisible-input
                class="flex-1"
                :button-icon="decodingIcon"
                :button-icon-class-name="decodingIconClassName"
                :button-tooltip="$t('clientCard.vehicles.decodeVin')"
                :disabled="isDisabled"
                :value="vehicle.vin"
                @button-click="decodeVin"
                @input="onUpdateVin"
            />
        </activix-tooltip>

        <vin-missing-data
            :opened.sync="vinDataModalOpened"
            :vehicle="vehicle"
            :trims="availableTrims"
            :transmissions="availableTransmissions"
            :exterior-colors="availableExteriorColors"
            :interior-colors="availableInteriorColors"
        />
    </flexible-input>
</template>

<script>
    import { mapActions } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import FlexibleInput from '@/components/container/input/FlexibleInput.vue';
    import InputLabel from '@/components/presentational/input/InputLabel.vue';
    import InvisibleInput from '@/components/container/input/InvisibleInput';
    import VinMissingData from '@/components/modals/VinMissingData.vue';

    import Account from '@/entities/Account';
    import LeadVehicle from '@/entities/LeadVehicle.js';
    import LeadVehicleTransmission from '@/entities/LeadVehicleTransmission.js';

    export default {
        components: {
            FlexibleInput,
            InputLabel,
            InvisibleInput,
            VinMissingData,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            stockManagementEnabled: {
                type: Boolean,
                required: true,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        data: () => ({
            availableExteriorColors: [],
            availableInteriorColors: [],
            availableTrims: [],
            vinDataModalOpened: false,
        }),

        computed: {
            availableTransmissions() {
                return LeadVehicleTransmission.selectOptions('value', 'text');
            },

            decodingIcon() {
                if (!this.showVinDecoding || !this.vinMayBeDecoded) {
                    return '';
                }

                if (this.$wait.is('decoding.vin')) {
                    return this.$icons.loading;
                }

                return 'regular/search';
            },

            decodingIconClassName() {
                if (this.$wait.is('decoding.vin')) {
                    return 'spin-inverse text-md';
                }

                return '';
            },

            hasVehicleValue() {
                return !empty(this.vehicle.vehicle_value);
            },

            internalBlockedReason() {
                if (this.blockedReason) {
                    return this.blockedReason;
                }

                if (this.vehicle.isExported) {
                    return this.$t('clientCard.blocked.vehicleExported');
                }

                if (this.hasVehicleValue) {
                    return this.$t('clientCard.blocked.vehicleAppraisalCompleted');
                }

                if (this.isLinkedToInventory) {
                    return this.$t('clientCard.blocked.vehicleStock');
                }

                return '';
            },

            isDisabled() {
                return this.disabled || this.vehicle.isExported || this.hasVehicleValue || this.isLinkedToInventory;
            },

            isLinkedToInventory() {
                return !empty(this.vehicle.inventory_unit_id) && this.stockManagementEnabled;
            },

            showVinDecoding() {
                return !this.hasVehicleValue &&
                    (this.account.vin_decoder || this.account.vin_manual_decoder);
            },

            tooltipVin() {
                if (!this.vehicle.exportSupplier) {
                    return '';
                }

                return `${this.$t(`${this.vehicle.exportSupplier.display_name}.tooltip.niv`)}`;
            },

            vehicleValueTooltip() {
                return this.hasVehicleValue ? this.$t('clientCard.vehicleEvaluate') : '';
            },

            vinMayBeDecoded() {
                return this.vehicle.vin && !this.vehicle.vin_decoded;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, [
                'decodeVehicleVin',
            ]),

            async decodeVin() {
                if (this.vehicle.vin_decoded) {
                    return;
                }

                this.$wait.start('decoding.vin');

                try {
                    const data = await this.decodeVehicleVin({
                        vin: this.vehicle.vin,
                        vehicleId: this.vehicle.id,
                    });

                    this.availableTrims = !empty(data.missingDataChoices.trims) ? data.missingDataChoices.trims : [];
                    this.availableExteriorColors = !empty(data.missingDataChoices.exteriorColors)
                        ? data.missingDataChoices.exteriorColors
                        : [];
                    this.availableInteriorColors = !empty(data.missingDataChoices.interiorColors)
                        ? data.missingDataChoices.interiorColors
                        : [];

                    this.$wait.end('decoding.vin');

                    // Show modal only if some data is missing and choices are available
                    if (
                        !empty(this.availableTrims) ||
                        !empty(this.availableExteriorColors) ||
                        !empty(this.availableInteriorColors)
                    ) {
                        this.vinDataModalOpened = true;
                    }
                } catch (error) {
                    this.$wait.end('decoding.vin');

                    this.$notify.warning(this.$t('clientCard.vehicles.vinDecodingFailed'));
                }
            },

            onUpdateVin(value) {
                this.$emit('update-vehicle', {
                    vin: value,
                    vin_decoded: false,
                });
            },
        },
    };
</script>
