<template>
    <activix-tooltip :content="iconTooltip">
        <div
            class="relative flex items-center justify-center h-12 w-12 rounded-full text-2xl"
            :class="{
                'bg-red-100 text-red-500' : isDeliveryFailed || isBounced,
                'border-2 border-dashed text-gray-500 bg-white' : planned,
                'border border-2 border-opacity-0 text-gray-600 bg-gray-200': !planned,
            }"
        >
            <icon :name="iconName" />
            <communication-direction-icon :communication="communication" />
        </div>
    </activix-tooltip>
</template>

<script>
    import CommunicationDirectionIcon from '@/components/container/lead/communications/CommunicationDirectionIcon.vue';

    import Communication from '@/entities/Communication.js';
    import CommunicationKind from '@/entities/CommunicationKind.js';
    import CommunicationStatus from '@/entities/CommunicationStatus.js';

    export default {
        components: { CommunicationDirectionIcon },

        props: {
            communication: {
                required: true,
                type: Communication,
            },
            planned: {
                default: false,
                type: Boolean,
            },
        },

        computed: {
            iconName() {
                if (this.communication.media?.ziggeo_id) {
                    return this.$icons.emailVideo;
                }

                return this.$icons.email;
            },

            iconTooltip() {
                if (this.isBounced) {
                    return this.$t('client.emailNotDelivered');
                }

                if (this.communication.automation || this.communication.kind === CommunicationKind.AUTO) {
                    return this.$t('clientCard.automatedEmail');
                }

                if (this.communication.kind === CommunicationKind.PLANNED) {
                    return this.$t('clientCard.plannedEmail');
                }

                if (this.isDeliveryFailed) {
                    return this.$t('leadXpress.deliveryFailed');
                }

                return '';
            },

            isBounced() {
                return !!this.communication.bounced_at;
            },

            isDeliveryFailed() {
                return this.communication.status === CommunicationStatus.DELIVERY_FAILED;
            },
        },
    };
</script>
