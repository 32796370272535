<template>
    <div>
        <div class="grid grid-cols-1 gap-6" v-if="vehicles.length > 0 && !$wait.is('fetching.stock')">
            <vehicle-list-item
                :vehicle="vehicle"
                :selected-vehicle-id="selectedVehicleId"
                :category="category"
                :key="vehicle.id"
                @selectVehicle="selectVehicle"
                v-for="vehicle in vehicles"
            />
        </div>

        <div v-if="vehicles.length < 1 && !$wait.is('fetching.stock')">
            <div class="flex flex-col items-center justify-center py-12 gap-3">
                <icon class="text-6xl text-gray-700" name="regular/car-actions-disable-1" />
                <p class="mb-0" xc>
                    {{ emptyMessage }}
                </p>
            </div>
        </div>

        <div v-if="$wait.is('fetching.stock')">
            <div class="flex flex-col items-center justify-center py-12 gap-3">
                <activix-spinner :size="40" />
                <p class="mb-0">
                    {{ $t('clientCard.vehicles.searchingVehicles') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import VehicleListItem from './VehicleListItem.vue';

    export default {
        components: {
            VehicleListItem,
        },

        props: {
            vehicles: {
                type: Array,
                required: true,
            },
            filters: {
                type: Object,
                required: true,
            },
            selectedVehicleId: {
                type: Number,
                required: false,
            },
            category: {
                type: String,
                default: 'car',
                validator: value => ['car', 'powersport'].includes(value),
            },
        },

        computed: {
            emptyMessage() {
                if (this.filters.make || this.filters.model || this.filters.year) {
                    return this.$t('clientCard.vehicles.noStockFoundForFilter', [this.filters.make, this.filters.model, this.filters.year]);
                }

                return this.$t('clientCard.vehicles.emptyInventory');
            },
        },

        methods: {
            selectVehicle(vehicleId) {
                this.$emit('selectVehicle', vehicleId);
            },
        },
    };
</script>
