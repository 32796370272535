<template>
    <activix-tooltip :content="tooltip">
        <follow-up-field
            follow-up="delivery"
            :date="lead.delivery_date"
            :disabled="disabled"
            :follow-up-params="{ last: 'sale' }"
            :lead-id="lead.id"
            @input="updateDeliveryDate($event)"
        />
    </activix-tooltip>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import FollowUpField from '@/components/container/lead/input/FollowUpField.vue';

    import Lead from '@/entities/Lead.js';
    import LeadType from '@/entities/LeadType';

    export default {
        components: { FollowUpField },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            disabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.lead.sale_date && !this.lead.sale_by_phone && !this.checked && !this.lead.isRefinanced) {
                    return true;
                }

                if (this.lead.delivered_date && !this.lead.isRefinanced) {
                    return true;
                }

                if (!this.$can('leads.delivery_date:update')) {
                    return true;
                }

                return this.guest && !this.lead.hasDeliveryMan(this.authUser.id);
            },

            soldVehicle() {
                return this.lead.getSoldVehicle();
            },

            tooltip() {
                if (this.lead.hasInvalidStatus()) {
                    return `<em>${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.delivery'),
                    ])}</em>`;
                }

                if (!this.lead.sale_date && !this.lead.delivery_date && !this.lead.refinanced_date) {
                    if (this.lead.isOfType(LeadType.RENEWAL)) {
                        return this.$t('clientCard.needRenewalChecked');
                    }

                    return this.$t('clientCard.needSaleChecked');
                }

                if (this.lead.delivered_date) {
                    return this.$t('clientCard.modifyDeliveryDate');
                }

                return '';
            },
        },

        methods: {
            updateDeliveryDate(value) {
                const data = { delivery_date: value };

                if (!value) {
                    data.delivered_by_id = null;
                }

                this.updateLead(data);
            },

            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
