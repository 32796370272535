<template>
    <invisible-multiselect
        :disabled="disabled"
        :nullable="false"
        :options="options"
        :value="value"
        @input="updateLead({ event: $event })"
    />
</template>

<script>
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect';

    import CampaignType from '@/entities/CampaignType';
    import Lead from '@/entities/Lead';

    export default {
        components: {
            InvisibleMultiselect,
        },

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            leadCampaign() {
                return this.lead.campaigns.find(campaign => campaign.type === CampaignType.EVENT);
            },

            options() {
                return this.lead.account.campaigns
                    .filter(campaign => campaign.type === CampaignType.EVENT)
                    .map(campaign => ({
                        value: campaign.id,
                        text: campaign.name,
                    }))
                    .sort((a, b) => b.value - a.value);
            },

            value() {
                return this.leadCampaign?.id;
            },
        },

        methods: {
            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
