<template>
    <activix-tooltip :content="tooltip">
        <checkbox-date-picker
            :checkbox-color="checkboxColor"
            :disabled="isDisabled"
            :date="date.toString()"
            @check="onCheckboxClick"
            @date-click="onDateClick"
        />
    </activix-tooltip>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import CheckboxDatePicker from '@/components/container/input/CheckboxDatePicker';

    import ActivixDate from '@/value-objects/ActivixDate';
    import Lead from '@/entities/Lead.js';

    export default {
        components: { CheckboxDatePicker },

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            disabledTooltip: {
                type: String,
                default: '',
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            checkboxColor() {
                return this.isHomeRoadTest ? 'purple' : 'blue';
            },

            date() {
                const date = this.lead.home_road_test_date || this.lead.road_test_date;

                return new ActivixDate(date);
            },

            isDisabled() {
                if (this.disabled) {
                    return true;
                }

                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (this.authUser.id && !this.authUser.isAuthorizedToActionOnLead(this.lead)) {
                    return true;
                }

                if (this.presentedDate.isEmpty()) {
                    return true;
                }

                if (!this.$can('leads.road_test_date:update')) {
                    return true;
                }

                return this.guest;
            },

            isHomeRoadTest() {
                return !!this.lead.home_road_test_date;
            },

            localizedDate() {
                return this.date.toHumanShort(true);
            },

            presentedDate() {
                return new ActivixDate(this.lead.presented_date);
            },

            tooltip() {
                if (this.disabled) {
                    return this.disabledTooltip;
                }

                if (this.lead.hasInvalidStatus()) {
                    return `<em>${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.roadTest'),
                    ])}</em>`;
                }

                if (!this.lead.presented_date) {
                    return this.$t('clientCard.needPresentedDealerChecked');
                }

                return '';
            },
        },

        methods: {
            onActivation() {
                if (this.date.isEmpty() && this.presentedDate.isToday()) {
                    this.updateLead({ road_test_date: this.lead.presented_date });
                } else {
                    this.openFollowUpModal();
                }
            },

            onCheckboxClick(isChecked) {
                if (isChecked) {
                    this.onActivation();
                } else {
                    this.updateLead({
                        road_test_date: null,
                        home_road_test_date: null,
                    });
                }
            },

            onDateClick() {
                this.onActivation();
            },

            openFollowUpModal() {
                this.$modal.show('followUp', {
                    leadId: this.lead.id,
                    current: this.isHomeRoadTest ? 'home_road_test' : 'road_test',
                    last: null,
                });
            },

            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
