<template>
    <tfoot class="bg-white | xl:sticky xl:inset-x-0 xl:bottom-0 xl:z-20">
        <tr>
            <slot />
        </tr>
    </tfoot>
</template>

<script>
    export default {};
</script>
