<template>
    <activix-helper-notification
        :title="$t('screenPop.leadCommunicating.title')"
        :description="description"
        ref="screenPop"
    >
        <template #avatar>
            <div class="h-12 w-12 rounded-full shadow-md bg-gray-500 flex items-center justify-center">
                <icon class="text-white text-2xl" :name="icon" />
            </div>
        </template>
    </activix-helper-notification>
</template>

<script>
    import LeadCommunicating from '@/mixins/LeadCommunicating.js';

    export default {
        mixins: [LeadCommunicating],

        props: {
            communicatingLead: {
                type: Object,
                default: () => ({ id: null }),
            },
        },

        computed: {
            description() {
                return this.getLeadCommunicatingMessage(this.communicatingLead);
            },

            icon() {
                return this.getLeadCommunicatingIcon(this.communicatingLead);
            },
        },

        watch: {
            'communicatingLead.id'(newValue, oldValue) {
                if (newValue && !oldValue) {
                    this.open();
                } else {
                    this.close();
                }
            },
        },

        methods: {
            open() {
                if (this.$refs.screenPop) {
                    this.$refs.screenPop.open();
                }
            },

            close() {
                if (this.$refs.screenPop) {
                    this.$refs.screenPop.close();
                }
            },
        },

        mounted() {
            if (this.communicatingLead.id) {
                this.open();
            }
        },
    };
</script>
