<template>
    <general-box :pills="pills">
        <template #header>
            <general-header :lead="contextLead" @update-lead="updateLead" />
        </template>

        <template #user-assignations>
            <user-assignations
                class="p-3 border-b border-gray-200 bg-gray-100 | md:p-6 xl:p-3 2xl:p-6"
                :lead="contextLead"
                @update-lead="updateLead"
            />
        </template>

        <template #tabs="{ activePill }">
            <lead-general-details
                :lead="contextLead"
                @update-lead="updateLead"
                v-if="activePill === 'general'"
            />

            <lead-contact-details
                :lead="contextLead"
                @update-email="emailChanged"
                @update-lead="updateLead"
                @update-phone="phoneChanged"
                v-else-if="activePill === 'contact'"
            />

            <custom-input-list
                section="generalInfo"
                :blocked-reason="guest ? $t('clientCard.guestMode') : ''"
                :disabled="guest"
                :values="contextLead.custom_fields"
                @input="updateLead"
                v-else-if="activePill === 'custom'"
            />
        </template>
    </general-box>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store';
    import { useGlobalStore } from '@/store/store';

    import { showWarning } from '@/utils/toastr';

    import CustomInputList from '@/components/presentational/input/CustomInputList';
    import GeneralBox from '@/components/container/boxes/GeneralBox';
    import GeneralHeader from '@/components/container/lead/GeneralHeader';
    import LeadContactDetails from '@/components/container/lead/LeadContactDetails';
    import LeadGeneralDetails from '@/components/container/lead/LeadGeneralDetails';
    import UserAssignations from '@/components/container/lead/UserAssignations.vue';

    import ClientCardSection from '@/entities/ClientCardSection';

    export default {
        components: {
            CustomInputList,
            GeneralBox,
            GeneralHeader,
            LeadContactDetails,
            LeadGeneralDetails,
            UserAssignations,
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useGlobalStore, ['guest']),

            pills() {
                const pills = ['general', 'contact'];

                if (this.hasCustomFields) {
                    pills.push('custom');
                }

                return pills.map(pill => ({
                    name: pill,
                    label: this.$t(`clientCard.boxes.general.tabs.${pill}`),
                }));
            },

            hasCustomFields() {
                return this.contextLead.account.hasSectionCustomFields(ClientCardSection.GENERAL_INFO);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, [
                'addLeadEmailAction',
                'addLeadPhoneAction',
                'deleteLeadEmailAction',
                'deleteLeadPhoneAction',
                'updateLeadAction',
                'updateLeadEmailAction',
                'updateLeadPhoneAction',
            ]),

            async deletePhone(phone) {
                try {
                    await this.deleteLeadPhoneAction(phone.id);
                } catch (e) {
                    if (e.response && e.response.status === 400) {
                        showWarning(this.$t('toastr.nioTextPhoneCantBeDeleted'));
                    }
                }
            },

            async emailChanged({ email, callback }) {
                if (!email.id) {
                    await this.addLeadEmailAction(email, this.contextLead.id);
                } else if (!email.email) {
                    await this.deleteLeadEmailAction(email.id);
                } else {
                    await this.updateLeadEmailAction(email, email.id, this.contextLead.id);
                }

                if (typeof callback === 'function') {
                    callback();
                }
            },

            async phoneChanged({ phone, callback }) {
                if (!phone.id) {
                    await this.addLeadPhoneAction(phone, this.contextLead.id);
                } else if (!phone.phone && !phone.extension) {
                    await this.deletePhone(phone);
                } else {
                    await this.updatePhone(phone);
                }

                if (typeof callback === 'function') {
                    callback();
                }
            },

            updateLead({ data, options = {} }) {
                this.updateLeadAction(this.contextLead.id, data, options);
            },

            async updatePhone(phone) {
                try {
                    await this.updateLeadPhoneAction(phone, phone.id, this.contextLead.id);
                } catch (e) {
                    if (e.response && e.response.status === 400) {
                        showWarning(this.$t('toastr.phoneMustBeMobile'));
                    }
                }
            },
        },
    };
</script>
