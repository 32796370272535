<template>
    <textarea-autosize
        resize="both"
        class="form-control | mt-2"
        :class="{ 'p-0 border-none bg-transparent': lgLayout }"
        :autosize="true"
        :disabled="disabled"
        ref="input"
        @blur.native="onBlur"
        @focus.native="onFocusIn"
        @keyup.native="debounceTriggerAccept"
        v-model="currentValue"
    />
</template>

<script>
    import { debounce } from 'lodash-es';
    import { mapActions } from 'pinia';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        props: {
            name: {
                type: String,
            },
            value: [String, Number, Array],
            placeholder: String,
            disabled: {
                type: Boolean,
                default: false,
            },
            recordId: {
                type: Number,
                required: true,
            },
            vehicle: {
                type: Boolean,
                default: false,
            },
            customField: {
                type: Object,
                default: null,
            },
        },

        data() {
            return {
                currentValue: null,
                editing: false,
            };
        },

        watch: {
            value() {
                if (this.editing) {
                    return;
                }

                if (empty(this.value) || this.value === false) {
                    this.currentValue = null;
                    return;
                }

                this.currentValue = this.decodeString(this.value);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'updateLeadVehicleAction']),

            onFocusIn() {
                this.editing = true;
            },

            triggerAccept() {
                if (this.disabled) {
                    return;
                }

                this.accept(this.currentValue);
            },

            async accept(value) {
                this.currentValue = value;

                // Only update if value changed and recordId is set
                if (value == this.value || (empty(value) && empty(this.value)) || empty(this.recordId)) {
                    return;
                }

                let options = {};
                const payload = {
                    [this.name]: value,
                };

                if (this.customField) {
                    options = {
                        customField: { ...this.customField, value },
                    };
                }

                if (this.vehicle) {
                    await this.updateLeadVehicleAction(payload, this.recordId, this.leadId, options);
                } else {
                    await this.updateLeadAction(this.recordId, payload, options);
                }
            },

            debounceTriggerAccept() {
                this.triggerAccept();
            },

            init() {
                if (empty(this.value)) {
                    return;
                }

                this.currentValue = this.decodeString(this.value);
            },

            onBlur() {
                // Delay is necessary to prevent event overlapping
                setTimeout(() => {
                    this.triggerAccept();
                    this.editing = false;
                }, 50);
            },
        },

        created() {
            this.debounceTriggerAccept = debounce(this.debounceTriggerAccept, 1500);

            this.init();
        },
    };
</script>
