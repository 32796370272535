<template>
    <lead-input
        :disabled="disabled"
        :center-icon="centerIcon"
        :editing="editing"
        :handle-enter="false"
        @accept="triggerAccept"
        @cancel="cancel"
        @edit="editField"
        @blur="onBlur"
    >
        <textarea-autosize
            resize="both"
            class="form-control | resize-x"
            :class="{ 'p-0 border-none bg-transparent': lgLayout }"
            :autosize="true"
            :disabled="disabled"
            ref="input"
            v-model="currentValue"
        />

        <template #value>
            <template v-if="!empty(currentValue)">
                {{ currentValue }}
            </template>
            <template v-else>
                {{ emptyValue }}
            </template>
        </template>
    </lead-input>
</template>

<script>
    // Components
    import { mapActions } from 'pinia';
    import LeadInput from '../LeadInput.vue';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        components: {
            LeadInput,
        },

        props: {
            name: {
                type: String,
            },
            value: [String, Number, Array],
            placeholder: String,
            disabled: Boolean,
            recordId: {
                type: Number,
                required: true,
            },
            vehicle: {
                type: Boolean,
                default: false,
            },
            emptyValue: {
                type: String,
                default: '...',
            },
            centerIcon: {
                type: Boolean,
                default: true,
            },
            customField: {
                type: Object,
                default: null,
            },
        },

        data() {
            return {
                currentValue: null,
                editing: false,
            };
        },

        watch: {
            value() {
                if (this.editing) {
                    return;
                }

                if (empty(this.value) || this.value === false) {
                    this.currentValue = null;
                    return;
                }

                this.currentValue = this.decodeString(this.value);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'updateLeadVehicleAction']),

            async editField() {
                if (this.disabled && empty(this.currentValue)) {
                    return;
                }

                this.editing = true;

                await this.$nextTick();

                this.$refs.input.$el.focus();
            },

            triggerAccept() {
                if (this.disabled || !this.editing) {
                    this.editing = false;
                    return;
                }

                this.accept(this.currentValue);
            },

            async accept(value) {
                this.editing = false;
                this.currentValue = value;

                // Only update if value changed and recordId is set
                if (value == this.value || (empty(value) && empty(this.value)) || empty(this.recordId)) {
                    return;
                }

                let options = {};
                const payload = {
                    [this.name]: value,
                };

                if (this.customField) {
                    options = {
                        customField: { ...this.customField, value },
                    };
                }

                if (this.vehicle) {
                    await this.updateLeadVehicleAction(payload, this.recordId, this.leadId, options);
                } else {
                    await this.updateLeadAction(this.recordId, payload, options);
                }
            },

            cancel() {
                this.editing = false;
            },

            init() {
                if (empty(this.value)) {
                    return;
                }

                this.currentValue = this.decodeString(this.value);
            },

            onBlur() {
                // Delay is necessary to prevent event overlapping
                setTimeout(() => {
                    this.triggerAccept();
                }, 50);
            },
        },

        created() {
            this.init();
        },
    };
</script>
