<template>
    <activix-select :options="contactTypeOptions" :same-width="false" :value="selectedOption.value">
        <template #trigger>
            <button class="flex items-center gap-1.5 truncate | lg:text-lg xl:text-xl 2xl:text-2xl">
                <span
                    class="font-semibold"
                    v-text="selectedOption.label"
                    v-if="isBusiness"
                />

                <span
                    :class="{ 'font-semibold': index === selectedOptionLabelParts.length - 1 }"
                    :key="index"
                    v-text="contactNamePart"
                    v-for="(contactNamePart, index) in selectedOptionLabelParts"
                    v-else
                />

                <icon class="text-xs" name="bold/arrow-down-1" />
            </button>
        </template>

        <template #default="{ options }">
            <div class="flex flex-col flex-1 -my-1">
                <button
                    class="group flex gap-1.5 items-center px-3 py-1.5 select-none transition-colors"
                    :class="{
                        'text-gray-400 italic': option.disabled,
                        'text-gray-600 hover:bg-gray-100 hover:text-gray-800': !option.disabled,
                    }"
                    :disabled="option.disabled"
                    :key="option.value"
                    @click="onSelect(option)"
                    v-for="option in options"
                >
                    <icon :name="option.icon" />
                    <span v-text="option.label" />
                    <span class="w-6 flex-1 text-right">
                        <icon class="text-sm text-blue-500" name="bold/check-1" v-if="option.value === value" />
                    </span>
                </button>
            </div>
        </template>
    </activix-select>
</template>

<script>
    import { ActivixSelect } from '@activix/ui';

    import Civility from '@/entities/Civility.js';
    import ContactType from '@/entities/ContactType.js';
    import Lead from '@/entities/Lead.js';

    export default {
        components: {
            ActivixSelect,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            businessContactName() {
                return this.lead.business_name || this.mainContactName;
            },

            civility() {
                if (!this.lead.civility) {
                    return '';
                }

                return Civility.getTranslation(this.lead.civility);
            },

            contactTypeOptions() {
                return [
                    {
                        icon: 'regular/single-neutral-id-card-3',
                        label: [this.civility, (this.mainContactName || this.$t('clientCard.unknown'))].join(' ').trim(),
                        value: ContactType.MAIN,
                    },
                    {
                        disabled: !this.lead.business_name,
                        icon: 'regular/baggage',
                        label: this.lead.business_name || this.$t('clientCard.boxes.general.noBusiness'),
                        value: ContactType.BUSINESS,
                    },
                ];
            },

            isBusiness() {
                return this.value === ContactType.BUSINESS;
            },

            mainContactName() {
                return [this.lead.first_name, this.lead.last_name].join(' ').trim();
            },

            selectedOption() {
                const selectedOption = this.contactTypeOptions.find(option => option.value === this.value);

                return selectedOption || { value: '', label: '' };
            },

            selectedOptionLabelParts() {
                return this.selectedOption.label.split(' ');
            },

            value() {
                if (this.lead.business) {
                    return ContactType.BUSINESS;
                }

                return ContactType.MAIN;
            },
        },

        methods: {
            onSelect(option) {
                if (option.disabled) {
                    return;
                }

                const business = option.value === ContactType.BUSINESS;

                this.updateLead({ business });
            },

            updateLead(data) {
                this.$emit('update-lead', { data });
            },
        },
    };
</script>
