<template>
    <div class="flex items-end" v-if="contextLead.created_at">
        <div class="w-full | md:w-auto">
            <div class="flex justify-end mb-1 -mt-2" v-if="arrowsAreVisible">
                <router-link
                    class="text-sm"
                    :replace="true"
                    :class="!previousLead.id ? 'disabled text-grey-500' : 'text-grey-700'"
                    :to="previousLead.link"
                >
                    <icon name="regular/arrow-circle-left" />
                </router-link>

                <router-link
                    class="text-sm ml-1"
                    :replace="true"
                    :class="!nextLead.id ? 'disabled text-grey-500' : 'text-grey-700'"
                    :to="nextLead.link"
                >
                    <icon name="regular/arrow-circle-right" />
                </router-link>
            </div>

            <lead-input-wrapper :with-padding="false">
                <lead-input-group>
                    <lead-input-label class="text-right">
                        {{ $t('clientCard.createdAt') }}
                    </lead-input-label>
                    <lead-input-value class="md:text-left" :collapsing="false">
                        <activix-tooltip :content="getTime(contextLead.created_at)">
                            <span>{{ getDate(contextLead.created_at) }}</span>
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group>
                    <lead-input-label class="text-right">
                        {{ $t('clientCard.updatedAt') }}
                    </lead-input-label>
                    <lead-input-value class="md:text-left" :collapsing="false">
                        <activix-tooltip :content="getTime(contextLead.updated_at)">
                            <span>{{ getDate(contextLead.updated_at) }}</span>
                        </activix-tooltip>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="optOutIsVisible">
                    <lead-input-label class="text-right">
                        {{ $t('clientCard.subscribed') }}
                    </lead-input-label>
                    <lead-input-value class="md:text-right" :collapsing="false">
                        <div class="input-wrapper | items-center">
                            <activix-tooltip :content="unsubscribePhoneTooltip">
                                <icon
                                    :class="[
                                        isUnsubscribePhone ? 'link-blue' : 'link-grey-light',
                                        { 'cursor-not-allowed': unsubscribeDisabled },
                                    ]"
                                    :name="$icons.phone"
                                    @click="toggleUnsubscribe('call')"
                                />
                            </activix-tooltip>
                            <activix-tooltip :content="unsubscribeEmailTooltip">
                                <icon
                                    class="ml-2"
                                    :class="[
                                        isUnsubscribeEmail ? 'link-blue' : 'link-grey-light',
                                        { 'cursor-not-allowed': unsubscribeDisabled },
                                    ]"
                                    :name="$icons.email"
                                    @click="toggleUnsubscribe('email')"
                                />
                            </activix-tooltip>
                            <activix-tooltip :content="unsubscribeSmsTooltip" v-if="contextLead.account.hasNioText()">
                                <icon
                                    class="ml-2"
                                    :class="[
                                        isUnsubscribeSms ? 'link-blue' : 'link-grey-light',
                                        { 'cursor-not-allowed': unsubscribeDisabled },
                                    ]"
                                    :name="$icons.sms"
                                    @click="toggleUnsubscribe('sms')"
                                />
                            </activix-tooltip>
                            <activix-tooltip :content="unsubscribeAllTooltip">
                                <icon
                                    class="ml-2"
                                    name="regular/delete"
                                    :class="[
                                        isUnsubscribeAll ? 'link-blue' : 'link-grey-light',
                                        { 'cursor-not-allowed': unsubscribeAllDisabled },
                                    ]"
                                    @click="toggleUnsubscribeAll"
                                />
                            </activix-tooltip>
                        </div>
                    </lead-input-value>
                </lead-input-group>

                <lead-input-group v-if="$feature.isEnabled('casl_compliance')">
                    <lead-input-label class="text-right">
                        {{ $t('consent.consent') }}
                    </lead-input-label>
                    <lead-input-value :collapsing="false">
                        <div class="md:text-left flex items-center cursor-pointer" @click="openConsentModal">
                            <consent-shield class="mr-2" :lead="contextLead" />

                            <activix-tooltip :content="consentLimitText">
                                <span>{{ consentLimitDate }}</span>
                            </activix-tooltip>
                        </div>
                    </lead-input-value>
                </lead-input-group>
            </lead-input-wrapper>
        </div>

        <do-not-disturb
            :unsubscribed-to-all-date="contextLead.unsubscribe_all_date"
            :opened.sync="doNotDisturbModalOpened"
            @update="updateLead"
        />
        <activix-confirm-modal
            :content="unsubscribeModalContent"
            :opened.sync="unsubscribeConfirmOpened"
            :title="$t('general.areYouSure')"
            @approve="triggerUnsubscribe"
        />

    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    // Components
    import ConsentShield from '@/components/ConsentShield.vue';
    import DoNotDisturb from '../modals/DND.vue';
    import LeadInputGroup from './LeadInputGroup.vue';
    import LeadInputLabel from './LeadInputLabel.vue';
    import LeadInputValue from './LeadInputValue.vue';
    import LeadInputWrapper from './LeadInputWrapper.vue';

    // Value Object
    import ActivixDate from '../../value-objects/ActivixDate.js';
    import CaslConsentLimitEvent from '../../entities/CaslConsentLimitEvent.js';
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            ConsentShield,
            DoNotDisturb,
            LeadInputGroup,
            LeadInputLabel,
            LeadInputValue,
            LeadInputWrapper,
        },

        data() {
            return {
                doNotDisturbModalOpened: false,
                unsubscribeConfirmOpened: false,
                selectedType: '',
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useGlobalStore, ['authUser', 'guest']),
            ...mapState(useDashboardStore, ['tableLeads']),

            consentLimitDate() {
                if (this.contextLead.customer.isConfirmedConsent) {
                    return `∞ ${this.$tc('consent.daysLeft', 0)}`;
                }

                return this.getDate(this.contextLead.customer.casl_consent.limit_date);
            },

            consentLimitText() {
                if (this.contextLead.customer.isConfirmedConsent) {
                    return '';
                }

                if (!this.contextLead.customer.casl_consent.limit_event || !this.contextLead.customer.casl_consent.limit_event_date) {
                    return '';
                }

                const limitEventText = CaslConsentLimitEvent.getTranslation(this.contextLead.customer.casl_consent.limit_event);
                const limitEventDate = new ActivixDate(this.contextLead.customer.casl_consent.limit_event_date).toHumanShort(false);

                return `${limitEventText} ${limitEventDate}`;
            },

            arrowsAreVisible() {
                return !this.lgLayout && this.tableLeads.length > 1 && this.currentLeadIndex !== -1;
            },

            optOutIsVisible() {
                return !this.lgLayout && this.contextLead.account.unsubscribe;
            },

            unsubscribeAllDisabled() {
                return this.guest;
            },

            unsubscribeDisabled() {
                return this.guest || this.isUnsubscribeAll;
            },

            isUnsubscribeAll() {
                return !!this.contextLead.unsubscribe_all_date;
            },

            isUnsubscribePhone() {
                return this.isUnsubscribeAll || !!this.contextLead.unsubscribe_call;
            },

            isUnsubscribeEmail() {
                return this.isUnsubscribeAll || !!this.contextLead.unsubscribe_email;
            },

            isUnsubscribeSms() {
                return this.isUnsubscribeAll || !!this.contextLead.unsubscribe_sms;
            },

            unsubscribeAllDate() {
                if (this.isUnsubscribeAll) {
                    if (as_locale(this.contextLead.unsubscribe_all_date, 'unsubscribe_all_date').year() == 2222) {
                        return this.$t('clientCard.indefinitely');
                    }

                    return as_locale(this.contextLead.unsubscribe_all_date, 'unsubscribe_all_date').humanShort(true);
                }

                return '...';
            },

            unsubscribeAllTooltip() {
                if (this.isUnsubscribeAll) {
                    if (as_locale(this.contextLead.unsubscribe_all_date, 'unsubscribe_all_date').year() == 2222) {
                        return this.$t('clientCard.clientIsAllUnsubscribedForever');
                    }

                    return this.$t('clientCard.clientIsAllUnsubscribed', [
                        as_locale(this.contextLead.unsubscribe_all_date, 'unsubscribe_all_date').humanShort(true),
                    ]);
                }

                return '';
            },

            unsubscribePhoneTooltip() {
                if (this.unsubscribeAllTooltip) {
                    return this.unsubscribeAllTooltip;
                }

                if (this.isUnsubscribePhone) {
                    return this.$t('clientCard.unsubscribedPhone', [
                        new ActivixDate(this.contextLead.unsubscribe_call_date).toHumanShort(false),
                    ]);
                }

                return '';
            },

            unsubscribeEmailTooltip() {
                if (this.unsubscribeAllTooltip) {
                    return this.unsubscribeAllTooltip;
                }

                if (this.isUnsubscribeEmail) {
                    return this.$t('clientCard.unsubscribedEmail', [
                        new ActivixDate(this.contextLead.unsubscribe_email_date).toHumanShort(false),
                    ]);
                }

                return '';
            },

            unsubscribeSmsTooltip() {
                if (this.unsubscribeAllTooltip) {
                    return this.unsubscribeAllTooltip;
                }

                if (this.isUnsubscribeSms) {
                    return this.$t('clientCard.unsubscribedSms', [
                        new ActivixDate(this.contextLead.unsubscribe_sms_date).toHumanShort(false),
                    ]);
                }

                return '';
            },

            currentLeadIndex() {
                return this.tableLeads.findIndex(lead => lead.id == this.contextLead.id);
            },

            previousLead() {
                const previousIndex = this.currentLeadIndex - 1;

                if (previousIndex >= 0) {
                    const leadId = this.tableLeads[previousIndex].id;

                    return {
                        id: leadId,
                        link: {
                            name: 'leads.update',
                            params: { id: leadId },
                        },
                    };
                }

                return {
                    id: 0,
                    link: '',
                };
            },

            nextLead() {
                const nextIndex = this.currentLeadIndex + 1;

                if (nextIndex > 0 && nextIndex <= this.tableLeads.length - 1) {
                    const leadId = this.tableLeads[nextIndex].id;

                    return {
                        id: leadId,
                        link: {
                            name: 'leads.update',
                            params: { id: leadId },
                        },
                    };
                }

                return {
                    id: 0,
                    link: '',
                };
            },

            unsubscribeModalContent() {
                if (!this.selectedType) {
                    return '';
                }

                const type = this.selectedType.replace('unsubscribe_', '');

                return this.$t(`clientCard.unsubscribeModal.${type}`);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            getDate(date) {
                return new ActivixDate(date).toHumanShort(false);
            },

            getTime(date) {
                return new ActivixDate(date).toHumanTime();
            },

            openConsentModal() {
                this.$modal.show('consentConfig', {
                    leadId: this.contextLead.id,
                });
            },

            toggleUnsubscribe(type) {
                if (this.unsubscribeDisabled) {
                    return;
                }

                this.selectedType = `unsubscribe_${type}`;

                if (this.contextLead[this.selectedType]) {
                    this.unsubscribeConfirmOpened = true;
                } else {
                    this.triggerUnsubscribe();
                }
            },

            triggerUnsubscribe() {
                if (this.unsubscribeDisabled) {
                    return;
                }

                const payload = {};

                if (this.contextLead[this.selectedType]) {
                    payload[this.selectedType] = false;
                    payload[`${this.selectedType}_date`] = null;
                } else {
                    payload[this.selectedType] = true;
                    payload[`${this.selectedType}_date`] = new ActivixDate('now').toString();
                }

                this.updateLead(payload);
            },

            toggleUnsubscribeAll() {
                if (this.unsubscribeAllDisabled) {
                    return;
                }

                this.doNotDisturbModalOpened = true;
            },

            updateLead(data) {
                this.updateLeadAction(this.contextLead.id, data);
            },
        },
    };
</script>
