<template>
    <box>
        <box-header slot="header">
            {{ $t('clientCard.boxes.commercialProducts.title') }}

            <box-header-icon
                slot="right"
                icon-name="regular/cog"
                :disabled="$wait.is('adding.vehicle')"
            >
                <template #popoverContent>
                    <div class="flex p-6 rounded-t-lg border-b border-gray-200 bg-white">
                        <h4 class="text-lg my-0 leading-none">
                            {{ $t('clientCard.boxes.commercialProducts.commercialSpecifications') }}
                        </h4>
                    </div>

                    <div class="p-6 space-y-3 rounded-b-lg bg-white" :class="{ 'text-gray-500': actionDisabled }">
                        <div class="flex justify-between items-center gap-6">
                            <span>{{ $t('clientCard.boxes.commercialProducts.clientMet') }}</span>
                            <activix-switcher
                                :disabled="actionDisabled"
                                :value="contextLead.commercial_meeting"
                                @input="updateLead({ commercial_meeting: $event })"
                            />
                        </div>
                        <div class="flex justify-between items-center gap-6">
                            <span>{{ $t('clientCard.boxes.commercialProducts.included') }}</span>
                            <activix-switcher
                                :disabled="actionDisabled"
                                :value="contextLead.commercial_no_need"
                                @input="updateLead({ commercial_no_need: $event })"
                            />
                        </div>
                    </div>
                </template>
            </box-header-icon>
        </box-header>

        <template slot="content">
            <commercial-products-widget :lead="contextLead" />

            <box-content class="px-0">
                <commercial-products :lead="contextLead" />
            </box-content>
        </template>
    </box>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store';
    import { useGlobalStore } from '@/store/store.js';

    import Box from '@/components/presentational/boxes/Box';
    import BoxContent from '@/components/presentational/boxes/BoxContent';
    import BoxHeader from '@/components/presentational/boxes/BoxHeader';
    import BoxHeaderIcon from '@/components/presentational/boxes/BoxHeaderIcon.vue';
    import CommercialProducts from '@/components/container/lead/CommercialProducts.vue';
    import CommercialProductsWidget from '@/components/container/lead/widget/CommercialProductsWidget';

    export default {
        components: {
            Box,
            BoxContent,
            BoxHeader,
            BoxHeaderIcon,
            CommercialProducts,
            CommercialProductsWidget,
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            actionDisabled() {
                return this.guest || this.authUser.isAdvisor() || this.authUser.isBdc();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            updateLead(data) {
                this.updateLeadAction(this.contextLead.id, data);
            },
        },
    };
</script>
