<template>
    <invisible-multiselect
        :disabled="disabled"
        :nullable="true"
        :options="options"
        :value="vehicle.weight"
        @input="$emit('input', $event)"
        v-if="isLegacyVehicleWeight"
    />
    <invisible-input
        class="flex-1"
        :disabled="disabled"
        mask="integer"
        :value="vehicle.weight"
        @input="$emit('input', $event)"
        v-else
    />
</template>

<script>
    import InvisibleInput from '@/components/container/input/InvisibleInput';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect.vue';

    import LeadVehicle from '@/entities/LeadVehicle.js';
    import LegacyLeadVehicleWeight from '@/entities/LegacyLeadVehicleWeight.js';

    export default {
        components: {
            InvisibleInput,
            InvisibleMultiselect,
        },

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        computed: {
            isLegacyVehicleWeight() {
                return this.weightOptions.includes(this.vehicle.weight);
            },

            options() {
                return this.weightOptions.map(entry => ({ value: entry, text: entry }));
            },

            weightOptions() {
                return Object.values(LegacyLeadVehicleWeight.entries);
            },
        },
    };
</script>
