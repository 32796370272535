<template>
    <box>
        <template #header>
            <box-header>
                {{ $t('clientCard.boxes.communications.title') }}

                <template #right>
                    <box-header-icon
                        icon-name="regular/add"
                        @click="openCommunicationModal"
                        v-if="showAddCommunication"
                    />

                    <communication-filters
                        :communications="communicationsAndAutomationJobs"
                        @communications-filtered="onCommunicationsFiltered"
                    />
                </template>
            </box-header>
        </template>

        <template #content>
            <transition-group
                class="flex-grow flex"
                enter-active-class="transition duration-150 transform"
                enter-class="opacity-0 -translate-y-2"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition duration-150 transform"
                leave-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-2"
                mode="out-in"
            >
                <template v-if="filteredCommunicationsAndAutomations.length">
                    <div
                        class="bg-gray-100 text-center py-2"
                        key="show-all-automations"
                        v-if="showLoadAutomationsRow"
                    >
                        <a class="font-semibold" @click="automations.showAll = true">
                            {{ $t('clientCard.showAllAutomations') }}
                        </a>
                    </div>

                    <communication-list
                        class="flex-grow max-w-full"
                        :account="contextLead.account"
                        :communications="filteredCommunicationsAndAutomations"
                        :lead="contextLead"
                        key="communication-list"
                        @edit-communication="openCommunicationModal"
                        @open-context-modal="openContextModal"
                    />
                </template>

                <empty-state
                    class="pb-6"
                    :description="emptyStateDescription"
                    :title="$t('clientCard.emptyState.communicationTitle')"
                    svg="note-blank-2"
                    key="empty-state"
                    v-else
                />
            </transition-group>

            <add-communication :lead="contextLead" :opened.sync="modals.addOrEditCommunication.opened" />
            <email-accordion-modal :opened.sync="modals.emailAccordion.opened" :communication-id="modals.emailAccordion.communicationId" />
            <email-lead name="communication:emailLead" :is-forwarding="isForwarding" />
            <automation-preview
                :lead="contextLead"
                :opened.sync="modals.automationPreview.opened"
                :job="modals.automationPreview.job"
                @closed="modals.automationPreview.job = null"
            />
            <messenger-preview
                :opened.sync="modals.messengerPreview.opened"
                :communication="modals.messengerPreview.communication"
                @closed="modals.messengerPreview.communication = null"
            />
        </template>
    </box>
</template>

<script>
    import { mapState } from 'pinia';
    import { useClientCardStore } from '@/store/modules/clientCard/store';
    import { useContextStore } from '@/store/modules/context/store';
    import { useGlobalStore } from '@/store/store';

    import { snakeCase } from 'lodash-es';

    import EmailCompose from '@/mixins/EmailCompose.js';

    import AddCommunication from '@/components/modals/AddCommunication';
    import AutomationPreview from '@/components/modals/AutomationPreview';
    import Box from '@/components/presentational/boxes/Box';
    import BoxHeader from '@/components/presentational/boxes/BoxHeader';
    import BoxHeaderIcon from '@/components/presentational/boxes/BoxHeaderIcon';
    import CommunicationList from '@/components/container/lead/communications/CommunicationList';
    import CommunicationFilters from '@/components/container/lead/communications/CommunicationFilters';
    import EmailAccordionModal from '@/components/modals/EmailAccordionModal';
    import EmailLead from '@/components/modals/EmailLead';
    import EmptyState from '@/components/EmptyState';
    import MessengerPreview from '@/components/modals/MessengerPreview';

    import AutomationActionType from '@/entities/AutomationActionType';
    import Communication from '@/entities/Communication';
    import CommunicationMethod from '@/entities/CommunicationMethod';
    import CommunicationStatus from '@/entities/CommunicationStatus';
    import CommunicationType from '@/entities/CommunicationType';
    import LeadType from '@/entities/LeadType';
    import Service from '@/entities/Service';
    import Supplier from '@/entities/Supplier';

    export default {
        components: {
            AddCommunication,
            AutomationPreview,
            Box,
            BoxHeader,
            BoxHeaderIcon,
            CommunicationList,
            CommunicationFilters,
            EmailAccordionModal,
            EmailLead,
            EmptyState,
            MessengerPreview,
        },

        mixins: [EmailCompose],

        data() {
            return {
                automations: {
                    limit: 3,
                    showAll: false,
                },
                filteredCommunicationsAndAutomations: [],
                isForwarding: false,
                modals: {
                    addOrEditCommunication: {
                        opened: false,
                    },
                    automationPreview: {
                        opened: false,
                    },
                    emailAccordion: {
                        communicationId: null,
                        opened: false,
                    },
                    messengerPreview: {
                        opened: false,
                    },
                },
                niotextTemplateCategories: [],
            };
        },

        computed: {
            ...mapState(useGlobalStore, {
                activeUsers: 'activeUsers',
                authUser: 'authUser',
            }),

            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextResponseTemplates: 'contextResponseTemplates',
            }),

            automationJobs() {
                return this.contextLead.automation_jobs
                    .filter(job => {
                        if (!job.action) {
                            return false;
                        }

                        const smsJob = job.action.action === AutomationActionType.SEND_SMS;
                        const emailJob = job.action.action === AutomationActionType.SEND_EMAIL &&
                            this.contextResponseTemplates.some(t => t.id === job.action.parameters.template.id);

                        return !job.execution_started_at && (smsJob || emailJob);
                    })
                    .sort((a, b) => a.execution_date.localeCompare(b.execution_date));
            },

            automationJobsAsCommunications() {
                const jobs = this.automationJobs.map(job => {
                    if (job.action.action === AutomationActionType.SEND_SMS) {
                        return this.formatSmsJobAsCommunication(job);
                    }

                    return this.formatEmailJobAsCommunication(job);
                });

                if (this.automations.showAll) {
                    return jobs;
                }

                return jobs.slice(0, this.automations.limit);
            },

            communicationsAndAutomationJobs() {
                return [...this.automationJobsAsCommunications, ...this.connectedCommunications]
                    .map(communication => {
                        return new Communication({
                            ...communication,
                            sort_created_at: communication.original_created_at || communication.created_at,
                        });
                    })
                    .sort((a, b) => b.created_at.localeCompare(a.created_at));
            },

            connectedCommunications() {
                return this.contextLead.communications.filter(communication => {
                    return (
                        communication.connected &&
                        communication.status !== CommunicationStatus.INTERRUPTED &&
                        communication.status !== CommunicationStatus.ERROR &&
                        communication.status !== CommunicationStatus.INVALID &&
                        communication.status !== CommunicationStatus.PENDING
                    );
                });
            },

            emptyStateDescription() {
                if (this.communicationsAndAutomationJobs.length) {
                    return this.$t('clientCard.emptyState.communicationNoMatchDescription');
                }

                return this.$t('clientCard.emptyState.communicationDescription');
            },

            showAddCommunication() {
                return this.authUser.hasAccessToEditAccount(this.contextLead.account_id);
            },

            showAutomationAction() {
                return this.contextLead.account.automation;
            },

            showExchangeCount() {
                return this.contextLead.account.niotext;
            },

            showLoadAutomationsRow() {
                return this.automationJobs.length > this.automations.limit && !this.automations.showAll;
            },

            showTimeElapsed() {
                return this.contextLead.isNotOfType(LeadType.EVENT);
            },
        },

        watch: {
            communicationsAndAutomationJobs: {
                immediate: true,
                async handler() {
                    if (this.niotextTemplateCategories.length || this.$wait.is('fetching.niotext.templates')) {
                        return;
                    }

                    const hasNiotextAutomationJob = this.communicationsAndAutomationJobs
                        .some(communication => communication.automation_job && communication.isSms);

                    if (true || hasNiotextAutomationJob) {
                        this.$wait.start('fetching.niotext.templates');

                        await this.loadNioTextTemplates();

                        this.$wait.end('fetching.niotext.templates');
                    }
                },
            },
        },

        methods: {
            formatEmailJobAsCommunication(job) {
                let templateLocale = this.contextLead.locale;

                if (job.action.parameters.locales.length === 1 &&
                    typeof job.action.parameters.locales[0] === 'string' &&
                    ['fr', 'en'].includes(job.action.parameters.locales[0])
                ) {
                    templateLocale = job.action.parameters.locales[0];
                }

                const template = this.contextResponseTemplates.find(t => t.id === job.action.parameters.template.id);
                let translation = template.translations.find(t => t.locale === templateLocale);
                const sender = this.contextLead[snakeCase(job.action.parameters.from_role)];

                if (!translation) {
                    translation = template.translations[0];
                }

                return {
                    id: `a_${job.id}`, // Custom id necessary to prevent overlap with real communications
                    automation: true,
                    automation_job: job,
                    created_at: job.execution_date,
                    created_by_user: false,
                    user: sender,
                    email_subject: this.replaceAttributesInMessage({
                        html: false,
                        lead: this.contextLead,
                        locale: this.contextLead.locale,
                        message: translation.subject,
                        sender,
                        users: this.contextLead.account.users,
                    }),
                    email_body: this.replaceAttributesInMessage({
                        html: true,
                        lead: this.contextLead,
                        locale: this.contextLead.locale,
                        message: translation.body,
                        sender,
                        users: this.contextLead.account.users,
                    }),
                    communication_method_id: CommunicationMethod.EMAIL,
                    communication_type_id: CommunicationType.OUTGOING,
                    service_id: Service.LEAD_XPRESS,
                };
            },

            formatSmsJobAsCommunication(job) {
                return {
                    id: `a_${job.id}`, // Custom id necessary to prevent overlap with real communications
                    automation: true,
                    automation_job: job,
                    locale: this.contextLead.locale,
                    created_at: job.execution_date,
                    created_by_user: false,
                    user: this.getSmsCommunicationUser(job),
                    email_subject: '',
                    email_body: '',
                    communication_method_id: CommunicationMethod.SMS,
                    communication_type_id: CommunicationType.OUTGOING,
                    niotext_template: this.getNiotextTemplate(job),
                    service_id: Service.NIOTEXT,
                };
            },

            getNiotextTemplate(job) {
                const templateId = job.action.parameters.template.id;
                const templateCategoryId = job.action.parameters.template.category_id;

                const category = this.niotextTemplateCategories.find(templateCategory => {
                    return templateCategory.id === templateCategoryId;
                });

                return category?.templates?.find(template => {
                    return template.id === templateId;
                });
            },

            getSmsCommunicationUser(job) {
                if (this.contextLead[snakeCase(job.action.parameters.from_role)]) {
                    return this.contextLead[snakeCase(job.action.parameters.from_role)];
                }

                return this.activeUsers.find(user => user.id === job.action.parameters.fallback_user);
            },

            async loadNioTextTemplates() {
                this.niotextTemplateCategories = await this.$api.niotextTemplates.index(this.contextLead.account_id);
            },

            onCommunicationsFiltered(filteredCommunications) {
                this.filteredCommunicationsAndAutomations = filteredCommunications;
            },

            openCommunicationModal(communication) {
                useClientCardStore().currentCommunication = communication;

                this.$nextTick(() => {
                    this.modals.addOrEditCommunication.opened = true;
                });
            },

            openContextModal(communication) {
                if (communication.isEmail) {
                    if (communication.automation) {
                        this.modals.automationPreview.job = communication.automation_job;
                        this.modals.automationPreview.opened = true;
                    } else {
                        this.openEmailAccordionModal(communication.id);
                    }
                }

                if (communication.isMessenger) {
                    if (communication.created_by_user) {
                        this.openCommunicationModal(communication);
                    } else {
                        this.modals.messengerPreview.communication = communication;
                        this.modals.messengerPreview.opened = true;
                    }
                }
            },

            openEmailAccordionModal(communicationId = null) {
                if (communicationId) {
                    this.modals.emailAccordion.communicationId = communicationId;
                }

                this.modals.emailAccordion.opened = true;
            },

            openEmailLeadModal(isForwarding = false) {
                this.isForwarding = isForwarding;

                setTimeout(() => {
                    this.$modal.show('communication:emailLead', this.contextLead.id);
                }, 400);
            },
        },

        async created() {
            this.$eventBus.$on('open-email-lead-modal', this.openEmailLeadModal);
            this.$eventBus.$on('openEmailAccordionModal', this.openEmailAccordionModal);

            await this.$nextTick();
            if (this.$route?.query?.communicationId) {
                if (this.connectedCommunications.some(c => c.id === this.$route.query.communicationId)) {
                    this.openEmailAccordionModal();
                }
            }
        },

        beforeDestroy() {
            this.$eventBus.$off('open-email-lead-modal', this.openEmailLeadModal);
            this.$eventBus.$off('openEmailAccordionModal', this.openEmailAccordionModal);
        },
    };
</script>
