<template>
    <flexible-input>
        <input-label slot="label" class="flex items-center" :blocked-reason="blockedReason">
            <activix-tooltip :content="$t('clientCard.vehicles.balanceTooltip')">
                {{ $t('clientCard.vehicles.balance') }}
            </activix-tooltip>
        </input-label>

        <activix-tooltip :content="inputTooltip">
            <invisible-input
                class="flex-1"
                mask="money"
                :disabled="disabled"
                :value="vehicle.balance"
                @input="$emit('input', $event)"
                v-if="empty(vehicle.payment) || empty(vehicle.frequency)"
            />
            <div class="cursor-not-allowed inline-block" v-else>
                {{ toMoney(vehicle.balance, 2) }}
            </div>
        </activix-tooltip>
    </flexible-input>
</template>

<script>
    import FlexibleInput from '@/components/container/input/FlexibleInput.vue';
    import InputLabel from '@/components/presentational/input/InputLabel.vue';
    import InvisibleInput from '@/components/container/input/InvisibleInput';

    import LeadVehicle from '@/entities/LeadVehicle.js';

    export default {
        components: {
            FlexibleInput,
            InputLabel,
            InvisibleInput,
        },

        props: {
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        computed: {
            balanceCheckTooltip() {
                return this.vehicle.payment && this.vehicle.frequency
                    ? this.$t('clientCard.needPaymentAndFrequencyEmpty')
                    : '';
            },

            inputTooltip() {
                if (this.disabled) {
                    return '';
                }

                return this.balanceCheckTooltip;
            },
        },
    };
</script>
