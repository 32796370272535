<template>
    <div
        class="absolute right-0 top-0 z-10 flex justify-center items-center -mr-1 -mt-1"
        :class="{
            'group-hover:animate-slide-out': communication.isOutgoing,
            'group-hover:animate-slide-in': communication.isIncoming,
        }"
    >
        <icon
            class="w-4 h-4"
            :class="[communication.isOutgoing ? 'transform rotate-180' : '', communication.automation ? 'text-gray-500' : 'text-gray-600']"
            name="bold/arrow-thick-circle-bottom-left-corner"
        />
    </div>
</template>

<script>
    import Communication from '@/entities/Communication.js';

    export default {
        props: {
            communication: {
                required: true,
                type: Communication,
            },
        },
    };
</script>
