<template>
    <activix-confirm-modal
        type="error"
        :approve-text="$t('dashboards.removeIt')"
        :content="$t('dashboards.deleteFollowUpConfirmation', [$t(`dashboards.performance.${followUp}`)])"
        :deny-text="$t('general.cancel')"
        :opened="opened"
        :title="$t('general.areYouSure')"
        @update:opened="$emit('update:opened', $event)"
        @approve="$emit('approve')"
        @closed="$emit('closed')"
    />
</template>

<script>
    export default {
        props: {
            followUp: {
                type: String,
                required: true,
            },
            opened: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
