<template>
    <header
        class="
            flex justify-between items-stretch border-b border-gray-200
            h-12 md:h-18 xl:h-12 2xl:h-18
        "
    >
        <div class="relative">
            <lead-type-icon
                class="
                    p-1.5 rounded-r-lg rounded-tl-lg shadow-lg bg-white
                    -mt-12 md:-mt-14 xl:-mt-12 2xl:-mt-14
                "
                :lead="lead"
            />

            <lead-status
                data-intercom-target="lead.status"
                class="absolute bottom-0 right-0"
                icon-class="-mr-0.5 -mb-0.5 p-0.5 text-2xl bg-white shadow-md rounded-full border border-grey-200"
                :lead="lead"
            />
        </div>

        <div class="flex-1 flex justify-between items-stretch">
            <div class="flex-shrink flex items-center px-3 space-x-3 | md:px-6 xl:px-3 2xl:px-6">
                <primary-contact-type :lead="lead" @update-lead="updateLead" />
            </div>

            <div class="flex-shrink-0 flex items-stretch">
                <general-options @open-modal="openModal">
                    <template #actions="{ closeMenu }">
                        <lead-actions
                            class="md:hidden xl:flex 3xl:hidden"
                            :as-dropdown-items="true"
                            :lead="lead"
                            @action-selected="closeMenu"
                        />
                    </template>

                    <template #modals>
                        <consent-config
                            name="consentConfigMoreInfo"
                            @close="modals.consent.opened = false"
                        />
                        <contact-preference
                            :communication-preference="lead.communication_preference"
                            :opened="modals.preference.opened"
                            :has-niotext="lead.account.hasNioText()"
                            :unsubscribed-to-all-date="lead.unsubscribe_all_date"
                            :unsubscribed-to-email-date="lead.unsubscribe_email_date"
                            :unsubscribed-to-phone-date="lead.unsubscribe_call_date"
                            :unsubscribed-to-sms-date="lead.unsubscribe_sms_date"
                            @close="modals.preference.opened = false"
                            @update="updateLead( { data: $event })"
                        />
                        <d-n-d
                            :opened="modals.dnd.opened"
                            :unsubscribed-to-all-date="lead.unsubscribe_all_date"
                            @close="modals.dnd.opened = false"
                            @update="updateLead({ data: $event })"
                        />
                        <opt-out-management
                            :opened="modals.subscription.opened"
                            :unsubscribed-to-all-date="lead.unsubscribe_all_date"
                            :unsubscribed-to-email-date="lead.unsubscribe_email_date"
                            :unsubscribed-to-phone-date="lead.unsubscribe_call_date"
                            :unsubscribed-to-sms-date="lead.unsubscribe_sms_date"
                            @close="modals.subscription.opened = false"
                            @update="updateLead({ data: $event })"
                        />
                    </template>
                </general-options>

                <lead-actions :lead="lead" class="border-l border-gray-200 hidden md:flex xl:hidden 3xl:flex" />
            </div>
        </div>
    </header>
</template>

<script>
    import ConsentConfig from '@/components/modals/ConsentConfig';
    import ContactPreference from '@/components/modals/ContactPreference';
    import DND from '@/components/modals/DND';
    import GeneralOptions from '@/components/container/lead/GeneralOptions';
    import LeadActions from '@/components/container/lead/LeadActions.vue';
    import LeadStatus from '@/components/dashboards/columns/LeadStatus';
    import LeadTypeIcon from '@/components/presentational/lead/LeadTypeIcon';
    import OptOutManagement from '@/components/modals/OptOutManagement';
    import PrimaryContactType from '@/components/container/lead/PrimaryContactType.vue';

    import Lead from '@/entities/Lead';

    export default {
        components: {
            ConsentConfig,
            ContactPreference,
            DND,
            GeneralOptions,
            LeadActions,
            LeadStatus,
            LeadTypeIcon,
            OptOutManagement,
            PrimaryContactType,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        data: () => ({
            modals: {
                consent: {
                    opened: false,
                },
                dnd: {
                    opened: false,
                },
                preference: {
                    opened: false,
                },
                subscription: {
                    opened: false,
                },
            },
        }),

        watch: {
            'modals.consent.opened'() {
                if (this.modals.consent.opened) {
                    this.$modal.show('consentConfigMoreInfo', {
                        leadId: this.lead.id,
                    });
                }
            },
        },

        methods: {
            openModal(modalName) {
                this.modals[modalName].opened = true;
            },

            updateLead({ data, options = {} }) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
