<template>
    <div class="lg:flex lg:items-center mb-6 mx-2">
        <div class="w-1/4 mx-2">
            <label class="block text-grey-500 font-bold lg:text-left mb-1 lg:mb-0 ml-4" v-if="$te(translationKey)">
                {{ $t(translationKey) }}
            </label>
        </div>
        <div class="w-1/6 mx-2">
            <activix-masked-input
                class="price | text-center"
                type="text"
                :integer-limit="8"
                mask-type="money"
                :placeholder="$t('general.price')"
                :value="cost"
                @input="$emit('update:cost', $event)"
            />
        </div>
        <div class="w-1/2 mx-2">
            <input
                type="text"
                class="form-control | text-left"
                maxlength="256"
                :placeholder="$t('modal.desc')"
                :value="description"
                @input="$emit('update:description', $event.target.value)"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AccidentedVehiclePart',

        props: {
            cost: {
                type: [Number, String],
            },
            description: {
                type: String,
            },
            name: {
                type: String,
            },
        },

        computed: {
            translationKey() {
                return `clientCard.leadVehicleAccidented.${this.name}`;
            },
        },
    };
</script>
