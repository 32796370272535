<template>
    <flexible-input>
        <input-label slot="label" class="flex items-center" :blocked-reason="internalBlockedReason">
            {{ $t('clientCard.vehicles.year') }}
            <activix-tooltip :content="tooltipMatch" v-if="vehicle.exportSupplier">
                <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
            </activix-tooltip>
        </input-label>

        <activix-tooltip :content="vehicleValueTooltip">
            <invisible-multiselect
                :append-missing-value="true"
                :disabled="isDisabled"
                :nullable="true"
                :options="options"
                :value="vehicleYear"
                @input="$emit('input', $event)"
            />
        </activix-tooltip>
    </flexible-input>
</template>

<script>
    import { mapState } from 'pinia';
    import { useClientCardStore } from '@/store/modules/clientCard/store.js';

    import { range } from 'lodash-es';

    import FlexibleInput from '@/components/container/input/FlexibleInput.vue';
    import InputLabel from '@/components/presentational/input/InputLabel.vue';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect.vue';

    import ActivixDate from '@/value-objects/ActivixDate.js';
    import LeadVehicle from '@/entities/LeadVehicle.js';

    export default {
        components: {
            FlexibleInput,
            InputLabel,
            InvisibleMultiselect,
        },

        props: {
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            minYear: {
                type: Number,
                default: 1899,
            },
            maxYear: {
                type: Number,
                default: () => new ActivixDate('now').year + 3,
            },
            stockManagementEnabled: {
                type: Boolean,
                required: true,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        computed: {
            ...mapState(useClientCardStore, ['vehicleMakes', 'vehicleModels']),

            hasVehicleValue() {
                return !empty(this.vehicle.vehicle_value);
            },

            internalBlockedReason() {
                if (this.blockedReason) {
                    return this.blockedReason;
                }

                if (this.vehicle.isExported) {
                    return this.$t('clientCard.blocked.vehicleExported');
                }

                if (this.hasVehicleValue) {
                    return this.$t('clientCard.blocked.vehicleAppraisalCompleted');
                }

                if (this.isLinkedToInventory) {
                    return this.$t('clientCard.blocked.vehicleStock');
                }

                return '';
            },

            isDisabled() {
                return this.disabled || this.vehicle.isExported || this.hasVehicleValue || this.isLinkedToInventory;
            },

            isLinkedToInventory() {
                return !empty(this.vehicle.inventory_unit_id) && this.stockManagementEnabled;
            },

            options() {
                return range(this.maxYear, this.minYear, -1)
                    .map(year => {
                        const yearString = year.toString();

                        return {
                            value: yearString,
                            text: yearString,
                            bold: this.vehicleYears.some(y => y.name === yearString),
                        };
                    });
            },

            tooltipMatch() {
                const exportSupplier = this.vehicle.exportSupplier;

                if (!exportSupplier) {
                    return '';
                }

                return `${this.$t(`${exportSupplier.display_name}.tooltip.match`)}`;
            },

            vehicleMake() {
                return this.vehicleMakes.find(make => make.name === this.vehicle.make) || {};
            },

            vehicleModel() {
                const makeId = this.vehicleMake.id;

                return this.vehicleModels[makeId]?.find(model => model.name === this.vehicle.model) || {};
            },

            vehicleValueTooltip() {
                return this.hasVehicleValue ? this.$t('clientCard.vehicleEvaluate') : '';
            },

            vehicleYear() {
                return this.vehicle.year ? this.vehicle.year.toString() : null;
            },

            vehicleYears() {
                return this.vehicleModel.years || [];
            },
        },
    };
</script>
