<template>
    <div>
        <div class="box | mb-6" :class="{ loading: loading }">
            <div class="box-header | flex items-center">
                <h4 class="box-title">
                    {{ $t('clientCard.history') }}
                </h4>
                <div class="relative flex items-center ml-2">
                    <activix-tooltip :content="$t('dashboards.refresh')">
                        <icon
                            :name="$icons.loading"
                            class="link-grey-light"
                            :class="{ 'spin-inverse': fetching || loading }"
                            @click="fetchHistory(true)"
                        />
                    </activix-tooltip>
                    <div class="badge badge-warning | absolute top-0 right-0 -mr-px -mt-px" v-if="refreshNeeded" />
                </div>
            </div>
            <div class="box-body | pt-0" :class="{ 'pb-2': mdLayout }">
                <div v-if="!formatedActivities.length">
                    {{ $t('history.noHistory') }}
                </div>
                <ul class="list-none p-0 -my-3" v-else>
                    <li :key="index" v-for="(item, index) in formatedActivities">
                        <history-item class="py-3" :item="item" :bordered="index !== 0" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    // Uitls
    import { camelCase, get, difference, first } from 'lodash-es';
    import { mapState } from 'pinia';

    // Entities
    import CaslConsentType from '@/entities/CaslConsentType.js';
    import Supplier from '@/entities/Supplier';
    import CommentType from '../../entities/CommentType.js';
    import Division from '../../entities/Division.js';
    import LeadType from '../../entities/LeadType.js';

    // Components
    import HistoryItem from './HistoryItem.vue';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            HistoryItem,
        },
        data() {
            return {
                loading: true,
                fetching: false,
                refreshNeeded: false,
                activities: [],
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),
            ...mapState(useGlobalStore, ['authUser', 'configs', 'guest', 'usersAndSupplierUsers']),

            formatedActivities() {
                return this.activities
                    .filter(item => {
                        return this.isVisible(item) && (!['client', 'customer'].includes(item.field_name) || this.showAssociation(item));
                    })
                    .map(item => {
                        return {
                            ...item,
                            isAssociation: this.showAssociation(item),
                            formatedValue: this.formatActivity(item),
                            formatedType: this.formatType(item),
                        };
                    });
            },

            dontShowProfit() {
                if (this.authUser.id == this.contextLead.user_id || this.authUser.id == this.contextLead.bdc_user_id) {
                    return false;
                }

                if (this.contextAccount.sale_table_options && !this.contextAccount.sale_table_options.hide_profit) {
                    return false;
                }

                if (this.authUser.isAdvisor() || this.authUser.isBdc() || this.authUser.isBdcDirector()) {
                    return true;
                }

                return false;
            },

            users() {
                return this.usersAndSupplierUsers.concat(this.authUser.account.users);
            },
        },

        watch: {
            contextLead: {
                deep: true,
                handler(newValue, oldValue) {
                    if (this.fetching || !newValue.id) {
                        return;
                    }

                    if (newValue.id != oldValue.id) {
                        this.fetchHistory();
                        return;
                    }

                    this.refreshNeeded = true;
                },
            },
        },

        methods: {
            fetchHistory(showLoading = false) {
                if (this.fetching || !this.contextLead.id) {
                    return;
                }

                this.fetching = true;
                this.refreshNeeded = false;

                if (showLoading) {
                    this.loading = true;
                }

                this.$axios
                    .get(`v1/leads/${this.contextLead.id}/history`)
                    .then(response => {
                        this.activities = response.data.data;
                        this.loading = false;
                        this.fetching = false;
                    })
                    .catch(() => {
                        this.loading = false;
                        this.fetching = false;
                    });
            },

            formatActivity(item) {
                let value = '';
                const type = item.type.indexOf('vehicle') !== -1 ? 'vehicle' : camelCase(item.type);

                value += `<span class="text-blue-500">${this.formatUserName(item)}</span> `;

                if (item.field_name == 'created_at' && item.old_value === null) {
                    // Created
                    if (item.type == 'lead_phone') {
                        value += `${this.$t('history.addedNumber')}
                        ${this.getPhoneType(item.model_id)
                            ? `<span class="text-blue-500">(${this.getPhoneType(item.model_id)})</span>`
                            : ''
                        }`;
                    } else if (item.type == 'lead_email') {
                        value += `${this.$t('history.addedEmail')}
                        ${this.getEmailType(item.model_id)
                            ? `<span class="text-blue-500">(${this.getEmailType(item.model_id)})</span>`
                            : ''
                        }`;
                    } else if (item.type == 'customables' && item.relevant_data) {
                        value += `
                            ${this.$t('history.addedCustomable')}
                            <span class="text-blue-500">${item.relevant_data}</span>
                        `;
                    } else {
                        value += `${this.$t('history.added')} ${
                            this.getGender(item.type) == 'F' ? this.$t('history.aFeminine') : this.$t('history.a')
                        } `;
                        // Field name
                        if (this.$te(`history.${camelCase(item.type)}.value`)) {
                            value += `<span class="text-blue-500">${this.$t(
                                `history.${camelCase(item.type)}.value`,
                            )}</span> `;
                        } else {
                            value += `<span class="text-blue-500">${this.$t(
                                `history.${camelCase(item.type)}Value`,
                            )}</span> `;
                        }
                    }
                } else if (item.type === 'customables') {
                    const oldValue = this.formatValue(item, 'old');
                    const newValue = this.formatValue(item, 'new');

                    if (oldValue == newValue) {
                        return null;
                    }

                    value += `
                        ${this.$t('history.updated')}
                        <span class="text-blue-500">${item.relevant_data}</span>
                        ${this.$t('history.from')}
                        <code>${oldValue}</code>
                        ${this.$t('history.to')}
                        <code>${newValue}</code>
                    `;
                } else if (item.type === 'customer') {
                    if (!this.$feature.isEnabled('casl_compliance')) {
                        return null;
                    }

                    if (item.field_name === 'casl_consent.confirmed_type') {
                        if (item.new_value === CaslConsentType.MANUAL) {
                            value += `${this.$t('history.customer.caslConsentConfirmedTypeManual')}`;
                        } else if (!item.new_value) {
                            value += `${this.$t('history.customer.caslConsentConfirmedTypeManualRemoved')}`;
                        } else {
                            return null;
                        }
                    } else if (item.field_name === 'casl_consent.confirmation.sent_at') {
                        value += `${this.$t('history.customer.caslConsentConfirmationSentAt')}`;
                    } else {
                        return null;
                    }
                } else if (item.type === 'communication') {
                    const oldValue = this.formatValue(item, 'old');
                    const newValue = this.formatValue(item, 'new');

                    value += `
                        ${this.$t('history.updated')}
                        <span class="text-blue-500">"${this.$t(`history.communication.field.${item.field_name}`)}"</span>
                        ${this.$t('history.ofFeminine')}
                        <span class="text-blue-500 lowercase">${this.$t('history.communication.entityName')}</span>
                        ${this.$t('history.from')}
                        <code>${oldValue}</code>
                        ${this.$t('history.to')}
                        <code>${newValue}</code>
                    `;
                } else if (
                    item.field_name === 'deleted_at' &&
                    item.old_value === null &&
                    (item.type === 'lead_phone' || item.type === 'lead_email')
                ) {
                    // Deleted
                    if (item.type === 'lead_phone' && item.relevant_data) {
                        value += `${this.$t('history.deletedNumber')} <code>${this.maskValue(item.type, item.relevant_data)}</code>`;
                    } else if (item.type == 'lead_email' && item.relevant_data) {
                        value += `${this.$t('history.deletedEmail')} <code>${this.maskValue(item.type, item.relevant_data)}</code>`;
                    }
                } else if (item.field_name == 'renewal_cycle') {
                    // Renewal Cycle
                    value += this.$t('history.renewalCycle');
                } else if (item.field_name == 'merged') {
                    // Merge
                    value += `${this.$t('history.merged')} <code>${item.old_lead_name}</code> ${this.$t(
                        'history.toThisLead',
                    )}`;
                } else if (item.field_name == 'exported') {
                    const oldValue = item.old_value ? JSON.parse(item.old_value) : [];
                    const newValue = item.new_value ? JSON.parse(item.new_value) : [];
                    // Export
                    const exportedDms = first(difference(newValue, oldValue));
                    if (exportedDms && Supplier.isSupplierDeskingTool(exportedDms)) {
                        value += this.$t('history.exportedToDeskingTool');
                    } else {
                        value += this.$t('history.exportedToDms');
                    }
                } else if (item.field_name == 'exported_suppliers') {
                    // Export
                    value += this.getDmsName(item);
                } else if (item.field_name == 'customer' || item.field_name == 'client') {
                    value += `${this.$t('history.associated')} `;

                    return value;
                } else if (item.field_name == 'eventLeadCreated') {
                    value += `${this.$t('history.eventLeadCreated')} `;

                    return value;
                } else if (item.field_name == 'manualAssociation') {
                    value += `${this.$t('history.manualAssociation')} `;

                    return value;
                } else if (item.field_name == 'deleted_at' && item.type == 'lead') {
                    if (item.old_value === null && item.new_value !== null) {
                        value += `${this.$t('history.deleted')} `;
                    } else if (item.old_value !== null && item.new_value === null) {
                        value += `${this.$t('history.restored')} `;
                    }
                } else if (item.field_name == 'deleted_at' && item.type == 'task_event') {
                    value += `${this.$t('history.taskEvent.deleted')} `;

                    // Field name
                    value += `<span class="text-blue-500">"${this.$t('history.taskEvent.value')}"</span> `;
                } else if (item.field_name == 'second_user' || item.field_name == 'second_bdc_user') {
                    if (item.old_value == '...' && item.new_value !== null && item.field_name == 'second_user') {
                        value += `${this.$t('history.assignationDemand')}  <code>${this.$t('history.advisor')}</code> `;
                    } else if (
                        item.old_value == '...' &&
                        item.new_value !== null &&
                        item.field_name == 'second_bdc_user'
                    ) {
                        value += `${this.$t('history.assignationDemand')}  <code>${this.$t('history.bdc')}</code> `;
                    } else {
                        return null;
                    }
                } else {
                    // Updated
                    value += `${this.$t('history.updated')} `;

                    // Field name
                    if (item.field_name === 'postal_code') {
                        value += `<span class="text-blue-500">"${this.$t(
                            `history.${type}.${camelCase(item.field_name)}.${this.contextLead.country}`,
                        )}"</span> `;
                    } else if (item.type === 'comment' && item.relevant_data?.related_id && item.relevant_data?.type === CommentType.CASE) {
                        value += `${this.$t('history.the')} <span class="text-blue-500">${this.$t('history.case')}</span> `;
                        value += `<code>#${item.relevant_data?.related_id}</code>`;

                        return value;
                    } else {
                        value += `<span class="text-blue-500">"${this.$t(
                            `history.${type}.${camelCase(item.field_name)}`,
                        )}"</span> `;
                    }

                    if (this.getGender(item.type) == 'F') {
                        value += this.$t('history.ofFeminine');
                    } else {
                        value += this.$t('history.of');
                    }

                    if (item.type == 'lead_phone') {
                        value += ` ${this.$t(`history.${camelCase(item.type)}Value`, [
                            this.getPhoneType(item.model_id),
                        ]).toLowerCase()} `;
                    } else if (item.type == 'lead_email') {
                        value += ` ${this.$t(`history.${camelCase(item.type)}Value`, [
                            this.getEmailType(item.model_id),
                        ]).toLowerCase()} `;
                    } else if (this.$te(`history.${camelCase(item.type)}.value`)) {
                        value += ` ${this.$t(`history.${camelCase(item.type)}.value`).toLowerCase()} `;
                    } else {
                        value += ` ${this.$t(`history.${camelCase(item.type)}Value`).toLowerCase()} `;
                    }

                    // Old value
                    value += `${this.$t('history.from')} <code>${this.formatValue(item, 'old')}</code> `;

                    // New value
                    value += `${this.$t('history.to')} <code>${this.formatValue(item, 'new')}</code> `;
                    if (this.formatValue(item, 'old') == this.formatValue(item, 'new')) {
                        return null;
                    }
                }

                value = `${value.trim()}.`;

                return value;
            },

            formatUserName(item) {
                if (!item.user_id) {
                    return 'Activix CRM';
                }

                if (item.user_name) {
                    return item.user_name;
                }

                const user = this.users.find(user => user.id == item.user_id);

                return user?.fullName || this.$t('general.unknown');
            },

            formatType(item) {
                let value = '';
                const lead = this.associatedLead(item);

                if (lead) {
                    value = ` (${this.getTypeName(lead)})`;
                }

                return value || '';
            },

            associatedLead(item) {
                if (!this.contextLead.customer || !this.contextLead.customer.leads) {
                    return '';
                }

                const lead = this.contextLead.customer.leads.find(lead => lead.id == item.relevant_data);

                return lead || '';
            },

            formatValue(item, type) {
                const provinces = this.configs.provinces;

                let typeValue = item[`${type}_value`];
                const rawTypeValue = item[`raw_${type}_value`];

                // Null
                if (empty(typeValue)) {
                    return '...';
                }

                // Boolean
                if (['0', '1'].includes(item.new_value) && ['0', '1', null].includes(item.old_value)) {
                    return typeValue === '1' ? this.$t('general.yes') : this.$t('general.no');
                }

                if (typeValue == '...') {
                    return '...';
                }

                const dateType = get(this.configs.dateCasts, item.field_name);

                if (['date', 'datetime'].includes(dateType)) {
                    switch (dateType) {
                        case 'date':
                            typeValue = locale_date(typeValue, '', 'UTC');
                            break;

                        default:
                            typeValue = locale_dt(typeValue, '', 'UTC');
                            break;
                    }

                    return typeValue.humanShort();
                }

                // Specific fields
                if (item.type == 'lead') {
                    // Lead
                    switch (camelCase(item.field_name)) {
                        case 'locale':
                            if (['fr', 'en'].includes(typeValue.toLowerCase())) {
                                return this.$t(`database.${typeValue.toLowerCase()}`);
                            }

                            return typeValue;

                        case 'source':
                            return this.contextAccount.sourcesWithProvider.find(source => source.id == rawTypeValue)?.name || typeValue;

                        case 'civility':
                            switch (typeValue) {
                                case 'mr':
                                    return this.$t('database.mr');
                                case 'ms':
                                    return this.$t('database.ms');
                                case 'miss':
                                    return this.$t('database.miss');
                                default:
                                    return typeValue;
                            }

                        case 'sex':
                            return typeValue == 'W' ? this.$t('database.female') : this.$t('database.male');

                        case 'province':
                            if (!empty(provinces[typeValue.toUpperCase()])) {
                                return this.$t(`clientCard.provinces.${typeValue.toUpperCase()}`);
                            }
                            return typeValue;

                        case 'status':
                            return this.$t(`result.${typeValue}`);

                        case 'discountedDate':
                            return typeValue ? this.$t('general.no') : this.$t('general.yes');

                        case 'division':
                            return this.$te(`divisions.${typeValue}`)
                                ? this.$t(`divisions.${typeValue}`)
                                : Division.getTranslation(typeValue);

                        case 'leadType':
                            return this.$t(`leadTypes.${camelCase(typeValue)}`);

                        case 'leadForm': {
                            const keyPath = `leadForms.${typeValue}`;

                            if (this.$te(keyPath)) {
                                return this.$t(keyPath);
                            }

                            break;
                        }

                        case 'productTotal':
                            return this.toMoney(typeValue, 2);

                        case 'result':
                            return this.$t(`clientCard.${typeValue}`);

                        case 'progressState':
                            return this.$t(`progress_state.${typeValue}`);
                    }
                } else if (item.type.indexOf('vehicle') !== -1) {
                    // Vehicle
                    switch (camelCase(item.field_name)) {
                        case 'stockState':
                            return this.$t(`inventory.${typeValue}`);

                        case 'verifiedBy':
                            return typeValue == 'nothing' ? this.$t('general.no') : this.$t('general.yes');

                        case 'modality':
                        case 'frequency':
                        case 'condition':
                        case 'intention':
                        case 'category':
                        case 'categoryRv':
                        case 'length':
                        case 'mechanical':
                        case 'fuel':
                            if (typeValue == 'two_weeks') {
                                return this.$t('clientCard.vehicles.biWeekly');
                            }

                            return this.$t(`clientCard.vehicles.${typeValue}`);

                        case 'term':
                            return `${typeValue} ${this.$t('clientCard.vehicles.months')}`;

                        case 'rate':
                            return `${typeValue} %`;

                        case 'soldDate':
                            return locale_dt(typeValue, '', 'UTC').humanShort();

                        case 'weight':
                            return typeValue;

                        case 'payment':
                        case 'profit':
                        case 'price':
                        case 'accessories':
                        case 'securityDeposit':
                        case 'residual':
                        case 'requested':
                        case 'value':
                        case 'budgetMax':
                        case 'budgetMin':
                        case 'initialCash':
                            return this.toMoney(typeValue, 2);
                    }
                } else if (item.type == 'lead_phone' && !this.authUser.isAdmin()) {
                    return this.maskPhone(typeValue);
                } else if (item.type == 'lead_email' && !this.authUser.isAdmin()) {
                    return this.maskEmail(typeValue);
                } else if (item.type == 'customables') {
                    if (item.custom_field_type == 'boolean') {
                        return typeValue === '1' ? this.$t('general.yes') : this.$t('general.no');
                    }

                    if (item.custom_field_type == 'datetime') {
                        return locale_dt(typeValue, '', 'UTC').humanShort();
                    }

                    if (item.custom_field_type == 'currency') {
                        return this.toMoney(typeValue / 100, 2);
                    }
                } else if (item.type === 'communication') {
                    return this.$t(`history.communication.value.${typeValue}`);
                }

                return typeValue;
            },

            maskValue(type, value) {
                if (this.authUser.isAdmin()) {
                    return value;
                }

                if (type == 'lead_phone') {
                    return this.maskPhone(value);
                }

                if (type == 'lead_email') {
                    return this.maskEmail(value);
                }

                return value;
            },

            getGender(type) {
                type = camelCase(type);

                if (type == 'taskEvent' || type == 'reminder') {
                    return 'F';
                }

                return 'M';
            },

            isVisible(item) {
                if (
                    !this.contextLead.account.hasGeneralInfoField(item.field_name) ||
                    !this.contextLead.account.hasPerformanceField(item.field_name) ||
                    !this.contextLead.account.hasProcessField(item.field_name) ||
                    !this.contextLead.account.hasVehicleField(item.field_name, item.type)
                ) {
                    return false;
                }

                if (item.type == 'customables' && item.custom_field_type == 'textarea') {
                    return false;
                }

                if (!['lead', 'task_event', 'lead_phone', 'lead_email'].includes(item.type) && item.field_name == 'deleted_at') {
                    return false;
                }

                if (item.type == 'task_event' && item.field_name == 'confirmation_timeframe') {
                    return false;
                }

                if (item.type == 'lead' &&
                    item.field_name == 'call_date' &&
                    this.contextLead.lead_type_id !== LeadType.PHONE
                ) {
                    return false;
                }

                if (item.type == 'lead' && ['created_at', 'updated_at'].includes(item.field_name)) {
                    return false;
                }

                if (item.type == 'lead_phone' && item.field_name == 'lead') {
                    return false;
                }

                if (
                    (item.type == 'exchange_vehicle' && item.field_name == 'type') ||
                    (item.type == 'wanted_vehicle' && item.field_name == 'type')
                ) {
                    return false;
                }

                if (this.formatActivity(item) === null) {
                    return false;
                }

                if (item.field_name == 'profit' && this.dontShowProfit) {
                    return false;
                }

                return true;
            },

            getPhoneType(id) {
                for (let i = 0; i < this.contextLead.lead_phones.length; i++) {
                    if (this.contextLead.lead_phones[i].id == id) {
                        return this.$t(`history.leadPhone.type.${this.contextLead.lead_phones[i].type}`);
                    }
                }
                return '';
            },

            getEmailType(id) {
                for (let i = 0; i < this.contextLead.lead_emails.length; i++) {
                    if (this.contextLead.lead_emails[i].id == id) {
                        return this.$t(`history.leadEmail.type.${this.contextLead.lead_emails[i].type}`);
                    }
                }

                return '';
            },

            getTypeName(lead) {
                switch (lead.lead_type_id) {
                    case 1:
                        return this.$t('dashboards.webLead');
                    case 2:
                        return this.$t('dashboards.phoneUp');
                    case 3:
                        return this.$t('dashboards.walkIn');
                    case 4:
                        return this.$t('dashboards.loyalty');
                    case 5:
                        return this.$t('dashboards.renewalAbr');
                    case 6:
                        return this.$t('dashboards.sms');
                    case 7:
                        return this.$t('dashboards.event');
                    case 8:
                        return this.$t('dashboards.prebooking');
                    case 10:
                        return this.$t('dashboards.webOrder');
                }

                return '';
            },

            showAssociation(item) {
                return ['customer', 'client', 'eventLeadCreated', 'manualAssociation'].includes(item.field_name) && this.authUser.isAdmin();
            },

            getDmsName(item) {
                if (!item.new_value) {
                    return this.$t('history.exportedToDms');
                }

                const oldValues = JSON.parse(item.old_value);
                const newValues = JSON.parse(item.new_value);
                const newSupplier = {};

                // get difference between two objects
                for (const [key, value] of Object.entries(newValues)) {
                    if (!oldValues.hasOwnProperty(key)) {
                        newSupplier[key] = value;
                    }
                }

                if (Object.keys(newSupplier).length === 0) {
                    return this.$t('history.exportedToDms');
                }

                const supplierId = newSupplier[Object.keys(newSupplier)[0]];

                const supplier = this.contextAccount.suppliers.find(supplier => supplier.id === supplierId);

                if (!supplier) {
                    return this.$t('history.exportedToDms');
                }

                const displayName = camelCase(supplier.display_name);

                return `${this.$t('history.exportedToSupplier')} ${this.$t(`suppliers.${displayName}`)}`;
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.fetchHistory();
            });
        },
    };
</script>
