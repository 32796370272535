<template>
    <notes-box
        :account="contextLead.account"
        :clear-context.sync="clearContext"
        :filters="filters"
        :filter-values="filterValues"
        :initial-fetch-completed="initialFetchCompleted"
        :notes="notes"
        :pagination="pagination"
        @add-audio-note="addAudioNote"
        @add-note="addNote"
        @apply-filters="applyFilters"
        @clear-filters="clearFilters"
        @delete-note="deleteNote"
        @fetch-next-page="fetchNextPage"
        @refresh-items="refreshItems"
        @update-note="updateNote"
    />
</template>

<script>
    import { mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store';

    import NotesBox from '@/components/container/boxes/NotesBox';
    import NotesBoxMixin from '@/mixins/NotesBoxMixin';

    import CommentType from '@/entities/CommentType';

    export default {
        components: {
            NotesBox,
        },

        mixins: [NotesBoxMixin],

        data: () => ({
            filtersStorageKey: 'lead.notes.filters',
        }),

        computed: {
            ...mapState(useContextStore, ['contextLead']),

            filters() {
                return {
                    noteType: {
                        label: this.$t('clientCard.boxes.notes.filters.noteType.title'),
                        options: CommentType.selectOptions(this.contextLead.account, 'value', 'text'),
                        type: 'select',
                        value: this.filterValues.noteType?.[0],
                    },
                };
            },
        },

        watch: {
            'contextLead.id'() {
                this.initialFetchCompleted = false;
                this.triggerClearContext();
                this.refreshItems();
            },
        },

        methods: {
            async addAudioNote({ note, rootNoteId }) {
                this.$wait.start('creating.note');

                try {
                    await this.$api.comments.storeAudioNote({
                        ...note,
                        leadId: this.contextLead.id,
                    });

                    this.onNoteAdded(rootNoteId);
                } finally {
                    this.$wait.end('creating.note');
                }
            },

            async addNote({ note, rootNoteId }) {
                this.$wait.start('creating.note');

                note.lead_id = this.contextLead.id;

                try {
                    await this.$api.comments.store({
                        comment: note,
                        file: note.file,
                    });

                    if (note.alert_users.length > 0) {
                        this.$behavior.track('Notes', { action: 'mention', users: note.alert_users });
                    }

                    this.onNoteAdded(rootNoteId);
                } finally {
                    this.$wait.end('creating.note');
                }
            },

            async deleteNote({ note, rootNoteId }) {
                if (!note) {
                    return;
                }

                const waitKey = `deleting.note[${note.id}]`;

                if (this.$wait.is(waitKey)) {
                    return;
                }

                this.$wait.start(waitKey);

                try {
                    await this.$api.comments.destroy(note.id);

                    this.removeNoteFromList(note.id, rootNoteId);
                } catch (error) {
                    if (error.response && error.response.status !== 404) {
                        this.appendNewError({
                            code: '0085',
                            display: true,
                            error,
                            payload: {
                                comment_id: note.id,
                            },
                        });
                    }

                    throw error;
                } finally {
                    this.$wait.end(waitKey);
                }
            },

            async fetchPage(page, replaceItems = false) {
                if (!this.contextLead.id) {
                    return;
                }

                this.$wait.start('fetching.notes');

                const skip = (page - 1) * this.pagination.perPage;

                const response = await this.$axios.get(`v1/comments/${this.contextLead.id}`, {
                    params: {
                        skip,
                        take: this.pagination.perPage,
                        filter: this.filterValues.noteType?.[0],
                    },
                });

                const fetchedNotes = Object.values(response.data.comments);

                this.mergeFetchedNotes(fetchedNotes, replaceItems);

                this.pagination.totalItemsCount = response.data.total;

                this.$wait.end('fetching.notes');

                this.initialFetchCompleted = true;
            },

            async updateNote({ note, rootNoteId }) {
                if (!note) {
                    return;
                }

                this.$wait.start('updating.note');

                const updatedNoteData = await this.$api.comments.update(note.id, note);

                this.updateNoteFromList(note.id, rootNoteId, updatedNoteData);

                this.$wait.end('updating.note');
            },
        },
    };
</script>
