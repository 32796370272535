<template>
    <div class="flex items-center">
        <activix-tooltip :content="tooltip">
            <follow-up-field
                follow-up="appt_call"
                :checkbox-color="checkboxColor"
                :date="lead.appt_call_date"
                :disabled="isDisabled"
                :follow-up-params="{ showPhoneIcon: true }"
                :lead-id="lead.id"
                @input="updateLead({ appt_call_date: $event })"
            />
        </activix-tooltip>

        <span class="flex items-center ml-4" v-if="canAddAppointment && !isDisabled">
            <icon name="bold/add" class="link-grey text-sm" @click="addAppointment" />
        </span>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import FollowUpField from '@/components/container/lead/input/FollowUpField.vue';

    import ActivixDate from '@/value-objects/ActivixDate.js';
    import Lead from '@/entities/Lead.js';
    import LeadType from '@/entities/LeadType';
    import TaskEventType from '@/entities/TaskEventType';

    export default {
        components: { FollowUpField },

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            disabledTooltip: {
                type: String,
                default: '',
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            appointmentDate() {
                return new ActivixDate(this.lead.appointment_date);
            },

            canAddAppointment() {
                return !this.phoneAppointmentDate.isEmpty() && this.appointmentDate.isEmpty();
            },

            checkboxColor() {
                if (this.mainEvent?.canceled) {
                    return 'red';
                }

                if (this.mainEvent?.confirmed) {
                    return 'green';
                }

                return 'purple';
            },

            isDisabled() {
                if (this.disabled) {
                    return true;
                }

                if (this.lead.isService()) {
                    return true;
                }

                if (this.lead.isOfType(LeadType.WALK_IN)) {
                    return true;
                }

                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (this.lead.sale_date) {
                    return true;
                }

                if (this.lead.presented_date || this.lead.home_presented_date) {
                    return true;
                }

                if (this.lead.appointment_date && !this.lead.appt_call_date) {
                    return true;
                }

                if (!this.$can('leads.phone_appointment:update')) {
                    return true;
                }

                return this.guest;
            },

            mainEvent() {
                if (!this.lead || !this.lead.task_events) {
                    return false;
                }

                return this.lead.task_events.find(taskEvent => {
                    return taskEvent.task_event_type_id === TaskEventType.PHONE_APPOINTMENT && taskEvent.main_event;
                });
            },

            phoneAppointmentDate() {
                return new ActivixDate(this.lead.appt_call_date);
            },

            tooltip() {
                if (this.disabled) {
                    return this.disabledTooltip;
                }

                let disabledTooltip = '';

                if (this.lead.isService()) {
                    disabledTooltip = this.$t('result.leadIsService');
                } else if (this.lead.isOfType(LeadType.WALK_IN)) {
                    disabledTooltip = this.$t('result.leadIsWalkIn');
                } else if (this.lead.hasInvalidStatus()) {
                    disabledTooltip += `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.phoneAppointment'),
                    ])}`;
                } else if (this.lead.sale_by_phone) {
                    disabledTooltip = this.$t('result.leadIsSoldbyPhone');
                } else if (this.lead.presented_date || this.lead.home_presented_date) {
                    disabledTooltip = this.$t('result.leadAlreadyPresented');
                } else if (this.lead.appointment_date && !this.lead.appt_call_date) {
                    disabledTooltip = this.$t('result.leadAlreadyHasAppointment');
                } else if (this.guest) {
                    disabledTooltip = this.$t('clientCard.guestMode');
                }

                if (disabledTooltip) {
                    return `
                        <div class="italic">${disabledTooltip}</div>
                    `;
                }

                if (this.lead.appt_call_date && this.mainEvent && this.mainEvent.no_show) {
                    return `<div>${this.$t('modal.noShowTooltip')}</div>`;
                }

                return '';
            },
        },

        methods: {
            addAppointment() {
                if (this.isDisabled) {
                    return;
                }

                this.$modal.show('followUp', {
                    leadId: this.lead.id,
                    current: 'appointment',
                    last: null,
                });
            },

            openCancelAppointmentModal() {
                this.$modal.show('cancelAppointment', {
                    leadId: this.lead.id,
                    mainEventId: this.mainEvent?.id,
                    isPhoneAppointment: true,
                });
            },

            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },

        created() {
            this.$eventBus.$on('open-cancel-phone-appointment', this.openCancelAppointmentModal);
        },

        beforeDestroy() {
            this.$eventBus.$off('open-cancel-phone-appointment', this.openCancelAppointmentModal);
        },
    };
</script>
