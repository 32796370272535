<template>
    <div class="flex items-stretch divide-x divide-gray-300 shadow-xl" v-if="arrowsAreVisible">
        <activix-tooltip :content="$t('clientCard.previousDashboardLead')">
            <router-link
                :tag="previousLeadDisabled ? 'button' : 'a'"
                :disabled="previousLeadDisabled"
                :replace="true"
                class="relative flex flex-col justify-center items-center w-12 h-12 py-3 rounded-l-lg text-lg text-gray-700 bg-white bg-opacity-80 filter backdrop-blur-sm"
                :class="{
                    'text-gray-500 cursor-not-allowed': previousLeadDisabled,
                    'active:text-gray-700 focus:text-gray-700 hover:text-gray-700 hover:bg-gray-300 visited:text-gray-700': !previousLeadDisabled,
                }"
                :to="previousLead.link"
            >
                <icon name="regular/arrow-left-1" />
            </router-link>
        </activix-tooltip>

        <activix-tooltip :content="$t('clientCard.nextDashboardLead')">
            <router-link
                :tag="nextLeadDisabled ? 'button' : 'a'"
                :disabled="nextLeadDisabled"
                :replace="true"
                class="relative flex flex-col justify-center items-center w-12 h-12 py-3 rounded-r-lg text-lg text-gray-700 bg-white bg-opacity-80 filter backdrop-blur-sm"
                :class="{
                    'text-gray-500 cursor-not-allowed': nextLeadDisabled,
                    'active:text-gray-700 focus:text-gray-700 hover:text-gray-700 hover:bg-gray-300 visited:text-gray-700': !nextLeadDisabled,
                }"
                :to="nextLead.link"
            >
                <icon name="regular/arrow-right-1" />
            </router-link>
        </activix-tooltip>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useDashboardStore } from '@/store/modules/dashboard/store';

    import Lead from '@/entities/Lead.js';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useDashboardStore, ['tableLeads']),

            arrowsAreVisible() {
                return !this.lgLayout && this.tableLeads.length > 1 && this.currentLeadIndex !== -1;
            },

            currentLeadIndex() {
                return this.tableLeads.findIndex(lead => lead.id === this.lead.id);
            },

            nextLead() {
                const nextIndex = this.currentLeadIndex + 1;

                if (nextIndex > 0 && nextIndex <= this.tableLeads.length - 1) {
                    const leadId = this.tableLeads[nextIndex].id;

                    return {
                        id: leadId,
                        link: {
                            name: 'leads.update',
                            params: { id: leadId },
                        },
                    };
                }

                return {
                    id: 0,
                    link: '',
                };
            },

            nextLeadDisabled() {
                return !this.nextLead.id;
            },

            previousLead() {
                const previousIndex = this.currentLeadIndex - 1;

                if (previousIndex >= 0) {
                    const leadId = this.tableLeads[previousIndex].id;

                    return {
                        id: leadId,
                        link: {
                            name: 'leads.update',
                            params: { id: leadId },
                        },
                    };
                }

                return {
                    id: 0,
                    link: '',
                };
            },

            previousLeadDisabled() {
                return !this.previousLead.id;
            },
        },
    };
</script>
