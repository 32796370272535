<template>
    <activix-modal
        portal="modal-1"
        size="xl"
        :closable="false"
        :no-padding="true"
        :opened="opened"
        @close="close"
    >
        <template slot="body">
            <merge-management @merged="close" />
        </template>
    </activix-modal>
</template>

<script>
    import MergeManagement from '@/components/lead/MergeManagement.vue';

    export default {
        components: {
            MergeManagement,
        },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
        },

        methods: {
            close() {
                this.$emit('close');
            },
        },
    };
</script>

<style scoped>
    .merge-management >>> .box {
        @apply m-0;
    }
</style>
