<template>
    <div>
        <activix-tooltip :content="tooltip" v-if="!active">
            <button
                class="flex items-center space-x-1.5 text-lg text-gray-650 font-semibold uppercase tracking-wide whitespace-nowrap"
                :disabled="disabled"
                @click="modals.confirmChange.opened = true"
            >
                <span v-text="label" />
                <icon class="text-xs" name="bold/arrow-down-1" />
            </button>
        </activix-tooltip>

        <activix-select
            height-class-name="max-h-80"
            :options="typeOptions"
            :same-width="false"
            :value="value"
            ref="select"
            v-else
        >
            <template #trigger>
                <button class="flex items-center gap-1.5 text-lg text-gray-650 font-semibold uppercase tracking-wide whitespace-nowrap">
                    {{ label }}
                    <icon class="text-xs" name="bold/arrow-down-1" />
                </button>
            </template>

            <template #default="{ options }">
                <div class="flex flex-col -my-1">
                    <button
                        class="group flex gap-1.5 items-center px-3 py-1.5 select-none transition-colors"
                        :class="{
                            'text-gray-400 italic': option.disabled,
                            'text-gray-600 hover:bg-gray-100 hover:text-gray-800': !option.disabled,
                        }"
                        :key="option.value"
                        @click="onSelect(option.value)"
                        v-for="option in options"
                    >
                        <icon :name="getIcon(option.value)" />
                        <span v-text="option.label" />
                        <span class="w-6 flex-1 text-right">
                            <icon class="text-sm text-blue-500" name="bold/check-1" v-if="option.value === value" />
                        </span>
                    </button>
                </div>
            </template>
        </activix-select>

        <activix-confirm-modal
            :content="$t('clientCard.sureChangeLeadType')"
            :opened.sync="modals.confirmChange.opened"
            @approve="active = true"
        />

        <activix-confirm-modal
            approve-text="Ok"
            type="info"
            :content="modals.validation.content"
            :opened.sync="modals.validation.opened"
            :show-deny="false"
        />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import { ActivixSelect } from '@activix/ui';

    import CreatedMethod from '@/entities/CreatedMethod.js';
    import Lead from '@/entities/Lead';
    import LeadType from '@/entities/LeadType';
    import LeadVehicleModality from '@/entities/LeadVehicleModality.js';

    export default {
        components: {
            ActivixSelect,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        data() {
            return {
                active: false,
                modals: {
                    confirmChange: {
                        opened: false,
                    },
                    validation: {
                        opened: false,
                        content: '',
                    },
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs', 'guest']),

            disabled() {
                if (this.authUser.isAdmin()) {
                    return false;
                }

                return this.guest || this.lead.isOfType(LeadType.EVENT) || this.isLeadWebByScraper;
            },

            hasAccessToNioText() {
                return this.lead.account.hasNioText() &&
                    (this.authUser.hasNioText() || this.authUser.isAdmin());
            },

            isLeadWebByScraper() {
                return this.lead.isOfType(LeadType.EMAIL) && this.lead.created_method === CreatedMethod.SCRAPER;
            },

            label() {
                const leadTypeId = this.lead.lead_type_id ? parseInt(this.lead.lead_type_id, 10) : 0;

                return leadTypeId ? LeadType.getTranslation(leadTypeId) : '';
            },

            tooltip() {
                if (this.isLeadWebByScraper && !this.authUser.isAdmin()) {
                    return this.$t('clientCard.integrationLeadBlockedTooltip');
                }

                return '';
            },

            typeOptions() {
                const leadTypes = this.configs.leadTypes
                    .filter(type => {
                        if (type.id === LeadType.EVENT && this.value !== LeadType.EVENT) {
                            return false;
                        }

                        if (type.id === LeadType.SMS && !this.hasAccessToNioText) {
                            return false;
                        }

                        if ([LeadType.DMS, LeadType.PRE_BOOKING].includes(type.id) && !this.lead.isService()) {
                            return false;
                        }

                        if (this.authUser.isAdmin()) {
                            return true;
                        }

                        if (![LeadType.LOYALTY, LeadType.RENEWAL, LeadType.WEB_ORDER].includes(type.id)) {
                            return true;
                        }

                        return (
                            (type.id === LeadType.WEB_ORDER && this.authUser.module_access.web_order) ||
                            (type.id === LeadType.LOYALTY && this.authUser.module_access.loyalty) ||
                            (type.id === LeadType.RENEWAL && this.authUser.module_access.renewal)
                        );
                    })
                    .map(type => ({
                        value: type.id,
                        label: LeadType.getTranslation(type.id),
                    }));

                return leadTypes.sort((a, b) => a.label.localeCompare(b.label));
            },

            value() {
                return this.lead.lead_type_id ? parseInt(this.lead.lead_type_id, 10) : 0;
            },
        },

        watch: {
            'lead.id'() {
                this.active = false;
            },
        },

        methods: {
            getIcon(leadTypeId) {
                return LeadType.getIcon(leadTypeId);
            },

            onSelect(newType) {
                this.$refs.select.close();

                if (!this.validate(newType)) {
                    return;
                }

                this.updateLead({ lead_type_id: newType });
            },

            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },

            validate(newType) {
                if (newType === this.lead.lead_type_id) {
                    return true;
                }

                if (
                    [LeadType.LOYALTY, LeadType.RENEWAL, LeadType.WEB_ORDER].includes(newType) &&
                    this.lead.isService()
                ) {
                    this.modals.validation.content = this.$t('clientCard.invalidLeadTypeForServiceLead');
                    this.modals.validation.opened = true;

                    return false;
                }

                // If the type renewal validate that it has a current vehicle with an end contract date or a cash modality
                if (newType === LeadType.RENEWAL) {
                    if (!this.lead.exchange_vehicles.length) {
                        this.modals.validation.content = this.$t('clientCard.needOneCurrentVehicle');
                        this.modals.validation.opened = true;

                        return false;
                    }

                    const hasExchangeVehicleWithoutEndContractDate = this.lead.exchange_vehicles.some(
                        vehicle => vehicle.modality !== LeadVehicleModality.CASH && !vehicle.end_contract_date,
                    );

                    if (hasExchangeVehicleWithoutEndContractDate) {
                        this.modals.validation.content = this.$t('clientCard.endContractRequired');
                        this.modals.validation.opened = true;

                        return false;
                    }
                }

                return true;
            },
        },
    };
</script>
