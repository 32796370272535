var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.contextLead.created_at)?_c('div',{staticClass:"flex items-end"},[_c('div',{staticClass:"w-full | md:w-auto"},[(_vm.arrowsAreVisible)?_c('div',{staticClass:"flex justify-end mb-1 -mt-2"},[_c('router-link',{staticClass:"text-sm",class:!_vm.previousLead.id ? 'disabled text-grey-500' : 'text-grey-700',attrs:{"replace":true,"to":_vm.previousLead.link}},[_c('icon',{attrs:{"name":"regular/arrow-circle-left"}})],1),_c('router-link',{staticClass:"text-sm ml-1",class:!_vm.nextLead.id ? 'disabled text-grey-500' : 'text-grey-700',attrs:{"replace":true,"to":_vm.nextLead.link}},[_c('icon',{attrs:{"name":"regular/arrow-circle-right"}})],1)],1):_vm._e(),_c('lead-input-wrapper',{attrs:{"with-padding":false}},[_c('lead-input-group',[_c('lead-input-label',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('clientCard.createdAt'))+" ")]),_c('lead-input-value',{staticClass:"md:text-left",attrs:{"collapsing":false}},[_c('activix-tooltip',{attrs:{"content":_vm.getTime(_vm.contextLead.created_at)}},[_c('span',[_vm._v(_vm._s(_vm.getDate(_vm.contextLead.created_at)))])])],1)],1),_c('lead-input-group',[_c('lead-input-label',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('clientCard.updatedAt'))+" ")]),_c('lead-input-value',{staticClass:"md:text-left",attrs:{"collapsing":false}},[_c('activix-tooltip',{attrs:{"content":_vm.getTime(_vm.contextLead.updated_at)}},[_c('span',[_vm._v(_vm._s(_vm.getDate(_vm.contextLead.updated_at)))])])],1)],1),(_vm.optOutIsVisible)?_c('lead-input-group',[_c('lead-input-label',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('clientCard.subscribed'))+" ")]),_c('lead-input-value',{staticClass:"md:text-right",attrs:{"collapsing":false}},[_c('div',{staticClass:"input-wrapper | items-center"},[_c('activix-tooltip',{attrs:{"content":_vm.unsubscribePhoneTooltip}},[_c('icon',{class:[
                                    _vm.isUnsubscribePhone ? 'link-blue' : 'link-grey-light',
                                    { 'cursor-not-allowed': _vm.unsubscribeDisabled } ],attrs:{"name":_vm.$icons.phone},on:{"click":function($event){return _vm.toggleUnsubscribe('call')}}})],1),_c('activix-tooltip',{attrs:{"content":_vm.unsubscribeEmailTooltip}},[_c('icon',{staticClass:"ml-2",class:[
                                    _vm.isUnsubscribeEmail ? 'link-blue' : 'link-grey-light',
                                    { 'cursor-not-allowed': _vm.unsubscribeDisabled } ],attrs:{"name":_vm.$icons.email},on:{"click":function($event){return _vm.toggleUnsubscribe('email')}}})],1),(_vm.contextLead.account.hasNioText())?_c('activix-tooltip',{attrs:{"content":_vm.unsubscribeSmsTooltip}},[_c('icon',{staticClass:"ml-2",class:[
                                    _vm.isUnsubscribeSms ? 'link-blue' : 'link-grey-light',
                                    { 'cursor-not-allowed': _vm.unsubscribeDisabled } ],attrs:{"name":_vm.$icons.sms},on:{"click":function($event){return _vm.toggleUnsubscribe('sms')}}})],1):_vm._e(),_c('activix-tooltip',{attrs:{"content":_vm.unsubscribeAllTooltip}},[_c('icon',{staticClass:"ml-2",class:[
                                    _vm.isUnsubscribeAll ? 'link-blue' : 'link-grey-light',
                                    { 'cursor-not-allowed': _vm.unsubscribeAllDisabled } ],attrs:{"name":"regular/delete"},on:{"click":_vm.toggleUnsubscribeAll}})],1)],1)])],1):_vm._e(),(_vm.$feature.isEnabled('casl_compliance'))?_c('lead-input-group',[_c('lead-input-label',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('consent.consent'))+" ")]),_c('lead-input-value',{attrs:{"collapsing":false}},[_c('div',{staticClass:"md:text-left flex items-center cursor-pointer",on:{"click":_vm.openConsentModal}},[_c('consent-shield',{staticClass:"mr-2",attrs:{"lead":_vm.contextLead}}),_c('activix-tooltip',{attrs:{"content":_vm.consentLimitText}},[_c('span',[_vm._v(_vm._s(_vm.consentLimitDate))])])],1)])],1):_vm._e()],1)],1),_c('do-not-disturb',{attrs:{"unsubscribed-to-all-date":_vm.contextLead.unsubscribe_all_date,"opened":_vm.doNotDisturbModalOpened},on:{"update:opened":function($event){_vm.doNotDisturbModalOpened=$event},"update":_vm.updateLead}}),_c('activix-confirm-modal',{attrs:{"content":_vm.unsubscribeModalContent,"opened":_vm.unsubscribeConfirmOpened,"title":_vm.$t('general.areYouSure')},on:{"update:opened":function($event){_vm.unsubscribeConfirmOpened=$event},"approve":_vm.triggerUnsubscribe}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }