<template>
    <invisible-multiselect
        :options="countries"
        :value="value"
        @input="onChange"
    />
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect';

    import Lead from '@/entities/Lead';

    export default {
        components: { InvisibleMultiselect },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['configs']),

            countries() {
                return this.configs.countries.map(country => {
                    return {
                        value: country,
                        text: this.$t(`clientCard.countries.${country}`),
                    };
                });
            },

            value() {
                return this.lead.country;
            },
        },
        methods: {
            onChange(value) {
                const data = {
                    country: value,
                    postal_code: null,
                    province: null,
                };

                this.$emit('update-lead', { data });
            },
        },
    };
</script>
