<template>
    <activix-modal
        :size="isOnlyLink ? 'lg' : 'md'"
        :opened="opened"
        :no-padding="isOnlyLink"
        @close="close"
    >
        <template slot="header">
            <h4 class="modal-title" v-text="$t('clientCard.navigationHistory')" />
        </template>

        <template slot="body" class="p-0">
            <iframe :src="iframeLink" class="min-h-screen-50" v-if="isOnlyLink" />
            <div class="min-h-56 space-y-6" v-else>
                <navigation-history-item :history-item="historyItem" :key="index" v-for="(historyItem, index) in navigationHistoryFormatted" />
            </div>
        </template>

        <template slot="footer">
            <activix-button @click="close" v-text="$t('clientCard.close')" />
        </template>
    </activix-modal>
</template>

<script>
    import { mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store.js';

    import Provider from '@/entities/Provider.js';

    import NavigationHistoryItem from '../lead/NavigationHistoryItem.vue';

    export default {
        components: {
            NavigationHistoryItem,
        },

        props: {
            opened: {
                type: Boolean,
                default: false,
            },
            navigationHistory: {
                type: String,
                default: '',
            },
        },

        computed: {
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
            }),

            navigationHistoryFormatted() {
                if (!this.navigationHistory) {
                    return [];
                }

                if (this.contextLead.source.provider_id == Provider.MAGNETIS && this.navigationHistory.startsWith('http')) {
                    return [this.navigationHistory];
                }

                const lines = [];

                this.textToLines(this.navigationHistory).forEach(line => {
                    lines.push(this.formatLine(line));
                });

                return lines;
            },

            iframeLink() {
                return this.navigationHistoryFormatted[0];
            },

            isOnlyLink() {
                return this.navigationHistoryFormatted.length == 1 &&
                    typeof this.navigationHistoryFormatted[0] == 'string' &&
                    this.navigationHistoryFormatted[0].startsWith('http');
            },
        },

        methods: {
            close() {
                this.$emit('update:opened', false);
                this.$emit('close');
            },

            textToLines(text) {
                const lines = text.split(/(?=\d{2}\/\d{2}\/\d{4})/);

                return lines.filter(line => line);
            },

            formatLine(line) {
                const output = line.split(/(\d{2}\/\d{2}\/\d{4} - \d{2}:\d{2}:\d{2}) - (.*)/);

                if (output.length == 4) {
                    return {
                        date: output[1],
                        text: output[2],
                    };
                }

                return {
                    text: line,
                };
            },
        },
    };
</script>
