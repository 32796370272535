<template>
    <activix-modal
        size="lg"
        :opened="opened"
        @close="close"
        @open="onOpen"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('email.originalEmail') }}
            </h4>
        </template>

        <template slot="body">
            <div class="panel panel-default">
                <div class="panel-body">
                    <span v-html="urlify(contextLead.chat)" v-if="contextLead.chat" />
                    <span v-html="urlify(scraperDescription)" v-else-if="contextLead.scraper_description" />
                    <iframe :src="scraperUrl" ref="iframe" v-else-if="scraperUrl" />
                    <span v-html="urlify(contextLead.original_email)" v-else-if="contextLead.original_email" />
                    <span v-else>{{ $t('email.no_original_email') }}</span>
                </div>
            </div>

            <div class="panel panel-default | mt-6" v-if="scraperRawUrl && authUser.isAdmin()">
                <div class="panel-body">
                    <iframe class="min-h-screen-50" :src="scraperRawUrl" ref="rawRequest" />
                </div>
            </div>
        </template>

        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.close') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    // Utils
    import autolinker from 'autolinker';
    import { mapState } from 'pinia';
    import { nl2br } from '../../utils/string.js';

    import { useGlobalStore } from '../../store/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        props: {
            opened: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                iframes: [],
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useGlobalStore, ['authUser', 'configs']),

            requestUrl() {
                if (!this.contextLead.request) {
                    return '';
                }

                return this.contextLead.request.old_url;
            },

            scraperRawUrl() {
                if (!this.requestUrl) {
                    return '';
                }

                return `${window.location.protocol}//leadactivix.ca/scraper/files/${this.requestUrl}`;
            },

            scraperUrl() {
                if (!this.requestUrl) {
                    return '';
                }

                return `${window.location.protocol}//leadactivix.ca/scraper/show_original.php?authToken=${process.env.VUE_APP_SCRAPER_AUTH_TOKEN}&file=${this.requestUrl}`;
            },

            scraperDescription() {
                let content = nl2br(this.contextLead.scraper_description);

                if (this.authUser.block_lead_info) {
                    content = content.replace(/(\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)/, 'XXXX');
                    content = content.replace(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/, 'XXXX');
                }

                return content;
            },
        },

        methods: {
            urlify(text) {
                return autolinker.link(text);
            },

            validateEmail(email) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },

            close() {
                this.$emit('update:opened', false);
                this.$emit('close');
            },

            onOpen() {
                if (this.$refs.iframe) {
                    this.iframes.push(iFrameResize({ autoResize: false, scrolling: true }, this.$refs.iframe));
                }

                if (this.$refs.rawRequest) {
                    this.iframes.push(iFrameResize({ autoResize: false, scrolling: true }, this.$refs.rawRequest));
                }
            },

            onClosed() {
                this.iframes.forEach(iframe => {
                    iframe[0].iFrameResizer.close();
                });

                this.iframes = [];
            },
        },
    };
</script>
