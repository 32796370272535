<template>
    <activix-tooltip placement="bottom" :content="tooltip" :disabled="isCurrentLead">
        <router-link
            class="tab"
            :class="{
                active: isCurrentLead,
                faded: isFaded,
            }"
            :replace="true"
            :to="{ name: 'leads.update', params: { id: lead.id } }"
        >
            <div class="tab-content" @mouseenter="$emit('mouseenter')">
                <div :class="iconColor">
                    <icon class="text-3xl" :class="{ 'flip-x': isService }" :name="icon" />
                </div>
                <div class="ml-4 text-grey-650">
                    <div class="font-bold flex items-center">
                        <div class="truncate max-w-36 mr-1" v-if="isCustomAccount">
                            {{ lead.fullName }}
                        </div>
                        <template v-else-if="vehicleMakeAndModel">
                            <div class="truncate max-w-36 mr-1">
                                {{ vehicleMakeAndModel }}
                            </div>
                            <div>
                                {{ vehicle.year }}
                                <span class="font-normal" v-if="vehiclesCount > 1">({{ vehiclesCount }})</span>
                            </div>
                        </template>
                        <template v-else>
                            {{ $t('clientCard.noMakeModel') }}
                        </template>
                    </div>
                    <div class="flex items-center">
                        {{ date }}
                        <template v-if="leadIsRenewal">
                            *
                        </template>
                        <process-progress :lead="lead" />
                    </div>
                </div>
                <div
                    class="ml-4 text-grey-500 hover:text-grey-600"
                    @click.prevent="$emit('delete')"
                    v-if="allowDeleteLead"
                >
                    <icon class="text-2xs" name="bold/remove" />
                </div>
            </div>
        </router-link>
    </activix-tooltip>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import AccountType from '@/entities/AccountType.js';
    import { mapState } from 'pinia';

    import Division from '../../entities/Division.js';
    import LeadStatus from '../../entities/LeadStatus.js';
    import LeadType from '../../entities/LeadType.js';
    import ProcessProgress from './ProcessProgress.vue';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import Supplier from '../../entities/Supplier.js';

    export default {
        components: { ProcessProgress },

        props: ['lead'],

        data() {
            return {
                deleteModal: {
                    opened: false,
                    lead: null,
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),

            allowDeleteLead() {
                return this.authUser.isAdmin() || this.authUser.custom_permissions.delete_lead;
            },

            isFaded() {
                return [LeadStatus.DUPLICATE, LeadStatus.INVALID].includes(this.lead.status);
            },

            isService() {
                return this.lead.division_id == Division.SERVICE;
            },

            icon() {
                return this.getLeadTypeIcon(this.lead);
            },

            iconColor() {
                switch (this.lead.status) {
                    case LeadStatus.DUPLICATE:
                        return 'text-grey-600';

                    case LeadStatus.INVALID:
                        return 'text-orange-500';

                    case LeadStatus.LOST:
                        return 'text-red-500';

                    default:
                        return 'text-green-500';
                }
            },

            civility() {
                switch (this.lead.civility) {
                    case 'mr':
                        return this.$t('database.mr');

                    case 'ms':
                        return this.$t('database.ms');

                    case 'miss':
                        return this.$t('database.miss');

                    case 'dr':
                        return this.$t('database.dr');

                    case 'me':
                        return this.$t('database.me');

                    default:
                        return this.lead.civility;
                }
            },

            tooltip() {
                let tooltip = `
                    <div>
                        <span class="font-semibold">${this.$t('clientCard.client')}</span> :
                        ${this.civility || ''} ${this.lead.fullName || this.$t('clientCard.unknown')}
                    </div>
                `;

                if (this.lead.user.fullName) {
                    tooltip += `
                        <div>
                            <span class="font-semibold">${this.$t('clientCard.advisor')}</span> :
                            ${this.lead.user.fullName}
                        </div>
                    `;
                }

                if (this.lead.bdc_user.fullName) {
                    tooltip += `
                        <div>
                            <span class="font-semibold">${this.$t('clientCard.bdcAgent')}</span> :
                            ${this.lead.bdc_user.fullName}
                        </div>
                    `;
                }

                tooltip += `
                    <div>
                        <span class="font-semibold">${this.$t('clientCard.type')}</span> :
                        ${this.typeName}
                    </div>
                `;

                tooltip += `
                    <div>
                        <span class="font-semibold">${this.$t('clientCard.status')}</span> :
                        ${this.$t(`result.${this.lead.status || 'active'}`)}
                    </div>
                `;

                if (this.performanceTooltip) {
                    tooltip += `
                        <div>
                            <span class="font-semibold">${this.$t('clientCard.performance')}</span> :
                            ${this.performanceTooltip}
                        </div>
                    `;
                }

                if (this.lead.lead_type_id == LeadType.RENEWAL) {
                    tooltip += `
                        <div class="mt-2 italic">
                            * ${this.$t('date.endContractDate')}
                        </div>
                    `;
                }

                return `<div class="text-left">${tooltip}</div>`;
            },

            performanceTooltip() {
                if (this.lead.sale_date) {
                    if (this.lead.isOfType(LeadType.RENEWAL)) {
                        return this.$t('result.renewed');
                    }

                    return this.soldLabel;
                }

                if (this.lead.presented_date) {
                    return this.$t('result.presented');
                }

                if (this.lead.appointment_date) {
                    return this.$t('result.appointment');
                }

                return '';
            },

            soldLabel() {
                return this.contextAccount.hasSupplier(Supplier.POLESTAR) ? this.$tc('clientCard.order') : this.$t('result.sale');
            },

            date() {
                let date = null;

                if (this.lead.division_id == Division.SERVICE) {
                    date = this.lead.appointment_date;
                } else {
                    switch (this.lead.lead_type_id) {
                        case LeadType.PHONE:
                            date = this.lead.call_date;
                            break;
                        case LeadType.WALK_IN:
                            date = this.lead.presented_date;
                            break;
                        case LeadType.RENEWAL:
                            date = this.lead.end_contract_date;
                            break;
                    }
                }

                date = locale_dt(date || this.lead.created_at);

                return date.humanShort(true);
            },

            vehicle() {
                if (this.isService || this.lead.isOfType(LeadType.RENEWAL)) {
                    return this.lead.exchange_vehicles[0] || {};
                }

                return this.lead.getSoldOrFirstWantedVehicle() || {};
            },

            vehicleMakeAndModel() {
                if (!this.vehicle) {
                    return '';
                }

                return `${this.vehicle.make || ''} ${this.vehicle.model || ''}`.trim();
            },

            isCustomAccount() {
                return this.contextAccount.type === AccountType.CUSTOM;
            },

            vehicleIsSold() {
                return this.vehicle.sold && !!this.lead.sale_date;
            },

            vehicleIsRenewed() {
                return this.lead.isOfType(LeadType.RENEWAL) && this.vehicleIsSold;
            },

            vehicleIsRefinanced() {
                return this.lead.isOfType(LeadType.RENEWAL) && this.vehicle.sold && !!this.lead.refinanced_date;
            },

            vehicleIsDelivered() {
                return this.lead.delivered_date && this.vehicle.sold;
            },

            vehiclesCount() {
                if (this.isService || this.lead.isOfType(LeadType.RENEWAL)) {
                    return this.lead.exchange_vehicles.length;
                }

                return this.lead.wanted_vehicles.length;
            },

            typeName() {
                switch (this.lead.lead_type_id) {
                    case LeadType.DMS:
                        return this.$t('dashboards.dms');
                    case LeadType.EMAIL:
                        return this.$t('dashboards.webLead');
                    case LeadType.PHONE:
                        return this.$t('dashboards.phoneUp');
                    case LeadType.WALK_IN:
                        return this.$t('dashboards.walkIn');
                    case LeadType.LOYALTY:
                        return this.$t('dashboards.loyalty');
                    case LeadType.RENEWAL:
                        return this.$t('dashboards.renewalAbr');
                    case LeadType.SMS:
                        return this.$t('dashboards.sms');
                    case LeadType.EVENT:
                        return this.$t('dashboards.event');
                    case LeadType.PRE_BOOKING:
                        return this.$t('dashboards.prebooking');
                    case LeadType.WEB_ORDER:
                        return this.$t('dashboards.webOrder');
                }

                return '';
            },

            isCurrentLead() {
                return this.contextLead.id == this.lead.id;
            },

            leadIsRenewal() {
                return this.lead.lead_type_id == LeadType.RENEWAL;
            },
        },
    };
</script>
