<template>
    <div class="relative">
        <div class="relative z-10">
            <template v-if="communication.isPhone">
                <communication-audio-icon
                    :communication="communication"
                    :lead="lead"
                />
            </template>

            <template v-else-if="communication.isVideo">
                <communication-video-icon
                    :communication="communication"
                />
            </template>

            <template v-else-if="communication.isEmail">
                <communication-email-icon :communication="communication" :planned="planned" />
            </template>

            <template v-else-if="communication.isSms">
                <communication-sms-icon :communication="communication" :planned="planned" />
            </template>

            <template v-else-if="communication.isMessenger">
                <communication-messenger-icon :communication="communication" />
            </template>
        </div>

        <template v-if="showAnchorLine">
            <div class="absolute inset-0 z-10 flex justify-center w-full h-full mt-12 pointer-events-none" v-if="planned">
                <span class="w-0 border-l-2 border-dashed border-gray-300" />
            </div>
            <div class="absolute inset-0 z-10 flex justify-center w-full h-full mt-12 pointer-events-none" v-else>
                <span class="w-2px bg-gray-300" />
            </div>
        </template>
    </div>
</template>

<script>
    import CommunicationAudioIcon from '@/components/container/lead/communications/CommunicationAudioIcon';
    import CommunicationEmailIcon from '@/components/container/lead/communications/CommunicationEmailIcon';
    import CommunicationMessengerIcon from '@/components/container/lead/communications/CommunicationMessengerIcon';
    import CommunicationSmsIcon from '@/components/container/lead/communications/CommunicationSmsIcon';
    import CommunicationVideoIcon from '@/components/container/lead/communications/CommunicationVideoIcon';

    import Communication from '@/entities/Communication';
    import Lead from '@/entities/Lead';

    export default {
        components: {
            CommunicationAudioIcon,
            CommunicationEmailIcon,
            CommunicationMessengerIcon,
            CommunicationSmsIcon,
            CommunicationVideoIcon,
        },

        props: {
            communication: {
                required: true,
                type: Communication,
            },
            lead: {
                required: true,
                type: Lead,
            },
            planned: {
                default: false,
                type: Boolean,
            },
            showAnchorLine: {
                required: true,
                type: Boolean,
            },
        },
    };
</script>
