<template>
    <div class="input-wrapper" :class="{ disabled: disabled, 'pr-8': lgLayout && centerIcon }">
        <div
            class="input-container"
            @keyup.enter="onEnter"
            @keyup.esc="onCancel"
            v-on-clickaway="onBlur"
            v-if="editing"
        >
            <slot />

            <activix-button
                class="ml-2 shadow h-8 w-9 p-0 flex-shrink-0"
                size="small"
                type="primary"
                @click="onAccept"
            >
                <icon name="bold/check-1" class="text-xs" />
            </activix-button>
            <activix-button class="ml-2 shadow h-8 w-9 p-0 flex-shrink-0" size="small" @click="onCancel">
                <icon name="bold/close" class="text-xs text-grey-650" />
            </activix-button>
        </div>

        <div
            class="link-grey-dark | flex flex-grow truncate items-center group"
            :class="{ disabled: disabled }"
            v-else
        >
            <div class="flex-grow truncate" @click="!valid ? onEdit() : false">
                <slot name="value" />
            </div>
            <activix-tooltip :content="$t('general.edit')">
                <activix-button
                    class="ml-2 shadow h-7 w-8 my-1 p-0 flex-shrink-0 opacity-0 | group-hover:opacity-100"
                    size="small"
                    @click="onEdit"
                >
                    <icon name="regular/pencil-1" class="text-xs" />
                </activix-button>
            </activix-tooltip>
            <activix-tooltip :content="$t('general.copyToClipboard')">
                <activix-button
                    class="ml-2 shadow h-7 w-8 my-1 p-0 flex-shrink-0 opacity-0 | group-hover:opacity-100"
                    size="small"
                    @click="onCopyUrl"
                >
                    <icon name="regular/common-file-double-1" class="text-xs text-grey-650" />
                </activix-button>
            </activix-tooltip>
        </div>

        <slot name="right" />
    </div>
</template>

<script>
    export default {
        props: {
            centerIcon: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            editing: {
                type: Boolean,
                default: false,
            },
            valid: {
                type: Boolean,
                default: true,
            },
            handleEnter: {
                type: Boolean,
                default: true,
            },
            showButtons: {
                type: Boolean,
                default: true,
            },
        },

        methods: {
            onEnter() {
                if (this.handleEnter) {
                    this.$emit('accept');
                }
            },

            onAccept() {
                this.$emit('accept');
            },

            onCancel() {
                this.$emit('cancel');
            },

            onEdit() {
                this.$emit('edit');
            },

            onBlur() {
                this.$emit('blur');
            },
            onCopyUrl() {
                this.$emit('copy');
            },
        },
    };
</script>
