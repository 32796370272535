import Enum from './Enum.js';

export default class CommunicationPreference extends Enum {
    static get entries() {
        return {
            email: 'email',
            phone: 'phone',
            sms: 'sms',
        };
    }

    static get EMAIL() {
        return this.entries.email;
    }

    static get PHONE() {
        return this.entries.phone;
    }

    static get SMS() {
        return this.entries.sms;
    }

    static get translationGroup() {
        return 'communicationPreferences';
    }
}
