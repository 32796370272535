<template>
    <button
        class="flex justify-between items-center gap-1.5 p-3 rounded-md text-lg bg-white bg-opacity-100 | transition hover:shadow-md hover:bg-opacity-100"
        :class="{ 'shadow-md': selected }"
        @click="$emit('feature-selected')"
    >
        <span class="flex items-center overflow-hidden">
            <activix-tooltip :content="iconTooltip">
                <icon
                    class="flex-shrink-0 mr-1.5"
                    :class="{ 'text-blue-500': featurePreview.isActive }"
                    name="regular/lab-flask-experiment"
                />
            </activix-tooltip>
            <span class="truncate" v-text="title" />
        </span>

        <feature-preview-new-tag v-if="featurePreview.isNew" />
    </button>
</template>

<script>
    import FeaturePreviewNewTag from '@/components/presentational/featurePreview/FeaturePreviewNewTag';

    import ActivixDate from '@/value-objects/ActivixDate';
    import FeaturePreview from '@/entities/FeaturePreview.js';

    export default {
        components: { FeaturePreviewNewTag },

        props: {
            featurePreview: {
                type: FeaturePreview,
                required: true,
            },
            selected: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            iconTooltip() {
                if (this.featurePreview.isActive) {
                    const activatedAtDelay = new ActivixDate(this.featurePreview.activated_at).formatDistanceToNow();

                    return [
                        this.$t('featurePreview.activated'),
                        activatedAtDelay,
                    ].join(' ');
                }

                return '';
            },

            title() {
                const locale = this.$i18n.locale;
                return this.featurePreview.title[locale];
            },
        },
    };
</script>
