<template>
    <div>
        <section class="relative grid grid-cols-2 items-start justify-around gap-6 p-6 border-b border-gray-200 bg-gray-100 | md:grid-cols-3 md:p-12 xl:p-6 2xl:p-12">
            <div class="text-center">
                <label class="mb-0 text-gray-600 tracking-wider uppercase text-sm">
                    {{ $t('commercial.totalProfit') }}
                </label>
                <samp class="block text-2xl text-gray-700 tabular-nums">
                    <template v-if="$i18n.locale !== 'fr'">$ </template>
                    {{ totalPriceRounded }}<span class="mx-1 text-base align-top">{{ totalPriceDecimal }}</span>
                    <template v-if="$i18n.locale === 'fr'"> $</template>

                </samp>
            </div>

            <div class="text-center">
                <label class="mb-0 text-gray-600 tracking-wider uppercase text-sm">
                    {{ $t('clientCard.boxes.commercialProducts.soldProductsCount') }}
                </label>
                <samp class="block text-2xl text-gray-700 tabular-nums">
                    {{ soldProductsCount }}
                </samp>
            </div>

            <div class="col-span-full text-center | md:col-span-1">
                <label class="mb-0 text-gray-600 tracking-wider uppercase text-sm">
                    {{ $t('clientCard.boxes.commercialProducts.specifications') }}
                </label>
                <samp class="block font-sans text-gray-700 tabular-nums">
                    {{ commercialMeetingText }}
                </samp>
                <samp class="block font-sans text-gray-700 tabular-nums">
                    {{ commercialNoNeedText }}
                </samp>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import { extractDecimals, toLocaleNumber } from '@/utils/numbers.js';

    import Lead from '@/entities/Lead';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            actionDisabled() {
                return this.guest || this.authUser.isAdvisor() || this.authUser.isBdc();
            },

            commercialMeetingText() {
                if (this.lead.commercial_meeting) {
                    return this.$t('clientCard.boxes.commercialProducts.clientMet');
                }

                return this.$t('clientCard.boxes.commercialProducts.clientNotMet');
            },

            commercialNoNeedText() {
                if (this.lead.commercial_no_need) {
                    return this.$t('clientCard.boxes.commercialProducts.included');
                }

                return this.$t('clientCard.boxes.commercialProducts.notIncluded');
            },

            commercialProducts() {
                return this.lead.products.filter(product => product.type === 'commercial');
            },

            soldProductsCount() {
                return this.commercialProducts.filter(product => product.pivot.sold).length;
            },

            totalPrice() {
                let total = 0;

                for (const product of this.commercialProducts) {
                    if (empty(product.pivot.price) || !product.pivot.sold) {
                        continue;
                    }

                    total += parseFloat(product.pivot.price);
                }
                return total;
            },

            totalPriceDecimal() {
                return extractDecimals(this.totalPrice);
            },

            totalPriceRounded() {
                return toLocaleNumber(Math.floor(this.totalPrice));
            },
        },
    };
</script>
