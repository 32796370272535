<template>
    <div>
        <div class="box | mb-6">
            <div class="box-header">
                <h4 class="box-title" v-if="contextLead.isService()">
                    {{ $t('clientCard.revivalService') }}
                </h4>
                <h4 class="box-title" v-else>
                    {{ $t('clientCard.followUp') }}
                </h4>
            </div>
            <div class="box-body | pt-0" :class="{ 'pb-2': mdLayout }">
                <div class="flex flex-wrap -mx-3">
                    <lead-input-wrapper class="w-full xs:w-1/2">
                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('clientCard.lastPresentedDate') }}
                            </lead-input-label>
                            <lead-input-value>
                                {{ lastPresentedDateString }}
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('clientCard.serviceMonthlyKm') }}
                            </lead-input-label>
                            <lead-input-value>
                                <input-text
                                    name="service_monthly_km"
                                    type="kilometers"
                                    :record-id="contextLead.id"
                                    :value="contextLead.service_monthly_km"
                                    :disabled="guest"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.isService()">
                            <lead-input-label>
                                {{ $t('clientCard.nextPresentedDate') }}
                            </lead-input-label>
                            <lead-input-value>
                                <date-time-picker
                                    name="next_presented_date"
                                    :disabled="guest"
                                    :enable-empty="true"
                                    :record-id="contextLead.id"
                                    :value="contextLead.next_presented_date"
                                />
                            </lead-input-value>
                        </lead-input-group>
                    </lead-input-wrapper>
                    <lead-input-wrapper class="w-full xs:w-1/2">
                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('clientCard.odometerLastVisit') }}
                            </lead-input-label>
                            <lead-input-value>
                                <input-text
                                    name="odometer_last_visit"
                                    type="kilometers"
                                    :record-id="contextLead.id"
                                    :value="contextLead.odometer_last_visit"
                                    :disabled="guest"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group>
                            <lead-input-label>
                                {{ $t('clientCard.serviceIntervalKm') }}
                            </lead-input-label>
                            <lead-input-value>
                                <select-picker
                                    name="service_interval_km"
                                    :disabled="guest"
                                    :options="intervals"
                                    :record-id="contextLead.id"
                                    :value="contextLead.service_interval_km"
                                />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.isService()">
                            <lead-input-label>
                                {{ $t('clientCard.code') }}
                            </lead-input-label>
                            <lead-input-value>
                                <input-text
                                    name="code"
                                    :record-id="contextLead.id"
                                    :value="contextLead.code"
                                    :disabled="guest"
                                />
                            </lead-input-value>
                        </lead-input-group>
                    </lead-input-wrapper>
                </div>
            </div>
        </div>

        <activix-confirm-modal
            :content="updateNextPresentedDateModal.content"
            :opened.sync="updateNextPresentedDateModal.opened"
            :title="$t('clientCard.nextPresentedDate')"
            @approve="triggerUpdateNextPresentedDate(updateNextPresentedDateModal.nextPresentedDate)"
            @closed="clearNextPresentedDateModal"
        />
    </div>
</template>

<script>
    // Utils
    import { get, floor } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';

    // Components
    import DateTimePicker from './inputs/DateTimePicker.vue';
    import InputText from './inputs/InputText.vue';
    import LeadInputGroup from './LeadInputGroup.vue';
    import LeadInputLabel from './LeadInputLabel.vue';
    import LeadInputValue from './LeadInputValue.vue';
    import LeadInputWrapper from './LeadInputWrapper.vue';
    import SelectPicker from './inputs/SelectPicker.vue';

    // Entities
    import TaskEventType from '../../entities/TaskEventType.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useClientCardStore } from '../../store/modules/clientCard/store.js';

    export default {
        components: {
            DateTimePicker,
            InputText,
            LeadInputGroup,
            LeadInputLabel,
            LeadInputValue,
            LeadInputWrapper,
            SelectPicker,
        },

        data() {
            return {
                leadTypeEditable: false,
                divisionEditable: false,
                intervals: [
                    { value: '1000', text: '1,000 KM' },
                    { value: '5000', text: '5,000 KM' },
                    { value: '6000', text: '6,000 KM' },
                    { value: '8000', text: '8,000 KM' },
                    { value: '12000', text: '12,000 KM' },
                    { value: '16000', text: '16,000 KM' },
                    { value: '18000', text: '18,000 KM' },
                    { value: '20000', text: '20,000 KM' },
                    { value: '24000', text: '24,000 KM' },
                    { value: '25000', text: '25,000 KM' },
                ],
                selectServiceVehicleModalOpened: false,
                updateNextPresentedDateModal: {
                    content: '',
                    nextPresentedDate: null,
                    opened: false,
                },
                oldContextLeadId: null,
            };
        },

        computed: {
            ...mapState(useClientCardStore, ['authorizedAction']),
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),
            ...mapState(useGlobalStore, ['authUser', 'guest', 'configs']),

            canSetNextVisitDate() {
                return (
                    this.contextLead.service_interval_km &&
                    this.contextLead.service_monthly_km &&
                    this.lastPresentedDate
                );
            },

            isServiceDivision() {
                return this.leadIsServiceDivision(this.contextLead);
            },

            lastPresentedDate() {
                return get(this.contextLead, 'customer.last_presented_date') || this.contextLead.presented_date;
            },

            lastPresentedDateString() {
                return as_locale(this.lastPresentedDate, 'presented_date').humanShort(true) || '...';
            },
        },

        watch: {
            lastPresentedDate(newValue, oldValue) {
                if (this.canSetNextVisitDate && oldValue != newValue) {
                    this.onUpdateNextPresentedDate();
                }
            },

            'contextLead.id': {
                immediate: true,
                handler(newContextLeadId, oldContextLeadId) {
                    this.oldContextLeadId = oldContextLeadId;
                },
            },

            'contextLead.service_interval_km'(newValue, oldValue) {
                if (this.canSetNextVisitDate && oldValue != newValue) {
                    this.onUpdateNextPresentedDate();
                }
            },

            'contextLead.service_monthly_km'(newValue, oldValue) {
                if (this.canSetNextVisitDate && oldValue != newValue) {
                    this.onUpdateNextPresentedDate();
                }
            },

            'contextLead.next_presented_date'(newValue, oldValue) {
                if (
                    (typeof this.oldContextLeadId !== 'undefined' && this.contextLead.id != this.oldContextLeadId) ||
                    !newValue ||
                    locale_dt(oldValue).isSame(locale_dt(newValue))
                ) {
                    this.oldContextLeadId = this.contextLead.id;
                    return;
                }

                this.$eventBus.$emit('open-add-task-event', {
                    leadId: this.contextLead.id,
                    type: TaskEventType.CALL,
                    startAt: newValue,
                });
            },

            contextLead: {
                handler(newValue) {
                    // Reset default values
                    this.leadTypeEditable = false;

                    if (
                        this.leadHasSoldServiceProducts(newValue) ||
                        (this.leadIsPrebookingType(newValue) && this.leadIsServiceDivision(newValue))
                    ) {
                        this.divisionEditable = false;
                    } else {
                        this.divisionEditable = true;
                    }
                },
                deep: true,
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            onUpdateNextPresentedDate() {
                const currentNextPresentedDate = as_locale(this.contextLead.next_presented_date, 'next_presented_date');
                const nextPresentedDate = as_locale(this.lastPresentedDate, 'last_presented_date');
                const numberOfMonth = this.contextLead.service_interval_km / this.contextLead.service_monthly_km;

                if (numberOfMonth % 1 != 0) {
                    const start = nextPresentedDate.clone();
                    const end = start.clone().addMonths(floor(numberOfMonth));
                    const daysInBetween = end.diffInDays(start);
                    const numberOfDaysInLastMonth = end.daysInMonth();
                    const numberOfDaysToAdd = daysInBetween + floor(numberOfDaysInLastMonth * (numberOfMonth % 1));

                    nextPresentedDate.addDays(numberOfDaysToAdd);
                } else {
                    nextPresentedDate.addMonths(numberOfMonth);
                }

                if (currentNextPresentedDate.isValid() && !nextPresentedDate.isSame(currentNextPresentedDate)) {
                    this.updateNextPresentedDateModal.nextPresentedDate = nextPresentedDate;
                    this.updateNextPresentedDateModal.content = this.$t('clientCard.setNextPresentedDate', [
                        nextPresentedDate.humanShort(true),
                        currentNextPresentedDate.humanShort(true),
                    ]);
                    this.updateNextPresentedDateModal.opened = true;
                } else {
                    this.triggerUpdateNextPresentedDate(nextPresentedDate);
                }
            },

            triggerUpdateNextPresentedDate(nextPresentedDate) {
                this.updateLeadAction(this.contextLead.id, { next_presented_date: nextPresentedDate.toString() });
            },

            clearNextPresentedDateModal() {
                this.updateNextPresentedDateModal.content = '';
                this.updateNextPresentedDateModal.nextPresentedDate = null;
            },

            leadIsPrebookingType(lead) {
                return lead.lead_type_id == 8;
            },

            leadIsServiceDivision(lead) {
                return lead.division_id == 3;
            },

            leadHasSoldServiceProducts(lead) {
                return lead.products.some(product => product.pivot.sold && product.type == 'service');
            },
        },
    };
</script>
