<template>
    <box>
        <box-header slot="header">
            {{ $t('clientCard.boxes.financialAndDelivery.title') }}
        </box-header>

        <template slot="content">
            <financial-and-delivery-progress-bar :lead="contextLead" />

            <div class="flex flex-grow flex-col">
                <box-content>
                    <financial-and-delivery
                        :lead="contextLead"
                        @update-lead="updateLead"
                        @update-vehicle="updateVehicle"
                    />
                </box-content>

                <template v-if="hasCustomFields">
                    <hr class="w-full h-px m-0 border-none bg-gray-200" />

                    <box-content class="flex-grow-0">
                        <financial-and-delivery-custom-fields
                            :lead="contextLead"
                            @update-lead="updateLead"
                        />
                    </box-content>
                </template>
            </div>
        </template>
    </box>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store';
    import { useGlobalStore } from '@/store/store.js';

    import Box from '@/components/presentational/boxes/Box';
    import BoxContent from '@/components/presentational/boxes/BoxContent.vue';
    import BoxHeader from '@/components/presentational/boxes/BoxHeader';
    import FinancialAndDelivery from '@/components/container/lead/FinancialAndDelivery';
    import FinancialAndDeliveryCustomFields from '@/components/container/lead/FinancialAndDeliveryCustomFields.vue';
    import FinancialAndDeliveryProgressBar from '@/components/container/lead/FinancialAndDeliveryProgressBar';

    import ClientCardSection from '@/entities/ClientCardSection';

    export default {
        components: {
            Box,
            BoxContent,
            BoxHeader,
            FinancialAndDelivery,
            FinancialAndDeliveryCustomFields,
            FinancialAndDeliveryProgressBar,
        },

        computed: {
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
            }),

            hasCustomFields() {
                return this.contextLead.account.hasSectionCustomFields(ClientCardSection.PROCESS);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'updateLeadVehicleAction']),

            updateLead({ data, options = {} }) {
                this.updateLeadAction(this.contextLead.id, data, options);
            },

            updateVehicle({ data, id = null, options = {} }) {
                const vehicleId = id || this.contextLead.getSoldOrFirstWantedVehicle().id;

                this.updateLeadVehicleAction(data, vehicleId, this.contextLead.id, options);
            },
        },
    };
</script>
