<template>
    <div class="flex flex-col flex-grow max-w-full px-6 py-3 rounded-md shadow-md bg-white">
        <input-label>
            {{ label }}
        </input-label>

        <invisible-multiselect
            :disabled="guest"
            :multiple="multiselectMultiple"
            :options="options"
            :placeholder="multiselectPlaceholder"
            :select-all="false"
            :value="multiselectValue"
            @input="$emit('fields-input', { value: $event })"
        />
        <invisible-textarea
            class="mt-2"
            :placeholder="$t('clientCard.additionalNotes')"
            :value="textinputValue"
            @input="$emit('fields-input', { comment: $event })"
        />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    import InputLabel from '@/components/presentational/input/InputLabel.vue';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect.vue';
    import InvisibleTextarea from '@/components/container/input/InvisibleTextarea.vue';

    export default {
        components: {
            InputLabel,
            InvisibleMultiselect,
            InvisibleTextarea,
        },

        props: {
            label: String,
            multiselectValue: [String, Array],
            multiselectOptions: Array,
            multiselectMultiple: {
                type: Boolean,
                default: false,
            },
            multiselectPlaceholder: {
                type: String,
                default: '---',
            },
            multiselectCustomLabels: String,
            textinputValue: String,
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            options() {
                return this.multiselectOptions.map(option => {
                    return {
                        value: option,
                        text: this.$t(`${this.multiselectCustomLabels}.${option}`),
                    };
                });
            },

            limit() {
                if (this.multiselectMultiple) {
                    return 2;
                }

                return 100;
            },
        },
    };
</script>
