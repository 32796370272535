<template>
    <flexible-input>
        <input-label slot="label" class="flex items-center" :blocked-reason="internalBlockedReason">
            {{ $t('clientCard.vehicles.make') }}
            <activix-tooltip :content="tooltipMatch" v-if="vehicle.exportSupplier">
                <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
            </activix-tooltip>
        </input-label>

        <activix-tooltip :content="vehicleValueTooltip">
            <invisible-input
                class="flex-1"
                :disabled="isDisabled"
                :value="vehicle.make"
                @input="onUpdateMake"
                v-if="account.vehicle_text"
            />
            <invisible-multiselect
                :append-missing-value="true"
                :disabled="isDisabled"
                :nullable="true"
                :options="options"
                :searchable="true"
                :value="vehicle.make"
                @input="onUpdateMake"
                v-else
            />
        </activix-tooltip>
    </flexible-input>
</template>

<script>
    import { mapState } from 'pinia';
    import { useClientCardStore } from '@/store/modules/clientCard/store.js';

    import FlexibleInput from '@/components/container/input/FlexibleInput.vue';
    import InputLabel from '@/components/presentational/input/InputLabel.vue';
    import InvisibleInput from '@/components/container/input/InvisibleInput';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect.vue';

    import Account from '@/entities/Account';
    import LeadVehicle from '@/entities/LeadVehicle.js';

    export default {
        components: {
            FlexibleInput,
            InputLabel,
            InvisibleInput,
            InvisibleMultiselect,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            stockManagementEnabled: {
                type: Boolean,
                required: true,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        computed: {
            ...mapState(useClientCardStore, ['vehicles', 'vehicleMakes']),

            hasVehicleValue() {
                return !empty(this.vehicle.vehicle_value);
            },

            internalBlockedReason() {
                if (this.blockedReason) {
                    return this.blockedReason;
                }

                if (this.vehicle.isExported) {
                    return this.$t('clientCard.blocked.vehicleExported');
                }

                if (this.hasVehicleValue) {
                    return this.$t('clientCard.blocked.vehicleAppraisalCompleted');
                }

                if (this.isLinkedToInventory) {
                    return this.$t('clientCard.blocked.vehicleStock');
                }

                return '';
            },

            isDisabled() {
                return this.disabled || this.vehicle.isExported || this.hasVehicleValue || this.isLinkedToInventory;
            },

            isLinkedToInventory() {
                return !empty(this.vehicle.inventory_unit_id) && this.stockManagementEnabled;
            },

            options() {
                if (this.account.vehicle_text) {
                    return [];
                }

                if (this.account.power_sport) {
                    return this.vehicles.map(vehicle => {
                        return {
                            value: vehicle.make,
                            text: vehicle.make,
                        };
                    });
                }

                return this.vehicleMakes.map(make => {
                    return {
                        value: make.name,
                        text: make.name,
                    };
                });
            },

            tooltipMatch() {
                const exportSupplier = this.vehicle.exportSupplier;

                if (!exportSupplier) {
                    return '';
                }

                return `${this.$t(`${exportSupplier.display_name}.tooltip.match`)}`;
            },

            vehicleValueTooltip() {
                return this.hasVehicleValue ? this.$t('clientCard.vehicleEvaluate') : '';
            },
        },

        methods: {
            onUpdateMake(make) {
                const data = {
                    make,
                };

                if (!this.account.vehicle_text) {
                    data.model = '';
                }

                this.$emit('update-vehicle', data);
            },
        },
    };
</script>
