<template>
    <box class="p-3 !rounded-md !shadow-md">
        <input-group slot="content">
            <div class="flex items-center" slot="label">
                <input-label :blocked-reason="blockedReason">
                    <span>{{ label }}</span>
                </input-label>

                <activix-tooltip
                    :content="reassignmentTooltip"
                    v-if="reassignmentAllowed"
                >
                    <div>
                        <icon
                            class="ml-2 mb-0.5"
                            :class="reassignmentValue ? 'link-orange' : 'link-grey'"
                            name="regular/human-resources-employee-previous"
                            @click="$emit('open-reassignment-user-request')"
                        />
                    </div>
                </activix-tooltip>
            </div>
            <template slot="input">
                <div class="flex-1 mt-1">
                    <activix-tooltip :content="reassignmentValueTooltip" v-if="readonly">
                        <div class="disabled opacity-50">
                            {{ reassignmentValueText }}
                        </div>
                    </activix-tooltip>

                    <activix-select
                        trigger-padding-class-name="px-2.5 py-1.5"
                        :disabled="disabled"
                        :filterable="true"
                        :nullable="true"
                        :options="options"
                        placeholder="---"
                        :value="value"
                        ref="select"
                        @input="$emit('input', $event)"
                        v-else
                    />
                </div>
            </template>
        </input-group>
    </box>
</template>

<script>
    import { ActivixSelect } from '@activix/ui';

    import Box from '@/components/presentational/boxes/Box.vue';
    import InputGroup from '@/components/presentational/input/InputGroup.vue';
    import InputLabel from '@/components/presentational/input/InputLabel.vue';

    import User from '@/entities/User.js';

    export default {
        components: {
            ActivixSelect,
            Box,
            InputGroup,
            InputLabel,
        },

        props: {
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                default: '',
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            reassignmentAllowed: {
                type: Boolean,
                default: false,
            },
            reassignmentValue: {
                type: Number,
                validator: value => typeof value === 'number' || value === null,
            },
            reassignmentValueTooltip: {
                type: String,
                default: '',
            },
            users: {
                type: Array,
                required: true,
                validator: users => users.every(user => user instanceof User),
            },
            value: {
                type: Number,
                validator: value => typeof value === 'number' || value === null,
            },
        },

        computed: {
            options() {
                return this.users.map(user => {
                    return {
                        value: user.id,
                        label: this.getFullName(user),
                    };
                });
            },

            reassignmentTooltip() {
                if (this.reassignmentValue) {
                    return this.reassignmentValueTooltip;
                }

                return this.$t('clientCard.assignationRequest');
            },

            reassignmentValueText() {
                return this.options.find(option => option.value === this.value)?.label || '---';
            },
        },

        methods: {
            open() {
                this.$refs.select.open();
            },
        },

        created() {
            if (this.reassignmentAllowed && typeof this.reassignmentValue === 'undefined') {
                throw Error('UserAssignationItem needs a reassignmentValue prop when you activate reassignmentAllowed.');
            }
        },
    };
</script>
