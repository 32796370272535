<template>
    <activix-tooltip
        placement="right"
        :content="tooltip"
        v-if="isActive"
    >
        <div class="flex"><!-- Wrapper because tooltips don't work on disabled elements -->
            <button
                class="flex flex-grow items-center h-18 p-6 bg-white | hover:bg-gray-200"
                :class="{
                    'justify-start': withLabel,
                    'justify-center': !withLabel,
                    'opacity-50': !isEnabled,
                }"
                :disabled="!isEnabled"
                @click="openInterDealerPanel"
            >
                <span class="flex-shrink-0 flex place-items-stretch w-18 h-18 p-1.5">
                    <span class="relative flex-1 flex justify-center items-center">
                        <img
                            alt="Group Logo"
                            class="max-h-9 max-w-9 | transition-all duration-300"
                            :class="{ 'grayscale': !isEnabled && !loading }"
                            :src="groupFavicon"
                            @error="faviconIsLoaded = false"
                            v-if="groupFavicon && faviconIsLoaded"
                        />

                        <icon
                            class="text-lg"
                            name="regular/hierarchy-4"
                            v-else
                        />
                    </span>
                </span>

                <span class="ml-1.5" v-if="withLabel">{{ label }}</span>
            </button>
        </div>
    </activix-tooltip>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';
    import { useInterAccountLeadsStore } from '@/store/modules/interAccountLeads/store';
    import { useLayoutStore } from '@/store/modules/layout/store';

    import Lead from '@/entities/Lead';

    export default {
        props: {
            lead: {
                required: true,
                type: Lead,
            },

            withLabel: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            faviconIsLoaded: true,
        }),

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'parentAuthUser']),
            ...mapState(useLayoutStore, ['activeSidebar']),
            ...mapState(useInterAccountLeadsStore, {
                interAccountLeads: 'leads',
                siblingAccounts: 'siblingAccounts',
                loading: 'loading',
            }),

            groupFavicon() {
                return this.lead.account.parents.find(parent => !!parent.favicon_url)?.favicon_url;
            },

            isActive() {
                if (this.authUser.isAdmin()) {
                    return true;
                }

                return this.isEnabled && !!this.siblingAccounts.length;
            },

            isEnabled() {
                return !!this.interAccountLeads.length || this.activeSidebar === 'interAccountLeads';
            },

            label() {
                return this.$t('clientCard.associatedLeads.interDealerLeads');
            },

            tooltip() {
                if (!this.isEnabled) {
                    return this.$t('addLead.noInterAccountLeads');
                }

                if (this.withLabel) {
                    return '';
                }

                return this.label;
            },
        },

        watch: {
            'lead.id'() {
                if (this.activeSidebar !== 'interAccountLeads') {
                    this.searchLeads(true);
                }
            },
        },

        methods: {
            ...mapActions(useInterAccountLeadsStore, ['fetchInterAccountLeads']),

            openInterDealerPanel() {
                this.$eventBus.$emit('show-right-sidebar', 'interAccountLeads');
            },

            async searchLeads(clear = false) {
                if (!this.lead.id) {
                    return;
                }

                if (!this.parentAuthUser.children.length >= 2 && !this.authUser.isAdmin()) {
                    return;
                }

                await this.fetchInterAccountLeads({ lead_id: this.lead.id, clear });
            },
        },

        mounted() {
            if (!this.interAccountLeads.length) {
                this.searchLeads();
            }
        },
    };
</script>
