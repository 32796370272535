<template>
    <div>
        <div class="grid grid-cols-3 gap-6 p-6 rounded-lg shadow-md bg-white mb-6">
            <input-group :label="$t('clientCard.vehicles.make')">
                <template #input>
                    <activix-select
                        class="flex-grow"
                        :options="makeOptions"
                        :nullable="true"
                        identifier="value"
                        filterable
                        v-model="filters.make"
                    />
                </template>
            </input-group>

            <input-group :label="$t('clientCard.vehicles.model')">
                <template #input>
                    <activix-select
                        class="flex-grow"
                        :options="modelOptions"
                        :nullable="true"
                        identifier="value"
                        filterable
                        v-model="filters.model"
                    />
                </template>
            </input-group>

            <input-group :label="$t('clientCard.vehicles.year')">
                <template #input>
                    <activix-select
                        class="flex-grow"
                        :options="yearOptions"
                        :nullable="true"
                        identifier="value"
                        filterable
                        v-model="filters.year"
                    />
                </template>
            </input-group>
        </div>
    </div>
</template>

<script>
    import { ActivixSelect } from '@activix/ui';
    import InputGroup from '@/components/presentational/input/InputGroup.vue';
    import { mapActions, mapState, mapWritableState } from 'pinia';
    import { useClientCardStore } from '@/store/modules/clientCard/store';
    import { useGlobalStore } from '@/store/store';
    import { debounce, range } from 'lodash-es';
    import ActivixDate from '@/value-objects/ActivixDate';

    export default {
        components: {
            ActivixSelect,
            InputGroup,
        },

        props: {
            vehicleInfo: {
                type: Array,
                required: false,
            },
            accountPowerSport: {
                type: Boolean,
                default: false,
            },
            accountVehicleText: {
                type: Boolean,
                default: false,
            },
            minYear: {
                type: Number,
                default: 1899,
            },
            maxYear: {
                type: Number,
                default: () => new ActivixDate('now').year + 3,
            },
        },

        data() {
            return {
                filters: {
                    make: this.vehicleInfo.make,
                    model: this.vehicleInfo.model,
                    year: this.vehicleInfo.year,
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['configs']),
            ...mapState(useClientCardStore, ['vehicleMakes']),
            ...mapWritableState(useClientCardStore, ['vehicleModels']),

            makeOptions() {
                if (this.accountVehicleText) {
                    return [];
                }

                if (this.accountPowerSport) {
                    return this.vehicles.map(vehicle => {
                        return {
                            value: vehicle.make,
                            text: vehicle.make,
                        };
                    });
                }

                return this.vehicleMakes.map(make => {
                    return {
                        value: make.name,
                        label: make.name,
                    };
                });
            },

            modelOptions() {
                const displayLocal = this.configs.locale === 'fr' ? 'display_name_fr' : 'display_name_en';
                const makeId = this.vehicleMakeId;

                return (this.vehicleModels[makeId] || [])
                    .map(model => {
                        const modelName = model[displayLocal] || model.name;

                        return {
                            value: modelName,
                            label: modelName,
                        };
                    })
                    .sort((a, b) => a.label.localeCompare(b.label));
            },

            yearOptions() {
                return range(this.maxYear, this.minYear, -1)
                    .map(year => {
                        const yearString = year.toString();

                        return {
                            value: yearString,
                            label: yearString,
                        };
                    });
            },

            vehicleMake() {
                return this.vehicleMakes.find(make => make.name === this.filters.make) || {};
            },

            vehicleMakeId() {
                return this.vehicleMake.id;
            },

            vehicleMakeModels() {
                return this.vehicleModels[this.vehicleMakeId];
            },
        },

        watch: {
            'filters.make': {
                handler() {
                    if (this.accountPowerSport) {
                        return;
                    }

                    this.triggerFetchVehicleModels();

                    if (this.vehicleInfo.make !== this.filters.make) {
                        this.filters.model = null;
                    }

                    this.updateFilters();
                },
            },

            'filters.model': {
                handler() {
                    if (this.accountPowerSport) {
                        return;
                    }
                    if (this.vehicleInfo.model !== this.filters.model) {
                        this.updateFilters();
                    }
                },
            },

            'filters.year': {
                handler() {
                    if (this.accountPowerSport) {
                        return;
                    }
                    if (this.vehicleInfo.year !== this.filters.year) {
                        this.updateFilters();
                    }
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, [
                'fetchVehicleModels',
            ]),

            async triggerFetchVehicleModels() {
                if (!this.vehicleMakeId || this.vehicleMakeModels) {
                    return;
                }

                const models = await this.fetchVehicleModels(this.vehicleMakeId);
                this.$set(this.vehicleModels, this.vehicleMakeId, models);
            },

            updateFilters() {
                this.$emit('updateFilters', this.filters);
            },
        },

        mounted() {
            this.updateFilters = debounce(this.updateFilters, 500);
        },

        beforeDestroy() {
            this.filters = [];
            this.updateFilters();
        },
    };
</script>
