<template>
    <flexible-input :blocked-reason="internalBlockedReason" :label="$t('clientCard.origin')">
        <div class="flex flex-grow">
            <invisible-multiselect
                :allow-empty="false"
                :disabled="disabled || isRestricted"
                :nullable="true"
                :options="options"
                :value="value"
                @input="updateLead({ source_id: $event })"
            />

            <a
                class="ml-2 flex items-center justify-center"
                @click="modals.originalEmail.opened = true"
                v-if="showOriginalEmailModalLink"
            >
                <activix-tooltip :content="$t('clientCard.originalEmail')">
                    <icon name="regular/archive-folder" />
                </activix-tooltip>
            </a>

            <original-email :opened="modals.originalEmail.opened" @close="modals.originalEmail.opened = false" />
        </div>
    </flexible-input>
</template>

<script>
    import { uniqBy } from 'lodash-es';

    import FlexibleInput from '@/components/container/input/FlexibleInput';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect';
    import OriginalEmail from '@/components/modals/OriginalEmail';

    import CreatedMethods from '@/entities/CreatedMethod';
    import Lead from '@/entities/Lead';

    export default {
        components: {
            FlexibleInput,
            InvisibleMultiselect,
            OriginalEmail,
        },

        props: {
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        data: () => ({
            modals: {
                originalEmail: {
                    opened: false,
                },
            },
        }),

        computed: {
            internalBlockedReason() {
                if (this.blockedReason) {
                    return this.blockedReason;
                }

                if (this.lead.created_method === CreatedMethods.MESSENGER) {
                    return this.$t('clientCard.blocked.specificCreatedMethod', [
                        CreatedMethods.getTranslation(this.lead.created_method),
                    ]);
                }

                return '';
            },

            isRestricted() {
                return this.lead.created_method === 'messenger';
            },

            options() {
                const sources = [];

                if (!empty(this.lead.import_source)) {
                    sources.push({
                        value: 'text-source',
                        text: this.lead.import_source,
                    });
                } else if (!empty(this.lead.media_source)) {
                    sources.push({
                        value: 'text-source',
                        text: this.lead.media_source,
                    });
                }

                for (const source of this.lead.account.sourcesWithProvider) {
                    if (empty(source.lead_type_id) || source.lead_type_id === this.lead.lead_type_id) {
                        if (this.lead.account.display_approved_sources && source.approved_source_id) {
                            sources.push({
                                value: source.approved_source.id,
                                text: source.approved_source.name,
                            });
                        } else if (source.display_on_listing) {
                            sources.push({
                                value: source.id,
                                text: source.name,
                            });
                        }
                    }
                }

                // Add current source to have the possibility to see it (Only if not already in array)
                if (
                    this.lead.source_id &&
                    this.lead.source?.id === this.lead.source_id &&
                    !sources.some(source => source.value === this.lead.source_id)
                ) {
                    if (this.lead.account.display_approved_sources && this.lead.source.approved_source) {
                        sources.push({
                            value: this.lead.source.id,
                            text: this.lead.source.approved_source.name,
                        });
                    } else {
                        sources.push({
                            value: this.lead.source.id,
                            text: this.lead.source.name,
                        });
                    }
                }

                // Sort
                sources.sort((a, b) => a.text.localeCompare(b.text));

                return uniqBy(sources, 'value');
            },

            showOriginalEmailModalLink() {
                return (
                    (!!this.lead.request?.old_url && this.lead.request?.parser?.type !== 'xml') ||
                    !!this.lead.original_email ||
                    !!this.lead.scraper_description ||
                    !!this.lead.chat
                );
            },

            value() {
                if (this.lead.source_id) {
                    return this.lead.source_id;
                }

                if (this.lead.media_source || this.lead.import_source) {
                    return 'text-source';
                }

                return null;
            },
        },

        methods: {
            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
