<template>
    <div class="space-y-3">
        <div class="flex flex-col flex-wrap justify-between text-gray-600 | lg:flex-row lg:gap-3">
            <p class="m-0">
                {{ $t('featurePreview.availableSince', [availableSinceDate]) }}
            </p>

            <p class="m-0" v-if="releasePlannedOn">
                {{ $t('featurePreview.projectedAdoptionOn', [releasePlannedOn]) }}
            </p>

            <p class="m-0">
                {{ autoActivated }}
            </p>
        </div>

        <div class="flex gap-3 pt-2">
            <activix-button @click="$emit('open-feedback')">
                {{ $t('featurePreview.giveFeedback') }}
            </activix-button>
        </div>
    </div>
</template>

<script>
    import ActivixDate from '@/value-objects/ActivixDate';
    import FeaturePreview from '@/entities/FeaturePreview.js';

    export default {
        props: {
            featurePreview: {
                type: FeaturePreview,
                required: true,
            },
        },

        computed: {
            autoActivated() {
                if (this.featurePreview.auto_activated) {
                    return this.$t('featurePreview.autoActivated');
                }

                return '';
            },

            availableSinceDate() {
                return new ActivixDate(this.featurePreview.available_at).toHumanShort(false);
            },

            releasePlannedOn() {
                const releasePlannedOn = new ActivixDate(this.featurePreview.release_planned_on);

                if (releasePlannedOn.isEmpty()) {
                    return '';
                }

                return `Q${releasePlannedOn.getQuarter()} ${releasePlannedOn.year}`;
            },
        },
    };
</script>
