var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('activix-select',{ref:"select",attrs:{"disabled":_vm.isReadonly,"options":_vm.resultStatusOptions,"same-width":false,"value":_vm.resultStatus},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var disabled = ref.disabled;
return [_c('activix-tooltip',{attrs:{"content":_vm.tooltip}},[_c('button',{staticClass:"flex justify-center items-center gap-0.5 rounded-lg text-sm text-white px-2 py-1.5 | sm:gap-1.5",class:[_vm.statusClass, { 'cursor-default': disabled }]},[_c('icon',{attrs:{"name":_vm.statusIcon}}),_c('span',{staticClass:"hidden | sm:inline",domProps:{"textContent":_vm._s(_vm.statusString)}}),(!disabled)?_c('icon',{staticClass:"ml-1.5 | print:hidden",attrs:{"name":"bold/arrow-down-1","scale":"0.55"}}):_vm._e()],1)])]}},{key:"default",fn:function(ref){
var options = ref.options;
return [_c('div',{staticClass:"flex flex-col -my-1"},_vm._l((options),function(option){return _c('button',{key:option.value,staticClass:"group flex gap-1.5 items-center px-3 py-1.5 select-none transition-colors",class:{
                        'text-gray-400 italic': option.disabled,
                        'text-gray-600 hover:bg-gray-100 hover:text-gray-800': !option.disabled,
                    },on:{"click":function($event){return _vm.onSelect(option.value)}}},[_c('icon',{class:option.iconColorClassName,attrs:{"name":option.iconName}}),_c('span',{domProps:{"textContent":_vm._s(option.label)}}),_c('span',{staticClass:"w-6 flex-1 text-right"},[(option.value === _vm.resultStatus)?_c('icon',{staticClass:"text-sm text-blue-500",attrs:{"name":"bold/check-1"}}):_vm._e()],1)],1)}),0)]}}])}),_c('add-communication',{attrs:{"opened":_vm.modals.addCommunication.opened,"lead":_vm.lead},on:{"close":function($event){_vm.modals.addCommunication.opened = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }