<template>
    <component :is="view" />
</template>

<script>
    import Lead from './Lead.vue';
    import LeadV2 from './LeadV2.vue';

    export default {
        computed: {
            view() {
                return this.$feature.isEnabled('new-lead-page', false)
                    ? LeadV2
                    : Lead;
            },
        },
    };
</script>
