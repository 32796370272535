<template>
    <div class="-mt-6 mb-6 | lg:-mx-6 flex bg-gray-300">
        <vue-scroll>
            <div class="tabs-container | h-20">
                <tab-item
                    :lead="lead"
                    :key="lead.id"
                    @delete="onDelete(lead)"
                    @mouseenter="fetchLead(lead.id)"
                    v-for="lead in filteredLeads"
                />

                <div class="flex-1 flex items-center border-b border-grey-350 px-8">
                    <button
                        class="flex items-center group"
                        @click="openLeadCreate"
                        v-if="contextLead.id && authUser.hasAccessToEditAccount(contextLead.account_id)"
                    >
                        <icon class="text-sm text-blue-500 group-hover:text-blue-600" :name="$icons.add" />
                        <span
                            class="ml-2 uppercase tracking-wider text-grey-500 group-hover:text-grey-600"
                            v-text="$t('clientCard.newAssociate')"
                        />
                    </button>
                </div>

                <activix-confirm-modal
                    type="error"
                    :content="$tc('client.deleteLeads.confirmation', 1)"
                    :opened.sync="deleteModal.opened"
                    :approve-callback="triggerDelete"
                    @approve="onApprove"
                />
            </div>
        </vue-scroll>
        <inter-account-leads-button />
    </div>
</template>

<script>
    import { get, camelCase } from 'lodash-es';
    import Lead from '@/entities/Lead.js';
    import LeadType from '@/entities/LeadType.js';
    import Division from '@/entities/Division.js';
    import TabItem from '@/components/lead/TabItem.vue';
    import InterAccountLeadsButton from '@/components/inter_account_leads/InterAccountLeadsButton.vue';
    import { mapActions, mapState } from 'pinia';
    import { useGlobalStore } from '../../store/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            TabItem,
            InterAccountLeadsButton,
        },

        data() {
            return {
                deleteModal: {
                    opened: false,
                    lead: null,
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'leads']),
            ...mapState(useContextStore, ['contextLead']),

            filteredLeads() {
                if (!this.contextLead.id) return [];

                if (get(this.contextLead.customer, 'leads', []).length <= 1) {
                    return [this.contextLead];
                }

                const getDate = lead => {
                    let date = null;

                    if (lead.division_id == Division.SERVICE) {
                        date = lead.appointment_date;
                    } else {
                        switch (lead.lead_type_id) {
                            case LeadType.PHONE:
                                date = lead.call_date;
                                break;
                            case LeadType.WALK_IN:
                                date = lead.presented_date;
                                break;
                            case LeadType.RENEWAL:
                                date = lead.end_contract_date;
                                break;
                        }
                    }

                    return date || lead.created_at;
                };

                const leads = this.contextLead.customer.leads
                    .map(lead => {
                        // Fetch lead from array instead of customer leads (If exist)
                        return this.leads.find(l => l.id == lead.id) || new Lead(lead);
                    })
                    .filter(lead => {
                        // Filter import and division
                        return (
                            (empty(lead.import) || (lead.import.finished_at && lead.import.validated)) &&
                            this.authUser.hasAccessToEditAccount(lead.account_id) &&
                            (lead.division_id != Division.SERVICE ||
                                this.authUser.isAdmin() ||
                                this.authUser.module_access.service === true)
                        );
                    })
                    .sort((b, a) => {
                        const aDate = locale_dt(getDate(a));
                        const bDate = locale_dt(getDate(b));

                        return aDate.diff(bDate);
                    });

                return leads.length ? leads : [this.contextLead];
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['fetchLead', 'deleteLead']),

            openLeadCreate() {
                const leadPhone = this.contextLead.lead_phones.find(phone => phone.type == 'home') || {};
                const leadEmail = this.contextLead.lead_emails.find(email => email.type == 'home') || {};

                this.$eventBus.$emit('open-lead-create', {
                    account: this.contextLead.account_id,
                    advisor: this.contextLead.user_id,
                    bdc: this.contextLead.bdc_user_id,
                    civility: this.contextLead.civility,
                    division: this.contextLead.division_id,
                    email: leadEmail.email,
                    name: this.contextLead.fullName,
                    phone: leadPhone.phone,
                    relatedLead: this.contextLead.id,
                    serviceAdvisor: this.contextLead.service_advisor_id,
                    serviceAgent: this.contextLead.service_agent_id,
                    source: this.contextLead.source_id,
                    type: null,
                    addingAssociatedLead: true,
                    relatedLeadId: this.contextLead.id,
                });
            },

            onDelete(lead) {
                this.deleteModal.lead = lead;
                this.deleteModal.opened = true;
            },

            async triggerDelete() {
                try {
                    await this.$api.leads.delete({ id: this.deleteModal.lead.id });
                } catch (error) {
                    this.$notify.error(this.$t('client.deleteLeads.failedSingle'));
                }
            },

            onApprove() {
                const lead = this.deleteModal.lead;
                this.deleteModal.lead = null;
                let newLeadId = null;

                if (this.filteredLeads.length == 1) {
                    newLeadId = 'dashboard';
                } else if (this.contextLead.id != lead.id) {
                    newLeadId = this.contextLead.id;
                } else {
                    const index = this.filteredLeads.findIndex(l => l.id == lead.id);

                    newLeadId = index == 0 ? this.filteredLeads[1].id : this.filteredLeads[index - 1].id;
                }

                const relatedEventLead = this.contextLead.customer.leads.filter(lead => {
                    return lead.event_related_id === this.contextLead.id;
                });

                if (relatedEventLead.length) {
                    this.deleteLead(relatedEventLead[0].id);
                }

                this.deleteLead(lead.id);

                this.$nextTick(() => {
                    if (newLeadId == 'dashboard') {
                        const defaultDashboard = camelCase(this.authUser.default_dashboard);

                        this.$router.replace({ name: `dashboards.${defaultDashboard}` });
                    } else if (newLeadId !== null) {
                        this.$router.replace({ name: 'leads.update', params: { id: newLeadId } });
                    }

                    this.$eventBus.$emit('fetch-table-leads', { getCount: true });
                });
            },
        },

        created() {
            this.$eventBus.$on('open-associated-lead-create', this.openLeadCreate);
        },

        beforeDestroy() {
            this.$eventBus.$off('open-associated-lead-create', this.openLeadCreate);
        },
    };
</script>

<style lang="less">
    .tabs-container {
        @apply flex flex-nowrap bg-grey-300 whitespace-nowrap;

        .tab {
            @apply relative flex items-center h-full px-6 border border-t-0 border-grey-350 -ml-px;

            .tab-content {
                @apply flex items-center opacity-75;
            }

            &.active {
                @apply bg-grey-150;
                border-bottom-color: theme('colors.grey.150');

                .tab-content {
                    @apply opacity-100;
                }
            }

            &.faded {
                .tab-content {
                    @apply opacity-50;
                }
            }

            &.active.faded {
                .tab-content {
                    @apply opacity-75;
                }
            }
        }
    }
</style>
