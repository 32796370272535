<template>
    <header
        class="
            flex justify-between items-start h-12 ml-24 pl-3 space-x-6
            md:h-14 md:ml-32 md:pl-6
            xl:h-12 xl:ml-24 xl:pl-3
            2xl:h-14 2xl:ml-32 2xl:pl-6
        "
    >
        <div class="flex items-center self-start mt-2 space-x-6">
            <div class="flex items-center space-x-3">
                <lead-type-picker
                    class="flex items-center gap-1.5 text-lg text-gray-650 font-semibold uppercase tracking-wide"
                    :lead="lead"
                    @update-lead="updateLead"
                />

                <result-status :lead="lead" @update-lead="updateLead" />

                <result-time class="hidden md:inline-flex" :lead="lead" v-if="showResultTime" />

                <div
                    class="
                        flex items-center gap-1.5 rounded-full text-sm bg-gray-400
                        px-3 py-0.5
                        2xl:py-1
                    "
                    v-if="showCallCount"
                >
                    <icon name="regular/phone-actions-call" />
                    {{ lead.called_count || 0 }}
                </div>
            </div>
        </div>

        <div class="flex items-center space-x-6">
            <div class="space-x-6 text-gray-650 hidden | 2xl:inline-flex | print:inline-flex">
                <activix-tooltip :content="leadCreatedAtFormatted">
                    <date-distance :date="lead.created_at" :prefix="$t('clientCard.createdAt')" />
                </activix-tooltip>

                <activix-tooltip :content="leadUpdatedAtFormatted">
                    <date-distance :date="lead.updated_at" :prefix="$t('clientCard.lastUpdated')" />
                </activix-tooltip>
            </div>

            <div class="flex items-center space-x-3 | print:hidden">
                <!-- Responsive Popover -->
                <activix-tooltip :content="$t('clientCard.boxes.general.moreOptions')">
                    <activix-button
                        class="rounded | 2xl:hidden"
                        type="white"
                        v-popover:responsiveExtra
                    >
                        <icon name="regular/information-circle" />
                        <el-popover
                            placement="bottom-end"
                            trigger="click"
                            ref="responsiveExtra"
                            @click.stop
                        >
                            <div class="flex flex-col items-center p-3 gap-3">
                                <div class="flex items-center space-x-3 md:hidden" v-if="showResultTime || showCallCount">
                                    <result-time :lead="lead" v-if="showResultTime" />

                                    <div
                                        class="
                                    flex items-center gap-1.5 rounded-full text-sm bg-gray-400
                                    px-3 py-0.5
                                    xl:py-1
                                "
                                        v-if="showCallCount"
                                    >
                                        <icon name="regular/phone-actions-call" />
                                        {{ lead.called_count || 0 }}
                                    </div>
                                </div>

                                <div class="flex flex-col items-center">
                                    <activix-tooltip :content="leadCreatedAtFormatted">
                                        <date-distance :date="lead.created_at" :prefix="$t('clientCard.createdAt')" />
                                    </activix-tooltip>

                                    <activix-tooltip :content="leadUpdatedAtFormatted">
                                        <date-distance :date="lead.updated_at" :prefix="$t('clientCard.lastUpdated')" />
                                    </activix-tooltip>
                                </div>

                                <div class="flex justify-between items-center gap-3 | xl:hidden">
                                    <activix-tooltip :content="$t('clientCard.leadManagement')">
                                        <lead-management
                                            :lead="lead"
                                            @delete-lead="deleteLead"
                                            @update-lead="updateLead"
                                        />
                                    </activix-tooltip>
                                    <activix-tooltip :content="$t('clientCard.association') + ' / ' + $t('clientCard.fusion')">
                                        <activix-button
                                            class="rounded"
                                            :disabled="guest"
                                            type="white"
                                            @click="onDuplicateManagementClick"
                                        >
                                            <icon name="regular/diagram-fall-down" />
                                        </activix-button>
                                    </activix-tooltip>

                                    <activix-tooltip :content="$t('clientCard.exportTo')">
                                        <lead-export-dropdown
                                            class="space-x-3 h-[30px] btn-white cursor-pointer rounded"
                                            :class="{'cursor-not-allowed opacity-60': guest }"
                                            data-intercom-target="lead.export"
                                            trigger-class-name="px-4 py-1"
                                        />
                                    </activix-tooltip>
                                </div>
                            </div>
                        </el-popover>
                    </activix-button>
                </activix-tooltip>

                <activix-tooltip :content="$t('clientCard.leadManagement')">
                    <lead-management
                        class="hidden | xl:inline-flex"
                        :lead="lead"
                        @delete-lead="deleteLead"
                        @update-lead="updateLead"
                    />
                </activix-tooltip>

                <activix-tooltip :content="$t('clientCard.association') + ' / ' + $t('clientCard.fusion')">
                    <activix-button
                        class="rounded hidden | xl:inline-flex"
                        :disabled="guest"
                        type="white"
                        @click="onDuplicateManagementClick"
                    >
                        <icon name="regular/diagram-fall-down" />
                    </activix-button>
                </activix-tooltip>

                <activix-tooltip :content="$t('clientCard.exportTo')">
                    <lead-export-dropdown
                        class="space-x-3 btn-white cursor-pointer rounded hidden | xl:inline-flex xl:h-[30px]"
                        :class="{ 'cursor-not-allowed opacity-60': guest }"
                        data-intercom-target="lead.export"
                        trigger-class-name="px-4 py-1.5"
                    />
                </activix-tooltip>
            </div>
        </div>
    </header>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';
    import { useLeadStore } from '@/store/modules/lead/store.js';

    import DateDistance from '@/components/container/date/DateDistance.vue';
    import LeadExportDropdown from '@/components/lead/LeadExportDropdown.vue';
    import LeadManagement from '@/components/lead/LeadManagement.vue';
    import LeadTypePicker from '@/components/container/input/lead/LeadTypePicker.vue';
    import ResultStatus from '@/components/container/lead/ResultStatus.vue';
    import ResultTime from '@/components/container/lead/ResultTime.vue';

    import ActivixDate from '@/value-objects/ActivixDate.js';
    import Lead from '@/entities/Lead.js';
    import LeadType from '@/entities/LeadType';

    export default {
        components: {
            DateDistance,
            LeadExportDropdown,
            LeadManagement,
            LeadTypePicker,
            ResultStatus,
            ResultTime,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),
            ...mapState(useLeadStore, ['mergeManualCheckDuplicate']),

            leadCreatedAtFormatted() {
                return new ActivixDate(this.lead.created_at).toHumanShort();
            },

            leadUpdatedAtFormatted() {
                return new ActivixDate(this.lead.updated_at).toHumanShort();
            },

            showCallCount() {
                return this.lead.isOfType(LeadType.EVENT) && this.lead.account.hasGeneralInfoField('called_count');
            },

            showResultTime() {
                return (
                    this.lead.calculate_time &&
                    !this.lead.created_by_user &&
                    [LeadType.EMAIL, LeadType.WEB_ORDER, LeadType.SMS].includes(this.lead.lead_type_id)
                );
            },
        },

        methods: {
            closePopover() {
                this.$refs.responsiveExtra?.doClose();
            },

            deleteLead() {
                this.closePopover();

                this.$emit('delete-lead');
            },

            onDuplicateManagementClick() {
                this.closePopover();

                useLeadStore().mergeManualCheckDuplicate = !this.mergeManualCheckDuplicate;
            },

            updateLead({ data, options = {} }) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
