<template>
    <div>
        <div class="box | mb-6">
            <div class="box-header | flex items-center">
                <h4 class="box-title">
                    {{ $t('clientCard.process') }}
                </h4>

                <div class="progress | flex-1 h-1 bg-grey-300 mb-0 ml-6 mt-1 mr-4">
                    <div class="progress-bar progress-bar-primary" ref="progress" />
                </div>

                <activix-tooltip :content="$t('clientCard.delivered')">
                    <icon
                        name="bold/check-1"
                        class="text-sm"
                        :class="checkIconClass"
                    />
                </activix-tooltip>
            </div>
            <div class="box-body | pt-0" :class="{ 'pb-2': mdLayout }">
                <div class="flex flex-wrap -mx-3">
                    <lead-input-wrapper class="w-full xs:w-1/2" section="process" position="left">
                        <lead-input-group v-if="contextLead.account.hasProcessField('lead_bank')">
                            <lead-input-label>
                                {{ $t('clientCard.institution') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip :content="institutionTooltip">
                                    <select-picker
                                        name="lead_bank_id"
                                        :disabled="institutionDisabled"
                                        :options="leadBankOptions"
                                        :record-id="contextLead.id"
                                        :value="leadBank"
                                    />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasProcessField('progress_state')">
                            <lead-input-label>
                                {{ $t('clientCard.state') }}
                            </lead-input-label>
                            <lead-input-value>
                                <!-- CUSTOM - Hard coded disabled for 440 Ford - Phil - 2017-02-22 -->
                                <activix-tooltip :content="approbationTooltip">
                                    <select-picker
                                        name="progress_state"
                                        :disabled="approbationDisabled"
                                        :options="leadProcesses"
                                        :record-id="contextLead.id"
                                        :value="contextLead.progress_state"
                                    />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasProcessField('approved')">
                            <lead-input-label>
                                {{ $t('clientCard.approved') }}
                            </lead-input-label>
                            <lead-input-value>
                                <approved :in-client-card="true" :lead="contextLead" />
                            </lead-input-value>
                        </lead-input-group>

                        <!-- This field is also in Performance.vue if the account does not have process activated -->
                        <lead-input-group v-if="contextLead.account.hasProcessField('delivery')">
                            <lead-input-label>
                                {{ $t('clientCard.deliveryDate') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip :content="deliveryDateTooltip">
                                    <delivery :in-client-card="true" :lead="contextLead" />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasProcessField('available')">
                            <lead-input-label>
                                {{ $t('clientCard.deliveryPrepDate') }}
                            </lead-input-label>
                            <lead-input-value>
                                <available :in-client-card="true" :lead="contextLead" />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasProcessField('deliverable')">
                            <lead-input-label>
                                {{ $t('clientCard.deliverableDate') }}
                            </lead-input-label>
                            <lead-input-value>
                                <deliverable :in-client-card="true" :lead="contextLead" />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group
                            v-if="
                                contextAccount.waiting_sale_option &&
                                    contextLead.account.hasProcessField('waiting_sale')
                            "
                        >
                            <lead-input-label>
                                {{ $t('clientCard.waitingSale') }}
                            </lead-input-label>
                            <lead-input-value>
                                <waiting-sale
                                    :in-client-card="true"
                                    :lead="contextLead"
                                />
                            </lead-input-value>
                        </lead-input-group>
                        <lead-input-group v-if="contextLead.account.hasProcessField('paperwork')">
                            <lead-input-label>
                                {{ $t('clientCard.paperwork') }}
                            </lead-input-label>
                            <lead-input-value>
                                <paperwork :in-client-card="true" :lead="contextLead" />
                            </lead-input-value>
                        </lead-input-group>
                    </lead-input-wrapper>
                    <lead-input-wrapper class="w-full xs:w-1/2" section="process" position="right">
                        <lead-input-group v-if="contextLead.account.hasProcessField('prepared')">
                            <lead-input-label>
                                {{ $t('clientCard.prepared') }}
                            </lead-input-label>
                            <lead-input-value>
                                <prepared :in-client-card="true" :lead="contextLead" />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasProcessField('gas')">
                            <lead-input-label>
                                {{ $t('clientCard.gas') }}
                            </lead-input-label>
                            <lead-input-value>
                                <gas :in-client-card="true" :lead="contextLead" />
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasProcessField('inspected')">
                            <lead-input-label>
                                {{ $t('clientCard.inspected') }}
                            </lead-input-label>
                            <lead-input-value>
                                <inspected :in-client-card="true" :lead="contextLead" />
                            </lead-input-value>
                        </lead-input-group>

                        <!-- This field is also in Performance.vue if the account does not have process activated -->
                        <lead-input-group v-if="contextLead.account.hasProcessField('delivered')">
                            <lead-input-label>
                                {{ $t('result.delivered') }}
                            </lead-input-label>
                            <lead-input-value>
                                <div class="flex items-center">
                                    <span>
                                        <delivered :in-client-card="true" :lead="contextLead" />
                                    </span>
                                    <span class="ml-2 flex items-center" v-if="showRenewalIcon">
                                        <activix-tooltip :content="$t('createdMethods.auto_renewal')">
                                            <icon :name="$icons.loading" class="link-orange" @click="renew" />
                                        </activix-tooltip>
                                    </span>
                                </div>
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="$feature.isEnabled('casl_compliance')">
                            <lead-input-label>
                                {{ $t('consent.consent') }}
                            </lead-input-label>
                            <lead-input-value>
                                <div class="flex item" @click="openConsentModal">
                                    <span>
                                        <consent :lead="contextLead" />
                                    </span>
                                </div>
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasProcessField('recorded')">
                            <lead-input-label>
                                {{ $t('clientCard.vehicles.saved_date') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip :content="recordedTooltip">
                                    <recorded :in-client-card="true" :lead="contextLead" />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>

                        <lead-input-group v-if="contextLead.account.hasProcessField('discounted')">
                            <lead-input-label>
                                {{ $tc('clientCard.discounted', 1) }}
                            </lead-input-label>
                            <lead-input-value>
                                <discounted :lead="contextLead" :in-client-card="true" />
                            </lead-input-value>
                        </lead-input-group>

                        <!-- This field is also in Performance.vue if the account does not have process activated -->
                        <lead-input-group v-if="contextLead.account.hasProcessField('csi')">
                            <lead-input-label>
                                {{ $t('clientCard.csi') }}
                            </lead-input-label>
                            <lead-input-value>
                                <activix-tooltip :content="csiTooltip">
                                    <csi :in-client-card="true" :lead="contextLead" />
                                </activix-tooltip>
                            </lead-input-value>
                        </lead-input-group>
                    </lead-input-wrapper>
                    <lead-input-wrapper class="md:w-full" section="process" display-type="box" />
                </div>
            </div>
        </div>
        <create-renewal :lead="contextLead" :opened.sync="createRenewalModalOpened" />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    // Components
    import Approved from '../dashboards/columns/FollowUp/Approved.vue';
    import Available from '../dashboards/columns/FollowUp/Available.vue';
    import Consent from '../dashboards/columns/FollowUp/Consent.vue';
    import CreateRenewal from '../modals/CreateRenewal.vue';
    import Csi from '../dashboards/columns/FollowUp/Csi.vue';
    import Deliverable from '../dashboards/columns/FollowUp/Deliverable.vue';
    import Delivered from '../dashboards/columns/FollowUp/Delivered.vue';
    import Delivery from '../dashboards/columns/FollowUp/Delivery.vue';
    import Discounted from '../dashboards/columns/FollowUp/Discounted.vue';
    import Gas from '../dashboards/columns/FollowUp/Gas.vue';
    import Inspected from '../dashboards/columns/FollowUp/Inspected.vue';
    import LeadInputGroup from './LeadInputGroup.vue';
    import LeadInputLabel from './LeadInputLabel.vue';
    import LeadInputValue from './LeadInputValue.vue';
    import LeadInputWrapper from './LeadInputWrapper.vue';
    import Paperwork from '../dashboards/columns/FollowUp/Paperwork.vue';
    import Prepared from '../dashboards/columns/FollowUp/Prepared.vue';
    import Recorded from '../dashboards/columns/FollowUp/Recorded.vue';
    import SelectPicker from './inputs/SelectPicker.vue';
    import WaitingSale from '../dashboards/columns/FollowUp/WaitingSale.vue';

    // Entities
    import LeadType from '../../entities/LeadType.js';
    import ProgressState from '../../entities/ProgressState.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useClientCardStore } from '../../store/modules/clientCard/store.js';

    export default {
        components: {
            Approved,
            Available,
            Consent,
            CreateRenewal,
            Csi,
            Deliverable,
            Delivered,
            Delivery,
            Discounted,
            Gas,
            Inspected,
            LeadInputGroup,
            LeadInputLabel,
            LeadInputValue,
            LeadInputWrapper,
            Paperwork,
            Prepared,
            Recorded,
            SelectPicker,
            WaitingSale,
        },
        data() {
            return {
                createRenewalModalOpened: false,
                tootTipRenewal: '',
            };
        },

        computed: {
            ...mapState(useClientCardStore, ['leadBanks']),
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            checkIconClass() {
                return this.progressPercent === 100 ? 'text-blue-500' : 'text-grey-300';
            },

            leadBankOptions() {
                const banks = [];

                for (const leadBank of this.leadBanks) {
                    banks.push(leadBank);
                }

                if (!empty(this.contextLead.imported_bank)) {
                    banks.push({
                        value: 'text-form',
                        text: `${this.contextLead.imported_bank}`,
                    });
                }

                return [{ value: '', text: '...' }].concat(banks);
            },

            leadBank() {
                if (this.contextLead.imported_bank && !this.contextLead.lead_bank_id) {
                    return 'text-form';
                }

                return this.contextLead.lead_bank_id;
            },

            leadProcesses() {
                return ProgressState.values
                    .map(state => {
                        return {
                            value: state,
                            text: ProgressState.getTranslation(state),
                            disabled: state === ProgressState.REFUSED && (this.contextLead.delivered || this.contextLead.approved),
                        };
                    })
                    .sort((a, b) => a.text.localeCompare(b.text));
            },

            vehicleEligibleForRenewal() {
                return (
                    this.contextLead.canAutoRenew() &&
                    this.soldVehicle.modality != 'cash' &&
                    !as_locale(this.soldVehicle.end_contract_date, 'end_contract_date').isValid() &&
                    !this.soldVehicle.term
                );
            },

            showRenewalIcon() {
                if (
                    !this.soldVehicle ||
                    !this.contextLead.canAutoRenew() ||
                    this.contextLead.renewal_cycle ||
                    !this.contextLead.delivered ||
                    !this.vehicleEligibleForRenewal
                ) {
                    return false;
                }

                return true;
            },

            // @TODO Revamp when we uncomment
            // userCommunications() {
            //     return [];
            // },

            deliveredTooltip() {
                if (!['invalid', 'lost', 'duplicate'].includes(this.contextLead.status)) {
                    if (!this.contextLead.sale) {
                        if (this.contextLead.isOfType(LeadType.RENEWAL)) {
                            return this.$t('clientCard.needRenewalChecked');
                        }

                        return this.$t('clientCard.needSaleChecked');
                    }
                } else {
                    return `${this.$t(`clientCard.statusTooltip.${this.contextLead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.delivered'),
                    ])}`;
                }

                return '';
            },

            csiTooltip() {
                if (['invalid', 'lost', 'duplicate'].includes(this.contextLead.status)) {
                    return `${this.$t(`clientCard.statusTooltip.${this.contextLead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.csi'),
                    ])}`;
                }

                return !this.contextLead.delivered ? this.$t('clientCard.needDeliveredChecked') : '';
            },

            recordedTooltip() {
                if (['invalid', 'lost', 'duplicate'].includes(this.contextLead.status)) {
                    return `${this.$t(`clientCard.statusTooltip.${this.contextLead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.recorded'),
                    ])}`;
                }

                return '';
            },

            institutionTooltip() {
                if (['invalid', 'lost', 'duplicate'].includes(this.contextLead.status)) {
                    return `${this.$t(`clientCard.statusTooltip.${this.contextLead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.institution'),
                    ])}`;
                }

                return '';
            },

            institutionDisabled() {
                if (!this.$can('leads.lead_bank_id:update')) {
                    return true;
                }
                if (['invalid', 'lost', 'duplicate'].includes(this.contextLead.status)) {
                    return true;
                }

                return this.guest;
            },

            approbationTooltip() {
                if (['invalid', 'lost', 'duplicate'].includes(this.contextLead.status)) {
                    return `${this.$t(`clientCard.statusTooltip.${this.contextLead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.approbation'),
                    ])}`;
                }

                return '';
            },

            approbationDisabled() {
                if (!this.$can('leads.progress_state:update')) {
                    return true;
                }

                if (['invalid', 'lost', 'duplicate'].includes(this.contextLead.status)) {
                    return true;
                }

                return this.guest;
            },

            progressPercent() {
                if (this.contextLead.delivered) {
                    return 100;
                }

                let cnt = this.contextLead.delivery_date ? 1 : 0;
                let max = 7;

                cnt += this.contextLead.lead_bank_id ? 1 : 0;
                cnt += this.contextLead.progress_state ? 1 : 0;
                cnt += this.contextLead.approved ? 1 : 0;
                cnt += this.contextLead.prepared ? 1 : 0;
                cnt += this.contextLead.inspected ? 1 : 0;

                if (this.contextLead.account.client_card_fields.process.available) {
                    max++;
                    cnt += this.contextLead.available_date ? 1 : 0;
                }

                if (this.contextLead.account.client_card_fields.process.paperwork) {
                    max++;
                    cnt += this.contextLead.paperwork_date ? 1 : 0;
                }

                if (this.contextLead.account.client_card_fields.process.gas) {
                    max++;
                    cnt += this.contextLead.gas ? 1 : 0;
                }

                return (cnt / max) * 100;
            },

            deliveryDateTooltip() {
                if (['invalid', 'lost', 'duplicate'].includes(this.contextLead.status)) {
                    return '';
                }

                if (!this.contextLead.sale_date && !this.contextLead.delivery_date && !this.contextLead.refinanced_date) {
                    if (this.contextLead.isOfType(LeadType.RENEWAL)) {
                        return this.$t('clientCard.needRenewalChecked');
                    }

                    return this.$t('clientCard.needSaleChecked');
                }

                if (this.contextLead.delivered) {
                    return this.$t('clientCard.modifyDeliveryDate');
                }

                return '';
            },

            soldVehicle() {
                return this.contextLead.getSoldOrFirstWantedVehicle();
            },
        },

        watch: {
            'contextLead.lead_bank_id'(newValue) {
                if (newValue && this.contextLead.progress_state == ProgressState.CASH_DEAL) {
                    this.updateLeadAction(this.contextLead.id, { progress_state: null });
                }
            },

            'contextLead.progress_state'() {
                if (this.contextLead.progress_state == ProgressState.CASH_DEAL) {
                    if (this.contextLead.lead_bank_id) {
                        this.updateLeadAction(this.contextLead.id, { lead_bank_id: null });
                    }

                    if (!empty(this.contextLead.getSoldVehicle())) {
                        this.updateLeadVehicleAction(
                            {
                                modality: 'cash',
                            },
                            this.contextLead.getSoldVehicle().id,
                        );
                    }
                }
            },

            progressPercent(newValue) {
                this.updateProgress(newValue);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'updateLeadVehicleAction']),

            openConsentModal() {
                this.$modal.show('consentConfig', {
                    leadId: this.contextLead.id,
                });
            },

            renew() {
                if (this.vehicleEligibleForRenewal) {
                    this.createRenewalModalOpened = true;
                } else {
                    this.$notify.success(this.$t('clientCard.renewalSuccessful'));

                    // @TODO Why are we doing this?
                    this.updateLeadAction(this.contextLead.id, {});
                }
            },

            updateProgress(value) {
                $(this.$refs.progress).animate({ width: `${value}%` }, 200);
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.updateProgress(this.progressPercent);
            });
        },
    };
</script>
