<template>
    <box class="calendar-v2 h-full overflow-hidden | print:hidden">
        <box-header slot="header">
            {{ $t('clientCard.boxes.calendar.title') }}
            <box-header-icon
                slot="right"
                icon-name="regular/add"
                :tooltip="$t('modal.addTaskEventTitle')"
                @click="openCreateTaskEventModal"
            />
        </box-header>

        <box-content slot="content" class="p-0">
            <calendar :current-lead="contextLead" />
        </box-content>
    </box>
</template>

<script>
    import { mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store';

    import Box from '@/components/presentational/boxes/Box';
    import BoxContent from '@/components/presentational/boxes/BoxContent';
    import BoxHeader from '@/components/presentational/boxes/BoxHeader';
    import BoxHeaderIcon from '@/components/presentational/boxes/BoxHeaderIcon';
    import Calendar from '@/components/lead/Calendar.vue';

    export default {
        components: {
            Box,
            BoxContent,
            BoxHeader,
            BoxHeaderIcon,
            Calendar,
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
        },

        methods: {
            openCreateTaskEventModal() {
                this.$eventBus.$emit('open-add-task-event', {
                    leadId: this.contextLead.id,
                });
            },
        },
    };
</script>
