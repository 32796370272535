<template>
    <section>
        <input-list :balance-columns="true" :fields="fields" />

        <activix-confirm-modal
            :content="updateNextPresentedDateModal.content"
            :opened.sync="updateNextPresentedDateModal.opened"
            :title="$t('clientCard.nextPresentedDate')"
            @approve="triggerUpdateNextPresentedDate(updateNextPresentedDateModal.nextPresentedDate)"
            @closed="clearNextPresentedDateModal"
            v-if="withModal"
        />
    </section>
</template>

<script>
    import { get, floor } from 'lodash-es';

    import InvisibleInput from '@/components/container/input/InvisibleInput';
    import InvisibleDateTimePicker from '@/components/container/input/InvisibleDateTimePicker';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect';
    import InputList from '@/components/container/input/InputList';

    import Lead from '@/entities/Lead';
    import TaskEventType from '@/entities/TaskEventType';

    export default {
        components: { InputList },

        props: {
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            lead: {
                type: Lead,
                required: true,
            },
            withModal: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                oldLeadId: null,
                updateNextPresentedDateModal: {
                    content: '',
                    nextPresentedDate: null,
                    opened: false,
                },
            };
        },

        computed: {
            canSetNextVisitDate() {
                return (
                    this.lead.service_interval_km &&
                    this.lead.service_monthly_km &&
                    this.lastPresentedDate
                );
            },

            fields() {
                return [
                    {
                        column: 1,
                        label: this.$t('clientCard.lastPresentedDate'),
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: this.lastPresentedDateString,
                        },
                    },
                    {
                        column: 1,
                        label: this.$t('clientCard.odometerLastVisit'),
                        blockedReason: this.blockedReason,
                        component: InvisibleInput,
                        props: {
                            disabled: this.disabled,
                            mask: 'km',
                            value: this.lead.odometer_last_visit,
                        },
                        events: { input: value => this.updateLead({ odometer_last_visit: value }) },
                    },
                    {
                        column: 1,
                        label: this.$t('clientCard.serviceMonthlyKm'),
                        blockedReason: this.blockedReason,
                        component: InvisibleInput,
                        props: {
                            disabled: this.disabled,
                            mask: 'km',
                            value: this.lead.service_monthly_km,
                        },
                        events: { input: value => this.updateLead({ service_monthly_km: value }) },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.serviceIntervalKm'),
                        blockedReason: this.blockedReason,
                        component: InvisibleMultiselect,
                        props: {
                            disabled: this.disabled,
                            nullable: true,
                            options: this.intervals,
                            value: this.lead.service_interval_km?.toString() || '',
                        },
                        events: { input: value => this.updateLead({ service_interval_km: value }) },
                    },
                    {
                        column: 2,
                        visible: this.lead.isService(),
                        label: this.$t('clientCard.nextPresentedDate'),
                        blockedReason: this.blockedReason,
                        component: InvisibleDateTimePicker,
                        props: {
                            disabled: this.disabled,
                            value: this.lead.next_presented_date,
                        },
                        events: { input: value => this.updateLead({ next_presented_date: value }) },
                    },
                    {
                        column: 2,
                        visible: this.lead.isService(),
                        label: this.$t('clientCard.code'),
                        blockedReason: this.blockedReason,
                        component: InvisibleInput,
                        props: {
                            disabled: this.disabled,
                            value: this.lead.code,

                        },
                        events: { input: value => this.updateLead({ code: value }) },
                    },
                ];
            },

            intervals() {
                return [
                    { value: '1000', text: '1,000 KM' },
                    { value: '5000', text: '5,000 KM' },
                    { value: '6000', text: '6,000 KM' },
                    { value: '8000', text: '8,000 KM' },
                    { value: '12000', text: '12,000 KM' },
                    { value: '16000', text: '16,000 KM' },
                    { value: '18000', text: '18,000 KM' },
                    { value: '20000', text: '20,000 KM' },
                    { value: '24000', text: '24,000 KM' },
                    { value: '25000', text: '25,000 KM' },
                ];
            },

            lastPresentedDate() {
                return get(this.lead, 'customer.last_presented_date') || this.lead.presented_date;
            },

            lastPresentedDateString() {
                return as_locale(this.lastPresentedDate, 'presented_date').humanShort(true);
            },
        },

        watch: {
            lastPresentedDate(newValue, oldValue) {
                if (this.canSetNextVisitDate && oldValue != newValue) {
                    this.onUpdateNextPresentedDate();
                }
            },

            'lead.id': {
                immediate: true,
                handler(newLeadId, oldLeadId) {
                    this.oldLeadId = oldLeadId;
                },
            },

            'lead.next_presented_date'(newValue, oldValue) {
                if (
                    (this.oldLeadId && this.lead.id !== this.oldLeadId) ||
                    !newValue ||
                    locale_dt(oldValue).isSame(locale_dt(newValue))
                ) {
                    this.oldLeadId = this.lead.id;
                    return;
                }

                this.$eventBus.$emit('open-add-task-event', {
                    leadId: this.lead.id,
                    type: TaskEventType.CALL,
                    startAt: newValue,
                });
            },

            'lead.service_interval_km'(newValue, oldValue) {
                if (this.canSetNextVisitDate && oldValue != newValue) {
                    this.onUpdateNextPresentedDate();
                }
            },

            'lead.service_monthly_km'(newValue, oldValue) {
                if (this.canSetNextVisitDate && oldValue != newValue) {
                    this.onUpdateNextPresentedDate();
                }
            },
        },

        methods: {
            clearNextPresentedDateModal() {
                this.updateNextPresentedDateModal.content = '';
                this.updateNextPresentedDateModal.nextPresentedDate = null;
            },

            onUpdateNextPresentedDate() {
                const currentNextPresentedDate = as_locale(this.lead.next_presented_date, 'next_presented_date');
                const nextPresentedDate = as_locale(this.lastPresentedDate, 'last_presented_date');
                const numberOfMonth = this.lead.service_interval_km / this.lead.service_monthly_km;

                if (numberOfMonth % 1 != 0) {
                    const start = nextPresentedDate.clone();
                    const end = start.clone().addMonths(floor(numberOfMonth));
                    const daysInBetween = end.diffInDays(start);
                    const numberOfDaysInLastMonth = end.daysInMonth();
                    const numberOfDaysToAdd = daysInBetween + floor(numberOfDaysInLastMonth * (numberOfMonth % 1));

                    nextPresentedDate.addDays(numberOfDaysToAdd);
                } else {
                    nextPresentedDate.addMonths(numberOfMonth);
                }

                if (currentNextPresentedDate.isValid() && !nextPresentedDate.isSame(currentNextPresentedDate)) {
                    this.updateNextPresentedDateModal.nextPresentedDate = nextPresentedDate;
                    this.updateNextPresentedDateModal.content = this.$t('clientCard.setNextPresentedDate', [
                        nextPresentedDate.humanShort(true),
                        currentNextPresentedDate.humanShort(true),
                    ]);
                    this.updateNextPresentedDateModal.opened = true;
                } else {
                    this.triggerUpdateNextPresentedDate(nextPresentedDate);
                }
            },

            triggerUpdateNextPresentedDate(nextPresentedDate) {
                this.updateLead({ next_presented_date: nextPresentedDate.toString() });
            },

            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
