<template>
    <progress-bar :steps="progressBarSteps" />
</template>

<script>
    import ProgressBar from '@/components/container/lead/ProgressBar.vue';
    import Lead from '@/entities/Lead.js';

    export default {
        components: { ProgressBar },
        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            progressBarSteps() {
                return [
                    {
                        tooltip: this.$t('clientCard.boxes.financialAndDelivery.progress.vehicleSold'),
                        isActive: () => !!this.lead.sale_date,
                    },
                    {
                        tooltip: this.$t('clientCard.boxes.financialAndDelivery.progress.approvalSent'),
                        isActive: () => !!this.lead.progress_state,
                        icon: 'regular/send-email',
                    },
                    {
                        tooltip: this.$t('clientCard.boxes.financialAndDelivery.progress.clientApproved'),
                        isActive: () => this.lead.approved,
                        icon: 'regular/accounting-document',
                    },
                    {
                        tooltip: this.$t('clientCard.boxes.financialAndDelivery.progress.deliveryDateSet'),
                        isActive: () => !!this.lead.delivery_date,
                        icon: 'regular/calendar-check',
                    },
                    {
                        tooltip: this.$t('clientCard.boxes.financialAndDelivery.progress.vehicleInspected'),
                        isActive: () => this.lead.inspected,
                    },
                    {
                        tooltip: this.$t('clientCard.boxes.financialAndDelivery.progress.vehicleDelivered'),
                        isActive: () => !!this.lead.delivered_date,
                        icon: 'regular/check-circle-1',
                    },
                ];
            },
        },
    };
</script>
