<template>
    <activix-modal
        size="lg"
        :loading-overlay-opacity="true"
        :loading="isUpdating"
        :opened="opened"
        :no-padding="true"
        @close="close"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('featurePreview.title') }}
            </h4>
        </template>

        <template slot="body">
            <section class="grid grid-cols-3 rounded-b-sm">
                <nav class="flex flex-col justify-between p-6 gap-3 border-r border-gray-200 bg-gray-100">
                    <div class="flex flex-col content-start gap-3">
                        <feature-preview-button
                            :feature-preview="featurePreview"
                            :selected="isSelectedFeature(featurePreview)"
                            :key="featurePreview.id"
                            @feature-selected="onFeatureSelected(featurePreview)"
                            v-for="featurePreview in featurePreviews"
                        />
                    </div>
                </nav>

                <div class="col-span-2 p-6 space-y-6">
                    <feature-preview
                        :active-state="selectedFeaturePreviewInternalActiveState"
                        :feature-preview="selectedFeaturePreview"
                        @active-state-changed="onFeatureActiveStateChanged"
                        v-if="selectedFeaturePreview"
                    />
                </div>
            </section>
        </template>

        <template slot="footer">
            <div class="flex justify-end">
                <activix-button
                    :loading="isUpdating"
                    :disabled="featuresWithStateUpdated.length === 0"
                    type="primary"
                    @click="apply"
                >
                    {{ $t('featurePreview.applyAndReload') }}
                </activix-button>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    import { mapActions } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import { isNetworkDisconnectedError } from '@/plugins/axios';

    import FeaturePreview from '@/components/container/featurePreview/FeaturePreview';
    import FeaturePreviewButton from '@/components/container/featurePreview/FeaturePreviewButton';

    export default {
        components: {
            FeaturePreview,
            FeaturePreviewButton,
        },

        props: {
            featurePreviews: {
                type: Array,
                required: true,
            },
            opened: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                internalActiveStates: {},
                selectedFeaturePreview: null,
            };
        },

        computed: {
            featuresWithStateUpdated() {
                return Object.entries(this.internalActiveStates)
                    .filter(([featurePreviewId, activeState]) => {
                        const matchingFeature = this.featurePreviews
                            .find(originalFeature => originalFeature.id === parseInt(featurePreviewId, 10));

                        return matchingFeature && matchingFeature.isActive !== activeState;
                    })
                    .map(([featurePreviewId, activeState]) => ({ featurePreviewId, activeState }));
            },

            isUpdating() {
                return this.$wait.is('updating.featurePreviews');
            },

            selectedFeaturePreviewInternalActiveState() {
                return this.internalActiveStates[this.selectedFeaturePreview.id] || false;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['appendNewError']),

            async apply() {
                if (this.isUpdating) {
                    return;
                }

                this.$wait.start('updating.featurePreviews');

                const responses = await Promise.allSettled(
                    this.featuresWithStateUpdated.map(({ featurePreviewId, activeState }) => {
                        return this.updateFeaturePreviewActiveState(featurePreviewId, activeState);
                    }),
                );

                const failures = responses
                    .filter(response => {
                        return response.status === 'rejected' &&
                            !isNetworkDisconnectedError(response.reason) &&
                            response.reason.response?.status !== 403;
                    })
                    .map(response => response.reason);

                if (failures.length) {
                    this.manageFailures(failures);
                } else {
                    window.location.reload();
                }
            },

            close() {
                this.$emit('update:opened', false);
            },

            initInternalActiveStates() {
                this.internalActiveStates = Object.fromEntries(
                    this.featurePreviews.map(feature => [feature.id, feature.isActive]),
                );
            },

            isSelectedFeature(feature) {
                return feature.id === this.selectedFeaturePreview?.id;
            },

            manageFailures(failures) {
                failures.forEach(error => {
                    this.appendNewError({
                        code: '0119',
                        data: error.config?.data,
                        display: true,
                        error,
                        message: 'featurePreviewActivationFailed',
                    });
                });

                this.close();
            },

            onClosed() {
                this.$wait.end('updating.featurePreviews');
                this.initInternalActiveStates();
            },

            onFeatureActiveStateChanged({ featurePreviewId, activeState }) {
                this.internalActiveStates[featurePreviewId] = activeState;
            },

            onFeatureSelected(featurePreview) {
                this.selectedFeaturePreview = featurePreview;
            },

            async updateFeaturePreviewActiveState(featurePreviewId, activeState) {
                return this.$axios.put(`v1/auth-user/update-feature-preview-activation/${featurePreviewId}`, {
                    active: activeState,
                });
            },
        },

        mounted() {
            this.initInternalActiveStates();
            this.selectedFeaturePreview = this.featurePreviews[0];
        },
    };
</script>
