import Enum from './Enum.js';

export default class Provider extends Enum {
    static get entries() {
        return {
            magnetis: 158,
        };
    }

    static get MAGNETIS() {
        return this.entries.magnetis;
    }
}
