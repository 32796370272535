<template>
    <div class="flex flex-col">
        <communication-list-item
            :account="account"
            :communication="communication"
            :lead="lead"
            :show-anchor-line="index + 1 !== communications.length"
            :key="communication.id"
            @edit-communication="$emit('edit-communication', $event)"
            @open-context-modal="$emit('open-context-modal', $event)"
            v-for="(communication, index) in communications"
        />
    </div>
</template>

<script>
    import CommunicationListItem from '@/components/container/lead/communications/CommunicationListItem';

    import Account from '@/entities/Account.js';
    import Lead from '@/entities/Lead.js';

    export default {
        components: { CommunicationListItem },

        props: {
            account: {
                required: true,
                type: Account,
            },
            communications: {
                required: true,
                type: Array,
            },
            lead: {
                required: true,
                type: Lead,
            },
        },
    };
</script>
