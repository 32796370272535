<template>
    <label
        :for="vehicle.index"
        class="rounded-lg font-normal"
        :class="selected ? 'ring-4 ring-blue-500' : ''"
    >
        <div
            class="
                group
                relative grid grid-cols-3 pl-12 rounded-lg shadow-md bg-white
                cursor-pointer transform transition duration-300 hover:shadow-xl
            "
        >
            <div class="absolute left-0 inset-y-0 flex justify-center items-center px-3 border-r border-gray-200 rounded-l-lg bg-gray-100" @click="selectVehicle(vehicle.id)">
                <activix-radio
                    :id="vehicle.index"
                    name="vehicle-inventory"
                    :checked="selected"
                />
            </div>

            <picture class="flex justify-center items-center p-6">
                <img
                    alt="Car wireframe"
                    class="flex-grow max-h-32 object-contain opacity-50"
                    src="@/assets/img/car-wireframe.png"
                    v-if="!vehicle.make || !vehicle.model || !vehicle.year"
                />
                <template v-else>
                    <img
                        class="flex-grow max-h-32 object-contain"
                        src="@/assets/img/powersport.png"
                        alt="Powersport vehicle"
                        v-if="vehicle.lead_vehicle_category === 'powersport'"
                    />

                    <img
                        alt="Car"
                        class="flex-grow max-h-32 object-contain"
                        src="@/assets/img/car-wireframe.png"
                        v-else
                    />
                </template>
            </picture>

            <div class="col-span-2 grid grid-cols-2 place-content-center items-center gap-6 p-6">
                <div class="flex flex-col">
                    <p class="mb-0 space-x-1">
                        <span
                            :class="vehicle.year ? '' : 'italic text-gray-500'"
                            v-text="vehicle.year || $t('clientCard.vehicles.year')"
                        />
                        <span
                            :class="vehicle.make ? '': 'italic text-gray-500'"
                            v-text="vehicle.make || $t('clientCard.vehicles.make')"
                        />
                    </p>
                    <p
                        class="mb-0 text-xl"
                        :class="vehicle.model ? 'text-gray-800': 'italic text-gray-500'"
                        v-text="vehicleModel"
                    />
                    <p
                        class="mb-0"
                        :class="vehicle.trim ? 'text-sm text-gray-700': 'italic text-gray-500'"
                        v-text="vehicleTrim"
                    />
                </div>

                <div class="flex flex-col items-start gap-3">
                    <!-- STOCK STATUS + ORDER STATUS-->
                    <div class="flex items-center space-x-1.5">
                        <div
                            class="flex items-center text-sm"
                            :class="statusIsPending ? 'rounded-l-sm' : 'rounded-sm'"
                        >
                            <span class="mr-1.5 w-1.5 h-1.5 rounded-full" :class="stockStatusColor" /> {{ stockStatusText }}
                        </div>

                        <samp
                            class="tracking-wide text-sm tabular-nums text-gray-600"
                            v-text="`#${vehicle.stock}`"
                            v-if="statusIsAvailable"
                        />

                        <samp
                            class="flex items-center font-sans text-sm text-gray-600 rounded-r-sm"
                            v-text="`(${vehicle.stock})`"
                            v-if="!statusIsAvailable && vehicle.stock"
                        />
                    </div>

                    <p class="mb-0">
                        <span
                            :class="vehicle.transmission ? 'text-sm text-gray-700': 'italic text-gray-500'"
                            v-text="vehicle.transmission ? vehicle.transmission : $t('clientCard.vehicles.transmissionMissing')"
                            v-if="vehicle.drivetrain || vehicle.transmission"
                        />
                        <span v-text="` – `" v-if="vehicle.drivetrain || vehicle.transmission" />
                        <span
                            :class="vehicle.drivetrain ? 'text-sm text-gray-700': 'italic text-gray-500'"
                            v-text="vehicle.drivetrain ? vehicle.drivetrain : $t('clientCard.vehicles.drivetrainMissing')"
                            v-if="vehicle.drivetrain || vehicle.transmission"
                        />

                        <span
                            :class="vehicle.drivetrain && vehicle.transmission ? '': 'italic text-gray-500'"
                            v-text="vehicle.drivetrain && vehicle.transmission ? '' : $t('clientCard.vehicles.drivetrainTransmissionMissing')"
                            v-if="!vehicle.drivetrain && !vehicle.transmission"
                        />
                    </p>

                    <p
                        class="mb-0"
                        :class="vehicle.mileage ? 'text-sm text-gray-700': 'italic text-gray-500'"
                        v-text="vehicle.mileage ? odometer : $t('clientCard.vehicles.mileageMissing')"
                    />
                </div>
            </div>
        </div>
    </label>
</template>

<script>
    import LeadVehicle from '@/entities/LeadVehicle.js';
    import InventoryStockStatus from '@/entities/InventoryStockStatus.js';

    export default {
        props: {
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
            category: {
                type: String,
                required: true,
            },
            selectedVehicleId: {
                type: Number,
                required: false,
            },
            openedByDefault: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            statusIsPending() {
                return this.vehicle.status === InventoryStockStatus.ORDER || this.vehicle.status === InventoryStockStatus.RESERVED;
            },

            statusIsAvailable() {
                return this.vehicle.stock && (this.vehicle.status === InventoryStockStatus.IN_STOCK || this.vehicle.status === InventoryStockStatus.AVAILABLE);
            },

            vehicleTrim() {
                return this.vehicle.trim || this.$t('clientCard.vehicles.trimMissing');
            },

            vehicleModel() {
                return this.vehicle.model || this.$t('clientCard.vehicles.model');
            },

            stockStatusColor() {
                const statusColor = {
                    in_stock: 'bg-green-500',
                    available: 'bg-green-500',
                    unavailable: 'bg-red-500',
                    order: 'bg-blue-500',
                    reserved: 'bg-blue-500',
                    locate: 'bg-orange-500',
                }[this.vehicle.status];

                if (statusColor && this.vehicle.stock) {
                    return statusColor;
                }

                return 'bg-gray-300';
            },

            stockStatusText() {
                const stock = {
                    in_stock: this.$t('clientCard.vehicles.inStock'),
                    available: this.$t('clientCard.vehicles.inStock'),
                    unavailable: this.$t('clientCard.vehicles.unavailable'),
                    order: this.$t('clientCard.vehicles.order'),
                    reserved: this.$t('clientCard.vehicles.order'),
                    locate: this.$t('clientCard.vehicles.locate'),
                }[this.vehicle.status];

                if (stock && this.vehicle.stock) {
                    return stock;
                }

                return this.$t('clientCard.vehicles.stockStatusTBD');
            },

            odometer() {
                const unit = this.vehicle.odometer_unit || 'km';

                if (!this.vehicle.mileage) {
                    return `– ${unit}`;
                }

                return this.$n(this.vehicle.mileage, unit);
            },

            selected() {
                return this.selectedVehicleId == this.vehicle.id;
            },
        },

        methods: {
            selectVehicle(vehicleId) {
                if (this.selectedVehicleId != vehicleId) {
                    this.$emit('selectVehicle', vehicleId);
                } else {
                    this.$emit('selectVehicle', null);
                }
            },
        },
    };
</script>
