<template>
    <activix-modal size="sm" :opened="opened" :closable="false">
        <div class="flex flex-col" slot="body">
            <sweet-alert-icon class="mx-auto" type="info" />
            <div class="text-2xl font-semibold text-center my-2" v-text="$t('modal.titleAssignAdvisor')" />

            <template v-if="!saved">
                <div class="text-center mt-4 ">
                    <i18n path="modal.leadAssignText" tag="div" v-if="!selfUser">
                        <span class="font-semibold">{{ userFullName }}</span>
                    </i18n>
                    <template v-else>
                        <div>{{ $t('modal.leadAssignUser') }}</div>
                        <activix-multiselect
                            class="mt-2 mb-5"
                            label="text"
                            :allow-empty="false"
                            :options="formatedAssignmentUsers"
                            v-model="user"
                        />
                    </template>
                    <div>{{ $t('modal.leadAssignReason') }}</div>
                </div>

                <textarea class="form-control h-32 mt-2" v-model="content" />
            </template>

            <div class="mt-6 mb-2 text-center" v-html="$t('modal.moreInfoToDirection', [userFullName])" v-else />
        </div>

        <div slot="footer" v-if="!saved">
            <div class="text-red-600 mb-3" v-if="shouldDisplayError">
                {{ $t('clientCard.minAssignReasonRequest') }}
            </div>
            <activix-button @click="close">
                {{ $t('modal.cancel') }}
            </activix-button>
            <activix-button type="primary" @click="save">
                {{ $t('modal.send') }}
            </activix-button>
        </div>
        <div slot="footer" v-if="saved">
            <activix-button type="primary" @click="close">
                {{ $t('general.ok') }}
            </activix-button>
        </div>
    </activix-modal>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';
    import SweetAlertIcon from '../elements/SweetAlert/Icon.vue';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            SweetAlertIcon,
        },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },

            requestType: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                content: '',
                user: null,
                displayError: false,
                saved: false,
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useGlobalStore, ['authUser', 'assignmentUsers']),

            selfUser() {
                if (this.requestType == 'advisor') {
                    return this.authUser.id == this.contextLead.user_id;
                }

                return this.authUser.id == this.contextLead.bdc_user_id;
            },

            formatedAssignmentUsers() {
                return this.assignmentUsers.map(user => {
                    return {
                        value: user.id,
                        text: this.getFullName(user),
                    };
                });
            },
            userFullName() {
                if (this.requestType == 'advisor') {
                    return this.contextLead.user.fullName;
                }

                return this.contextLead.bdc_user.fullName;
            },

            disabledContent() {
                return this.content.length < 10;
            },

            shouldDisplayError() {
                return this.displayError && this.content.length < 10;
            },
        },

        methods: {
            close() {
                this.content = '';
                this.displayError = false;
                this.$emit('closed');
            },

            save() {
                if (this.content.length < 10 || (!this.user && this.selfUser)) {
                    this.displayError = true;
                    return;
                }

                this.saved = true;
                this.$emit('save', this.content, this.user?.value);
                this.content = '';
                this.user = null;
                this.displayError = false;
            },
        },
    };
</script>
