<template>
    <div class="flex justify-between items-center" :class="{ 'border-t border-grey-200': bordered }">
        <div class="flex items-center flex-1">
            <div
                class="flex items-center justify-center flex-shrink-0 bg-grey-200 border rounded-full h-10 w-10 | md:h-8 md:w-8"
            >
                <icon class="text-grey-600 text-xl | md:text-base" :name="icon" />
            </div>
            <div class="ml-3">
                <template v-if="item.isAssociation">
                    <span v-html="item.formatedValue" />
                    <router-link
                        :to="{ name: 'leads.update', params: { id: item.relevant_data } }"
                        v-text="item.old_lead_name"
                    />
                    <span v-html="item.formatedType" />
                </template>
                <span v-html="item.formatedValue" v-else />
            </div>
        </div>
        <div class="text-grey-600 ml-3">
            <div class="hidden md:block">
                {{ formatedDateTime }}
            </div>
            <div class="block md:hidden text-right">
                <div>{{ formatedDate }}</div>
                <div>{{ formatedTime }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            item: {
                type: Object,
                required: true,
            },
            bordered: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            formatedDateTime() {
                const date = locale_dt(this.item.date);

                if (date.isSameDay(now())) {
                    return date.fromNow();
                }

                return date.humanShort();
            },

            formatedDate() {
                return locale_dt(this.item.date).humanShort(true);
            },

            formatedTime() {
                return locale_dt(this.item.date).toTimeShortString();
            },

            icon() {
                if (this.item.isAssociation) {
                    return 'regular/multiple-neutral-2';
                }

                switch (this.item.type) {
                    case 'wanted_vehicle':
                    case 'exchange_vehicle':
                        return 'regular/car-1';

                    case 'lead_phone':
                        return 'regular/phone';

                    case 'task_event':
                        return 'regular/calendar-3';

                    case 'lead_email':
                        return 'regular/email-action-unread';

                    case 'comment':
                        return 'regular/pencil-1';

                    case 'reminder':
                        return 'regular/alarm-bell';

                    default:
                        return 'regular/single-neutral-actions';
                }
            },
        },
    };
</script>
