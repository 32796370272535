<template>
    <flexible-input :label="$t('result.delivered')">
        <template #label-suffix>
            <activix-tooltip :content="$t('createdMethods.auto_renewal')" v-if="showRenewalIcon">
                <icon
                    :name="$icons.loading"
                    class="link-orange"
                    @click="triggerCreateRenewalManually"
                />
            </activix-tooltip>
        </template>

        <activix-tooltip :content="tooltip">
            <checkbox-date-picker
                :date="lead.delivered_date"
                :disabled="disabled"
                @check="onCheckboxClick"
                @date-click="onDateClick"
            />
        </activix-tooltip>

        <create-renewal :lead="lead" :opened.sync="modals.createRenewal.opened" @closed="openModal(true)" />

        <remove-follow-up
            follow-up="delivered"
            :opened.sync="modals.removeFollowUp.opened"
            @approve="updateLead({ delivered_date: null })"
            @closed="modals.removeFollowUp.opened = false"
        />
    </flexible-input>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import CheckboxDatePicker from '@/components/container/input/CheckboxDatePicker';
    import CreateRenewal from '@/components/modals/CreateRenewal';
    import FlexibleInput from '@/components/container/input/FlexibleInput';
    import RemoveFollowUp from '@/components/modals/RemoveFollowUp.vue';

    import AccountType from '@/entities/AccountType';
    import Lead from '@/entities/Lead.js';
    import LeadType from '@/entities/LeadType';
    import LeadVehicle from '@/entities/LeadVehicle';

    export default {
        components: {
            CheckboxDatePicker,
            CreateRenewal,
            FlexibleInput,
            RemoveFollowUp,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        data() {
            return {
                modals: {
                    createRenewal: {
                        manually: false,
                        opened: false,
                    },
                    removeFollowUp: {
                        opened: false,
                    },
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            disabled() {
                if (this.lead.hasInvalidStatus()) {
                    return true;
                }

                if (!this.lead.sale_date && !this.lead.delivered_date && !this.lead.isRefinanced) {
                    return true;
                }

                if (!this.soldVehicle.id && !this.lead.delivered_date && this.lead.account.type !== AccountType.CUSTOM && !this.lead.isRefinanced) {
                    return true;
                }

                if (!this.$can('leads.delivered_date:update')) {
                    return true;
                }

                return this.guest && !this.lead.hasDeliveryMan(this.authUser.id);
            },

            showRenewalIcon() {
                return !!(
                    this.lead.canAutoRenew() &&
                    this.soldVehicle.eligibleForRenewal &&
                    !this.lead.renewal_cycle &&
                    this.lead.delivered_date
                );
            },

            soldVehicle() {
                return new LeadVehicle(this.lead.getSoldVehicle());
            },

            tooltip() {
                if (this.guest && !this.lead.hasDeliveryMan(this.authUser.id)) {
                    return this.$t('clientCard.guestMode');
                }

                if (!this.lead.sale_date && !this.lead.sale_by_phone && !this.lead.delivered_date && !this.lead.isRefinanced) {
                    if (this.lead.isOfType(LeadType.RENEWAL)) {
                        return this.$t('clientCard.leadMustBeRenewed');
                    }

                    return this.$t('clientCard.leadMustBeSold');
                }

                if (!this.lead.hasInvalidStatus()) {
                    if (!this.lead.sale_date && !this.lead.delivered_date && !this.lead.isRefinanced) {
                        if (this.lead.isOfType(LeadType.RENEWAL)) {
                            return this.$t('clientCard.needRenewalChecked');
                        }

                        return this.$t('clientCard.needSaleChecked');
                    }
                } else {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.delivered'),
                    ])}`;
                }

                if (!this.soldVehicle.id && !this.lead.delivered_date && !this.lead.isRefinanced) {
                    return this.$t('clientCard.leadMustHaveASoldWantedVehicle');
                }

                if (this.lead.delivered_date && this.lead.delivered_by_id) {
                    return `<strong>${this.$t('clientCard.deliveredBy')}</strong> : ${
                        this.lead.delivered_by.fullName
                    }`;
                }

                return '';
            },
        },

        methods: {
            onCheckboxClick(isChecked) {
                if (this.disabled) {
                    return;
                }

                if (isChecked && this.lead.canAutoRenew() && this.soldVehicle.eligibleForRenewal) {
                    this.modals.createRenewal.opened = true;
                } else {
                    this.openModal(isChecked);
                }
            },

            onDateClick() {
                if (this.disabled) {
                    return;
                }

                this.openFollowUpModal();
            },

            openFollowUpModal() {
                this.$modal.show('followUp', {
                    leadId: this.lead.id,
                    current: 'delivered',
                    last: 'sale',
                });
            },

            openModal(isChecked) {
                if (this.modals.createRenewal.manually) {
                    this.modals.createRenewal.manually = false;

                    return;
                }

                if (isChecked) {
                    this.openFollowUpModal();
                } else {
                    this.modals.removeFollowUp.opened = true;
                }
            },

            triggerCreateRenewalManually() {
                this.modals.createRenewal.manually = true;
                this.modals.createRenewal.opened = true;
            },

            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>
