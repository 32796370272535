<template>
    <div class="flex-1 flex items-center justify-center h-full" v-if="isLoading">
        <activix-spinner :message="$t('general.loading')" />
    </div>

    <div class="relative flex flex-col" v-else-if="isInitialized">
        <portal to="left-sidebar">
            <associated-leads :active-lead-id="contextLead.id" :customer="contextLead.customer">
                <template #actions="{ isExpanded, relatedLeadId }">
                    <create-associated-lead
                        :account="contextLead.account"
                        :default-data="newAssociatedLeadDefaultData"
                        :related-lead-id="relatedLeadId"
                        :with-label="isExpanded"
                    />

                    <inter-dealer-leads :lead="contextLead" :with-label="isExpanded" />
                </template>
            </associated-leads>
        </portal>

        <lead-v2-header
            :lead="contextLead"
            @delete-lead="onDelete"
            @update-lead="updateLead"
        />

        <lead-v2-boxes />

        <portal to="content-footer">
            <footer class="absolute inset-x-0 bottom-6 h-12 flex justify-center items-center | print:hidden">
                <img class="w-32 grayscale opacity-30" alt="Activix CRM" src="@/assets/img/logo_activix_crm_gray.png" />
            </footer>
        </portal>

        <portal to="anchor-bottom-left">
            <div class="flex gap-6 h-12 mt-6 | print:hidden">
                <lead-actions class="overflow-hidden rounded-lg shadow-xl bg-white/80 backdrop-blur-sm" :lead="contextLead" />
                <task-event-actions class="overflow-hidden rounded-lg shadow-xl bg-white/80 backdrop-blur-sm" :lead="contextLead" />
            </div>
        </portal>

        <portal to="anchor-bottom-right">
            <div class="flex gap-6 h-12 mt-6 | print:hidden">
                <feature-preview-management-button />
                <lead-arrows-navigation :lead="contextLead" />
            </div>
        </portal>

        <merge-management-modal
            :opened="modals.mergeManagement.opened"
            @close="closeMergeManagement"
        />

        <call-lead name="lead:callLead" />
        <email-lead name="lead:emailLead" />
        <videoconference-invitation name="lead:videoconferenceInvitation" />

        <cancel-appointment name="cancelAppointment" />
        <consent-config name="consentConfig" />
        <follow-up name="followUp" />
        <select-service-vehicle :opened="modals.selectServiceVehicle.opened" />
        <select-vehicle name="selectVehicle" :in-client-card="true" />

        <screen-pop-lead-communicating :communicating-lead="communicatingLead" />
        <screen-pop-no-associated-lead-event v-if="isEventLead && isCreatedAfterRelease" />
        <activix-confirm-modal
            type="error"
            :content="$tc('client.deleteLeads.confirmation', 1)"
            :opened.sync="modals.deleteLead.opened"
            @approve="onDeleteApproved"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useAssociatedLeadsStore } from '@/store/modules/associatedLeads/store.js';
    import { useClientCardStore } from '@/store/modules/clientCard/store.js';
    import { useContextStore } from '@/store/modules/context/store';
    import { useGlobalStore } from '@/store/store.js';
    import { useInterAccountLeadsStore } from '@/store/modules/interAccountLeads/store.js';
    import { useLeadStore } from '@/store/modules/lead/store.js';

    import { camelCase } from 'lodash-es';

    import TrackView from '@/mixins/TrackView.js';

    import AssociatedLeads from '@/components/container/lead/AssociatedLeads';
    import CallLead from '@/components/modals/CallLead';
    import CancelAppointment from '@/components/modals/CancelAppointment.vue';
    import ConsentConfig from '@/components/modals/ConsentConfig.vue';
    import CreateAssociatedLead from '@/components/container/lead/CreateAssociatedLead.vue';
    import EmailLead from '@/components/modals/EmailLead';
    import FeaturePreviewManagementButton from '@/components/container/featurePreview/FeaturePreviewManagementButton';
    import FollowUp from '@/components/modals/FollowUp.vue';
    import InterDealerLeads from '@/components/container/lead/InterDealerLeads.vue';
    import LeadActions from '@/components/container/lead/LeadActions';
    import LeadArrowsNavigation from '@/components/container/lead/LeadArrowsNavigation';
    import LeadV2Boxes from '@/components/lead/LeadV2Boxes.vue';
    import LeadV2Header from '@/components/lead/LeadV2Header.vue';
    import MergeManagementModal from '@/components/modals/MergeManagementModal.vue';
    import ScreenPopLeadCommunicating from '@/components/modals/ScreenPopLeadCommunicating.vue';
    import ScreenPopNoAssociatedLeadEvent from '@/components/modals/ScreenPopNoAssociatedLeadEvent.vue';
    import SelectServiceVehicle from '@/components/modals/SelectServiceVehicle.vue';
    import SelectVehicle from '@/components/modals/SelectVehicle.vue';
    import TaskEventActions from '@/components/container/lead/TaskEventActions.vue';
    import VideoconferenceInvitation from '@/components/modals/VideoconferenceInvitation';

    import ActivixDate from '@/value-objects/ActivixDate.js';
    import CreatedMethod from '@/entities/CreatedMethod.js';
    import LeadType from '@/entities/LeadType.js';

    export default {
        components: {
            AssociatedLeads,
            CallLead,
            CancelAppointment,
            ConsentConfig,
            CreateAssociatedLead,
            EmailLead,
            FeaturePreviewManagementButton,
            FollowUp,
            InterDealerLeads,
            LeadActions,
            LeadArrowsNavigation,
            LeadV2Boxes,
            LeadV2Header,
            MergeManagementModal,
            ScreenPopLeadCommunicating,
            ScreenPopNoAssociatedLeadEvent,
            SelectServiceVehicle,
            SelectVehicle,
            TaskEventActions,
            VideoconferenceInvitation,
        },

        mixins: [TrackView],

        props: {
            rightSidebarOpened: {
                type: Boolean,
                default: false,
            },
        },

        metaInfo() {
            return {
                title: this.contextLead.fullName,
            };
        },

        data: () => ({
            modals: {
                mergeManagement: {
                    opened: false,
                },
                selectServiceVehicle: {
                    opened: false,
                },
                deleteLead: {
                    opened: false,
                },
            },
        }),

        computed: {
            ...mapState(useAssociatedLeadsStore, ['associatedLeads']),
            ...mapState(useGlobalStore, ['authUser', 'communicatingLead', 'guest']),
            ...mapState(useLeadStore, ['mergeManualCheckDuplicate']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextLead: 'contextLead',
            }),

            isCreatedAfterRelease() {
                const releaseDate = new ActivixDate('2021-05-01 00:00');
                const leadCreationDate = new ActivixDate(this.contextLead.created_at);

                return leadCreationDate.isAfter(releaseDate);
            },

            isCreatedBySerti() {
                return this.contextLead.created_method === CreatedMethod.SERTI;
            },

            isEventLead() {
                return this.contextLead.isOfType(LeadType.EVENT);
            },

            isInitialized() {
                return !!this.contextLead.id && !this.$wait.is('fetching.contextAccount');
            },

            isLoading() {
                return !this.isInitialized || this.$wait.is('initializing.lead');
            },

            newAssociatedLeadDefaultData() {
                return {
                    account: this.contextLead.account_id,
                    advisor: this.contextLead.user_id,
                    bdc: this.contextLead.bdc_user_id,
                    civility: this.contextLead.civility,
                    division: this.contextLead.division_id,
                    email: this.contextLead.homeEmail?.email,
                    name: this.contextLead.fullName,
                    phone: this.contextLead.homePhone?.phone,
                    relatedLead: this.contextLead.id,
                    serviceAdvisor: this.contextLead.service_advisor_id,
                    serviceAgent: this.contextLead.service_agent_id,
                    source: this.contextLead.source_id,
                };
            },

            shouldShowMergeManagementOnLoad() {
                if (this.isCreatedBySerti) {
                    return false;
                }

                return !this.contextLead.import_validated;
            },

            shouldShowServiceVehicleSelector() {
                if (!this.contextLead.isService()) {
                    return false;
                }

                if (this.contextLead.exchange_vehicles.length <= 1) {
                    return false;
                }

                const serviceVehicles = this.contextLead.exchange_vehicles.filter(vehicle => vehicle.service);

                return !serviceVehicles.length;
            },
        },

        watch: {
            '$route.params.id': {
                immediate: true,
                handler(newId, previousId) {
                    if (!newId || newId === previousId) {
                        return;
                    }

                    if (newId !== this.contextLead.id) {
                        this.$wait.start('initializing.lead');
                    }

                    this.setContextLeadIdAction(newId);
                },
            },

            'contextLead.id': {
                immediate: true,
                handler(newId, previousId) {
                    if (!newId || newId === previousId) {
                        return;
                    }

                    this.whenLeadIsInitialized();

                    this.$wait.end('initializing.lead');

                    this.checkCommunicating();
                },
            },

            guest: {
                immediate: true,
                handler() {
                    this.showGuestAlert();
                },
            },

            async mergeManualCheckDuplicate() {
                if (this.mergeManualCheckDuplicate) {
                    await this.$nextTick();

                    this.modals.mergeManagement.opened = true;
                } else {
                    this.modals.mergeManagement.opened = false;
                }
            },

            async shouldShowServiceVehicleSelector() {
                await this.$nextTick();

                this.modals.selectServiceVehicle.opened = this.shouldShowServiceVehicleSelector;
            },
        },

        methods: {
            ...mapActions(useAssociatedLeadsStore, ['removeLead']),
            ...mapActions(useContextStore, ['setContextAccountAction', 'setContextLeadIdAction']),
            ...mapActions(useGlobalStore, ['setCommunicating', 'updateLeadAction', 'deleteLead']),

            async checkCommunicating() {
                if (!this.contextLead.id) {
                    return;
                }

                const response = await this.$api.leads.checkCommunicating(this.contextLead.id);

                if (response.isCommunicating) {
                    this.setCommunicating(response.lead);
                }
            },

            closeMergeManagement() {
                useLeadStore().mergeManualCheckDuplicate = false;
            },

            onDelete() {
                this.modals.deleteLead.opened = true;
            },

            async onDeleteApproved() {
                try {
                    await this.$api.leads.delete({ id: this.contextLead.id });

                    const relatedEventLead = this.contextLead.customer.leads.filter(lead => {
                        return lead.event_related_id === this.contextLead.id;
                    });

                    if (relatedEventLead.length) {
                        this.deleteLead(relatedEventLead[0].id);
                    }

                    this.deleteLead(this.contextLead.id);

                    await this.$nextTick();

                    const redirectingLead = this.associatedLeads[0];

                    if (redirectingLead) {
                        this.$router.replace({
                            name: 'leads.update',
                            params: { id: redirectingLead.id },
                        });

                        return;
                    }

                    const defaultDashboard = camelCase(this.authUser.default_dashboard);

                    this.$router.replace({ name: `dashboards.${defaultDashboard}` });

                    this.$eventBus.$emit('fetch-table-leads', { getCount: true });
                } catch (error) {
                    this.$notify.error(this.$t('client.deleteLeads.failedSingle'));
                }
            },

            showGuestAlert() {
                if (!this.isInitialized) {
                    return;
                }

                if (this.isEventLead && this.isCreatedAfterRelease) {
                    const nonEventLeads = this.contextLead.customer.leads.filter(lead => {
                        return lead.lead_type_id !== LeadType.EVENT;
                    });

                    if (!nonEventLeads.length) {
                        return;
                    }
                }

                if (this.guest) {
                    this.$notify.warning({
                        duration: -1,
                        ignoreDuplicates: true,
                        text: this.$t('clientCard.guestMode'),
                    });
                } else {
                    this.$notify.clean();
                }
            },

            updateLead({ data, options = {} }) {
                this.updateLeadAction(this.contextLead.id, data, options);
            },

            whenLeadIsInitialized() {
                // Open duplicate validation if addLead panel not open
                if (!this.rightSidebarOpened) {
                    useLeadStore().mergeManualCheckDuplicate = this.shouldShowMergeManagementOnLoad;
                    useLeadStore().currentMergeAction = '';
                }

                if (
                    !this.contextAccount.id ||
                    this.contextAccount.id !== this.contextLead.account_id
                ) {
                    this.setContextAccountAction(this.contextLead.account_id);
                }

                if (this.guest) {
                    this.showGuestAlert();
                }
            },
        },

        beforeDestroy() {
            useInterAccountLeadsStore().leads = [];
            useClientCardStore().vehicleMakeWarranties = {};
            useClientCardStore().vehicleTrims = {};
            useClientCardStore().vehicleModels = {};
        },
    };
</script>
