<template>
    <div>
        <div class="box | mb-6">
            <div class="box-header | flex items-center">
                <h4 class="box-title">
                    {{ $t('clientCard.communications') }}
                </h4>
                <activix-tooltip :content="$t('clientCard.addCommunication')" v-if="showAddCommunication">
                    <icon name="bold/add" class="link-grey-light ml-2" @click="openAddCommunicationModal" />
                </activix-tooltip>
            </div>
            <communication-list
                class="box-body | p-0"
                :lead="contextLead"
                @open-add-communication-modal="openAddCommunicationModal"
            />

            <add-communication :lead="contextLead" :opened.sync="modals.addCommunicationOpened" />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    // Components
    import CommunicationList from './CommunicationList.vue';
    import AddCommunication from '../modals/AddCommunication.vue';

    // Pinia
    import { useClientCardStore } from '../../store/modules/clientCard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            AddCommunication,
            CommunicationList,
        },

        data() {
            return {
                modals: {
                    addCommunicationOpened: false,
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, ['contextLead']),

            showAddCommunication() {
                return this.authUser.hasAccessToEditAccount(this.contextLead.account_id);
            },
        },

        methods: {
            openAddCommunicationModal({ communication }) {
                useClientCardStore().currentCommunication = communication;

                this.$nextTick(() => {
                    this.modals.addCommunicationOpened = true;
                });
            },
        },
    };
</script>
